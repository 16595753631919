import React, { useEffect, useState } from 'react';
import { Modal, Image } from 'react-bootstrap';
import CalenderIcon from "../../assets/images/icon/calender-icon.svg";
import CrossIcon from "../../assets/images/icon/cross-modal-icon.svg";
import { CustomDropDown } from '../../utils/form';
import PerformanceTable from './PerformanceTable';
import { formattedDate } from '../../utils/ReusableFunctions';
import { getAuthDataApi } from '../home/Api';
import { toast } from 'react-toastify';
/* eslint-disable react-hooks/exhaustive-deps */




export default function PerformanceModal({ showPerformance, setShowPerformance, uuid }) {

  const handleClose = () => setShowPerformance(false);

  // const { color, language } = cobrandcolorLanguage;
  const [performanceDetails, setPerformanceDetails] = useState([]);
  const [variation, setVariation] = useState([]);
  const [caption, setCaption] = useState([]);
  const [colorOptions, setColorOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [color, setColor] = useState("");
  const [language, setLanguage] = useState("");
  // const [isLoading, setIsLoading] = useState(true);




  async function handlePerformanceDetails() {
    try {
      const res = await getAuthDataApi(`website/scheduledPostPlanner/${uuid}`);

      if (res) {
        setPerformanceDetails(res);
        setVariation(res?.content?.contentVariation);
        let languageOption1 = res?.content?.contentVariation?.map((item) => item.language);
        let languageOption = Object.values(languageOption1.reduce((uniqueMap, obj) => {
          uniqueMap[obj.languageName] = obj;
          return uniqueMap;
        }, {}));

        let colorOption = res?.content?.contentVariation?.map((item) => item.color);

        setLanguageOptions(languageOption);
        setColorOptions(colorOption);

        if (languageOption?.length === 1) {
          let getFixedObj = res?.content?.contentVariation?.find(item => item.language?.languageName === languageOption[0]?.languageName);
          handleObject(getFixedObj);
        }

        if (colorOption?.length > 1) {
          let getFixedObj = res?.content?.contentVariation?.find(item => item.color?.colorName === colorOption[0]?.colorName);
          handleObject(getFixedObj);
        }

        setCaption(res?.caption);
      } else {
        toast.error("Failed to get data");
      }
    }
    catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // setIsLoading(false);
    }
  }

  useEffect(() => {
    if (uuid) {
      handlePerformanceDetails()
    }
  }, [uuid]);

  const handleObject = (data) => {
    setLanguage(data?.language?.languageName);
    // setVariationId(data.uuid)
    setColor(data?.color)
  }

  const languageSelectHandler = (data) => {
    let getFixedObj = variation?.find(item =>
      item.color?.colorName === color?.colorName && item.language?.languageName === data?.languageName
    ) || variation?.find(item => item.language?.languageName === data?.languageName
    );
    handleObject(getFixedObj)
  }

  const colorSelectHandler = (data) => {
    let getFixedObj = variation?.find((item, i) => (item.color?.colorName === data?.colorName && item.language?.languageName === language));
    handleObject(getFixedObj);
  };

  return (

    <Modal className='performance-post-modal' centered show={showPerformance} onHide={handleClose}>
      <Modal.Body>
        <div className="performance-modal-heading d-flex flex-row justify-content-between align-items-baseline">
          <div className="d-flex flex-row align-items-center date-wrapper">
            <Image src={CalenderIcon} />
            <p className="lato-regular black-242 fs-14-11">{formattedDate(performanceDetails?.date)}</p>
          </div>
          <Image width={20} height={20} src={CrossIcon} onClick={handleClose} style={{ cursor: "pointer" }} />
        </div>
        <div className='edit-modal-main row '>
          <div className='d-flex flex-row '>

            <div className=' '>
              {performanceDetails?.content?.thumbnail ?
                <Image className="edit-schedule-image" src={performanceDetails?.content?.thumbnail} alt="image" />
                :
                <div className='card-plus '>
                  <h5 title={performanceDetails?.caption} className="lato-bold fs-14-12 text-ellipsis-two black-242">
                    {performanceDetails?.caption}
                  </h5>
                </div>
              }
            </div>
            <div className="post-details flex-row  d-flex d-md-none">
              <p className="tag-planner-text lato-medium black-242 op-5">{performanceDetails?.userPostPlanner?.playlist?.title} | {formattedDate(performanceDetails?.date)} </p>
              <p className="tag-planner-text lato-medium black-242 op-5 d-none d-md-flex">{performanceDetails?.content?.contentType?.contentTypeName}</p>

            </div>
          </div>
          <div className='d-flex col-12 d-md-none'>

            <PerformanceTable />
          </div>


          <div className='edit-modal-dropdown col-md-3 col-lg-3 '>
            <div className="branding-color-language-frame">
              <div className="row dropwon-component-wrapper1 edit-dropdown-wrapper d-flex flex-row flex-md-column justify-content-start ">
                <div className='col-6'>
                  <CustomDropDown
                    label={color || "Select Color"}
                    options={colorOptions || []}
                    getData={(data) => colorSelectHandler(data)}
                    id="color"
                    disabled={colorOptions?.length <= 1}
                    classes='light-border'
                  />
                </div>
                <div className='col-5'>
                  <CustomDropDown
                    label={language || "Select Language"}
                    options={languageOptions?.length === 1 && language === languageOptions[0]?.languageName ? [] : languageOptions || []}
                    getData={(data) => {
                      languageSelectHandler(data)

                    }}
                    id="Language"
                    classes='light-border'

                    disabled={languageOptions?.length <= 1}

                  />
                </div>
                <div className='col-6'>

                </div>

              </div>
            </div>
          </div>
          <div className='col-6 d-flex flex-column justify-content-between'>
            <div className="post-details flex-row d-none d-md-flex">
              <p className="tag-planner-text lato-medium black-242 op-5">{performanceDetails?.userPostPlanner?.playlist?.title} | {performanceDetails?.date} </p>
              <p className="tag-planner-text lato-medium black-242 op-5 d-none d-md-flex">{performanceDetails?.content?.contentType?.contentTypeName}</p>
            </div>
            <div className="text-area-frame d-none d-md-flex">
              <textarea type="text" maxLength={120} className='fx-14 lato-normal black-242 op-8 text-area ' name='caption' value={caption} placeholder='Caption for Post 1' />

            </div>
          </div>
          <div className='col-12 col-md-12'>
            <div className='d-none d-md-flex'>
              <PerformanceTable />
            </div>
          </div>
          <div className="text-area-frame d-md-none">
            <textarea type="text" maxLength={120} className='fx-14 lato-normal black-242 op-8 text-area ' name='caption' value={caption} placeholder='Caption for Post 1' />

          </div>
        </div>




      </Modal.Body>
    </Modal>





  )








}
