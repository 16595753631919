import { Header } from "../common/pages";
import { BreadCrumb } from "../home/_utils";
import { useHistory, useLocation } from 'react-router-dom';
import { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import TemplatesTab from "./TemplatesTab";
import { useParams } from "react-router-dom/cjs/react-router-dom";

export default function Personalization() {
    const history = useHistory();
    const location = useLocation();
    const { personalizedType } = useParams();

    // Function to get the tab key from the URL
    const getTabKeyFromURL = () => {
        const params = new URLSearchParams(location.search);
        return params.get('tab') || 'templates';
    };

    const [activeTab, setActiveTab] = useState(getTabKeyFromURL());

    useEffect(() => {
        history.push(`/personalization/${personalizedType}?tab=${activeTab}`);

    }, [activeTab, history]);

    const breadCrumbHandler = () => {
        let breadCrumb
        if (personalizedType == "All") return ["Personalization"]
        else return ["Personalization", personalizedType]

    }


    return (
        <section className="page-padding personalization-wrapper">

            <Header />
            <div className="container">
                <BreadCrumb path={breadCrumbHandler()} />
                <div className="post-planner-heading">
                    <h5 className="fs-32-28 lato-semibold black-242">Personalization</h5>

                </div>
            </div>
            <div className="container">
                <Tabs
                    activeKey={activeTab}
                    onSelect={(k) => setActiveTab(k)}
                    class="trending-tabs personalization-tabs"
                    id="uncontrolled-tab-example">
                    <Tab
                        eventKey="templates"
                        className='lato-regular fs-18-14 black-242'
                        title="Templates">
                        <TemplatesTab />
                    </Tab>
                    {/* <Tab eventKey="recent" title="Recent">

                    </Tab>
                    <Tab eventKey="history" title="History">

                    </Tab> */}
                </Tabs>


            </div>
        </section>

    )
}