import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { formatNumber } from "../../../utils/ReusableFunctions";
import { API_LIMIT, START_PAGE } from "../../../utils/Constant";

export default function CardHeader({ count, hide = true, countTitle, isSubCount = true, viewAll = "/social-media", viewDetail }) {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search)

  async function navigateData() {
    // console.log(location.search.split('pageSize=')[0] + 'pageSize=18');
    if (window.location.href.includes("post-planner") || window.location.href.includes("collection-details")) {
      viewDetail(viewAll, START_PAGE);

    }

    else {
      // let searchUrl = `${((params.get('searchValue') ?? false) || (params.get('value') ?? false)) ? `searchValue=${params.get('searchValue') ?? params.get('value')}` : ''}&pageNumber=${params.get('pageNumber') || START_PAGE}&pageSize=${params.get('pageSize') || API_LIMIT}`;

      let search = (location.search) ? location.search.split('?').pop() : `?pageNumber=${START_PAGE}&pageSize=${API_LIMIT}`;

      let viewAllContentType = viewAll.split('/').pop();

      let param = new URLSearchParams(search);
      let contentType = param.get('contentType');
      if(contentType) {
        search = search.replace("contentType="+contentType,"contentType="+viewAllContentType);
      }
      
      history.push({
        pathname: viewAll,
        search: search,
      });
    }
  }

  return (
    <div className="curated-sub-header">
      <h5 className="lato-regular fs-28-18 black-242">
        {countTitle}&nbsp;&nbsp;&nbsp;&nbsp;
        {isSubCount && <span className="lato-regular fs-14-12">{formatNumber(count)}</span>}
      </h5>
      {hide && count >= 3 && (
        <p onClick={navigateData} className=" fs-14-12 lato-medium black-242">
          View all
          {/* <span className={isSubCount ? 'desk-show' : ""}>
            {formatNumber(count)} {countTitle}
          </span> */}
        </p>
      )}
    </div>
  );
}
