import { Button, Image, Tab, Tabs } from "react-bootstrap";
import { Header } from "../common/pages";
import { BreadCrumb } from "../home/_utils";
import { useHistory, useLocation } from 'react-router-dom';
import { getToken } from "../../utils/ManageToken";
import { useState, useEffect } from "react";
import SettingsIcon from "../../assets/images/icon/settings-icon.svg";
import CreatePlannerModal from "./CreatePlannerModal";
import CreatePlanTab from "./CreatePlanTab";
import ScheduledPlanTab from "./ScheduledPlanTab";
import PlannerHistoryTab from "./PlannerHistoryTab";
import SocialPreferenceModal from "../profile/SocialPreferenceModal";
import ReplacePost from "./ReplacePost";
import { getAuthDataApi } from "../home/Api";
import { getUserDetails, groupData, groupDataByDate } from "../../utils/ReusableFunctions";
import { toast } from "react-toastify";
import moment from "moment";


export default function Planner() {
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedKey, setSelectedKey] = useState('Social');
  const [openReplace, setOpenReplace] = useState(false);
  const [replaceData, setReplaceData] = useState();
  const [addData, setAddData] = useState();
  const [preview, setPreview] = useState(false);
  const [previewData, setPreviewData] = useState();
  const [performanceData, setPerformanceData] = useState();
  const [feeds, setFeeds] = useState([]);
  const [isConnected, setIsConnected] = useState();
  const [check, setCheck] = useState(false);
  const [recentLength, setRecentLength] = useState();
  const [recentData, setRecentData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [maxEndDate, setMaxEndDate] = useState();
  const [filterOptions, setFilterOptions] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [checkCount, setCheckCount] = useState(false);
  const [activeKey, setActiveKey] = useState("")
  const [posts, setPosts] = useState([]);
  const [events, setEvents] = useState([
    {
      start: moment("2024-06-14").toDate(),
      end: moment("2024-06-14").add(1, "hour").toDate(),
      title: "1 post scheduled",
      color: "#270089"
    },
  ]);

  // Function to get the tab key from the URL
  const getTabKeyFromURL = () => {
    const params = new URLSearchParams(location.search);
    return params.get('tab') || 'create-plan'; // Default to 'create-plan' if no tab parameter is found
  };

  const [activeTab, setActiveTab] = useState(getTabKeyFromURL());

  useEffect(() => {

    history.push(`/post-planner?tab=${activeTab}`);
    // console.log(activeTab)
  }, [activeTab, history]);

  const handleShow = ((key) => {
    setSelectedKey(key);
    setShow(true);

  })
  useEffect(() => {
    const token = getToken();
    if (token) {
      fetchPlatform();
      getAllowedDate();
      getUserDetails();
      checkSubscription();
    }
  }, [])
  const userDetails = getUserDetails();
  const checkSubscription = async () => {
    const check = await getAuthDataApi("website/userPostPlanner/contentPendingActionCount");

    if (check == 0) {
      setCheckCount(true);
    }
  }

  async function fetchPlatform() {
    const res = await getAuthDataApi("website/platform?pageSize=10&pageNumber=1");

    if (res) {
      setFeeds(res?.list)
    }

  }
  async function getAllowedDate() {
    const res = await getAuthDataApi("website/userPostPlanner/allowedDate");

    if (res) {
      setMaxEndDate(res?.endDate);

    }

  }
  useEffect(async () => {
    const token = getToken();
    if (token) {
      const user = await getAuthDataApi("website/userPlatform/user");

      if (user?.length > 0) {

        setIsConnected(true)
      }
      else {
        setIsConnected(false)
      }
    }
  }, []);

  async function handleGetScheduled() {
    try {

      const res = await getAuthDataApi(`/website/userPostPlanner/recent`);

      if (res) {
        setCheck(true);
        setRecentLength(res?.length);
        const groupedData = groupDataByDate(res);
        setRecentData(groupedData);
        setFilterData(res);
        setPosts(res);
        setEvents(groupData(res));
        const uniqueTitles = ["All", ...new Set(res.map(item => item?.userPostPlanner?.playlist?.title).filter(Boolean))];
        setFilterOptions(uniqueTitles);
      } else {
        toast.error("Failed to get data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <section className="page-padding planner-wrapper">
      <CreatePlannerModal open={open} setOpen={() => setOpen()} feeds={feeds} userDetails={userDetails} />
      <SocialPreferenceModal show={show} setShow={setShow} selectedKey={selectedKey} setSelectedKey={setSelectedKey} isConnected={isConnected} setIsConnected={setIsConnected} handleGetScheduled={handleGetScheduled} />

      <Header />
      <div className="container">
        <BreadCrumb path={["Post Planner"]} />
        <div className="post-planner-heading">
          <h5 className="fs-32-28 lato-semibold black-242">{openReplace ? (replaceData ? "Edit Post" : "Add Post") : "Post Planner"}</h5>

        </div>
      </div>
      {
        getToken() ?
          openReplace ?
            <div className="container">
              <ReplacePost replaceData={replaceData} setRecentData={setRecentData} setOpenReplace={setOpenReplace} addData={addData} maxEndDate={maxEndDate} userDetails={userDetails} recentData={recentData} checkCount={checkCount} activeKey={activeKey} />
            </div>
            :

            <div className="container">
              <div className="social-preferences-frame">
                <p className="lato-medium fs-14-12 primary pointer d-none d-md-flex" onClick={() => handleShow('Social')}> My Socials</p>
                <p className="lato-medium fs-14-12 primary pointer d-none d-md-flex" onClick={() => handleShow('Preferences')}> My Preferences</p>
                <div style={{ zIndex: "1" }} onClick={() => handleShow('Social')}>
                  <Image className="d-md-none" height={16} width={16} src={SettingsIcon} alt="icon" />
                </div>
              </div>

              <Tabs
                activeKey={activeTab}
                onSelect={(k) => setActiveTab(k)}
                class="trending-tabs post-planer-tabs"
                id="uncontrolled-tab-example">
                <Tab
                  eventKey="create-plan"
                  className='lato-regular fs-18-14 black-242'
                  title="Create Plan">
                  <CreatePlanTab open={open} setOpen={setOpen} setActiveTab={setActiveTab} feeds={feeds} maxEndDate={maxEndDate} userDetails={userDetails} activeTab={activeTab} />
                </Tab>
                <Tab eventKey="scheduled-plan" title="Scheduled Plan">
                  <ScheduledPlanTab activeTab={activeTab} setReplaceData={setReplaceData} replaceData={replaceData} openReplace={openReplace} setOpenReplace={setOpenReplace} setAddData={setAddData} preview={preview} setPreview={setPreview} previewData={previewData} setPreviewData={setPreviewData} isConnected={isConnected} setIsConnected={setIsConnected} setCheck={setCheck} setRecentLength={setRecentLength} setRecentData={setRecentData} check={check} recentData={recentData} recentLength={recentLength} handleGetScheduled={handleGetScheduled} isLoading={isLoading} setIsLoading={setIsLoading} maxEndDate={maxEndDate} filterOptions={filterOptions} filterData={filterData} setFilterData={setFilterData} setActiveKey={setActiveKey} setPosts={setPosts} posts={posts} setEvents={setEvents} events={events} />
                </Tab>
                <Tab eventKey="history" title="History">
                  <PlannerHistoryTab activeTab={activeTab} preview={preview} setPreview={setPreview} previewData={previewData} setPreviewData={setPreviewData} performanceData={performanceData} setPerformanceData={setPerformanceData} />
                </Tab>
              </Tabs>
            </div>
          :
          <div className="container">
            <div className="post-plan-no-login-wrapper">
              <h3 className=" lato-light fs-48-32 black-242">Consistency is the <span className="fs-48-32 lato-bold black-242">Key!</span></h3>
              <h6 className=" lato-regular fs-18-12 black-4">Schedule posts in advance effortlessly, ensuring your online presence remains consistent and engaging, even during your busiest days.</h6>
              <p className=" lato-regular fs-14-11 black-4 mb-2">Watch demo video</p>
              <div className='planner-video-tag' >
                <iframe className="video-frame" width="100%" height="100%" src="https://www.youtube.com/embed/IJgngYNmPp0" frameBorder="0"></iframe>
              </div>
              <div>
                <Button className='lato-medium fs-14-12 grey-1D1 primary-btn' onClick={() => history.push({ pathname: "/onboarding" })}>Login to Get Started</Button>
              </div>
            </div>
          </div>
      }
    </section>
  );
}