import React, { useEffect, useState, useRef } from "react";
import { Image } from "react-bootstrap";
import close from '../../assets/images/greay-cancel-icon.svg';
import searchIcon from '../../assets/images/Search_1.svg';
import { useLocation } from "react-router-dom";
import { getSearchParams } from "../../utils/ReusableFunctions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getAllKeywordApi } from "../home/Api";
/* eslint-disable react-hooks/exhaustive-deps */

export default function SelectKeywords({ options = [], clearClicked = () => { }, getKeys = () => { }, navigate = () => { }, placeholder, isEvent = '' }) {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  let searchParams = getSearchParams(location);

  const [keyword, setKeyword] = useState((searchParams.searchValue ?? "") || "");
  const [option, setOption] = useState([]);
  const [hide, setHide] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [tempKeyword, setTempKeyword] = useState("");

  const itemRefs = useRef([]); // Ref to track each suggestion item

  useEffect(() => {
    setKeyword(searchParams.searchValue);
  }, [searchParams.searchValue]);

  useEffect(() => {
    getKeys(keyword);
    if (location.search.includes('searchValue=') && keyword === "") {
      const newUrl = location.search.replace(`searchValue=${params.get('searchValue')}&`, '');
      history.push({ search: newUrl });
    }
  }, [keyword]);

  useEffect(() => {
    if (!isEvent && keyword) {
      getAllKeywordApi(setOption, keyword);
    } else {
      setOption([]);
    }
    if (keyword !== searchParams.searchValue) {
      setHide(true);
    }
  }, [keyword]);

  useEffect(() => {
    if (highlightedIndex !== -1 && itemRefs.current[highlightedIndex]) {
      itemRefs.current[highlightedIndex].scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [highlightedIndex]);

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      if (highlightedIndex !== -1 && option[highlightedIndex]) {
        addData(option[highlightedIndex]);
      } else {
        getKeys(keyword);
        navigate();
      }
      setHide(false);
    } else if (e.key === "ArrowDown") {
      setHighlightedIndex((prevIndex) =>
        prevIndex < option.length - 1 ? prevIndex + 1 : 0
      );
      setTempKeyword(option[highlightedIndex + 1] || "");
    } else if (e.key === "ArrowUp") {
      setHighlightedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : option.length - 1
      );
      setTempKeyword(option[highlightedIndex - 1] || "");
    } else if (e.key === "Tab" && highlightedIndex !== -1 && option[highlightedIndex]) {
      addData(option[highlightedIndex]);
    }
  }

  function addData(data) {
    setKeyword(data);
    navigate(data);
    setHide(false);
    setTempKeyword("");
    setHighlightedIndex(-1);
    if (data === "") {
      clearClicked(true);
    }
  }

  return (
    <div className="img-form-group w-100">
      <div className="keywords-dropdown-frame">
        <div className="keywords-input-fields">
          <input
            type="text"
            placeholder={placeholder}
            autoComplete="off"
            className="input-box-keywords"
            name="keyword"
            inputmode="search"
            value={tempKeyword || keyword || ""}
            onChange={(e) => {
              setKeyword(e.target.value);
              setTempKeyword("");
              setHighlightedIndex(-1);
            }}
            onKeyDown={handleKeyPress}
          />
          {keyword && (
            <Image
              src={close}
              onClick={() => { addData(""); }}
              alt="cancel icon"
              className="cancel-field"
            />
          )}
          <Image
            onClick={() => { getKeys(keyword); navigate(); setHide(false); }}
            src={searchIcon}
            alt="search icon"
            className="search-input-field-icon"
          />
        </div>
        {option.length > 0 && keyword && hide && (
          <ul className="autocomplete-keywords-dropdown">
            {option.map((item, i) => (
              <li
                key={i}
                ref={(el) => itemRefs.current[i] = el} // Set each item in the ref array
                onClick={() => { addData(item); setHide(false); }}
                className={`lato-medium ${highlightedIndex === i ? "highlighted" : ""}`}
                style={{
                  backgroundColor: highlightedIndex === i ? "rgba(117, 117, 117, 0.1)" : "",

                }}
              >
                {item}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}