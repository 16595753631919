import React, { useEffect } from "react";
import { Dropdown, Image } from "react-bootstrap";
import dropDownIon from "../../assets/images/dropdown-icon2.png";
import languageImage from "../../assets/images/language-icon.svg";
import colorIcon from "../../assets/images/color-icon.svg";
import { useState } from "react";
export default function CustomDropDown({ label, options, getData, id = "unique", disabled = false, classes = '' }) {
  const [selectedValue, setSelectedValue] = useState(label);
  // console.log("Lable", disabled);
  // console.log("options"+ " "+ JSON.stringify(options));
  useEffect(() => {
    setSelectedValue(label);
  }, [label])
  function passDatacolor(data) {
    // console.log("In Drop Comp",data)
    getData(data);
    setSelectedValue(data);
  }
  function passDataLanguage(data) {
    getData(data);
    setSelectedValue(data.languageName);
  }
  function passNormalData(data) {
    getData(data);
    setSelectedValue(data.position);
  }
  // console.log(((selectedValue?.colorCode)??false))
  // console.log("In state:", ((label?.colorCode)??false));

  return (
    <div className={"main-dropdown-wrapper " + classes}>
      <Dropdown>
        <Dropdown.Toggle disabled={disabled} className="custom-dropdown-btn lato-medium fx-16 black-242" id="dropdown-basic">
          {selectedValue?.colorCode || label?.colorCode || selectedValue === "Color" ? (
            <>
              {((selectedValue?.colorCode) ?? false) || ((label?.colorCode) ?? false) ? (
                <>
                  <span
                    className="color-circle"
                    style={{ backgroundColor: selectedValue?.colorCode || label?.colorCode }}
                  ></span>{" "}
                  {selectedValue?.colorName || label?.colorName || "Color Name"}
                </>
              ) : (
                <>
                  <Image src={colorIcon} alt="colorimage" />&nbsp;{selectedValue || label || "Select Color"}
                </>
              )}
            </>
          ) : (id === 'normal') ? (
            <>
              {selectedValue || label || "Select Language"}
            </>
          )
            : (
              <>
                <Image src={languageImage} alt="languageimage" />&nbsp;{selectedValue || label || "Select Language"}
              </>

            )}
          <Image src={dropDownIon} className="drop-down-icon" alt="icon" />{" "}
        </Dropdown.Toggle>

        {Object.keys(options).length > 0 &&
          <Dropdown.Menu className="dropdown-show" data-popper-escaped={false}>
            <Dropdown.Item href="" className="p-0 bg-white">
              {/* {typeof (label) === "object" ? ( */}
              {id === "color" ? (
                <ul>
                  <label htmlFor={id} className="w-100">
                    {options && options?.map((item, i) => {
                      return (
                        <li
                          key={i}
                          onClick={() => passDatacolor(item)}
                          className="lato-regular fx-14 black-242"
                        >
                          <span
                            className="color-circle"
                            style={{ backgroundColor: item.colorCode }}
                          ></span>{" "}
                          {item.colorName}{" "}
                        </li>
                      );
                    })}
                  </label>
                </ul>
              ) : (id === 'normal') ? (
                <ul>
                  <label htmlFor={id} className="w-100">
                    {options && options?.map((item, i) => {
                      return (
                        <li
                          key={i}
                          onClick={() => passNormalData(item)}
                          className="lato-regular fx-14 black-242"
                        >
                          {item.position}{" "}
                        </li>
                      );
                    })}
                  </label>
                </ul>
              ) : (
                <ul>
                  <label htmlFor={id} className="w-100">
                    {options && options?.map((item, i) => {
                      return (
                        <li
                          key={i}
                          onClick={() => passDataLanguage(item)}
                          className="lato-regular fx-14 black-242"
                        >
                          {item.languageName}{" "}
                        </li>
                      );
                    })}
                  </label>
                </ul>
              )}
            </Dropdown.Item>
          </Dropdown.Menu>
        }

      </Dropdown>
    </div>
  );
}
