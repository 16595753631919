import CrossIcon from "../../assets/images/icon/cross-modal-icon.svg";
import ImageChecked from "../../assets/images/icon/tick-icon.svg";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Image, Modal, Row } from 'react-bootstrap';
import { isCurrentDateAllowed, MAX_LIMIT, START_PAGE, uploadFrequency } from "../../utils/Constant";
import DatePicker from "react-datepicker";
import { commonAuthApis } from "../profile/Api";
import moment from 'moment-timezone';
import { toast } from "react-toastify";
import { getPlaylistApi } from "./Api";
import { UseMediaQuery } from "./_utilis/useMediaQuery";
import { Spinner } from "../common/components";
import { getMinDate } from "./_utilis/platformsTime";
import { commonAuthPostApi, getAuthDataApi } from "../home/Api";
import { CustomModal } from "../common/pages";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import LockIcon from "../../assets/images/icon/lock-icon.svg"
import { getToken } from "../../utils/ManageToken";

/* eslint-disable eqeqeq */



export default function ViewPlaylistModal({ previewOpen, setPreviewOpen, playlistDetail, feeds, playlist, handlePlaylistSelect, isPostPlanner, dates, setDates, setPlaylistData, maxEndDate, user }) {

    const [startDate, setStartDate] = useState(() => {
        if (dates[playlistDetail.uuid]?.startDate) {
            return dates[playlistDetail.uuid].startDate;
        } else if (playlistDetail?.userPostPlanner?.startDate) {
            return new Date(playlistDetail.userPostPlanner.startDate);
        } else if (playlistDetail?.startDate) {
            return new Date(playlistDetail.startDate);
        }
        return null;
    });

    const [endDate, setEndDate] = useState(() => {
        if (dates[playlistDetail.uuid]?.endDate) {
            return dates[playlistDetail.uuid].endDate;
        } else if (playlistDetail?.userPostPlanner?.endDate) {
            return new Date(playlistDetail.userPostPlanner.endDate);
        } else if (playlistDetail?.endDate) {
            return new Date(playlistDetail.endDate);
        }
        return null;
    });

    const isMobile = UseMediaQuery('(max-width: 768px)');
    const [isLoading, setIsLoading] = useState(false)
    const [warning, setWarning] = useState(false);
    const [checklist, setChecklist] = useState([]);
    const history = useHistory();
    const [customizationType, setCustomizationType] = useState("auto")

    const handleClose = (() => {
        setPreviewOpen(false)
    });
    const handleCloseModal = (() => {
        setWarning(false);

    });
    const handleNavigate = (() => {
        history.push('/mymembership')

    })

    useEffect(() => {
        const token = getToken();
        if (token) {
            fetchData()
        }
    }, [])


    const fetchData = async () => {
        try {
            const response = await getAuthDataApi("website/playlist/content-types");

            if (response?.list) {
                setChecklist(response.list)

            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSubmit = async () => {
        if (isPostPlanner) {
            setIsLoading(true);

            const payload = {
                "data": {
                    "startDate": moment(startDate).tz('Asia/Kolkata').format('YYYY-MM-DDTHH:mm:ssZ'),
                    "endDate": moment(endDate).tz('Asia/Kolkata').format('YYYY-MM-DDTHH:mm:ssZ')
                }
            }
            const checkData = {
                "data": {
                    "playlists": [{

                        uuid: playlistDetail?.uuid,
                        startDate: payload?.data?.startDate,
                        endDate: payload?.data?.endDate
                    }]
                }

            }
            const check = await commonAuthPostApi(`website/userPostPlanner/checkSubscription`, checkData);
            if (check?.data?.pending >= 0) {

                const res = await commonAuthApis(`website/userPostPlanner/${playlistDetail?.userPostPlanner?.uuid}`, payload, "put");
                if (res) {
                    setIsLoading(false);
                    toast.success("Updated Successfully");
                    const data = await getPlaylistApi(`website/playlist?pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}`);
                    if (data) {
                        setPlaylistData(data?.data?.list);
                    }
                    handleClose();
                }
            } else {
                setWarning(true);
                setIsLoading(false);

            }
        } else {
            setDates(prevDates => {
                const updatedDate = {
                    ...prevDates,
                    [playlistDetail?.uuid]: {
                        ...prevDates[playlistDetail?.uuid],
                        startDate: startDate,
                        endDate: endDate
                    }
                };

                return updatedDate;
            });
            handleClose();

        }


    }

    return (
        <>
            <Modal
                size="lg"
                className="planner-custom-model"
                show={previewOpen}
                style={{}}
                onHide={() => {
                    handleClose();

                }}
            >
                <Modal.Body className="create-planner-body">
                    <div className="d-flex flex-row justify-content-between ">
                        <p className="lato-bold fx-24 black-242 ">View Playlist</p>
                        <div onClick={() => { handleClose() }} className="">
                            <Image className="create-cross-icon mt-2 mt-md-0" src={CrossIcon} alt="image" />
                        </div>
                    </div>
                    <Form >
                        <Row>
                            <Col md={6}>
                                <label htmlFor="planName">Plan Name</label>
                                <input
                                    type="text"
                                    id="planName"
                                    className={`form-control-modal lato-regular fx-14 mb-2
 `}
                                    value={playlistDetail?.title || ""}
                                    disabled
                                    style={{ cursor: "not-allowed" }}
                                // onChange={(e) => handleChange("planName", e.target.value)}
                                />
                            </Col>

                            <Col md={6} className="planner-custom-control mt-5 mt-md-0" >
                                <label htmlFor="uploadFrequency">Upload Frequency</label>
                                <input
                                    type="text"
                                    id="uploadFrequency"
                                    className={`form-control-modal lato-regular fx-14 mb-2
 `}
                                    value={uploadFrequency.find(freq => freq?.value == playlistDetail?.uploadFrequency)?.label || ""}
                                    disabled
                                    style={{ cursor: "not-allowed" }}
                                // onChange={(e) => handleChange("planName", e.target.value)}
                                />
                            </Col>




                            <Col md={12} className="customization mt-5">
                                <h4 className="lato-bold fx-18 black-242 py-0 ">Types of Customization</h4>

                                <div className="mb-3 d-flex flex-column flex-md-row ">
                                    <label className=" d-flex flex-row mb-md-0 mb-4">
                                        <input
                                            type="radio"
                                            name="customizationType"
                                            value="auto"
                                            checked={customizationType === 'auto'}
                                            onChange={(e) => setCustomizationType(e.target.value)}
                                            className="custom-radio"
                                            disabled
                                            style={{ cursor: 'not-allowed' }}

                                        />
                                        <h5 className="auto">
                                            <p className="lato-medium fx-14 black-242">Auto <span className="lato-regular fx-12 black-4">(Thefinpedia curated content)</span></p>
                                        </h5>
                                    </label>

                                    <label style={{ whiteSpace: 'nowrap' }} className=" d-flex flex-row align-items-center">
                                        <input
                                            type="radio"
                                            name="customizationType"
                                            value="manual"
                                            checked={customizationType === 'manual'}
                                            onChange={(e) => setCustomizationType(e.target.value)}
                                            className="custom-radio"
                                            disabled
                                            style={{ cursor: 'not-allowed' }}
                                        />
                                        <h5 style={{ whiteSpace: 'nowrap' }} className="auto d-flex flex-row align-items-center mb-0">
                                            <p className="lato-medium fx-14 black-242">Manual <span className="lato-regular fx-12 black-4 mb-0">(Custom Upload)</span></p>
                                            {!user?.isCustomUploadAllowed &&
                                                <div style={{ whiteSpace: 'nowrap' }} className="d-flex flex-row align-items-center">
                                                    <Image src={LockIcon} alt="icon" className="mx-2" />
                                                    <span className="lato-regular fx-14 primary mx-2 pointer mb-0">Upgrade Plan</span>
                                                </div>


                                            }
                                        </h5>

                                    </label>
                                </div>

                                <h5 className="auto-text lato-regular fx-14 black-242 op-5">
                                    {customizationType === 'manual'
                                        ? "You will be allowed to upload your own content from the scheduled plan tab once you schedule a planner here."
                                        : "Your posts are system curated. You will be allowed to view and edit the content list from the scheduled plan tab once you schedule a planner here."}
                                </h5>

                                <hr />
                            </Col>
                            <Col md={5} className="d-flex flex-row align-items-center">

                                <div className="form-group custom-date-range-picker mb-0 ">
                                    <label htmlFor="" className="form-label"> Duration of Plan </label>
                                    <div className="d-flex flex-row gap-10">
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) => { setStartDate(date) }}
                                            selectsStart
                                            // maxDate={endDate}
                                            maxDate={endDate || new Date(maxEndDate)}
                                            minDate={isCurrentDateAllowed ? new Date() : getMinDate()}
                                            isClearable={startDate}

                                            placeholderText="Start Date"
                                            className="form-control"
                                            showYearDropdown
                                            yearDropdownItemNumber={50}
                                            scrollableYearDropdown
                                            dateFormat={isMobile ? " d MMM , yyyy" : "d MMMM , yyyy"}
                                            onFocus={(e) => {
                                                e.target.blur()
                                                e.target.readOnly = true
                                            }}
                                        />
                                        <div className="d-none d-md-flex">

                                            <DatePicker
                                                selected={endDate}
                                                onChange={(date) => { setEndDate(date) }}
                                                selectsStart
                                                minDate={startDate || (isCurrentDateAllowed ? new Date : getMinDate())}
                                                maxDate={new Date(maxEndDate)}
                                                isClearable={!!endDate}
                                                placeholderText="End Date"
                                                className="form-control"
                                                showYearDropdown
                                                yearDropdownItemNumber={50}
                                                scrollableYearDropdown
                                                dateFormat={isMobile ? " d MMM , yyyy" : "d MMMM , yyyy"}
                                                onFocus={(e) => {
                                                    e.target.blur()
                                                    e.target.readOnly = true
                                                }}

                                            />
                                        </div>
                                        <div className="d-md-none">
                                            <DatePicker
                                                selected={endDate}
                                                onChange={(date) => { setEndDate(date) }}
                                                selectsStart
                                                minDate={startDate || (isCurrentDateAllowed ? new Date : getMinDate())}
                                                maxDate={new Date(maxEndDate)}
                                                isClearable={!!endDate}
                                                placeholderText="End Date"
                                                className="form-control"
                                                showYearDropdown
                                                yearDropdownItemNumber={50}
                                                scrollableYearDropdown
                                                dateFormat={isMobile ? " d MMM , yyyy" : "d MMMM , yyyy"}
                                                popperPlacement="right-start"
                                                onFocus={(e) => {
                                                    e.target.blur()
                                                    e.target.readOnly = true
                                                }}

                                            />
                                        </div>


                                    </div>
                                </div>


                            </Col>

                            <Col md={4}></Col>
                            {customizationType === 'auto' &&

                                <Col md={12} className="content-checkbox mt-5">
                                    <p className="lato-bold fx-18 black-242 py-0">Types of content to be included in planner</p>
                                    <h5 className="lato-regular fx-14 black-242 op-5">
                                        Types of content for selection will be available based on your preferred branding settings.
                                    </h5>
                                    <div className="form-checkbox">
                                        {checklist
                                            .filter(option =>
                                                playlistDetail.uploadFrequency == "" ||
                                                (playlistDetail.uploadFrequency == '0.00' && option.isAvailableCustomizedForPostPlanner) ||
                                                (playlistDetail.uploadFrequency != '0.00' && !option.isAvailableCustomizedForPostPlanner)
                                            )
                                            .map((option, index) => (
                                                <div key={index} className="form-checkbox-item">
                                                    <input
                                                        type="checkbox"
                                                        className="d-none"
                                                        id={`contentCheck${index}`}
                                                        checked={playlistDetail?.contentTypes && playlistDetail?.contentTypes.some(item => item.uuid === option.uuid)}
                                                        disabled
                                                        onChange={() => this.handleItemCheckboxChange(option)}

                                                    />
                                                    <label style={{ cursor: "not-allowed", border: "1px solid $grey-2 " }} className="cobrand-custom-checkbox-modal mb-0 mt-1" htmlFor={`contentCheck${index}`}>
                                                        {playlistDetail?.contentTypes && playlistDetail?.contentTypes.some(item => item.uuid === option.uuid) && (
                                                            <img style={{ cursor: "not-allowed" }} src={ImageChecked} alt="tick" className="d-block" />
                                                        )}
                                                    </label>
                                                    <p className=" platform-text lato-regular fx-14 black-242 mt-1">{option?.categoryName || option?.socialMediaName}
                                                    </p>

                                                </div>
                                            ))}
                                    </div>
                                    <hr />

                                </Col>
                            }
                            <Col md={12} className="content-checkbox ">
                                <h4 className="lato-bold fx-18 black-242">Select preferred Platforms</h4>
                                <div className="form-checkbox d-flex flex-md-row flex-column justify-content-between align-content-center text-center">
                                    {feeds.map((item, index) => (
                                        <div key={index} className="form-checkbox-item d-flex flex-row ">
                                            <input
                                                type="checkbox"
                                                className="d-none"
                                                id={`platformCheck${index}`}
                                                checked={playlistDetail?.platforms?.some(i => i.platform?.uuid === item?.uuid)}


                                            // onChange={() => this.handlePlatformCheckboxChange(platform)}
                                            />
                                            <label style={{ cursor: "not-allowed" }} className="cobrand-custom-checkbox-modal mb-0 mt-1" htmlFor={`platformCheck${index}`}>
                                                {playlistDetail?.platforms?.some(i => i.platform?.uuid === item?.uuid) && (
                                                    <img style={{ cursor: "not-allowed" }} src={ImageChecked} alt="tick" className="d-block " />
                                                )}
                                            </label>
                                            <div className="platform-image mt-1 ">
                                                <Image height={15} width={15} src={item?.iconImagePath} />
                                            </div>
                                            <p className=" platform-text lato-regular fx-14 black-242 "> {item?.platformName}</p>

                                        </div>
                                    ))}
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className=" create-modal-btn d-flex justify-content-center align-content-center">
                                    <Button disabled={!startDate || !endDate || isLoading} className="primary-btn" onClick={handleSubmit}>{isLoading ? <Spinner /> : "Save Changes"}</Button>
                                </div>
                            </Col>


                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
            <CustomModal
                show={warning}
                onHide={handleCloseModal}
                modalClass={"planner-heads-modal"}
            >
                <div className=" heading d-flex justify-content-between mb-4">
                    <p className=" lato-bold fs-20-16 red "> Heads Up!</p>
                    <Image src={CrossIcon} className='close-icon model-icon-close' alt='icon' style={{ cursor: "pointer" }} onClick={handleCloseModal} />
                </div>
                <p className="lato-medium fs-18-16 black-242 ">You do not have sufficient content usage available! Want to keep the service flowing? Simply Top Up or Upgrade now.</p>
                <div className="d-flex justify-content-center">
                    <Button onClick={handleNavigate} className="primary-btn">View Plans</Button>
                </div>
            </CustomModal>
        </>
    )
}