import React from "react";


export default function SearchEngin() {


  function navigateHandler(type) {
    window.location.href = `/collection-type/${type}`;
  }
  return (
    <div className="most-powerfull-engin">
      <div className="container">
        <h5 className=" fs-32-28 mb-11 lato-semibold green-5DF text-center">
          Most intelligent search engine on personal finance
        </h5>
        <p className="lato-regular fs-22-14 green-5DF mt-2 text-center ">
          Define your search based on{" "}
        </p>
        <div className="engin-btn-wrapper">
          <div onClick={() => navigateHandler('amcContentCreator')} className="engin-btn lato-regular fs-24-12 text-white pointer">Creator</div>
          <div onClick={() => navigateHandler('category')} className="engin-btn lato-regular fs-24-12 pointer text-white">
            Category{" "}
          </div>
          <div onClick={() => navigateHandler('campaign')} className="engin-btn lato-regular  fs-24-12 pointer text-white">
            Campaign{" "}
          </div>
          <div onClick={() => navigateHandler('language')} className="engin-btn lato-regular fs-24-12 pointer text-white">
            Language
          </div>
        </div>
      </div>
    </div>
  );
}
