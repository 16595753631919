import { createBroadcaster } from "@broadcaster/react";

// It is recommended to have only one Broadcaster instance per system per channel.
export const { useBroadcaster } = createBroadcaster({
    // All broadcasters with the same channel name will be able to communicate.
    channel: "SOCIAL_CHANNEL",
    metadata: {
        iteration: 0,
        currentTime: Date.now() // Will capture the current timestamp.
    },
});