import React, { useEffect, useState } from "react";

const RadioWithLabel = ({ options, onSelectionChange, lable, classes, initialValue = null, disabled = false }) => {
  const [selectedValue, setSelectedValue] = useState(initialValue);
  // console.log("desal", disabled)
  useEffect(() => {
    setSelectedValue(initialValue)
  }, [initialValue])
  return (

    <div>
      {lable && <h6 className="lato-regular fs-24-18 black-242">{lable}</h6>}
      <div className={`redio-squre-option-label ${classes}`}>
        {options?.map((option, i) => (
          <label
            key={i}
            className="custom-radio-label fs-18-14 lato-medium black-242"
          // style={{ minWidth: "25rem" }}
          >
            <input
              type="radio" //convert it checkbox in want ot uncheck 2nd clicked
              className="custom-radio-input"
              name={lable}
              disabled={disabled}
              value={option.value || option.position}
              checked={selectedValue === (option.value || option.position)}
              onChange={() => {
                onSelectionChange(option.value ? option.value : option);
                setSelectedValue(option.value || option.position);
              }}
            />
            <span className="custom-radio "></span>
            {option.label || option.position}
          </label>
        ))}
      </div>
    </div>
  );
};

export default RadioWithLabel;
