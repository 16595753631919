import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { getAuthDataApi } from '../home/Api';
import { useBroadcaster } from './_utils/useBroadcaster';


export default function SocialAuth() {
  const { platform } = useParams();
  const location = useLocation();
  const { message, postMessage } = useBroadcaster();


  useEffect(() => {
    const fetchData = async () => {
      const query = new URLSearchParams(location.search);
      const code = query.get('code');

      if (code) {

        const res = await getAuthDataApi(`website/auth/platform/${platform}/saveAuthToken?code=${code}`);
        if (res) {
          postMessage(true);

          window.close();

        }
      }
    };

    fetchData();
  }, [platform, location.search]);

  return (
    <div>
      Connecting to Social Media
    </div>
  );
}
