import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { API_LIMIT, SOCIALMEDIA, SOCIALMEDIA_URL_DASH, SOCIAL_MEDIA, START_PAGE } from "../../../utils/Constant";
import { getCommonApi } from "../Api";
/* eslint-disable array-callback-return */

export default function SocialMediaSection() {
  const history = useHistory()
  const [mediaName, setMediaName] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const settings = {
    dots: true,
    infinite: true,
    slidesToScroll: 4,
    // slidesToShow: 4,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 6500,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          centerMode: false,
          slidesToShow: 2,
          variableWidth: true,
          slidesToScroll: 2,

          // centerPadding: "60px",
        }
      },
      {
        breakpoint: 340,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          variableWidth: true,

        }
      },
    ]
  };


  async function featchData() {
    let data = await getCommonApi(SOCIALMEDIA_URL_DASH);
    if (data.status === 200) {
      setMediaName(data?.data?.data || []);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    featchData()
  }, [])
  function navigate(data) {

    history.push({
      pathname: `/content-type/All`,
      search: `?key=socialMedias&value=${encodeURIComponent(data?.socialMediaName)}&pageNumber=${START_PAGE}&pageSize=${API_LIMIT}`,
      state: data
    })
  }
  return (

    <div className="online-presence-section bg-ECE position-relative">
      <div className="container-mb position-relative">
        <Link to={`/${SOCIALMEDIA}`} className="lato-medium fx-14 black-242 home-explort-all pointer">Explore All</Link>
        <h4 className="fs-32-28 px-3 lato-semibold primary text-center">
          Elevate your digital presence
        </h4>
        <p className="fs-22-16 lato-regular black-242 text-center">
          Get daily social media content with your logo
        </p>
        <div className="social-media-card-frame">
          {isLoading ?
            <div className=" d-flex align-items-center justify-content-center-wrap gap-12 ">{
              [...Array(4)].map((item) => {
                return (
                  <div className="mx-2">
                    <div className="shimmer-frame socialmedia-card-shrim">&nbsp;</div>
                  </div>
                )
              })
            }</div>
            :
            <Slider className="home-socialmedia-slider" {...settings} >
              {
                mediaName?.map((item, i) => {
                  if (item.bannerPath) {
                    return (
                      <div key={i} className="social-image-frame">
                        {/* <div className={!item.bannerPath ? "rambo-image-wrapper" : ""}> */}
                        {/* {!item.bannerPath && <p className=" w-100 text-center lato-regular f-s-14">{item.socialMediaName}</p>} */}
                        {item.bannerPath &&
                          <Image className="social-image f-s-14" onClick={() => navigate(item)} src={item.bannerPath} alt={item.socialMediaName} />
                        }
                        {/* </div> */}
                      </div>
                    )
                  }

                })}
            </Slider>
          }
        </div>

      </div>
    </div>
  );
}
