import { Button, Dropdown, Image } from "react-bootstrap";
import ListIcon from "../../assets/images/icon/list-icon.svg";
import CalenderIcon from "../../assets/images/icon/calender-icon.svg";
import DotIcon from "../../assets/images/icon/dot-icon.svg";
import PlusIcon from "../../assets/images/icon/blue-plus-icon.svg";
import EditIcon from "../../assets/images/icon/edit-icon.svg";
import DeleteIcon from "../../assets/images/icon/delete-icon.svg";
import DangerIcon from "../../assets/images/icon/danger-icon.svg";
import { useState, useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import DeletePostModal from "./DeletePostModal";
import CalenderView from "./CalenderView";
import { getAuthDataApi } from "../home/Api";
import { groupData, groupDataByDate, isResOk } from "../../utils/ReusableFunctions";
import { formatDate } from "./_utilis/platformsTime";
import PreviewIcon from "../../assets/images/icon/preview-icon.svg";
import PreviewModal from "./PreviewModal";
import CrossIcon from "../../assets/images/icon/cross-modal-icon.svg"
import { CustomModal } from "../common/pages";
import UploadMediaModal from "./UploadMediaModal";
import { isCurrentDateAllowed } from "../../utils/Constant";
import UploadPlus from "../../assets/images/icon/upload-plus.svg"
import SocialPreferenceModal from "../profile/SocialPreferenceModal";
import { toast } from "react-toastify";
import { Spinner } from '../common/components';
import ImageChecked from "../../assets/images/icon/tick-icon.svg";
import DownIcon from "../../assets/images/dropdown-icon.svg";
import DeleteBlue from "../../assets/images/icon/delete-blue-icon.svg";
import { scheduleAuthPostApi } from "./Api";
import PremiumIcon from "../../assets/images/tags.svg";



export default function ScheduledPlanTab({ activeTab, setReplaceData, openReplace, setOpenReplace, setAddData, preview, setPreview, previewData, setPreviewData, isConnected, setIsConnected, setCheck, setRecentLength, setRecentData, check, recentData, recentLength, handleGetScheduled, isLoading, setIsLoading, maxEndDate, filterOptions, filterData, setFilterData, setActiveKey, setPosts, posts, setEvents, events }) {
  const [isOpen, setIsOpen] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [isOpenTwo, setIsOpenTwo] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const scheduledData = location?.state?.data || null;
  const [editData, setEditData] = useState();
  const [deleteData, setDeleteData] = useState();
  const [id, setId] = useState("");
  const [checkCount, setCheckCount] = useState(false);
  // Get the current type from the query parameters
  const queryParams = new URLSearchParams(location.search);
  const currentType = queryParams.get("type") || "list";
  const [warning, setWarning] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [upload, setUpload] = useState(false);
  const dropdownRef = useRef(null); // Create a ref for the dropdown
  const [show, setShow] = useState(false);
  const [selectedKey, setSelectedKey] = useState('Social');
  const [loadingPostId, setLoadingPostId] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState("")
  const [isFiltered, setIsFiltered] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [type, setType] = useState("");



  useEffect(() => {
    const handleClickOutside = (event) => {

      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false); // Close the dropdown
      }
    };


    document.addEventListener("mousedown", handleClickOutside);


    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleSelect = (eventKey) => {
    setSelectedPlan(eventKey);

    // Filter data based on the selected plan
    const filteredData = eventKey === "All"
      ? filterData
      : filterData.filter(item => item?.userPostPlanner?.playlist?.title === eventKey);

    // Group and set the filtered data
    setRecentData(groupDataByDate(filteredData));
    setEvents(groupData(filteredData))
    setPosts(filteredData)

    // Update selectedItems to keep only UUIDs from filteredData
    if (isSelectAll) {
      const data = eventKey === "All" || eventKey == ""
        ? filterData
        : filterData.filter(post => post?.userPostPlanner?.playlist?.title.toLowerCase() === eventKey.toLowerCase());
      // Step 2: Map to get UUIDs from the filtered data
      const allUuids = data.map(post => post.uuid);
      setSelectedItems(allUuids);


    }



  };

  const handleTabClick = (tab) => {
    queryParams.set("type", tab);
    history.push({ search: queryParams.toString() });
  };
  const handleEditOpen = () => {
    setShowEdit(true)
  };

  useEffect(() => {

  }, [])

  useEffect(() => {
    const isScheduledPlanTab = location.pathname === '/post-planner' && queryParams.get('tab') === 'scheduled-plan';
    if (isScheduledPlanTab && !queryParams.get("type")) {
      queryParams.set("type", "list");
      history.replace({ search: queryParams.toString() });
    }
  }, [location, queryParams, history]);

  const isScheduledPlanTab = location.pathname === '/post-planner' && queryParams.get('tab') === 'scheduled-plan';

  // Function to format the date

  const handlePost = async (e, post) => {
    e.preventDefault();
    setLoadingPostId(post?.uuid);

    const response = await scheduleAuthPostApi(`website/scheduledPostPlanner/post/direct/${post?.uuid}`);
    if (isResOk(response?.status)) {
      const res = await getAuthDataApi(`/website/userPostPlanner/recent`);
      if (res) {
        const groupedData = groupDataByDate(res);
        setRecentData(groupedData);
        setEvents(groupData(res));
        setLoadingPostId(null);
        setRecentLength(res?.length);


      }

      toast.success("Posted Successfully")
    }
    else {
      setLoadingPostId(null);
    }



  }

  // Function to group data by date
  const handleConnect = ((key) => {
    setSelectedKey(key);
    setShow(true);
  })

  const handleEdit = (post) => {
    setShowEdit(true);
    setEditData(post)
    setId(post?.uuid)
  };

  const handleDelete = (post) => {
    setShowDelete(true);
    setType("postDelete")
    setDeleteData(post.uuid)



  };
  const handleFilterDelete = () => {
    setShowDelete(true);
    setType("filterDelete")
  };

  const handleClose = (() => {
    setWarning(false);
  });
  const handleNavigate = () => {
    history.push('/mymembership')
  };
  const handleAddPost = (key, schedule) => {
    setOpenReplace(true);
    setReplaceData(schedule);
    setActiveKey(key);
  }
  const onHide = (() => {
    setShowDelete(false)
  })


  const handleCheckboxChange = (e, uuid) => {
    e.preventDefault();
    setSelectedItems((prevSelected) =>
      prevSelected.includes(uuid)
        ? prevSelected.filter((id) => id !== uuid)
        : [...prevSelected, uuid]
    );
  };

  const handleSelectAllChange = (e) => {
    e.preventDefault();
    if (isSelectAll) {
      setSelectedItems([]);
    } else {
      // Step 1: Filter data based on selected plan
      const filteredData = selectedPlan === "All" || selectedPlan == ""
        ? filterData
        : filterData.filter(post => post?.userPostPlanner?.playlist?.title.toLowerCase() === selectedPlan.toLowerCase());
      // Step 2: Map to get UUIDs from the filtered data
      const allUuids = filteredData.map(post => post.uuid);


      // Step 3: Set the selected UUIDs in state
      setSelectedItems(allUuids);

    }
    setIsSelectAll(!isSelectAll);


  };

  useEffect(() => {
    if (scheduledData) {
      const groupedData = groupDataByDate(scheduledData);
      setRecentData(groupedData);
    } else {
      handleGetScheduled()
    }

  }, [scheduledData, activeTab]);

  const handlePreview = (post) => {
    setPreview(true);
    setPreviewData(post);
  }
  const checkSubscription = async () => {
    const check = await getAuthDataApi("website/userPostPlanner/contentPendingActionCount");

    if (check == 0) {
      setCheckCount(true);
    }
  }
  useEffect(() => {
    checkSubscription()

  }, [recentData]);

  const handleUploadClick = () => {
    setUpload(true);

  };



  return (

    <div className="scheduled-plan-tab">
      <div className="scheduled-plan-heading d-flex flex-row justify-content-between">
        <h5 className="lato-regular fs-24-18 black-242">Modify your planner</h5>
        {isScheduledPlanTab && (
          <div className="schedule-btn-wrapper d-none d-md-block align-items-center ">
            <Button
              className={`scheduled-plan-btn lato-regular fs-14-11 black-242 ${currentType === "calendar" ? "active" : ""}`}
              onClick={() => handleTabClick("calendar")}

            >
              <Image style={{ marginRight: "5px" }} src={CalenderIcon} alt="icon" />
              Calendar
            </Button>

            <Button
              className={`scheduled-plan-btn lato-regular fs-14-11 black-242 ${currentType === "list" ? "active" : ""}`}
              onClick={() => handleTabClick("list")}

            >
              <Image style={{ marginRight: "5px" }} src={ListIcon} alt="icon" className="" />
              List
            </Button>
          </div>
        )}

        <div className="icons-wrapper d-flex flex-row d-md-none">
          {isScheduledPlanTab && (
            <>
              <div className={`icons-small d-flex align-items-center mx-2 ${currentType === "calendar" ? "active" : ""}`}>
                <Image
                  height={18}
                  width={18}
                  src={CalenderIcon}
                  alt="icon"
                  className="icon"
                  onClick={() => handleTabClick("calendar")}
                />
              </div>
              <div className={`icons-small d-flex align-items-center ${currentType === "list" ? "active" : ""}`}>
                <Image
                  height={18}
                  width={18}
                  src={ListIcon}
                  alt="icon"
                  className="icon"

                  onClick={() => handleTabClick("list")}
                />
              </div>
            </>
          )}
        </div>
      </div>

      <div className="scheduled-content">
        <p className="lato-regular fs-14-11 primary">
          <span className="lato-bold">Note:</span> You can only edit the scheduled post until 10:45 PM on the day preceding the scheduled date. Co-Brand/ White label feature will be utilized in this selected planner as per your <span className="lato-bold">Subscribed Plan.</span>
        </p>
      </div>
      {isConnected === false &&

        <div className="alert-danger d-flex flex-md-row flex-column justify-content-between" >
          <div className=" d-flex flex-row">
            <Image height={20} width={20} src={DangerIcon} alt="Danger Icon" />
            <div className="content-details d-flex flex-column">
              <p className="lato-medium fx-14 black-242 mb-0">
                Social Media handles not connected
              </p>
              <p className=" lato-regular fx-14 black-4 mt-2 mt-md-1">
                Connect your social media handles to start scheduling posts.
              </p>
            </div>
          </div>
          <Button onClick={() => { handleConnect('Social') }} className="primary-btn mt-2 mt-md-0">Connect now</Button>
        </div>

      }

      <div className="d-flex flex-row justify-content-between">
        <p className=" schedule-text lato-medium fs-18-14 black-242 ">Scheduled posts ({recentLength}) </p>
        <div onClick={() => { handleAddPost("Add") }} className=" d-flex align-items-center pointer" style={{ position: 'relative' }}>
          <p className="lato-bold fs-16-14 primary" >Add Post</p>
          <Image width={16} height={16} style={{ marginLeft: "8px" }} src={PlusIcon} />

        </div>
      </div >

      <div className="filter-wrapper d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex flex-row align-items-center">
          <div className="d-inline flex-row align-items-center mr-3">
            <label className=" checkbox-container lato-regular fs-14-12 black-242 d-flex flex-row">
              <input
                type="checkbox"
                // id={`create_card_id_${index}`}
                checked={isSelectAll}
                onChange={(e) => { handleSelectAllChange(e) }}
                // style={{ marginRight: '8px', marginTop: "0" }}
                className="d-none "
              />
              <label style={{ marginLeft: "0" }} onClick={(e) => { handleSelectAllChange(e) }} htmlFor={`create_card_id`} className={`cobrand-custom-checkbox bg-transparent mb-0 border-black`}>
                {isSelectAll && <Image src={ImageChecked} alt="checked" className='d-block' />}
              </label>

            </label>
          </div>

          <Dropdown className="feeds-card-dropdown " onSelect={handleSelect}>
            <Dropdown.Toggle className="dropdown-btn d-flex justify-content-between" id="dropdown-basic">

              <h6 className="lato-semibold fx-14 black-4 text-center"> {selectedPlan ? selectedPlan : "Filter by Plans"}</h6>
              <Image src={DownIcon} alt="image" className="dropdown-icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="show-filter-dropdown w-100">
              {filterOptions.map((option) => (
                <Dropdown.Item key={option} className="lato-regular fs-14-12 black-242" eventKey={option}>
                  {option}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>



        </div>
        {selectedItems && selectedItems?.length > 0 &&
          <div onClick={handleFilterDelete} className="d-flex flex-row align-items-center pointer">
            <p className="lato-medium fx-12 primary mr-2">Delete selected</p>
            <Image src={DeleteBlue} alt="icon" />

          </div>
        }

      </div>

      {isScheduledPlanTab && currentType === "list" ? (
        recentData?.length > 0 || !check ?
          <div className="schedule-cards d-flex flex-column">
            {recentData && recentData.map((schedule, index) => (
              <div key={index} className="d-flex flex-column">
                <div className="d-flex flex-row justify-content-between">
                  <div className="d-flex flex-row align-items-center date-wrapper">
                    <Image src={CalenderIcon} />
                    <p className="lato-regular black-242 fs-14-11">{schedule.date}</p>

                  </div>

                </div>
                <div className="d-flex flex-column scheduled-content-wrapper">
                  {schedule.content.map((post, postIndex) => (
                    <div key={postIndex} className="d-flex flex-column scheduled-item">
                      <div className="d-flex flex-row justify-content-between">
                        {!post?.isManual ?
                          <div className=" d-flex flex-row schedule-card-image align-items-center ">
                            <div className="d-inline flex-row justify-content-center align-items-center mr-3">
                              <label className="checkbox-container lato-regular fs-14-12 black-242 d-flex flex-row">
                                <input
                                  type="checkbox"
                                  checked={selectedItems?.includes(post.uuid)}
                                  onChange={(e) => handleCheckboxChange(e, post.uuid)}
                                  className="d-none"
                                />
                                <label
                                  style={{ marginLeft: "0" }}
                                  onClick={(e) => handleCheckboxChange(e, post.uuid)}
                                  htmlFor={`create_card_id_${postIndex}`}
                                  className={`cobrand-custom-checkbox bg-transparent mb-0 border-black`}
                                >
                                  {selectedItems?.includes(post?.uuid) && (
                                    <Image src={ImageChecked} alt="checked" className="d-block" />
                                  )}
                                </label>
                              </label>
                            </div>
                            {post?.content?.thumbnail ?
                              <div className="thumbnail-image">
                                <Image width={80} height={80} src={post?.content?.thumbnail} alt="image" />
                                <div className="preview-image">
                                  <Image className="view-detail" onClick={() => { handlePreview(post) }} width={20} height={20} src={PreviewIcon} alt="icon" />
                                </div>
                                {post?.content?.isPremium &&
                                  <div className="premium-icon">
                                    <Image className="view-detail" width={18} height={18} src={PremiumIcon} alt="icon" />
                                  </div>
                                }
                              </div>
                              :
                              <div className='card-plus '>
                                <h5 title={post?.caption} className="lato-bold fs-10-5 text-ellipsis-two black-242">
                                  {post?.caption}
                                </h5>
                                <div className="preview-image">
                                  <Image className="view-detail" onClick={() => { handlePreview(post) }} width={20} height={20} src={PreviewIcon} alt="icon" />
                                </div>
                                {post?.content?.isPremium &&
                                  <div className="premium-icon">
                                    <Image className="view-detail" width={18} height={18} src={PremiumIcon} alt="icon" />
                                  </div>
                                }
                              </div>

                            }

                          </div>
                          :
                          <div className=" schedule-card-image d-flex flex-row align-items-center">
                            <div className="d-inline flex-row align-items-center mr-3">
                              <label className=" checkbox-container lato-regular fs-14-12 black-242 d-flex flex-row">
                                <input
                                  type="checkbox"
                                  // id={`create_card_id_${index}`}
                                  checked={selectedItems?.includes(post.uuid)}
                                  onChange={(e) => handleCheckboxChange(e, post.uuid)}
                                  // style={{ marginRight: '8px', marginTop: "0" }}
                                  className="d-none "
                                />
                                <label style={{ marginLeft: "0" }} onClick={(e) => handleCheckboxChange(e, post.uuid)} htmlFor={`create_card_id`} className={`cobrand-custom-checkbox bg-transparent mb-0 mt-1 border-black`}>
                                  {selectedItems?.includes(post?.uuid) && <Image src={ImageChecked} alt="checked" className='d-block' />}
                                </label>

                              </label>
                            </div>
                            {
                              post?.scheduledPostPlannerContents?.[0] ? (
                                post?.scheduledPostPlannerContents[0].thumbnailPath ? (
                                  <div className="thumbnail-image">
                                    <Image
                                      width={80}
                                      height={80}
                                      src={post?.scheduledPostPlannerContents[0].thumbnailPath}
                                      alt="image"

                                    />
                                    <div className="preview-image">
                                      <Image
                                        className="view-detail"
                                        onClick={() => handlePreview(post)}
                                        width={20}
                                        height={20}
                                        src={PreviewIcon}
                                        alt="icon"

                                      />
                                    </div>
                                    {post?.content?.isPremium &&

                                      <div className="premium-icon">
                                        <Image className="view-detail" width={18} height={18} src={PremiumIcon} alt="icon" />
                                      </div>
                                    }
                                  </div>
                                ) : (
                                  <div className="upload-card-image">
                                    <Image
                                      className="pointer"
                                      onClick={() => handleAddPost("Edit", post)}
                                      src={UploadPlus}
                                      alt="plus-icon"
                                    />
                                  </div>
                                )
                              ) : (
                                <div className="upload-card-image">
                                  <Image
                                    className="pointer"
                                    onClick={() => { handleAddPost("Edit", post) }}
                                    src={UploadPlus}
                                    alt="plus-icon"
                                  />
                                </div>
                              )
                            }

                          </div>
                        }
                        <div className="d-flex flex-column justify-content-between scheduled-content-text">
                          <div className="planner-title-container">
                            <h5 className="lato-medium fs-14-11 black-242">{post?.caption}</h5>
                            <div style={{ cursor: (post?.isScheduled && !isCurrentDateAllowed) ? "not-allowed" : "pointer" }} className="d-flex flex-md-row flex-column align-items-center">
                              {isCurrentDateAllowed && post?.isPosted === false &&
                                <Button style={{
                                  cursor: loadingPostId === post?.uuid ? "not-allowed" : "pointer"
                                }} disabled={loadingPostId === post?.uuid} onClick={(e) => { handlePost(e, post) }} className="primary-btn mr-3 mb-2 mb-md-0 "> {loadingPostId === post.uuid ? <span className=' d-flex align-items-center gap-12 justify-content-center'><Spinner /> Posting...</span> : 'Post Now'} </Button>
                              }
                              <div>
                                <Image
                                  onClick={() => {
                                    if (!(post?.isScheduled && !isCurrentDateAllowed)) {
                                      handleAddPost("Edit", post);
                                    }
                                  }}

                                  width={18}
                                  height={18}
                                  src={EditIcon}
                                  style={{ opacity: (post?.isScheduled && !isCurrentDateAllowed) ? 0.5 : 1 }}
                                />
                                <Image
                                  onClick={() => {
                                    if (!(post?.isScheduled && !isCurrentDateAllowed)) {
                                      handleDelete(post);
                                    }
                                  }}
                                  className="icon-delete pointer"
                                  width={18}
                                  height={18}
                                  src={DeleteIcon}
                                  style={{ opacity: (post?.isScheduled && !isCurrentDateAllowed) ? 0.5 : 1 }}
                                />
                              </div>
                            </div>

                          </div>
                          <div className="d-flex flex-row">
                            {(post?.userPostPlanner?.endDate || post?.date) && (
                              <p className="tag-planner-text lato-medium black-242 op-5">
                                {post?.userPostPlanner?.playlist?.title && `${post.userPostPlanner.playlist.title} | `}
                                Ends on {formatDate(post?.userPostPlanner?.endDate || post?.date)}
                              </p>
                            )}

                            {/* <p className="tag-planner-text lato-medium black-242 op-5">{post.date}</p> */}

                            {post?.contentTypeName &&
                              <p className="tag-planner-text lato-medium black-242 op-5 d-none d-md-flex">{post?.contentTypeName} </p>}

                          </div>

                          <div className="d-flex flex-row justify-content-md-between flex-wrap ">
                            <div className="d-flex flex-column">
                              <div className="d-flex flex-row">

                                {post?.contentTypeName &&

                                  <p className="tag-planner-text lato-medium black-242 op-5 d-md-none">{post?.contentTypeName} </p>}

                                {/* <p className="lato-regular fs-10-8 black-4 d-md-none">Occasion</p> */}
                              </div>
                              <div>

                                <p className="lato-regular fs-10-8 black-4 d-flex text-center align-items-center">
                                  <Image height={8} width={8} src={DotIcon} className="dot-icon" />
                                  {post?.isScheduled ? "Scheduled" : "Scheduled"}
                                  {post?.occasionName ? <span className="mx-1"> | {post?.occasionName}</span> : ""}
                                </p>

                              </div>
                            </div>

                            <div className="d-md-flex flex-row d-none">
                              {post?.sheduledPostPlannerPlatforms.map((item) => (
                                <div key={item} className="social-icons-wrapper">
                                  <Image className="social-image" height={13} width={13} src={item?.platform?.iconImagePath} />
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-md-none flex-row d-flex">
                        {post?.sheduledPostPlannerPlatforms.map((item) => (
                          <div key={item} className="social-icons-wrapper">
                            <Image className="social-image" height={9} width={9} src={item?.platform?.iconImagePath} />
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="planner-break-line"></div>
              </div>
            ))}
          </div>
          :
          <div style={{ height: '40vh' }} className=" d-flex justify-content-center align-items-center text-center "> <p className="lato-regular fs-18-12 black-242">You have not scheduled any posts yet </p></div>
      ) : (
        isScheduledPlanTab && currentType === "calendar" && (
          <div className="">

            <CalenderView type={activeTab} openReplace={openReplace} setOpenReplace={setOpenReplace} setAddData={setAddData} setReplaceData={setReplaceData} onHide={onHide} events={events} setEvents={setEvents} posts={posts} setPosts={setPosts} setRecentData={setRecentData} setRecentLength={setRecentLength} />
          </div>
        )
      )
      }
      {/* <EditPostModal setRecentData={setRecentData} showEdit={showEdit} setShowEdit={setShowEdit} id={id} setId={setId} /> */}
      <DeletePostModal showDelete={showDelete} onHide={onHide} deleteData={deleteData} setRecentData={setRecentData} type={type} selectedItems={selectedItems} setSelectedItems={setSelectedItems} setIsSelectAll={setIsSelectAll} setSelectedPlan={setSelectedPlan} setEvents={setEvents} posts={posts} setPosts={setPosts} setRecentLength={setRecentLength} />
      <PreviewModal preview={preview} setPreview={setPreview} previewData={previewData} />
      <UploadMediaModal upload={upload} setUpload={setUpload} maxEndDate={maxEndDate} setShowDropdown={setShowDropdown} />
      <SocialPreferenceModal show={show} setShow={setShow} selectedKey={selectedKey} setSelectedKey={setSelectedKey} isConnected={isConnected} setIsConnected={setIsConnected} handleGetScheduled={handleGetScheduled} />

      <CustomModal
        show={warning}
        onHide={handleClose}
        modalClass={"planner-heads-modal"}
      >
        <div className=" heading d-flex justify-content-between mb-4">
          <p className=" lato-bold fs-20-16 red "> Heads Up!</p>
          <Image src={CrossIcon} className='close-icon model-icon-close' alt='icon' style={{ cursor: "pointer" }} onClick={handleClose} />
        </div>
        <p className="lato-medium fs-18-16 black-242 ">You do not have sufficient content usage available! Want to keep the service flowing? Simply Top Up or Upgrade now.</p>
        <div className="d-flex justify-content-center">
          <Button onClick={handleNavigate} className="primary-btn">View Plans</Button>
        </div>
      </CustomModal>
    </div >


  )
};
