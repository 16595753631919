import React from 'react';
import { Modal, Tabs, Tab, Image } from 'react-bootstrap';
import SocialContent from './SocialContent';
import CrossIcon from "../../assets/images/icon/cross-modal-icon.svg";
import PreferenceContent from './PreferenceContent';

export default function SocialPreferenceModal({ show, setShow, selectedKey, setSelectedKey, isConnected, setIsConnected, handleGetScheduled }) {

  const handleClose = () => {
    setShow(false)
    handleGetScheduled()
  };
  const handleSelect = (key) => setSelectedKey(key);

  return (
    <Modal
      className={selectedKey === 'Social' ? 'my-social-wrapper' : 'my-preferences-wrapper'}
      centered
      show={show}
      onHide={handleClose}
    >
      <div className='social-modal-header'>
        <Image height={24} width={24} src={CrossIcon} alt="close" onClick={handleClose} />
      </div>
      <Modal.Body>
        <Tabs
          defaultActiveKey="Social"
          activeKey={selectedKey}
          onSelect={handleSelect}
          class="trending-tabs"
        >
          <Tab eventKey="Social" title="My Socials">
            <div className='my-social-tabs'>
              <p className='lato-regular fs-14-12 primary'>
                <span className='lato-bold fs-14-12 primary'>Note:</span> If you've previously saved in ‘My Socials’ section, they will be automatically retrieved here. Any changes made to your settings here will also update your ‘‘My Socials’ section.
              </p>
              <SocialContent show={show} setIsConnected={setIsConnected} handleGetScheduled={handleGetScheduled} />

            </div>
          </Tab>
          <Tab eventKey="Preferences" title="My Preferences">
            <div className='my-preference-tabs'>
              <h2 className='lato-regular fs-14-12 primary'>
                <span className='lato-bold fs-14-12 primary'>Note:</span> If you've previously saved in ‘My Preferences’ section, they will be automatically retrieved here. Any changes made to your settings here will also update your ‘My Preferences’ section.
              </h2>
              <PreferenceContent />

            </div>
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
}
