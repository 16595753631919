import React from "react";
import DownIcon from "../../../assets/images/dropdown-icon.svg";
import { Dropdown, Image } from "react-bootstrap";
import { useEffect } from "react";
import { SORTABLE_URL } from "../../../utils/Constant";
import { useState } from "react";
import { getDataApi } from "../Api";
import { useHistory, useLocation } from "react-router";
export default function DropdownCommon({ title, clases }) {
  const location = useLocation();
  const history = useHistory()
  const params = new URLSearchParams(location.search)
  const [sortList, setSortList] = useState([]);
  const [sortitle, setSortitle] = useState("");

  useEffect(async () => {
    const sortValue = JSON.parse(sessionStorage.getItem("sortValue")) || [];
    // console.log("hi",sortValue)
    if (sortValue?.length < 1) {
      const data = await getDataApi(SORTABLE_URL);
      setSortList(data);
      sessionStorage.setItem("sortValue", JSON.stringify(data));
    } else {
      setSortList(sortValue);
    }
  }, [])

  function navigate(data) {

    setSortitle(data);
    let path = location.pathname;
    let searchParams = new URLSearchParams(location.search);
    searchParams.set("pageNumber", "1");
    searchParams.set("sortBy", data);
    let search = searchParams.toString();
    history.push({
      pathname: path,
      search: `?${search}`,
    });
  }
  return (
    <div className={`dropdown-main-wrapper ${clases}`}>
      <Dropdown>
        <Dropdown.Toggle className="dropdown-btn mb-gap-5" id="dropdown-basic">
          <h6 className="lato-semibold fx-14 black-242">
            {(params.get('sortBy') ?? false)
              ? params
                .get('sortBy')
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')
              : (params.get('searchValue') ?? false)
                ? "Most Relevant"
                : "Most Recent"}
          </h6>
          <Image src={DownIcon} alt="image" />
        </Dropdown.Toggle>


        <Dropdown.Menu className="show-filter-dropdown">
          {sortList && sortList?.map((item, i) => {
            return (
              <Dropdown.Item key={i} onClick={() => navigate(item?.key.toLowerCase())} className="lato-regular black-242 fx-14" href=''>{item.key.charAt(0).toUpperCase() + item?.key.slice(1)}</Dropdown.Item>
            )
          })}
          {/* <Dropdown.Item className="lato-regular black-242 " href=''>Most Liked</Dropdown.Item>
          <Dropdown.Item className="lato-regular black-242 " href=''>Most Viewed</Dropdown.Item> */}

        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
