import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Modal, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getAuthDataApi } from "../home/Api";
import { toast } from "react-toastify";
import CalenderIcon from "../../assets/images/icon/calender-icon.svg";
import CrossIcon from "../../assets/images/icon/cross-modal-icon.svg";
import PerformanceModal from "./PerformanceModal";
import PublishedIcon from "../../assets/images/icon/published-icon.svg";
import UnpublishedIcon from "../../assets/images/icon/unpublished-icon.svg";

const localizer = momentLocalizer(moment);

const HistoryCalendarView = ({ setPerformanceData, performanceData }) => {
  const [events, setEvents] = useState([
    {
      start: moment("2024-06-14").toDate(),
      end: moment("2024-06-14").add(1, "hour").toDate(),
      title: "1 post scheduled",
      color: "#270089"
    },

  ]);
  const [newEvent, setNewEvent] = useState({
    title: "",
    start: "",
    end: "",
    color: "#270089"
  });
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [scheduledData, setScheduledData] = useState(null);
  const [posts, setPosts] = useState([]);
  const [todayPosts, settodayPosts] = useState([]);
  const [showPerformance, setShowPerformance] = useState(false);



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEvent((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleAddEvent = () => {
    setEvents((prev) => [
      ...prev,
      {
        ...newEvent,
        start: moment(newEvent.start).toDate(),
        end: moment(newEvent.end).toDate()
      }
    ]);
    setNewEvent({ title: "", start: "", end: "", color: "#270089" });
    setShowModal(false);
  };

  const handleSelectSlot = ({ start }) => {
    const formattedStart = moment(start).format('YYYY-MM-DD'); // Format start date as needed
    const todaysPost = posts?.filter(post => moment(post.date).format('YYYY-MM-DD') === formattedStart);

    setShowModal(true);
    settodayPosts(todaysPost);

  };


  const handleEvent = (e) => {
    setNewEvent({
      title: e.title,
      start: moment(e.start).format('YYYY-MM-DDTHH:mm'),
      end: moment(e.end).format('YYYY-MM-DDTHH:mm'),
      color: e.color
    });
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setNewEvent({ title: "", start: moment().format('YYYY-MM-DDTHH:mm'), end: moment().format('YYYY-MM-DDTHH:mm') });
  };

  const Event = ({ event }) => {
    return (
      <span>
        <span className="event-dot" style={{ backgroundColor: event.color }} />
        {event.title}
      </span>
    );
  };

  const groupDataByDate = (data) => {
    return data.reduce((acc, item) => {
      const formattedDate = moment(item.date);
      const start = formattedDate.format('YYYY-MM-DD HH:mm:ss');
      const end = formattedDate.add(1, 'hour').format('YYYY-MM-DD HH:mm:ss');
      const isPosted = item.isPosted || false; // Assuming isPosted defaults to false if not provided
      const existingGroupIndex = acc.findIndex(group => group.start === start);

      if (existingGroupIndex !== -1) {
        if (isPosted) {
          acc[existingGroupIndex].type = 'published';
          acc[existingGroupIndex].postedCount += 1;
          acc[existingGroupIndex].title = `${acc[existingGroupIndex].postedCount} ${acc[existingGroupIndex].postedCount === 1 ? 'post published' : 'posts published'}`;
          acc[existingGroupIndex].color = '#5DFDCB';
        } else {
          acc[existingGroupIndex].type = 'unpublished';
          acc[existingGroupIndex].scheduledCount += 1;
          acc[existingGroupIndex].title = `${acc[existingGroupIndex].scheduledCount} ${acc[existingGroupIndex].scheduledCount === 1 ? 'post published' : 'posts unpublished'}`;
          acc[existingGroupIndex].color = '#D72630';
        }
        acc[existingGroupIndex].end = end;
      } else {
        const newGroup = {
          start: start,
          end: end,
          type: isPosted ? 'published' : 'unpublished',
          scheduledCount: isPosted ? undefined : 1,
          postedCount: isPosted ? 1 : undefined,
          title: isPosted ? '1 post published' : '1 post unpublished',
          color: isPosted ? '#5DFDCB' : '#D72630'
        };
        acc.push(newGroup);
      }

      return acc;
    }, []);
  };

  const handleGetHistory = async () => {
    setIsLoading(true)
    try {
      const res = await getAuthDataApi(`/website/userPostPlanner/history`);
      if (res) {
        const groupedData = groupDataByDate(res);

        setEvents(groupedData);
        setPosts(res);
      } else {
        toast.error("Failed to get data");
      }
    }
    catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };




  useEffect(() => {
    handleGetHistory();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const weekday = weekdays[date.getDay()];

    return `${day}-${month}-${year} | ${weekday}`;
  };

  const handlePerformance = (post) => {
    setShowPerformance(true);
    setPerformanceData(post)

  };


  return (
    <div className="calendar-view">
      <Calendar
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={events}
        style={{ height: "100vh" }}
        views={['month']}
        components={{
          event: Event,
          toolbar: null
        }}
        selectable
        onSelectSlot={handleSelectSlot}
      // onSelectEvent={handleEvent}
      />

      <Modal className="post-modal" show={showModal} centered onHide={handleClose}>
        <Modal.Body>
          {todayPosts.length > 0 && (
            <div className="d-flex flex-row justify-content-between align-items-baseline">
              <div className="d-flex flex-row align-items-center date-wrapper">
                <Image src={CalenderIcon} />
                <p className="lato-regular black-242 fs-14-11">{formattedDate(todayPosts[0]?.date)}</p>
              </div>
              <Image style={{ cursor: "pointer" }} onClick={handleClose} src={CrossIcon} />
            </div>
          )}
          {todayPosts.map((post, postIndex) => (
            <div key={postIndex} className="d-flex flex-column scheduled-item">
              <div className="d-flex flex-row justify-content-between">
                <div>
                  {post?.content?.thumbnail ? (
                    <Image width={80} height={80} src={post?.content?.thumbnail} alt="image" />
                  ) : (
                    <div className="card-plus">
                      <h5 title={post?.caption} className="lato-bold fs-10-5 text-ellipsis-two black-242">
                        {post?.caption}
                      </h5>
                    </div>
                  )}
                </div>
                <div className="d-flex flex-column justify-content-between scheduled-content-text">
                  <div className="planner-title-container">
                    <h5 className="lato-medium fs-14-11 black-242">{post?.caption}</h5>
                    <div className="d-flex">
                      {/* <Image style={{cursor:"pointer"}} onClick={() => handlePerformance(post)} width={18} height={18} src={AnalyticIcon} />  */}

                    </div>
                  </div>
                  <div className="d-flex flex-row">
                    {post?.userPostPlanner?.endDate &&
                      <p className="tag-planner-text lato-medium black-242 op-5">{post?.userPostPlanner?.playlist?.title} | Ends on {formatDate(post?.userPostPlanner?.endDate)}</p>}
                    <p className="tag-planner-text lato-medium fs-12-10 black-242 op-5 d-none d-md-flex">{post?.contentTypeName}</p>
                  </div>
                  <div className="d-flex flex-row justify-content-between flex-wrap mt-2">
                    <p className="tag-planner-text lato-medium black-242 op-5 d-md-none">{post.isScheduled}</p>
                    <p className="lato-regular fs-10-8 black-4 d-md-none">{post?.date}</p>
                    <p className="lato-regular fs-10-8 black-4 d-flex text-center align-items-center">
                      <Image height={8} width={8} src={post?.isPosted ? PublishedIcon : UnpublishedIcon} className="dot-icon" />
                      <span className="lato-medium  black-4">{post?.isPosted ? "Published" : "Unpublished"}</span>
                      {post?.occasionName ? <span className="mx-1"> | {post?.occasionName} </span> : ""}
                    </p>
                    <div className="d-md-flex flex-row d-none">
                      {post?.sheduledPostPlannerPlatforms.map((item) => (
                        <div key={item} className="social-icons-wrapper">
                          <Image className="social-image" height={13} width={13} src={item?.platform?.iconImagePath} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-md-none flex-row d-flex">
                {post?.sheduledPostPlannerPlatforms.map((item) => (
                  <div key={item} className="social-icons-wrapper">
                    <Image className="social-image" height={9} width={9} src={item?.platform?.iconImagePath} />
                  </div>
                ))}
              </div>
            </div>
          ))}

        </Modal.Body>
      </Modal>

      <PerformanceModal showPerformance={showPerformance} setShowPerformance={setShowPerformance} uuid={performanceData?.uuid} />



    </div>
  );
};

export default HistoryCalendarView;
