import React, { useState, useEffect } from "react";
import { getDataApi } from "../../profile/Api";
import { Image, Modal } from "react-bootstrap";
import search from "../../../assets/images/search.svg";
import { USER_TRACKING, UserTracking } from "../../../utils/Constant";
import { commonAuthPostApi } from "../../home/Api";
import close from "../../../assets/images/cancel-icon.svg";
import loadingSpinner from "../../../assets/images/loading-spinner.gif";
import { toast } from "react-toastify";
import Spinner from "./Spinner";

/* eslint-disable react-hooks/exhaustive-deps */



function SaveCollectionModal({ id, variationId, show, handleClose, collectionPopUp, isUnSaved = false, getStats = () => { } }) {
  const [searchValue, setSearchValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [collection, setCollection] = useState([]);
  const [collectionData, setCollectionData] = useState([]);
  const [isCollection, setIsCollection] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [add, setAdd] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemArr, setSelectedItemArr] = useState([]);



  async function fetchData() {
    setIsLoading(true);
    try {
      const data = await getDataApi('user-collection');

      const contentIncludes = data.data?.filter(item => item.items.some(subItem => subItem.content.uuid === id));

      setIsCollection(contentIncludes);
      setSelectedItemArr(contentIncludes.map(item => item.uuid));
      setCollection([...data.data].reverse());
      setCollectionData([...data.data].reverse());
    } catch (error) {
      // console.error("Error fetching data:", error);
      // Handle the error here
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {

    fetchData();
  }, []);

  function filterCollection(query) {
    setIsLoading(true);
    const filteredCollection = collectionData.filter((item) =>
      item.name.toLowerCase().includes(query.toLowerCase())
    );
    setCollection(filteredCollection);
    setNotFound(filteredCollection.length === 0);
    setIsLoading(false);
  }

  useEffect(() => {
    if (!add) {
      if (searchValue !== "") {
        filterCollection(searchValue);
      } else {
        setCollection(collectionData);
        setNotFound(false);
      }
    }
  }, [searchValue]);


  const handleListItemClick = (item) => {

    setSelectedItem(item);
    setSelectedValue(item.name);

    let isExist = selectedItemArr?.find((id) => id === item.uuid);

    if (isExist ?? false) {

      let removeData = selectedItemArr?.filter((id) => id !== item.uuid);
      setSelectedItemArr(removeData);
    } else {

      setSelectedItemArr([...selectedItemArr, item.uuid]);
    }

  };

  //  adding  new collection
  const addCollection = async () => {

    const info = {
      "data": {
        "name": searchValue
      }
    }
    const isMatch = collection?.some((item) => item.name === searchValue);
    // console.log("IsMatched", isMatch);
    if (searchValue && !isMatch) {
      const data = await commonAuthPostApi('user-collection', info);
      if (data) {
        fetchData();
        setAdd(false);
        setSearchValue("")
        // setSelectedItem(data);
      }
    } else {
      if (searchValue) {
        toast.error("This Collection Name is Exist")
      } else {
        toast.error("Input Field Notbe Empty")
      }
    }

  }
  async function saveCollectionHandler() {

    setIsSave(true)
    const info = {
      "data": {
        "contentUuid": id,
        "type": (isCollection?.length !== 0 ? UserTracking.UNSAVE : UserTracking.SAVED),
        "userCollection": selectedItemArr.map((item) => ({ 'uuid': item })),
        "contentVariationUuid": variationId,
      }
    }


    const data = await commonAuthPostApi(USER_TRACKING, info);
    if (data) {
      getStats();
    }


    setIsSave(false)

    const collectionNames = ((collectionData.filter((item) => selectedItemArr.includes(item.uuid))).map((item) => item.name)).join(', ');
    collectionPopUp({ 'id': id, "lable": false, 'unSaved': collectionNames, "selectedItem": !data.message ? ` ${collectionNames ? (`Saved to ` + collectionNames) : 'Removed from collection!'}` : data.message })


  }

  return (
    // <div className="save-collection-modal-container">
    <Modal className="save-collection-custom-model" centered show={show} onHide={handleClose}>
      <Modal.Header className="save-collection-model-header">
        <Modal.Title className="lato-regular fs-22-18 black-242">Save to:
          <Image src={close} onClick={handleClose} alt="canle icon" className="close-btn pointer" />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="save-collection-model-body">
        <div className="keywords-input-fields">
          <input
            type="text"
            placeholder={add ? "Add New Collection" : "Search collections"}
            autoComplete="off"
            className="input-box-search-collection fs-18-14 lato-semibold black-242 op-5 w-100"
            name="searchValue"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          {add ? <div onClick={addCollection} className="search icon fs-18-14 lato-semibold  add-colloectin-btn ">Add</div> :
            <Image
              onClick={() => { }}
              src={search}
              alt="search icon"
              className="search-input-field-icon"
            />}
        </div>
        <ul className="saveto-collection-list">
          <li className="lato-regular fs-22-18 black-242">
            Collections:
            <span
              className={`lato-regular fs-14-11 black-242 add-new-collection pointer`}
              onClick={() => {
                if (add) {
                  // If "add" mode is on, cancel the add action and reset states
                  setAdd(false);
                  setSearchValue("");
                  setSelectedItem(null); // Reset selectedItem to null properly
                } else {
                  // If not in "add" mode, toggle the add action
                  setAdd(true);
                  setSearchValue("");
                }
              }}
            >
              {add ? "cancel" : "Add New Collection"}
            </span>
          </li>
          {isLoading ? (
            <li className="w-100 d-flex align-items-center justify-content-center">
              <Image src={loadingSpinner} alt="Loading..." />
            </li>

          ) : notFound ? (
            <li className="lato-semibold fs-18-14 black-242 primary text-center">Collection is Not Found</li>
          ) : (
            collection.map((item, i) => (
              <li
                key={i}
                className={`lato-semibold fs-18-14 black-242 pointer ${selectedItemArr?.includes(item.uuid) ? "selected-item" : ""}`}
                onClick={() => handleListItemClick(item)}
              >
                {item.name}
              </li>
            ))
          )}
        </ul>
        <div className="d-flex justify-content-center pt-21">

          <div className={`primary-btn py-3 ${(selectedItemArr?.length > 0 || isCollection.length > 0) ? "pointer" : "disabled-btn"} `} onClick={(selectedItemArr?.length > 0 || isCollection.length > 0) ? saveCollectionHandler : () => { }}>{(isSave) ? <span className=" d-flex align-items-center gap-12"><Spinner /> Saving...  </span> : "Save"}</div>

        </div>
      </Modal.Body>
    </Modal>
    // </div>
  );
}


export default SaveCollectionModal;