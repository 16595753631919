import React, { useEffect, useRef, useState } from 'react'
import RangeFields from './components/RangeFields'
import BarChart from './components/BarChart'
import { CheckToolsSubscription, ShowShare, ToolsScreenshotHeader, captureImageHandler, debounce, formatCurrency, toolsDisclaimer, toolsInvestorDetails } from '../../utils/ReusableFunctions';
import { useMemo } from 'react';
export default function Lumpsum({ calculator, textLogo = '', imageData, setImageData, isDisable, setIsDisable, setAlertData, setIsAlert, setModalData }) {
  let [lumpsum, setLumpsum] = useState(5000000);
  let [afterYears, setAfterYears] = useState(8);
  let [roi, setRoi] = useState(12);
  let [investmentPV, setInvestmentPV] = useState(5000000);
  let [investmentFV, setInvestmentFV] = useState(12379816);
  useEffect(() => {
    onSubmitAction(roi, lumpsum, afterYears);
  }, [])
  // useEffect(() => {
  //   setLumpsum(lumpsum);
  //   setAfterYears(afterYears);
  //   setRoi(roi);
  //   setInvestmentPV(investmentPV);
  //   setInvestmentFV(investmentFV);
  // }, [lumpsum, roi, afterYears, investmentFV, investmentPV])

  const handleChange = (value, name) => {
    if (name === "roi") {
      roi = value
    }
    if (name === "lumpsum") {
      lumpsum = value
    }
    if (name === "afterYears") {
      afterYears = value
    }
    onSubmitAction(roi, lumpsum, afterYears);
  }


  function onSubmitAction(roi, lumpsum, afterYears) {
    roi = roi / 100;
    investmentPV = lumpsum
    investmentFV = Math.round(lumpsum * (1 + roi) ** (afterYears));
    setLumpsum(lumpsum)
    setRoi(roi * 100);
    setAfterYears(afterYears);
    setInvestmentPV(investmentPV);
    setInvestmentFV(investmentFV);
  };
  let downloadScreenshotFunc = null;

  const setDownloadScreenshotFunc = (func) => {
    downloadScreenshotFunc = func;
  };
  const barChart = useMemo(() => {
    return (
      <BarChart
        info={{
          labels: ["Total Invested Amount", "Total Invested Value"],
          data: [investmentPV, investmentFV],
        }}
      />
    );
  }, [investmentPV, investmentFV]);
  return (
    <div>
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART START ---------------------------------------------- */}
      <div className="calculator-result-main-wrapper">
        <div className="calculator-component-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Calculator
          </h4>


          <RangeFields
            label="Lumpsum Investment"
            name={"lumpsum"}
            value={lumpsum}
            minVal={500}
            maxVal={10000000}
            step={500}
            getValue={debounce(handleChange)}
            showComma />
          <RangeFields
            label="Expected Rate of Return"
            name={'afterYears'}
            value={afterYears}
            minVal={1}
            maxVal={50}
            unit="%"
            step={1}
            getValue={debounce(handleChange)}
          />
          <RangeFields
            label="Investment Tenure"
            name={"roi"}
            value={roi}
            minVal={1}
            maxVal={50}
            unit="Year"
            step={0.5}
            getValue={debounce(handleChange)}
          />
        </div>
        <div className="result-graph-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Results{" "}

            {<ShowShare calculator isDisable={isDisable} setIsDisable={setIsDisable} clickHandler={() => { CheckToolsSubscription(textLogo, downloadScreenshotFunc, calculator?.uuid, imageData, setIsDisable, setAlertData, setIsAlert, setModalData) }} />}

          </h4>
          <div className="result-graph-wrapper">
            <div className={`graph-1-frame ${false ? "w-auto" : "w-100"}`}>

              {barChart}
            </div>
          </div>
          {lumpsumResultText(lumpsum, roi, investmentFV, afterYears)}

        </div>
      </div>
      <div className="make-none">
        <CalculatorOutputScreenShot
          textLogo={textLogo}
          investmentPV={investmentPV}
          investmentFV={investmentFV}
          lumpsum={lumpsum}
          afterYears={afterYears}
          roi={roi}
          onDownloadScreenshot={setDownloadScreenshotFunc}
          setImageData={setImageData} />
      </div>
    </div>
  )
}

function CalculatorOutputScreenShot({ textLogo, investmentPV, investmentFV, roi, lumpsum, afterYears, onDownloadScreenshot, setImageData }) {
  const { cobrandingLabelling, investor } = textLogo;
  const [signup, setSignup] = useState(false);
  const screenshotRef = useRef(null);

  useEffect(() => {
    if (onDownloadScreenshot) {

      captureImageHandler(onDownloadScreenshot, screenshotRef, setSignup, setImageData);
    }
  }, [textLogo, screenshotRef, roi, lumpsum, afterYears, investmentFV, investmentPV]);
  return (
    <>

      {/* {signup && <SignUp show={signup} handleClose={() => setSignup(!signup)} />} */}

      <div ref={screenshotRef} className="tools-calulator-output-screenshot-frame ">
        {ToolsScreenshotHeader(cobrandingLabelling)}


        <div className="result-graph-wrapper">
          <div className={`graph-1-frame ${false ? "w-auto" : "w-100"}`}>
            <BarChart
              info={{
                labels: ["Total Invested Amount", "Total Invested Value"],
                data: [investmentPV, investmentFV],
              }}
            />
          </div>
        </div>
        {toolsInvestorDetails(investor)}
        {lumpsumResultText(lumpsum, roi, investmentFV, afterYears)}
        {toolsDisclaimer()}
      </div>

    </>
  )
};

function lumpsumResultText(lumpsum, roi, investmentFV, afterYears) {
  return (
    <p className=" f-s-16 lh-24 black-242 op-7 pt-2 text-center">
      With an initial Lumpsum investment of <span className=" lato-semibold primary">₹{formatCurrency(lumpsum)}</span> for <span className=" lato-semibold primary">{roi}</span> years your initial investment of <span className=" lato-semibold primary">₹{formatCurrency(lumpsum)}</span> will be valued at <span className=" lato-semibold primary">₹{formatCurrency(investmentFV)}</span>, if the invested corpus keeps growing at <span className=" lato-semibold primary">{afterYears}%</span> for the investment tenure.
    </p>

  )
}