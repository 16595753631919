import React, { useEffect } from 'react'
import { useState } from 'react';
import { formatCurrencyNumber } from '../../../utils/ReusableFunctions';

export default function RangeFields({ name = "", value = "", label = "", minVal = 0, maxVal = 100, step = 1, getValue = () => { }, unit = "", type = "range", disable = false, showComma }) {
  const [amount, setAmount] = useState(value);
  useEffect(() => {
    setAmount(value)
  }, [value])

  const handleChange = (e) => {
    let value = e.target.value;
    const numericValue = parseFloat(value.replace(/,/g, '')) || 0;
    if (numericValue > maxVal) {
      value = maxVal;
    } else if (numericValue < minVal) {
      value = minVal;
    } else {
      value = numericValue;
    }

    setAmount(value);
    getValue(value, e.target.name);
  };

  const calculatePercentage = (value, min, max) => {
    return ((value - min) / (max - min)) * 100;
  };
  const backgroundStyle = {
    background: `linear-gradient(to right, #270089 0%, #270089 ${calculatePercentage(amount, minVal, maxVal)}%, rgba(36, 46, 61, 0.2) ${calculatePercentage(amount, minVal, maxVal)}%, rgba(36, 46, 61, 0.2) 100%)`
  };

  function handleUint(ui, state) {
    switch (unit) {
      case "%":
        return ui + ' %';
      case 'Year':
        return ui + ' Year';
      default:
        if (state === "min") {
          return "₹" + formatCurrencyNumber(minVal)
        } else {
          return "₹" + formatCurrencyNumber(maxVal)
        }
    }

  }
  return (
    <div className="range-slider">
      <div className={`range-input-frame ${type}`}>
        <div className="range-lable-frame">
          <h3 htmlFor="sip-amount f-s-14 lh-18 black-242 lato-regular ">{label}</h3>
          {
            type === "range"
            &&
            <>
              <input
                // id={id}
                name={name}

                type={type}
                min={minVal}
                max={maxVal}
                value={amount}
                step={step}
                onChange={handleChange}
                className="slider input-custom-range"
                style={backgroundStyle}
              />
              <div className="min-max-frame">
                <h5 className=' lato-regular f-s-12 lh-17 black-242'>Min {handleUint(minVal, "min") || 0}</h5>
                <h5 className=' lato-regular f-s-12 lh-17 black-242'>Max {handleUint(maxVal, "max") || "Na"}</h5>
              </div>
            </>
          }
        </div>
        <div className={`input-show-text fx-14 lato-semibold black-242 ${type}`}>
          {
            showComma ? (
              <input
                type="text"
                value={amount.toLocaleString('en-IN')}
                name={name}
                onChange={handleChange}
                disabled={disable}
                className="amount-display fx-14 lato-semibold black-242"
              />
            ) : (
              <input
                type="number"
                value={amount}
                name={name}
                onChange={handleChange}
                disabled={disable}
                className="amount-display fx-14 lato-semibold black-242"
              />
            )
          }&nbsp;
          {unit || '₹'}
        </div>

      </div>
    </div>
  );

}

