
import React from 'react';
import { useState } from 'react';
import { Carousel, Image } from 'react-bootstrap';
// import logger from 'logging-library';
// import FileViewer from 'react-file-viewer';




const MultiViewer = ({ imageArr, contentFilePath }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="carousel-main-slider-frame overflow-hidden w-100 ">
      <Carousel activeIndex={index} onSelect={handleSelect}>
        {imageArr?.map((ele => {
          return (
            <Carousel.Item>
              <Image
                className="carousel-slider-images d-block "
                src={ele?.filePath}
                alt="First slide"
                onContextMenu={(e) => e.preventDefault()}
              />
              <Carousel.Caption>
                {/* <h3>First slide label</h3>
                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
              </Carousel.Caption>
            </Carousel.Item>
          )
        }))}

      </Carousel>
    </div>
  );
};

export default MultiViewer;
