import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Modal, Button, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import DotIcon from "../../assets/images/icon/dot-icon.svg";
import EditIcon from "../../assets/images/icon/edit-icon.svg";
import DeleteIcon from "../../assets/images/icon/delete-icon.svg";
import CalenderIcon from "../../assets/images/icon/calender-icon.svg";
import DeletePostModal from "./DeletePostModal";
import { groupData } from "../../utils/ReusableFunctions";
import { isCurrentDateAllowed } from "../../utils/Constant";
import PremiumIcon from "../../assets/images/tags.svg";


const localizer = momentLocalizer(moment);

const CalendarView = ({ openReplace, setOpenReplace, setAddData, setReplaceData, events, setEvents, posts, setPosts, setRecentData, setRecentLength }) => {

  const [newEvent, setNewEvent] = useState({
    title: "",
    start: "",
    end: "",
    color: "#270089"
  });

  const [showModal, setShowModal] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [scheduledData, setScheduledData] = useState(null);
  const [todayPosts, settodayPosts] = useState([]);
  const [editData, setEditData] = useState();
  const [deleteData, setDeleteData] = useState();
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [type, setType] = useState("");


  const onHide = (() => {
    setShowDelete(false)
    handleClose();
  });


  const handleSelectSlot = ({ start }) => {
    const formattedStart = moment(start).format('YYYY-MM-DD'); // Format start date as needed
    const todaysPost = posts?.filter(post => moment(post.date).format('YYYY-MM-DD') === formattedStart);

    setShowModal(true);
    settodayPosts(todaysPost);


  };

  const handleAddPost = (key, post) => {
    setOpenReplace(true);
    setReplaceData(post);


  }

  const handleClose = () => {
    setShowModal(false);
    setNewEvent({ title: "", start: moment().format('YYYY-MM-DDTHH:mm'), end: moment().format('YYYY-MM-DDTHH:mm') });
  };

  const Event = ({ event }) => {
    return (
      <span>
        <span className="event-dot" style={{ backgroundColor: event.color }} />
        {event.title}
      </span>
    );
  };



  useEffect(() => {

    if (scheduledData) {
      const groupedData = groupData(scheduledData);


      setEvents(groupedData);
    } else {
      // handleGetScheduled();
    }



  }, [scheduledData]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const weekday = weekdays[date.getDay()];

    return `${day}-${month}-${year} | ${weekday}`;
  };

  const handleEdit = (post) => {
    setShowEdit(true);
    setEditData(post)
  };

  const handleDelete = (post) => {
    setShowDelete(true);
    setDeleteData(post?.uuid)
    setReplaceData(post);
    setType("postDelete")

  };
  return (
    <div className="calendar-view">
      <Calendar
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={events}
        style={{ height: "100vh" }}
        views={['month']}
        components={{
          event: Event,
          toolbar: null
        }}
        selectable
        // onSelectSlot={handleSelectSlot}
        onSelectEvent={handleSelectSlot}
      />

      <Modal className="post-modal" show={showModal} centered onHide={handleClose}>
        <Modal.Body>
          {todayPosts.length > 0 && (
            <div className="d-flex flex-row justify-content-between align-items-baseline">
              <div className="d-flex flex-row align-items-center date-wrapper">
                <Image src={CalenderIcon} />
                <p className="lato-regular black-242 fs-14-11">{formattedDate(todayPosts[0]?.date)}</p>
              </div>

            </div>
          )}
          {todayPosts.map((post, postIndex) => (
            <div key={postIndex} className="d-flex flex-column scheduled-item">
              <div className="d-flex flex-row justify-content-between">
                <div className="thumbnail-image">
                  {post?.content?.thumbnail ? (
                    <Image width={80} height={80} src={post.content.thumbnail} alt="image" />
                  ) : post?.scheduledPostPlannerContents?.[0]?.thumbnailPath ? (
                    <Image width={80} height={80} src={post.scheduledPostPlannerContents[0].thumbnailPath} alt="image" />
                  ) : (
                    <div className="card-plus">
                      <h5 title={post?.caption} className="lato-bold fs-10-5 text-ellipsis-two black-242">
                        {post?.caption}
                      </h5>
                    </div>
                  )}
                  {post?.content?.isPremium &&
                    <div className="premium-icon">
                      <Image className="view-detail" width={18} height={18} src={PremiumIcon} alt="icon" />
                    </div>
                  }

                </div>
                <div className="d-flex flex-column justify-content-between scheduled-content-text">
                  <div className="planner-title-container">
                    <h5 className="lato-medium fs-14-11 black-242">{post?.caption}</h5>
                    <div style={{ cursor: (post?.isScheduled && !isCurrentDateAllowed) ? "not-allowed" : "pointer" }} className="d-flex">
                      <Image
                        onClick={() => {
                          if (!(post?.isScheduled && !isCurrentDateAllowed)) {
                            handleAddPost("Edit", post);
                          }
                        }}

                        width={18}
                        height={18}
                        src={EditIcon}
                        style={{ opacity: (post?.isScheduled && !isCurrentDateAllowed) ? 0.5 : 1 }}
                      />
                      <Image
                        onClick={() => {
                          if (!(post?.isScheduled && !isCurrentDateAllowed)) {
                            handleDelete(post);
                          }
                        }}
                        className="icon-delete"
                        width={18}
                        height={18}
                        src={DeleteIcon}
                        style={{ opacity: (post?.isScheduled && !isCurrentDateAllowed) ? 0.5 : 1 }}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-md-row flex-column">
                    {post?.userPostPlanner?.endDate &&

                      <p className="tag-planner-text lato-medium fs-12-10 black-242 op-5">{post?.userPostPlanner?.playlist?.title} | Ends on {formatDate(post?.userPostPlanner?.endDate)}</p>
                    }

                    <p className="tag-planner-text lato-medium black-242 op-5 mt-2 mt-md-0 ">{post?.contentTypeName} </p>

                  </div>
                  <div className="d-flex flex-row justify-content-between flex-wrap mt-2">
                    <p className="lato-regular fs-10-8 black-4 d-flex text-center align-items-center">
                      <Image height={8} width={8} src={DotIcon} className="dot-icon" />
                      <span className="lato-medium black-4">{post?.isScheduled ? "Scheduled" : "Scheduled"}</span>
                      {post?.occasionName ? <span className="mx-1"> | {post?.occasionName}</span> : ""}
                    </p>
                    <div className="d-md-flex flex-row d-none">
                      {post?.sheduledPostPlannerPlatforms.map((item) => (
                        <div key={item} className="social-icons-wrapper">
                          <Image className="social-image" height={13} width={13} src={item?.platform?.iconImagePath} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-md-none flex-row d-flex mt-2">
                {post?.sheduledPostPlannerPlatforms.map((item) => (
                  <div key={item} className="social-icons-wrapper">
                    <Image className="social-image" height={9} width={9} src={item?.platform?.iconImagePath} />
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div className="d-flex justify-content-center">
            <Button className="primary-btn" variant="primary" onClick={handleClose}>
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <DeletePostModal setRecentData={setRecentData} showDelete={showDelete} onHide={onHide} deleteData={deleteData} type={type} openReplace={openReplace} setOpenReplace={setOpenReplace} setEvents={setEvents} posts={posts} setPosts={setPosts} setRecentLength={setRecentLength} />


    </div>
  );
};

export default CalendarView;
