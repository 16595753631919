import { Button, Dropdown, Image } from "react-bootstrap";
import DownIcon from "../../assets/images/dropdown-icon.svg";
import PostIcon from "../../assets/images/icon/post-icon.svg";
import YoutubeIcon from "../../assets/images/icon/youtube-icon.svg";
import PostPlannerCard from "./PostPlannerCard";
import CustomPlanner from "../../assets/images/custom-planner.png";
import { useEffect, useState } from "react";
import { deletePostApi, getPlaylistApi } from './Api';
import { MAX_LIMIT, SOCIALMEDIA_URL, START_PAGE } from '../../utils/Constant';
import { uploadFrequencyNew as uploadFrequencyOptions } from '../../utils/Constant';
import { commonAuthPostApi, getCommonApi } from "../home/Api";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import CrossIcon from "../../assets/images/icon/cross-modal-icon.svg"
import { CustomModal } from "../common/pages";
import { Spinner } from "../common/components";
import moment from 'moment-timezone';
import ImageChecked from "../../assets/images/icon/tick-icon.svg";
import DisclaimerIcon from "../../assets/images/icon/disclaimer-icon.svg";


const addPLannerData = { thumbnailPath: CustomPlanner, title: "Create customized plan" };

export default function CreatePlanTab({ open, setOpen, activeTab, setActiveTab, feeds, checklist, setChecklist, fetchContent, maxEndDate, userDetails }) {
  const [selectedKeys, setSelectedKeys] = useState("");
  const [selected, setSelected] = useState("");
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedPlaylists, setSelectedPlaylists] = useState([]);
  const [playlistData, setPlaylistData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [duration, setDuration] = useState("");
  const [durationOptions, setDurationOptions] = useState([]);
  const history = useHistory();
  const [playlist, setPlaylist] = useState([]);
  const [warning, setWarning] = useState(false);
  const [deletePost, setDeletePost] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isComplaince, setIsComplaince] = useState(true);
  const [dates, setDates] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [disclaimerOpen, setDisclaimerOpen] = useState(false);

  useEffect(() => {
    fetchData()
  }, [open, activeTab])

  const handleClose = (() => {
    setWarning(false);
  })

  const handleDelete = (() => {
    setDeletePost(false);
  })
  const handleModalClose = (() => {
    setModalOpen(false);
    setDisclaimerOpen(false);
  })

  const handleDisclaimer = (() => {
    setDisclaimerOpen(true);
  })

  const handleNavigate = () => {
    history.push('/mymembership')
  }
  async function fetchData() {
    setIsLoading(true);
    try {
      const queryParams = [];

      if (selected) {
        queryParams.push(`platforms=${selected}`);
      }

      if (duration && duration != "20") {

        queryParams.push(`uploadFrequencies=${duration}`);
      }

      const queryString = queryParams.length > 0 ? `&${queryParams.join('&')}` : '';
      const data = await getPlaylistApi(`website/playlist?pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}${queryString}`);

      setPlaylistData(data?.data?.list);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }
  const fetchPlatform = async () => {
    try {
      const response = await getCommonApi(SOCIALMEDIA_URL);
      if (response.status === 200) {
        setPlaylist(response?.data?.data)

      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const getUploadFrequencyLabel = (value) => {
    const frequency = uploadFrequencyOptions.find(option => option.value === value);
    return frequency ? frequency.label : "";
  };
  useEffect(() => {
    fetchPlatform()
  }, [])
  const handleCheckboxChange = (e) => {
    e.preventDefault();
    setIsComplaince(!isComplaince);
  };

  const handleDiscontinue = async (e) => {
    e.preventDefault();
    const res = await deletePostApi(`website/userPostPlanner/${deleteData}`)
    if (res) {
      handleDelete();
      fetchData();

      setSelectedPlaylists([]);
      setIsComplaince(false);

    }
  }
  const orderedLabels = ["Daily", "Weekly", "Bi-Weekly", "Fortnightly", "As and When"];

  const reorderedDurationOptions = uploadFrequencyOptions.sort((a, b) => {
    if (a.label === "All") return -1; // Move "All" to the top
    if (b.label === "All") return 1;

    // Sort according to the defined order
    return orderedLabels.indexOf(a.label) - orderedLabels.indexOf(b.label);
  });



  async function handleSaveScheduled(e) {
    e.preventDefault();
    setIsLoading(true);
    for (const playlist of selectedPlaylists) {
      if (!playlist.startDate || !playlist.endDate) {
        toast.error("Please enter the start and end dates to proceed.");
        setIsLoading(false);
        return;
      }
    }
    const playlistData = {
      "data": {
        "playlists": selectedPlaylists
      }
    };

    try {

      const check = await commonAuthPostApi(`website/userPostPlanner/checkSubscription`, playlistData);


      if (check?.data?.pending >= 0) {
        const res = await commonAuthPostApi(`website/userPostPlanner`, playlistData);
        if (res.data) {
          history.push({
            path: "/post-planner?tab=scheduled-plan&type=list",
            state: {
              data: res.data
            }
          })
          setActiveTab("scheduled-plan")
          // setIsLoading(false);
          toast.success("Added Successfully");
          fetchData();
          setSelectedPlaylists([]);
          setIsComplaince(false);
        } else {
          toast.error("Failed to add plan");
        }

      } else {
        setWarning(true)
      }



    } catch (error) {
      // console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {

  }, [selectedPlaylists]);

  useEffect(() => {
    fetchData();
  }, [selected, duration]);

  function activeHandler(item) {
    setSelected(item);
  }

  const handleSelect = (eventKey) => {
    setDuration(eventKey);

  };


  const handlePlaylistSelect = (uuid, updatedDates = dates) => {
    setSelectedPlaylists((prev) => {
      const playlist = prev.find(item => item.uuid === uuid);
      if (!playlist) {
        return prev; // UUID not found, return the previous state without adding it
      }

      const { startDate, endDate } = updatedDates[uuid] || {};
      const convertedStartDate = startDate
        ? moment(startDate).tz('Asia/Kolkata').format('YYYY-MM-DDTHH:mm:ssZ')
        : null;
      const convertedEndDate = endDate
        ? moment(endDate).tz('Asia/Kolkata').format('YYYY-MM-DDTHH:mm:ssZ')
        : null;

      return prev.map(item =>
        item.uuid === uuid
          ? { ...item, startDate: convertedStartDate, endDate: convertedEndDate }
          : item
      );
    });
  };

  const handleUuidSelect = (uuid, updatedDates = dates) => {
    setSelectedPlaylists((prev) => {
      const { startDate, endDate } = updatedDates[uuid] || {};
      const convertedStartDate = startDate
        ? moment(startDate).tz('Asia/Kolkata').format('YYYY-MM-DDTHH:mm:ssZ')
        : null;
      const convertedEndDate = endDate
        ? moment(endDate).tz('Asia/Kolkata').format('YYYY-MM-DDTHH:mm:ssZ')
        : null;

      if (prev.some(item => item.uuid === uuid)) {
        return prev.filter((item) => item.uuid !== uuid);
      } else {
        return [...prev, { uuid, startDate: convertedStartDate, endDate: convertedEndDate }];
      }
    });
  };

  const handlePostSelect = (data) => {
    setDeletePost(true);
    setDeleteData(data?.userPostPlanner?.uuid)
  }

  const handleUploadDurationChange = (uuid, uploadDuration) => {
    setSelectedPlaylists((prev) =>
      prev.map((item) =>
        item.uuid === uuid ? { ...item, uploadDuration } : item
      )
    );
  };

  return (
    <div className="create-plan-tab py-4 py-md-5">
      <div className="post-planner-detail d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex flex-row">
          <h6 className="planner-text-start lato-regular fs-20-16 black-4">
            Boost your social media engagement effortlessly! Watch this video to learn how!
          </h6>
          <div className="youtube-icon d-flex justify-content-center">
            <Image src={YoutubeIcon} alt="icon" />
          </div>
        </div>
        <div onClick={handleDisclaimer} className="d-flex flex-row align-items-center planner-disclaimer pointer ">
          <Image className="disclaimer-icon" width={16} height={16} src={DisclaimerIcon} alt="icon" />
          <p className="lato-medium fs-14-12 black-242 d-none d-md-flex">Disclaimer</p>

        </div>
      </div>


      <div className="d-flex flex-column">
        <div className="">
          <h4 className="lato-semibold fs-32-24 black-242">Select plan according to your requirement</h4>

        </div>
        <div className=" flex-row justify-content-between d-none d-md-flex">
          <div className="d-inline flex-row align-items-center">
            <label style={{ marginTop: "1.8rem" }} className=" checkbox-container lato-regular fs-14-12 black-242 d-flex flex-row">
              <input
                type="checkbox"
                // id={`create_card_id_${index}`}
                checked={isComplaince}
                onChange={handleCheckboxChange}
                // style={{ marginRight: '8px', marginTop: "0" }}
                className="d-none  "
              />
              <label style={{ marginLeft: "0" }} onClick={handleCheckboxChange} htmlFor={`create_card_id`} className={`cobrand-custom-checkbox bg-transparent mb-0 mt-1 border-black`}>
                {isComplaince && <Image src={ImageChecked} alt="checked" className='d-block' />}
              </label>
              <div className="d-inline flex-row flex-wrap mt-md-1 mt-0 mx-2">
                Compliance Undertaking: I agree not to alter AMC content and accept full responsibility for any changes. <span onClick={(e) => { setModalOpen(true); e.preventDefault() }} className="lato-bold fs-14-12 primary pointer"> Learn more.</span>
              </div>
            </label>
          </div>
          <div className="post-planner-button ">
            <Button onClick={(e) => { handleSaveScheduled(e) }} disabled={selectedPlaylists.length === 0 || !isComplaince || isLoading} className="primary-btn d-none d-lg-flex">{isLoading ? <Spinner /> : 'Next'}</Button>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between ">
          <div className="planner-feeds-wrapper feeds-wrapper flex-row align-items-center d-none d-lg-flex">
            <p className='lato-regular black-242 op-6 fs-18-16 desk-show'>Filter by Platforms:</p>
            <h4
              onClick={() => setSelected("")}
              className={`Social-media-frame fs-14-12 lato-bold black-242 ${selected === "" ? "active-feed" : 'bg-white'}`}>
              All
            </h4>
            {feeds?.map((item, i) => (
              <h4 key={i} onClick={() => activeHandler(item?.platformName)} className={`Social-media-frame fs-14-12 lato-bold black-242 ${selected === item.platformName ? "active-feed" : ''}`}>
                <Image height={20} width={20} src={item?.iconImagePath} alt='icon' />
                <span className='lato-medium fs-14-12 black-1D1'>{item?.platformName}</span>
              </h4>
            ))}
          </div>

          <div className="planner-feeds-wrapper feeds-wrapper flex-row align-items-center d-md-flex d-lg-none">
            <div className="d-flex flex-row">
              <p className='lato-regular black-242 op-6 fs-12-10 mx-1'>Filter by: </p>
              <h4
                onClick={() => {
                  setSelected("")
                }} className={`Social-media-frame fs-14-10 lato-bold black-242 ${selected === "" ? "active-feed" : 'bg-white'}`}>
                All
              </h4>
              <div className="d-flex flex-row social-icons">
                {feeds?.map((item, i) => (
                  <h4 key={i} onClick={() => activeHandler(item?.platformName)} className={`Social-media-frame fs-14-12 lato-bold black-242 ${selected === item?.platformName ? "active-feed" : ''}`}>

                    <Image className="feed-image" key={i} height={9} width={9} src={item?.iconImagePath} alt='icon' />
                  </h4>
                ))}
              </div>
            </div>
          </div>
          <Dropdown className="feeds-card-dropdown " onSelect={handleSelect}>
            <Dropdown.Toggle className="dropdown-btn py-3 d-flex justify-content-between" id="dropdown-basic">
              <Image className="dropdown-image" width={20} height={20} src={PostIcon} alt="image" />
              <h6 className="lato-semibold fx-14 black-4">{duration ? getUploadFrequencyLabel(duration) : "Frequency"}</h6>
              <Image src={DownIcon} alt="image" className="dropdown-icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="show-filter-dropdown w-100">
              {reorderedDurationOptions.map((option) => (
                <Dropdown.Item key={option.value} className="lato-regular fs-14-12 black-242" eventKey={option.value}>
                  {option.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="planner-cards-main">
          <div className="row">
            <div className="col-6 col-md-4 col-lg-3 planner-cards-wrapper">
              <PostPlannerCard
                handleClick={() => setOpen(true)}
                data={addPLannerData}
                index={-1}
                selectedCard={selectedCard}
                setSelectedCard={setSelectedCard}
                handlePlaylistSelect={handlePlaylistSelect}
                selectedPlaylists={selectedPlaylists}
                handleUploadDurationChange={handleUploadDurationChange}
                fetchData={fetchData}
                feeds={feeds}
                playlist={playlist}
                dates={dates}
                setDates={setDates}
                checklist={checklist}
                setChecklist={setChecklist}
                fetchContent={fetchContent}
                maxEndDate={maxEndDate}
                handleUuidSelect={handleUuidSelect}
                userDetails={userDetails}



              />
            </div>
            {playlistData && playlistData?.map((data, i) => (
              <div key={i} className="col-6 col-md-4 col-lg-3 planner-cards-wrapper">
                <PostPlannerCard
                  data={data}
                  index={i}
                  selectedCard={selectedCard}
                  setSelectedCard={setSelectedCard}
                  handlePlaylistSelect={handlePlaylistSelect}
                  handlePostSelect={handlePostSelect}
                  selectedPlaylists={selectedPlaylists}
                  handleUploadDurationChange={handleUploadDurationChange}
                  isDynamic={true}
                  feeds={feeds}
                  playlist={playlist}
                  dates={dates}
                  setDates={setDates}
                  setPlaylistData={setPlaylistData}
                  checklist={checklist}
                  setChecklist={setChecklist}
                  fetchContent={fetchContent}
                  maxEndDate={maxEndDate}
                  handleUuidSelect={handleUuidSelect}
                  userDetails={userDetails}
                />
              </div>
            ))}
            <div className="d-flex d-md-none" style={{ height: "35rem" }}></div>
          </div>
        </div>
      </div>

      <div className="sticky-btn d-flex flex-column d-md-none">
        <div className="d-inline flex-row align-items-center">
          <label style={{ marginTop: "1.8rem" }} className=" checkbox-container lato-regular fs-14-12 black-242 mt-4 d-flex flex-row">
            <input
              type="checkbox"
              checked={isComplaince}
              onChange={handleCheckboxChange}
              className="d-none  "
            />
            <label style={{ marginLeft: "0" }} onClick={handleCheckboxChange} htmlFor={`create_card_id`} className={`cobrand-custom-checkbox bg-transparent mb-0 mt-3 border-black`}>
              {isComplaince && <Image src={ImageChecked} alt="checked" className='d-block' />}
            </label>
            <div className="d-inline flex-row flex-wrap mt-md-1 mt-0 mx-2">
              <p>Compliance Undertaking: I agree not to alter AMC content and accept full responsibility for any changes.<br /> <span onClick={(e) => { setModalOpen(true); e.preventDefault() }} className="lato-bold fs-14-12 primary pointer"> Learn more.</span></p>
            </div>
          </label>
        </div>
        <Button disabled={selectedPlaylists.length === 0 || !isComplaince || isLoading} onClick={(e) => { handleSaveScheduled(e) }} className="primary-btn d-lg-none w-100 mt-2">{isLoading ? <Spinner /> : 'Next'}</Button>
      </div>



      <CustomModal
        show={warning}
        onHide={handleClose}
        modalClass={"planner-heads-modal"}
      >
        <div className=" heading d-flex justify-content-between mb-4">
          <p className=" lato-bold fs-20-16 red "> Heads Up!</p>
          <Image src={CrossIcon} className='close-icon model-icon-close' alt='icon' style={{ cursor: "pointer" }} onClick={handleClose} />
        </div>
        <p className="lato-medium fs-18-16 black-242 ">You do not have sufficient content usage available! Want to keep the service flowing? Simply Top Up or Upgrade now.</p>
        <div className="d-flex justify-content-center">
          <Button onClick={handleNavigate} className="primary-btn">View Plans</Button>
        </div>
      </CustomModal>

      <CustomModal
        show={deletePost}
        onHide={handleDelete}
        modalClass={"discontinue-modal"}
      >
        <div className=" heading d-flex justify-content-between mb-4">
          <p className=" lato-bold fx-20 primary"> Discontinue posting?</p>
          <Image src={CrossIcon} className='close-icon model-icon-close' alt='icon' style={{ cursor: "pointer" }} onClick={handleDelete} />
        </div>
        <p className="lato-medium fs-18-16 black-242 ">This will discontinue posting content on social platforms scheduled for selected planner.</p>

        <div className="d-flex flex-row justify-content-center justify-content-md-end">
          <Button onClick={handleDelete} className="secondary-btn">Cancel</Button>
          <Button onClick={(e) => { handleDiscontinue(e) }} className="primary-btn">Discontinue</Button>

        </div>

      </CustomModal>
      <CustomModal
        show={modalOpen}
        onHide={handleModalClose}
        modalClass={"compliance-modal"}
      >
        <div className=" heading d-flex justify-content-between mb-4">
          <p className=" lato-bold fs-20-16 primary"> Compliance Undertaking</p>
          <Image src={CrossIcon} className='close-icon model-icon-close' alt='icon' style={{ cursor: "pointer" }} onClick={handleModalClose} />
        </div>
        <p className="lato-medium fx-12 black-242 op-5">Content provided by AMCs must remain unaltered, including their logos. Any modifications or misleading additions are strictly prohibited and may result in legal consequences. You will be solely responsible for any such actions; Thefinpedia and the AMC will not assume liability.</p> <br />
        <div className="d-flex flex-row"><p className="lato-medium fx-12 black-242 op-5">For details, please refer to the&nbsp;</p> <span onClick={() => history.push('/terms-conditions')} className="fx-12 lato-medium primary pointer"> Terms & Conditions.</span></div>



      </CustomModal>
      <CustomModal
        show={disclaimerOpen}
        onHide={handleModalClose}
        modalClass={"compliance-modal"}
      >
        <div className=" heading d-flex justify-content-between mb-4">
          <p className=" lato-bold fs-20-16 primary"> Disclaimer</p>
          <Image src={CrossIcon} className='close-icon model-icon-close' alt='icon' style={{ cursor: "pointer" }} onClick={handleModalClose} />
        </div>
        <p className="lato-medium fx-12 black-242 op-5">While TFP Post Planner endeavors to schedule and publish posts as intended, TFP does not guarantee the successful delivery of posts. Factors such as platform issues, network disruptions, or content violations may prevent the publication of posts. TFP shall not be held liable for any losses resulting from failed posts. Users are responsible for monitoring the status of their posts and addressing any issues in a timely manner</p> <br />
        <div className="d-flex flex-row"><p className="lato-medium fx-12 black-242 op-5">For details, please refer to the </p> &nbsp; <span onClick={() => history.push('/terms-conditions')} className="fx-12 lato-medium primary pointer"> Terms & Conditions.</span></div>



      </CustomModal>
    </div>
  );
}