import React from "react";
import PropTypes from "prop-types";
import {
  BaseReactComponent,
  CustomTextControl,
  Form,
  FormElement,
  FormSubmitButton,
  FormValidator,
} from "../../utils/form";
import { CustomModal } from "../common/pages";
import { MdArrowBack } from 'react-icons/md';
import forgotImage from '../../assets/images/forgot-password-image.svg'
import forgotSuccess from '../../assets/images/forgot-success.svg'
import { Image } from "react-bootstrap";
import { forgotPasswordApi } from "./Api";

class ForgotPassword extends BaseReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      showSuccess: false,
    };
  }


  onSubmit = () => {
    const data = {
      data: {
        "emailAddress": this.state.email,
      }
    }
    forgotPasswordApi(this, data);
  };
  render() {
    return (
      <CustomModal
        show={this.props.show}
        onHide={this.props.handleClose}
        // title={}
        modalClass={"forgot-password-modal"}
      >
        {
          this.state.showSuccess
            ?
            <>
              <Image src={forgotSuccess} className="forgot-image" />
              <br />
              <br />
              <br />
              <br />
              <p className="lato-regular f-s-20 black-242 lh-24">Success! Check your inbox for a password reset link.</p></>
            :
            <>
              <Image src={forgotImage} className="forgot-image" />
              <h3 className="lato-semibold f-s-30 black-242 lh-32">Forgot Password</h3>
              <p className="lato-regular f-s-20 black-242 lh-24">Enter your email below, and we'll send you a password reset link. Get ready to regain access in no time!</p>
              <Form onValidSubmit={this.onSubmit}>
                <FormElement
                  valueLink={this.linkState(this, "email")}
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "Please enter email id",
                    },
                    {
                      validate: FormValidator.isEmail,
                      message: "Please enter a valid email id",
                    },
                  ]}
                  control={{
                    type: CustomTextControl,
                    settings: {
                      placeholder: "Email id",
                    },
                  }}
                />
                <FormSubmitButton customClass="primary-btn">
                  Submit
                </FormSubmitButton>
              </Form>
            </>
        }

        <div onClick={() => this.props.handleClose()} className="lato-semibold fs-18-14 black-242 back-to-login pointer">
          <MdArrowBack />&nbsp; <span>Back to Log in</span>
        </div>

      </CustomModal>
    );
  }
}

ForgotPassword.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default ForgotPassword;
