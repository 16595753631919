import React, { useEffect, useState } from 'react'
import RangeFields from './components/RangeFields'
import BarChart from './components/BarChart'
import RadioFields from './components/RadioFields'
import { mfObj } from '../../utils/Constant';
import { CheckToolsSubscription, ShowShare, ToolsScreenshotHeader, captureImageHandler, debounce, formatCurrency, toolsDisclaimer, toolsInvestorDetails } from '../../utils/ReusableFunctions';
import { useRef } from 'react';
import { useMemo } from 'react';

export default function DelaySip({ calculator, textLogo = '', imageData, setImageData, isDisable, setIsDisable, setAlertData, setIsAlert, setModalData }) {

  let [frequency, setFrequency] = useState("monthly");
  let [sipAmount, setSipAmount] = useState(25000);
  let [rateOfInterest, setRateOfInterest] = useState(12);
  let [tenure, setTenure] = useState(7);
  let [delayInYears, setDelay] = useState(2);
  const selectOption = [
    { value: "monthly", label: "Monthly" },
    // { value: "quarterly", label: "Quarterly" },
  ];


  let [investedAmountNonDelay, setInvestedAmountNonDelay] = useState(2100000);
  let [investmentValNonDelay, setInvestmentValNonDelay] = useState(3299475);

  let [investedAmountDelay, setInvestedAmountDelay] = useState(1500000);
  let [investmentValDelay, setInvestmentValDelay] = useState(2062159);

  useEffect(() => {
    onSubmitAction(sipAmount, rateOfInterest, tenure, delayInYears, frequency);
  }, [sipAmount, rateOfInterest, tenure, delayInYears, frequency])

  const onSubmitAction = (sipAmount = 0, rateOfInterest, tenure = 0, delayInYears = 0, frequency) => {
    let multiplicationFactor = mfObj[frequency];
    let roi = (rateOfInterest / 12) * 100 || 0;
    let roiOriginal = rateOfInterest / 100;
    const toDivideROI = roi / 10000 || 0;
    const toMultiplyROI = ((1 + (roiOriginal / 12)) * 100 / 100) || 0


    let investedAmountNonDelay = sipAmount * tenure * 12;
    let investedAmountDelay = (tenure - delayInYears) * 12 * sipAmount;

    let investmentValNonDelay = Math.round(((((((1 + (roiOriginal / 12)) * 100) / 100) ** (tenure * 12)) - 1) / toDivideROI) * sipAmount * toMultiplyROI);
    let investmentValDelay = Math.round((((toMultiplyROI ** ((tenure - delayInYears) * 12)) - 1) / toDivideROI) * sipAmount * toMultiplyROI);

    setFrequency(frequency)
    setSipAmount(sipAmount)
    setRateOfInterest(rateOfInterest)
    setTenure(tenure)
    setDelay(delayInYears)

    setInvestedAmountNonDelay(investedAmountNonDelay);
    setInvestmentValNonDelay(investmentValNonDelay);

    setInvestedAmountDelay(investedAmountDelay);
    setInvestmentValDelay(investmentValDelay);


  };

  const handleChange = (value, name) => {

    if (name === "sipAmount") {
      sipAmount = value
    }
    if (name === "rateOfInterest") {
      rateOfInterest = value
    }
    if (name === "tenure") {
      tenure = value
    }
    if (name === "delayInYears") {
      delayInYears = value
    }
    if (name === "frequency") {
      frequency = value
    }

    onSubmitAction(sipAmount, rateOfInterest, tenure, delayInYears, frequency);
  }


  let downloadScreenshotFunc = null;

  const setDownloadScreenshotFunc = (func) => {
    downloadScreenshotFunc = func;
  };


  const barChart = useMemo(() => {
    return (
      <BarChart
        info={{
          labels: ["Invested Amount", "Investment Value"],
          data: [investedAmountNonDelay, investmentValNonDelay],
        }}
      />
    );
  }, [investedAmountNonDelay, investmentValNonDelay]);

  const barChart2 = useMemo(() => {
    return (
      <BarChart
        info={{
          labels: ["Invested Amount", "Investment Value"],
          data: [investedAmountDelay, investmentValDelay],
        }}
      />
    );
  }, [investedAmountDelay, investmentValDelay]);
  return (
    <div>

      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART START ---------------------------------------------- */}
      <div className="calculator-result-main-wrapper">
        <div className="calculator-component-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Calculator

          </h4>
          <RangeFields
            label="SIP Amount"
            name={"sipAmount"}
            value={sipAmount}
            minVal={500}
            maxVal={1000000}
            step={500}
            getValue={debounce(handleChange)}
            showComma />

          <RangeFields
            label="Expected Rate of Return"
            name={'rateOfInterest'}
            value={rateOfInterest}
            minVal={4}
            maxVal={50}
            step={0.5}
            unit="%"
            getValue={debounce(handleChange)}
          />
          <RadioFields
            options={selectOption}
            initialValue={"monthly"}
            onSelectionChange={(v) => { setFrequency(v) }}

            label="SIP Frequency"
          />
          <RangeFields
            label="Investment Tenure"
            name={"tenure"}
            value={tenure}
            minVal={2}
            maxVal={50}
            unit="Year"
            step={1}
            getValue={debounce(handleChange)}
          />


          <RangeFields
            label="Delay"
            name={'delayInYears'}
            value={delayInYears}
            minVal={1}
            maxVal={20}
            step={1}
            unit="Year"
            getValue={debounce(handleChange)}
          />




        </div>
        <div className="result-graph-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Results{" "}

            {<ShowShare calculator isDisable={isDisable} setIsDisable={setIsDisable} clickHandler={() => { CheckToolsSubscription(textLogo, downloadScreenshotFunc, calculator?.uuid, imageData, setIsDisable, setAlertData, setIsAlert, setModalData) }} />}

          </h4>
          <div className="result-graph-wrapper">
            <div className={`graph-1-frame flex-column ${false ? "w-auto" : "w-100"}`}>
              <p className='lato-semibold  f-s-12 lh-14 primary text-center pt-2'>Without Delay</p>

              {barChart}
            </div>
            <div className={`graph-1-frame flex-column ${false ? "w-auto" : "w-100"}`}>
              <p className='lato-semibold  f-s-12 lh-14 primary text-center pt-2'>With Delay of {delayInYears} years</p>

              {barChart2}
            </div>
          </div>
          <div className='d-flex justify-content-around p-2'>
            {delaySipText(sipAmount, tenure, delayInYears, investedAmountNonDelay, investmentValDelay, rateOfInterest, investmentValNonDelay, investedAmountDelay)}
          </div>
        </div>
      </div>
      <div className="make-none">
        <CalculatorOutputScreenShot
          textLogo={textLogo}
          data={[investedAmountNonDelay, investmentValNonDelay]}
          data2={[investedAmountDelay, investmentValDelay]}
          sipAmount={sipAmount}
          investedAmountNonDelay={investedAmountNonDelay}
          investmentValNonDelay={investmentValNonDelay}
          investedAmountDelay={investedAmountDelay}
          investmentValDelay={investmentValDelay}
          tenure={tenure}
          delayInYears={delayInYears}
          rateOfInterest={rateOfInterest}
          onDownloadScreenshot={setDownloadScreenshotFunc}
          setImageData={setImageData}
        />
      </div>
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART END ---------------------------------------------- */}
    </div>
  )
}

function CalculatorOutputScreenShot({ textLogo, data, data2, sipAmount, tenure, delayInYears, investedAmountNonDelay, onDownloadScreenshot, investmentValDelay, rateOfInterest, investmentValNonDelay, investedAmountDelay, setImageData, frequency }) {
  const { cobrandingLabelling, investor } = textLogo;
  const [signup, setSignup] = useState(false);
  const screenshotRef = useRef(null);
  useEffect(() => {

    if (onDownloadScreenshot) {
      captureImageHandler(onDownloadScreenshot, screenshotRef, setSignup, setImageData);

    }
  }, [textLogo, screenshotRef, sipAmount, rateOfInterest, tenure, delayInYears, frequency]);
  return (<>
    {/* {signup && <SignUp show={signup} handleClose={() => setSignup(!signup)} />} */}

    <div ref={screenshotRef} className="tools-calulator-output-screenshot-frame">
      {ToolsScreenshotHeader(cobrandingLabelling)}

      <div className="result-graph-wrapper">
        <div className={`graph-1-frame bg-gray ${false ? "w-auto" : "w-100"}`}>
          <BarChart
            info={{
              labels: ["Invested Amount", "Investment Value"],
              data: data,
            }}
          />
        </div>
        <div className={`graph-1-frame bg-gray ${false ? "w-auto" : "w-100"}`}>
          <BarChart
            info={{
              labels: ["Invested Amount", "Investment Value"],
              data: data2,
            }}
          />
        </div>
      </div>
      {toolsInvestorDetails(investor)}
      <br />

      {delaySipText(sipAmount, tenure, delayInYears, investedAmountNonDelay, investmentValDelay, rateOfInterest, investmentValNonDelay, investedAmountDelay)}

      {toolsDisclaimer()}

    </div>

  </>
  )
};

function delaySipText(sipAmount, tenure, delayInYears, investedAmountNonDelay, investmentValDelay, rateOfInterest, investmentValNonDelay, investedAmountDelay) {
  return (
    <p className=" f-s-16 lh-24 black-242 op-7 pt-2 text-center">
      With a monthly SIP of <span className=" lato-semibold primary">₹{formatCurrency(sipAmount)}</span>, after <span className=" lato-semibold primary">{tenure}</span> years of investment, your total investment amount will be <span className=" lato-semibold primary">₹{formatCurrency(investedAmountNonDelay)}</span> and its value will be <span className=" lato-semibold primary">₹{formatCurrency(investmentValNonDelay)}</span>. However, if you delay your SIP by <span className=" lato-semibold primary">{delayInYears}</span> years, your total investment amount will be <span className=" lato-semibold primary">₹{formatCurrency(investedAmountDelay)}</span> and its value will be <span className=" lato-semibold primary">₹{formatCurrency(investmentValDelay)}</span>, if the invested corpus keeps growing at <span className=" lato-semibold primary">{rateOfInterest}%</span> for the investment tenure.
    </p>
  )

}