import React, { Component } from "react";
import { connect } from "react-redux";
import { Image, Modal } from "react-bootstrap";

import training from '../../assets/images/eventss-training.png';

import BlackEye from "../../assets/images/black-eye.svg";
import ViewIcon from "../../assets/images/icon/view-icon-24.svg";
import BlackShare from "../../assets/images/icon/Share_1-24.svg";

import defaultIcon from "../../assets/images/default-grey.png";
import download from "../../assets/images/download-icon.svg";
import saveFilled from "../../assets/images/icon/save-field-24.svg";
import save from "../../assets/images/icon/save-24.svg";
import liked from "../../assets/images/Like.svg";
import likdeFill from "../../assets/images/Like-fill.svg";

import BlackOutlineDownload from "../../assets/images/icon/Download_1-24.svg";
import Hashtag from "../../assets/images/hashtag-icon.svg";
import BlackDownload from "../../assets/images/black-download.svg";

import Footer from "../common/pages/Footer";
import { Trending } from "../common";
import CrossIcon from "../../assets/images/icon/cross-modal-icon.svg"

import {
  VideoDetailsDownloadModal,
  VideoDetailsContentType,

  ContentTypesCards,
  BreadCrumb,

} from "./_utils";

import close from "../../assets/images/cancel-icon.svg";
import vsite from "../../assets/images/vsite.svg";
// import Presentation from "../../assets/images/presentation-details.png";
import { commonAuthPostApi, getDetailsApi, getSearchAllDataApi } from "./Api";
import { API_LIMIT, CAROUSEL, DEFAULT_PUBLISH_DATE, MAX_LIMIT, NA, SHARE_BASE_URL, START_PAGE, USER_TRACKING, UserTracking, VIDEOS } from "../../utils/Constant";
import { CustomDropDown } from "../../utils/form";
import { formatNumber, isPremiumAccess, isToday } from "../../utils/ReusableFunctions";
import { AlertModal, CustomModal, Header, NotFound } from "../common/pages";
import { DetailsSkeleton } from "../common/components/SkeletonCard";

import { AllShimmer, SignUp } from "../common/components";
import { getToken } from "../../utils/ManageToken";
import { RWebShare } from "react-web-share";
import { toast } from "react-toastify";
import SaveCollectionModal from "../common/components/SaveCollectionModal";

import moment from "moment";
import MultiViewer from "./_utils/Multiviewer";
/* eslint-disable eqeqeq */




class VideoDetails extends Component {
  constructor(props) {
    super(props);
    const searchValueState = props.location?.state?.searchValue || "";
    const { type } = props.match.params;

    // console.log(searchValueState)
    this.state = {
      searchValue: searchValueState,
      videoId: type?.split("&id=")[1],
      contentType: type,
      suggestionData: [],
      allData: [],
      contentData: [],
      language: "",
      color: "",
      path: "",
      updatedTitle: "",
      contentVariationimages: [],
      isAlert: false,
      alertData: {},
      updatedDescription: "",
      variationId: "",
      variation: [],
      show: sessionStorage.getItem('openSuccess') || false,
      link: "",
      likeCount: '',
      shareCount: '',
      downloadCount: '',
      savedCount: '',
      loading: true,
      isLoading: true,
      confirm2: false,
      isError: false,
      isSaved: null,
      isLiked: null,
      likeDisable: true,
      isView: null,
      viewMore: true,
      saveTo: false,
      confirm: false,
      isDownload: false,
      collectionName: "",
      signup: false,
      showWarning: false,
      isPremium: false,
      limit: "",
      pending: "",
      modalData: {},
      varId: ""
    };
  }

  showDownloadModal = () => {
    this.setState({
      show: !this.state.show,
      signup: this.state.signup
    })
  }


  onHide = (() => {
    this.setState({
      isAlert: false
    })
    if ((this.state.modalData?.data?.limit > 0 && this.state.modalData?.data?.pending > 0) || this.state.modalData?.data?.limit == 0) {
      this.setState({ limit: this.state.modalData?.data?.limit })
      this.setState({ pending: this.state.modalData?.data?.pending })

      this.showDownloadModal();
    }
  })

  showUsageModal = () => {
    // console.log("call Download Model")
    this.setState({
      showWarning: !this.state.showWarning
    })
  }
  breadCrumbHandler = () => {
    const { type } = this.props.match.params;
    const { state } = this.props.location;
    // console.log(state)
    const { updatedTitle } = this.state;
    let baseCrumb = type.split("&id=")[0].replace(/^\w/, (a) => a.toUpperCase()).replace("_", "/");
    // console.log(baseCrumb)
    if (state?.breadCrumbPath) {

      return state?.breadCrumbPath.length === 1 ? [state.breadCrumbPath[0], baseCrumb, updatedTitle,] : [state.breadCrumbPath[0] == "targetedContents" ? "Targeted Playlists" : state.breadCrumbPath[0], state.breadCrumbPath[1], baseCrumb, updatedTitle,]
    } else {
      return [baseCrumb, updatedTitle,]
    }

  }

  async loadData() {
    const id = this.state.contentType?.split("&id=")[1];
    const types = this.state.contentType?.split("&id=")[0];


    if (id) {
      const locationState = this.props.location?.state;
      const data = await getDetailsApi(`website/content/details/${id}`);
      const variation = data.data.data?.contentVariation;
      variation && variation.length > 0 && variation.push(data.data.data?.defaultVariation);
      // * IF COMINF FORM LANGUAGES THEN SET DEFAULT LANGUAGE
      const isItFromLang = locationState?.breadCrumbPath[0] === 'languages';
      let getExactLangObj = false;
      if (isItFromLang) {
        getExactLangObj = variation.length > 0 ? variation?.find((item, i) => (item.language?.languageName === locationState?.breadCrumbPath[1])) : data?.data?.data?.defaultVariation;
        this.setState({
          variationId: getExactLangObj?.uuid,
          link: getExactLangObj?.extUrlLink,
        })
      }

      if (!data?.response?.data?.message) {
        const token = getToken();
        const isPremiumAccessed = isPremiumAccess();
        if (data?.data?.data?.isPremium) {
          if (!token) {
            this.setState({ signup: !this.state.signup })
          } else if (!isPremiumAccessed) {
            this.setState({ confirm2: !this.state.confirm2 })
          }
          else {


            this.setState({
              updatedTitle: isItFromLang ? getExactLangObj?.title : data.data.data?.defaultVariation?.title,
              contentVariationimages: isItFromLang ? getExactLangObj?.contentVariationimages : data.data.data?.defaultVariation?.contentVariationimages,
              updatedDescription: isItFromLang ? getExactLangObj?.description : data.data.data?.defaultVariation?.description,
              allData: data.data.data,
              contentData: data.data.data,
              variation,
              language: isItFromLang ? getExactLangObj?.language?.languageName : data.data.data?.defaultVariation?.language?.languageName,
              color: isItFromLang ? getExactLangObj?.color : data.data.data?.defaultVariation?.color,
              path: isItFromLang ? getExactLangObj?.contentFilePath : data.data.data?.defaultVariation?.contentFilePath,
              loading: false,
              isPremium: data?.data?.data?.isPremium,
              filePath: data?.data?.data?.defaultVariation?.contentFilePath || "",
              varId: data?.data?.data?.defaultVariation?.id || ""

            })
          }
        }
        else {


          this.setState({
            updatedTitle: isItFromLang ? getExactLangObj?.title : data.data.data?.defaultVariation?.title,
            contentVariationimages: isItFromLang ? getExactLangObj?.contentVariationimages : data.data.data?.defaultVariation?.contentVariationimages,
            updatedDescription: isItFromLang ? getExactLangObj?.description : data.data.data?.defaultVariation?.description,
            allData: data.data.data,
            contentData: data.data.data,
            variation,
            language: isItFromLang ? getExactLangObj?.language?.languageName : data.data.data?.defaultVariation?.language?.languageName,
            color: isItFromLang ? getExactLangObj?.color : data.data.data?.defaultVariation?.color,
            path: isItFromLang ? getExactLangObj?.contentFilePath : data.data.data?.defaultVariation?.contentFilePath,
            loading: false,
            isPremium: data?.data?.data?.isPremium,
            filePath: data?.data?.data?.defaultVariation?.contentFilePath || "",
            varId: data?.data?.data?.defaultVariation?.id || "",

          })
        };

        const suggestionData = await getSearchAllDataApi((types === "All") ? "" : types?.replace("_", "/"), `search=${this.state.searchValue || ""}&pageNumber=${START_PAGE}&pageSize=8`);

        if ((suggestionData?.contents?.length ?? false)) {
          this.setState({ suggestionData, isLoading: false });
        } else {
          this.setState({ isLoading: false });
        }
      } else {
        this.setState({ isError: true })
      }


    }
  }
  getStats = async () => {
    const id = this.state.contentType?.split("&id=")[1];

    if (id) {
      const stats = await getDetailsApi(`website/content/stats/${id}?variationId=${this.state.varId}`);
      this.setState({
        isLiked: stats.data.data?.userStats?.isLiked,
        isSaved: stats.data.data?.userStats?.isSaved,
        isView: stats.data.data?.userStats?.isViewed,
        isDownload: stats.data.data?.userStats?.isDownloaded,
        likeCount: stats?.data?.data?.likeCount,
        shareCount: stats?.data?.data?.shareCount,
        downloadCount: stats?.data?.data?.downloadCount,
        savedCount: stats?.data?.data?.savedCount,
        viewCount: stats?.data?.data?.viewCount,


      })
    }


  }
  userTrackingHandler = async (type) => {

    // e.preventDefault()
    // const id = this.state.contentType?.split("&id=")[1];
    // console.log("Check Id", this.state.videoId)
    const info = {
      "data": {
        "contentUuid": this.state.videoId,
        "type": type,
        "contentVariationUuid": this.state.variationId ? this.state.variationId : this.state.contentData?.defaultVariation.uuid
      }
    }
    let token = getToken();


    if (!token) {
      // toast.error("You need to login first");
      if (type !== UserTracking.SHARED && type !== UserTracking.DOWNLOADED) {
        this.setState({ signup: true })
      } else if (type === UserTracking.DOWNLOADED) {

        // const response = await commonAuthPostApi(USER_TRACKING, info);
      }
    } else {
      this.setState({ likeDisable: false })
      if (type === UserTracking.LIKED || type === UserTracking.UNLIKE || type == UserTracking.SHARED) {
        const response = await commonAuthPostApi(USER_TRACKING, info);
        if (!response.message) {
          this.getStats();
          this.setState({ likeDisable: true })

          // const newCount = type === UserTracking.LIKED ? this.state.likeCount + 1 : this.state.likeCount - 1;
          // let newCount = this.state.likeCount;
          // let newShareCount = this.state.shareCount;
          // let newSavedCount = this.state.savedCount;


          // if (type === UserTracking.LIKED) {
          //   newCount += 1;
          //   this.setState({ isLiked: !this.state.isLiked, likeCount: newCount, likeDisable: true })
          // } else if (type === UserTracking.UNLIKE) {
          //   newCount -= 1;
          //   this.setState({ isLiked: !this.state.isLiked, likeCount: newCount, likeDisable: true })
          // }
          // else if (type === UserTracking.SHARED) {
          //   // this.loadData()
          //   newShareCount += 1;
          //   this.setState({ shareCount: newShareCount, likeDisable: true })
          // }


          // toast.success(`Succesfully Downloaded`);
        }
        // console.log(!response.message)
      }
      // else if (type === UserTracking.DOWNLOADED && this.state.path) {
      //   let newDownloadCount = this.state.downloadCount;
      //   console.log('new', newDownloadCount, this.state.downloadCount);
      //   const response = await commonAuthPostApi(USER_TRACKING, info);
      //   if (!response.message) {
      //     toast.success(`Successfully Downloaded`);
      //     this.setState({
      //       downloadCount: newDownloadCount + 1,
      //       isDownload: true
      //     })
      //   }
      // }

    }
  }
  async componentDidMount() {

    await this.loadData();
    await this.getStats();


  }

  async componentDidUpdate(prevProps) {
    const { type } = this.props.match.params;
    const prevType = prevProps.match.params.type;

    // Check if the type in the URL has changed
    if (type !== prevType) {
      this.setState({ contentType: type }, () => {
        this.loadData();
        window.scrollTo(0, 0);
      });
    }
  }
  setDefaultVariationData = (params) => {
    // console.log(params);
    let downloadPath = params?.contentFilePath;
    let youtubeLink = params?.extUrlLink;
    let updatedTitle = params?.title;
    let updatedDescription = params?.description;
    let variationId = params?.uuid;
    let contentVariationimages = params?.contentVariationimages
    this.setState({
      updatedDescription,
      variationId,
      updatedTitle,
      contentVariationimages,
      language: params.languageName,
      color: params?.color || this.state.color,
      path: downloadPath,
      link: youtubeLink
    })

  }

  languageSelectHandler = async (data) => {

    let getFixedObj = this.state.variation?.find(item =>
      item.color?.colorName === this.state.color.colorName && item.language?.languageName === data.languageName
    ) || this.state.variation?.find(item => item.language?.languageName === data.languageName
    );

    getFixedObj.languageName = data.languageName;

    this.setDefaultVariationData(getFixedObj);
    this.getStats();



  }
  colorSelectHandler = async (data) => {
    let getFixedObj = this.state.variation?.find((item, i) => (item.color?.colorName === data?.colorName && item.language?.languageName === this.state.language));
    getFixedObj.languageName = this.state.language;
    this.setDefaultVariationData(getFixedObj);
    this.getStats();





  }
  saveToCollectionHandler = () => {
    let token = getToken();
    if (!token) {
      this.setState({ signup: true })
    } else {
      this.setState({ saveTo: true })
    }
  }
  downloadModalOpen = async () => {
    const { contentData } = this.state;
    // const isPremium = contentData?.isPremium;
    const token = getToken();
    // const isPremiumAccessed = isPremiumAccess();


    if (token) {

      const details = {
        data: {
          "contentUuid": contentData?.uuid,
          "actionType": "Downloaded"
        }
      }

      const res = await commonAuthPostApi("website/content/check-subscription", details)

      if (res.data) {
        if (res.data.alerts.length > 0) {

          this.setState({
            alertData: res?.data,
            isAlert: true,
            modalData: res

          })

        } else {
          if ((res?.data?.limit > 0 && res?.data?.pending > 0) || res?.data?.limit == 0) {
            this.setState({ limit: res?.data?.limit })
            this.setState({ pending: res?.data?.pending })

            this.showDownloadModal();
          }
        }

      }
    } else {
      this.setState({ signup: true });

    }

  }

  conformCollectionPopUp = (data) => {
    // console.log("Conform to saved says,", data)
    this.setState({
      saveTo: data.label,
      confirm: !this.state.confirm,
      isSaved: (data.unSaved !== ""),
      collectionName: data.selectedItem,
      savedCount: (data.unSaved !== "") ? this.state.savedCount + 1 : this.state.savedCount - 1,
    })
  }
  navigateAmcDetails = () => {
    if (this.state.allData?.amcId != null) {
      this.props.history.push({ pathname: `/content-type/All`, search: `?key=amc&value=${this.state.allData?.amc?.amcName}&pageNumber=${START_PAGE}&pageSize=${API_LIMIT}`, })
    }
    else {
      this.props.history.push({ pathname: `/content-type/All`, search: `?key=creator&value=${this.state.allData?.contentCreator?.contentCreatorName}&pageNumber=${START_PAGE}&pageSize=${API_LIMIT}`, })

    }
  }


  render() {

    const { type } = this.props.match.params;
    const { state } = this.props.location;
    const { contentVariationimages, allData, likeCount, shareCount, savedCount, downloadCount, viewCount, signup, saveTo, isDownload, confirm, collectionName, isView, viewMore, isLoading, updatedDescription, variationId, updatedTitle, isLiked, isSaved, isError, suggestionData, show, loading, link, contentType, contentData, language, color, variation, path, showWarning, isPremium, limit, pending } = this.state;
    console.log(variation, "variation")
    // Check session storage for showSuccess
    const showSuccess = sessionStorage.getItem('showSuccess');

    // Update the show state based on session storage
    if (showSuccess) {
      this.setState({ show: true });
    }

    let languageOption1 = variation?.map((item) => item.language);
    let languageOption = Object.values(languageOption1.reduce((uniqueMap, obj) => {
      uniqueMap[obj.languageName] = obj;
      return uniqueMap;
    }, {}));

    let colorOption = variation?.filter((item) => item.language.languageName === language).map((item) => item.color);


    let publishedAtSplit = [];
    let publishedAt = '';
    if (contentData?.publishedAt) {
      publishedAtSplit = contentData?.publishedAt.split('-');
      publishedAt = publishedAtSplit[2] + '-' + publishedAtSplit[1] + '-' + publishedAtSplit[0] + 'T00:00:00.000Z'
    }

    return (
      <div className="video-details-page" id="my-component">
        <Header />
        {signup && (
          <SignUp
            show={signup}
            handleClose={() => {
              this.setState({ signup: !signup });
              // window.location.href = "/"
            }}
          />
        )}

        {
          confirm &&
          <Modal className="save-confirm-collection-custom-model" centered show={confirm} onHide={() => this.setState({ confirm: !confirm })}>

            <Modal.Body className="save-confirm-collection-model-body">
              <Image src={close} onClick={() => this.setState({ confirm: !confirm })} alt="canle icon" className="close-btn" />
              <h4 className="lato-regular fs-28-18 black-242 w-100 text-center mt-2 mt-md-0">{collectionName}</h4>
              <div className="d-flex justify-content-center ">
                <div className={`primary-btn py-3 pointer`} onClick={() => { this.props.history.push('/mycollections') }}>View Collection</div>
              </div>
            </Modal.Body>
          </Modal>

        }
        {saveTo &&
          <SaveCollectionModal id={this.state.contentType?.split("&id=")[1]} variationId={this.state.variationId ? this.state.variationId : this.state.contentData?.defaultVariation.uuid} show={saveTo} handleClose={() => this.setState({ saveTo: !saveTo })} collectionPopUp={this.conformCollectionPopUp} getStats={this.getStats} />
        }
        {/* ?============================ SAVE TO MODAL END ========================== */}

        {
          show &&
          <VideoDetailsDownloadModal
            ctx={() => { this.setState({ downloadCount: this.state.downloadCount + 1, isDownload: true }) }}
            show={show}
            handleClose={this.showDownloadModal}
            contentType={contentType}
            detailsData={allData}
            downloadPath={path}
            variation={variation}
            languageColor={{ "language": language, "color": color }}
            updatedThings={{ "tittle": updatedTitle, "description": updatedDescription }}
            variationId={variationId}
            signup={false}
            isPremium={isPremium}
            limit={limit}
            pending={pending}
            getStats={this.getStats}
          // selectedVariation={}
          />
        }

        <CustomModal
          show={showWarning}
          onHide={this.showUsageModal}
          title={""}
          modalClass={"usage-alert-modal"}
        >
          <div className=" d-flex flex-column">
            <div className=" alert-heading d-flex flex-row justify-content-between">
              <div className="alert-heading-text">
                <p className="lato-bold fs-20-16">Usage Alert</p>
              </div>
              <div className="cross-icon ">
                <Image width={20} height={20} src={CrossIcon} onClick={this.showUsageModal} style={{ cursor: "pointer" }} />
              </div>
            </div>
            <div className="alert-text d-flex ">
              <p className="lato-regular fs-16-14 black-242">
                It seems you've reached your daily limit for free content downloads. Want to access more services just like our pro marketers, why not be a part of it? Join us now and enjoy premium benefits. <span className="lato-regular fs-16-14 primary">View Plans</span>
              </p>
            </div>

          </div>
        </CustomModal>
        {
          isError ? (
            <NotFound massage="Something went wrong" />
          ) : loading ? (
            <DetailsSkeleton />
          ) : (
            <div className="video-detail-wrapper">
              <div className="container">

                <BreadCrumb
                  path={this.breadCrumbHandler()}
                  clasese="mb-0 detail-page-breadcrumb"
                  isKeyValue={(state?.breadCrumbPath ?? '')}
                />

                <div className="video-profile-details">
                  <div className="profile-detail">
                    {(contentData?.amc?.circleImagePath || contentData?.contentCreator?.thumbnailPath) &&
                      <Image
                        src={contentData?.amc?.circleImagePath || contentData?.contentCreator?.thumbnailPath || defaultIcon}
                        className="desk-show profile-img pointer"
                        alt="progile Image"
                        title={contentData?.amc?.amcName}
                        onClick={contentData?.amc?.amcName ? this.navigateAmcDetails : null}
                      />
                    }
                    <div className="name-count-details">
                      <div className="count-detail-heading">
                        <h6 className="lato-regular f-s-24 lh-32 black-242 d-flex gap-12 align-items-center">
                          {updatedTitle || 'Na'}

                        </h6>
                      </div>
                      <div className="d-flex align-items-center ">
                        {
                          (contentData?.amc?.amcName || contentData?.contentCreator?.contentCreatorName) &&
                          <p onClick={this.navigateAmcDetails} className="fs-18-14 lato-semibold grey-151 pointer mb-0">
                            <Image
                              src={contentData?.amc?.circleImagePath || contentData?.contentCreator?.thumbnailPath || defaultIcon}
                              className=" mb-show amc-profile-img-mb pointer"
                              alt="progile Image"
                              title={contentData?.amc?.amcName}
                            // onClick={contentData?.amc?.amcName ? this.navigateAmcDetails : null}
                            />
                            <span className="op-5"> {contentData?.amc?.amcName || contentData?.contentCreator?.contentCreatorName || ""}</span> &nbsp;&nbsp;&nbsp;
                          </p>
                        }
                        <div className="details-duration desk-show ">
                          <div className="video-tag">
                            <h6 onClick={() => {
                              const newSearchValue = `pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}`;
                              this.props.history.push({
                                pathname: `/content-type/${(contentData?.contentType?.contentTypeName).toLowerCase().replace("/", "_")}`,
                                search: `${newSearchValue}`,
                              });
                            }} className="fs-14-12 lato-medium pointer op-6">{(contentData?.contentType?.contentTypeName).toUpperCase()}</h6>
                          </div>
                          {
                            contentData?.category &&
                            <div className="video-tag pointer"
                              onClick={() => {
                                const newSearchValue = `?key=category&value=${contentData?.category?.categoryName.toLowerCase()}&pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}`;
                                this.props.history.push({
                                  // pathname: `/content-type/${(contentType?.split("&id=")[0]).toLowerCase().replace("/", "_")}`,
                                  pathname: `/content-type/All`,
                                  search: `${newSearchValue}`,
                                });
                              }}
                            >
                              <h6 className="fs-14-12 lato-medium op-6">{(contentData?.category?.categoryName.toUpperCase() || "")}</h6>
                            </div>
                          }

                          {
                            contentData?.publishedAt && contentData?.publishedAt !== DEFAULT_PUBLISH_DATE &&
                            // <span><h6 className="fs-18-12 lato-semibold grey-151 op-5">{formatDate(contentData?.publishedAt)}</h6></span>
                            <span><h6 className="fs-18-12 lato-semibold grey-151 op-5">{isToday(publishedAt) ? 'Today' : ((publishedAt ?? false) ? moment(publishedAt).fromNow() : '')}</h6></span>
                          }
                        </div>
                      </div>
                      {/* ==================== Duration mb=============== */}
                      <div className="details-duration mb-flex justify-content-start">
                        <div className="video-tag">
                          <h6 onClick={() => {
                            const newSearchValue = `pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}`;
                            this.props.history.push({
                              pathname: `/content-type/${(contentType?.split("&id=")[0]).toLowerCase().replace("/", "_")}`,
                              search: `${newSearchValue}`,
                            });
                          }} className="fs-14-12 lato-medium op-6">{(contentType?.split("&id=")[0]).toUpperCase()}</h6>
                        </div>
                        {
                          contentData?.category &&
                          <div className="video-tag pointer"
                            onClick={() => {
                              const newSearchValue = `?key=category&value=${contentData?.category?.categoryName.toLowerCase()}&pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}`;
                              this.props.history.push({
                                // pathname: `/content-type/${(contentType?.split("&id=")[0]).toLowerCase().replace("/", "_")}`,
                                pathname: `/content-type/All`,
                                search: `${newSearchValue}`,
                              });
                            }}
                          >
                            <h6 className="fs-14-12 lato-medium op-6">{(contentData?.category?.categoryName.toUpperCase() || "")}</h6>
                          </div>
                        }
                        <span><h6 className="fs-18-12 lato-semibold">{isToday(publishedAt) ? 'Today' : ((publishedAt ?? false) ? moment(publishedAt).fromNow() : '')}</h6></span>
                      </div>

                    </div>
                  </div>

                </div>
                <div className="video-detail-para">

                  <p className=" like-share-icons-wrapper">
                    <span> {isLiked ? <Image src={likdeFill} className="fs-18-16 lato-semibold" onClick={this.state.likeDisable ? () => { this.userTrackingHandler(UserTracking.UNLIKE) } : null} /> : <Image src={liked} className="fs-18-16 lato-semibold" onClick={this.state.likeDisable ? () => { this.userTrackingHandler(UserTracking.LIKED) } : null} />}<h6 className="fs-18-12 lato-semibold">{formatNumber(likeCount)}</h6></span>
                    <span> {isView ? <Image src={BlackEye} alt="download" /> : <Image src={ViewIcon} alt="videw icon" onClick={() => { }} />}<h6 className="fs-18-12 lato-semibold">{formatNumber(viewCount)}</h6></span>
                    <span>
                      <RWebShare
                        data={{
                          text: "",
                          // url: `${SHARE_BASE_URL + "details/" + contentType.toLowerCase() + "&id=" + contentData.uuid}`,
                          url: `${SHARE_BASE_URL + "details/" + contentType.toLowerCase()}`,
                          title: contentData.title,
                        }}
                        // onClick={(e) => this.userTrackingHandler(e, UserTracking.SHARED)}
                        onClick={(e) => {
                          this.userTrackingHandler(UserTracking.SHARED);  // Calls the API handler
                          e.preventDefault();  // Prevents the default action of the click
                        }}
                      >

                        {/* <div className="lato-semibold fx-14 black-242 d-flex share-wrapper-btn "> */}
                        <div className="video-details-share-icon">
                          <Image src={BlackShare} alt="share" />
                          <h6 className="fs-18-12 lato-semibold">{formatNumber(shareCount)}
                          </h6>
                        </div>
                        {/* </div> */}
                      </RWebShare>
                    </span>
                    <span
                      className={this.state.path ? '' : 'pointer-none'}
                      onClick={() => { if (this.state?.path) { this.downloadModalOpen() } else { toast.error("No download file available"); } }}
                    >
                      <Image src={isDownload ? BlackDownload : BlackOutlineDownload} alt="download" className="" />
                      <h6 className="fs-18-12 lato-semibold">{formatNumber(downloadCount)}</h6>
                    </span>

                    <span
                      onClick={this.saveToCollectionHandler}
                    >
                      {isSaved ? <Image src={saveFilled} className=" fs-18-16 lato-semibold " /> : <Image src={save} className="fs-18-16 lato-semibold " />}<h6 className="fs-18-12 lato-semibold">{formatNumber(savedCount)}</h6>
                    </span>
                  </p>
                  <div className="video-details-dropwon-component-wrapper">

                    <CustomDropDown
                      label={language || "Select Language"}
                      options={languageOption?.length === 1 && language === languageOption[0]?.languageName ? [] : languageOption || []}
                      getData={(data) => {
                        this.languageSelectHandler(data)
                        // console.log(data)
                      }}
                      id="Language"
                      disabled={languageOption?.length <= 1}
                    // disabled={languageOption?.length>=1 && language===languageOption[0]?.languageName}
                    />
                    {/* {console.log(languageOption?.length<=1 , language===languageOption[0]?.languageName)} */}
                    <CustomDropDown
                      label={color || "Select Color"}
                      options={colorOption || []}
                      getData={(data) => this.colorSelectHandler(data)}
                      id="color"
                      disabled={colorOption?.length <= 1}
                    />
                    <button disabled={!this.state.filePath} style={{
                      cursor: !this.state.filePath ? 'not-allowed ' : 'pointer'
                    }} onClick={this.downloadModalOpen} className={`primary-btn  mb-none pointer ${!this.state.filePath ? "button-download cursor-notallowed" : "download-btn"}`}>
                      <Image style={{ opacity: !this.state.filePath ? 0.2 : 1 }} src={download} alt="download" />
                      Download
                    </button>
                  </div>
                </div>

                <div className="video-play-area" oncontextmenu="return false;">


                  {(CAROUSEL === type.split("&id=")[0] && contentVariationimages?.length > 0) ?

                    <MultiViewer imageArr={contentVariationimages} contentFilePath={this.state.path || contentData?.defaultVariation?.contentFilePath || ''} />
                    :
                    <VideoDetailsContentType type={type.split("&id=")[0]} extUrlLink={link ? link || '' : contentData?.defaultVariation?.extUrlLink || ""} contentFilePath={this.state.path || contentData?.defaultVariation?.contentFilePath || ''} />
                  }
                </div>
                {
                  updatedDescription &&
                  <div className={`description-box-wrapper d-flex align-items-end w-100 ${!viewMore && "flex-wrap justify-content-end"} `}>
                    <p className={`lato-regular fs-18-14 op-5 grey-151 w-100 word-wrap ${viewMore ? "ellipsis-1" : ""} `}>{updatedDescription || NA}</p>
                    {updatedDescription.length > 150 &&
                      <span className="lato-regular blue-0C5 pointer view-more-des d-none d-md-flex fs-16-14 " onClick={() => this.setState({ viewMore: !viewMore })}>Show {viewMore ? "more" : "less"}</span>
                    }
                    {updatedDescription.length > 50 &&
                      <span className="lato-regular blue-0C5 pointer view-more-des d-md-none d-flex fs-16-14 " onClick={() => this.setState({ viewMore: !viewMore })}>Show {viewMore ? "more" : "less"}</span>
                    }
                  </div>
                }
                {
                  type.split("&id=")[0] !== VIDEOS.toLocaleLowerCase() && contentData?.defaultVariation?.extUrlLink &&
                  <div className=" d-flex align-items-center justify-content-end">
                    <a
                      rel="noreferrer"
                      target="_blank"
                      className="vist-btn f-s-11 lh-16 lato-medium pointer"
                      href={contentData?.defaultVariation?.extUrlLink}
                    >
                      <Image src={vsite} alt="image" /> Visit source website
                    </a>
                  </div>}
                {
                  contentData?.keywords &&
                  <div className="filter-wrapper trend-suggestions video-trend-suggestion">
                    {contentData?.keywords !== ""
                      && contentData?.keywords.replace(", ", ",")?.split(",")?.map((item) => {
                        return (
                          <Trending title={item} type={type.split("&id=")[0]} classes="video-trend lh-20 fs-14-10 lato-medium black-242 hashtag" image={Hashtag} />
                        )
                      })
                    }
                  </div>}
                <div className="suggestion-section">
                  {isLoading ? (
                    <AllShimmer type="suggestion" tabName={type.split("&id=")[0]} />
                  ) : (
                    suggestionData?.contents && suggestionData?.contents.length > 0 &&
                    // suggestionData?.contents[0]?.suggestions &&
                    suggestionData?.contents?.map((item, j) => {

                      const suggestionDataFilter = item?.list?.filter((item, i) => item.uuid !== type.split("&id=")[1]);

                      if (item.list?.length > 0) {
                        return (
                          <ContentTypesCards
                            key={j}
                            countTitle={item?.title}
                            hide={true}
                            data={suggestionDataFilter}
                            // data={item?.suggestions}
                            count={item?.count}
                            typeId={item?.uuid}
                            suggestionData="Suggested"
                            code={item?.code}
                            removeNotFound={suggestionDataFilter.length == 0 ? false : true}
                          />
                        );
                      }
                    })
                  )}
                </div>
              </div>
            </div>)
        }
        {
          this.state.confirm2 &&
          <Modal
            className="save-confirm-collection-custom-model"
            show={this.state.confirm2}
            centered
            onHide={() => {
              this.setState({ confirm2: !this.state.confirm2 }, () => {
                window.location.href = "/"
              });
            }}
          >

            <Modal.Body className="save-confirm-collection-model-body text-center">
              <Image src={close} onClick={() => {
                this.setState({ confirm2: !this.state.confirm2 }, () => {
                  window.location.href = "/"
                });
              }} alt="canle icon" className="close-btn pointer" />
              <Image src={training} className="mb-10" />
              <h4 className="lato-regular fs-24-16 black-242 w-100 text-center">This content is available only to the subscribed members. Want to be a part of this <span className="primary"> premium community</span>? </h4>
              <div className="d-flex justify-content-center ">
                <div className={`primary-btn py-3 pointer`} onClick={() => { window.location.href = "mymembership" }}>View Membership Plans</div>
              </div>
            </Modal.Body>
          </Modal>
        }
        <AlertModal show={this.state.isAlert} onHide={this.onHide} alertData={this.state.alertData} type={"usage"} modalClass={"alerts-modal"} />

        <Footer />

        <div className="download-btn-container ">
          <button disabled={!this.state.filePath} style={{
            cursor: !this.state.filePath ? 'not-allowed' : 'pointer'
          }} onClick={this.downloadModalOpen} className="primary-btn download-btn ">
            <Image src={download} alt="download" />
            Download
          </button>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({ onBoardingState: state.HomePageState });
const mapDispatchToProps = {
  // getPosts: fetchPosts
};
VideoDetails.propTypes = {
  // getPosts: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoDetails);