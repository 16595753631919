import React from "react";
import { AlertModal, NotFound } from "../common/pages";
import { PageSubHeader } from "../common";
import { Image, Modal, Tab, Tabs } from "react-bootstrap";
import share from "../../assets/images/Share_1.png";
import save from "../../assets/images/save-holo.svg";
import download from "../../assets/images/Download_1.png";
import close from "../../assets/images/cancel-icon.svg";
import search from "../../assets/images/search.svg";
import { MAX_LIMIT, NA, SHARE_BASE_URL, START_PAGE, USER_TRACKING, UserTracking } from "../../utils/Constant";
import { useState } from "react";
import { getDataApi } from "./Api";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";
import { AllShimmer, Spinner } from "../common/components";
import { RWebShare } from "react-web-share";
import SaveCollectionModal from "../common/components/SaveCollectionModal";
import { toast } from "react-toastify";
import { commonAuthPostApi, getDetailsApi } from "../home/Api";
import { BreadCrumb, VideoDetailsDownloadModal } from "../home/_utils";
import { formatDate } from "../../utils/ReusableFunctions";
import PremiumIcon from "../../assets/images/tags.svg";
import save1 from "../../assets/images/save-filed.svg";
import BlackDownload from "../../assets/images/black-download.svg";

/* eslint-disable eqeqeq */



const activityTab = [
  { tab_Name: "Downloads", type: UserTracking.DOWNLOADED },
  { tab_Name: "Co-Brands", type: UserTracking.Cobrand },
  { tab_Name: "Likes", type: UserTracking.LIKED },
  { tab_Name: "Shares", type: UserTracking.SHARED },
  { tab_Name: "Recent Search", type: UserTracking.SEARCH },
];

export default function MyActivity() {
  const history = useHistory();
  const location = useLocation();
  const urlTypeValue = location.search.split("type=")[1]?.replace(/^\w/, (a) => a.toUpperCase());
  const [activityData, setActivityData] = useState([]);
  const [saveTo, setSaveTo] = useState(false)
  const [variationId, setVariationId] = useState('')
  const [id, setId] = useState(null)
  const [confirm, setConfirm] = useState(false)
  const [collectionName, setCollectionName] = useState('Collection Name')
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState(location.search?.split("type=")[1]);
  const [activityDetails, setActivityDetails] = useState({});
  const [show, setShow] = useState(false);
  const [limit, setLimit] = useState();
  const [pending, setPending] = useState();
  const [signUp, setSignUp] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [alertData, setAlertData] = useState({});
  const [modalData, setModalData] = useState({});
  const [isLoading, setIsLoading] = useState(null)
  const params = new URLSearchParams(location.search);

  useEffect(async () => {
    await getActivityData();

  }, [location.search]);

  const getActivityData = async () => {
    setLoading(true)
    const url = (urlTypeValue === UserTracking.SEARCH) ? "user-search" : `user-tracking?type=${urlTypeValue ?? UserTracking.DOWNLOADED}`;
    const response = await getDataApi(url);
    setLoading(false);
    if (urlTypeValue === UserTracking.SEARCH) {
      setActivityData([... new Set(response?.data.map((item) => item.searchText))]);
    } else {
      setActivityData(response?.data);

    }

  }

  const showDownloadModal = () => {
    setShow(!show);
    setSignUp(!signUp);
  }

  const onHide = (() => {
    setIsAlert(false)
    if ((modalData?.data?.limit > 0 && modalData?.data?.pending > 0) || modalData?.data?.limit == 0) {
      setLimit(modalData.data.limit);
      setPending(modalData?.data?.pending);
      showDownloadModal()
    }
  })

  const handleTabSelect = (Type) => {
    if (type !== Type) {
      setLoading(true);
    }
    history.push(`/myactivity/user-tracking?type=${Type.toLowerCase()}`)
    setType(Type);
  };

  function searchHandler(text) {
    let path = `/content-type/All`;
    let search = `${text ? `searchValue=${text}` : ""}&pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}`;
    history.push({
      pathname: path,
      search: search,
    });
  }

  function conformCollectionPopUp(data) {
    setSaveTo(data.label);
    setCollectionName(data.selectedItem);
    setConfirm(!confirm)
  }
  //

  function navigate(contentType, uuid) {
    history.push({ pathname: `/details/${contentType?.replace("/", "_").toLowerCase()}&id=${uuid || START_PAGE}`, state: { 'breadCrumbPath': ['My Activity'] } })
  }
  async function userTrackingHandler(content, id, variationUuid) {
    if (!id) {
      return;
    }
    const info = {
      "data": {
        "contentUuid": id,
        "contentVariationUuid": variationUuid || "",
        "type": content,
      }
    }

    const response = await commonAuthPostApi(USER_TRACKING, info);
    if (response && !response?.message) {
      toast.success(`Succesfully ${content}`);
    }
  }
  const checkSubscription = async (uuid) => {

    const details = {
      data: {
        "contentUuid": uuid,
        "actionType": "Downloaded"

      }
    }
    const res = await commonAuthPostApi("website/content/check-subscription", details)
    if (res?.data) {
      if (res?.data?.alerts?.length > 0) {
        setIsAlert(true)
        setAlertData(res?.data)
        setModalData(res)
        setIsLoading(null)


      }
      else {
        if ((res?.data?.limit > 0 && res?.data?.pending > 0) || res?.data?.limit == 0) {

          setLimit(res?.data?.limit);
          setPending(res?.data?.pending);
          showDownloadModal();
          setIsLoading(null)



        }
      }
    }
  }
  async function likeTrackingHandler(content, id, variationUuid, contentFilePath, item) {
    if (!id) {
      return;
    }

    const data = await getDetailsApi(`website/content/details/${id}`);
    if (data) {

      setActivityDetails(data.data.data);
      checkSubscription(data?.data?.data?.uuid);
      setIsLoading(data?.data?.data?.uuid)
    }

  }
  const handleNavigateTool = ((data) => {
    history.push({
      pathname: `/tools/calculator${data?.link}`,
      state: data
    })
  });

  const navigateToDetails = (item) => {

    history.push({
      pathname: `/details/${item?.contentType?.replace("/", "_").toLowerCase()}&id=${item?.content?.uuid || START_PAGE}`,
      state: {
        'breadCrumbPath': (params.get('key') ?? false) ? [params.get('key'), params.get('value')] : '',
        'searchValue': params.get('searchValue') || ""
      }
    });
  };
  return (
    <div>
      {/* <Header /> */}
      <BreadCrumb path={["My Activity"]} clasese="mb-3-desk" />
      <PageSubHeader
        title="My Activity"
        count=""
        isSearch={false}
        clases="myactivity-pageheader"
      />
      {/* <div className="container"> */}
      <div className="tabs-main-wrapper">
        <Tabs
          defaultActiveKey={UserTracking.DOWNLOADED}
          activeKey={location.search?.split("type=")[1]?.replace(/^\w/, (a) => a.toUpperCase()) || UserTracking.DOWNLOADED}
          id="uncontrolled-tab-example"
          onSelect={handleTabSelect}
        >
          {activityTab?.map((tab, i) => {
            return (
              <Tab
                eventKey={tab.type}
                key={i}
                title={tab.tab_Name}
                className="activity-tab"
              >
                {loading ?
                  <AllShimmer type="MyActivity" tabName={tab.tab_Name} />
                  : (
                    <>
                      {tab.tab_Name === "Recent Search" ? (
                        <div className="tab-recent-wrapper">
                          {activityData?.length > 0 ? (
                            activityData?.map((item, i) => {
                              if (!item) {
                                return null;
                              }

                              return (
                                <div onClick={() => searchHandler(item)} className="resent-serch-box fs-14-12 black-242" key={i}>
                                  <Image src={search} alt="icon" />
                                  {item}
                                </div>
                              );
                            })

                          ) : (
                            <div className="d-flex justify-content-center w-100">
                              <NotFound />
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="tab-content-wrapper">
                          {
                            activityData?.list?.length > 0 ? (
                              activityData?.list?.map((item, i) => {

                                return (
                                  <div key={i} className="activity-box">
                                    <div className="activity-image-frame p-0" style={{ cursor: "pointer" }}>
                                      {item?.thumbnail ?
                                        <Image
                                          onClick={() => { item?.contentType == "Tool" ? handleNavigateTool(item?.tool) : navigateToDetails(item) }}
                                          src={item?.thumbnail}
                                          className="activity-image pointer"
                                          alt={item?.title || NA}
                                        />
                                        : <p className=" lato-regular fs-14-12 p-1" onClick={() => { item?.contentType == "Tool" ? handleNavigateTool(item?.tool) : navigateToDetails(item) }}> {item?.title || NA}</p>}
                                      {item?.content?.isPremium ?
                                        <div className="premium-icon">
                                          <Image height={14} width={18} src={PremiumIcon} alt="icon" />
                                        </div>
                                        :
                                        <div></div>
                                      }
                                    </div>

                                    <div className="activity-content-frame">
                                      <h6 title={item?.title || NA} onClick={() => { item?.contentType == "Tool" ? handleNavigateTool(item?.tool) : navigateToDetails(item) }} className="lato-semibold pointer fs-18-14 black-242">
                                        {item?.title || NA}
                                      </h6>
                                      <h6 className="lato-regular fs-16-12 black-242 op-6 ">
                                        Content type: {item?.contentType || NA}
                                      </h6>
                                      <h6 className="lato-regular fs-16-12 black-242 op-6">
                                        {formatDate(item?.createdAt)}
                                      </h6>
                                      <div className="activity-options">
                                        <RWebShare
                                          data={{
                                            text: "",

                                            url: item?.tool
                                              ? `${SHARE_BASE_URL + "tools/calculator" + (item?.tool?.link || "")}`
                                              : `${SHARE_BASE_URL + "details/" + item?.contentType?.toLowerCase().replace("/", "_") + "&id=" + (item?.content?.uuid || "")}`

                                          }}
                                          onClick={() => userTrackingHandler(UserTracking.SHARED, item?.content?.uuid, item?.contentVariationUuid)}
                                        >
                                          <div className="act-btn lato-regular fs-14-12 black-242">
                                            <Image src={share} alt="image" />{" "}
                                            <span className="desk-show"> Share</span>

                                          </div>
                                        </RWebShare>
                                        {item?.contentType == "Tool" ?
                                          <div></div>

                                          :
                                          <div onClick={() => { setSaveTo(!saveTo); setId(item.content?.uuid); setVariationId(item.contentVariationUuid) }} className="act-btn lato-regular fs-14-12 black-242">
                                            <Image height={18} width={18} src={item?.userStats?.isSaved ? save1 : save} alt="image" />
                                            <span className="desk-show"> Save</span>

                                          </div>

                                        }
                                        {tab.tab_Name == "Likes" || tab?.tab_Name == "Shares" ? <div className="act-btn lato-regular fs-14-12 black-242" onClick={() => likeTrackingHandler(UserTracking.DOWNLOADED, item?.content?.uuid, item?.contentVariationUuid, item?.contentFilePath)}>
                                          {isLoading == item?.content?.uuid ? <span className=' d-flex align-items-center gap-12 justify-content-center'><Spinner /></span> :
                                            <Image src={item?.userStats?.isDownloaded ? BlackDownload : download} alt="image" />}{" "}
                                          <span className="desk-show"> Download</span>
                                        </div>
                                          :
                                          <a rel="noreferrer" download href={item?.contentFilePath} target="_blank" className="act-btn lato-regular fs-14-12 black-242 ">
                                            <Image src={item?.userStats?.isDownloaded ? BlackDownload : download} alt="image" />{" "}
                                            <span className="desk-show"> Download</span>
                                          </a>
                                        }

                                      </div>
                                    </div>
                                  </div>
                                );
                              })


                            ) : (
                              <div className="d-flex justify-content-center w-100">
                                <NotFound />
                              </div>

                            )

                          }
                        </div>
                      )}
                    </>
                  )}
              </Tab>
            );
          })}
        </Tabs>
      </div>
      {/* </div> */}
      {/* <Footer /> */}
      {/* ?============================ SAVE TO MODAL START ========================= */}
      {saveTo &&
        <SaveCollectionModal id={id} variationId={variationId} show={saveTo} handleClose={() => setSaveTo(!saveTo)} collectionPopUp={conformCollectionPopUp} getStats={getActivityData} />
      }
      {/* ?============================ SAVE TO MODAL END  ========================== */}

      {
        confirm &&
        <Modal className="save-confirm-collection-custom-model" centered show={confirm} onHide={() => setConfirm(!confirm)}>

          <Modal.Body className="save-confirm-collection-model-body">
            <Image src={close} onClick={() => setConfirm(!confirm)} alt="canle icon" className="close-btn" />
            <h4 className="lato-regular fs-28-18 black-242 w-100 text-center">{collectionName}</h4>
            <div className="d-flex justify-content-center ">
              <div className={`primary-btn py-3 pointer`} onClick={() => { history.push('/mycollections') }}>View Collection</div>
            </div>
          </Modal.Body>
        </Modal>

      }
      {
        show &&
        <VideoDetailsDownloadModal
          show={show}
          handleClose={showDownloadModal}
          contentType={activityDetails?.contentType.contentTypeName}
          detailsData={activityDetails}
          downloadPath={activityDetails?.defaultVariation?.contentFilePath}
          variation={activityDetails?.contentVariation}
          languageColor={{ "language": activityDetails?.defaultVariation?.language?.languageName, "color": activityDetails?.defaultVariation?.color }}
          updatedThings={{ "tittle": activityDetails?.defaultVariation?.title, "description": activityDetails?.defaultVariation?.description }}
          variationId={activityDetails?.defaultVariation?.uuid}
          signup={false}
          isPremium={activityDetails?.isPremium}
          limit={limit}
          pending={pending}

        />
      }

      <AlertModal show={isAlert} onHide={onHide} alertData={alertData} type={"usage"} modalClass={"alerts-modal"} />

    </div>
  );
}
