import React from 'react';
import InstagramPlanner from "../../assets/images/icon/instagram-planner-icon.svg";
import LinkedinPlanner from "../../assets/images/icon/linkedin-planner-icon.svg";
import TwitterPlanner from "../../assets/images/icon/twitter-planner-icon.svg";
import FacebookPlanner from "../../assets/images/icon/facebook-planner-icon.svg";
import { Image } from 'react-bootstrap';

export default function PerformanceTable() {
    const data = [
        {
            rowName: 'Accounts Reached',
            twitter: 1200,
            instagram: 3500,
            linkedIn: 800,
            facebook: 2700,
        },
        {
            rowName: 'Accounts Engaged',
            twitter: 300,
            instagram: 1500,
            linkedIn: 200,
            facebook: 900,
        },
        {
            rowName: 'Likes',
            twitter: 180,
            instagram: 1200,
            linkedIn: 130,
            facebook: 800,
        },
        {
            rowName: 'Shares',
            twitter: 50,
            instagram: 300,
            linkedIn: 40,
            facebook: 200,
        },
        {
            rowName: 'Saved',
            twitter: 30,
            instagram: 500,
            linkedIn: 25,
            facebook: 150,
        },
        {
            rowName: 'Comments',
            twitter: 70,
            instagram: 600,
            linkedIn: 90,
            facebook: 400,
        },
    ];

    return (
        <table className="table table-borderless performance-table">
            <thead className='centered-table'>
                <tr >
                    <th scope="col" style={{ width: '15%' }}>
                    </th>
                    <th scope="col centered-table" >
                        <div className="social-icons-wrapper">
                            <div className='image-outer'>

                            <Image className='icon-image' width={18} height={18} src={TwitterPlanner} alt="Twitter" />
                            </div>

                        </div>
                            <p className='lato-bold fs-14-12 black-242 d-none d-md-block'>Twitter</p>
                    </th>
                    <th scope="col" >
                        <div className="social-icons-wrapper">
                        <div className='image-outer'>
                            <Image className='icon-image' width={18} height={18} src={InstagramPlanner} alt="Instagram" />
                        </div>
                        </div>
                            <p className='lato-bold fs-14-12 black-242 d-none d-md-block'>Instagram</p>
                    </th>
                    <th scope="col" >
                        <div className="social-icons-wrapper">
                        <div className='image-outer'>
                            <Image className='icon-image' width={18} height={18} src={LinkedinPlanner} alt="LinkedIn" />
                        </div>
                        </div>
                            <p className='lato-bold fs-14-12 black-242 d-none d-md-block'>LinkedIn</p>
                    </th>
                    <th scope="col" >
                        <div className="social-icons-wrapper">
                        <div className='image-outer'>
                            <Image className='icon-image' width={18} height={18} src={FacebookPlanner} alt="Facebook" />
                        </div>
                        </div>
                            <p className='lato-bold fs-14-12 black-242 d-none d-md-block'>Facebook</p>
                    </th>
                </tr>
            </thead>
            <tbody>
                {data.map((row, index) => (
                    <tr className="centered-table" key={index}>
                        <th className="left-align" scope="row">{row?.rowName}</th>
                        <td >{row?.twitter}</td>
                        <td>{row?.instagram}</td>
                        <td>{row?.linkedIn}</td>
                        <td>{row?.facebook}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}