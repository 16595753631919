import React, { useState } from 'react';
import { Image } from "react-bootstrap";
import { API_LIMIT, START_PAGE } from './Constant';
import { toast } from 'react-toastify';
import download from "../assets/images/Download_1.png";
import share from "../assets/images/Share_1.png";
import { RWebShare } from 'react-web-share';
import { getCommonApi } from '../app/events/Api';
import moment from 'moment';
import html2canvas from 'html2canvas';
import { getToken } from './ManageToken';
import { commonAuthPostApi, getAuthDataApi } from '../app/home/Api';
import { SignUp, Spinner } from '../app/common/components';
import { commonAuthApis } from '../app/profile/Api';
/* eslint-disable eqeqeq */



export const getUser = () => {
  return JSON.parse(localStorage.getItem('finUserDetails'));
}

// GET EXTENSION NAME WRAPPER
export const getExtensionNameWrapper = (attachmentName, attachmentUrl, className) => {
  const re = /(?:\.([^.]+))?$/;
  let ext = re.exec(attachmentName)[1];
  let attachmentWrapper;

  if (ext === "jpeg" || ext === "png" || ext === "jpg") {
    attachmentWrapper = (
      <Image
        src={attachmentUrl}
        alt="link-preview"
      />
    )
  } else {
    attachmentWrapper = (
      <span className={className}>{ext}</span>
    )
  }
  return attachmentWrapper
}


export const formatCurrencyNumber = (num) => {
  if (num >= 10000000) { // 1 Crore or more
    return (num / 10000000).toFixed(0) + 'Cr';
  } else if (num >= 100000) { // 1 Lakh or more
    return (num / 100000).toFixed(0) + 'L';
  } else if (num >= 1000) { // 1 Thousand or more
    return (num / 1000).toFixed(0) + 'K';
  } else {
    return num.toString(); // Less than 1000
  }
}


export const formatNumber = (num, precision = 0) => {
  const map = [
    { suffix: 'T', threshold: 1e12 },
    { suffix: 'B', threshold: 1e9 },
    { suffix: 'M', threshold: 1e6 },
    { suffix: 'K', threshold: 1e3 },
    { suffix: '', threshold: 1 },
  ];

  const found = map.find((x) => Math.abs(num) >= x.threshold);
  if (found) {
    const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
    return formatted;
  }
  return num;
}

export const calculateTotalPageCount = (totalCount) => {
  return Math.ceil(totalCount / API_LIMIT)
}

export const navigateSetUrl = (history, pathname, search) => {
  history.push({
    ...(pathname && { pathname: pathname }),
    ...(search && { search: search })
  })
}

export const getSearchParams = (location) => {
  const params = new URLSearchParams(location.search);
  // console.log('params', params);
  let searchValue = params.get("searchValue") || "";
  let page = params.get("pageNumber") || START_PAGE;
  return ({ searchValue, page })
}

// export const formatDate = (timestamp) => {
//   let date = new Date(timestamp);
//   let current = new Date();
//   let diffTime = Math.abs(current - date);
//   let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
//   return (diffDays + " days ago");
// }


export const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  const current = new Date();
  current.setHours(0);
  current.setMinutes(0);
  current.setSeconds(0);
  current.setMilliseconds(0);

  const diffTime = Math.abs(current - date);


  const diffDays = (diffTime / (1000 * 60 * 60 * 24));




  if (diffDays == 0) {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const amPm = hours < 12 ? 'AM' : 'PM';
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
    return `${formattedHours}:${minutes} ${amPm} today`;
  }
  else if (diffDays === 1) {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const amPm = hours < 12 ? 'AM' : 'PM';
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
    return `${formattedHours}:${minutes} ${amPm} yesterday`;

  }
  else if (diffDays <= 6) {

    return `${diffDays} day${diffDays !== 1 ? 's' : ''} ago`;
  } else if (diffDays <= 13) {

    const weeks = Math.floor(diffDays / 7);
    return `${weeks} week${weeks !== 1 ? 's' : ''} ago`;
  } else if (diffDays <= 30) {

    return '2 weeks ago';
  } else if (diffDays <= 365) {

    const months = Math.floor(diffDays / 30);
    return `${months} month${months !== 1 ? 's' : ''} ago`;
  } else {

    const years = Math.floor(diffDays / 365);
    return `${years} year${years !== 1 ? 's' : ''} ago`;
  }
};

export function isEmptyObj(obj) {
  return Object.keys(obj).length !== 0;
}

export function convertToTitleCase(str) {
  // console.log("getting", str)
  // console.log("conert to cammo;e", str.toLowerCase().replace(/-\w/g, match => match.charAt(1).toUpperCase()))
  return str.toLowerCase().replace(/-\w/g, match => match.charAt(1).toUpperCase());
}
export const toCamelCase = (sentence) => sentence.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');

export const formatCurrency = (x = 0) => {
  return parseInt(x)?.toLocaleString('en-IN');
}

// export const showShare = (calculator) => {
//   return (
//     <div className="result-download-icon">
//       <span>
//         <Image src={download} alt="download" />
//       </span>{" "}
//       <span>
//         <RWebShare
//           data={{
//             text: calculator?.name,
//             url: calculator?.url,
//             title: calculator?.name,
//           }}
//         >
//           <Image src={share} alt="download" />
//         </RWebShare>
//       </span>{" "}
//     </div>
//   )
// }

export const ShowShare = ({ calculator, isDisable, setIsDisable, clickHandler }) => {
  const [signup, setSignup] = useState(false);
  const token = getToken();
  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
  const handleClick = (async () => {
    setIsDisable(true);
    await delay(3000);
    await clickHandler();

  })
  return (
    <div className="result-download-icon">
      <span onClick={!isDisable ? token ? handleClick : () => setSignup(true) : ""}>
        {isDisable ? <span className=' d-flex align-items-center gap-12 justify-content-center'><Spinner /> </span> : <Image src={download} alt="download" />}
      </span>{" "}
      <span>
        <RWebShare
          data={{
            text: calculator?.name,
            url: calculator?.url,
            title: calculator?.name,
          }}
        >
          <Image src={share} alt="share" />
        </RWebShare>
      </span>{" "}
      {signup && <SignUp show={signup} handleClose={() => setSignup(!signup)} />}
    </div>
  );
};
export const isPremiumAccess = () => {
  return JSON.parse(localStorage.getItem("finUserDetails"))?.isPremium || false;

}
export const isResOk = (res) => {
  return (res > 199 && res < 230);

}
export const isEventExpired = (endDate) => {
  return moment(endDate).isBefore(moment());
};
export const reUpdatedUserDetails = async () => {
  const res = await getCommonApi('user/details');
  if (res.status >= 200 && res.status <= 210) {
    localStorage.setItem("finUserDetails", JSON.stringify(res.data?.data));
  }

}
export function isToday(date) {
  const publishedAtDate = moment(date);
  const currentDate = moment();
  const hoursDifference = currentDate.diff(publishedAtDate, 'hours');
  // console.log(publishedAtDate)
  // console.log("Chedk is upload is done hovers or it become yestaday",hoursDifference)
  return hoursDifference < 24;
}






export async function convertImageToBase64(url) {
  try {

    const response = await fetch(url);
    if (!response.ok) throw new Error('Network response was not ok');

    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Error fetching image:', error);
    throw error;
  }
}



export const CheckToolsSubscription = async (textLogo, downloadScreenshotFunc, toolUuid, imageData, setIsDisable, setAlertData, setIsAlert, setModalData) => {
  const { cobrandingLabelling, investor } = textLogo;
  const { startLogo, startText } = cobrandingLabelling;
  const isCobrand = Object.values(cobrandingLabelling).includes(true);

  const token = getToken();
  if (token) {
    const payload = {
      "data": {
        "tool": {
          "uuid": toolUuid
        },
        "type": isCobrand ? "Cobrand" : "Downloaded",
        "cobrandType": startLogo && startText
          ? "LOGO-TEXT"
          : startLogo
            ? "LOGO"
            : startText
              ? "TEXT"
              : "",
        "personalizedMessage": investor?.pesronalizedText || ""
      }

    }
    //  console.log("Test cobrand=>", isCobrand);
    const formData = new FormData();
    formData.append("key", JSON.stringify(payload))
    formData.append("file", imageData)

    const check = await getAuthDataApi("website/tools/check-subscription/Cobrand")

    if (check?.alert?.length > 0 && check?.pending <= 0) {

      setAlertData(check);
      setIsAlert(true);
      setModalData(check);
      setIsDisable(false)



    }

    else {

      if ((check?.limit > 0 && check?.pending > 0) || check?.limit == 0) {


        const res = await commonAuthPostApi("website/tools/cobrand", formData)

        if (res.message) {
          setIsDisable(false);

          return
        }
        if (res) {
          // window.open(res?.data, "_blank");
          // toast.success("Downloaded Successfully")
          const downloadUrl = res?.data;
          const response = await fetch(downloadUrl);
          const blob = await response.blob();
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = downloadUrl.split("/").pop();
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(link.href);
          toast.success("Downloaded Successfully");
          setIsDisable(false);

        }
        else {
          toast.error("Something went wrong");
          setIsDisable(false);

        }
        // }
      }
    }
  }
  else {
    toast.error("You Need To Login")
  }


  //  const isValidSub = await checkToolsSubscriptionStatus(`website/tools/check-subscription/${isCobrand ? UserTracking["Cobrand"] :UserTracking["DOWNLOADED"]}`, '','get');
  //   if(isResOk(isValidSub.status)){
  //    console.log(isValidSub)
  //    downloadScreenshotFunc && await downloadScreenshotFunc();
  //    const res = await checkToolsSubscriptionStatus(`website/tools/utilize-subscription/${isCobrand ? UserTracking["Cobrand"] :UserTracking["DOWNLOADED"]}`, '');
  //   }
}

export const captureImageHandler = (onDownloadScreenshot, screenshotRef, setSignup, setImageData) => {
  // onDownloadScreenshot(() => {
  if (screenshotRef.current && getToken()) {
    setTimeout(() => {
      if (screenshotRef.current && getToken()) {
        html2canvas(screenshotRef.current, { useCORS: true }).then(canvas => {
          const image = canvas.toDataURL('image/png');
          const byteString = atob(image.split(',')[1]);
          const mimeString = image.split(',')[0].split(':')[1].split(';')[0];
          const ab = new ArrayBuffer(byteString.length);
          const ia = new Uint8Array(ab);
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }

          const blob = new Blob([ab], { type: mimeString }); // Convert Blob to File
          const file = new File([blob], 'screenshot.png', { type: mimeString });
          setImageData(file);

        });
      } else {
        setSignup(true);
      }
    }, 1000);
    // html2canvas(screenshotRef.current, { useCORS: true, }).then(canvas => {
    //   const image = canvas.toDataURL('image/png');
    //   const link = document.createElement('a');
    //   link.href = image;
    //   link.download = 'calculator-output.png';
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    // });
  } else {
    setSignup(true)
  }
  // });
};

export const formattedDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();

  const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const weekday = weekdays[date.getDay()];

  return `${day}-${month}-${year} | ${weekday}`;
};

export const groupDataByDate = (data) => {
  return data?.reduce((acc, item) => {
    const newDate = formattedDate(item?.date);
    const existingGroup = acc.find(group => group.date === newDate);

    if (existingGroup) {
      existingGroup.content.push(item);
    } else {
      acc.push({
        date: newDate,
        content: [item]
      });
    }

    return acc;
  }, []);
};


export const getUserDetails = () => {
  return JSON.parse(localStorage.getItem('finUserDetails'));


}

export const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export const getDetails = async () => {
  const details = await getAuthDataApi(`user/details`, '', 'get');
  if (details) {
    localStorage.setItem('finUserDetails', JSON.stringify(details));

  }

}
export const replaceUrlCharacters = (itemTitle) => {
  if (itemTitle == null) return itemTitle;
  itemTitle = itemTitle.replace("+", "%2B");
  itemTitle = itemTitle.replace("&", "%26");
  return itemTitle;
}


export const ToolsScreenshotHeader = (cobrandingLabelling) => {
  const userProfileDetails = getUser();

  const [companyLogo, setCompanyLogo] = useState({
    logoUrl: userProfileDetails?.organizationLogoPath ?? "",
    logoFile: null,
  });

  const { logoUrl, logoFile } = companyLogo;

  const [brandingText, setBrandingText] = useState({
    company: userProfileDetails?.companyName ?? "",
    arnNo: userProfileDetails?.arnNumber ?? "",
    companyEmail: userProfileDetails?.emailAddress ?? "",
    companyNo: userProfileDetails?.mobileNumber ?? "",
  });

  return (
    <div className="tools-calculator-output">
      {cobrandingLabelling?.startLogo && (
        <div
          className={`startlabeling-size ${cobrandingLabelling?.startText && "justify-content-end"
            }`}
        >
          {/* <Image src={logoUrl} alt="logo" /> */}
        </div>
      )}

      {cobrandingLabelling?.startText && (
        <div
          className={`branding-details-box ${cobrandingLabelling?.startLogo && "justify-content-start"
            }`}
        >
          <div>
            <p className="lato-regular f-s-12 lh-15 mb-2 black-242">
              {brandingText.companyEmail}
            </p>
            <p className="lato-regular f-s-12 lh-15 black-242">
              {brandingText.company}
            </p>
          </div>
          <div>
            <p className="lato-regular f-s-12 lh-15 mb-2 black-242">
              {brandingText.companyNo}
            </p>
            <p className="lato-regular f-s-12 lh-15 black-242">
              {brandingText.arnNo}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export const toolsInvestorDetails = (investor) => {
  return (
    <div>
      <p className=" f-s-16 lh-24 black-242 op-7 pt-3 text-center">Dear {investor?.investorName ? investor?.investorName : 'Investor'}</p>
      {investor?.personalizedText &&
        <p className=" f-s-16 lh-24 black-242 op-7 pt-2 text-center"> {investor?.personalizedText}</p>
      }
    </div>

  )
}

export const toolsDisclaimer = () => {
  return (
    <div>
      <p className=" f-s-12 lh-14 black-242 op-9 pt-5 mt-5 text-center">Note: Returns are neither assured nor guaranteed. There is no warranty about the accuracy of the calculator.<br />
        Disclaimer: Mutual Fund investments are subject to market risks, read all scheme related documents carefully.
      </p>
      <div className="bg-primary lh-16 mt-5">&nbspl;</div>
    </div>

  )
}

export const groupData = (data) => {
  return data.reduce((acc, item) => {
    const formattedDate = moment(item.date);
    const start = formattedDate.format('YYYY-MM-DD HH:mm:ss');
    const end = formattedDate.add(1, 'hour').format('YYYY-MM-DD HH:mm:ss');
    const isPosted = item.isPosted || false; // Assuming isPosted defaults to false if not provided
    const existingGroupIndex = acc.findIndex(group => group.start === start);

    if (existingGroupIndex !== -1) {
      if (isPosted) {
        acc[existingGroupIndex].type = 'published';
        acc[existingGroupIndex].postedCount += 1;
        acc[existingGroupIndex].title = `${acc[existingGroupIndex].postedCount} ${acc[existingGroupIndex].postedCount === 1 ? 'post published' : 'posts published'}`;
        acc[existingGroupIndex].color = 'green';
      } else {
        acc[existingGroupIndex].type = 'scheduled';
        acc[existingGroupIndex].scheduledCount += 1;
        acc[existingGroupIndex].title = `${acc[existingGroupIndex].scheduledCount} ${acc[existingGroupIndex].scheduledCount === 1 ? 'post scheduled' : 'posts scheduled'}`;
        acc[existingGroupIndex].color = '#270089';
      }
      acc[existingGroupIndex].end = end;
    } else {
      const newGroup = {
        start: start,
        end: end,
        type: isPosted ? 'published' : 'scheduled',
        scheduledCount: isPosted ? undefined : 1,
        postedCount: isPosted ? 1 : undefined,
        title: isPosted ? '1 post published' : '1 post scheduled',
        color: isPosted ? 'green' : '#270089'
      };
      acc.push(newGroup);
    }

    return acc;
  }, []);
};

export const downloadFromS3 = async (imageUrl, getStats = () => { }, setLoading, setDownload) => {
  try {
    const response = await fetch(imageUrl, { mode: 'cors' });
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "downloaded";
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);

    getStats();


    setTimeout(() => {
      if (setDownload) {

        setDownload(true);
      }
    }, 1300);
  } catch (error) {
    console.error("Error downloading the image:", error);
    window.open(imageUrl, '_blank');
    getStats();
    setTimeout(() => {
      if (setDownload) {
        setDownload(true);
      }
    }, 1300);
  }
  finally {
    setLoading(false);
  }

};


export const handleEventRegistration = async (data, setLoading, setWarning, history) => {
  setLoading(true);
  const subscription = {
    "data": {
      eventUuid: data?.uuid
    }
  }
  const check = await commonAuthPostApi(`website/events/check-subscription`, subscription)
    ;

  if ((check?.data?.limit > 0 && check?.data?.pending >= 0) || check?.data?.limit == 0) {
    if (data?.contentCreator?.isFinpediaUser) {
      history.push({ pathname: '/event-chekout', state: { detail: data } })
      setLoading(false);

    } else {
      // CALL API FOR REGISTER IN FIND DATA
      const info = {
        "data": {
          "eventUuid": data?.uuid
        }
      }
      const res = await commonAuthApis('/eventRegistration', info)
      // console.log(res)
      if (res?.status > 199 && res?.status < 240) {

        const registrationLink = data?.registrationLink;
        const validUrl = registrationLink && (registrationLink.startsWith('http://') || registrationLink.startsWith('https://'))
          ? registrationLink
          : `https://${registrationLink}`;

        window.open(validUrl || "", '_blank');
        setLoading(false);
      }
    }
  }
  else {
    setWarning(true);
    setLoading(false);
  }
};
export const isNonFinUser = async (data, setLoading, setWarning, history) => {
  // console.log("Yes this event is registered");
  setLoading(true)
  const subscription = {
    "data": {
      eventUuid: data?.uuid
    }
  }
  const check = await commonAuthPostApi(`website/events/check-subscription`, subscription)
    ;

  if ((check?.data?.limit > 0 && check?.data?.pending >= 0) || check?.data?.limit == 0) {


    if (!data?.contentCreator?.isFinpediaUser) {
      const registrationLink = data?.registrationLink;
      const validUrl = registrationLink && (registrationLink.startsWith('http://') || registrationLink.startsWith('https://'))
        ? registrationLink
        : `https://${registrationLink}`;

      window.open(validUrl || "", '_blank');
      setLoading(false);
    }
  }
  else {
    setWarning(true);
    setLoading(false)
  }
};


