import React from 'react';

import { connect } from "react-redux";

import { BreadCrumb } from '../home/_utils';
import CancelImage from '../../assets/images/cancle-input.svg'
import logo from '../../assets/images/brand-logo.png'
import coupon from '../../assets/images/coupon-ticket.svg'
import { BaseReactComponent, CustomTextControl, Form, FormElement, FormSubmitButton, FormValidator, SelectControl } from '../../utils/form';
import { Button, Col, Image, Row } from 'react-bootstrap';
import { getDetails, getUser, reUpdatedUserDetails } from '../../utils/ReusableFunctions';
import { commonAuthApis } from '../profile/Api';
import { SHARE_RAZ_KEY, loadScript, resetInDays } from '../../utils/Constant';
import { toast } from 'react-toastify';
import { getAuthDataApi } from '../home/Api';
import { Spinner } from './components';
import moment from 'moment';
import arrow from '../../assets/images/arrow.svg';
import UnusedBenefitIcon from "../../assets/images/icon/unusedBenefitIcon.svg"
import CrossIcon from "../../assets/images/icon/cross-modal-icon.svg"
import { CustomModal } from './pages';

/* eslint-disable eqeqeq */


class CheckOutPage extends BaseReactComponent {
  constructor(props) {
    super(props);
    const userDetails = getUser() || {};
    const breakup = props.location.state?.data || null;
    const requestObj = props?.location?.state?.requestObj || null;

    // console.log("Exits plans -",requestObj,requestObj?.data.newPlanUuid?"Upgrade":"Process");
    const isNewPurchase = props.location.state.isNewPurchase || null;
    const breakupAmount = props.location.state.breakupAmount || null;

    this.state = {
      userDetails,
      firstName: userDetails?.firstName || null,
      lastName: userDetails?.lastName || null,
      // name: userDetails?.firstName + " " + userDetails?.lastName || "",
      companyName: userDetails?.companyName || null,
      companyAddress: userDetails?.companyAddress || null,
      emailAddress: userDetails?.emailAddress || null,
      arnNumber: userDetails?.arnNumber || null,
      city: userDetails?.city || null,
      state: userDetails?.state || null,
      pincode: userDetails?.pincode || null,
      mobileNumber: userDetails?.mobileNumber || null,
      breakup,
      requestObj,
      isNewPurchase,
      gstCoupon: true,
      couponCode: true,
      couponApplied: false,
      coupon: "",
      allStateData: [],
      allCityData: [],
      isUpdataing: false,
      oldPlan: {},
      user: {},
      alertMessage: "",
      isAlert: false,
      isUnused: false,
      breakupAmount
    }
  }
  getDataHandler = async (url) => {
    const data = await getAuthDataApi(url);
    return data;
  }

  fetchData = async () => {
    const data = await getAuthDataApi(`user/billing-plan/available`, '', 'get');
    this.setState({ oldPlan: data.userSubscriptions[0]?.userSubscription });

  };


  componentDidMount() {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");


    this.getDataHandler("/website/location")
      .then((data) => {
        if (Array.isArray(data)) {
          this.setState({ allStateData: data });
        }
      })
      .catch((error) => {
        // console.error("Error fetching data:", error);
      });
    if (this.state.state) {
      this.getCity();
    }
    this.fetchData()


  }
  getCity = () => {
    this.getDataHandler(`/website/location/${this.state.state}`)
      .then((data) => {
        if (Array.isArray(data)) {
          this.setState({ allCityData: data });
        }
      })
      .catch((error) => {
        // console.error("Error fetching data:", error);
      });
  }


  isUpdated = (name) => {
    const { userDetails } = this.state;
    return userDetails[name] !== this.state[name];
  }
  onValidSubmit = async (e) => {
    // e.preventDefault();
    this.setState({ isUpdataing: true });
    const { firstName, lastName, companyName, arnNumber, city, state, pincode, mobileNumber, companyAddress } = this.state;
    const userInfo = {
      data: {
        ...(this.isUpdated("firstName") ? { firstName } : {}),
        ...(this.isUpdated("lastName") ? { lastName } : {}),
        ...(this.isUpdated("companyName") ? { companyName } : {}),
        // ...(this.isUpdated("emailAddress") ? { unverifiedEmailAddress: emailAddress } : {}),
        ...(this.isUpdated("arnNumber") ? { arnNumber } : {}),
        ...(this.isUpdated("city") ? { city } : {}),
        ...(this.isUpdated("state") ? { state } : {}),
        ...(this.isUpdated("pincode") ? { pincode } : {}),
        ...(this.isUpdated("mobileNumber") ? { mobileNumber } : {}),
        ...(this.isUpdated("companyAddress") ? { companyAddress } : {})
      }
    }


    if ((Object.keys(userInfo.data).length) > 0) {


      const res = await commonAuthApis("user/update-profile", userInfo, "put");

      localStorage.setItem('finUserDetails', JSON.stringify(res?.data?.data));
      if (res.status >= 200 && res.status <= 210) {
        this.reaxorpayHandler()
      }
    } else {
      this.reaxorpayHandler();

    }
  }
  reaxorpayHandler = async () => {
    // console.log(window)
    let orderData = {
      "data": {
        "amount": Math.round(this.state.breakup.breakup.finalAmount * 100),
        "currency": "INR",
        "receipt": "Finpedia",
        "premiumPlanUuid": this.state.breakup?.premiumPlan?.uuid,
        "subPremiumPlanUuid": this.state.breakup?.subPremiumPlan.uuid,
        "subPremiumPlanVariationUuid": this.state.breakup?.subPremiumPlanVariation?.uuid,
        "couponCode": this.state.coupon,
        "emailAddress": this.state.emailAddress
      }
    }
    const res = await commonAuthApis('payment/order', orderData);
    this.setState({ isUpdataing: false });

    if (res.status >= 200 && res.status <= 210) {

      const self = this;
      const orderRes = res.data?.data;

      if (this.state.breakup?.breakup?.finalAmount === 0) {
        let response = {
          amount: this.state.breakup?.breakup?.netAmount,
          couponCode: this.state.coupon,
          emailAddress: this.state.emailAddress
        }

        const res = await commonAuthApis(`user/billing-plan/${orderRes?.uuid}/process`, { "data": response });

        if (res.status >= 200 && res.status <= 210) {
          toast.success('payment successful')
          sessionStorage.setItem('finSubscriptionDone', true);
          sessionStorage.setItem('finSubscriptionIsTopup', res?.data?.data?.userSubscription?.isTopup);
          sessionStorage.setItem('invoicePdf', res?.data?.data?.userBillingPlan?.invoicePdfPath);
          if (res?.data?.data.userSubscription?.level == 0) {
            sessionStorage.setItem('finSubscriptionIsBasic', JSON.stringify(res?.data?.data?.userSubscription?.subPremiumPlan?.name));
          }

          reUpdatedUserDetails()
          getDetails();
          this.props.history.push({
            pathname: '/mymembership',
            state: { path: res.data.data?.userBillingPlan?.invoicePdfPath || "" },
          });
        }


      } else {

        var options = {
          "key": SHARE_RAZ_KEY, // Enter the Key ID generated from the Dashboard
          "amount": Math.round(res?.data?.data.amount), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          "currency": res?.data?.data.currency,
          "name": "Thefinpedia",
          "description": "Test Transaction",
          "image": logo,
          "emailAddress": this.state.emailAddress,
          "order_id": res?.data?.data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          // "callback_url": "http://13.200.83.191:3001/finpedia/v1/payment/checkout",
          "handler": async function (response) {
            response.userSubscriptionUuid = orderRes?.userSubscription?.uuid;
            response.couponAmount = self.state.breakup?.breakup?.couponDiscountedAmount || 0;
            response.emailAddress = self.state.emailAddress || "";
            const res = await commonAuthApis('payment/checkout', { "data": response });
            if (res.status >= 200 && res.status <= 210) {
              toast.success('payment successful')
              sessionStorage.setItem('finSubscriptionDone', true);
              sessionStorage.setItem('finSubscriptionIsTopup', res?.data?.data?.userSubscription?.isTopup);
              sessionStorage.setItem('invoicePdf', res?.data?.data?.userBillingPlan?.invoicePdfPath);
              if (res?.data?.data.userSubscription?.level == 0) {
                sessionStorage.setItem('finSubscriptionIsBasic', JSON.stringify(res?.data?.data?.userSubscription?.subPremiumPlan?.name));
              }

              reUpdatedUserDetails()
              getDetails();
              self.props.history.push({
                pathname: '/mymembership',
                state: { path: res.data.data?.userBillingPlan?.invoicePdfPath || "" },
              });
            }
          },
          "prefill": {
            "name": this.state.name,
            "email": this.state.emailAddress,
            "contact": this.state?.mobileNumber
          },
          "notes": {
            "address": "Razorpay Corporate Office"
          },
          "theme": {
            "color": "#3399cc"
          }
        };
        let rzp1 = window.Razorpay(options);
        rzp1.open();

      }



    }
  }

  aplyCouponHandler = async (remove) => {

    const couponData = this.state.requestObj?.data;
    if (!remove) {

      couponData.couponCode = this.state.coupon;
    } else {
      delete couponData.couponCode;
    }

    const res = await commonAuthApis('user/billing-plan/purchase-amount-breakup', { 'data': couponData });
    if (res.status === 200) {
      this.setState({ couponApplied: true, breakup: res?.data?.data, breakupAmount: res?.data?.data?.breakup?.previousSubcriptionBreakup });

    }

  }

  handleUnused = () => {
    this.setState({ isUnused: true })

  }
  handleClose = () => {
    this.setState({ isUnused: false })

  }



  remainsTimeHandler = (endDate) => {
    const currentDate = moment();
    const givenDate = moment(endDate);

    const duration = moment.duration(givenDate.diff(currentDate));
    const months = duration.months();
    const days = duration.days();

    return `( ${months === 0 ? '' : months + ' months'} ${days === 0 ? '' : days + ' days'} unused )`;
  }
  render() {
    const { couponCode, breakup, firstName, lastName, emailAddress, companyName, arnNumber, state, city, pincode, mobileNumber, companyAddress, breakupAmount } = this.state;
    const isFormValid = firstName && lastName && emailAddress && FormValidator.isEmail(emailAddress) && companyName && arnNumber && state && city && pincode && mobileNumber && companyAddress;
    return (
      <>
        <section className='checkout-section'>
          {/* <Header /> */}
          <div className="checkout-main-wrapper">
            {/* <div className="container"> */}
            <BreadCrumb path={['My Membership', 'Checkout']} />
            <Form onValidSubmit={this.onValidSubmit}>
              <div className="checkout-frame">
                <div className="billing-information w-51">
                  <h4 className='lato-regular fs-32-24 black-242'>Billing Information</h4>

                  <Row>
                    <Col md={6}>
                      <FormElement
                        valueLink={this.linkState(this, "firstName")}
                        label="First Name"
                        required
                        control={{
                          type: CustomTextControl,
                          settings: {
                            type: "text",
                            disabled: false,
                            placeholder: "Enter First Name"
                          },
                        }}
                        classes={{
                          inputField: "form-control",
                          label: "form-label-custom"
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <FormElement
                        valueLink={this.linkState(this, "lastName")}
                        label="Last Name"
                        required

                        control={{
                          type: CustomTextControl,
                          settings: {
                            type: "text",
                            disabled: false,
                            placeholder: "Enter Last Name"
                          },
                        }}
                        classes={{
                          inputField: "form-control",
                          label: "form-label-custom"
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <FormElement
                        valueLink={this.linkState(this, "emailAddress")}
                        label="Email Address"
                        required

                        validations={[
                          {
                            validate: FormValidator.isEmail,
                            message: "Please enter a valid email",
                          },
                        ]}
                        control={{
                          type: CustomTextControl,
                          settings: {
                            type: "text",
                            disabled: false,
                            placeholder: "Enter email address",
                          },
                        }}
                        classes={{
                          inputField: "form-control",
                          label: "form-label-custom"
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <FormElement
                        valueLink={this.linkState(this, "companyName")}
                        label="Company Name"
                        required

                        control={{
                          type: CustomTextControl,
                          settings: {
                            type: "text",
                            disabled: false,
                            // options: this.state.CompanyName,
                            // multiple: false,
                            searchable: true,
                            placeholder: "Enter company name",
                            // onChangeCallback: (onBlur) => {
                            // onBlur(this.state.companyName);
                            // }
                          }
                        }}
                        classes={{
                          inputField: "form-control",
                          label: "form-label-custom"
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <FormElement
                        valueLink={this.linkState(this, "companyAddress")}
                        label="Company Address"
                        required

                        control={{
                          type: CustomTextControl,
                          settings: {
                            type: "text",
                            disabled: false,
                            // options: this.state.CompanyName,
                            // multiple: false,
                            searchable: true,
                            placeholder: "Enter company address",
                            // onChangeCallback: (onBlur) => {
                            // onBlur(this.state.companyName);
                            // }
                          }
                        }}
                        classes={{
                          inputField: "form-control",
                          label: "form-label-custom"
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <FormElement
                        valueLink={this.linkState(this, "arnNumber")}
                        label="ARN/RIA Number"
                        required



                        control={{
                          type: CustomTextControl,
                          settings: {
                            type: "text",
                            disabled: false,
                            placeholder: "Enter arn/rai number",
                          }
                        }}
                        classes={{
                          inputField: "form-control",
                          label: "form-label-custom"
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <FormElement
                        valueLink={this.linkState(this, "gstNo")}
                        label="GST Number"
                        control={{
                          type: CustomTextControl,
                          settings: {
                            type: "text",
                            disabled: false,
                            placeholder: "Enter GST number",
                          }
                        }}
                        classes={{
                          inputField: "form-control",
                          label: "form-label-custom"
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <FormElement
                        valueLink={this.linkState(this, "state")}
                        label={<span style={{ color: '#242e3d', fontSize: "1.8rem", fontWeight: "600", fontFamily: '"Lato" ,sans-serif' }}>State</span>}
                        required
                        control={{
                          type: SelectControl,
                          settings: {
                            options: this.state.allStateData?.map((item, i) => { return ({ "value": item, "label": item }) }),
                            multiple: false,
                            searchable: true,
                            placeholder: "Select State",
                            onChangeCallback: (onBlur) => {
                              this.setState({ city: "" }, () => {
                                this.getCity()
                              })

                              onBlur(this.state.state);
                            }
                          }
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <FormElement
                        valueLink={this.linkState(this, "city")}
                        label={<span style={{ color: '#242e3d', fontSize: "1.8rem", fontWeight: "600", fontFamily: '"Lato" ,sans-serif' }}>City</span>}
                        required
                        control={{
                          type: SelectControl,
                          settings: {
                            options: this.state.allCityData?.map((item, i) => { return ({ "value": item, "label": item }) }),
                            multiple: false,
                            searchable: true,
                            placeholder: "Select City",
                            onChangeCallback: (onBlur) => {
                              onBlur(this.state.city);
                            }
                          }
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <FormElement
                        valueLink={this.linkState(this, "pincode")}
                        label="Pincode"
                        required

                        control={{
                          type: CustomTextControl,
                          settings: {
                            type: "text",
                            disabled: false,
                            placeholder: "Enter pincode",
                          }
                        }}
                        classes={{
                          inputField: "form-control",
                          label: "form-label-custom"
                        }}
                      />
                    </Col>
                  </Row>

                </div>
                <div className="order-details w-51">
                  <div className="billing-information">
                    <h4 className='lato-regular fs-32-24 black-242'>Order Details</h4>
                  </div>
                  {this.state.requestObj?.data?.isTopup ?
                    (<div></div>)
                    :
                    (<div className='d-flex flex-row justify-content-between '>
                      <div>
                        <p className='lato-medium fs-18-14 black-242 op-5'>{this.state?.oldPlan?.subPremiumPlan?.name}</p>
                        {!this.state.oldPlan?.subPremiumPlan?.isDefault &&
                          <div className='d-flex flex-row text-nowrap'>
                            <p className='lato-medium fs-16-11 black-242 op-5'>
                              ({resetInDays[this.state?.oldPlan?.subPremiumPlanVariation?.resetInDays]})
                            </p>

                            <p className='lato-medium fs-16-11 black-242 op-6'>- ₹{this.state?.oldPlan?.subPremiumPlanVariation?.discountedPrice}</p>
                          </div>
                        }
                      </div>
                      <Image src={arrow} alt='arrow' />
                      <div className='d-flex flex-column'>
                        <p className='lato-medium fs-18-14 black-242 op-5'>{this.state.breakup?.subPremiumPlan?.name}</p>
                        <div className='d-flex flex-row text-nowrap'>
                          <p className='lato-medium fs-16-11 black-242 op-5'>
                            ({resetInDays[this.state?.breakup?.subPremiumPlanVariation?.resetInDays]})
                          </p>
                          <p className='lato-medium fs-16-11 black-242 op-6 text-nowrap'>- ₹{this.state.breakup?.subPremiumPlanVariation?.discountedPrice}</p>
                        </div>
                      </div>

                    </div>)
                  }
                  <hr />
                  <ul className='order-detail-ul'>
                    <li >
                      <p className='lato-semibold fs-18-14 black-242'>{breakup.premiumPlan.name + " " + breakup?.subPremiumPlan?.name}</p>
                      <p className='lato-bold fs-18-14 black-242 min-w-150 text-right text-nowrap'>{breakup.breakup.planDiscountedAmount ? <> {(breakup.breakup.planPrice ?? 0) !== 0 && <s> ₹ {breakup.breakup.planPrice} </s>}&nbsp;&nbsp; ₹ {breakup.breakup.planDiscountedAmount}</> : `₹${breakup.breakup.planPrice}`}</p>
                    </li>

                    {breakup.breakup?.previousSubcriptionBalanceAmount > 0 &&
                      <li >
                        <p className='lato-semibold fs-18-14 black-242'>{this.state.oldPlan?.subPremiumPlan?.name} <span className="lato-medium light-gray grey-919"> (unused benefits)</span> <Image className="mb-1 pointer" onClick={this.handleUnused} src={UnusedBenefitIcon} /></p>
                        <p className='lato-bold fs-18-14 black-242 min-w-150 text-right text-nowrap' >- ₹ {breakup.breakup.previousSubcriptionBalanceAmount ? <>{breakup.breakup.previousSubcriptionBalanceAmount} </> : 0}</p>
                      </li>
                    }
                    {breakup?.breakup?.balanceAmount && <li className=''>
                      <p className='lato-semibold fs-18-14 black-242 '>Balance Amount</p>
                      <p className='lato-bold fs-18-14 black-242 min-w-150 text-right text-nowrap'>- ₹ {breakup?.breakup?.balanceAmount}</p>
                    </li>}
                    {breakup?.breakup?.couponDiscountedAmount && <li className=''>
                      <p className='lato-semibold fs-18-14 black-242'>Coupon Discount: {this.state.coupon}</p>
                      <p className='lato-bold fs-18-14 black-242 min-w-150 text-right text-nowrap'>- ₹ {breakup?.breakup?.couponDiscountedAmount}</p>
                    </li>}
                    {(breakup?.breakup?.netAmount ?? false) &&
                      <li >
                        <p className='lato-semibold fs-18-14 black-242'>Net Amount</p>
                        <p className='lato-bold fs-18-14 black-242 min-w-150 text-right text-nowrap'>₹{breakup?.breakup?.netAmount}</p>
                      </li>
                    }


                    <li className='mb-0'>
                      <p className='lato-semibold fs-18-14 black-242'>Total</p>
                      <p className='lato-bold fs-18-14 black-242 min-w-150 text-right text-nowrap'>₹ {breakup.breakup.finalAmount}</p>
                    </li>
                    {
                      !this.state.requestObj.data.userBillingPlanUuid &&
                      <li className='lato-normal f-s-12 lh-16 primary pointer'>
                        {couponCode ?
                          <p onClick={() => this.setState({ couponCode: false, couponApplied: false })} >Do you have a coupon code?</p>
                          : (this.state.couponApplied) ?
                            <div className="coupon-aplay">
                              <p className='lato-regular fs-14-13 primary d-flex align-items-center gap-12'>
                                <Image src={coupon} alt='' /> Code: {this.state.coupon}
                              </p>
                              <Image src={CancelImage} onClick={() => { this.setState({ couponApplied: false, couponCode: true, coupon: "" }); this.aplyCouponHandler(true) }} alt='' />
                            </div>
                            :
                            <div className="coupon-box">
                              <input type="text" className='lato-regular fs-14-11 coupon-input w-100' onChange={(e) => this.setState({ coupon: e.target.value })} name='coupon' value={this.state.coupon} placeholder='Add Coupon Code'
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault(); // Prevent the default form submission behavior (if any)
                                    this.aplyCouponHandler();
                                  }
                                }}
                              />
                              <Image src={CancelImage} onClick={() => this.state.coupon ? this.setState({ coupon: "" }) : this.setState({ couponCode: true })} alt="cancle" />
                              <Button onClick={() => this.aplyCouponHandler("")} className='primary-btn pointer '>Apply</Button>
                            </div>

                        } </li>
                    }

                    <li className='confirm-pay-btn'>
                      <FormSubmitButton disabled={!isFormValid} customClass={"mt-3 primary-btn fx-14 w-100"}>
                        {this.state.isUpdataing ? <span className=' d-flex align-items-center gap-12 justify-content-center'><Spinner /> Loading...</span> : 'Confirm and Pay'}
                      </FormSubmitButton>
                      {/* <Button onClick={this.handlePay} className=" mt-3 primary-btn fx-14 w-100"> </Button> */}
                    </li>
                  </ul>
                  <p className='lato-regular f-s-12 lh-14 black-05 '>Cancel your subscription anytime from your subscription settings.
                    By clicking "Confirm and pay" you agree to the <span className='primary pointer' onClick={() => this.props.history.push('/terms-conditions')}>Terms and Conditions.</span> </p>
                </div>

              </div>
            </Form>
            {/* </div> */}
          </div>
          {/* <Footer /> */}
        </section>
        <CustomModal
          show={this.state.isUnused}
          onHide={this.handleClose}
          modalClass={"unused-heads-modal"}
        >
          <div className=" heading d-flex justify-content-between mb-4">
            <p className=" lato-bold fs-20-16 primary "> Unused Benefits breakdown</p>
            <Image src={CrossIcon} className='close-icon model-icon-close' alt='icon' style={{ cursor: "pointer" }} onClick={this.handleClose} />
          </div>
          <p className='unused-text lato-regular fs-14-12 black-4 '>Your unused benefits are calculated based on the remaining days of your current plan or any unutilized usage.</p>

          <ul className='detail-ul'>
            <li>
              <p className='lato-medium fs-12-10 black-242'>Unused</p>
              <p className='lato-medium fs-12-10 black-242'>Amount</p>
            </li>
            <hr />
            <li>
              <p className='lato-regular fs-14-12 black-4'>{breakupAmount?.userTotalContentActionPendingCount
              } Content</p>
              <p className='lato-regular fs-14-12 black-242'>
                ₹{breakupAmount?.userTotalContentActionPendingCost ? Math.floor(breakupAmount.userTotalContentActionPendingCost) : 0}
              </p>

            </li>
            <li>
              <p className='lato-regular fs-14-12 black-4'>{breakupAmount?.
                userTotalToolsActionPendingCount
              } Tools</p>
              <p className='lato-regular fs-14-12 black-242'>
                ₹{breakupAmount?.userTotalToolsActionPendingCost ? Math.floor(breakupAmount.userTotalToolsActionPendingCost) : 0}
              </p>

            </li>
            {breakupAmount?.daysRemaining &&
              <li>
                <p className='lato-regular fs-14-12 black-4'>{breakupAmount?.daysRemaining} days</p>
                <p className='lato-regular fs-14-12 black-242'>
                  -₹{breakupAmount?.previousSubcriptionBalanceAmount ? Math.floor(breakupAmount.previousSubcriptionBalanceAmount) : 0}
                </p>

              </li>
            }
            <hr />

            <li>
              <p className='lato-bold fs-14-12 primary'>Total</p>
              <p className='lato-bold fs-14-12 primary'>
                ₹{breakupAmount?.previousSubcriptionBalanceAmount ? Math.floor(breakupAmount.previousSubcriptionBalanceAmount) : 0}
              </p>

            </li>

          </ul>

        </CustomModal>

      </>
    )
  }
}

const mapStateToProps = state => ({ profileState: state.ProfileState });
const mapDispatchToProps = {
  // getPosts: fetchPosts
}
CheckOutPage.propTypes = {
  // getPosts: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckOutPage);