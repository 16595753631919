import React, { useEffect, useState } from 'react'
import RangeFields from './components/RangeFields'
import BarChart from './components/BarChart'
import { CheckToolsSubscription, ShowShare, ToolsScreenshotHeader, captureImageHandler, checkToolsSubscription, debounce, formatCurrency, getUser, toolsDisclaimer, toolsInvestorDetails, toolsScreenshotHeader } from '../../utils/ReusableFunctions';
import { useMemo } from 'react';
import { useRef } from 'react';

export default function SIPWithEMIPlanner({ calculator, textLogo = '', imageData, setImageData, isDisable, setIsDisable, setAlertData, setIsAlert, setModalData }) {


  let [loanAmount, setLoanAmount] = useState(6000000);
  let [loanInterest, setLoanInterest] = useState(10);
  let [tenure, setTenure] = useState(20);
  let [disabledEMI, setDisabledEMI] = useState(57901);
  let [disabledInputEMI25, setDisabledInputEMI25] = useState(14475);
  let [investmentReturn, setInvestmentReturn] = useState(15);
  let [totalInterestPaidOut, setTotalInterestPaidOut] = useState(13896312);
  let [TotalLoanInterest, setTotalLoanInterest] = useState(43041);
  let [interestOutGo, setInterestOutGo] = useState(7896312);
  let [totalAmountInvested, setTotalAmountInvested] = useState(3474078);
  let [totalAccumulatedCorpus, setTotalAccumulatedCorpus] = useState(21943940);

  // useEffect(() => {
  //   onSubmitAction(loanAmount, loanInterest, tenure, investmentReturn, totalAmountInvested, TotalLoanInterest, totalAccumulatedCorpus, interestOutGo);
  // }, [])
  useEffect(() => {
    setTotalInterestPaidOut(totalInterestPaidOut);
    setTotalAmountInvested(totalAmountInvested);
    setTotalAccumulatedCorpus(totalAccumulatedCorpus);
    setTotalLoanInterest(TotalLoanInterest);
    setInterestOutGo(interestOutGo);
  }, [totalInterestPaidOut, disabledEMI, disabledInputEMI25, totalAmountInvested, totalAccumulatedCorpus, TotalLoanInterest, interestOutGo])

  const handleChange = (value, name) => {

    if (name === "loanAmount") {
      loanAmount = value
      setLoanAmount(loanAmount)
    }
    if (name === "loanInterest") {
      loanInterest = value
      setLoanInterest(loanInterest)
    }
    if (name === "tenure") {
      tenure = value
      setTenure(tenure)
    }
    if (name === "investmentReturn") {
      investmentReturn = value
      setInvestmentReturn(investmentReturn)
    }
    onSubmitAction(loanAmount, loanInterest, tenure, investmentReturn, totalAmountInvested, TotalLoanInterest, totalAccumulatedCorpus, interestOutGo);
  }

  const onSubmitAction = (loanAmount, loanInterest, tenure, investmentReturn, totalAmountInvested, TotalLoanInterest, totalAccumulatedCorpus, interestOutGo) => {

    let TotalLoanPrincipal = Number(loanAmount);
    let roi = ((investmentReturn / 100) / 12) * 100;
    let emiAmount = (loanAmount / (1 / (1 + ((loanInterest / 100) / 12)) ** (tenure * 12))) / ((((1 + ((loanInterest / 100) / 12)) ** (tenure * 12)) - 1) / ((loanInterest / 100) / 12));
    let sippmt = 0.25 * emiAmount;
    let totalOutGo = emiAmount * 12 * tenure;
    let totalInterestPaidOut = Math.round(totalOutGo);
    let futureValue = ((((1 + ((investmentReturn / 100) / 12)) ** (tenure * 12)) - 1) / ((investmentReturn / 100) / 12) * sippmt * (1 + ((investmentReturn / 100) / 12)));
    totalAmountInvested = Math.round(tenure * 12 * sippmt);

    TotalLoanInterest = totalInterestPaidOut > 0 ? totalInterestPaidOut : 0;
    totalAccumulatedCorpus = Math.round(((((1 + ((investmentReturn / 100) / 12)) ** (tenure * 12)) - 1) / ((investmentReturn / 100) / 12) * sippmt * (1 + (investmentReturn / 100) / 12)));
    interestOutGo = Math.round(totalInterestPaidOut - loanAmount);

    setTotalInterestPaidOut(totalInterestPaidOut);
    setDisabledEMI(Math.round(emiAmount));
    setDisabledInputEMI25(Math.round(sippmt));
    setTotalAmountInvested(totalAmountInvested);
    setTotalAccumulatedCorpus(totalAccumulatedCorpus);
    setTotalLoanInterest(TotalLoanInterest);
    setInterestOutGo(interestOutGo);
  };




  let downloadScreenshotFunc = null;

  const setDownloadScreenshotFunc = (func) => {
    downloadScreenshotFunc = func;
  };

  const barChart = useMemo(() => {
    return (
      <BarChart
        info={{
          labels: [["Total Loan", "Out Go"], ["Total Interest", "Out Go"]],
          data: [totalInterestPaidOut, interestOutGo],
        }}
      />
    );
  }, [totalInterestPaidOut, interestOutGo]);
  const barChart2 = useMemo(() => {
    return (
      <BarChart
        info={{
          labels: [["Invested", "Amount"], ["Investment", "Value"]],
          data: [totalAmountInvested, totalAccumulatedCorpus],
        }}
      />
    );
  }, [totalAmountInvested, totalAccumulatedCorpus]);
  return (
    <div>
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART START ---------------------------------------------- */}
      <div className="calculator-result-main-wrapper">
        <div className="calculator-component-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Calculator
          </h4>


          <RangeFields
            label="Outstanding Loan Amount"
            name={"loanAmount"}
            value={loanAmount}
            minVal={100000}
            maxVal={500000000}
            step={100000}
            getValue={debounce(handleChange)}
            showComma />
          <RangeFields
            label="Rate of Interest on Loan"
            name={'loanInterest'}
            value={loanInterest}
            minVal={4}
            maxVal={25}
            step={0.5}
            unit="%"
            getValue={debounce(handleChange)}
          />
          <RangeFields
            label="Remaining Loan Tenure / Investment Tenure"
            name={"tenure"}
            value={tenure}
            minVal={1}
            maxVal={30}
            unit="Year"
            step={1}
            getValue={debounce(handleChange)}
          />
          <RangeFields
            label="Monthly EMI Amount"
            name={"disabledEMI"}
            type={'number'}
            value={disabledEMI}
            disable={true}
            getValue={debounce(handleChange)}
            showComma
          />
          <RangeFields
            label="Monthly SIP Amount (25% of EMI)"
            name={"disabledInputEMI25"}
            type={'number'}
            value={disabledInputEMI25}
            disable={true}
            getValue={debounce(handleChange)}
          />

          <RangeFields
            label="Expected Rate of Return on the SIP Amount"
            name={"investmentReturn"}
            value={investmentReturn}
            minVal={1}
            maxVal={50}
            unit="%"
            step={0.5}
            getValue={debounce(handleChange)}
          />

        </div>
        <div className="result-graph-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Results{" "}

            {<ShowShare calculator={calculator} isDisable={isDisable} setIsDisable={setIsDisable} clickHandler={() => { CheckToolsSubscription(textLogo, downloadScreenshotFunc, calculator?.uuid, imageData, setIsDisable, setAlertData, setIsAlert, setModalData) }} />}

          </h4>
          <div className="result-graph-wrapper">
            <div className={`graph-1-frame flex-column ${false ? "w-auto" : "w-100"}`}>
              {barChart}
            </div>
            <div className={`graph-1-frame flex-column ${false ? "w-auto" : "w-100"}`}>
              {barChart2}
            </div>
          </div>
          {emiPlannerResultText(disabledInputEMI25, investmentReturn, tenure, totalAccumulatedCorpus)}

        </div>
      </div>
      <div className="make-none">
        <CalculatorOutputScreenShot textLogo={textLogo} totalInterestPaidOut={totalInterestPaidOut} totalAccumulatedCorpus={totalAccumulatedCorpus} totalAmountInvested={totalAmountInvested} interestOutGo={interestOutGo} onDownloadScreenshot={setDownloadScreenshotFunc}
          setImageData={setImageData} investmentReturn={investmentReturn} tenure={tenure} />
      </div>
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART END ---------------------------------------------- */}
    </div>
  )
}

function CalculatorOutputScreenShot({ textLogo, totalInterestPaidOut, totalAccumulatedCorpus, totalAmountInvested, totalSIP, interestOutGo, onDownloadScreenshot, setImageData, disabledEMI, disabledInputEMI25, totalLoanInterest, investmentReturn, tenure }) {
  const { cobrandingLabelling, investor } = textLogo;
  const [signup, setSignup] = useState(false);



  const screenshotRef = useRef(null);
  const generatePDF = () => {
    const content = document.getElementById('content-to-print');
    const newWindow = window.open('', '_blank');
    newWindow.document.write(content.innerHTML);
    newWindow.document.write(`
      <style>
        body {
          font-family: Arial, sans-serif;
        }
      </style>
    `);
    newWindow.print();
  };
  useEffect(() => {
    if (onDownloadScreenshot) {

      captureImageHandler(onDownloadScreenshot, screenshotRef, setSignup, setImageData);
    }
  }, [textLogo, screenshotRef, totalInterestPaidOut, disabledEMI, disabledInputEMI25, totalAmountInvested, totalLoanInterest, totalAccumulatedCorpus, interestOutGo, tenure]);
  return (<>
    {/* {signup && <SignUp show={signup} handleClose={() => setSignup(!signup)} />} */}

    <div ref={screenshotRef} id='content-to-print' className="tools-calulator-output-screenshot-frame ">
      {ToolsScreenshotHeader(cobrandingLabelling)}



      <div className="result-graph-wrapper">
        <div className={`graph-1-frame flex-column ${false ? "w-auto" : "w-100"}`}>
          <BarChart
            info={{
              labels: [["Total Loan", "Out Go"], ["Total Interest", "Out Go"]],
              data: [totalInterestPaidOut, interestOutGo],
            }}
          />
        </div>
        <div className={`graph-1-frame flex-column ${false ? "w-auto" : "w-100"}`}>
          <BarChart
            info={{
              labels: [["Invested", "Amount"], ["Investment", "Value"]],
              data: [totalAmountInvested, totalAccumulatedCorpus],
            }}
          />
        </div>
      </div>
      {toolsInvestorDetails(investor)}
      {emiPlannerResultText(disabledInputEMI25, investmentReturn, tenure, totalAccumulatedCorpus)}
      <br /><br />

      {toolsDisclaimer()}
    </div>

  </>
  )
};

function emiPlannerResultText(disabledInputEMI25, investmentReturn, tenure, totalAccumulatedCorpus) {
  return (
    <div className='d-flex w-100'>
      <p className=" f-s-16 lh-24 black-242 op-7 pt-2 text-center">
        For SIP Amount of <span className=" lato-semibold primary">₹{formatCurrency(disabledInputEMI25)}</span> on monthly frequency, with <span className=" lato-semibold primary">{investmentReturn}%</span> Expected Rate of Return for Investment period of <span className=" lato-semibold primary">{tenure} Years</span>, results in Total Investment Value of <span className=" lato-semibold primary"> ₹{formatCurrency(totalAccumulatedCorpus)}</span>
      </p>
    </div>

  )
}