import { toast } from "react-toastify";
import { postLoginInstance, preLoginInstance } from "../../utils";
import { getToken } from "../../utils/ManageToken";
import { API_LIMIT, START_PAGE } from "../../utils/Constant";
//? ======================= COMMON GET DATA ===============================
export const getDataApi = async (url) => {
  try {
    const response = await preLoginInstance.get(url);
    const data = response.data;

    return data.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong")
    return null;
  }
};
export const getNotificationData = async (url) => {
  try {
    const response = await postLoginInstance.get(url);
    return response;
  }
  catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong")
    return null;
  }
}

export const postNotificationData = async (url, data) => {
  try {
    const response = await postLoginInstance.post(url, data);
    return response;
  }
  catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong")
    return null;
  }
}

export const getAllCitesApi = (locationSearch, setAllCities) => {
  return postLoginInstance.get(`website/location/allCities?pageSize=${API_LIMIT}&pageNumber=${START_PAGE}  ${locationSearch && `&searchValue=${locationSearch}`}`)
    .then((res) => {
      let option = [];

      res.data.data.sort().map((item) =>
        option.push({
          label: item,
          value: item
        }))
      setAllCities(option);
    }).catch((error) => {
      toast.error(error?.response?.data?.message || "Something went wrong")
    })
}
export const modifyNotificationApi = async (url) => {
  try {
    const response = await postLoginInstance.post(url);;
    return response;
  }
  catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong")
    return '';
  }
}
export const getCommonApi = async (url) => {
  try {
    const response = await preLoginInstance.get(url);
    const data = response.data;
    // console.log(data)
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong")
    return error;
  }
};
export const getAuthDataApi = async (url) => {
  try {
    const response = await postLoginInstance.get(url);
    const data = response?.data;

    // console.log(data)
    return data.data;
  } catch (error) {

    toast.error(error?.response?.data?.message || "Something went wrong")
    return error;
  }
};
export const commonPostApi = async (url, data, method = 'post') => {
  try {
    const response = await preLoginInstance[method](url, data);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong")
    throw error;
  }
};
export const commonAuthPostApi = async (url, data) => {

  try {
    // console.log("Linke api called")
    const response = await postLoginInstance.post(url, data);

    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong")

    return error.response;
    // throw error;
  }
};

//? ======================= COMMON GET DATA ===============================
export const getAllKeywordApi = async (setKeywordOption, searchValue = "") => {
  try {
    const res = await preLoginInstance.get(`website/keyword/list?pageSize=10&pageNumber=1&searchValue=${searchValue}`);
    let keysArray = res?.data?.data?.map((key) => key.replace("#", ""));
    keysArray = [...new Set(keysArray)]
    let stringData = JSON.stringify(keysArray);
    // sessionStorage.setItem("keysData", stringData);
    setKeywordOption(keysArray);
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong")
  }
}
export const getContentTypeApi = async (setContentTypeOption) => {
  try {
    const res = await preLoginInstance.get("website/contentType/list");
    let all = {
      contentIcon: "https://s3.ap-south-1.amazonaws.com/beta.thefinpedia.com/all-content-files/content-type-icons/all-content.svg",
      contentTypeName: "All",
      uuid: ""
    }
    let data = res?.data?.data
    data.splice(0, 0, all)
    let stringData = JSON.stringify(data);
    localStorage.setItem("contenType", stringData);
    setContentTypeOption(res?.data?.data);

  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong")
  }
}
export const getSearchAllDataApi = async (contentType = "", search = "", socialMedia = "") => {
  // console.log("Common Search api", search);
  search = search.charAt(0) === "?" ? search.slice(1) : search;
  let a = search.split("contentType=");
  contentType = (a.length > 1) ? (a[1] !== "") ? a[1] : contentType : contentType;

  search = search.replace('contentType=' + contentType, "");
  try {
    const response = await (getToken() ? postLoginInstance : preLoginInstance).get(`content/search?${contentType ? `contentType=${contentType}` : ''}${socialMedia ? `&socialMedia=${socialMedia}` : ''}${search ? `&${search}` : ''}`);
    const data = response.data.data;
    return data;

  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong")
    return [];
  }
};
export const getDetailsApi = async (url) => {
  // console.log("Video details id", id)
  try {
    const response = await (getToken() ? postLoginInstance : preLoginInstance).get(url);
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong")
    return error;
  }
};
export const getFilterApi = async (filterUrl = "content/filter") => {
  try {
    // const response = await preLoginInstance.get(`content/filter${key ? `?key=${key}` : ""}`);
    const response = await postLoginInstance.get(filterUrl);
    const data = response.data;
    // console.log(data)
    return data.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong")
    return null;
  }
};
export const downloadCobrandingApi = async (data) => {
  try {
    const response = await postLoginInstance.post("website/content/cobrand", data);
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong")
    return false;
  }
};

// ,{
//   Authorization:"Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGFpbXMiOnsidXNlcklkIjoxfSwiaWF0IjoxNjkyNjMxMTYxLCJleHAiOjE2OTI2MzEyNjF9.W8eO-Va4P6zzQSWhjed6drXS8BGXEEHq4dqUFWFXzt8"
// }


// ?============================== PROFILE PUT API START ==================================
export const updateProfileApi = async (url, data) => {
  try {
    const response = await postLoginInstance.put(url, data);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong ")
    // throw error;
    return false;
  }
};
// ?============================== PROFILE PUT API END ==================================
export const getTrendingKeysApi = async (setTrendingKeywords = '') => {
  try {
    const response = await postLoginInstance.get('website/trendingKeywords');
    let stringData = JSON.stringify(response.data.data);
    if (setTrendingKeywords) {
      setTrendingKeywords(response.data.data)
    }
    sessionStorage.setItem("trendingKeywords", stringData);
    // return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong")
    throw error;
  }
};

// POST OF THE DAY API
export const getAllPostApi = async () => {
  try {
    const response = await postLoginInstance.get('website/postOfTheDay');
    // console.log('res', response.data);
    return response.data.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong")
    throw error;
  }
};


export const getNotificationListeningApi = async (url) => {
  try {
    const response = await postLoginInstance.get(url);
    // console.log('res', response.data);
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong")
    return '';
  }
};