import React, { useEffect, useState } from 'react';
import { Button, Image, Modal } from "react-bootstrap";
import CrossBtn from '../../../assets/images/icon/cross-modal-icon.svg';
import { useHistory } from 'react-router-dom';


const AlertModal = ({ show, onHide, title, children, alertData, breakupAmount, modalClass = null, data, type }) => {
  const history = useHistory();
  const [alertMessage, setAlertMessage] = useState("")

  const handleNavigate = (() => {
    if (type == "usage") {
      history.push("/mymembership")
    }
    else {

      history.push({
        pathname: "/checkout",
        state: {
          data: alertData,
          requestObj: data,
          isNewPurchase: true,
          breakupAmount: breakupAmount
        }
      })
    }


  })

  useEffect(() => {

    const alerts = Array.isArray(alertData?.alerts) ? alertData.alerts : [];
    const alert = Array.isArray(alertData?.alert) ? alertData.alert : [];

    if (alerts.length > 0) {
      setAlertMessage(alertData?.alerts[0]);
    }
    else if (
      alert.length > 0) {
      setAlertMessage(alertData?.alert[0]);

    }



  }, [alertData]);



  return (

    <Modal show={show} centered onHide={onHide} dialogClassName={` ${modalClass}`}>
      {
        // title &&
        <Modal.Header >
          {alertMessage?.title &&
            <Modal.Title className='lato-bold fs-20-16 red-D72 px-0'>{alertMessage?.title}</Modal.Title>
          }
          <Image src={CrossBtn} className='close-icon model-icon-close pointer' alt='icon' onClick={onHide} />
        </Modal.Header>
      }
      <Modal.Body >
        {alertMessage &&
          <p className='lato-medium fx-14 black-242 custom-alert'
            dangerouslySetInnerHTML={{ __html: alertMessage?.description }}>
          </p>
        }


      </Modal.Body>
      <div className='alert-footer d-flex flex-row justify-content-between'>
        {alertMessage?.successButtonText && <span onClick={() => history.push('/terms-conditions')} className='lato-medium fs-12-10 black-242 op-5 pointer mt-md-4 mt-3'>Terms & Conditions </span>}

        <div className='d-flex flex-row'>
          {alertMessage?.successButtonText && (
            <Button
              onClick={(e) => {
                e.preventDefault();
                if (alertMessage?.code === "POST_PLANNER_TOP_UP_ALLOWED") {
                  history.push("/contact");
                } else {
                  onHide();
                }
              }}
              className="secondary-btn "
            >
              {alertMessage?.successButtonText}
            </Button>
          )}

          {alertMessage?.cancelButtonText &&
            < Button onClick={alertMessage?.code == "POST_PLANNER_TOP_UP_ALLOWED" ? onHide : handleNavigate} className="primary-btn ">{alertMessage?.cancelButtonText}</Button>
          }
        </div>
      </div>
    </Modal >

  );
};
export default AlertModal;