
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Dropdown, Image } from "react-bootstrap";
import Media from "../../assets/images/media.svg";
import { EVENTS, MAX_LIMIT, MOBILE_BREAKPOINT, START_PAGE } from "../../utils/Constant";
import SelectKeywords from "./SelectKeywords";
import { getContentTypeApi } from "../home/Api";
import { ContentTypeOptions } from '../../utils/Constant';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function SearchDropDown({
  classes,
  contentType = ContentTypeOptions,
  dropdown = true,
  placeholder = "Search",
  getKeys = () => { },
  clearFilter = () => { },
  isEvent = false,
  isFilterYes = false,
  isPostPlanner = false,
  handleSearch = () => { }
}) {
  // console.log(isFilterYes)
  const [keywordOption, setKeywordOption] = useState([]);
  const [key, setKey] = useState("");
  const [contentTypeOption, setContentTypeOption] = useState('All');
  const [contentTypeId, setContentTypeId] = useState('');
  const [isMedia, setIsMedia] = useState();
  const [contentTypes, setContentTypes] = useState([]);
  const [dropDownIcon, setDropDownIcon] = useState(Media)

  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const getParam = new URLSearchParams(location?.search);
  const [icon, setIcon] = useState("");
  // console.log(params)

  useEffect(async () => {
    // call keyword list api
    let isMedia = location.pathname.includes('/social-media');
    setIsMedia(isMedia)
    // const keysValue = JSON.parse(sessionStorage.getItem("keysData"));
    const contenType = JSON.parse(localStorage.getItem("contenType"));
    const trendingKeywords = JSON.parse(sessionStorage.getItem("trendingKeywords"));

    if (!contenType) {
      getContentTypeApi(setContentTypes);
    } else {
      // setKeywordOption(keysValue);
      setContentTypes(contenType);
    }


    const paramsArray = params?.type;
    let paramsType;
    let paramsSerch;

    if (paramsArray) {
      paramsType = paramsArray === "" ? "All" : paramsArray?.charAt(0).toUpperCase();
      paramsSerch = paramsArray?.slice(1);
    }
    let setType = paramsType + paramsSerch;
    setContentTypeOption(setType || 'All');

    contenType?.forEach((item) => {
      if (item.contentTypeName === setType) {
        setDropDownIcon(item.contentIcon);
      }
    });
  }, [params]);

  const handleDropdownItemClick = async (keyword = key) => {
    // if (keyword !== "" || contentTypeOption !== 'All') {
    let path, mediaPath, search = "";
    mediaPath = `/social-media/${params?.type}`
    path = isEvent ? `/${EVENTS}` : `/content-type/${contentTypeOption?.replace('/', "_")}`;
    if (getParam.has('key')) {
      search = `key=${getParam.get('key')}&value=${getParam.get('value')}&searchValue=${keyword || key}&pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}`
    } else {
      search = `${(keyword) ? `searchValue=${keyword}` : ""}&pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}`;
    }

    getParam.set("searchValue", keyword)
    getParam.set("pageNumber", START_PAGE)
    getParam.set("pageSize", MAX_LIMIT)

    if (getParam.has('contentType')) {
      getParam.delete("contentType")
    }

    if (isPostPlanner) {

      handleSearch(contentTypeOption, keyword);
    } else {
      history.push({
        pathname: isMedia ? mediaPath : path,
        search: getParam.toString(),
      });
    }
    clearFilter(keyword);
    // }
    // }
  };
  function getKeysData(data) {

    setKey(data);
    getKeys(data);
  }

  function clearClicked(data) {
    if (location.pathname === "/") {
      history.push({
        pathname: "/",
        search: "",
      });
    }
  }

  const innerWidth = window.innerWidth;

  return (
    <div className={`search-dropdown ${classes}`}>
      {dropdown && (
        <div className="dropdown-wrapper">
          <Dropdown>
            <Dropdown.Toggle className="dropdown-title lato-semibold" id="dropdown-basic">
              {innerWidth >= MOBILE_BREAKPOINT ? (
                contentTypeOption?.replace('_', "/")
              ) : (
                <Image src={dropDownIcon || Media} alt="meida" />
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu>

              {contentTypes?.map((item, i) => (
                <Dropdown.Item
                  key={i}
                  onClick={() => { setContentTypeOption(item.contentTypeName); setContentTypeId(item.uuid); setDropDownIcon(item.contentIcon || Media) }}
                  title={item.option}
                  className="lato-semibold fs-18-16 d-flex  black-242"
                > <Image src={item.contentIcon || Media} className="dropDown-mb-image mb-show" alt="meida" />
                  {item.contentTypeName}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
      {/* dropdown for keywords */}
      <div className={`search-wrapper ${dropdown ? "" : "left-b-none"} `}>
        <SelectKeywords
          label="Keywords"
          clearClicked={clearClicked}
          getKeys={getKeysData}
          navigate={handleDropdownItemClick}
          placeholder={placeholder}
          isEvent={isEvent}
        />
      </div>
    </div>
  );
}

