import React from 'react'
import { Image } from 'react-bootstrap';
import ellipse from '../../assets/images/ellipse.png';
import { formatNumber } from '../../utils/ReusableFunctions';

export default function SocialMediaPostCard({ onClick = () => { }, data = "" }) {
  return (
    <div className='social-media-post-cards amc-rambo-effect responsive-card-width ' onClick={onClick}>
      {
        data?.isSponsored &&
        <div className="post-user lato-bold fs-18-9 black-242">
          Sponsored <Image src={ellipse} alt='user-profile-img' />
        </div>
      }
      <div className="img-cover">
        {data?.thumbnailPath ? <Image src={data?.thumbnailPath} className='post-image' alt='post Image' />
          : <p className=' lato-regular fs-14-13'>{data?.socialMediaName}</p>}
      </div>
      <div className="post-content-wrapper">
        <h4 className='lato-medium fs-24-14 black-242 '>{data?.socialMediaName}</h4>
        <div className={`count-wrapper amc-count-content-type ${(data?.contentTypeStats ?? false) ? Object.keys(data?.contentTypeStats || {})?.length > 3 && "justify-content-start gap-25" : ""} `}>
          {
            Object.keys(data?.contentTypeStats || {})?.map(

              (item, i) => {
                if (item === 'totalContentCount') {
                  return '';
                } else {
                  return (
                    <div className="social-media-type-states">
                      <p className='lato-semibold fs-14-9 text-center black-242'>{formatNumber(data?.contentTypeStats[item])}</p>
                      <p className='lato-semibold fs-14-9 text-center op-8 black-242'>{item}</p>
                    </div>
                  )
                }

              })
          }
        </div>
      </div>
    </div>
  )
}
