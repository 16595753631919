import React from "react";
import YouTube from "react-youtube";

function YouTubePlayer({link}) {
  const videoLink = link;
  const videoId = videoLink.split("v=")[1];

  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  };

  return <YouTube videoId={videoId} opts={opts} />;
}

export default YouTubePlayer;
