import React, { useEffect, useRef, useState } from 'react'
import RangeFields from './components/RangeFields'
import BarChart from './components/BarChart'
import { CheckToolsSubscription, ShowShare, ToolsScreenshotHeader, captureImageHandler, checkToolsSubscription, debounce, formatCurrency, getUser, toolsDisclaimer, toolsInvestorDetails, toolsScreenshotHeader } from '../../utils/ReusableFunctions';
import { Image } from 'react-bootstrap'
import { useMemo } from 'react';
import { SignUp } from '../common/components';
export default function ImpactOfInflation({ calculator, textLogo = '', imageData, setImageData, isDisable, setIsDisable, setAlertData, setIsAlert, setModalData }) {

  let [roi, setRoi] = useState(5);
  let [afterYears, setAfterYears] = useState(5);
  let [monthlyExpense, setMonthlyExpense] = useState(32000);
  let [monthlyExpenseFV, setMonthlyExpenseFV] = useState(40841);
  // let [monthlyExpense, setMonthlyExpense] = useState(32000);
  // let [monthlyExpenseFV, setMonthlyExpenseFV] = useState(40841);


  const onSubmitAction = (roi, monthlyExpense, afterYears) => {
    roi = (roi / 100);

    let monthlyExpenseFV = Math.round(monthlyExpense / (1 / (1 + roi) ** afterYears));
    setRoi(roi * 100);
    setAfterYears(afterYears);
    setMonthlyExpense(monthlyExpense);
    setMonthlyExpenseFV(monthlyExpenseFV);
    // console.log("Test roi vaue 11111111111:=",monthlyExpenseFV,monthlyExpense);

  };
  useEffect(() => {
    onSubmitAction(roi, monthlyExpense, afterYears);
  }, [])
  // useEffect(() => {
  //   console.log("Test roi vaue 222222222222:=",monthlyExpenseFV,monthlyExpense)
  //   setRoi(roi);
  //   setAfterYears(afterYears);
  //   setMonthlyExpense(monthlyExpense);
  //   setMonthlyExpenseFV(monthlyExpenseFV);
  // }, [roi, afterYears, monthlyExpense, monthlyExpenseFV])

  const handleChange = (value, name) => {
    if (name === "roi") {
      roi = value;
    }
    if (name === "monthlyExpense") {
      monthlyExpense = value;
    }
    if (name === "afterYears") {
      afterYears = value;
    }
    onSubmitAction(roi, monthlyExpense, afterYears);
  }


  let downloadScreenshotFunc = null;

  const setDownloadScreenshotFunc = (func) => {
    downloadScreenshotFunc = func;
  };
  const barChart = useMemo(() => {
    return (
      <BarChart
        info={{
          labels: ["Present Value", "Future Value"],
          data: [monthlyExpense, monthlyExpenseFV],
        }}
      />
    );
  }, [monthlyExpense, monthlyExpenseFV]);
  return (
    <div>
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART START ---------------------------------------------- */}
      <div className="calculator-result-main-wrapper">
        <div className="calculator-component-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Calculator
          </h4>


          <RangeFields
            label="Current Monthly Expense"
            name={"monthlyExpense"}
            value={monthlyExpense}
            minVal={5000}
            maxVal={1000000}
            step={1000}
            getValue={debounce(handleChange)}
            showComma />
          <RangeFields
            label="Expected Inflation"
            name={'roi'}
            value={roi.toFixed(0)}
            minVal={1}
            maxVal={25}
            step={1}
            unit="%"
            getValue={debounce(handleChange)}
          />
          <RangeFields
            label="After No. of Years"
            name={"afterYears"}
            value={afterYears}
            minVal={1}
            maxVal={50}
            unit="Year"
            step={1}
            getValue={debounce(handleChange)}
          />
        </div>
        <div className="result-graph-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Results{" "}

            {<ShowShare calculator isDisable={isDisable} setIsDisable={setIsDisable} clickHandler={() => { CheckToolsSubscription(textLogo, downloadScreenshotFunc, calculator?.uuid, imageData, setIsDisable, setAlertData, setIsAlert, setModalData) }} />}

          </h4>
          <div className="result-graph-wrapper">
            <div className={`graph-1-frame ${false ? "w-auto" : "w-100"}`}>

              {barChart}
            </div>
          </div>
          {impactOfInflationText(roi, monthlyExpense, monthlyExpenseFV, afterYears)}

        </div>
      </div>
      <div className="make-none">
        <CalculatorOutputScreenShot
          textLogo={textLogo}
          monthlyExpense={monthlyExpense}
          monthlyExpenseFV={monthlyExpenseFV}
          roi={roi}
          afterYears={afterYears}
          onDownloadScreenshot={setDownloadScreenshotFunc}
          setImageData={setImageData} />
      </div>
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART END ---------------------------------------------- */}
    </div>
  )
}


function CalculatorOutputScreenShot({ textLogo, monthlyExpense, monthlyExpenseFV, roi, afterYears, onDownloadScreenshot, setImageData }) {
  const { cobrandingLabelling, investor } = textLogo;
  const [signup, setSignup] = useState(false);
  const screenshotRef = useRef(null);

  useEffect(() => {
    if (onDownloadScreenshot) {

      captureImageHandler(onDownloadScreenshot, screenshotRef, setSignup, setImageData);
    }
  }, [textLogo, screenshotRef, roi, monthlyExpense, afterYears, monthlyExpenseFV]);
  return (
    <>
      {/* {signup && <SignUp show={signup} handleClose={() => setSignup(!signup)} />} */}

      <div ref={screenshotRef} className="tools-calulator-output-screenshot-frame ">
        {ToolsScreenshotHeader(cobrandingLabelling)}

        <div className="result-graph-wrapper">
          <div className={`graph-1-frame ${false ? "w-auto" : "w-100"}`}>
            <BarChart
              info={{
                labels: ["Present Value", "Future Value"],
                data: [monthlyExpense, monthlyExpenseFV],
              }}
            />

          </div>
        </div>
        {toolsInvestorDetails(investor)}
        <br />
        {impactOfInflationText(roi, monthlyExpense, monthlyExpenseFV, afterYears)}
        {toolsDisclaimer()}
      </div>

    </>
  )
};

function impactOfInflationText(roi, monthlyExpense, monthlyExpenseFV, afterYears) {

  return (
    <p className=" f-s-16 lh-24 black-242 op-7 pt-2 text-center">
      Considering the rate of inflation to be <span className=" lato-semibold primary">{roi.toFixed(0)}%</span>, your current monthly expenses of <span className=" lato-semibold primary">₹{formatCurrency(monthlyExpense)}</span> will amount to <span className=" lato-semibold primary">₹{formatCurrency(monthlyExpenseFV)}</span> after <span className=" lato-semibold primary">{afterYears}</span> years.
    </p>
  )
}