import { useEffect, useRef, useState } from 'react';
import ImageChecked from '../../assets/images/icon/tick-icon.svg';
import RadioFields from '../tools/components/RadioFields';
import { CustomDropDown } from "../../utils/form";



import { getUser } from "../../utils/ReusableFunctions";
import { toast } from "react-toastify";
import editPencle from '../../assets/images/edit-icon.png';

import { DEFAULT_IMAGE_HEIGHT, DEFAULT_IMAGE_WIDTH, positionNames } from '../../utils/Constant';
import { commonAuthApis } from "./Api";
import { commonAuthPostApi, getAuthDataApi } from '../home/Api';
import { Button, Image, Modal } from 'react-bootstrap';
import Close1 from "../../assets/images/Close1.svg";
import { Spinner } from '../common/components';



const selectOption = [
  { value: "creator", label: "Creator logo + My logo" },
  { value: "organisation", label: "Only My logo" },
  { value: "both", label: "Both" }

];
export default function PreferenceContent({ onSubmit }) {
  let [contact, setContact] = useState(null);
  const [checked, setChecked] = useState("false");
  const [colorOptions, setColorOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const userProfileDetails = getUser();
  const [loading, setloading] = useState(false);

  // TODO:------ LOGO AND CONPANY DETAILS STATE START
  const [isLogoUploaded, setIsLogoUploaded] = useState(false);
  const [companyLogo, setCompanyLogo] = useState({ logoUrl: userProfileDetails?.organizationLogoPath ?? "", logoFile: null })
  const { logoUrl } = companyLogo;
  const [brandinText, setBrandinText] = useState({ company: userProfileDetails?.companyName ?? "", arnNo: userProfileDetails?.arnNumber ?? "", companyEmail: userProfileDetails?.emailAddress ?? "", companyNo: userProfileDetails?.mobileNumber ?? "" });
  const { company, arnNo, companyEmail, companyNo } = brandinText;
  // TODO:------ LOGO AND CONPANY DETAILS STATE END


  //* -----------------------------------------------------------------------------------------
  const [cobrandcolorLanguage, setCobrandcolorLanguage] = useState({ color: '', language: '' });
  const { color, language } = cobrandcolorLanguage;
  const [cobrandingLabelling, setCobrandingLabelling] = useState({ labelStart: false, startText: false, startLogo: false, lableEnd: false, endText: false, endLogo: false, labelFull: false, fullText: false, fullLogo: false, positionId: '', textPosition: '', logoPosition: '', personalization: false, personalizationText: '' })
  const { labelStart, lableEnd, labelFull, startText, startLogo, endText, endLogo, fullText, fullLogo, textPosition, logoPosition, personalization, personalizationText } = cobrandingLabelling;
  //* -----------------------------------------------------------------------------------------
  //TODO:---- PRIVE STATE START
  const [privewArr, setPrivewArr] = useState(["no"])
  // const [currentIndex, setCurrentIndex] = useState(1);

  const [editModal, setEditModal] = useState({ showModal: false, eidtText: false, isLogoUpdate: false });
  const { isLogoUpdate } = editModal;
  const [branding, setBranding] = useState(false);

  const fileInputRef = useRef(null);
  // console.log(editModal);

  //TODO:---- PRIVE STATE END

  function coBrandingTextHandler(e, validation) {
    setBrandinText({ ...brandinText, [e.target.name]: e.target.value });
  }
  function uploadComanyLogoHandler(e) {
    setIsLogoUploaded(true)
    const file = e?.target?.files[0];
    const image = new window.Image();
    image.src = URL?.createObjectURL(file);
    // setNewLogo(false)
    image.onload = async function () {
      const width = image.width;
      const height = image.height;

      // if (width > DEFAULT_IMAGE_WIDTH || height > DEFAULT_IMAGE_HEIGHT) {
      if (false) {
        toast.error(`Image dimensions must be ${DEFAULT_IMAGE_WIDTH}x${DEFAULT_IMAGE_HEIGHT} or smaller.Uploaded image is(${width}x${height}) `);
      } else {
        //  toast.warn("logi must be")
        setCompanyLogo({ ...companyLogo, logoUrl: URL.createObjectURL(file), logoFile: file });
        const orgLogo = new FormData();
        orgLogo.append("organization-logo", file);
        setEditModal({ showModal: false, eidtText: false, isLogoUpdate: false })
        const res = await commonAuthApis("user/upload-organization-logo", orgLogo, "put");
        // console.log(res)
        if (res.status === 200) {
          setIsLogoUploaded(false)
          toast.success("Successfully Upldated.")
          localStorage.setItem('finUserDetails', JSON.stringify(res?.data?.data));
          // console.log(res)

        }

      }
    };



  }
  const reversedPositionNames = Object.fromEntries(
    Object.entries(positionNames).map(([key, value]) => [value, key])
  );


  useEffect(async () => {
    const res = await getAuthDataApi("website/userPreference")
    if (res) {
      setContact(res?.branding)
      setCobrandingLabelling({

        ...cobrandingLabelling,
        personalization: res?.personalizedMessage.length > 0,
        personalizationText: res?.personalizedMessage,
        startText: res?.cobrandPositions.start.some((i) => i === "TEXT") || false,
        endText: res?.cobrandPositions.end.some((i) => i === "TEXT") || false,
        startLogo: res?.cobrandPositions.start.some((i) => i === "LOGO") || false,
        endLogo: res?.cobrandPositions.end.some((i) => i === "LOGO") || false,
        fullText: res?.cobrandPositions?.full?.text && res?.cobrandPositions?.full?.text[0],
        fullLogo: res?.cobrandPositions?.full?.logo && res?.cobrandPositions?.full?.logo[0],
        lableEnd: res?.cobrandPositions?.end.length > 0 ? true : false,
        labelStart: res?.cobrandPositions?.start.length > 0 ? true : false,
        labelFull: (res?.cobrandPositions?.full?.text?.length > 0 || res?.cobrandPositions?.full?.logo?.length > 0) ? true : false,
        textPosition: res?.cobrandPositions?.full?.text && res?.cobrandPositions?.full?.text[0],
        logoPosition: res?.cobrandPositions?.full?.logo && res?.cobrandPositions?.full?.logo[0]

      })
      setCobrandcolorLanguage({
        ...cobrandcolorLanguage,
        color: res?.color,
        language: res?.language
      })
    }
  }, [])

  const handleCheckboxChange = (event, position = '', value) => {
    const { name, checked } = event.target;

    if (position) {
      if (checked) {
        setPrivewArr([...privewArr, position]);
      } else {
        const filter = privewArr.filter((item) => item !== position);
        setPrivewArr(filter);
      }
    }

    if (name === 'labelStart') {
      setCobrandingLabelling({
        ...cobrandingLabelling,
        labelStart: checked,
        startText: checked ? cobrandingLabelling.startText : false,
        startLogo: checked ? cobrandingLabelling.startLogo : false
      });

    } else if (name === 'lableEnd') {
      setCobrandingLabelling({
        ...cobrandingLabelling,
        lableEnd: checked,
        endText: checked ? cobrandingLabelling.endText : false,
        endLogo: checked ? cobrandingLabelling.endLogo : false
      });

    } else if (name === 'labelFull') {
      setCobrandingLabelling({
        ...cobrandingLabelling,
        labelFull: checked,
        fullText: checked ? cobrandingLabelling.fullText : false,
        fullLogo: checked ? cobrandingLabelling.fullLogo : false,
        textPosition: checked ? cobrandingLabelling.textPosition : "",
        logoPosition: checked ? cobrandingLabelling.logoPosition : ""
      });

    } else {
      setCobrandingLabelling({ ...cobrandingLabelling, [name]: checked });

    }
  };
  const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg']; // Add more extensions if needed
  const docExtensions = ['.pdf', '.doc', '.docx', '.ppt', '.pptx'];

  const isFile = (filePath, file) => {
    const extension = filePath ? filePath.toLowerCase().slice(filePath.lastIndexOf('.')) : '';
    switch (file) {
      case 'img':
        return imageExtensions.includes(extension)
      case 'doc':
        return docExtensions.includes(extension)
      default:
        return false;

    }
    return imageExtensions.includes(extension);
  };
  const handleCheckboxState = () => {
    setChecked(!checked);
  }

  const dropdownOptions = Object.entries(positionNames).map(([position, value]) => ({ position, value }));
  useEffect(async () => {
    const res = await getAuthDataApi("website/languages");
    if (res) {
      const languages = res?.list?.map((item) => item?.languageName)
      setLanguageOptions(res?.list)
    };

    const colors = await getAuthDataApi("website/color");
    if (colors) {
      setColorOptions(colors?.list)

    }

  }, []);


  const handleSubmit = (async () => {
    setloading(true);

    const start = [
      cobrandingLabelling.startText ? "TEXT" : null,
      cobrandingLabelling.startLogo ? "LOGO" : null,
    ].filter(item => item !== null);

    const end = [
      cobrandingLabelling.endText ? "TEXT" : null,
      cobrandingLabelling.endLogo ? "LOGO" : null,
    ].filter(item => item !== null);

    const full = cobrandingLabelling.labelFull
      ? {
        text: (cobrandingLabelling.fullText && cobrandingLabelling.textPosition) ? [cobrandingLabelling.textPosition] : [],
        logo: (cobrandingLabelling.fullLogo && cobrandingLabelling.logoPosition) ? [cobrandingLabelling.logoPosition] : []
      }
      : [];

    const data = {
      data: {
        ...(contact && { branding: contact }),
        personalizedMessage: personalization && personalizationText || "",
        cobrandPositions: {
          start: start,
          end: end,
          full: full
        },
        ...(cobrandcolorLanguage.color?.uuid && {
          color: {
            uuid: cobrandcolorLanguage.color.uuid
          }
        }),
        ...(cobrandcolorLanguage.language?.uuid && {
          language: {
            uuid: cobrandcolorLanguage.language.uuid
          }
        })
      }
    };




    // console.log(data)

    const res = await commonAuthPostApi("/website/userPreference", data)
    if (res?.data) {
      setloading(false);

      toast.success("Added Successfully")

    }
  })
  return (
    <div className="my-preference-content">

      {/* ====================================================================================== */}
      <h5 className="lato-medium fs-24-18 black-242 mb-4">Content Preference</h5>
      {/* ================ COLOR AND LANGUAGE START =============== */}
      <div className="branding-color-language-frame">
        <h5 className=' lato-semibold fs-16-14 black-242 op-5'>Preferred content settings</h5>
        <div className="dropwon-component-wrapper justify-content-start">

          <CustomDropDown
            label={color || "Color"}
            options={colorOptions}
            // options={getColorOptions(variation, language)}
            // getData={(data) => console.log(data)}
            getData={(data) => setCobrandcolorLanguage({ ...cobrandcolorLanguage, color: data || "" })}

            id="color"
            // disabled={getColorOptions(variation, language).length <= 1}
            classes='light-border'
          />


          <CustomDropDown
            label={language?.languageName || "Language"}
            // options={uniqueLangs || []}
            options={languageOptions}
            getData={(data) => {
              // console.log(data)
              setCobrandcolorLanguage({ ...cobrandcolorLanguage, language: data || {} })
            }}
            id="Language"
            // disabled={(uniqueLangs?.length <= 1)}
            classes='light-border'
          />

        </div>
      </div>
      {/* ================ COLOR AND LANGUAGE END ================= */}
      {/* ====================================================================================== */}


      <div className="branding-preference d-flex flex-column">
        <h5 className="lato-medium fs-24-18 black-242">Branding Preference</h5>
        <div className="preference-checkbox d-flex flex-row align-text-center">
          <input type="checkbox" checked={branding} name="label-brand" onChange={(e) => setBranding(!branding)} id="branding" className='d-none ' />
          <label htmlFor="branding" className='cobrand-custom-checkbox mb-0 mt-1'>
            <img src={ImageChecked} alt="" />
          </label>
          <p className='lato-semibold fs-16-14 black-242'>I would like to add branding to the posts</p>
        </div>
        <h6 className='lato-regular fs-14-12 black-242 op-5'>Based on your selection, preferred branding will be applied on content as per the availability. In case of cobranding and certain whitelabel content where the preferred position is not available, default positioning would be selected. </h6>

        <div className='preferences-radio-btn'>
          <RadioFields
            options={selectOption}
            initialValue={contact}
            onSelectionChange={(v) => { setContact(v) }}
            type={"sm-column"}

          />

        </div>
      </div>

      <div className="company-details-wrapper mt-4">
        <h5 className='lato-bold f-s-20 lh-28 black-242 d-flex align-items-baseline justify-content-between '><span className='op-7'>Company details </span>

        </h5>
        <div className="uplad-data mb-wrap">
          <input type="file" ref={fileInputRef} name="" className=' d-none' onChange={uploadComanyLogoHandler} id="companyLogo" accept="image/*" />
          {isLogoUpdate && <CobrandUpdateDetailsModal editModal={editModal} setEditModal={setEditModal} />}

          {!logoUrl ?
            <div className="company-logo-main-wrapper pointer" onClick={() => fileInputRef.current.click()}>
              <label htmlFor={''} className="upload-image-logo ">
                <span className=' lato-medium f-s-11 mw-83 lh-16 black-50 m-0'>Upload your logo 450x250 px</span>
              </label>
              <p className=' lato-medium f-s-9 lh-12 black-242 '> <img src={editPencle} className='edit-pen-cobrand-logo' alt="" /> <span className='underline'>Upload logo 450x250 px</span></p>

            </div>
            :
            <div className="company-logo-main-wrapper" > {/* AFTER IMAGE UPLOADED */}
              <div className={`company-logo-frame ${isLogoUploaded ? 'show-loading-effect' : ''}`}>
                <img src={logoUrl} alt="" className='company-logo' />
              </div>
              <label htmlFor='' className='p-0 m-0'>
                <p className=' lato-medium f-s-9 lh-12 black-242 ' onClick={() => { setEditModal({ ...editModal, isLogoUpdate: true, showModal: true, eidtText: false }) }}> <img src={editPencle} className='edit-pen-cobrand-logo' alt="" /> <span className='underline'>Upload new logo 450x250 px</span></p>
              </label>
            </div>
          }
          <div className="brand-select-text-wrapper">
            <input
              type="text"
              onChange={(e) => coBrandingTextHandler(e, ["isAlphaNumeric"])}
              className={`input-box lato-regular fs-14-13 ${company ? "higheLight" : ""
                }`}
              value={company}
              name="company"
              placeholder="Company Name"
              disabled={false ? false : true}
            />
            <input
              type="number"
              onChange={(e) => coBrandingTextHandler(e, ["isNum"])}
              className={`input-box lato-regular fs-14-13 ${arnNo ? "higheLight" : ""
                }`}
              value={arnNo}
              name="arnNo"
              placeholder="ARN Number"
              maxLength={10}
              disabled={false ? false : true}
            />
            <input
              type="email"
              onChange={(e) => coBrandingTextHandler(e, ["isEmail"])}
              className={`input-box lato-regular fs-14-13 ${companyEmail ? "higheLight" : ""
                }`}
              value={companyEmail}
              pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
              title="Please enter a valid email address"
              name="companyEmail"
              placeholder="Email Id"
              disabled={false ? false : true}
            />
            <input
              type="number"
              onChange={(e) => coBrandingTextHandler(e, ["isNum"])}
              className={`input-box lato-regular fs-14-13 ${companyNo ? "higheLight" : ""
                }`}
              value={companyNo}
              name="companyNo"
              placeholder="Contact Number"
              maxLength={10}
              disabled={false ? false : true}
            />
          </div>
        </div>

      </div>
      {/* COMPANY DETAILS END */}
      {/* ------------------- */}
      {/* ------------------- */}
      {/* Co-BRANDING/WHITE-LABELING START */}
      <div className="my-preferences-co-branding-leabling">
        <h5 className='lato-bold f-s-20 lh-28 black-242 op-7 mb-16'>Co-branding/White-labelling</h5>
        <p className=' lato-regular fs-14-13 black-242 op-5'>Co-branding/White-labelling display positions.</p>
        {true &&
          <>
            {true &&
              <div className="start-label-frame">
                <input type="checkbox" name="labelStart" checked={labelStart} onChange={(e) => handleCheckboxChange(e, 'Start Position')} id="start-check-box" className='d-none ' />
                <label htmlFor="start-check-box" className='cobrand-custom-checkbox mb-0 mt-1'>
                  <img src={ImageChecked} alt="" />
                </label>
                <div className="check-box-text-frame">
                  <p className=' lato-regular fs-14-13 black-242'>Start</p>
                  <p className=' lato-regular f-s-12 lh-16 black-242 op-5 '>Co-branding/White-labelling will be positioned in the Center before any content</p>
                  {labelStart &&

                    <div className="start-logo-frame">
                      {/* CHECK BOX WRAPPER START */}
                      <div className=" d-flex align-items-center gap-8">
                        <input type="checkbox" name="startText" checked={startText} onChange={handleCheckboxChange} id="start-check-box1" className='d-none ' />
                        <label htmlFor="start-check-box1" className='cobrand-custom-checkbox mb-0 mt-1'>
                          <img src={ImageChecked} alt="" />
                        </label>
                        <p className=' lato-regular fs-14-13 black-242'>Text</p>
                      </div>
                      {/* CHECK BOX WRAPPER END */}
                      {/* CHECK BOX WRAPPER START */}
                      <div className={`d-flex align-items-center gap-8 ${!logoUrl ? 'disabled-checkBox' : ""}`}>
                        <input type="checkbox" name="startLogo" checked={startLogo} onChange={handleCheckboxChange} disabled={!logoUrl} id="start-check-box2" className='d-none ' />
                        <label htmlFor="start-check-box2" className='cobrand-custom-checkbox mb-0 mt-1'>
                          <img src={ImageChecked} alt="" />
                        </label>
                        <p className=' lato-regular fs-14-13 black-242'>Logo</p>
                      </div>
                      {/* CHECK BOX WRAPPER END */}
                    </div>


                  }
                </div>
              </div>
            }
            {true &&
              <div className="start-label-frame">
                <input type="checkbox" name="lableEnd" checked={lableEnd} onChange={(e) => handleCheckboxChange(e, 'End Position')} id="end-check-box" className='d-none ' />
                <label htmlFor="end-check-box" className='cobrand-custom-checkbox mb-0 mt-1'>
                  <img src={ImageChecked} alt="" />
                </label>
                <div className="check-box-text-frame">
                  <p className=' lato-regular fs-14-13 black-242'>End</p>
                  <p className=' lato-regular f-s-12 lh-16 black-242 op-5 '>Co-branding/White-labelling will be positioned in the Center after any content.</p>
                  {lableEnd &&
                    <div className="start-logo-frame">
                      {/* CHECK BOX WRAPPER START */}
                      <div className=" d-flex align-items-center gap-8">
                        <input type="checkbox" name="endText" checked={endText} onChange={handleCheckboxChange} id="end-check-box1" className='d-none ' />
                        <label htmlFor="end-check-box1" className='cobrand-custom-checkbox mb-0 mt-1'>
                          <img src={ImageChecked} alt="" />
                        </label>
                        <p className=' lato-regular fs-14-13 black-242'>Text</p>
                      </div>
                      {/* CHECK BOX WRAPPER END */}
                      {/* CHECK BOX WRAPPER START */}
                      <div className={`d-flex align-items-center gap-8 ${!logoUrl ? 'disabled-checkBox' : ""}`}>
                        <input type="checkbox" name="endLogo" checked={endLogo} disabled={!logoUrl} onChange={handleCheckboxChange} id="end-check-box2" className='d-none ' />
                        <label htmlFor="end-check-box2" className='cobrand-custom-checkbox mb-0 mt-1'>
                          <img src={ImageChecked} alt="" />
                        </label>
                        <p className=' lato-regular fs-14-13 black-242'>Logo</p>
                      </div>
                      {/* CHECK BOX WRAPPER END */}
                    </div>
                  }
                </div>
              </div>
            }
          </>}
        {true &&
          <div className="start-label-frame">
            <input type="checkbox" name="labelFull" checked={labelFull} onChange={(e) => handleCheckboxChange(e, 'Full')} id="full-check-box" className='d-none ' />
            <label htmlFor="full-check-box" className='cobrand-custom-checkbox mb-0 mt-1'>
              <img src={ImageChecked} alt="" />
            </label>
            <div className="check-box-text-frame">
              <p className=' lato-regular fs-14-13 black-242'>Full</p>
              <p className=' lato-regular f-s-12 lh-16 black-242 op-5'>Co-branding/White-labelling will be allowed on available positions on the content.</p>
              {labelFull &&
                <div className="start-logo-frame d-flex flex-row align-items-start gap-12 flex-wrap mb-3">
                  {/* CHECK BOX WRAPPER START */}
                  <div className=" d-flex align-items-center gap-8 mw-184 mb-full ">
                    <input type="checkbox" name="fullText" checked={fullText} onChange={handleCheckboxChange} id="full-check-box1" className='d-none ' />
                    <label htmlFor="full-check-box1" className='cobrand-custom-checkbox mb-0 mt-1'>
                      <img src={ImageChecked} alt="" />
                    </label>
                    <p className=' lato-regular fs-14-13 black-242'>Text</p>
                  </div>
                  {/* CHECK BOX WRAPPER END */}
                  {/* CHECK BOX WRAPPER START */}
                  <div className={`d-flex align-items-center gap-8 ${!logoUrl ? 'disabled-checkBox' : ""}`}>
                    <input type="checkbox" name="fullLogo" checked={fullLogo} disabled={!logoUrl} onChange={handleCheckboxChange} id="full-check-box2" className='d-none ' />
                    <label htmlFor="full-check-box2" className='cobrand-custom-checkbox mb-0 mt-1'>
                      <img src={ImageChecked} alt="" />
                    </label>
                    <p className=' lato-regular fs-14-13 black-242'>Logo</p>
                  </div>
                  {/* CHECK BOX WRAPPER END */}
                </div>
              }

              {labelFull &&
                <div className=" d-flex flex-row align-items-start gap-12 flex-wrap">
                  <div className=' d-flex mb-full'>
                    <CustomDropDown
                      label={reversedPositionNames[textPosition] || "Select Text Position"}
                      options={dropdownOptions}
                      getData={(data) => setCobrandingLabelling({ ...cobrandingLabelling, textPosition: data.value })}
                      id="normal"
                      disabled={!fullText}
                      classes='custom-button-font'
                    />
                  </div>
                  <div className=' d-flex mb-full'>
                    <CustomDropDown
                      label={reversedPositionNames[logoPosition] || "Select Logo Position"}
                      options={dropdownOptions}
                      getData={(data) => setCobrandingLabelling({ ...cobrandingLabelling, logoPosition: data.value })}
                      id="normal"
                      disabled={!fullLogo}
                      classes='custom-button-font'
                    />
                  </div>
                </div>
              }
            </div>
          </div>
        }

      </div>
      {/* Co-BRANDING/WHITE-LABELING END */}
      {/* ------------------- */}

      {/* ------------------- */}


      {/* Personalization START */}
      <div className="personalization-frame mb-5">
        <h5 className='lato-bold f-s-20 lh-28 black-242 op-7 mb-16'>Customized Message</h5>
        <div className="start-label-frame d-flex gap-8">
          <input type="checkbox" name="personalization" checked={personalization} onChange={(e) => handleCheckboxChange(e, 'Personalization')} id="personalization-check-box" className='d-none ' />
          <label htmlFor="personalization-check-box" className='cobrand-custom-checkbox mb-0 mt-1'>
            <img src={ImageChecked} alt="" />
          </label>
          <div className="check-box-text-frame">
            <p className=' lato-semibold fs-16-14 black-242'>Do you want to add custom message?</p>
            <p className=' lato-medium f-s-12 lh-16 black-242 op-5 mb-12'>Your custom message will get added to start of the video and on the top of other content types</p>
          </div>
        </div>
        {personalization &&
          <div className="text-area-frame">
            <textarea type="text" maxLength={120} className='fx-14 lato-normal black-242 op-8 text-area ' name='personalizationText' value={personalizationText} onChange={(e) => { setCobrandingLabelling({ ...cobrandingLabelling, personalizationText: e.target.value }) }} placeholder='Add your short greeting message here.' />
            <span className='lato-regular f-s-8 lh-12 black-242 op-5 count-text-words'>{personalizationText.length}/120</span>
          </div>
        }
      </div>

      {/* Personalization END */}
      {/* ------------------- */}

      <div className="my-preference-btn-main d-flex justify-content-center">
        <Button disabled={loading} onClick={handleSubmit} className="primary-btn lato-medium fs-14-12">{loading ? <span className=' d-flex align-items-center gap-12 justify-content-center'><Spinner /> Saving...</span> : "Save Changes"}</Button>
      </div>


    </div>
  )




}

function CobrandUpdateDetailsModal({ editModal, setEditModal }) {
  const { showModal, eidtText, isLogoUpdate } = editModal;
  // console.log(showModal)
  return (
    <Modal className='cobrand-edit-modal' centered show={showModal} onHide={() => setEditModal({ ...editModal, showModal: false })}>
      <Modal.Body>
        <div className="cobrand-edit-modal-header">
          <h3 className='f-s-20 lh-28 primary lato-bold'>Edit company details?</h3>
          <Image onClick={() => setEditModal({ ...editModal, showModal: false })} src={Close1} alt='icon' className='cancle-icon pointer' />
        </div>
        <p className=' lato-regular f-s-14 lh-20 black-242'>Changes will be applied to your profile page.<br />
          Note: You will be required to re-verify any new details entered here.</p>
        <p className=' lato-regular f-s-14 lh-20 black-242 my-12'>Do you still wish to change company details?</p>
        {isLogoUpdate &&
          <p className="f-s-10">(Please upload image of {DEFAULT_IMAGE_WIDTH} X {DEFAULT_IMAGE_HEIGHT} dimensions for best output)</p>
        }
        <div className=" d-flex align-items-center justify-content-end gap-12">
          <Button className='secondary-btn mw-102' onClick={() => setEditModal({ ...editModal, showModal: false })}>Cancel</Button>

          {isLogoUpdate ?
            <label htmlFor={"companyLogo"} className='primary-btn mw-102 py-10 text-center pointer mb-0' >Yes</label>
            :
            <Button className='primary-btn mw-102 py-10  ' onClick={() => setEditModal({ ...editModal, showModal: false, eidtText: true, isLogoUpdate: false })}>Yes</Button>
          }
        </div>
      </Modal.Body>
    </Modal>
  )
}