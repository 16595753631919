import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { API_LIMIT, SOCIALMEDIA_URL, SOCIAL_MEDIA, START_PAGE } from "../../utils/Constant";
import { getAllPostApi, getCommonApi } from "../home/Api";
import { AllShimmer } from "../common/components";
import { Footer, Header, NotFound } from "../common/pages";
import SocialMediaPostCard from "./SocialMediaPostCard";
import { SearchFilter } from "../common";
import { BreadCrumb } from "../home/_utils";


export default function SocialMedia() {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [postList, setPost] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  async function fetchData() {
    let data = await getCommonApi(SOCIALMEDIA_URL);
    const res = await getAllPostApi();
    setPost(res.list)
    if (data.status === 200) {
      setData(data?.data?.data || []);
      setFilterData(data?.data?.data || []);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
  }, [])
  function navigate(data) {
    history.push({
      pathname: `/content-type/All`,
      search: `?key=socialMedias&value=${encodeURIComponent(data?.socialMediaName)}&pageNumber=${START_PAGE}&pageSize=${API_LIMIT}`,
      state: data
    })
  }
  function setValueHandler(info) {
    setFilterData(info)
  }
  return (
    <div className="social-media-main-wrapper">
      <Header />
      <section className="page-padding">
        {isLoading ?
          <AllShimmer type="POTD" noContainer={false} />
          :
          <>
            <div className="container">
              <BreadCrumb path={["Social Media"]} />
              <SearchFilter data={data} filterData={filterData} setValueHandler={setValueHandler} name="socialMedia" placeholder="social media" />
            </div>


            <div className="social-media-card-wrapper">
              <div className="">

                <div className="container">
                  <h5 className="lato-regular fs-32-28 w-100 mb-20">Social Media</h5>
                  <div className="social-media-posts-wrapper align-items-stretch">
                    {filterData ?
                      filterData?.map((item, i) => {
                        return (
                          <SocialMediaPostCard
                            onClick={() => navigate(item)}
                            data={item}

                          />
                        )
                      })
                      :
                      <NotFound />
                    }

                  </div>
                </div>
              </div>
            </div>
          </>

        }
      </section>
      <Footer />
      {/* <SocialMediaPage viewAll={`/content-type/${SOCIALMEDIA}/post-of-the-day`} title="post-of-the-day" /> */}
    </div>
  );
}
