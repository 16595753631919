import React, { useState, useEffect } from "react";

export default function RadioFields({ options, type, onSelectionChange, label, classes, initialValue = null, disabled = false }) {
  const [selectedValue, setSelectedValue] = useState(initialValue);

  useEffect(() => {
    setSelectedValue(initialValue)
  }, [initialValue])


  return (
    <div className="radio-fields-frame">
      <h3 htmlFor="lato-regular sip-amount f-s-14 lh-20 black-242 ">
        {label}
      </h3>
      <div className={`radio-option-frame d-flex gap-12 py-4 ${type === "column" ? "flex-column" : ""} ${type === "sm-column" ? "flex-column flex-md-row" : ""} ${type === "column" ? "align-items-start" : "align-items-md-center"} ${type === "sm-column" ? "align-items-start justify-content-sm-start d-sm-flex-column" : ""}`}>

        {options?.map((option, i) => (
          <div className={`${classes} d-flex align-items-center gap-12 mr-1 `}>
            <input
              type="radio"
              className="input-radio"
              id={option.label || option.socialMediaName || option.categoryName}
              name={option.label || option.socialMediaName || option.categoryName}
              // disabled={option.disabled}
              value={option.value || option.position || option.socialMediaName || option.categoryName}
              checked={selectedValue === (option.value || option.position || option.socialMediaName || option.categoryName)}
              onChange={() => {
                onSelectionChange(option.value ? option.value : option);
                setSelectedValue(option.value || option.position || option.socialMediaName || option.categoryName);
              }}
            />
            <label
              htmlFor={option.label || option.socialMediaName || option.categoryName}
              className="f-s-14 lh-20 black-242 lato-regular mb-0 pointer"
            >
              {option.label || option.position || option.socialMediaName || option.categoryName}
            </label>
          </div>))}

      </div>
    </div>
  );
}

