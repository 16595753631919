
import { BreadCrumb } from "../home/_utils";
import PreferenceContent from "./PreferenceContent";

export default function MyPreferences() {


  return (
    <div className="my-preferences-wrapper">
      <BreadCrumb path={["My Preferences"]} />
      <div className="my-preference-heading">
        <h5 className="fs-32-28 lato-semibold black-242 ">My Preferences</h5>
      </div>
      <PreferenceContent />
    </div>



  )



}