import { Button, Image, Modal } from "react-bootstrap";
import CrossIcon from "../../assets/images/icon/cross-modal-icon.svg";
import { commonAuthPostApi, getAuthDataApi } from "../home/Api";
import { deletePostApi, getPlaylistApi } from "./Api";
import { toast } from "react-toastify";
import { groupData, groupDataByDate, isResOk } from "../../utils/ReusableFunctions";
import { MAX_LIMIT, START_PAGE } from "../../utils/Constant";
import { useState } from "react";
import { Spinner } from "../common/components";



export default function DeletePostModal({ showDelete, onHide, deleteData, setPlaylistData, setRecentData, handleClose, type, selectedItems, setSelectedItems, setIsSelectAll, setSelectedPlan, setEvents, posts, setPosts, setRecentLength }) {
  const [loading, setLoading] = useState(false)


  async function deletePost(uuid) {
    if (type == "custom") {


      setLoading(true);
      const res = await deletePostApi(`website/playlist/${uuid}`);
      if (res) {

        const data = await getPlaylistApi(`website/playlist?pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}`);
        if (data) {
          setPlaylistData(data?.data?.list);

        }
        setLoading(false)
        onHide();
        handleClose();
      }
      else {
        setLoading(false);
      }

    }
    else if (type == "postDelete") {
      try {
        setLoading(true)


        const res = await deletePostApi(`website/scheduledPostPlanner/${uuid}`);
        if (res) {


          const data = await getAuthDataApi(`/website/userPostPlanner/recent`);
          if (data) {
            setLoading(false)
            const groupedData = groupDataByDate(data);
            setRecentData(groupedData);
            const groupedDataRes = groupData(data);
            setEvents(groupedDataRes);
            setPosts(data);

            setRecentLength(data?.length);
          }

        } else {
          setLoading(false)
        }
      } catch (error) {
        setLoading(false)
        console.log(error)

      } finally {
        onHide();

        setLoading(false)
      }
    }
    else {


      try {
        const payload = {

          "data": selectedItems

        }
        setLoading(true)
        const res = await commonAuthPostApi("website/scheduledPostPlanner/deleteAll", payload)

        if (res?.data) {
          toast.success("Successfully Deleted");
          const data = await getAuthDataApi(`/website/userPostPlanner/recent`);

          if (data) {
            setLoading(false);
            const groupedData = groupData(data);
            const groupedDataRes = groupDataByDate(data);


            setRecentData(groupedDataRes);
            setEvents(groupedData);
            setPosts(data);
          }

          setIsSelectAll(false);
          setSelectedPlan("");
          setSelectedItems([]);
          onHide();
          setRecentLength(data?.length);

        } else {
          setLoading(false)
        }
      } catch (error) {
        setLoading(false)

      }
    }
  }

  return (
    <Modal className='delete-post-modal' centered show={showDelete} onHide={onHide}>
      <Modal.Body>
        <div className="delete-modal-heading d-flex flex-column">
          <div className=" d-flex flex-row justify-content-between">
            <p className="lato-semibold fs-20-16 primary">{type == "custom" ? "Delete Plan?" : "Delete Post?"}</p>
            <Image className="cross-icon" width={16} height={16} src={CrossIcon} onClick={onHide} style={{ cursor: "pointer" }} />
          </div>
          <div className="delete-text d-flex  justify-content-center">
            {type == "custom" ? <p className="lato-medium fx-14 black-242">
              Are you sure, you want to permanently delete this customized plan? If your plan is active, your scheduled posts will be discarded.
            </p>
              :
              <p className="lato-medium fx-14 black-242">
                Are you sure, you want to permanently delete selected post(s)?
              </p>
            }
          </div>
          <div className="delete-modal-tab-btn d-flex flex-row justify-content-end gap-12">
            <Button onClick={onHide} className="secondary-btn lato-medium fs-14-12">Cancel</Button>
            <Button disabled={loading} onClick={() => deletePost(deleteData)} className="primary-btn lato-medium fs-14-12">{loading ? <span className=' d-flex align-items-center gap-12 justify-content-center'><Spinner /> Deleting...</span> : "Delete"}</Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
