import React, { useEffect, useState } from 'react'
import { Header } from '../common/pages'
import backIcon from '../../assets/images/left-back-arrow.png'
import vidoImg from '../../assets/images/video-img.png'
import cancle from '../../assets/images/cancle-input.svg'
import logo from '../../assets/images/brand-logo.png';
import { Button, Image } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { checkSubscriptionStatus, commonAuthApis } from '../profile/Api'
import { getUser, isResOk } from '../../utils/ReusableFunctions'
import moment from 'moment/moment'
import { Spinner } from '../common/components'
import { EVENTS, IS_ONLINE, PAID, SHARE_BASE_URL, SHARE_RAZ_KEY, loadScript } from '../../utils/Constant'
import { toast } from 'react-toastify'
import { RWebShare } from 'react-web-share'
import { FaCopy } from "react-icons/fa";
import { useRef } from 'react';
import AddToCalendar from '@culturehq/add-to-calendar'
import { FormValidator } from '../../utils/form'

/* eslint-disable react-hooks/exhaustive-deps */

export default function EventCheckOut() {
  const history = useHistory();
  const location = useLocation();
  const userDetails = getUser() || {};
  const [isUpdating, setIsUpdating] = useState(false);
  const [coupon, setCoupon] = useState('');
  const [isConform, setIsConform] = useState(true);
  const [subPremiumModal, setSubPremiumModal] = useState({ isCurrentSub: false, isValidSubscription: false });
  const { isCurrentSub, isValidSubscription } = subPremiumModal;
  const [applyCoupon, setApplyCoupon] = useState(true)
  const [registerEventDetails, setRegisterEventDetails] = useState({})
  const [breakupInfo, setBreakupInfo] = useState({});
  const [eventUser, setEventUser] = useState({ firstName: userDetails.firstName, lastName: userDetails.lastName, emailAddress: userDetails.emailAddress, mobileNumber: userDetails.mobileNumber });
  const { firstName, lastName, emailAddress, mobileNumber } = eventUser;
  const isFormValid = firstName && lastName && emailAddress && FormValidator.isEmail(emailAddress) && mobileNumber;
  const textRef = useRef(null);

  const handleCopyClick = () => {
    const textToCopy = textRef.current.innerText;

    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  };
  useEffect(() => {
    if (location.state?.eventRegistration) {
      setRegisterEventDetails(location.state?.eventRegistration);
      setIsConform(false)


    }
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
    aplyCouponHandler(false);

  }, [])
  function changeHandler(e) {
    setEventUser({ ...eventUser, [e.target.name]: e.target.value })
  }
  const isUpdated = (name) => {
    return userDetails[name] !== eventUser[name];
  }
  const registeredEvent = async (isTrue = false) => {
    const eventInfo = {
      "data": {
        "eventUuid": location.state?.detail?.uuid

      }
    }

    const res = await commonAuthApis('eventRegistration', eventInfo)

    if (res.status >= 200 && res.status <= 210) {
      setRegisterEventDetails(res?.data?.data.event)
      setIsConform(false);

      if (isTrue) {
        const res = await commonAuthApis('website/events/utilize-subscription');
        if (isResOk(res.status)) {
          setIsConform(false);
        }
      }
    }
  }

  const paymentHandler = async () => {
    let orderData = {
      "data": {
        "amount": breakupInfo.breakup.finalAmount * 100,
        "currency": "INR",
        "receipt": "Finpedia",
        "eventUuid": location?.state?.detail?.uuid
      }
    }
    const res = await commonAuthApis('payment/order', orderData);

    if (res.status >= 200 && res.status <= 210) {

      const orderRes = res.data?.data;

      if (breakupInfo?.breakup?.finalAmount === 0) {

        // console.log('orderRes', orderRes);
        let response = {
          amount: breakupInfo?.breakup?.finalAmount,
          couponCode: coupon,
          emailAddress: emailAddress
        }

        const res = await commonAuthApis(`website/events/${orderRes?.uuid}/process`, { "data": response });


        if (res.status >= 200 && res.status <= 210) {
          toast.success('payment successful')
          setRegisterEventDetails(res?.data?.data?.event)


          setIsConform(false)


        }

      }
      else {

        var options = {
          "key": SHARE_RAZ_KEY,
          "amount": res?.data?.data.amount,
          "currency": res?.data?.data.currency,
          "name": "Thefinpedia",
          "description": "Test Transaction",
          "image": logo,
          "order_id": res?.data?.data.id,
          "eventRegistrationUuid": res?.data?.data?.eventRegistration?.uuid,
          // "callback_url": "http://13.200.83.191:3001/finpedia/v1/payment/checkout",
          "handler": async function (response) {
            response.eventRegistrationUuid = orderRes?.eventRegistration?.uuid;

            const res = await commonAuthApis('payment/checkout', { "data": response });

            if (res.status === 200) {
              toast.success('payment successful')
              setRegisterEventDetails(res?.data?.data?.event)
              setIsConform(false)
              // registeredEvent()
              // handleClose()
            }
          },
          "prefill": {
            "name": firstName + lastName,
            "email": emailAddress,
            "contact": mobileNumber
          },
          "notes": {
            "address": "Razorpay Corporate Office"
          },
          "theme": {
            "color": "#3399cc"
          }
        };
        let rzp1 = window.Razorpay(options);
        rzp1.open();
      }
    }
  }
  async function registerEventHandler() {


    setIsUpdating(false)

    if (location.state?.detail?.eventType === PAID) {

      const subCheckData = {
        "data": {
          "eventUuid": location?.state?.detail?.uuid
        }
      }
      const isValidSub = await checkSubscriptionStatus('website/events/check-subscription', subCheckData);
      if (isResOk(isValidSub.status)) {
        setSubPremiumModal({ ...subPremiumModal, isValidSubscription: true });
      } else {
        setSubPremiumModal({ ...subPremiumModal, isCurrentSub: true });

      }
    } else {
      registeredEvent()
    }
  }
  async function proceedToPayhandler() {
    setIsUpdating(true);
    const userInfo = {
      data: {
        ...(isUpdated("firstName") ? { firstName } : {}),
        ...(isUpdated("lastName") ? { lastName } : {}),
        ...(isUpdated("emailAddress") ? { emailAddress } : {}),
        ...(isUpdated("mobileNumber") ? { mobileNumber } : {}),
      }
    }

    if ((Object.keys(userInfo.data).length) > 0) {
      const res = await commonAuthApis("user/update-profile", userInfo, "put");
      if (res.status >= 200 && res.status <= 210) {
        localStorage.setItem('finUserDetails', JSON.stringify(res?.data?.data));
        registerEventHandler()
      }
    } else {
      registerEventHandler()
    }
  }

  const aplyCouponHandler = async (val) => {

    const coponData = {
      "data": {
        "eventUuid": location.state?.detail?.uuid,
        ...(!val ? {} : { "couponCode": coupon })

      }
    };
    const res = await commonAuthApis('eventRegistration/registration-amount-breakup', coponData);
    if (res.status === 200) {
      setBreakupInfo(res?.data?.data);


    }
  }

  useEffect(() => {
    // aplyCouponHandler();
  }, [applyCoupon])


  return (
    <div className='event-checkout-main-section'>
      <Header />
      {isConform ?
        <div className="event-checkout-section">
          <div className="container">
            <div className="event-checkout-frame">
              <div className="eve-check-form">
                <h3 className=' lato-bold fs-18-16 black-242 pointer' onClick={() => history.push({ pathname: `/${EVENTS}`, search: `pageNumber=1&pageSize=10` })}><Image src={backIcon} alt="backIcon" className=' pointer' onClick={() => history.push({ pathname: `/${EVENTS}`, search: `pageNumber=1&pageSize=10` })} /> <span onClick={() => history.push({ pathname: `/${EVENTS}`, search: `pageNumber=1&pageSize=10` })}>Back to event</span> </h3>
                <h2 className=' lato-bold fs-32-24 black-242'>Complete your registration</h2>
                <div className="input-box-wrapper mb-28-12">
                  <label className='d-block lato-semibold fs-18-16 black-242' htmlFor="name">First Name <span className=' text-danger'>*</span></label>
                  <input type="text" onChange={changeHandler} name="firstName" value={firstName} placeholder="Enter First Name" id='name' className='input-box lato-regular fx-16' />
                </div>
                <div className="input-box-wrapper mb-28-12">
                  <label className='d-block lato-semibold fs-18-16 black-242' htmlFor="name">Last Name <span className=' text-danger'>*</span></label>
                  <input type="text" onChange={changeHandler} name="lastName" value={lastName} placeholder="Enter Last Name" id='name' className='input-box lato-regular fx-16 black-242 ' />
                </div>
                <div className="input-box-wrapper mb-28-12">
                  <label className='d-block lato-semibold fs-18-16 black-242' htmlFor="name">Email Address <span className=' text-danger'>*</span></label>
                  <input type="text" onChange={changeHandler} name="emailAddress" value={emailAddress} placeholder="Enter email address" id='name' className='input-box lato-regular fx-16 black-242 ' />
                </div>
                <div className="input-box-wrapper mb-1">
                  <label className='d-block lato-semibold fs-18-16 black-242' htmlFor="name">Mobile Number <span className=' text-danger'>*</span></label>
                  <input type="text" onChange={changeHandler} name="mobileNumber" value={mobileNumber} placeholder="Mobile no" id='name' className='input-box lato-regular fx-16 black-242 ' />
                </div>
                <p className=' lato-regular fs-14-13 black-6C7'>This information will be shared with the {location?.state?.detail?.contentCreator?.contentCreatorName || location?.state?.detail?.amc?.amcName} Event organizers</p>
                <div className="refund-policy-frame">
                  <h4 className=' lato-bold fx-16 black-242'>Refund Policy</h4>
                  <p className=' lato-regular fx-12 black-242'>The registration fees for events are non-refundable, regardless of attendance or in any situation. If you have questions or need assistance with refunds, please reach out to our support team at info@thefinpedia.com. Kindly provide relevant details, and our team will assist you promptly. </p>

                </div>
              </div>
              <div className="eve-booking-summary">
                <div className="event-card-box">
                  <div className="event-img-box">
                    <img src={location.state?.detail?.thumbnailPath || vidoImg} alt="thumbnailPath" />
                  </div>
                  <ul className='event-card-content'>
                    <li className=' lato-semibold fx-14 black-242 op-8'>{moment(location.state?.detail?.startDate).format('lll') || "Na"} </li>
                    <li className=' lato-bold fs-18-16 black-242'>{location.state?.detail?.name || "Na"}</li>
                    <li className=' lato-semibold fx-14 black-242'>Organised by - {location?.state?.detail?.amc?.amcName || location.state?.detail?.contentCreator?.contentCreatorName || "Na"}</li>
                    <li className=' lato-semibold fx-14 black-242'>Speakers - {location.state?.detail?.eventSpeakers.map(item => item?.speakerName + ", ") || "Na"}</li>
                    <li className=' lato-semibold fx-14 black-242 mb-0'>Venue - {location.state?.detail?.venueName} {location.state?.detail?.city && `(${location?.state?.detail?.city})`}</li>
                  </ul>
                </div>
                <div className="aply-coupon-code">
                  {location.state?.detail?.eventType === PAID && <>
                    <p className=' lato-semibold fx-16 black-242 mb-2'>Apply Coupon Code</p>
                    <div className="coupon-frame-box mb-wrap">
                      <div className="coupon-box">
                        <input type="text" className='coupon-input lato-semibold fx-16 black-242' value={coupon} onChange={(e) => { setCoupon(e.target.value); }} placeholder='COUPON CODE HERE' />
                        {coupon &&
                          <Image src={cancle} className='cancle pointer' onClick={() => { setCoupon(""); aplyCouponHandler(false); setApplyCoupon(true) }} />
                        }
                      </div>
                      <Button className=' primary-btn mb-full py-2' onClick={() => { aplyCouponHandler(true); setApplyCoupon(false); }}>Apply  </Button>
                    </div>
                  </>}

                </div>
                <ul className='booking-summary-details'>
                  <li className=' lato-bold fs-18-16 black-242'>Booking Summary</li>
                  <li className=' lato-regular fx-16 d-flex align-items-center justify-content-between gap-12'>Amount <span>₹{breakupInfo?.breakup?.eventRegistrationPrice || '0'}</span></li>
                  {breakupInfo?.breakup?.couponDiscountedAmount &&
                    <li className=' lato-regular fx-16 d-flex align-items-center justify-content-between gap-12'>Discount <span> - ₹ {breakupInfo?.breakup?.couponDiscountedAmount || 0}</span></li>
                  }
                  <li className=' lato-bold fx-16 d-flex align-items-center justify-content-between gap-12'>Total <span> ₹{breakupInfo?.breakup?.finalAmount || "0"}</span></li>
                </ul>

                <div className="proceed-to-pay-btn">
                  <Button disabled={!isFormValid} className='primary-btn d-flex align-items-center justify-content-center gap-12' onClick={location.state?.detail?.eventType === PAID ? paymentHandler : proceedToPayhandler}> {isUpdating ? <> <Spinner /> Loading...  </> : location.state?.detail?.eventType === PAID ? "Proceed to Pay" : "Register"}</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="event-conform-payment-page">
          <div className="container">
            <div className="conformation-paid-wrapper">

              <h3 className=' lato-bold fs-18-16 black-242 pointer' onClick={() => history.push({ pathname: `/${EVENTS}`, search: `pageNumber=1&pageSize=10` })}><Image src={backIcon} alt="backIcon" className=' pointer' onClick={() => history.push({ pathname: `/${EVENTS}`, search: `pageNumber=1&pageSize=10` })} /> Back to event</h3>
              <h2 className=' lato-bold fs-32-24 black-242'>Congratulations! You're all booked for the event.</h2>
              <div className="event-litel-info">
                <div className="left-booked-card">
                  <img src={registerEventDetails?.thumbnailPath || vidoImg} alt="event-thembnail" />
                  <span className=' lato-semibold fx-12 black-242 booked '>Booked</span>
                </div>
                <div className="event-content-box">
                  <h6 className=' lato-bold fs-18-16 black-242 op-8'> {moment(registerEventDetails?.startDate).format('lll')}</h6>
                  <h3 className='lato-regular fs-24-18 black-242'>{registerEventDetails?.name || "Na"} </h3>

                  <AddToCalendar
                    event={{
                      name: registerEventDetails?.name || "Happy Hour",
                      details: registerEventDetails?.description || "Let's go after work",
                      location: registerEventDetails?.city || "Boston, MA",
                      startsAt: registerEventDetails?.startDate || "2024-02-17T04:00:00.000Z",
                      endsAt: registerEventDetails?.endDate || "2025-12-06T18:00:00-05:00"
                    }}
                  />

                  <RWebShare
                    data={{
                      text: "",
                      url: `${SHARE_BASE_URL + "events-details/" + registerEventDetails?.uuid}`,
                      title: registerEventDetails?.name,
                    }}

                  >
                    <Button className=' secondary-btn d-block'>Share </Button>
                  </RWebShare>


                </div>
              </div>
              {IS_ONLINE?.includes(registerEventDetails?.eventFormat) &&
                <p ref={textRef} className=' lato-bold fs-18-16 black-242 link-text pointer w-100 overflow-hidden'><a rel="noreferrer" target='_blank' href={registerEventDetails?.onlineLink}>{registerEventDetails?.onlineLink || "Link Url Here"}</a>  &nbsp;&nbsp; <FaCopy className=' pointer' onClick={handleCopyClick} /></p>
              }
            </div>
          </div>
        </div>
      }

    </div>
  )
}
