import React from "react";
import { useEffect, useState } from "react";
import { NotFound } from "../../common/pages";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import defaultVideoImage from '../../../assets/images/Video-Thumbnail.jpg'
import defaultPptImage from '../../../assets/images/ppt-thumbnail.jpg'
import defaultReportImage from '../../../assets/images/report-thumbnail.jpg'
import defaultPodcastImage from '../../../assets/images/podcast-thumbnail.jpg'
import defaultMinimalistImage from '../../../assets/images/Minimalist-thumbnail.jpg'
import defaultEbookImage from '../../../assets/images/e-book-thumbnails.jpg'
import defaultEmailerImage from '../../../assets/images/emailer-thumbnails.jpg'
import defaultInfographicImage from '../../../assets/images/Infographic-thumbnail.jpg'
import defaultBlogImage from '../../../assets/images/blog-thumbnail.jpg'
import { AllShimmer, Card, CardHeader } from "../../common/components";
import { Image } from "react-bootstrap";
/* eslint-disable react-hooks/exhaustive-deps */



const imageClass = {
  VIDEOS: "video-card-height",
  INFOGRAPHICS: "infographic-image-class",
  MINIMALIST: "podcasts-img-class",
  PRESENTATION: "video-card-height",
  PODCASTS: "podcasts-img-class",
  CAROUSEL: "podcasts-img-class",
  report: "infographic-image-class",
  "e-book": "infographic-image-class",
  'E-BOOKS': "infographic-image-class",
  emailer: "infographic-image-class",
  "ARTICLES": "video-card-height",
}







const imageDefault = {
  VIDEOS: defaultVideoImage,
  INFOGRAPHICS: defaultInfographicImage,
  MINIMALIST: defaultMinimalistImage,
  PRESENTATION: defaultPptImage,
  PODCASTS: defaultPodcastImage,
  reports: defaultReportImage,
  "e-book": defaultEbookImage,
  'E-BOOKS': defaultEbookImage,
  emailers: defaultEmailerImage,
  "ARTICLES": defaultBlogImage,
}

export default function ContentTypeCards({
  title = "Videos",
  hide,
  wrapperadd = "",
  countTitle = "Videos",
  isContainer = false,
  data = [],
  count = 0,
  typeId = "",
  suggestionData = false,
  bannerAds = [],
  code,
  removeNotFound = true,
  isLoading,
  type,
  selectedCard,
  setSelectedCard,
  replacePostData,
  setReplacePostData,
  color,
  setColor,
  language,
  setLanguage,
  languageOptions,
  setLanguageOptions,
  colorOptions,
  setColorOptions,
  variation,
  setVariation,
  setCaption,
  setVariationId,
  checkbox,
  componentType,
  viewDetail

}) {
  // console.log('countTitle', countTitle?.replace('/', "_")?.toLowerCase());
  const [videoList, setVideoList] = useState(data);
  const param = useParams();
  const location = useLocation()
  const serchParams = new URLSearchParams(location?.search)
  useEffect(() => {

    setVideoList(data);
  }, [data || []]);
  // console.log("Title New common",count, countTitle, data);

  let start_index = (serchParams.get("pageNumber") - 1) * 2;
  let end_index = start_index + 2;
  return (
    <div className="video-card-wrapper mb-67">
      <div className={isContainer ? "container" : ""}>
        {/* {console.log(videoList?.length !== 0)} */}
        {videoList?.length !== 0 ? (
          <>
            <CardHeader
              title={suggestionData ? suggestionData : countTitle}
              count={count}
              hide={hide}
              countTitle={suggestionData ? suggestionData : countTitle}
              viewAll={`/content-type/${countTitle?.replace('/', "_")}`}//change
              viewDetail={viewDetail}
            />
            <div className={`common-all-card-wrapper ${wrapperadd}`}>
              {isLoading ?
                <AllShimmer type={type === 'All' ? 'commonAllCard' : 'common'} urlType={type === 'All' ? code : type} />
                :
                videoList?.map((item, index) => {
                  // console.log(imageClass,countTitle,imageClass[countTitle])
                  return (
                    <>
                      {
                        (index === 6 || index === 12) && (bannerAds[0] || bannerAds[1]) &&
                        <a
                          className="banner-main-wrapper"
                          href={
                            bannerAds[index === 6 ? 0 : 1]?.redirectionLink
                              ? bannerAds[index === 6 ? 0 : 1]?.redirectionLink.startsWith('http://') || bannerAds[index === 6 ? 0 : 1]?.redirectionLink.startsWith('https://')
                                ? bannerAds[index === 6 ? 0 : 1]?.redirectionLink
                                : `http://${bannerAds[index === 6 ? 0 : 1]?.redirectionLink}`
                              : '#'
                          }
                          target="_blank"
                          rel="noopener noreferrer" // This helps to secure the external links
                        >
                          <Image src={bannerAds[index === 6 ? 0 : 1]?.bannerImagePath} alt="banner Image" />
                        </a>

                      }
                      <Card
                        key={index}
                        contentType={countTitle}
                        defaultImage={imageDefault[countTitle?.replace('/', "_")?.toLowerCase()]}
                        imageClass={imageClass[code]}//change
                        data={item}
                        classes={wrapperadd ? `responsive-card-width` : ""}
                        selectedCard={selectedCard}
                        setSelectedCard={setSelectedCard}
                        replacePostData={replacePostData}
                        setReplacePostData={setReplacePostData}
                        color={color}
                        setColor={setColor}
                        language={language}
                        setLanguage={setLanguage}
                        languageOptions={languageOptions}
                        setLanguageOptions={setLanguageOptions}
                        colorOptions={colorOptions}
                        setColorOptions={setColorOptions}
                        variation={variation}
                        setVariation={setVariation}
                        setCaption={setCaption}
                        setVariationId={setVariationId}
                        checkbox={checkbox}



                      />
                    </>
                  );
                })}
            </div>
          </>
        ) : (<>
          {removeNotFound &&
            <>
              <CardHeader
                title={countTitle}
                count={data.length}
                hide={data.list ? false : true}
                countTitle={countTitle}
                viewAll={`/content-type/${countTitle?.toLowerCase()}`}//change
                componentType={componentType}
                viewDetail={viewDetail}
              />
              <NotFound />
            </>
          }
        </>
        )}
      </div>
    </div>
  );
}
