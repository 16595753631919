import { Image } from "react-bootstrap";
import YoutubeIcon from "../../assets/images/icon/youtube-icon-new.svg";
import { useState, useEffect } from "react";
import { SearchFilter } from "../common";
import PersonalizationCards from "./PersonalizationCards";
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { getAuthDataApi } from "../home/Api";
import { MAX_LIMIT, START_PAGE } from "../../utils/Constant";
import SearchTemplate from "./SearchTemplate";

export default function TemplatesTab() {
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [selected, setSelected] = useState("");
    const history = useHistory();
    const location = useLocation();
    const { personalizedType } = useParams(); // Extract type from route
    const [templatesData, setTemplatesData] = useState([]);
    const [filteredTemplates, setFilteredTemplates] = useState([]);

    const queryParams = new URLSearchParams(location.search);

    function setValueHandler(info) {
        setFilterData(info);
    }



    const handleNavigate = async (personalizedType) => {
        history.push(`/personalization/${personalizedType}?tab=templates`)
    };

    async function getTemplateData(category) {

        const apiUrl = category
            ? `/website/PersonalizationTemplate?pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}&category=${category}`
            : `/website/PersonalizationTemplate?pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}`;

        try {
            const res = await getAuthDataApi(apiUrl);


            if (res) {
                setTemplatesData(res);
                setFilteredTemplates(res);
            } else {
                toast.error("Failed to get data");
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    useEffect(() => {

        getTemplateData(personalizedType !== "All" ? personalizedType : null);
    }, [personalizedType]);

    return (
        <div className="templates-tab">
            <div className="template-heading d-flex flex-row align-items-center">
                <h6 className="lato-semibold fs-32-20 black-242">
                    Select a template to personalize
                </h6>
                <div className="youtube-icon d-flex justify-content-center ml-3">
                    <Image width={36} height={24} src={YoutubeIcon} alt="icon" />
                </div>
            </div>
            <SearchTemplate

                setValueHandler={setValueHandler}
                setTemplatesData={setTemplatesData}
                setFilteredTemplates={setFilteredTemplates}
                getTemplateData={getTemplateData}
                name="templates"
                placeholder=" in Templates"
                clases="template-search"
            />

            <div className="personalization-cards">
                {filteredTemplates?.map((item, index) => (
                    <div key={index} className="">
                        <div className="d-flex flex-row justify-content-between align-items-center text-center">
                            {item?.count > 0 && <span className="card-heading lato-regular fs-28-18 black-242">{item?.title}</span>}
                            {item?.list?.length > 4 && personalizedType === "All" && (
                                <span
                                    onClick={() => handleNavigate(item?.title.toLowerCase())}
                                    className="d-none d-md-flex lato-medium fx-14 black-242 pr-0 pr-md-5 pt-5 pointer"
                                >
                                    View all
                                </span>
                            )}
                            {item?.list?.length > 2 && personalizedType === "All" && (
                                <span
                                    onClick={() => handleNavigate(item?.title.toLowerCase())}
                                    className="d-flex d-md-none lato-medium fx-14 black-242 pr-0 pr-md-5 pt-5 pointer"
                                >
                                    View all
                                </span>
                            )}
                        </div>

                        <PersonalizationCards
                            data={item}
                            type={personalizedType}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}
