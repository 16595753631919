import React, { useState } from 'react'
import { Image } from "react-bootstrap";
import close from '../../assets/images/greay-cancel-icon.svg';
import searchIcon from "../../assets/images/Search_1.svg";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { AMC_CONTENT_CREATOR } from '../../utils/Constant';
export default function SearchFilter({ data = '', filterData, setValueHandler = () => { }, name, placeholder, clases, changeValue = () => { }, setSortBy }) {
  const param = useParams();

  const [keyword, setKeyword] = useState("");


  function searchAmchandler(key = "") {

    if (data) {
      const filteredData = param.type === AMC_CONTENT_CREATOR
        ? [...data.filter(item => (item?.amcName || item?.contentCreatorName || '').toLowerCase().includes((key || keyword || '').toLowerCase()))]
        : [...data.filter(item => {
          let nameKey = name + 'Name';
          if (!item?.[nameKey]) {
            nameKey = name.slice(0, -1) + 'Name';
          }

          return (item?.[nameKey] || '').toLowerCase().includes((key || keyword || '').toLowerCase());
        })];


      if (!key.trim()) {
        setValueHandler(data);
      } else {
        setValueHandler(filteredData);
      }
    } else {
      changeValue(key);
    }
  }

  function changeHandler(e) {

    setKeyword(e.target.value);
    if (!window.location.pathname.includes('/social-media') && !window.location.pathname.includes('/tools')) {
      setSortBy("Max Content");
    }

    if (data) {
      searchAmchandler(e.target.value);
    }
  }

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      searchAmchandler(keyword);
    }
  };
  return (
    <div className={`keywords-input-fields search-amc-wrapper ${clases}`}>
      <input
        type="text"
        placeholder={`Search ${placeholder}`}
        autoComplete="off"
        className="input-box-keywords"
        name="keyword"
        inputmode="search"
        value={keyword || ""}
        onChange={changeHandler}
        onKeyDown={handleKeyPress}
      />
      {keyword && <Image src={close} onClick={() => {
        if (!window.location.pathname.includes('/social-media') && !window.location.pathname.includes('/tools')) {
          setSortBy("Max Content");
        }
        setKeyword(""); setValueHandler(data); searchAmchandler('')
      }} alt="canle icon" className="cancel-field" />}
      <Image
        onClick={() => searchAmchandler(keyword)}
        src={searchIcon}
        alt="search icon"
        className="search-input-field-icon "
      />
    </div>
  )
}
