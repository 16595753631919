import React, { useEffect, useState } from "react"
import CanvasCobrand from "./CobrandCanvas"
import { minusIcon, plusIcon } from "../../../assets/images/canvas"

const VideoCobrand = ({ data, setData, selectedLanguage }) => {
  const [currentScreen, setCurrentScreen] = useState(data[0].pageNumber)

  useEffect(() => {
    setCurrentScreen(data[0].pageNumber)
  }, [selectedLanguage])

  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  return (
    <div className="video-cobrand-container" onContextMenu={handleContextMenu} >
      <VideoCobrand.Sidebar
        active={currentScreen}
        setActive={setCurrentScreen}
        data={data}
        setData={setData}
      />
      <div className="right-container">
        {data.length > 0 && data.map((item, index) => {
          if (item.pageNumber === currentScreen) {
            if (true) {
              return (
                <CanvasCobrand
                  key={item.uuid}
                  screen={item.pageNumber}
                  canvas={item}
                  selectedLanguage={selectedLanguage}
                  setCanvas={(p) => {
                    const newData = [...data]
                    newData[index] = p;
                    setData(newData)
                  }}
                />
              )
            } else {
              return item.component
            }
          }

        })}
      </div>
    </div >
  )
}

export default VideoCobrand

const Sidebar = ({ active, setActive, data, setData }) => {
  return (
    <div className="sidebar">
      <p className="fx-14 lato-regular text-center">
        Pages
      </p>
      <div className="slides">
        {data.map((item, index) => (
          <div key={item?.uuid || index}>
            <div
              className={`sidebar-item ${active === item.pageNumber ? "active" : ""}`}
              onClick={() => setActive(item.pageNumber)}
            >
              {item?.component ? (
                <div
                  className={`thumbnail ${active === item.pageNumber ? "active" : ""
                    }`}
                  onClick={() => setActive(item.pageNumber)}
                >
                  {item?.component}
                </div>
              ) : (
                <div
                  className={`thumbnail ${active === item.pageNumber ? "active" : ""
                    }`}
                  onClick={() => setActive(item.pageNumber)}
                >
                  <img src={ item?.ref?.toDataURL()} alt="" />
                </div>
              )}
              <p className="f-s-14 text-center">{item.pageNumber}</p>


            </div>
            {index < data?.length - 1 && <hr className="show-desktop" />}
          </div>
        ))}
      </div>
    </div>
  )
}

VideoCobrand.Sidebar = Sidebar