import { Image, Modal } from "react-bootstrap";
import CrossIcon from "../../assets/images/icon/cross-modal-icon.svg";
import { VideoDetailsContentType } from "../home/_utils";
import PreviewSlider from "./PreviewSlider";


export default function PreviewModal({ preview, setPreview, previewData }) {
  const handleClose = () => setPreview(false);
  const handleContextMenu = (event) => {
    event.preventDefault();
  };
  const isZipFile = (filePath) =>
    filePath?.split('.')?.pop().toLowerCase() === 'zip';
  const zipFilePath =
    previewData?.contentVariation?.contentFilePath ||
    previewData?.scheduledPostPlannerContents[0]?.cobrandFilePath;


  return (
    <Modal className='preview-modal ' centered show={preview} onHide={handleClose}>
      <Modal.Body>
        <div className="preview-modal-heading d-flex flex-column">
          <div className="cross-icon d-flex flex-row justify-content-between">
            <p className="lato-bold fs-20-16 black-242">Preview</p>
            <Image width={20} height={20} src={CrossIcon} onClick={handleClose} style={{ cursor: "pointer" }} />
          </div >
          <div className="d-flex align-items-center justify-content-center" onContextMenu={handleContextMenu}>

            {isZipFile(zipFilePath) && previewData?.contentVariation?.contentVariationimages.length > 0 ? <div>
              <PreviewSlider images={previewData?.contentVariation?.contentVariationimages} previewData={previewData} />

            </div> :

              previewData?.contentVariation?.extUrlLink || previewData?.contentVariation?.contentFilePath || (previewData?.scheduledPostPlannerContents[0]?.cobrandFilePath &&
                ['mp4', 'mkv', 'avi', 'mov', 'pdf'].includes(
                  previewData.scheduledPostPlannerContents[0].cobrandFilePath.split('.').pop()
                )) ?
                <div style={{ width: "800px" }} className="mt-4">

                  {previewData?.scheduledPostPlannerContents?.length > 1 ? (
                    <PreviewSlider images={previewData.scheduledPostPlannerContents} previewData={previewData} />
                  ) : (
                    <>
                      <VideoDetailsContentType
                        type={""}
                        extUrlLink={previewData?.contentVariation?.extUrlLink || ""}
                        contentFilePath={
                          previewData?.contentVariation?.contentFilePath ||
                          previewData?.scheduledPostPlannerContents[0]?.cobrandFilePath
                        }
                        height="600"
                      />
                      <p className="preview-text lato-medium fs-12-10 black-242 text-center">
                        {previewData?.caption}
                      </p>
                    </>
                  )}
                </div>


                :
                <div className="d-flex flex-column text-center justify-content-center align-items-center" >
                  {previewData?.content?.thumbnail ? (
                    <Image
                      className="preview-image"
                      src={previewData?.content?.thumbnail}
                      alt="image"
                    />
                  ) : previewData?.scheduledPostPlannerContents?.length > 1 ? (
                    <PreviewSlider images={previewData.scheduledPostPlannerContents} previewData={previewData} />
                  ) : previewData?.scheduledPostPlannerContents?.length === 1 ? (
                    <Image
                      className="preview-image"
                      src={previewData?.scheduledPostPlannerContents[0]?.thumbnailPath}
                      alt="image"
                    />
                  ) : (
                    <div className="card-plus">
                      <h5
                        title={previewData?.caption}
                        className="lato-bold fs-20-16 text-ellipsis-two black-242"
                      >
                        {previewData?.caption}
                      </h5>
                    </div>
                  )}
                  {previewData?.scheduledPostPlannerContents?.length == 1 &&

                    <p className="preview-text lato-medium fs-14-12 black-242 text-center">
                      {previewData?.caption}
                    </p>}
                </div>

            }

          </div>

        </div>
      </Modal.Body>
    </Modal>
  );
}