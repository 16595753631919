import React, { useEffect } from 'react'
import { Button, Image, Modal } from "react-bootstrap";

import checked from '../../assets/images/cobranv2-checked.jpg';
import Close1 from "../../assets/images/Close1.svg";
import lockIcon from "../../assets/images/lock.svg";
import { FormValidator } from "../../utils/form";
import {
  DEFAULT_IMAGE_HEIGHT,
  DEFAULT_IMAGE_WIDTH,
} from "../../utils/Constant";
import { commonAuthApis } from "../profile/Api";
import { toast } from "react-toastify";
import { useState } from "react";
import { getUser } from "../../utils/ReusableFunctions";
import { useHistory } from 'react-router-dom';


export default function Personalised({ isTextLogo = () => { } }) {
  const userProfileDetails = getUser();
  const history = useHistory()
  const [isLoggedIn, setLogin] = useState(userProfileDetails ? true : false)
  const [cobrandingLabelling, setCobrandingLabelling] = useState({ startLogo: false, startText: false })
  const { startLogo, startText } = cobrandingLabelling;
  const [privewArr, setPrivewArr] = useState(["no"])

  const handleCheckboxChange = (event, position = '') => {
    const { name, checked } = event.target;

    if (position) {
      // console.log(event.target.checked);
      // console.log(checked ? position : 'remove');
      if (checked) {
        setPrivewArr([...privewArr, position])
      }
      else {
        const filter = privewArr.filter((item) => item !== position);
        setPrivewArr(filter);
      }
    }
    setCobrandingLabelling({ ...cobrandingLabelling, [name]: checked });
  };



  //* -----------------------------------------------------------------------------------------
  // =============================== INVESTOR DETAILS STATE START ========================
  const [investor, setInvestor] = useState({
    investorName: "",
    personalizedText: "",
  });

  const { personalizedText } = investor;
  // =============================== INVESTOR DETAILS STATE END ========================
  useEffect(() => {
    isTextLogo({ cobrandingLabelling: cobrandingLabelling, investor: investor });
  }, [cobrandingLabelling, investor])
  //* -----------------------------------------------------------------------------------------
  const [cobrandcolorLanguage, setCobrandcolorLanguage] = useState({
    color: "",
    language: "",
  });
  const { color, language } = cobrandcolorLanguage;
  //* ---------------------------------------------------------------------------------------------------------------
  // TODO:------ LOGO AND CONPANY DETAILS STATE START
  const [companyLogo, setCompanyLogo] = useState({
    logoUrl: userProfileDetails?.organizationLogoPath ?? "",
    logoFile: null,
  });
  const { logoUrl, logoFile } = companyLogo;
  const [brandinText, setBrandinText] = useState({
    company: userProfileDetails?.companyName ?? "",
    arnNo: userProfileDetails?.arnNumber ?? "",
    companyEmail: userProfileDetails?.emailAddress ?? "",
    companyNo: userProfileDetails?.mobileNumber ?? "",
  });
  const { company, arnNo, companyEmail, companyNo } = brandinText;

  const [editModal, setEditModal] = useState({
    showModal: false,
    eidtText: false,
    isLogoUpdate: false,
  });
  const { showModal, eidtText, isLogoUpdate } = editModal;
  const [isLoading, setIsLoading] = useState(false);
  // TODO:------ LOGO AND CONPANY DETAILS STATE END
  function coBrandingTextHandler(e, validation) {
    let isValid = [];
    validation.map((item) => {
      if (FormValidator[item](e.target.value)) {
        isValid.push(true);
      } else {
        isValid.push(false);
      }
    });
    const check = isValid.every((item) => item === true);
    if (check) {
      setBrandinText({ ...brandinText, [e.target.name]: e.target.value });
    }
  }
  function uploadComanyLogoHandler(e) {
    const file = e.target.files[0];
    const image = new window.Image();
    image.src = URL.createObjectURL(file);
    // setNewLogo(false)
    image.onload = async function () {
      const width = image.width;
      const height = image.height;

      if (width > DEFAULT_IMAGE_WIDTH || height > DEFAULT_IMAGE_HEIGHT) {
        toast.error(
          `Image dimensions must be ${DEFAULT_IMAGE_WIDTH}x${DEFAULT_IMAGE_HEIGHT} or smaller.Uploaded image is(${width}x${height}) `
        );
      } else {
        setCompanyLogo({
          ...companyLogo,
          logoUrl: URL.createObjectURL(file),
          logoFile: file,
        });
        const orgLogo = new FormData();
        orgLogo.append("organization-logo", file);
        setEditModal({
          showModal: false,
          eidtText: false,
          isLogoUpdate: false,
        });
        const res = await commonAuthApis(
          "user/upload-organization-logo",
          orgLogo,
          "put"
        );
        // console.log(res)
        if (res.status === 200) {
          toast.success("Successfully Upldated.");
          localStorage.setItem(
            "finUserDetails",
            JSON.stringify(res?.data?.data)
          );
          // console.log(res)
        }
      }
    };
    if (isLogoUpdate) {
    }
  }
  const investorOptions = [
    "Salaried",
    "Self-Employed"
  ];
  return (
    <div>
      {/* {showSignup && <SignUp show={showSignup} handleClose={() => setSignup(!showSignup)} />} */}
      {showModal && (
        <CobrandUpdateDetailsModal
          editModal={editModal}
          setEditModal={setEditModal}
        />
      )}
      {/* =============================== PERSONILIZATION AND BRANDING BOX START ================================== */}
      <div className="tools-personalization-branding-box">
        <h3 className=" lato-semibold f-s-20 lh-28 black-242">
          <span className='op-7'> Personalization & Branding {!isLoggedIn && <Image src={lockIcon} />}</span> <span> {!isLoggedIn && <Button className='primary-btn' onClick={() => { history.push('/onboarding') }}>Login</Button>}</span>
        </h3>
        {
          isLoggedIn &&
          <>
            {/* <h4 className="lato-semibold f-s-20 lh-28 black-242 coming-soon">Coming Soon...!</h4> */}
            <div className="personalize-branding-content">
              <div className="investor-details-frame">
                <h4 className=" lato-semibold f-s-16 lh-24 black-242 op-7">
                  Investor Details
                </h4>
                <input
                  type="text"
                  onChange={(e) => {
                    setInvestor({ ...investor, investorName: e.target.value });
                  }}
                  className={`input-box lato-regular fs-14-13 black-242 br-grey2 mt-4   ${true ? "higheLight" : ""
                    }`}
                  // value={investorName}
                  name="InvestorName"
                  placeholder="Investor Name"
                  maxLength={50}
                  disabled={true ? false : true}
                />
                <input
                  type="text"
                  onChange={(e) => {
                    setInvestor({ ...investor, personalizedText: e.target.value });
                  }}
                  placeholder="Personalized text.."
                  className={`input-box lato-regular fs-14-13 black-242 br-grey2 mt-4   ${true ? "higheLight" : ""
                    }`}
                  value={personalizedText}
                />

              </div>
              <div className="add-branding-frame">
                {/* COMPANY DETAILS START */}
                <div className="company-details-wrapper">
                  <h5 className=" lato-semibold f-s-16 lh-24 black-242  d-flex align-items-baseline justify-content-between ">
                    <span className="op-7">Add Branding </span>

                  </h5>
                  <div className="uplad-data mb-wrap">
                    <input
                      type="file"
                      accept="image/*"
                      name=""
                      className=" d-none"
                      onChange={uploadComanyLogoHandler}
                      id="companyLogo"
                    />
                    {isLogoUpdate && (
                      <CobrandUpdateDetailsModal
                        editModal={editModal}
                        setEditModal={setEditModal}
                      />
                    )}
                    {!logoUrl ? (
                      <label
                        htmlFor={"companyLogo"}
                        className="upload-image-logo  "
                      >
                        <span className=" lato-medium f-s-11 mw-83 lh-16 black-50 m-0">
                          Upload your logo 450x250 px
                        </span>
                      </label>
                    ) : (
                      <div className="company-logo-main-wrapper">
                        {" "}
                        {/* AFTER IMAGE UPLOADED */}
                        <div className="company-logo-frame">
                          <img src={logoUrl} alt="" className="company-logo" />
                        </div>
                        <div className=" d-flex align-items-center gap-8 mt-3">
                          <input type="checkbox" name="startLogo" checked={startLogo} onChange={handleCheckboxChange} id="start-check-box2" className='d-none ' />
                          <label htmlFor="start-check-box2" className='cobrand-custom-checkbox mb-0 mt-1'>
                            <img src={checked} alt="" />
                          </label>
                          <p className=' lato-regular fs-14-13 black-242'>Logo</p>
                        </div>


                      </div>
                    )}
                    <div className="brand-select-text-wrapper">
                      <input
                        type="text"
                        onChange={(e) =>
                          coBrandingTextHandler(e, ["isAlphaNumeric"])
                        }
                        className={`input-box lato-regular fs-14-13 ${company ? "higheLight" : ""
                          }`}
                        value={company}
                        name="company"
                        placeholder="Company Name"
                        disabled={eidtText ? false : true}
                      />
                      <input
                        type="text"
                        onChange={(e) => coBrandingTextHandler(e, ["isNum"])}
                        className={`input-box lato-regular fs-14-13 ${arnNo ? "higheLight" : ""
                          }`}
                        value={arnNo}
                        name="arnNo"
                        placeholder="ARN Number"
                        maxLength={10}
                        disabled={eidtText ? false : true}
                      />
                      <input
                        type="text"
                        onChange={coBrandingTextHandler}
                        className={`input-box lato-regular fs-14-13 ${companyEmail ? "higheLight" : ""
                          }`}
                        value={companyEmail}
                        name="companyEmail"
                        placeholder="Email Id"
                        disabled={eidtText ? false : true}
                      />
                      <input
                        type="text"
                        onChange={(e) => coBrandingTextHandler(e, ["isNum"])}
                        className={`input-box lato-regular fs-14-13 ${companyNo ? "higheLight" : ""
                          }`}
                        value={companyNo}
                        name="companyNo"
                        placeholder="Contact Number"
                        maxLength={10}
                        disabled={eidtText ? false : true}
                      />
                      <div className=" d-flex align-items-center gap-8">
                        <input type="checkbox" name="startText" checked={startText} onChange={handleCheckboxChange} id="start-check-box1" className='d-none ' />
                        <label htmlFor="start-check-box1" className='cobrand-custom-checkbox mb-0 mt-1'>
                          <img src={checked} alt="" />
                        </label>
                        <p className=' lato-regular fs-14-13 black-242'>Text</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* COMPANY DETAILS END */}

                {/* ================ COLOR AND LANGUAGE START =============== */}
                {/* <div className="branding-color-language-frame pt-3">
                  <h5 className=" lato-regular fs-14-13 black-242 op-5"></h5>
                  <div className="dropwon-component-wrapper mb-0 justify-content-end">
                    <CustomDropDown
                      label={language || "Language"}
                      // options={uniqueLangs || []}
                      options={[
                        { languageName: "helo" },
                        { languageName: "this" },
                        { languageName: "happy" },
                        { languageName: "cliply" },
                        { languageName: "chekLanguage" },
                      ]}
                      getData={(data) => {
                        console.log(data);
                        // setCobrandcolorLanguage({ ...cobrandcolorLanguage, language: data.languageName, color: getColorOptions(variation, data.languageName)[0] || "" })
                      }}
                      id="Language"
                      // disabled={(uniqueLangs?.length <= 1)}
                      classes="light-border"
                    />
                    <CustomDropDown
                      label={color || "Color"}
                      options={[
                        { colorName: "first", colorCode: "#000" },
                        { colorName: "play", colorCode: "#270089" },
                        { colorName: "this", colorCode: "#12b372" },
                      ]}
                      // options={getColorOptions(variation, language)}
                      // getData={(data) => console.log(data)}
                      getData={(data) =>
                        setCobrandcolorLanguage({
                          ...cobrandcolorLanguage,
                          color: data,
                        })
                      }
                      id="color"
                      // disabled={getColorOptions(variation, language).length <= 1}
                      classes="light-border"
                    />
                  </div>
                </div> */}
                {/* ================ COLOR AND LANGUAGE END ================= */}
              </div>
            </div>
          </>
        }

      </div>
      {/* =============================== PERSONILIZATION AND BRANDING BOX END ================================== */}
      {/*  */}
    </div>
  )
}

function CobrandUpdateDetailsModal({ editModal, setEditModal }) {
  const { showModal, eidtText, isLogoUpdate } = editModal;
  // console.log(showModal)
  return (
    <Modal
      className="cobrand-edit-modal"
      show={showModal}
      centered
      onHide={() => setEditModal({ ...editModal, showModal: false })}
    >
      <Modal.Body>
        <div className="cobrand-edit-modal-header">
          <h3 className="f-s-20 lh-28 primary lato-bold">
            Edit company details?
          </h3>
          <Image
            onClick={() => setEditModal({ ...editModal, showModal: false })}
            src={Close1}
            alt="icon"
            className="cancle-icon pointer"
          />
        </div>
        <p className=" lato-regular f-s-14 lh-20 black-242">
          Changes will be applied to your profile page.
          <br />
          Note: You will be required to re-verify any new details entered here.
        </p>
        <p className=" lato-regular f-s-14 lh-20 black-242 my-12">
          Do you still wish to change company details?
        </p>
        {isLogoUpdate && (
          <p className="f-s-10">
            (Please upload image of {DEFAULT_IMAGE_WIDTH} X{" "}
            {DEFAULT_IMAGE_HEIGHT} dimensions for best output)
          </p>
        )}
        <div className=" d-flex align-items-center justify-content-end gap-12">
          <Button
            className="secondary-btn mw-102"
            onClick={() => setEditModal({ ...editModal, showModal: false })}
          >
            Cancel
          </Button>

          {isLogoUpdate ? (
            <label
              htmlFor={"companyLogo"}
              className="primary-btn mw-102 py-10 text-center pointer mb-0"
            >
              Yes
            </label>
          ) : (
            <Button
              className="primary-btn mw-102 py-10  "
              onClick={() =>
                setEditModal({
                  ...editModal,
                  showModal: false,
                  eidtText: true,
                  isLogoUpdate: false,
                })
              }
            >
              Yes
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}