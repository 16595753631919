import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
// import './App.css';
import routes from "./routes";
import { ToastContainer } from 'react-toastify';

// import ReactGA from 'react-ga';


function App() {
  // ReactGA.initialize('G-825ZDFFLKY');
  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, [window.location]);
  // const[isVerified,setIsVerified]=useState(false);
  return (
    <div>


      <BrowserRouter>
        <Switch>
          {
            routes.map((prop, key) => {
              return <prop.type exact path={prop.path} key={key} component={prop.component} />;
            })
          }
          {/* <Route exact path="/" component={Home} /> */}
        </Switch>
      </BrowserRouter>
      <ToastContainer />


    </div>
  );
}

export default App;
