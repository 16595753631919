import React from "react";
import { Row, Col, Image } from 'react-bootstrap';
import tab_icon from "../../../assets/images/tab_icon.svg";
import whatsapp_icon from "../../../assets/images/whatsapp-icon.svg";
import sms_icon from "../../../assets/images/sms.svg";
import email_icon from "../../../assets/images/email-icon.svg";
export default function NotificationSelection({ platformOptions, toggleChangeHandler = () => { } }) {

    const { WebPushNotification, Email, SMS, WhatsApp } = platformOptions;

    return (
        <div className='content-event-main-notification '>
            <h5 className='lato-regular fs-12-400'>
                You will receive these notifications for your preferred color content uploaded
            </h5>
            <Row className='align-items-center'>
                <Col xs="auto">
                    <h4 className='lato-regular  fs-13-400 d-inline-block'>
                        <Image
                            src={tab_icon}
                            className="icons-style "
                            alt="Tab Icon"
                        />
                        Web Push Notifications
                    </h4>
                </Col>
                <Col className='text-right'>
                    <input
                        type="checkbox"
                        name="WebPushNotification"
                        id="WebPushNotification"
                        className="d-none"
                        checked={WebPushNotification}
                        onChange={toggleChangeHandler}
                    />
                    <label htmlFor="WebPushNotification" className={`toggle-btn ${WebPushNotification ? "n-active" : ""}`}>
                        <div className="toggle-circle"></div>
                    </label>
                </Col>
            </Row>
            <h5 className='lato-regular fs-12-400'>Available only for Subscribed users</h5>
            <Row className='align-items-center'>
                <Col xs="auto">
                    <h4 className="lato-regular fs-13-400 d-inline-block">
                        <Image
                            src={email_icon}
                            className="icons-style"
                            alt="email icon"
                        />
                        Email
                    </h4>
                </Col>
                <Col className='text-right'>
                    <input
                        type="checkbox"
                        name="Email"
                        id="Email"
                        className="d-none"
                        checked={Email}
                        onChange={toggleChangeHandler}
                    />
                    <label htmlFor="Email" className={`toggle-btn ${Email ? "n-active" : ""}`}>
                        <div className="toggle-circle"></div>
                    </label>
                </Col>
            </Row>
            <Row className='align-items-center'>
                <Col xs="auto">
                    <h4 className="lato-regular fs-13-400 d-inline-block">
                        <Image
                            src={sms_icon}
                            className="icons-style"
                            alt="sms icon"
                        />
                        SMS
                    </h4>
                </Col>
                <Col className='text-right'>
                    <input
                        type="checkbox"
                        name="SMS"
                        id="SMS"
                        className="d-none"
                        checked={SMS}
                        onChange={toggleChangeHandler}
                    />
                    <label htmlFor="SMS" className={`toggle-btn ${SMS ? "n-active" : ""}`}>
                        <div className="toggle-circle"></div>
                    </label>
                </Col>
            </Row>
            <Row className='align-items-center'>
                <Col xs="auto">
                    <h4 className="lato-regular fs-13-400 d-inline-block">
                        <Image
                            src={whatsapp_icon}
                            className="icons-style"
                            alt="whatsapp icon"
                        />
                        WhatsApp
                    </h4>
                </Col>
                <Col className='text-right'>
                    <input
                        type="checkbox"
                        name="WhatsApp"
                        id="WhatsApp"
                        className="d-none"
                        checked={WhatsApp}
                        onChange={toggleChangeHandler}
                    />
                    <label htmlFor="WhatsApp" className={`toggle-btn ${WhatsApp ? "n-active" : ""}`}>
                        <div className="toggle-circle"></div>
                    </label>
                </Col>
            </Row>
        </div>
    );
}
