import React, { useEffect, useRef, useState } from 'react'
import RangeFields from './components/RangeFields'
import BarChart from './components/BarChart'
import { CheckToolsSubscription, ShowShare, ToolsScreenshotHeader, captureImageHandler, debounce, formatCurrency, toolsDisclaimer, toolsInvestorDetails } from '../../utils/ReusableFunctions';
import { useMemo } from 'react';
export default function RetirementPlanner({ calculator, textLogo = '', imageData, setImageData, isDisable, setIsDisable, setAlertData, setIsAlert, setModalData }) {

  let [monthlyExpense, setMonthlyExpense] = useState(50000);
  let [currentAge, setCurrentAge] = useState(25);
  let [retirementAge, setRetirementAge] = useState(60);
  let [lifeExpectancy, setLifeExpectancy] = useState(85);
  let [expectedInvestmentReturn, setExpectedInvestmentReturn] = useState(2);
  let [inflation, setInflation] = useState(5);
  let [sipGrowthRate, setSipGrowthRate] = useState(10);


  let [fixedSIP, setFixedSIP] = useState(0);
  let [stepUpSIP, setStepUpSIP] = useState(0);
  let [fixedSIPInvesment, setFixedSIPInvesment] = useState(0);
  let [cumulativeSIP, setCumulativeSIP] = useState(0);
  let [lumpsum, setLumpsum] = useState(0);
  let [corpus, setCorpus] = useState(0);

  useEffect(() => {

    onSubmitAction(monthlyExpense, currentAge, retirementAge, lifeExpectancy, expectedInvestmentReturn, inflation, sipGrowthRate);

  }, [monthlyExpense, currentAge, retirementAge, lifeExpectancy, expectedInvestmentReturn, inflation, sipGrowthRate])

  const handleChange = (value, name) => {
    if (name === "monthlyExpense") {
      monthlyExpense = value
    }
    if (name === "currentAge") {
      currentAge = value
    }
    if (name === "retirementAge") {
      retirementAge = value
    }
    if (name === "lifeExpectancy") {
      lifeExpectancy = value
    }
    if (name === "expectedInvestmentReturn") {
      expectedInvestmentReturn = value
    }
    if (name === "inflation") {
      inflation = value
    }
    if (name === "stepUp") {
      sipGrowthRate = value
    }
    onSubmitAction(monthlyExpense, currentAge, retirementAge, lifeExpectancy, expectedInvestmentReturn, inflation, sipGrowthRate);
  }

  function onSubmitAction(monthlyExpense, currentAge, retirementAge, lifeExpectancy, expectedInvestmentReturn, inflation, sipGrowthRate) {

    let roi = expectedInvestmentReturn / 100;
    let yearsForRetirement = retirementAge - currentAge;
    let yearsInRetirement = lifeExpectancy - retirementAge;
    let tenure = yearsForRetirement * 12;
    let netReturns = ((roi + 1) / (1 + (inflation / 100))) - 1;
    let annualExpense = monthlyExpense * 12;
    let firstYearExpense = annualExpense / (1 / (1 + (inflation / 100)) ** yearsForRetirement);
    let roiConst = (expectedInvestmentReturn / 100) / 12;
    let corpus = ((firstYearExpense * ((((1 + netReturns) ** yearsInRetirement) - 1) / netReturns) * (1 + netReturns)) / ((1 + netReturns) ** yearsInRetirement))
    let fixedSIP = (corpus / (((((roiConst) + 1) ** tenure) - 1) / roiConst)) / (roiConst + 1);
    let lumpsum = (1 / ((roi + 1) ** yearsForRetirement)) * corpus;
    let fixedSIPInvesment = tenure * fixedSIP;

    setMonthlyExpense(monthlyExpense)
    setCurrentAge(currentAge);
    setRetirementAge(retirementAge);
    setLifeExpectancy(lifeExpectancy);
    setExpectedInvestmentReturn(expectedInvestmentReturn);
    setInflation(inflation);
    setSipGrowthRate(sipGrowthRate);

    setFixedSIP(Math.round(fixedSIP || 0));
    setStepUpSIP(Math.round(stepUpSIP || 0));

    setFixedSIPInvesment(Math.round(fixedSIPInvesment || 0));
    setCumulativeSIP(Math.round(cumulativeSIP || 0));
    setLumpsum(Math.round(lumpsum || 0));
    setCorpus(Math.round(corpus || 0));

    fixedSIP = Math.round(fixedSIP);
    stepUpSIP = Math.round(stepUpSIP);
    fixedSIPInvesment = Math.round(fixedSIPInvesment);
    cumulativeSIP = Math.round(cumulativeSIP);
    lumpsum = Math.round(lumpsum);
    corpus = Math.round(corpus);


    let corpus1 = Math.round(corpus);
    let init_pmt = -10000;
    let rate_of_return = expectedInvestmentReturn; // in percentage
    let total_period = yearsForRetirement; //years
    let growth_rate = sipGrowthRate; // in percentage
    let goal = corpus1;

    const fnParams = [init_pmt, rate_of_return / 100, total_period * 12, growth_rate / 100];

    try {
      const result = goalSeek({
        fn,
        fnParams,
        percentTolerance: 0.1,
        maxIterations: 100,
        maxStep: 0.1,
        goal: goal,
        independentVariableIdx: 0
      });

      let result1 = Math.ceil(Math.abs(result));
      stepUpSIP = Math.round(result1);
      setStepUpSIP(stepUpSIP || 0)

      let totalCorpus = totalSIP(result1, total_period, growth_rate);
      setCumulativeSIP(Math.round(totalCorpus || 0))

    } catch (e) {
      // console.log('error', e);
    }
  };

  const goalSeek = ({ fn, fnParams, percentTolerance, customToleranceFn, maxIterations, maxStep, goal, independentVariableIdx, }) => {
    if (typeof customToleranceFn !== 'function') {
      if (!percentTolerance) {
        throw Error('invalid inputs');
      }
    }
    let g;
    let y;
    let y1;
    let oldGuess;
    let newGuess;
    let res;
    const absoluteTolerance = ((percentTolerance || 0) / 100) * goal;
    // iterate through the guesses
    for (let i = 0; i < maxIterations; i++) {
      // define the root of the function as the error
      res = fn.apply(null, fnParams);
      res = parseFloat(res);

      y = res - goal;

      if (isNaN(y))
        throw TypeError('resulted in NaN 1');

      // was our initial guess a good one?
      if (typeof customToleranceFn !== 'function') {
        if (Math.abs(y) <= Math.abs(absoluteTolerance))
          return fnParams[independentVariableIdx];
      }
      else {
        if (customToleranceFn(res))
          return fnParams[independentVariableIdx];
      }
      // set the new guess, correcting for maxStep
      oldGuess = fnParams[independentVariableIdx];
      newGuess = oldGuess + y;
      // if (Math.abs(newGuess - oldGuess) > maxStep) {
      //     if (newGuess > oldGuess) {
      //         newGuess = oldGuess + maxStep;
      //     }
      //     else {
      //         newGuess = oldGuess - maxStep;
      //     }
      // }
      fnParams[independentVariableIdx] = newGuess;
      // re-run the fn with the new guess
      y1 = parseInt(fn.apply(null, fnParams)) - goal;


      if (isNaN(y1))
        throw TypeError('resulted in NaN 2');
      // calculate the error
      g = (y1 - y) / y;
      if (g === 0)
        g = 0.0001;
      // set the new guess based on the error, correcting for maxStep
      newGuess = oldGuess - y / g;

      fnParams[independentVariableIdx] = newGuess;
    }
    // done with iterations, and we failed to converge
    throw Error('failed to converge');
  };


  const fn = function calculate(pmt, rate, tper, growth) {
    let years;
    let cum_returns = 0;
    let growth_calc;
    let i;
    let this_pmt;
    let this_nper;
    let fv;


    growth_calc = 1 + growth;
    years = tper / 12;
    for (i = 1; i <= years; i++) {
      if (i == 1) {
        this_pmt = pmt;
      } else {
        this_pmt = pmt * ((growth_calc ** (i - 1)) - (growth_calc ** (i - 2)));
      }
      this_nper = tper - ((i - 1) * 12);

      fv = parseFloat(FV(rate, this_nper, this_pmt, 0, 1));

      cum_returns = cum_returns + fv;
    }
    return cum_returns
  }

  function FV(rate, nper, pmt, pv, type) {
    var pow = Math.pow(1 + (rate / 12), nper),
      fv;
    if (rate) {
      fv = (pmt * (1 + (rate / 12) * type) * (1 - pow) / (rate / 12)) - pv * pow;
    } else {
      fv = -1 * (pv + pmt * nper);
    }
    return fv.toFixed(2);
  }

  function totalSIP(firstValue, tenure, rate) {
    var totalValue = firstValue * 12;
    for (var i = 0; i < tenure; i++) {
      var temp = ((1 + (rate / 100)) * firstValue).toFixed(2);
      totalValue += temp * 12;
      firstValue = temp;
    }
    return Math.round(totalValue);
  }

  let downloadScreenshotFunc = null;

  const setDownloadScreenshotFunc = (func) => {
    downloadScreenshotFunc = func;
  };
  const barChart = useMemo(() => {
    return (
      <BarChart
        info={{
          labels: ["Fixed SIP", "Step Up SIP"],
          data: [fixedSIP, stepUpSIP],
        }}
      />
    );
  }, [fixedSIP, stepUpSIP]);
  const barChart2 = useMemo(() => {
    return (
      <BarChart
        info={{
          labels: ["Fixed SIP", "Step Up SIP", "Lumpsum", ["Retirement", "Corpus"]],
          data: [fixedSIPInvesment, cumulativeSIP, lumpsum, corpus],
        }}
      />
    );
  }, [fixedSIPInvesment, cumulativeSIP, lumpsum, corpus]);
  return (
    <div>
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART START ---------------------------------------------- */}
      <div className="calculator-result-main-wrapper">
        <div className="calculator-component-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Calculator
          </h4>


          <RangeFields
            label="Monthly Expense"
            name={"monthlyExpense"}
            value={monthlyExpense}
            minVal={5000}
            maxVal={1000000}
            step={1000}
            getValue={debounce(handleChange)}
            showComma />
          <RangeFields
            label="Current Age"
            name={'currentAge'}
            value={currentAge}
            minVal={18}
            maxVal={60}
            unit="Year"
            step={1}
            getValue={debounce(handleChange)}
          />
          <RangeFields
            label="Retirement Age"
            name={"retirementAge"}
            value={retirementAge}
            minVal={30}
            maxVal={70}
            unit="Year"
            step={1}
            getValue={debounce(handleChange)}
          />
          <RangeFields
            label="Retirement Corpus to last till the Age of"
            name={"lifeExpectancy"}
            value={lifeExpectancy}
            minVal={31}
            maxVal={100}
            unit="Year"
            step={1}
            getValue={debounce(handleChange)}
          />
          <RangeFields
            label="Expected Investment Returns"
            name={"expectedInvestmentReturn"}
            value={expectedInvestmentReturn}
            minVal={1}
            maxVal={50}
            unit="%"
            step={1}
            getValue={debounce(handleChange)}
          />
          <RangeFields
            label="Rate of Inflation"
            name={"inflation"}
            value={inflation}
            minVal={1}
            maxVal={25}
            unit="%"
            step={1}
            getValue={debounce(handleChange)}
          />
          <RangeFields
            label="SIP Step Up"
            name={"stepUp"}
            value={sipGrowthRate}
            minVal={1}
            maxVal={25}
            unit="%"
            step={1}
            getValue={debounce(handleChange)}
          />

        </div>
        <div className="result-graph-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Results{" "}


            {<ShowShare calculator isDisable={isDisable} setIsDisable={setIsDisable} clickHandler={() => { CheckToolsSubscription(textLogo, downloadScreenshotFunc, calculator?.uuid, imageData, setIsDisable, setAlertData, setIsAlert, setModalData) }} />}

          </h4>
          <div className="result-graph-wrapper">
            <div className={`graph-1-frame flex-column ${false ? "w-auto" : "w-50"}`}>
              <p className='lato-semibold  f-s-12 lh-14 primary text-center pt-2'>Monthly SIP Amount</p>

              {barChart}
            </div>
            <div className={`graph-1-frame flex-column ${false ? "w-auto" : "w-100"}`}>
              <p className='lato-semibold  f-s-12 lh-14 primary text-center pt-2'>Total Investment to create a corpus</p>

              {barChart2}
            </div>
          </div>

          {retirementPlannerText(fixedSIP, currentAge, retirementAge, corpus, sipGrowthRate, lumpsum, expectedInvestmentReturn)}
        </div>
      </div>
      <div className="make-none">
        <CalculatorOutputScreenShot
          textLogo={textLogo}
          fixedSIP={fixedSIP}
          sipGrowthRate={sipGrowthRate}
          stepUpSIP={stepUpSIP}
          fixedSIPInvesment={fixedSIPInvesment}
          cumulativeSIP={cumulativeSIP}
          lumpsum={lumpsum}
          corpus={corpus}
          currentAge={currentAge}
          retirementAge={retirementAge}
          stepUp={sipGrowthRate}
          expectedInvestmentReturn={expectedInvestmentReturn}
          onDownloadScreenshot={setDownloadScreenshotFunc}
          setImageData={setImageData} />
      </div>
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART END ---------------------------------------------- */}
    </div>
  )
}



function CalculatorOutputScreenShot({ textLogo, fixedSIP, sipGrowthRate, stepUpSIP, fixedSIPInvesment, lumpsum, cumulativeSIP, corpus, expectedInvestmentReturn, stepUp, retirementAge, currentAge, onDownloadScreenshot, setImageData, monthlyExpense, lifeExpectancy, inflation }) {
  const { cobrandingLabelling, investor } = textLogo;
  const [signup, setSignup] = useState(false);
  const screenshotRef = useRef(null);

  useEffect(() => {
    if (onDownloadScreenshot) {

      captureImageHandler(onDownloadScreenshot, screenshotRef, setSignup, setImageData);
    }
  }, [textLogo, screenshotRef, monthlyExpense, currentAge, retirementAge, lifeExpectancy, expectedInvestmentReturn, fixedSIP, corpus, stepUpSIP, cumulativeSIP, lumpsum, inflation, stepUp]);
  return (
    <>

      {/* {signup && <SignUp show={signup} handleClose={() => setSignup(!signup)} />} */}

      <div ref={screenshotRef} className="tools-calulator-output-screenshot-frame ">
        {ToolsScreenshotHeader(cobrandingLabelling)}

        <div className="result-graph-wrapper">
          <div className={`graph-1-frame ${false ? "w-auto" : "w-50"}`}>
            <BarChart
              info={{
                labels: ["Fixed SIP", "Step Up SIP"],
                data: [fixedSIP, stepUpSIP],
              }}
            />

          </div>
          <div className={`graph-1-frame ${false ? "w-auto" : "w-100"}`}>
            <BarChart
              info={{
                labels: ["Fixed SIP", "Step Up SIP", "Lumpsum", ["Retirement", "Corpus"]],
                data: [fixedSIPInvesment, cumulativeSIP, lumpsum, corpus],
              }}
            />

          </div>
        </div>
        {toolsInvestorDetails(investor)}
        {retirementPlannerText(fixedSIP, currentAge, retirementAge, corpus, sipGrowthRate, lumpsum, expectedInvestmentReturn)}
        {toolsDisclaimer()}
      </div>

    </>
  )
};

function retirementPlannerText(fixedSIP, currentAge, retirementAge, corpus, sipGrowthRate, lumpsum, expectedInvestmentReturn) {
  return (
    <div className='d-flex w-100'>
      <p className=" f-s-16 lh-24 black-242 op-7 pt-2 text-center">
        If you start a monthly SIP of <span className=" lato-semibold primary">₹{formatCurrency(fixedSIP)}</span> at the age of <span className=" lato-semibold primary">{currentAge}</span> and plan to retire by the age of <span className=" lato-semibold primary">{retirementAge}</span> you will create a retirement corpus of <span className=" lato-semibold primary">₹{formatCurrency(corpus)}</span>. And if you step up the monthly SIP amount annually at a rate of <span className=" lato-semibold primary">{sipGrowthRate}%</span> then you will have a retirement corpus of <span className=" lato-semibold primary">₹{formatCurrency(lumpsum)}</span>, if the invested corpus keeps growing at <span className=" lato-semibold primary">{expectedInvestmentReturn}%</span> for the investment tenure.
      </p>
    </div>

  )
}