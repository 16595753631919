import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { BreadCrumb } from "../home/_utils";
import { Header } from "../common/pages";
import { Image, Spinner } from "react-bootstrap";
import { CustomDropDown } from "../../utils/form";
import CobrandContent from "../common/canvas/CobrandContent";
import { useEffect, useState } from "react";
import { commonAuthPostApi, getAuthDataApi } from "../home/Api";
import { fabric } from "fabric"
import { toast } from "react-toastify";
import { AllShimmer, SignUp } from "../common/components";
import { EVENTS } from "../../utils/Constant";
import { DetailsEditor, DetailsSkeleton } from "../common/components/SkeletonCard";
import { getToken } from "../../utils/ManageToken";

export default function PersonalizationEditor() {
  const location = useLocation();
  const data = location.state?.data;
  const { personalizedType } = useParams();
  const params = new URLSearchParams(location.search);
  const [templatesDetails, setTemplatesDetails] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [languageOption, setLanguageOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingTemplate, setLoadingTemplate] = useState(false);
  const [signup, setSignup] = useState(false);



  const breadCrumbHandler = () => {
    const paramsSearch = new URLSearchParams(location.search);

    let breadCrumbPath = ["Personalization", personalizedType, paramsSearch.get("name")];

    return breadCrumbPath;
  };
  const { id } = useParams(); // Extract type from route

  const [contentPages, setContentPages] = useState(
    //   [
    //   {
    //     width: 500,
    //     height: 300,
    //     backgroundColor: "#ffffff",
    //     ref: null,
    //     name: "Page 1",
    //     thumbnail: null,
    //     duration: 0,
    //     editable: true
    //   },

    // ]
  );

  const generateImagesAndUpload = async () => {
    const token = getToken();
    if (token) {
      setLoading(true);

      const formData = new FormData();
      for (const [index, page] of contentPages.entries()) {

        if (page.ref) {
          try {

            const highResImage = page.ref.toDataURL({ format: 'png', multiplier: 3 });


            const response = await fetch(highResImage);
            const blob = await response.blob();

            const file = new File([blob], `image_${index + 1}.png`, { type: 'image/png' });

            formData.append('file', file);
          } catch (error) {
            setLoading(false);

            console.error(`Error generating image for page ${index + 1}:`, error);
          }
        }
      }


      const payload = {
        data: {
          personalizationTemplateUuid: id,
        },
      };

      formData.append("key", JSON.stringify(payload));

      try {
        const res = await commonAuthPostApi(`website/personalizationTemplate/cobrand`, formData);

        if (res) {
          const downloadUrl = res?.data;
          const response = await fetch(downloadUrl);
          const blob = await response.blob();
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = downloadUrl.split("/").pop();
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(link.href);
          toast.success("Downloaded Successfully");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error making API call:", error);
        setLoading(false);
      }
    } else {
      setSignup(true)
    }
  };

  async function getTemplateDetails() {
    setLoadingTemplate(true)
    try {
      const res = await getAuthDataApi(`website/personalizationTemplate/${id}`);
      if (res) {
        setTemplatesDetails(res);

        const languageOption = res?.personalizationTemplateVariations?.map(
          (item) => item?.language
        ) || [];
        setLanguageOption(languageOption);

        const pages = res?.personalizationTemplateVariations[0]?.personalizationTemplateVariationImages || [];
        const initializedPages = initializeContentPages(pages); // Use the separate function
        setContentPages(initializedPages);
        setLoadingTemplate(false);
      } else {
        toast.error("Failed to get data");
        setLoadingTemplate(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoadingTemplate(false);

    }
  }

  // Separate function to initialize content pages
  function initializeContentPages(pages) {
    return pages.map((item) => {

      // Attach the canvas reference to the item
      return {
        ...item,
        ref: null,
        backgroundColor: item.backgroundColor || "#ffffff",
        editable: true,
      };
    });
  }



  useEffect(() => {
    getTemplateDetails();

  }, []);




  const label =
    languageOption?.length > 0
      ? ` ${languageOption[0].languageName}`
      : "Select Language";

  const languageSelectHandler = (data) => {
    setSelectedLanguage(data);
    console.log("Selected Language:", data);
    const matchedTemplate = templatesDetails?.personalizationTemplateVariations?.find(
      (item) => item?.language?.uuid === data.uuid
    );
    if (matchedTemplate) {

      const pages = matchedTemplate.personalizationTemplateVariationImages || [];
      const initializedPages = initializeContentPages(pages);
      setContentPages(initializedPages);
    } else {
      console.warn("No matching template found for the selected language");
    }
  };


  return (
    <div className=" page-padding personalization-editor">
      <Header />
      {loadingTemplate ? (
        <DetailsEditor />

      ) :
        <>
          <div className="container">
            <BreadCrumb
              path={breadCrumbHandler()}
              clasese=""

            />
            <div className="editor-details">
              <p className="lato-regular fs-24-18 black-242 mb-4 ">Live Template Editor</p>

              <div className="d-flex flex-md-row flex-column justify-content-between ">
                <div className="d-flex flex-row text-details mb-4 mb-md-0">
                  <Image className="template-image mr-3" width={37} height={37} src={templatesDetails?.thumbnailImage} alt="img" />
                  <div className="d-flex flex-column">
                    <span className="lato-medium fx-14 black-242 mb-1">  {templatesDetails?.templateName}</span>
                    <div>
                      <span className="editor-tags lato-medium fx-12 black-4 mr-1">{templatesDetails?.personalizationCategory?.categoryName?.toUpperCase()}</span>
                      {/* <span className="editor-tags lato-medium fx-12 black-4 ">Image</span> */}
                    </div>
                  </div>

                </div>
                <div className="d-flex flex-row align-items-center buttons-container">
                  <CustomDropDown
                    label={label}
                    options={languageOption}
                    getData={languageSelectHandler}
                    id="Language"
                    disabled={languageOption?.length <= 1}
                  />
                  <button disabled={loading} onClick={() => { generateImagesAndUpload() }} className="lato-regular fx-14 white primary-btn d-flex text-center align-items-center ml-md-3 ml-4">{loading ? <span className=' d-flex align-items-center gap-12'><Spinner /> Downloading..</span> : 'Download'}</button>

                </div>

              </div>

            </div>



          </div>
          {
            contentPages &&
            <div className="canvas-container container-mb">

              <CobrandContent key={selectedLanguage} data={contentPages} setData={setContentPages} selectedLanguage={selectedLanguage} />

            </div>
          }
        </>
      }
      {signup && <SignUp show={signup} handleClose={() => setSignup(!signup)} />}

    </div>
  )
}