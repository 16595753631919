
import { toast } from "react-toastify";
import { postLoginInstance } from "../../utils";
export const getCommonApi = async (url) => {
  try {
    const response = await postLoginInstance.get(url);
   
    return response;
  } catch (error) {
    toast.error(error.response.data.message || "Something went wrong")
    return error;
  }
};