import React from 'react';
import { useState } from 'react';
import { BreadCrumb } from './_utils';
import { Header } from '../common/pages';
import { downloadFromS3, getUser } from '../../utils/ReusableFunctions';
import { CustomDropDown } from '../../utils/form';
import editPencle from '../../assets/images/edit-icon.png';
import checked from '../../assets/images/cobranv2-checked.jpg';
import demoImg from '../../assets/images/Video-Thumbnail.jpg';
import downloadIcon from "../../assets/images/download-white-icon.svg";

import downloadWhiteIcon from "../../assets/images/download-white.svg";
import Close1 from "../../assets/images/Close1.svg";

import { Button, Image, Modal } from 'react-bootstrap';
import { DEFAULT_IMAGE_HEIGHT, DEFAULT_IMAGE_WIDTH, ENDVIDEO, FULLVIDEO, STARTVIDEO, positionNames } from '../../utils/Constant';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { downloadCobrandingApi, getDetailsApi } from './Api';
import { Spinner } from '../common/components';
import { commonAuthApis } from '../profile/Api';

// import useDownloader from 'react-use-downloader';
/* eslint-disable no-mixed-operators */



const selectPositionObj = {
  'Top Left': { top: "1rem", left: "1rem" },
  'Top Right': { top: "1rem", right: "1rem" },
  'Top Center': { top: '1rem', left: '50%', transform: 'translateX(-50%)' },
  'Bottom Left': { bottom: "1rem", left: "1rem", top: "unset", right: "unset" },
  'Bottom Right': { bottom: "1rem", right: "1rem", top: "unset", left: "unset" },
  'Bottom Center': { bottom: '1rem', left: '50%', transform: 'translateX(-50%)' },
  'Center Left': { bottom: "unset", left: "1rem", top: '50%', transform: 'translateY(-50%)', right: "unset" },
  'Center Center': { bottom: "unset", left: "50%", top: '50%', transform: 'translate(-50%,-50%)', right: "unset" },
  'Center Right': { bottom: "unset", left: "unset", top: '50%', transform: 'translateY(-50%)', right: "1rem" },
  'Logo top, Text Bottom': { flexDirection: 'column', justifyContent: 'space-between', width: "100%", height: "100%", padding: '1rem 0' },
  'Text top, Logo Bottom': { flexDirection: 'column-reverse', justifyContent: 'space-between', width: "100%", height: "100%", padding: '1rem 0' },
  // "Video Start": { display: "none" },
  // "Video End": { display: "none" },
}
function getColorOptions(variation, language) {
  return (
    variation
      ?.filter((item) => item.language.languageName === language)
      .map((item) => item.color) || []
  );
}
const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg']; // Add more extensions if needed
const docExtensions = ['.pdf', '.doc', '.docx', '.ppt', '.pptx'];

const isFile = (filePath, file) => {
  const extension = filePath ? filePath.toLowerCase().slice(filePath.lastIndexOf('.')) : '';
  switch (file) {
    case 'img':
      return imageExtensions.includes(extension)
    case 'doc':
      return docExtensions.includes(extension)
    default:
      return false;

  }
  return imageExtensions.includes(extension);
};
export default function CoBrandingV2() {
  const location = useLocation();
  const history = useHistory();

  const { data, variation, languageColor, updatedThings } = location.state;


  const uniqueLangs = variation.reduce((unique, item) => {
    if (!unique.some(lang => lang.languageName === item.language.languageName)) {
      unique.push(item.language);
    }
    return unique;
  }, []);

  const displayPositionArray = data?.displayPosition?.map(item => item?.code);
  const checkCorner = data?.displayPosition?.filter((item, i) => item.code?.includes("-CORNER"));
  const userProfileDetails = getUser();

  //* ---------------------------------------------------------------------------------------------------------------


  //* ---------------------------------------------------------------------------------------------------------------
  // TODO:------ LOGO AND CONPANY DETAILS STATE START
  const [isLogoUploaded, setIsLogoUploaded] = useState(false);
  const [companyLogo, setCompanyLogo] = useState({ logoUrl: userProfileDetails?.organizationLogoPath ?? "", logoFile: null })
  const { logoUrl, logoFile } = companyLogo;
  const [brandinText, setBrandinText] = useState({ company: userProfileDetails?.companyName ?? "", arnNo: userProfileDetails?.arnNumber ?? "", companyEmail: userProfileDetails?.emailAddress ?? "", companyNo: userProfileDetails?.mobileNumber ?? "" });
  const { company, arnNo, companyEmail, companyNo } = brandinText;
  // TODO:------ LOGO AND CONPANY DETAILS STATE END
  //* -----------------------------------------------------------------------------------------
  const [cobrandcolorLanguage, setCobrandcolorLanguage] = useState({ color: languageColor?.color, language: languageColor?.language });
  const { color, language } = cobrandcolorLanguage;
  const [cobrandingLabelling, setCobrandingLabelling] = useState({ labelStart: false, startText: false, startLogo: false, lableEnd: false, endText: false, endLogo: false, labelFull: false, fullText: false, fullLogo: false, positionId: '', textPosition: '', logoPosition: '', personalization: false, personalizationText: '' })
  const { labelStart, lableEnd, labelFull, startText, startLogo, endText, endLogo, fullText, fullLogo, textPosition, logoPosition, personalization, personalizationText } = cobrandingLabelling;
  //* -----------------------------------------------------------------------------------------
  //TODO:---- PRIVE STATE START
  const [privewArr, setPrivewArr] = useState([""])
  const [currentIndex, setCurrentIndex] = useState(1);
  //TODO:---- PRIVE STATE END
  const [editModal, setEditModal] = useState({ showModal: false, eidtText: false, isLogoUpdate: false });
  const { showModal, eidtText, isLogoUpdate } = editModal;
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCompanyDetails, setIsLoadingCompanyDetails] = useState(false);
  const [isSaved, setIsSaved] = useState();
  const [isLiked, setIsLiked] = useState();
  const [isDownload, setIsDownload] = useState();
  const [isView, setIsView] = useState();
  const [likeCount, setLikeCount] = useState();
  const [shareCount, setShareCount] = useState();
  const [downloadCount, setDownloadCount] = useState();
  const [saveCount, setSaveCount] = useState();
  const [viewCount, setViewCount] = useState();

  const [isEditButton, setIsEditButton] = useState(true);



  function coBrandingTextHandler(e, validation) {
    setBrandinText({ ...brandinText, [e.target.name]: e.target.value });
    // let isValid = [];
    // validation.map((item) => {
    //   if (FormValidator[item](e.target.value)) {
    //     isValid.push(true)
    //   } else {
    //     isValid.push(false)
    //   }
    // })
    // const check = isValid.every((item) => item === true);
    // if (check) {
    //   setBrandinText({ ...brandinText, [e.target.name]: e.target.value });
    // }
  }
  async function getStats() {
    // const id = this.state.contentType?.split("&id=")[1];


    const stats = await getDetailsApi(`website/content/stats/${data?.uuid}?variationId=${data?.defaultVariation?.id}`);

    setIsLiked(stats.data.data?.userStats?.isLiked);
    setIsSaved(stats.data.data?.userStats?.isSaved);
    setIsView(stats.data.data?.userStats?.isViewed);
    setIsDownload(stats.data.data?.userStats?.isDownloaded);
    setLikeCount(stats?.data?.data?.likeCount);
    setShareCount(stats?.data?.data?.shareCount);
    setDownloadCount(stats?.data?.data?.downloadCount);
    setSaveCount(stats?.data?.data?.savedCount);
    setViewCount(stats?.data?.data?.viewCount);



  }

  function uploadComanyLogoHandler(e) {
    setIsLogoUploaded(true)
    const file = e?.target?.files[0];
    const image = new window.Image();
    image.src = URL?.createObjectURL(file);
    // setNewLogo(false)
    image.onload = async function () {
      const width = image.width;
      const height = image.height;

      // if (width > DEFAULT_IMAGE_WIDTH || height > DEFAULT_IMAGE_HEIGHT) {
      if (false) {
        toast.error(`Image dimensions must be ${DEFAULT_IMAGE_WIDTH}x${DEFAULT_IMAGE_HEIGHT} or smaller.Uploaded image is(${width}x${height}) `);
      } else {
        // toast.warn("logi must be")
        setCompanyLogo({ ...companyLogo, logoUrl: URL.createObjectURL(file), logoFile: file });
        const orgLogo = new FormData();
        orgLogo.append("organization-logo", file);
        setEditModal({ showModal: false, eidtText: false, isLogoUpdate: false })
        const res = await commonAuthApis("user/upload-organization-logo", orgLogo, "put");
        // console.log(res)
        if (res.status === 200) {
          setIsLogoUploaded(false)
          toast.success("Successfully Upldated.")
          localStorage.setItem('finUserDetails', JSON.stringify(res?.data?.data));
          // console.log(res)

        }

      }
    };
    if (isLogoUpdate) {

    }

  }

  const handleCheckboxChange = (event, position = '') => {
    const { name, checked } = event.target;

    if (position) {
      if (checked) {
        setPrivewArr([...privewArr, position])
      }
      else {
        const filter = privewArr.filter((item) => item !== position);
        setPrivewArr(filter);
      }
    }

    if (position === "Start Position") {
      cobrandingLabelling.labelStart = false
      cobrandingLabelling.startText = false
      cobrandingLabelling.startLogo = false
    } else if (position === "End Position") {
      cobrandingLabelling.lableEnd = false
      cobrandingLabelling.endText = false
      cobrandingLabelling.endLogo = false
    } else if (position === "Full") {
      cobrandingLabelling.labelFull = false
      cobrandingLabelling.fullText = false
      cobrandingLabelling.fullLogo = false
      cobrandingLabelling.textPosition = ""
      cobrandingLabelling.logoPosition = ""
    } else if (position === "Personalization") {
      cobrandingLabelling.personalizationText = ""
    }
    setCobrandingLabelling({ ...cobrandingLabelling, [name]: checked });
  };

  // * ---------------------- PRIVE SLIDER FUNCTIONS AND METHODS START -------------------------
  const showNextItem = () => {
    if (currentIndex === privewArr.length - 1) {
      setCurrentIndex(1);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const showPreviousItem = () => {
    if (currentIndex === 1) {
      setCurrentIndex(privewArr.length - 1);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const privewComponent = {
    "Start Position": <StartLabeling companyLogo={startLogo ? companyLogo : ''} brandinText={startText ? brandinText : ''} />,
    "End Position": <StartLabeling companyLogo={endLogo ? companyLogo : ''} brandinText={endText ? brandinText : ''} />,
    "Full": <FulltLabeling companyLogo={logoPosition ? companyLogo : ''} brandinText={textPosition ? brandinText : ''} setPosition={{ "text": textPosition, "logo": logoPosition }} />,
    "Personalization": <div className=' lato-bold f-s-20 lh-24 primary text-center w-100 h-100 d-flex align-items-center justify-content-center p-2 overflow-hidden'>{personalizationText || 'Your message will be shown here'}</div>
  }
  // * ---------------------- PRIVE SLIDER FUNCTIONS AND METHODS END ---------------------------
  //
  //
  // * ____________________________________ COBRAND POST HANDLER START _________________________________
  const cobrandPostHander = async () => {
    setIsLoading(true);
    const PostData = {
      "data": {
        "content": {
          "uuid": data.uuid
        },
        "language": {
          "uuid": (variation.find(item => item.language?.languageName === language)?.language?.uuid) || data.defaultLanguage.uuid
        },
        "color": {
          "uuid": color.uuid
        },
        ...(personalization && { "personalizedMessage": personalizationText }),
        "cobrandPositions": {
          ...(labelStart && {
            "start": [
              ...(startText ? ["TEXT"] : []),
              ...(startLogo ? ["LOGO"] : [])
            ]
          }),
          ...(lableEnd && {
            "end": [
              ...(endText ? ["TEXT"] : []),
              ...(endLogo ? ["LOGO"] : [])
            ]
          }),
          ...(labelFull && {
            "full": {
              ...(fullText && {
                "text": [positionNames[textPosition]]
              }),
              ...(fullLogo && {
                "logo": [positionNames[logoPosition]]
              })
            }
          })
        }
      }
    };
    // sessionStorage.setItem("openSuccess" , true)
    // history.goBack();

    const downloadResponse = await downloadCobrandingApi(PostData);
    if (downloadResponse.status > 199 && downloadResponse.status < 250) {
      setIsLoading(false);
      sessionStorage.setItem("openSuccess", true)
      history.goBack();

      downloadFromS3(downloadResponse?.data?.data, getStats, setIsLoading);

    } else {
      setIsLoading(false);
    }
  }
  // * ____________________________________ COBRAND POST HANDLER END   _________________________________
  //
  //
  //
  //  * ------- CHECK CAN ENABLE DOWNLOAD OR NOT START----------------


  const isDownloadable = () => {
    let checkTrue = [
      startLogo,
      startText,
      endLogo,
      endText,
      textPosition ? true : false,
      logoPosition ? true : false,
      personalizationText ? true : false
    ];

    // If labelFull is true, ensure fullText or fullLogo is true
    if (labelFull) {
      if (!fullText && !fullLogo) {
        return false;
      }
    }

    // If fullText is true, cobrandingLabelling.textPosition must be filled
    if (fullText && !cobrandingLabelling.textPosition) {
      return false;
    }

    // If fullLogo is true, logoPosition must be filled
    if (fullLogo && !cobrandingLabelling.logoPosition) {
      return false;
    }

    // If labelStart is true, ensure one of startText or startLogo is true
    if (labelStart && !(startText || startLogo)) {
      return false;
    }

    // If labelEnd is true, ensure one of endText or endLogo is true
    if (lableEnd && !(endText || endLogo)) {
      return false;
    }

    return checkTrue.includes(true);
  };

  //  * ------- CHECK CAN ENABLE DOWNLOAD OR NOT END ----------------

  // ?================= UPDATE COBRANDING DETAILS HANDLER START =======================
  const updateDetailsHandler = async () => {

    setEditModal({ ...editModal, eidtText: false });

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(companyEmail)) {
      toast.error("Please enter a valid email address");
      return;
    }
    setIsLoadingCompanyDetails(true)
    const coDetails = {
      data: {
        ...((userProfileDetails?.companyName ?? "") === company ? {} : { companyName: company || "" }),
        ...((userProfileDetails?.arnNumber ?? "") === arnNo ? {} : { arnNumber: arnNo || "" }),
        ...((userProfileDetails?.emailAddress ?? "") === companyEmail ? {} : { unverifiedEmailAddress: companyEmail || "" }),
        ...((userProfileDetails?.mobileNumber ?? "") === companyNo ? {} : { unverifiedMobileNumber: companyNo || "" }),
      }
    }
    const res = await commonAuthApis("user/update-profile", coDetails, "put");
    // console.log(res)
    if (res.status >= 200 && res.status <= 210) {
      setEditModal({ showModal: false, eidtText: false, isLogoUpdate: false });
      setIsEditButton(true);
      toast.success("Successfully Upldated.")
      setIsLoadingCompanyDetails(false)
      localStorage.setItem('finUserDetails', JSON.stringify(res?.data?.data));
    } else {
      setEditModal({ showModal: false, eidtText: false, isLogoUpdate: false });
      setIsLoadingCompanyDetails(false)
      setIsEditButton(true);

      setBrandinText({
        company: userProfileDetails?.companyName ?? "",
        arnNo: userProfileDetails?.arnNumber ?? "",
        companyEmail: userProfileDetails?.emailAddress ?? "",
        companyNo: userProfileDetails?.mobileNumber ?? ""
      })
      // console.log("Eroer updated")
    }

  }
  function hasProfileChanged() {
    const changes = [
      { key: 'companyName', value: company },
      { key: 'arnNumber', value: arnNo },
      { key: 'emailAddress', value: companyEmail },
      { key: 'mobileNumber', value: companyNo }
    ];
    const isChange = changes.some(change => (userProfileDetails[change.key] ?? "") !== change.value);
    // console.log(isChange,"Test this is workd")
    return isChange
  }

  const cancleCompanyDetails = () => {
    setBrandinText({
      company: userProfileDetails?.companyName ?? "",
      arnNo: userProfileDetails?.arnNumber ?? "",
      companyEmail: userProfileDetails?.emailAddress ?? "",
      companyNo: userProfileDetails?.mobileNumber ?? ""
    })
    setEditModal({ ...editModal, showModal: false, eidtText: false, isLogoUpdate: false })
  }
  // ?================= UPDATE COBRANDING DETAILS HANDLER END =======================
  return (
    <section className="cobranding-main-section">
      <Header />
      {showModal && <CobrandUpdateDetailsModal editModal={editModal} setEditModal={setEditModal} />}

      {/* =========================== HERO SECTION START ============================== */}
      <div className="cobranding-hero-section">
        <div className="container">
          <BreadCrumb path={[data?.contentType?.contentTypeName, updatedThings?.tittle, "Co-branding"]} clasese="mb-3-desk" />
          <h4 onClick={isDownloadable} className="lato-regular fs-32-28 black-242 text-start">
            Co-branding
          </h4>
          <div className="cobrandingv-main-wrapper">
            {/* LEFT PART OF CODE START */}
            <div className="cobrandv-left-content mb-full">

              <div className="cobanding-sub-frame">
                {/* COMPANY DETAILS START */}
                <div className="company-details-wrapper">
                  <h5 className='lato-bold f-s-20 lh-28 black-242  d-flex align-items-baseline justify-content-between '><span className='op-7'>Company details </span>
                    <div className=" d-flex gap-12">
                      {!eidtText && isEditButton ?
                        <span onClick={() => setEditModal({ ...editModal, isLogoUpdate: false, showModal: true, eidtText: true }, setIsEditButton(false))} className=' lato-bold fx-14 primary p-3 pointer'>Edit</span>
                        :
                        <>
                          <span className=' lato-bold fx-14 p-3 pointer' onClick={() => cancleCompanyDetails()}>Cancel</span>
                          <span
                            className='lato-bold fx-14 primary p-3 pointer'
                            onClick={!isLoadingCompanyDetails
                              ? companyNo && companyNo.length !== 10
                                ? () => { toast.warn("Mobile number must be exactly 10 digits.") }
                                : hasProfileChanged()
                                  ? updateDetailsHandler
                                  : () => { toast.warn("You haven't changed any field.") }
                              : () => { }
                            }
                          >
                            {isLoadingCompanyDetails
                              ? <span className='d-flex'><Spinner clasese={'primary-spinner'} />&nbsp; Saving...</span>
                              : 'Save'}
                          </span>
                        </>
                      }
                    </div>
                  </h5>
                  <div className="uplad-data mb-wrap">
                    <input type="file" name="" className=' d-none' onChange={uploadComanyLogoHandler} id="companyLogo" accept="image/*" />
                    {isLogoUpdate && <CobrandUpdateDetailsModal editModal={editModal} setEditModal={setEditModal} />}
                    {!logoUrl ?
                      <div className="company-logo-main-wrapper pointer" onClick={() => setEditModal({ ...editModal, isLogoUpdate: true, showModal: true, eidtText: false })}>
                        <label htmlFor={''} className="upload-image-logo  ">
                          <span className=' lato-medium f-s-11 mw-83 lh-16 black-50 m-0'>Upload your logo 450x250 px</span>
                        </label>
                        <p className=' lato-medium f-s-9 lh-12 black-242 '> <img src={editPencle} className='edit-pen-cobrand-logo' alt="" /> <span className='underline'>Upload logo 450x250 px</span></p>

                      </div>
                      :
                      <div className="company-logo-main-wrapper"> {/* AFTER IMAGE UPLOADED */}
                        <div className={`company-logo-frame  ${isLogoUploaded ? 'show-loading-effect' : ''}`}>
                          <img src={logoUrl} alt="" className='company-logo' />
                        </div>
                        <label htmlFor='' className='p-0 m-0'>
                          <p className=' lato-medium f-s-9 lh-12 black-242 ' onClick={() => setEditModal({ ...editModal, isLogoUpdate: true, showModal: true, eidtText: false })}> <img src={editPencle} className='edit-pen-cobrand-logo' alt="" /> <span className='underline'>Upload new logo 450x250 px</span></p>
                        </label>
                      </div>
                    }
                    <div className="brand-select-text-wrapper">
                      <input
                        type="text"
                        onChange={(e) => coBrandingTextHandler(e, ["isAlphaNumeric"])}
                        className={`input-box lato-regular fs-14-13 ${company ? "higheLight" : ""
                          }`}
                        value={company}
                        name="company"
                        placeholder="Company Name"
                        disabled={eidtText ? false : true}
                      />
                      <input
                        type="number"
                        onChange={(e) => coBrandingTextHandler(e, ["isNum"])}
                        className={`input-box lato-regular fs-14-13 ${arnNo ? "higheLight" : ""
                          }`}
                        value={arnNo}
                        name="arnNo"
                        placeholder="ARN Number"
                        maxLength={10}
                        disabled={eidtText ? false : true}
                      />
                      <input
                        type="email"
                        onChange={(e) => coBrandingTextHandler(e, ["isEmail"])}
                        className={`input-box lato-regular fs-14-13 ${companyEmail ? "higheLight" : ""
                          }`}
                        value={companyEmail}
                        pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                        title="Please enter a valid email address"
                        name="companyEmail"
                        placeholder="Email Id"
                        disabled={eidtText ? false : true}
                      />
                      <input
                        type="number"
                        onChange={(e) => coBrandingTextHandler(e, ["isNum"])}
                        className={`input-box lato-regular fs-14-13 ${companyNo ? "higheLight" : ""
                          }`}
                        value={companyNo}
                        name="companyNo"
                        placeholder="Contact Number"
                        maxLength={10}
                        disabled={eidtText ? false : true}


                      />


                    </div>
                  </div>

                </div>
                {/* COMPANY DETAILS END */}
                {/* ------------------- */}
                {/* Co-BRANDING/WHITE-LABELING START */}
                <div className="co-branding-leabling">
                  <h5 className='lato-bold f-s-20 lh-28 black-242 op-7 mb-16'>Co-branding/White-labelling</h5>
                  <p className=' lato-regular fs-14-13 black-242 op-5'>Co-branding/White-labelling display positions.</p>
                  {!isFile(data?.defaultVariation?.contentFilePath, 'img') && <>
                    {displayPositionArray.includes(STARTVIDEO) &&
                      <div className="start-label-frame">
                        <input type="checkbox" name="labelStart" checked={labelStart} onChange={(e) => handleCheckboxChange(e, 'Start Position')} id="start-check-box" className='d-none ' />
                        <label htmlFor="start-check-box" className='cobrand-custom-checkbox mb-0 mt-1'>
                          <img src={checked} alt="" />
                        </label>
                        <div className="check-box-text-frame">
                          <p className=' lato-regular fs-14-13 black-242'>Start</p>
                          <p className=' lato-regular f-s-12 lh-16 black-242 op-5 '>Co-branding/White-labelling will be positioned in the Center before any content</p>
                          {labelStart &&

                            <div className="start-logo-frame">
                              {/* CHECK BOX WRAPPER START */}
                              <div className=" d-flex align-items-center gap-8">
                                <input type="checkbox" name="startText" checked={startText} onChange={handleCheckboxChange} id="start-check-box1" className='d-none ' />
                                <label htmlFor="start-check-box1" className='cobrand-custom-checkbox mb-0 mt-1'>
                                  <img src={checked} alt="" />
                                </label>
                                <p className=' lato-regular fs-14-13 black-242'>Business Details</p>
                              </div>
                              {/* CHECK BOX WRAPPER END */}
                              {/* CHECK BOX WRAPPER START */}
                              <div className={`d-flex align-items-center gap-8 ${!logoUrl ? 'disabled-checkBox' : ""}`}>
                                <input type="checkbox" name="startLogo" checked={startLogo} onChange={handleCheckboxChange} disabled={!logoUrl} id="start-check-box2" className='d-none ' />
                                <label htmlFor="start-check-box2" className='cobrand-custom-checkbox mb-0 mt-1'>
                                  <img src={checked} alt="" />
                                </label>
                                <p className=' lato-regular fs-14-13 black-242'>Logo</p>
                              </div>
                              {/* CHECK BOX WRAPPER END */}
                            </div>


                          }
                        </div>
                      </div>
                    }
                    {displayPositionArray.includes(ENDVIDEO) &&
                      <div className="start-label-frame">
                        <input type="checkbox" name="lableEnd" checked={lableEnd} onChange={(e) => handleCheckboxChange(e, 'End Position')} id="end-check-box" className='d-none ' />
                        <label htmlFor="end-check-box" className='cobrand-custom-checkbox mb-0 mt-1'>
                          <img src={checked} alt="" />
                        </label>
                        <div className="check-box-text-frame">
                          <p className=' lato-regular fs-14-13 black-242'>End</p>
                          <p className=' lato-regular f-s-12 lh-16 black-242 op-5 '>Co-branding/White-labelling will be positioned in the Center after any content.</p>
                          {lableEnd &&
                            <div className="start-logo-frame">
                              {/* CHECK BOX WRAPPER START */}
                              <div className=" d-flex align-items-center gap-8">
                                <input type="checkbox" name="endText" checked={endText} onChange={handleCheckboxChange} id="end-check-box1" className='d-none ' />
                                <label htmlFor="end-check-box1" className='cobrand-custom-checkbox mb-0 mt-1'>
                                  <img src={checked} alt="" />
                                </label>
                                <p className=' lato-regular fs-14-13 black-242'>Business Details</p>
                              </div>
                              {/* CHECK BOX WRAPPER END */}
                              {/* CHECK BOX WRAPPER START */}
                              <div className={`d-flex align-items-center gap-8 ${!logoUrl ? 'disabled-checkBox' : ""}`}>
                                <input type="checkbox" name="endLogo" checked={endLogo} disabled={!logoUrl} onChange={handleCheckboxChange} id="end-check-box2" className='d-none ' />
                                <label htmlFor="end-check-box2" className='cobrand-custom-checkbox mb-0 mt-1'>
                                  <img src={checked} alt="" />
                                </label>
                                <p className=' lato-regular fs-14-13 black-242'>Logo</p>
                              </div>
                              {/* CHECK BOX WRAPPER END */}
                            </div>
                          }
                        </div>
                      </div>
                    }
                  </>}
                  {!isFile(data?.defaultVariation?.contentFilePath, 'doc') && displayPositionArray.includes(FULLVIDEO) &&
                    <div className="start-label-frame">
                      <input type="checkbox" name="labelFull" checked={labelFull} onChange={(e) => handleCheckboxChange(e, 'Full')} id="full-check-box" className='d-none ' />
                      <label htmlFor="full-check-box" className='cobrand-custom-checkbox mb-0 mt-1'>
                        <img src={checked} alt="" />
                      </label>
                      <div className="check-box-text-frame">
                        <p className=' lato-regular fs-14-13 black-242'>Full</p>
                        <p className=' lato-regular f-s-12 lh-16 black-242 op-5'>Co-branding/White-labelling will be allowed on available positions on the content.</p>
                        {labelFull &&
                          <div className="start-logo-frame d-flex flex-row align-items-start gap-12 flex-wrap mb-3">
                            {/* CHECK BOX WRAPPER START */}
                            <div className=" d-flex align-items-center gap-8 mw-184 mb-full ">
                              <input type="checkbox" name="fullText" checked={fullText} onChange={handleCheckboxChange} id="full-check-box1" className='d-none ' />
                              <label htmlFor="full-check-box1" className='cobrand-custom-checkbox mb-0 mt-1'>
                                <img src={checked} alt="" />
                              </label>
                              <p className=' lato-regular fs-14-13 black-242'>Business Details</p>
                            </div>
                            {/* CHECK BOX WRAPPER END */}
                            {/* CHECK BOX WRAPPER START */}
                            <div className={`d-flex align-items-center gap-8 ${!logoUrl ? 'disabled-checkBox' : ""}`}>
                              <input type="checkbox" name="fullLogo" checked={fullLogo} disabled={!logoUrl} onChange={handleCheckboxChange} id="full-check-box2" className='d-none ' />
                              <label htmlFor="full-check-box2" className='cobrand-custom-checkbox mb-0 mt-1'>
                                <img src={checked} alt="" />
                              </label>
                              <p className=' lato-regular fs-14-13 black-242'>Logo</p>
                            </div>
                            {/* CHECK BOX WRAPPER END */}
                          </div>
                        }

                        {labelFull &&
                          <div className=" d-flex flex-row align-items-start gap-12 flex-wrap">
                            <div className=' d-flex mb-full'>
                              <CustomDropDown
                                label={"Select Text Position"}
                                // options={variation?.filter((item) => item.language.languageName === language).map((item) => item.color) || []}
                                options={checkCorner}
                                // options={  checkCorner?.filter((item) => !data.displayPositionsNotAllowed?.split(',').includes(item.position?.replace(/\s+/g, '-').toUpperCase()))}
                                getData={(data) => setCobrandingLabelling({ ...cobrandingLabelling, textPosition: data.position })}
                                id="normal"
                                disabled={fullText ? false : true}
                                classes='custom-button-font'
                              />
                            </div>
                            <div className=' d-flex mb-full'>
                              <CustomDropDown
                                label={"Select Logo Position"}
                                options={checkCorner}
                                // options={variation?.filter((item) => item.language.languageName === language).map((item) => item.color) || []}
                                // options={ checkCorner?.filter((item) => !data.displayPositionsNotAllowed?.split(',').includes(item.position.replace(/\s+/g, '-').toUpperCase()))}
                                getData={(data) => setCobrandingLabelling({ ...cobrandingLabelling, logoPosition: data.position })}
                                id="normal"
                                disabled={fullLogo ? false : true}
                                classes='custom-button-font'
                              />
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  }
                  {/* ================ COLOR AND LANGUAGE START =============== */}
                  <div className="branding-color-language-frame">
                    <h5 className=' lato-regular fs-14-13 black-242 op-5'>Color and Language.</h5>
                    <div className="dropwon-component-wrapper">

                      <CustomDropDown
                        label={language || "Language"}
                        options={uniqueLangs || []}

                        getData={(data) => {

                          setCobrandcolorLanguage({ ...cobrandcolorLanguage, language: data.languageName, color: getColorOptions(variation, data.languageName)[0] || "" })
                        }}
                        id="Language"
                        disabled={(uniqueLangs?.length <= 1)}
                        classes='light-border'
                      />
                      <CustomDropDown
                        label={color || "Color"}
                        // options={[{ colorName: 'first', colorCode: "#000" }, { colorName: "play", colorCode: '#270089' }, { colorName: 'this', colorCode: '#12b372' }]}
                        options={getColorOptions(variation, language)}
                        // getData={(data) => console.log(data)}
                        getData={(data) => setCobrandcolorLanguage({ ...cobrandcolorLanguage, color: data })}

                        id="color"
                        disabled={getColorOptions(variation, language).length <= 1}
                        classes='light-border'
                      />

                    </div>
                  </div>
                  {/* ================ COLOR AND LANGUAGE END ================= */}
                </div>
                {/* Co-BRANDING/WHITE-LABELING END */}
                {/* ------------------- */}
                {/* Personalization START */}

                {!isFile(data?.defaultVariation?.contentFilePath, 'img') && ""}

                <div className="personalization-frame">
                  <h5 className='lato-bold f-s-20 lh-28 black-242 op-7 mb-16'>Add a custom message</h5>
                  <div className="start-label-frame d-flex gap-8">
                    <input type="checkbox" name="personalization" checked={personalization} onChange={(e) => handleCheckboxChange(e, 'Personalization')} id="personalization-check-box" className='d-none ' />
                    <label htmlFor="personalization-check-box" className='cobrand-custom-checkbox mb-0 mt-1'>
                      <img src={checked} alt="" />
                    </label>
                    <div className="check-box-text-frame">
                      <p className=' lato-bold fs-14-13 black-242'>Do you want to add a custom message?</p>
                      <p className=' lato-regular f-s-12 lh-16 black-242 op-5 mb-12'>Your custom message will get added to start of the video and on the top of other content types</p>
                    </div>
                  </div>
                  {personalization &&
                    <div className="text-area-frame">
                      <textarea type="text" maxLength={120} className='fx-14 lato-normal black-242 op-8 text-area ' name='personalizationText' value={personalizationText} onChange={(e) => { setCobrandingLabelling({ ...cobrandingLabelling, personalizationText: e.target.value }) }} placeholder='Add your short greeting message here.' />
                      <span className='lato-regular f-s-8 lh-12 black-242 op-5 count-text-words'>{personalizationText.length}/120</span>
                    </div>
                  }
                </div>

                {/* Personalization END */}
                {/* ------------------- */}
                {/* FILE INFORMATION START */}
                {/* FILE INFORMATION END */}
                {/* ------------------- */}
              </div>
            </div>
            {/* LEFT PART OF CODE END */}
            {/* ============================= */}
            {/* RIGHT PART OF CODE START (PRIVEW) */}
            <div className="cobrand-right-preview mb-full">
              <h3 className=' lato-regular fs-22-18 black-242 op-7 mb-hide' >Preview</h3>
              <div className="privew-main-wrapper">
                <div className="preview-header-frame">
                  <h3 className=' lato-medium fs-22-18 black-242 op-9 mb-show'>Preview</h3>

                  <div className="personalization-btn">
                    <div className="preview-arrow-box" onClick={privewArr?.length > 1 ? showPreviousItem : ""}>
                      <span className='preview-left-arrow'></span>
                    </div>
                    <div className="preview-arrow-box" onClick={privewArr?.length > 1 ? showNextItem : ""}>
                      <span className='preview-right-arrow'></span>
                    </div>
                    <p className='lato-regular f-s-12 lh-14 black-242 '>{privewArr[currentIndex] == "Personalization" ? "Message" : privewArr[currentIndex] || ''}</p>
                  </div>
                  {isLoading ?
                    <div className="lato-medium d-none d-md-flex fx-18  primary loading-button">
                      {/* <Image src={downloadGif} alt="icon" className={"download-icon"} /> */}
                      <Spinner />
                      Downloading...
                    </div>
                    :
                    <button
                      onClick={(isDownloadable() && !isLogoUploaded) && cobrandPostHander}
                      disabled={!(isDownloadable() && !isLogoUploaded)}
                      className={(isDownloadable() && !isLogoUploaded) ? "lato-medium d-none d-md-flex fx-14 download-btn primary-btn text-white pointer" : " lato-medium fx-14 d-none d-md-flex primary-btn cursor-notallowed button-download"}>
                      <Image
                        src={(isDownloadable() && !isLogoUploaded) ? downloadWhiteIcon : downloadIcon}
                        alt="icon"
                        className={"download-icon "}
                      />
                      Download
                    </button>
                  }
                  <div style={{ zIndex: "2" }} className='d-md-none sticky-btn '>
                    {isLoading ?
                      <div className="lato-medium fx-18 primary loading-button d-flex justify-content-center align-items-center">
                        {/* <Image src={downloadGif} alt="icon" className={"download-icon"} /> */}
                        <Spinner />
                        Downloading...
                      </div>
                      :
                      <button
                        onClick={(isDownloadable() && !isLogoUploaded) && cobrandPostHander}
                        disabled={!(isDownloadable() && !isLogoUploaded)}
                        className={(isDownloadable() && !isLogoUploaded) ? "lato-medium fx-14 download-btn primary-btn download-btn  text-white pointer" : " lato-medium fx-14 primary-btn download-btn cursor-notallowed"}>
                        <Image
                          src={(isDownloadable() && !isLogoUploaded) ? downloadWhiteIcon : downloadIcon}
                          alt="icon"
                          className={"download-icon"}
                        />
                        Download
                      </button>
                    }
                  </div>
                </div>
                <div className="preview-content-box ">
                  {privewComponent[privewArr[currentIndex]]}
                </div>
                <div className='d-flex flex-column'>
                  <p className='important lato-bold fs-14-12 primary'>Disclaimer:</p>
                  <p className='lato-medium fs-14-12 black-242 op-5'>Content provided by AMCs must remain unaltered, including their logos. Any modifications or misleading additions are strictly prohibited and may result in legal consequences. You will be solely responsible for any such actions; Thefinpedia and the AMC will not assume any liability.</p>
                  <p className='bottom-text lato-medium fs-14-12 black-242 '><span className='op-5'>For details, please refer to the </span><span className='lato-medium fs-14-12 primary pointer' onClick={() => history.push('/terms-conditions')}>Terms & Conditions.</span></p>
                </div>
              </div>
            </div>
            {/* RIGHT PART OF CODE END (PRIVEW) */}
          </div>

          {/* FILE INFORMATION SECTION START  */}
          <div className="file-information-main-wrapper mb-full">
            <h5 className='lato-bolc f-s-20 lh-28 black-242 op-7 '> File Information</h5>
            <div className="information-file-box">
              <div className="thumb-img-box">
                {/* <Image src={demoImg} alt='thumbnail' /> */}
                <Image
                  src={data?.contentType?.contentCode?.toLocaleLowerCase() === "minimalist" ? (isFile(data?.defaultVariation?.contentFilePath, 'img') ? data?.defaultVariation?.contentFilePath : demoImg) : data.thumbnail || demoImg}
                  className="file-thumbnail"
                  alt="thumbnail"
                />
              </div>
              <div className="right-content-box">
                <div className="sub-header-frame d-flex align-items-center gap-8">
                  {data?.amc?.circleImagePath && <Image
                    src={data?.amc && data?.amc?.circleImagePath || demoImg}
                    alt="profile-icon"
                    className="file-user-profile"
                  />}
                  {/* <Image src={demoImg} alt='profile' /> */}
                  <h4 className=' lato-regular f-s-18 lh-24 black-242'>{data?.amc?.amcName}</h4>
                </div>
                <h5 className=' lato-regular f-s-18 lh-24 black-242'>  {data?.title ? data.title : data?.defaultVariation?.title}</h5>
                <p className='lato-regular f-s-12 lh-20 black-242 op-9'> {data?.description ? data?.description : data.defaultVariation?.description}</p>
                <p className='lato-regular f-s-12 lh-20 black-242 op-9'>
                  Content Type :  <span className="lato-medium">{data?.contentType?.contentTypeName || ''}</span> <br />
                  Category :  <span className="lato-medium">{data?.category?.categoryName || ''} </span> <br />
                  Language :  <span className="lato-medium">{data?.defaultLanguage?.languageName || language}</span> <br />
                </p>
              </div>
            </div>
          </div>
          {/* FILE INFORMATION SECTION END  */}
        </div>


      </div>
      {/* =========================== HERO SECTION START ============================== */}
    </section>
  )
}

function StartLabeling({ companyLogo, brandinText }) {
  return (
    <div className={` start-labelling-frame `}>
      {companyLogo &&
        <Image src={companyLogo?.logoUrl} className='startlabeling-size ' alt='logo' />
      }
      {companyLogo && brandinText &&
        <div className="line bg-ECE w-75"></div>
      }
      {brandinText &&
        <p>
          <p className=' lato-regular fs-14-13 black-242'>{brandinText.company}</p>
          <p className=' lato-regular fs-14-13 black-242'>{brandinText.arnNo}</p>
          <p className=' lato-regular fs-14-13 black-242'>{brandinText.companyNo}</p>
          <p className=' lato-regular fs-14-13 black-242'>{brandinText.companyEmail}</p>
        </p>
      }
    </div>
  )
}
function FulltLabeling({ companyLogo, brandinText, setPosition }) {
  // console.log(setPosition)
  // console.log(selectPositionObj[setPosition.logo])
  return (
    <div className={`full-labelling-frame `}>
      <img src={demoImg} alt="thumbnail" className='thumbanial-img' />
      {
        (setPosition.logo === setPosition.text) ?
          <div className="box-position-set" style={selectPositionObj[setPosition.logo]}>
            {companyLogo &&
              <Image src={companyLogo.logoUrl} className='startlabeling-size ' alt='logo' />
            }

            {brandinText &&
              <div className='branding-details-box'>
                <div className="">
                  <p className=' lato-regular f-s-12 lh-15 black-242'>{brandinText.companyEmail}</p>
                  <p className=' lato-regular f-s-12 lh-15 black-242'>{brandinText.company}</p>
                </div>
                <div className="">
                  <p className=' lato-regular f-s-12 lh-15 black-242'>{brandinText.companyNo}</p>
                  <p className=' lato-regular f-s-12 lh-15 black-242'>{brandinText.arnNo}</p>
                </div>


              </div>
            }
          </div>
          :
          <>
            <div className="box-position-set" style={selectPositionObj[setPosition.logo]}>
              {companyLogo &&
                <Image src={companyLogo.logoUrl} className='startlabeling-size ' alt='logo' />
              }
            </div>
            <div className="box-position-set" style={selectPositionObj[setPosition.text]}>
              {brandinText &&
                <div className='branding-details-box'>
                  <div className="">
                    <p className=' lato-regular f-s-12 lh-15 black-242'>{brandinText.companyEmail}</p>
                    <p className=' lato-regular f-s-12 lh-15 black-242'>{brandinText.company}</p>
                  </div>
                  <div className="">
                    <p className=' lato-regular f-s-12 lh-15 black-242'>{brandinText.companyNo}</p>
                    <p className=' lato-regular f-s-12 lh-15 black-242'>{brandinText.arnNo}</p>
                  </div>


                </div>
              }
            </div>

          </>
      }


    </div>
  )
}



function CobrandUpdateDetailsModal({ editModal, setEditModal }) {
  const { showModal, isLogoUpdate } = editModal;
  // console.log(showModal)
  return (
    <Modal className='cobrand-edit-modal' centered show={showModal} onHide={() => setEditModal({ ...editModal, showModal: false })}>
      <Modal.Body>
        <div className="cobrand-edit-modal-header">
          <h3 className='f-s-20 lh-28 primary lato-bold'>Edit company details?</h3>
          <Image onClick={() => setEditModal({ ...editModal, showModal: false })} src={Close1} alt='icon' className='cancle-icon pointer' />
        </div>
        <p className=' lato-regular f-s-14 lh-20 black-242'>Changes will be applied to your profile page.<br />
          Note: You will be required to re-verify any new details entered here.</p>
        <p className=' lato-regular f-s-14 lh-20 black-242 my-12'>Do you still wish to change company details?</p>
        {isLogoUpdate &&
          <p className="f-s-10">(Please upload image of {DEFAULT_IMAGE_WIDTH} X {DEFAULT_IMAGE_HEIGHT} dimensions for best output)</p>
        }
        <div className=" d-flex align-items-center justify-content-end gap-12">
          <Button className='secondary-btn mw-102' onClick={() => setEditModal({ ...editModal, showModal: false })}>Cancel</Button>

          {isLogoUpdate ?
            <label htmlFor={"companyLogo"} className='primary-btn mw-102 py-10 text-center pointer mb-0' >Yes</label>
            :
            <Button className='primary-btn mw-102 py-10  ' onClick={() => setEditModal({ ...editModal, showModal: false, eidtText: true, isLogoUpdate: false })}>Yes</Button>
          }
        </div>
      </Modal.Body>
    </Modal>
  )
}