import React from "react";
import PlansCard from "./PlansCard";
import plans1 from '../../../assets/images/plans1.png';
import platforms from '../../../assets/images/all-platforms.png';
import languages from '../../../assets/images/multi-languages.svg';
import digitalPresence from '../../../assets/images/digital-presence.png';
import markitPlan from '../../../assets/images/markit-plan.png';
import training from '../../../assets/images/eventss-training.png';
import { Button } from "react-bootstrap";
export default function WhyPremium() {
  return (
    <div className="why-premium-section">
      <div className="container text-center">
        <h5 className=" fs-32-28 lato-semibold black-242 text-center">
          Why subscribe to <span className="lato-bold primary">Membership Plans</span>
        </h5>
        <p className="lato-regular fs-24-16 black-242 text-center">
          A team of personal finance, marketing, and research experts with
          decades of experience helping you
        </p>
        <div className="premium-plans-wrapper">
          <PlansCard image={plans1} text="Build your brand with <span class='primary lato-semibold'>  white-labeled institutional-quality </span> communication" clases="" />
          <PlansCard image={languages} text={`Get tailored high-quality compliance-approved in <span class='primary lato-semibold'> multiple languages and colors </span> `} clases="" />
          <PlansCard image={platforms} text={`Create engaging and captivating content in <span class='primary lato-semibold'> diverse formats for all platforms</span>`} clases="" />
          <PlansCard image={digitalPresence} text={`Optimise your <span class='primary lato-semibold'> digital presence with</span> our 360-degree Tech and Marketing services*`} clases="coming-soon" />
          <PlansCard image={markitPlan} text={`Build <span class='primary lato-semibold'> customized marketing plan </span> for better awareness, acquisition, retention, and referral`} clases="coming-soon" />
          <PlansCard image={training} text={`Get access to <span class='primary lato-semibold'> premium events, training, and sales tools </span> and much more`} clases="coming-soon" />
        </div>
        <a href="/mymembership">
          <Button className='primary-btn mx-auto'>Checkout Membership Plans</Button>
        </a>
      </div>
    </div>
  );
}
