

import { fabric } from "fabric";
import { placeholderImg } from "../../../assets/images/canvas";

fabric.Rect.prototype.toPlaceholder = function () {
  this.isPlaceholder = true;
  this.editable = true;

  // Function to attach placeholder behavior
  this.attachPlaceholder = function (canvas, scale) {
    const rect = this;
    this.editable = true;

    fabric.Image.fromURL(placeholderImg, (img) => {
      const imageElement = img.getElement();

      if (imageElement instanceof HTMLImageElement) {
        // Calculate the scale to fit the rectangle
        const scaleX = rect.width / img.width;
        const scaleY = rect.height / img.height;

        const pattern = new fabric.Pattern({
          source: imageElement,
          repeat: 'no-repeat',
          patternTransform: [scaleX, 0, 0, scaleY, 0, 0], // Scaling matrix
        });

        rect.set('fill', pattern);
        rect.set('stroke', 'black');
        rect.set('strokeDashArray', [5, 5]);
        canvas.renderAll();
      } else {
        console.error("Failed to create a pattern. The image element is invalid.");
      }
    });


    rect.on("mousedown", function () {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";
      input.onchange = (event) => {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const imageUrl = e.target.result;
            fabric.Image.fromURL(imageUrl, (img) => {
              img.set({
                left: rect.left,
                top: rect.top,
                scaleX: (rect.width / img.width) * rect.scaleX,
                scaleY: (rect.height / img.height) * rect.scaleY,
                angle: rect.angle,
                editable: true,
              });

              const objects = canvas.getObjects();
              const placeholderIndex = objects.findIndex((obj) => obj === rect);
              if (placeholderIndex !== -1) {
                canvas.insertAt(img, placeholderIndex);
              } else {
                canvas.add(img);
              }
              canvas.remove(rect);
              canvas.renderAll();
            });
          };
          reader.readAsDataURL(file);
        }
      };
      input.click();

    });


  };
};



fabric.Circle.prototype.toPlaceholder = function () {
  this.isPlaceholder = true; // Custom property
  this.editable = true;


  this.attachPlaceholder = function (canvas) {
    const circle = this; // Reference to the circle
    this.editable = true;
    this.radius = this.radius || 50;
    fabric.Image.fromURL(placeholderImg, (img) => {
      const imageElement = img.getElement();

        // Calculate scale to fit the circle
        const diameter = (circle.radius || 50) * 2; // Circle's diameter
        const scaleX = diameter / img.width;
        const scaleY = diameter / img.height;

        const pattern = new fabric.Pattern({
          source: imageElement,
          repeat: 'no-repeat',
          patternTransform: [scaleX, 0, 0, scaleY, 0, 0], // Scaling matrix
        });

        circle.set('fill', pattern);
        circle.set('stroke', 'black');
        circle.set('strokeDashArray', [5, 5]);
        canvas.renderAll();

    });


    circle.on("mousedown", function () {

      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";
      input.onchange = (event) => {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const imageUrl = e.target.result;
            getRoundedImage(imageUrl, (roundedImageUrl) => {
            fabric.Image.fromURL(roundedImageUrl, (img) => {
              img.set({
                left: circle.left,
                top: circle.top,
                scaleX: (circle.width / img.width) * circle.scaleX,
                scaleY: (circle.height / img.height) * circle.scaleY,
                angle: circle.angle,
                editable: true,
              });

              const objects = canvas.getObjects();
              const placeholderIndex = objects.findIndex((obj) => obj === circle);
              if (placeholderIndex !== -1) {
                canvas.insertAt(img, placeholderIndex);
              } else {
                canvas.add(img);
              }
              canvas.remove(circle);
              canvas.renderAll();
            });
          })
          }
          reader.readAsDataURL(file);
        }
      };
      input.click();

    })


  }
};
function getRoundedImage(imageSrc, callback, size = 150) {
  const img = new Image();
  img.crossOrigin = "anonymous"; // Avoid CORS issues if needed
  img.onload = () => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // Set canvas size to the specified size
    canvas.width = size;
    canvas.height = size;

    // Draw a circular clipping path
    ctx.beginPath();
    ctx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2);
    ctx.clip();

    // Calculate the size and position to crop the image
    const minSide = Math.min(img.width, img.height);
    const sx = (img.width - minSide) / 2;
    const sy = (img.height - minSide) / 2;

    // Draw the image inside the circular clipping path
    ctx.drawImage(img, sx, sy, minSide, minSide, 0, 0, size, size);

    // Convert canvas to a data URL
    const roundedImageUrl = canvas.toDataURL();
    callback(roundedImageUrl);
  };

  img.onerror = (err) => {
    console.error("Error loading image:", err);
    callback(null);
  };

  // Set image source
  img.src = imageSrc;
}






