import React, { useEffect, useState } from 'react'
import RangeFields from './components/RangeFields'
import BarChart from './components/BarChart'
import { CheckToolsSubscription, ShowShare, ToolsScreenshotHeader, captureImageHandler, debounce, formatCurrency, toolsDisclaimer, toolsInvestorDetails } from '../../utils/ReusableFunctions';
import { useMemo } from 'react';
import { useRef } from 'react';
export default function ChildEducationPlanner({ calculator, textLogo = '', imageData, setImageData, isDisable, setIsDisable, setAlertData, setIsAlert, setModalData }) {

  let [presentAge, setPresentAge] = useState(0);
  let [presentValue, setPresentValue] = useState(1000000);
  let [corpusAge, setCorpusAge] = useState(18);
  let [rateOfInterest, setRateOfInterest] = useState(12);
  let [inflation, setInflation] = useState(7);
  let [SIPStepUp, setSIPStepUp] = useState(10);
  let [fixedSIP, setFixedSIP] = useState(4416);
  let [growingSIP, setGrowingSIP] = useState(2345);
  let [corpusFV, setCorpusFV] = useState(3379932);
  let [fixedSIPInvesment, setFixedSIPInvesment] = useState(953856);
  let [cumulativeSIP, setCumulativeSIP] = useState(1439620);
  let [lumpsum, setLumpsum] = useState(439525);

  const onSubmitAction = (presentAge, presentValue, corpusAge, roi = 0, inflation, SIPStepUp) => {
    let cumulativeSIP = 0;
    corpusFV = Math.round((inflation / 100 + 1) ** (corpusAge - presentAge) * presentValue,);
    let toDivideROI = roi / 100 / 12;
    lumpsum = Math.round((1 / (roi / 100 + 1) ** (corpusAge - presentAge)) * corpusFV,);

    let tenure = (corpusAge - presentAge) * 12;
    fixedSIP = Math.round(
      corpusFV /
      (((roi / 100 / 12 + 1) ** tenure - 1) / toDivideROI) /
      (roi / 100 / 12 + 1),
    );

    let corpusPV = presentValue;
    let fixedSIPInvesment = fixedSIP * corpusAge * 12;


    setPresentAge(presentAge)
    setPresentValue(presentValue)
    setCorpusAge(corpusAge)
    setRateOfInterest(roi)
    setInflation(inflation)
    setSIPStepUp(SIPStepUp);
    setFixedSIP(fixedSIP);
    setGrowingSIP(growingSIP);
    setFixedSIPInvesment(fixedSIPInvesment);
    setCumulativeSIP(cumulativeSIP);
    setCorpusFV(Math.round(corpusFV));
    setLumpsum(lumpsum);

    let corpus1 = Math.round(corpusFV);
    let init_pmt = -10000;
    let rate_of_return = roi; // in percentage
    let total_period = (corpusAge - presentAge); //years
    let growth_rate = SIPStepUp; // in percentage
    let goal = corpus1;


    const fnParams = [
      init_pmt,
      rate_of_return / 100,
      total_period * 12,
      growth_rate / 100,
    ];

    try {
      const result = goalSeek({
        fn,
        fnParams,
        percentTolerance: 0.1,
        maxIterations: 100,
        maxStep: 0.1,
        goal: goal,
        independentVariableIdx: 0,
      });

      let result1 = Math.ceil(Math.abs(result));

      setGrowingSIP(result1);


      let totalCorpus = totalSIP(result1, total_period, growth_rate);
      setCumulativeSIP(totalCorpus)


    } catch (e) {
      // console.log('error', e);
    }

  };

  useEffect(() => {
    onSubmitAction(presentAge, presentValue, corpusAge, rateOfInterest, inflation, SIPStepUp);
  }, [])

  // useEffect(() => {

  // }, [presentAge, presentValue, corpusAge, rateOfInterest, inflation, SIPStepUp])

  const handleChange = (value, name) => {
    if (name === "presentAge") {
      presentAge = value
    }
    if (name === "presentValue") {
      presentValue = value
    }
    if (name === "corpusAge") {
      corpusAge = value
    }
    if (name === "rateOfInterest") {
      rateOfInterest = value
    }
    if (name === "inflation") {
      inflation = value
    }
    if (name === "SIPStepUp") {
      SIPStepUp = value
    }

    onSubmitAction(presentAge, presentValue, corpusAge, rateOfInterest, inflation, SIPStepUp);
  }


  const goalSeek = ({ fn, fnParams, percentTolerance, customToleranceFn, maxIterations, maxStep, goal, independentVariableIdx, }) => {
    if (typeof customToleranceFn !== 'function') {
      if (!percentTolerance) {
        throw Error('invalid inputs');
      }
    }
    let g;
    let y;
    let y1;
    let oldGuess;
    let newGuess;
    let res;
    const absoluteTolerance = ((percentTolerance || 0) / 100) * goal;
    // iterate through the guesses
    for (let i = 0; i < maxIterations; i++) {
      // define the root of the function as the error
      res = fn.apply(null, fnParams);
      res = parseFloat(res);

      y = res - goal;
      if (isNaN(y))
        throw TypeError('resulted in NaN 1');

      // was our initial guess a good one?
      if (typeof customToleranceFn !== 'function') {
        if (Math.abs(y) <= Math.abs(absoluteTolerance))
          return fnParams[independentVariableIdx];
      }
      else {
        if (customToleranceFn(res))
          return fnParams[independentVariableIdx];
      }
      // set the new guess, correcting for maxStep
      oldGuess = fnParams[independentVariableIdx];
      newGuess = oldGuess + y;

      fnParams[independentVariableIdx] = newGuess;

      y1 = parseInt(fn.apply(null, fnParams)) - goal;



      if (isNaN(y1))
        throw TypeError('resulted in NaN 2');
      // calculate the error
      g = (y1 - y) / y;
      if (g === 0)
        g = 0.0001;
      // set the new guess based on the error, correcting for maxStep
      newGuess = oldGuess - y / g;

      fnParams[independentVariableIdx] = newGuess;
    }
    // done with iterations, and we failed to converge
    throw Error('failed to converge');
  };

  const fn = function calculate(pmt, rate, tper, growth) {
    let years;
    let cum_returns = 0;
    let growth_calc;
    let i;
    let this_pmt;
    let this_nper;
    let fv;


    growth_calc = 1 + growth;
    years = tper / 12;
    for (i = 1; i <= years; i++) {
      if (i == 1) {
        this_pmt = pmt;
      } else {
        this_pmt = pmt * ((growth_calc ** (i - 1)) - (growth_calc ** (i - 2)));
      }
      this_nper = tper - ((i - 1) * 12);
      fv = parseFloat(FV(rate, this_nper, this_pmt, 0, 1));
      cum_returns = cum_returns + fv;
    }
    return cum_returns
  }

  function FV(rate, nper, pmt, pv, type) {
    let pow = Math.pow(1 + (rate / 12), nper),
      fv;
    if (rate) {
      fv = (pmt * (1 + (rate / 12) * type) * (1 - pow) / (rate / 12)) - pv * pow;
    } else {
      fv = -1 * (pv + pmt * nper);
    }
    return fv.toFixed(2);
  }

  function totalSIP(firstValue, tenure, rate) {
    let totalValue = firstValue * 12;
    for (let i = 0; i < tenure; i++) {
      let temp = ((1 + (rate / 100)) * firstValue).toFixed(2);
      totalValue += temp * 12;
      firstValue = temp;
    }
    return Math.round(totalValue);
  }




  let downloadScreenshotFunc = null;

  const setDownloadScreenshotFunc = (func) => {
    downloadScreenshotFunc = func;
  };
  const barChart = useMemo(() => {
    return (
      <BarChart
        info={{
          labels: ["Fixed SIP", "Step Up SIP"],
          data: [fixedSIP, growingSIP],
        }}
      />
    );
  }, [fixedSIP, growingSIP]);
  const barChart2 = useMemo(() => {
    return (
      <BarChart
        info={{
          labels: ["Fixed SIP", "Step Up SIP", "Lumpsum", ["Education", "Corpus"]],
          data: [fixedSIPInvesment, cumulativeSIP, lumpsum, Math.round(corpusFV)],
        }}
      />

    );
  }, [fixedSIPInvesment, cumulativeSIP, Math.round(corpusFV), lumpsum]);


  return (
    <div>
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART START ---------------------------------------------- */}
      <div className="calculator-result-main-wrapper">
        <div className="calculator-component-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Calculator
          </h4>
          <RangeFields
            label="Current Age of Child"
            name={"presentAge"}
            value={presentAge}
            minVal={0}
            maxVal={30}
            step={1}
            unit="Year"
            getValue={debounce(handleChange)} />

          <RangeFields
            label="Corpus Required"
            name={"presentValue"}
            value={presentValue}
            minVal={100000}
            maxVal={1000000000}
            step={5000}
            getValue={debounce(handleChange)}
            showComma
          />
          <RangeFields
            label="Corpus Required at Age of"
            name={'corpusAge'}
            value={corpusAge}
            minVal={5}
            maxVal={31}
            step={1}
            unit="Year"
            getValue={debounce(handleChange)}
          />
          <RangeFields
            label="Expected Rate of Return"
            name={'rateOfInterest'}
            value={rateOfInterest}
            minVal={4}
            maxVal={50}
            step={0.5}
            unit="%"
            getValue={debounce(handleChange)}
          />
          <RangeFields
            label="Rate of Inflation"
            name={'inflation'}
            value={inflation}
            minVal={1}
            maxVal={20}
            step={0.5}
            unit="%"
            getValue={debounce(handleChange)}
          />
          <RangeFields
            label="SIP Step Up (Annual)"
            name={'SIPStepUp'}
            value={SIPStepUp}
            minVal={1}
            maxVal={25}
            step={0.5}
            unit="%"
            getValue={debounce(handleChange)}
          />


        </div>
        <div className="result-graph-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Results{" "}

            {<ShowShare calculator isDisable={isDisable} setIsDisable={setIsDisable} clickHandler={() => { CheckToolsSubscription(textLogo, downloadScreenshotFunc, calculator?.uuid, imageData, setIsDisable, setAlertData, setIsAlert, setModalData) }} />}

          </h4>
          <div className="result-graph-wrapper">
            <div className={`graph-1-frame flex-column ${false ? "w-auto" : "w-50"}`}>
              <p className='lato-semibold  f-s-12 lh-14 primary text-center pt-2'>Monthly SIP Amount</p>

              {barChart}
            </div>
            <div className={`graph-1-frame flex-column ${false ? "w-auto" : "w-100"}`}>
              <p className='lato-semibold  f-s-12 lh-14 primary text-center pt-2'>Total Investment</p>

              {barChart2}
            </div>
          </div>
          {childEducationResultText(presentValue, presentAge, corpusFV, corpusAge, inflation, fixedSIP, growingSIP, rateOfInterest, SIPStepUp, fixedSIPInvesment, cumulativeSIP, lumpsum)}
        </div>
      </div>
      <div className="make-none">
        <CalculatorOutputScreenShot
          corpusAge={corpusAge}
          textLogo={textLogo}
          rateOfInterest={rateOfInterest}
          SIPStepUp={SIPStepUp}
          fixedSIP={fixedSIP}
          growingSIP={growingSIP}
          fixedSIPInvesment={fixedSIPInvesment}
          cumulativeSIP={cumulativeSIP}
          lumpsum={lumpsum}
          corpusFV={corpusFV}
          inflation={inflation}
          presentAge={presentAge}
          presentValue={presentValue}
          onDownloadScreenshot={setDownloadScreenshotFunc}
          setImageData={setImageData} />

      </div>
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART END ---------------------------------------------- */}

    </div>
  )
}

function CalculatorOutputScreenShot({ corpusAge, textLogo, rateOfInterest, SIPStepUp, fixedSIP, growingSIP, fixedSIPInvesment, sipAmount, cumulativeSIP, lumpsum, corpusFV, presentValue, presentAge, onDownloadScreenshot, inflation, setImageData }) {
  const { cobrandingLabelling, investor } = textLogo;
  const [signup, setSignup] = useState(false);
  const screenshotRef = useRef(null);

  useEffect(() => {
    if (onDownloadScreenshot) {

      captureImageHandler(onDownloadScreenshot, screenshotRef, setSignup, setImageData);
    }
  }, [textLogo, screenshotRef, presentAge, presentValue, corpusAge, fixedSIP, corpusFV, lumpsum, rateOfInterest, inflation, SIPStepUp]);
  return (
    <>
      {/* {signup && <SignUp show={signup} handleClose={() => setSignup(!signup)} />} */}
      <div ref={screenshotRef} className="tools-calulator-output-screenshot-frame ">
        {ToolsScreenshotHeader(cobrandingLabelling)}

        <div className="result-graph-wrapper">
          <div className={`graph-1-frame w-75 ${false ? "w-auto" : "w-50"}`}>
            <BarChart
              info={{
                labels: ["Fixed SIP", "Step Up SIP"],
                data: [fixedSIP, growingSIP],
              }}
            />

          </div>
          <div className={`graph-1-frame ${false ? "w-auto" : "w-100"}`}>
            <BarChart
              info={{
                labels: ["Fixed SIP", "Step Up SIP", "Lumpsum", ["Education", "Corpus"]],
                data: [fixedSIPInvesment, cumulativeSIP, lumpsum, Math.round(corpusFV)],
              }}
            />

          </div>
        </div>
        {toolsInvestorDetails(investor)}
        <br />
        {childEducationResultText(presentValue, presentAge, corpusFV, corpusAge, inflation, fixedSIP, growingSIP, rateOfInterest, SIPStepUp, fixedSIPInvesment, cumulativeSIP, lumpsum)}

        {toolsDisclaimer()}

      </div>

    </>
  )
};

function childEducationResultText(presentValue, presentAge, corpusFV, corpusAge, inflation, fixedSIP, growingSIP, rateOfInterest, SIPStepUp, fixedSIPInvesment, cumulativeSIP, lumpsum) {
  return (
    <div className='d-flex justify-content-around p-2'>
      <p className=" f-s-16 lh-24 black-242 op-7 pt-2 text-center">
        If the corpus required for your child education today is <span className=" lato-semibold primary">₹{formatCurrency(presentValue)}</span>  when your child is <span className=" lato-semibold primary">{presentAge}</span>  years of age, the corpus required will increase to <span className=" lato-semibold primary">₹{formatCurrency(corpusFV)}</span>  when you child turns <span className=" lato-semibold primary">{corpusAge}</span> years of age, considering inflation of <span className=" lato-semibold primary">{inflation}%</span>. And to accumulate this corpus, you can either start a monthly SIP of <span className=" lato-semibold primary">₹{formatCurrency(fixedSIP)}</span>, or do a Step Up SIP of <span className=" lato-semibold primary">₹{formatCurrency(growingSIP)}</span> or invest lumpsum amount of ₹"Lumpsum Amount", considering your investment grows at <span className=" lato-semibold primary">{rateOfInterest}%</span> and an Step Up SIP rate of <span className=" lato-semibold primary">{SIPStepUp}%</span> annually. At the end of investment tenure, your total investment to accumulate <span className=" lato-semibold primary">₹{formatCurrency(corpusFV)}</span> corpus, in case of SIP will be <span className=" lato-semibold primary">₹{formatCurrency(fixedSIPInvesment)}</span>, in case of Step UP SIP will be <span className=" lato-semibold primary">₹{formatCurrency(cumulativeSIP)}</span>, and in case of lumpsum will be <span className=" lato-semibold primary">₹{formatCurrency(lumpsum)}</span>
      </p>
    </div>

  )
}