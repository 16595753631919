import React from "react";
import { Image } from "react-bootstrap";
import Search from "../../../assets/images/search.svg";
import { formatNumber } from "../../../utils/ReusableFunctions";

const PageSubHeader = ({ title, count, isSearch, clases, changeHandler = {}, data, type }) => {
  return (
    <div className={`sub-header-wrapper ${clases}`}>
      <div className="mycollection-header">
        <div className={data ? "d-flex flex-column" : "mycollection-left"}>
          <h2 className="fs-32-24 lato-semibold black-242 mb-0">{title}</h2>
          {!type && <h4>{count}</h4>}
          {data &&

            <div className="content-type-data">
              {Object.keys(data?.contentTypeStats || {})?.map(
                (item, i) => {
                  if (item == 'totalContentCount') {
                    return ''
                  }
                  return (
                    <>
                      <p className=" lato-regular f-s-12 lh-16 black-242">
                        {item}{" "}
                        {formatNumber(data?.contentTypeStats[item])}
                      </p>
                      <span
                        className={`virtical-strip ${Object.keys(data?.contentTypeStats)
                          .length ===
                          i + 1 && "d-none"
                          }`}
                      ></span>
                    </>
                  );
                }
              )}
            </div>
          }

        </div>

        {isSearch && (
          <div className="mycollection-right">
            <input
              type="text"
              placeholder="Search in collections"
              className="fs-22-18 lato-regular"
              onChange={changeHandler}
            />
            <Image src={Search} alt="search" />
          </div>
        )}
      </div>
    </div>
  );
};

export default PageSubHeader;
