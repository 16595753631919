import React, { useState } from 'react'
import { Button, Image, Modal } from 'react-bootstrap';
import { CustomDropDown } from '../../utils/form';
import checked from '../../assets/images/cobranv2-checked.jpg';
import { getUser } from '../../utils/ReusableFunctions';
import CrossIcon from "../../assets/images/icon/cross-modal-icon.svg";
import { positionNames } from '../../utils/Constant';

const SelectPositionModal = ({ openPosition, setOpenPosition, cobrandingLabelling, setCobrandingLabelling }) => {
    const [isLoading, setIsLoading] = useState(false);

    const { labelStart, lableEnd, labelFull, startText, startLogo, endText, endLogo, fullText, fullLogo, positionId, textPosition, logoPosition, personalization, personalizationText } = cobrandingLabelling;
    const [privewArr, setPrivewArr] = useState(["no"])
    const userProfileDetails = getUser();
    const [companyLogo, setCompanyLogo] = useState({ logoUrl: userProfileDetails?.organizationLogoPath ?? "", logoFile: null })
    const { logoUrl, logoFile } = companyLogo;
    const checkCorner = [
        { position: 'Top Left' },
        { position: "Top Right" },
        { position: 'Top Center' },
        { position: 'Bottom Left' },
        { position: 'Bottom Right' },
        { position: 'Bottom Center' },
        { position: 'Center Left' },
        { position: 'Center Center' },
        { position: 'Center Right' },
    ]
    const handleCheckboxChange = (event, position = '') => {
        const { name, checked } = event.target;
        if (position) {
            if (checked) {
                setPrivewArr([...privewArr, position])
            }
            else {
                const filter = privewArr.filter((item) => item !== position);
                setPrivewArr(filter);
            }
        }

        if (name === 'labelStart') {
            setCobrandingLabelling({
                ...cobrandingLabelling,
                labelStart: checked,
                startText: checked ? cobrandingLabelling.startText : false,
                startLogo: checked ? cobrandingLabelling.startLogo : false
            });
        } else if (name === 'lableEnd') {
            setCobrandingLabelling({
                ...cobrandingLabelling,
                lableEnd: checked,
                endText: checked ? cobrandingLabelling.endText : false,
                endLogo: checked ? cobrandingLabelling.endLogo : false
            });

        } else if (name === 'labelFull') {
            setCobrandingLabelling({
                ...cobrandingLabelling,
                labelFull: checked,
                fullText: checked ? cobrandingLabelling.fullText : false,
                fullLogo: checked ? cobrandingLabelling.fullLogo : false,
                textPosition: checked ? cobrandingLabelling.textPosition : "",
                logoPosition: checked ? cobrandingLabelling.logoPosition : ""
            });
        }
        setCobrandingLabelling({ ...cobrandingLabelling, [name]: checked });
    };
    const handleClose = () => {
        setOpenPosition(false);
    };
    const dropdownOptions = Object.entries(positionNames).map(([position, value]) => ({ position, value }));

    const reversedPositionNames = Object.fromEntries(
        Object.entries(positionNames).map(([key, value]) => [value, key])
    );
    return (
        <Modal style={{ zIndex: "9999999" }} className='position-modal' centered show={openPosition} onHide={handleClose}>
            <Modal.Body>
                <div className="co-branding-leabling ">
                    <div className='select-position d-flex flex-row justify-content-between'>
                        <p className='lato-medium fs-24-12 black-242'>Select Position</p>
                        <div className='d-flex justify-content-end'>
                            <Image width={20} height={20} src={CrossIcon} onClick={handleClose} style={{ cursor: "pointer" }} />
                        </div>
                    </div>
                    <div>
                        <p className='lato-regular fs-14-12 black-4'>Co-branding/White-labelling display positions.</p>
                    </div>

                    <div className="start-label-frame">
                        <input type="checkbox" name="labelStart" checked={labelStart} onChange={(e) => handleCheckboxChange(e, 'Start Position')} id="start-check-box" className='d-none ' />
                        <label htmlFor="start-check-box" className='cobrand-custom-checkbox mb-0 mt-1'>
                            <img src={checked} alt="" />
                        </label>
                        <div className="check-box-text-frame">
                            <p className=' lato-regular fs-14-13 black-242'>Start</p>
                            <p className=' lato-regular f-s-12 lh-16 black-242 op-5 '>Co-branding/White-labelling will be positioned in the Center before any content</p>
                            {labelStart &&

                                <div className="start-logo-frame">
                                    {/* CHECK BOX WRAPPER START */}
                                    <div className=" d-flex align-items-center gap-8">
                                        <input type="checkbox" name="startText" checked={startText} onChange={handleCheckboxChange} id="start-check-box1" className='d-none ' />
                                        <label htmlFor="start-check-box1" className='cobrand-custom-checkbox mb-0 mt-1'>
                                            <img src={checked} alt="" />
                                        </label>
                                        <p className=' lato-regular fs-14-13 black-242'>Business Details</p>
                                    </div>

                                    <div className={`d-flex align-items-center gap-8 ${!logoUrl ? 'disabled-checkBox' : ""}`}>
                                        <input type="checkbox" name="startLogo" checked={startLogo} onChange={handleCheckboxChange} disabled={!logoUrl} id="start-check-box2" className='d-none ' />
                                        <label htmlFor="start-check-box2" className='cobrand-custom-checkbox mb-0 mt-1'>
                                            <img src={checked} alt="" />
                                        </label>
                                        <p className=' lato-regular fs-14-13 black-242'>Logo</p>
                                    </div>
                                    {/* CHECK BOX WRAPPER END */}
                                </div>


                            }
                        </div>
                    </div>
                    <div className="start-label-frame">
                        <input type="checkbox" name="lableEnd" checked={lableEnd} onChange={(e) => handleCheckboxChange(e, 'End Position')} id="end-check-box" className='d-none ' />
                        <label htmlFor="end-check-box" className='cobrand-custom-checkbox mb-0 mt-1'>
                            <img src={checked} alt="" />
                        </label>
                        <div className="check-box-text-frame">
                            <p className=' lato-regular fs-14-13 black-242'>End</p>
                            <p className=' lato-regular f-s-12 lh-16 black-242 op-5 '>Co-branding/White-labelling will be positioned in the Center after any content.</p>
                            {lableEnd &&
                                <div className="start-logo-frame">
                                    {/* CHECK BOX WRAPPER START */}
                                    <div className=" d-flex align-items-center gap-8">
                                        <input type="checkbox" name="endText" checked={endText} onChange={handleCheckboxChange} id="end-check-box1" className='d-none ' />
                                        <label htmlFor="end-check-box1" className='cobrand-custom-checkbox mb-0 mt-1'>
                                            <img src={checked} alt="" />
                                        </label>
                                        <p className=' lato-regular fs-14-13 black-242'>Business Details</p>
                                    </div>
                                    {/* CHECK BOX WRAPPER END */}
                                    {/* CHECK BOX WRAPPER START */}
                                    <div className={`d-flex align-items-center gap-8 ${!logoUrl ? 'disabled-checkBox' : ""}`}>
                                        <input type="checkbox" name="endLogo" checked={endLogo} disabled={!logoUrl} onChange={handleCheckboxChange} id="end-check-box2" className='d-none ' />
                                        <label htmlFor="end-check-box2" className='cobrand-custom-checkbox mb-0 mt-1'>
                                            <img src={checked} alt="" />
                                        </label>
                                        <p className=' lato-regular fs-14-13 black-242'>Logo</p>
                                    </div>
                                    {/* CHECK BOX WRAPPER END */}
                                </div>
                            }
                        </div>
                    </div>
                    <div className="start-label-frame">
                        <input type="checkbox" name="labelFull" checked={labelFull} onChange={(e) => handleCheckboxChange(e, 'Full')} id="full-check-box" className='d-none ' />
                        <label htmlFor="full-check-box" className='cobrand-custom-checkbox mb-0 mt-1'>
                            <img src={checked} alt="" />
                        </label>
                        <div className="check-box-text-frame">
                            <p className=' lato-regular fs-14-13 black-242'>Full</p>
                            <p className=' lato-regular f-s-12 lh-16 black-242 op-5'>Co-branding/White-labelling will be allowed on available positions on the content.</p>
                            {labelFull &&
                                <div className="start-logo-frame d-flex flex-row align-items-start gap-12 flex-wrap mb-3">
                                    {/* CHECK BOX WRAPPER START */}
                                    <div className=" d-flex align-items-center gap-8 mw-184 mb-full ">
                                        <input type="checkbox" name="fullText" checked={fullText} onChange={handleCheckboxChange} id="full-check-box1" className='d-none ' />
                                        <label htmlFor="full-check-box1" className='cobrand-custom-checkbox mb-0 mt-1'>
                                            <img src={checked} alt="" />
                                        </label>
                                        <p className=' lato-regular fs-14-13 black-242'>Business Details</p>
                                    </div>

                                    <div className={`d-flex align-items-center gap-8 ${!logoUrl ? 'disabled-checkBox' : ""}`}>
                                        <input type="checkbox" name="fullLogo" checked={fullLogo} disabled={!logoUrl} onChange={handleCheckboxChange} id="full-check-box2" className='d-none ' />
                                        <label htmlFor="full-check-box2" className='cobrand-custom-checkbox mb-0 mt-1'>
                                            <img src={checked} alt="" />
                                        </label>
                                        <p className=' lato-regular fs-14-13 black-242'>Logo</p>
                                    </div>
                                    {/* CHECK BOX WRAPPER END */}
                                </div>
                            }

                            {labelFull &&
                                <div className=" d-flex flex-row align-items-start gap-12 flex-wrap">
                                    <div className=' d-flex mb-full'>
                                        <CustomDropDown
                                            label={reversedPositionNames[textPosition] || "Select Text Position"}

                                            options={dropdownOptions}

                                            getData={(data) => setCobrandingLabelling({ ...cobrandingLabelling, textPosition: data.value })}
                                            id="normal"
                                            disabled={fullText ? false : true}
                                            classes='custom-button-font'
                                        />
                                    </div>
                                    <div className=' d-flex mb-full'>
                                        <CustomDropDown
                                            label={reversedPositionNames[logoPosition] || "Select Logo Position"}
                                            options={dropdownOptions}
                                            getData={(data) => setCobrandingLabelling({ ...cobrandingLabelling, logoPosition: data.value })}
                                            id="normal"
                                            disabled={fullLogo ? false : true}
                                            classes='custom-button-font'
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="select-modal-tab-btn d-flex justify-content-center">
                    <Button onClick={handleClose} style={{ marginRight: "1rem" }} className="secondary-btn lato-medium fs-14-12" >Cancel</Button>
                    <Button onClick={handleClose} className="primary-btn lato-medium fs-14-12" >Save </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default SelectPositionModal