import React, { useEffect, useState } from "react";
import { Dropdown, Image } from "react-bootstrap";
import close from '../../../assets/images/cancel-icon.svg';
import DownIcon from "../../../assets/images/dropdown-icon.svg";

export default function SelectLocation({ label, onchangeHandler = () => { }, placeholder, options = [], selectedKeyWords = [], required = true, getKeys = () => { console.log("default call Kesys") } }) {
    const [keyword, setKeyword] = useState("");
    // const [show,setShow]=useState(false);
    // const [option, setOption] = useState(options)
    const [selectedOption, setSelectedOption] = useState(selectedKeyWords || [])


    useEffect(() => {
        setSelectedOption(selectedKeyWords);
    }, [selectedKeyWords])
    function keywordChangeHandler(e) {
        setKeyword(e.target.value);
        onchangeHandler(e.target.value)



    }
    function selectHandler(data) {
        setKeyword("");
        setSelectedOption([...selectedOption, data])
        getKeys([...selectedOption, data]);
        // setOption([])
    }
    // function handleKeyPress(e) {
    //   if (e.key === "Enter" && keyword.trim() !== "") {
    //     setSelectedOption([...selectedOption, keyword])
    //     setKeyword("");
    //   }
    // };
    function removeKeyword(data) {
        // let remove=option?.spalise(index,1)
        const newArray = selectedOption.filter(item => item !== data);
        setSelectedOption(newArray);
    }
    return (

        <div className="keywords-dropdown-frame">
            <Dropdown className="single-select-api  w-100 mw-100">
                <Dropdown.Toggle className="dropdown-btn" id="dropdown-basic">
                    <input type="text" placeholder={placeholder} className="lato-semibold fx-14 black-242 input-box w-100 mw-100 border-0" name="keyword" value={keyword} disabled={false} onChange={keywordChangeHandler} />
                    {keyword && <Image src={close} onClick={() => setKeyword("")} alt="canle icon" className="cancle-filed" />}
                    <Image src={DownIcon} alt="image" />
                </Dropdown.Toggle>
                {/* {options.length > 0 && keyword && */}
                <Dropdown.Menu className="" show={options.length > 0 && keyword}>
                    {
                        options?.map((item, i) => {
                            return (
                                <Dropdown.Item onClick={() => selectHandler(item.label)} key={i} className="lato-regular fx-14 black-242 " >{item.label}</Dropdown.Item>
                            )
                        })
                    }
                </Dropdown.Menu>
                {/* } */}
            </Dropdown>
            {/* <div className="kaywords-input-fileds">
<input type="text" placeholder={placeholder} className="input-box-keywords" name="keyword" value={keyword} disabled={selectedOption.length===1} onChange={keywordChangeHandler}  />
{keyword && <Image src={close} onClick={() => setKeyword("")} alt="canle icon" className="cancle-filed" />}
</div> */}

            {/* {options.length > 0 && keyword &&
            <ul className="autocompleate-kaywords-dropdown">
                {
                    options?.map((item, i) => {
                        return (
                            <li onClick={() => selectHandler(item.label)} key={i}>{item.label}</li>
                        )
                    })
                }
            </ul>
        } */}

            {/* <div className="show-selected-kay-frame">
            {selectedOption?.map((item, i) => {
                return (
                    <span>{item} <Image src={close} onClick={() => removeKeyword(item)} alt="canle icon" className="cancle-key" /></span>
                )
            })}

        </div> */}

            <div className='pills-wrapper mt-3'>
                {selectedOption?.map((item, i) => (
                    <span
                        key={item}
                        className={`lato-regular fx-12 notification-pills active`}
                    >
                        {item}
                        <div className='targated-value-removed' onClick={() => removeKeyword(item)}>
                            <Image src={close} />
                        </div>
                    </span>
                ))}
            </div>
        </div>

    );
}
