
import React, { useState } from "react";
import { useEffect } from 'react';

import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  AMC,
  API_LIMIT,
  CREATOR,
  START_PAGE,
  TARGETEDCONTENT,
  socialMedias,
} from "../../utils/Constant";
import {

  getDetailsApi,
  getSearchAllDataApi,
} from "./Api";
import {
  calculateTotalPageCount,
  formatNumber,
  getSearchParams,
} from "../../utils/ReusableFunctions";

import { Header, Footer, Pagination, NotFound } from "../common/pages";
import { ContentTypesCards } from "./_utils";

import BlackDownload from "../../assets/images/download-white.svg";
import BlackShare from "../../assets/images/black-share.svg";
import save2 from "../../assets/images/save-filed.svg";
import { SlideFilter } from "./staticComponents";
import { AllShimmer } from "../common/components";
import { Image } from "react-bootstrap";
import defaultIcon from "../../assets/images/default-grey.png";
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */

const validValues = [
  "AMC",
  'amc',
  "category",
  "campaigns",
  "languages",
  AMC,
  CREATOR,
  "targetedContents",

];
function ContentResult() {
  const history = useHistory();
  const { type } = useParams();
  const location = useLocation();
  const getParams = getSearchParams(location);
  const params = new URLSearchParams(location.search);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubLoading, setIsSubLoading] = useState(true);
  const [searchData, setSearchData] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    parseInt(getParams.page, 10) || START_PAGE
  );
  async function fetchData() {
    setIsSubLoading(true);
    const currentParams = location.search;
    if (type !== TARGETEDCONTENT && params.get("key") === socialMedias) {
      const isSortExist = currentParams?.includes('&sortBy')
      const data = await getDetailsApi(
        `/content/searchDetails${currentParams}${type === "All" ? "" : `&contentType=${type?.replace("_", "/")}`}${(params.get("sortBy") && !isSortExist) ? `&sortBy=${params.get("sortBy")}` : ""}`
      );

      if (data.status === 200) {
        setData(data?.data?.data || []);
        setIsLoading(false);
        setIsSubLoading(false);
        setSearchData(false);
      }
    }
    // else if (type === TARGETEDCONTENT) {
    //   const data = await getCommonApi(`/targetedContent?pageNumber=${params.get("pageNumber")}&pageSize=${params.get("pageSize")}`);
    //   if(data.status===200){
    //     setData(data?.data?.data.list || []);
    //     setIsLoading(false);
    //   }

    // }
    else if (validValues.includes(params.get("key"))) {

      const isContentType = params.has('contentType');
      if (isContentType) {
        let existingValue = params.get('contentType');
        // params.set('contentType', `${existingValue},${type}`);
        params.set('contentType', `${existingValue}`);
      }

      const typeAllUrl = type === "All" ? "" : (isContentType) ? '' : `&contentType=${type?.replace("_", "/")}`;

      const data = await getDetailsApi(
        `/content/searchDetails?${params.toString()}${typeAllUrl}` + `${(params.get('sortBy') ?? false) ? '' : `&sortBy=${(params.get('searchValue') ?? false) ? "most relevant" : 'most recent'}`}`
      );
      setData(data?.data?.data || []);
      setIsLoading(false);
      setIsSubLoading(false);
      setSearchData(false);

    } else {
      let isMedia = location?.pathname.includes("/social-media");

      const data = await getSearchAllDataApi(
        isMedia ? "" : type === "All" ? "" : type === "E-Book" ? 'EBook' : type?.replace("_", "/"),
        currentParams + `${(params.get('sortBy') ?? false) ? '' : `&sortBy=${(params.get('searchValue') ?? false) ? "most relevant" : 'most recent'}`}`,
        isMedia ? type : ""
      );


      setData(data);
      setSearchData(true)


      setIsLoading(false);
      setIsSubLoading(false);
    }
  }
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [getParams.page, currentPage])
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    fetchData();
    setCurrentPage(getParams.page);
  }, [type, location.search, location.pathname, getParams.page]);
  const renderComponent = () => {

    if (((data?.contents?.length ?? 0) > 0) && type !== AMC && type !== TARGETEDCONTENT) {



      return (
        <>
          {params.get("key") !== null && params.get("key") !== socialMedias && type === "All" && <AmcDetailshead type={params.get("key")?.toLowerCase()} data={data} />}
          {params.get("key") === socialMedias && (
            <div className="socialMedias-main-header">
              <h5 className="lato-regular   fs-32-24 black-242">
                {decodeURIComponent(params.get("value").replace(/\w/, (a) => a.toUpperCase()) ?? "NaN") || "Na"} &nbsp;&nbsp;&nbsp;&nbsp;
                <span className="lato-regular fs-14-12">
                  {formatNumber(data.length)}
                </span>
              </h5>
            </div>
          )}
          {/* {(Array.isArray(data) ? data : data.contents)?.map((item, j) => { */}
          {(data.contents)?.map((item, j) => {

            if (item.list?.length > 0) {
              return (
                <div>
                  <ContentTypesCards
                    key={j}
                    countTitle={item?.title}
                    hide={data?.contents?.length === 1 ? false : true}
                    // wrapperadd={data.length === 1 ? `result-wrap ${item.list?.length>=3 && "justify-content-between"}` : ""}
                    wrapperadd={data?.contents?.length === 1
                      ? `result-wrap ${item.list?.length >= 3 && ""}`
                      : ""
                    }
                    data={item.list}
                    count={item.count}
                    typeId={item?.uuid}
                    bannerAds={data?.bannerAds}
                    code={item.code}
                    isLoading={isSubLoading}
                    type={type}
                  />
                  {
                    j < data?.contents.length && data?.contents.length !== 1 &&
                    (j + 1) % 2 == 0 && Math.floor(j / 2) < 2 && data?.bannerAds && data?.bannerAds.length > 0 && data?.bannerAds?.[Math.floor((j / 2))]?.bannerImagePath &&
                    <a rel="noreferrer" className="banner-main-wrapper" href={data?.bannerAds[Math.floor(j / 2)]?.redirectionLink} target="_blank">

                      <Image src={data?.bannerAds[Math.floor((j / 2))]?.bannerImagePath} alt="banner Image" />
                    </a>
                  }
                </div>
              );
            }
            return null;
          })}
          {/* </div> */}
        </>
      );
    } else {

      return (
        <div>
          {params.get("key") !== null && params.get("key") !== socialMedias && type === "All" ? (<>
            <AmcDetailshead type={params.get("key")} data={data} />
            {
              ((data?.contents?.length ?? 0) === 0) && <NotFound type="target" />
            }

          </>
          ) : (
            <NotFound />
          )}
          {/* <NotFound /> */}
        </div>
      );
    }
  };

  const searchParams = getSearchParams(location);
  const totalPage = calculateTotalPageCount(data && (((data?.contents?.length) ?? 0) !== 0) ? data?.contents[0].count : 10);

  return (
    <div className="content-result-page page-padding-filter">
      <Header />
      <>
        {isLoading ? (
          <AllShimmer type={type === "amc" ? null : type} />
        ) : (
          <SlideFilter
            dataLength={data && ((data?.contents?.length) ?? 0)}
            component={renderComponent()}
            isFilter={type === TARGETEDCONTENT ? false : true}
          />
        )}
        {/* {data[0]?.count > API_LIMIT && data.length < 2 && ( */}
        {data && ((data?.contents?.length) ?? false) && data?.contents[0].count > API_LIMIT && data.contents.length < 2 && (
          <Pagination
            history={history}
            location={location}
            page={currentPage}
            pageCount={totalPage}
          />
        )}
      </>
      <Footer />
    </div>
  );
}

export default ContentResult;

function AmcDetailshead({ type, data }) {
  // console.log("Text keys", type.toLowerCase());
  const location = useLocation();
  const params = new URLSearchParams(location.search)
  // const [amcCardData, setAmcCardData] = useState([]);
  return (
    <div className="">
      <div className="amc-details-heading-wrapper">
        <div className="amc-details-profile-image">
          {type.toLowerCase() !== 'languages' && data?.circleImagePath &&
            <span className="amc-detail-profile dt-show">
              <Image
                src={data?.circleImagePath || defaultIcon}
                alt="profile-Image"
                className="profile-Image"
              />
            </span>
          }

          <div>
            <h4 className=" lato-semibold fs-32-24 black-242">
              {type.toLowerCase() !== 'languages' && data?.circleImagePath &&
                <span className="amc-profile-img-mb  mb-inline-block">
                  <Image
                    src={data?.circleImagePath || defaultIcon}
                    alt="profile-Image"
                    className="profile-Image amc-profile-img-mb mr-3"
                  />
                </span>
              }
              {data ? (data[type?.toLowerCase() + "Name"] || decodeURIComponent(params.get("value")).replace(/\w/, (a) => a.toUpperCase())) : decodeURIComponent(params.get("value") || "Na").replace(/\w/, (a) => a.toUpperCase())}
            </h4>
            <div className="content-type-data">
              {Object.keys(data?.contentTypeStats || {})?.map(
                (item, i) => {
                  if (item == 'totalContentCount') {
                    return ''
                  }
                  return (
                    <>
                      <p className=" lato-regular f-s-12 lh-16 black-242">
                        {item}{" "}
                        {formatNumber(data?.contentTypeStats[item])}
                      </p>
                      <span
                        className={`virtical-strip ${Object.keys(data?.contentTypeStats)
                          .length ===
                          i + 1 && "d-none"
                          }`}
                      ></span>
                    </>
                  );
                }
              )}
            </div>
          </div>
        </div>
        {type.toLowerCase() === "campaign" && (
          <div className="download-share-icon ">
            <span className="icon-fram">
              <Image src={save2} alt="download icon" className="save-icon" />
            </span>
            <span className="icon-fram">
              <Image
                src={BlackShare}
                className="share-icon"
                alt="download icon"
              />
            </span>
            <div className="primary-btn d-flex align-items-center">
              <Image src={BlackDownload} alt="download icon" />
              &nbsp; Download{" "}
            </div>
          </div>
        )}
      </div>

      <p className=" lato-regular fs-16-14 black-242 op-6">
        {data?.description || ""}
      </p>
      {data?.bannerPath &&
        <Image
          src={data?.bannerPath}
          alt="image"
          className="amc-details-main-image"
        />
      }
    </div>
  );
}
