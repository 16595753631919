
import { Image, InputGroup, FormControl, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import RadioFields from '../tools/components/RadioFields';
import CrossBtn from "../../assets/images/icon/cross-btn.svg";

import { getAuthDataApi } from "../home/Api";
import { toast } from "react-toastify";
import { postLoginInstance } from "../../utils";
import { getUser, isResOk } from '../../utils/ReusableFunctions';
import CrossIcon from "../../assets/images/icon/cross-modal-icon.svg"

import { useBroadcaster } from "./_utils/useBroadcaster";
import { CustomModal } from "../common/pages";
import { scheduleAuthPostApi } from "../planner/Api";
/* eslint-disable eqeqeq */




export default function SocialContent({ show, setIsConnected, handleGetScheduled }) {
  const user = getUser() || {};
  const [contact, setContact] = useState("same");

  const [platformData, setPlatformData] = useState([]);
  const [userDetails, setUserDetails] = useState();
  const [platforms, setPlatforms] = useState()
  const [whatsappForm, setWhatsappForm] = useState(false)
  const [whatsappNumber, setWhatsappNumber] = useState(user.mobileNumber || "")
  const [whatsappUuid, setWhatsappUuid] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [disconnectOpen, setDisconnectOpen] = useState(false);
  const [deleteUuid, setDeleteUuid] = useState();

  const handleCheckboxChange = () => {

    setIsChecked(!isChecked);
  };
  const handleClear = () => {
    setWhatsappNumber('');
  };

  const { message } = useBroadcaster();

  useEffect(() => {

    if (message !== null) {


      if (message.payload) {
        userPlatforms();

      }
      else {
        toast.error("Unable to connect social Media");
      }
    }


  }, [message]);
  const handleModalClose = (() => {
    setDisconnectOpen(false);
  })

  const handleConnect = async (social) => {
    if (social?.platformName.toLowerCase() == "whatsapp") {
      setWhatsappForm(true);
      setWhatsappUuid(social?.platformUuid);
    } else {
      const res = await getAuthDataApi(`website/auth/platform/${social?.platformName.toLowerCase()}/signin`);

      if (res) {
        window.open(res?.signInUrl, "Popup", "location,status,scrollbars,resizable,width=800,height=800");


      }
    }
  };




  const handleWhatsApp = async () => {
    const data = {
      data: {
        platform: {
          uuid: whatsappUuid
        },
        mobileNumber: whatsappNumber
      }
    }

    const res = await scheduleAuthPostApi(`website/userPlatform`, data);
    if (isResOk(res?.status)) {
      toast.success("Added Successsfully")
      userPlatforms()
      getUserPlatforms()
      handleCancel()
      handleGetScheduled()

    }


  };

  const selectOption = [
    { value: "same", label: `Use the contact number you verified Thefinpedia: ${user?.mobileNumber}` },
    { value: "different", label: "Use a different contact number" },
  ];


  const userPlatforms = async () => {
    const res = await getAuthDataApi("website/platform?pageSize=10&pageNumber=1");
    if (res) {
      let customizedData = res?.list?.map(platform => ({
        platformName: platform?.platformName,
        iconImagePath: platform.iconImagePath,
        socialMediaUsername: "",
        socialMediaProfilePic: "",
        isVerified: false,
        platformUuid: platform?.uuid

      }));

      // setPlatformData(customizedData);


      const user = await getAuthDataApi("website/userPlatform/user");
      if (user) {
        const platforms = user?.map((item) => item);
        setPlatforms(platforms);


        // Update customizedData with user platform information
        customizedData = customizedData.map(customData => {
          const userPlatform = platforms.find(
            platform => platform?.platform.platformName == customData.platformName
          );
          if (userPlatform) {

            return {
              ...customData,
              socialMediaUsername: userPlatform.socialMediaUsername,
              socialMediaProfilePic: userPlatform.socialMediaProfilePic,
              isVerified: true,
              uuid: userPlatform.uuid
            };
          }
          return customData;
        });

        // Update state with the modified customizedData
        setPlatformData(customizedData);




      }
    }

  }
  const handleClick = () => {
    window.open('https://wa.link/ykgujr', '_blank');
  };
  const getUserPlatforms = async () => {
    const user = await getAuthDataApi("website/userPlatform/user");

    if (user?.length > 0) {
      setIsConnected(true)
    }
    else {
      setIsConnected(false)
    }
  }
  useEffect(() => {
    userPlatforms();

  }, []);
  const handleDisconnectOpen = (uuid) => {
    setDeleteUuid(uuid)
    setDisconnectOpen(true);
  }

  const handleDisconnect = async (deleteUuid) => {
    try {
      const response = await postLoginInstance.delete(`website/userPlatform/${deleteUuid}`);
      if (response) {
        userPlatforms();
        getUserPlatforms();
        handleModalClose();
        toast.success("Disconnected Succesfully");
        handleGetScheduled();
      }
      return response;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleCancel = (() => {
    setWhatsappForm(false);
  })

  return (
    <div className="my-socials-content">
      <h6 className="lato-regular fs-18-14 black-242 mt-4">
        Connect your social media handles here with confidence! Your information is encrypted, ensuring your credentials remain safe. Rest assured, we never have direct access to your login details. Your privacy and security are our top priorities.
      </h6>
      <div>
        <h5 className="lato-regular fs-24-18 black-242">Connect your socials</h5>
        <div className="my-social-cards d-flex flex-row flex-wrap justify-content-md-between">
          {platformData?.map((social, index) => (

            social?.isVerified ?
              <div key={index} className="my-socials-options d-flex flex-column justify-content-between align-items-center">
                <div className="d-flex flex-row">
                  <Image className="social-media-image" height={40} width={40} src={social?.socialMediaProfilePic} />
                  <div className="d-flex justify-content-center align-items-center icon-background-connect">
                    <Image height={18} width={18} src={social?.iconImagePath}></Image>
                  </div>
                </div>
                <h4 className="lato-semibold fs-14-12 black-242">{social?.platformName}</h4>
                <h4 className="social-username lato-semibold fs-14-12 black-4">{social?.socialMediaUsername}</h4>
                <p style={{ cursor: "pointer" }} onClick={() => { handleDisconnectOpen(social?.uuid) }} className=" disconnect-option lato-semibold fs-14-12 primary">Disconnect</p>
              </div>
              :
              <div key={index} className="my-socials-options d-flex flex-column justify-content-between align-items-center">
                <div className="icon-background-disconnect">
                  <Image height={18} width={18} src={social?.iconImagePath}></Image>
                </div>
                <h4 className="lato-semibold fs-14-12 black">{social?.platformName}</h4>
                <p style={{ cursor: "pointer" }} onClick={() => handleConnect(social)} className="lato-semibold fs-14-12 primary">Connect</p>
              </div>
          ))}
        </div>
      </div>
      {whatsappForm &&
        <>
          <div className="whatsapp-details">
            <div className="d-flex flex-row justify-content-between">
              <p className="lato-regular fs-24-18 black-242">Add WhatsApp Number</p>
              <Image style={{ cursor: "pointer" }} src={CrossIcon} alt="icon" onClick={handleCancel} />
            </div>
            <h6 className="lato-regular fs-14-12 primary"><span className="lato-bold fs-14-12 primary">Note:</span> All the scheduled posts will be shared with you on this WhatsApp number.</h6>
          </div>

          <RadioFields
            options={selectOption}
            initialValue={"same"}
            onSelectionChange={(v) => { setContact(v) }}
            type="column"
          />
          {contact === "different" &&
            <div className="col-12 col-md-4">
              <label className="lato-semibold fs-18-14 black-242">Enter WhatsApp Number</label>
              <InputGroup className="custom-input-group">
                <FormControl
                  value={whatsappNumber}
                  onChange={(e) => setWhatsappNumber(e.target.value)}
                  className="input-with-image"
                />
                {whatsappNumber && (
                  <img
                    src={CrossBtn}
                    alt="clear"
                    className="clear-icon "
                    onClick={handleClear}
                  />
                )}
              </InputGroup>
            </div>
          }
          <div className="consent-bar d-flex flex-column">
            <div className="d-flex align-items-center">
              <input
                type="checkbox"
                checked={isChecked}
                // id={`consentCheck${index}`}
                onChange={handleCheckboxChange}
                className="mr-2 "
              />

              <span className="lato-regular fs-16-14 black-242 mb-2">Consent Required</span>
            </div>
            <span style={{ marginLeft: "2rem" }} className="lato-regular fs-14-12 black-242 op-5 ">Please confirm that you want to receive messages from Thefinpedia over WhatsApp.<br />
              By clicking 'send', you will be redirected to Whatsapp web to Opt-In for Post Planner services directly on WhatsApp.</span>
            <div className="d-flex justify-content-end">


              <Button className="primary-btn" onClick={handleClick} disabled={!isChecked} >Send</Button>


            </div>

          </div>
          <div className="my-social-tab-btn d-flex justify-content-center">
            <Button style={{ cursor: "pointer" }} onClick={handleWhatsApp} className="primary-btn lato-medium fs-14-12">Save Changes</Button>
          </div>
        </>
      }

      <CustomModal
        show={disconnectOpen}
        onHide={handleModalClose}
        modalClass={"disconnect-modal"}
      >
        <div className=" heading d-flex justify-content-between mb-4">
          <p className=" lato-bold fs-20-16 primary"> Confirm Disconnect</p>
          <Image src={CrossIcon} className='close-icon model-icon-close' alt='icon' style={{ cursor: "pointer" }} onClick={handleModalClose} />
        </div>
        <p className="lato-medium fx-14 black-242 op-5">Disconnecting will stop all scheduled posts for this social media platform from being published. You can reconnect at any time. You will not lose any scheduled content until you discontinue scheduled planner.</p> <br />
        <div className=" disconnect-btns d-flex flex-row justify-content-end">
          <Button onClick={handleModalClose} className="secondary-btn">Cancel</Button>
          <Button onClick={() => { handleDisconnect(deleteUuid) }} className="primary-btn">Disconnect</Button>

        </div>



      </CustomModal>
    </div>
  );
}