import React from "react";
import { useState } from "react";
import { Image, Modal } from "react-bootstrap";
import close from "../../../assets/images/cancel-icon.svg";
import { toast } from "react-toastify";
export default function AddCollectionModal({ show, click, method = "post", updated, id = "", collectionName = "", data = [] }) {
  const [updateCollection, setUpdateCollection] = useState(collectionName);
  function updateCollectionHandler() {
    let info2 = {
      "data": {
        "name": updateCollection
      }
    }
    const isMatch = data?.some((item) => item.name === updateCollection);
    if (!isMatch) {
      updated(id, info2, method);
      click()
    } else {
      toast.error("This Collection Name Exist")
    }
  }
  return (
    <div>
      {" "}
      <Modal
        className="save-confirm-collection-custom-model"
        show={show}
        onHide={click}
        centered

      >
        <Modal.Body className="save-confirm-collection-model-body">
          <Image
            src={close}
            onClick={click}
            alt="canle icon"
            className="close-btn"
          />
          <input
            type="text"
            className="input-box-search-collection add-collection-input-box fs-18-14 lato-semibold black-242 op-5 w-100"
            value={updateCollection}
            onChange={(e) => setUpdateCollection(e.target.value)}
            name="updateCollection"
            placeholder="Enter Collection Name"
          />
          <div className="d-flex justify-content-center ">
            <div
              className={`primary-btn py-3 pointer`}
              onClick={updateCollectionHandler}
            >
              {method === "post" ? "Add Collection" : "Update Collection"}

            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
