import React, { useState } from 'react'
import { Footer, Header } from '../common/pages'
import { Button, Image } from 'react-bootstrap'
import cancle from '../../assets/images/input-cancle-image.svg';
import Phone from '../../assets/images/Phone-contact.svg';
import Location from '../../assets/images/Location-contact.svg';
import Mail from '../../assets/images/Mail-contact.svg';
import { toast } from 'react-toastify';
import { commonAuthPostApi } from './Api';
import { isResOk } from '../../utils/ReusableFunctions';
import { scheduleAuthPostApi } from '../planner/Api';
import { Spinner } from '../common/components';
export default function ContactUs() {
  const [contInfo, setContactInfo] = useState({ name: '', email: "", reason: "", message: "" });
  const { name, email, reason, message } = contInfo;
  const [loading, setLoading] = useState(false);

  async function submitHandler(e) {
    setLoading(true);
    e.preventDefault();
    const payload = {
      data: {
        name: name,
        email: email,
        reasonForContact: reason,
        message: message,
      },
    };

    try {
      const res = await scheduleAuthPostApi('user/contact-us', payload);

      if (isResOk(res?.status)) {
        toast.success('Submitted Successfully!');
        setContactInfo({ name: '', email: "", reason: "", message: "" });
        setLoading(false);
      }
    } catch (error) {
      console.error('Error submitting the contact form:', error);
      setLoading(false);

    }
  }
  function onchangHandler(e) {
    setContactInfo({ ...contInfo, [e.target.name]: e.target.value });
  };
  const isFormValid = name && email && reason && message;

  const reSetName = (ele) => setContactInfo({ ...contInfo, [ele]: '' })
  return (
    <div>
      <Header />
      <div className="contact-us-section">
        <div className="w-100">
          <div className="contact-us-wrapper w-100">
            <div className="left-wrapper">
              <div className="contact-form-frame">
                <h4 className='lato-regular fs-32-18 black-242'>Contact Us</h4>
                <p className='lato-semibold fx-16 black-242'>We love hearing from you! Contact us for any inquiries or comments – <br /> we're here to help!</p>
                <form className='contacr-form' onSubmit={submitHandler} >
                  <div className="contact-input-frame">
                    <input type="text" required className='input-box fx-16 lato-regular' placeholder='Name' value={name} name='name' onChange={onchangHandler} />
                    {name &&
                      <Image src={cancle} onClick={() => reSetName('name')} alt="cancle" className="input-cancle-img pointer" />
                    }
                  </div>
                  <div className="contact-input-frame">
                    <input type="email" required className='input-box fx-16 lato-regular' placeholder='E-mail' value={email} name='email' onChange={onchangHandler} />
                    {
                      email &&
                      <Image src={cancle} alt="cancle" onClick={() => reSetName('email')} className="input-cancle-img pointer" />
                    }
                  </div>
                  <div className="contact-input-frame">
                    <input type="text" className='input-box fx-16 lato-regular' placeholder='Reason for contact' value={reason} name='reason' onChange={onchangHandler} />
                    {
                      reason &&
                      <Image src={cancle} alt="cancle" onClick={() => reSetName('reason')} className="input-cancle-img " />}
                  </div>
                  <textarea className='contact-text-area  fx-16 lato-regular' placeholder='Message' id="" cols="30" rows="6" value={message} name='message' onChange={onchangHandler}></textarea>
                  <div className="contact-submit-btn">
                    <Button className="primary-btn " type='submit' disabled={!isFormValid || loading}>
                      {loading ? <span className=' d-flex align-items-center gap-12'><Spinner /> Submitting..</span> : 'Submit'}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
            <div className="contact-info-frame">
              <ul className='conteact-info-wrapper'>
                <li className=' lato-regular fx-16 black-242'><Image src={Location} alt='image' /> 7th Floor, Merchant Chambers, Vitthaldas Thackersey Marg, New Marine Lines, Churchgate, Mumbai, Maharashtra 400020</li>
                <li className=' lato-regular fx-16 black-242'><Image src={Phone} alt='image' /> <a className=' lato-regular fx-16 black-242' href="tel:+919769399959"> +91 9769399959</a></li>
                <li className=' lato-regular fx-16 black-242'><Image src={Mail} alt='image' /> <a className=' lato-regular fx-16 black-242' href="mailto:info@thefinpedia.com">info@thefinpedia.com</a></li>
              </ul>

            </div>
            {/* <textarea className='contact-text-area  fx-16 lato-regular' value={massage} onChange={contactChangeHandler} name='massage' maxLength={250} placeholder='Message' id="" cols="30" rows="6"></textarea>
                  <div className="contact-submit-btn">
                    <Button className="primary-btn " type='submit'>
                      Submit
                    </Button>
                  </div> */}
            {/* </form> */}
          </div>
        </div>
      </div>
      {/* </div>
      </div > */}
      <Footer />
    </div >
  )
}
