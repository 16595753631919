import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import arrowNext from "../../assets/images/Arrow_next.svg";
import arrowPre from "../../assets/images/Arrow_pre.svg";
import { navigateSetUrl } from "../../utils/ReusableFunctions";
import { START_PAGE } from "../../utils/Constant";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function Pagination(props) {

  const [pageNo, setPageNo] = useState(parseInt(props.page, 10))
  const history = useHistory()
  const location = useLocation()


  useEffect(() => {
    setPageNo(props.page)
  }, [props.page])
  const onLeftClick = (props) => {
    if (pageNo > 1) {
      const params = new URLSearchParams(location.search);
      params.set("pageNumber", parseInt(pageNo, 10) - 1);
      setPageNo(pageNo - 1)
      navigateSetUrl(history, history.pathname, params.toString())
      // ?*

    }
  };
  const onValueInput = (e) => {
    let no = e.target.value;
    setPageNo(no > 0 ? no : START_PAGE);

    if (no > 0) {
      const params = new URLSearchParams(props.location.search);
      params.set("pageNumber", parseInt(no, 10));
      navigateSetUrl(props.history, props.history.pathname, params.toString())
    }
  }

  const onNextClick = (props) => {

    if (pageNo < props.pageCount) {
      const params = new URLSearchParams(props.location.search);
      params.set("pageNumber", parseInt(pageNo, 10) + 1);
      setPageNo(parseInt(pageNo, 10) + 1)
      navigateSetUrl(props.history, props.history.pathname, params.toString())
    }
  };
  const onInputWheel = (e) => {
    e.preventDefault();
  };
  return (
    <div className="pagination-section">
      <Image
        onClick={() => onLeftClick(props)}
        src={arrowPre}
        className={`pagination-pre-icon pointer ${props.page <= 1 ? "disable-arrow" : ""}`}
        alt="back_icon"
      />
      <div className="pagination-count f-s-14 lato-medium black-242">
        Page
        {/* <span className="pagination-item pointer"> */}

        <input className="pagination-item pointer" onWheel={(e) => e.target.blur()} type="number" value={pageNo || props.page} onChange={onValueInput} />
        {/* </span> */}
        of {props.pageCount}
      </div>
      <Image
        onClick={props.page < props.pageCount ? () => { onNextClick(props) } : null} // Disable onClick when on the last page
        src={arrowNext}
        className={`pagination-next-icon pointer ${props.page >= props.pageCount ? "disable-arrow" : ""}`} // Add disabled class if on the last page
        alt="next_icon"
      />
    </div>
  );
}

