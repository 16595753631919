import React from 'react';
import { toast } from 'react-toastify';
import {
  BaseReactComponent, CustomTextControl, Form, FormElement, FormSubmitButton, FormValidator
} from '../../utils/form';
import { resetPasswordApi } from './Api';
import { CustomModal } from '../common/pages';
import PasswordHide from "../../assets/images/password-hide.svg";
import PasswordShow from "../../assets/images/password-show.svg";
import { Image } from 'react-bootstrap';

class ResetPassword extends BaseReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      inProgress: false,
      token: props.show ? props.show : "",
      newPassword: '',
      confirmPassword: '',
      showPassword: false,
      showConfirmPassword: false,
      passwordError: '',
      confirmPasswordError: '',
    };
  }

  validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
    return passwordRegex.test(password);
  };

  handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    const isPasswordValid = this.validatePassword(newPassword);
    let passwordError = '';

    if (newPassword.length === 0) {
      passwordError = 'Field cannot be empty';
    } else if (!isPasswordValid) {
      passwordError = 'Password must be at least 8 characters, contain 1 capital letter, 1 lowercase letter, 1 number, and 1 special character';
    }

    this.setState({
      newPassword,
      isPasswordValid,
      passwordError,
    });
  };

  handleConfirmPasswordChange = (e) => {
    const confirmPassword = e.target.value;
    const isConfirmPasswordValid = confirmPassword === this.state.newPassword;
    let confirmPasswordError = '';

    if (confirmPassword.length === 0) {
      confirmPasswordError = 'Field cannot be empty';
    } else if (!isConfirmPasswordValid) {
      confirmPasswordError = 'Passwords do not match';
    }

    this.setState({
      confirmPassword,
      isConfirmPasswordValid,
      confirmPasswordError,
    });
  };

  onSubmit = () => {
    if (this.state.passwordError || this.state.confirmPasswordError) {
      toast.error("Please fix the errors before submitting.");
      return;
    }

    const data = {
      data: {
        "newPassword": this.state.newPassword,
      }
    };
    resetPasswordApi(this, data);
  };

  render() {
    const { passwordError, confirmPasswordError, newPassword, confirmPassword, showPassword, showConfirmPassword } = this.state;
    const isFormValid = !passwordError && !confirmPasswordError && newPassword && confirmPassword;

    return (
      <CustomModal
        show={this.props.show}
        onHide={this.props.handleClose}
        modalClass={"forgot-password-modal"}
      >
        <div className="login-wrapper">
          <div className="login-content">
            <h1 className="red-hat-display-bold f-s-24">Set Password</h1>
            <Form onValidSubmit={this.onSubmit}>
              <div className='pos-rel'>
                <FormElement
                  valueLink={this.linkState(this, "newPassword")}
                  label="Password"
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "Field cannot be empty"
                    },
                    {
                      validate: () => this.validatePassword(this.state.newPassword),
                      message: "Password must be at least 8 characters, contain 1 capital letter, 1 lowercase letter, 1 number, and 1 special character"
                    }
                  ]}
                  control={{
                    type: CustomTextControl,
                    settings: {
                      placeholder: "Enter Password",
                      type: showPassword ? "text" : "password",
                    }
                  }}
                />
                <Image
                  src={showPassword ? PasswordShow : PasswordHide}
                  className='password-hide pos-abs pointer'
                  onClick={() => this.setState({ showPassword: !showPassword })}
                />
                {passwordError && <div className="validation-error">{passwordError}</div>}
              </div>
              <div className='pos-rel'>
                <FormElement
                  valueLink={this.linkState(this, "confirmPassword")}
                  label="Confirm Password"
                  required
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "Field cannot be empty"
                    },
                    {
                      validate: () => this.state.confirmPassword === this.state.newPassword,
                      message: "Passwords do not match"
                    }
                  ]}
                  control={{
                    type: CustomTextControl,
                    settings: {
                      placeholder: "Enter Password",
                      type: showConfirmPassword ? "text" : "password",
                    }
                  }}
                />
                <Image
                  src={showConfirmPassword ? PasswordShow : PasswordHide}
                  className='password-hide pos-abs pointer'
                  onClick={() => this.setState({ showConfirmPassword: !showConfirmPassword })}
                />
                {confirmPasswordError && <div className="validation-error">{confirmPasswordError}</div>}
              </div>
              <FormSubmitButton customClass="primary-btn" disabled={!isFormValid}>
                Reset Password
              </FormSubmitButton>
            </Form>
          </div>
        </div>
      </CustomModal>
    );
  }
}

export default ResetPassword;

