import React, { useEffect, useState } from 'react'
import { Button, Image } from 'react-bootstrap'
import { getCommonApi } from '../home/Api'
import { useHistory, useLocation } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import clare from "../../assets/images/cancel-icon.svg";
import { MAX_LIMIT, START_PAGE, TARGETEDCONTENT } from '../../utils/Constant';


export default function TargetedContentFilter() {
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [filterObj, setFilterObj] = useState({});
  async function featchedData() {

    const res = await getCommonApi(`content/filter?key=targetedContent`);
    if (res.status === 200) {
      let filterData = res.data.data?.find(item => item.code === 'TARGETED_CONTENT');
      setData(filterData || []);
    }
  }
  useEffect(() => {
    featchedData()
  }, [])



  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const excludedKeys = ['key', 'value', 'pageNumber', 'pageSize', 'searchValue', 'sortBy', 'order'];

    const filterObjUrl = {};
    params.forEach((value, key) => {
      if (!excludedKeys.includes(key)) {
        filterObjUrl[key] = value.split(',');
      }
    });

    setFilterObj(filterObjUrl)


  }, [location.search]);

  function targetfilterChangeHandler(e, item, title) {
    const newState = { ...filterObj };

    if (newState[title]) {
      const itemIndex = newState[title].indexOf(item);
      if (itemIndex !== -1) {
        newState[title].splice(itemIndex, 1);
        if (newState[title].length === 0) delete newState[title];
      } else {
        newState[title].push(item);
      }
    } else {
      newState[title] = [item];
    }

    const keyValues = Object.keys(newState).map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(newState[key].join(','))}`;
    });

    history.push({
      pathname: `/${TARGETEDCONTENT}${keyValues.length === 0 ? '' : '/filter'}`,
      search: keyValues.join('&') + `&pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}`
    });

    setFilterObj(newState);
  }
  const cleareFilterHandler = () => {
    setFilterObj({})
    history.push({ pathname: `/${TARGETEDCONTENT}`, search: `pageNumber=${(START_PAGE)}&pageSize=${MAX_LIMIT}` });
  }


  return (
    <div className="targeted-main-filter-wrapper ">
      <div className={`targeted-content-filter ${Object.keys(filterObj).length > 0 ? 'w-89' : 'w-100'} `}>
        {/* <Slider {...settings} currentSlide={1}> */}
        {data.items?.map((item, i) => {
          return (
            <CustomDropDown data={item} targetfilterChangeHandler={targetfilterChangeHandler} filterObj={filterObj} />
          )
        })}
        {/* </Slider> */}

      </div>
      {Object.keys(filterObj).length > 0 &&
        <div onClick={cleareFilterHandler} className={`targeted-clear-bth ${Object.keys(filterObj).length > 0 ? 'active-bg' : ''}`}>Clear <Image src={clare} className='' /></div>
      }
    </div>
  )
}

// function CustomDropDown({ data, targetfilterChangeHandler }) {
//     const [show, setShow] = useState(false);
//     const location = useLocation();
//     const params = new URLSearchParams(location.search);

//     return (
//         <>
//             <div className={`target-item-dropdown  ${show ? 'zindex-6' : ''}`}>
//                 <Button
//                     onClick={() => setShow(prevState => !prevState)} // Use functional form of setState
//                     className={`dropdown-toggle lato-medium f-s-14 lh-20 ${(params.get((data.title.replace(/\s/g, '')).replace(/\w/, (a) => a.toLowerCase())) ?? false) ? 'active-bg' : ''}`}
//                     id={data.title}
//                 >
//                     {data.title}
//                 </Button>
//                 <ul className={`dropdown-menu ${show ? 'show-menu' : ''} `} >
//                     {
//                         data.types?.map((item, i) => (
//                             <li key={i} className=' lato-regular'>
//                                 <input
//                                     type="checkbox"
//                                     onChange={(e) => targetfilterChangeHandler(e, item, (data.title.replace(/\s/g, '')).replace(/\w/, (a) => a.toLowerCase()))}
//                                     name=""
//                                     className='targeted-chekbox'
//                                     id={item + i}
//                                 />
//                                 <label className='w-100 pointer m-0' htmlFor={item + i}>{item}</label>
//                             </li>
//                         ))
//                     }
//                 </ul>
//             </div>
//             <div onClick={() => setShow(false)} className={show ? "dropdown-baground-wrapper" : "d-none"}> </div>
//         </>
//     )
// }

// ?---------- PRE START--
function CustomDropDown({ data, targetfilterChangeHandler, filterObj }) {
  const [show, setShow] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);


  const isChecked = (item, title) => {
    const formattedTitle = (title.replace(/\s/g, '')).replace(/\w/, (a) => a.toLowerCase());
    return filterObj[formattedTitle] && filterObj[formattedTitle].includes(item);
  };

  return (
    <>
      <div className={`target-item-dropdown  ${show ? 'zindex-6' : ''}`}>
        <Button onClick={() => setShow(!show)} className={`dropdown-toggle lato-medium f-s-14 lh-20 ${(params.get((data.title.replace(/\s/g, '')).replace(/\w/, (a) => a.toLowerCase())) ?? false) ? 'active-bg' : ''}`} id={data.title}>
          {data.title}
        </Button>
        <ul className={`dropdown-menu ${show ? 'show-menu' : ''} `} >
          {
            data.types?.map((item, i) => {
              return (
                <li key={i} className='lato-regular'>
                  <input
                    type="checkbox"
                    onChange={(e) => targetfilterChangeHandler(e, item, (data.title.replace(/\s/g, '')).replace(/\w/, (a) => a.toLowerCase()))}
                    checked={isChecked(item, data.title)}
                    className='targeted-chekbox'
                    id={item + i}
                  />
                  <label className='w-100 pointer m-0' htmlFor={item + i}>{item}</label>
                </li>
              )
            })
          }
        </ul>
      </div>
      <div onClick={() => setShow(false)} className={show ? "dropdown-baground-wrapper" : "d-none"}> </div>
    </>
  )
}






