import React, { useEffect, useState } from "react";
import { Dropdown, Image } from "react-bootstrap";
import { AllShimmer, CardHeader } from "../../common/components";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {
  MAX_LIMIT,
  SORT_ARRAY,
  START_PAGE,

} from "../../../utils/Constant";
import {
  useHistory,
  useLocation,

} from "react-router-dom/cjs/react-router-dom.min";
import DownIcon from '../../../assets/images/dropdown-icon.svg';

import { Footer, Header, NotFound, Pagination } from "../../common/pages";
import { BreadCrumb } from "../_utils";
import { getCommonApi } from "../Api";
import { SearchFilter, TargetedContentFilter } from "../../common";
/* eslint-disable react-hooks/exhaustive-deps */


export default function TargetedContent() {
  const navigate = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [data, setData] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [filterData, setFilterData] = useState([]);
  const [sortBy, setSortBy] = useState("Max Content");
  const pageSize = 18;

  async function featchData(sortByArr = SORT_ARRAY[sortBy]) {
    window.scroll(0, 0);
    const data = await getCommonApi(`website/targetedContent${location.search === '' ? `?pageNumber=${(params.get("pageNumber") ?? START_PAGE)}&pageSize=${pageSize}` : location.search}`);

    if (data.status === 200) {
      setData(data?.data?.data.list || []);
      setDataLength(data?.data?.data.length || 0);
      setFilterData(data?.data?.data.list || []);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    featchData();


  }, [location.search]);

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10); // 3 seconds delay

    // Cleanup the timeout if the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const sortByParam = params.get("sortBy");
    const orderParam = params.get("order");

    let selectedSortBy = "Max Content"; // Default value

    // Iterate through the SORT_ARRAY to find the matching key
    Object.keys(SORT_ARRAY).forEach(key => {
      if (SORT_ARRAY[key][0] === sortByParam && SORT_ARRAY[key][1] === orderParam) {
        selectedSortBy = key;
      }
    });

    setSortBy(selectedSortBy);


  }, [])

  function renderHandler(name, item) {
    navigate.push({
      pathname: `/content-type/All`,
      search: `?key=targetedContents&value=${encodeURIComponent(name)}&pageSize=${pageSize || MAX_LIMIT}&pageNumber=${START_PAGE}`,
      state: item
    })
  }
  function sortHandler(data) {
    setSortBy(data);
    sessionStorage.setItem("targetedContentData", data)
    // featchData(SORT_ARRAY[data]);
    const sortByArr = SORT_ARRAY[data];

    navigate.push({
      pathname: `/targetedContent`,
      search: `?pageNumber=${START_PAGE}&pageSize=${pageSize}${sortByArr.length > 0 ? `&sortBy=${sortByArr[0]}&order=${sortByArr[1]}` : ''}`,
    })



  }
  function serchHandler(val) {
    val = val.trim();
    if (!val) {
      params.delete("searchValue");
      navigate.push({ search: params.toString() })
    } else {
      params.set("searchValue", val);
      params.set("pageNumber", 1);

      navigate.push({ search: params.toString() })
    }

  }

  return (
    <div className="page-padding ">
      <div className="container">
        <Header />
        {isLoading ?
          <AllShimmer type="amc" noContainer={true} /> :
          <>
            <BreadCrumb
              path={["Targeted Playlists"]}
            />

            <SearchFilter name="targetedPlaylists" placeholder="Targeted Playlists" clases="" changeValue={serchHandler} setSortBy={setSortBy} />
            <TargetedContentFilter />
            <div className="d-flex align-items-center justify-content-between">
              <CardHeader
                title={"Targeted Playlists"}
                count={dataLength}
                hide={false}
                countTitle={"Targeted Playlists"}
              // viewAll={`/content-type/${countTitle?.toLowerCase()}`}//change
              />
              <div className={`dropdown-main-wrapper w-auto`}>
                <Dropdown>
                  <Dropdown.Toggle className="dropdown-btn" id="dropdown-basic">
                    <h6 className="lato-semibold fx-14 black-242">{sortBy || 'Sort By'}</h6>
                    <Image src={DownIcon} alt="image" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="show-filter-dropdown">
                    {Object.keys(SORT_ARRAY)?.map((item, i) => {
                      return (
                        <Dropdown.Item key={i} onClick={() => sortHandler(item)} className="lato-regular black-242 fx-14" href=''>{item}</Dropdown.Item>
                      )
                    })}

                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <div className="targeted-content-card-wrapper ">
              {filterData?.length > 0 ? (
                <>

                  {filterData?.map((item, i) => {
                    return (

                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          renderHandler(item.targetedContentName, item);
                        }}
                        className={`${item?.thumbnailPath ? "target-imag-frame" : "target-imag-frame-p"} pointer p-0 responsive-card-target`}>
                        {item.thumbnailPath ?

                          <LazyLoadImage
                            src={item.thumbnailPath}
                            // onClick={() => renderHandler(item.targetedContentName, item)}
                            alt="icon"
                            effect="blur"
                            className="responsive-card-width targetec-content-image"

                          />
                          : <p className=" lato-medium fs-14-13">{item.targetedContentName}</p>}
                      </div>


                    );
                  })}

                </>
              ) : (
                <div className="target-content-not-found">
                  <NotFound type="target" />
                </div>
              )}
            </div>

            {dataLength > 10 &&
              <Pagination
                history={navigate}
                location={location}
                page={parseInt(params.get('pageNumber'))}
                pageCount={Math.ceil(dataLength / pageSize)}
              />
            }
          </>
        }
      </div>
      <Footer />

    </div>
  );
}
