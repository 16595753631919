import { fabric } from "fabric";

fabric.Canvas.prototype.initialize = function (i) {
  return function (...t) {
    return i.call(this, ...t), this._historyInit(), this;
  };
}(fabric.Canvas.prototype.initialize);

fabric.Canvas.prototype.dispose = function (i) {
  return function (...t) {
    return i.call(this, ...t), this._historyDispose(), this;
  };
}(fabric.Canvas.prototype.dispose);

fabric.Canvas.prototype._historyNext = function () {
  return JSON.stringify(this.toDatalessJSON(this.extraProps));
};

fabric.Canvas.prototype._historyEvents = function () {
  return {
    "object:added": (t) => this._historySaveAction(t),
    "object:removed": (t) => this._historySaveAction(t),
    "object:modified": (t) => this._historySaveAction(t),
    "object:skewing": (t) => this._historySaveAction(t),
  };
};

fabric.Canvas.prototype._historyInit = function () {
  this.historyUndo = [];
  this.historyRedo = [];
  this.extraProps = [
    "selectable",
    "editable",
    "disableHistory",
    "lockMovementX",
    "lockMovementY",
    "lockScalingX",
    "lockScalingY",
    "lockRotation",
  ]; // Ensure all lock and custom properties are included
  this.historyNextState = this._historyNext();
  this.on(this._historyEvents());
};

fabric.Canvas.prototype._historyDispose = function () {
  this.off(this._historyEvents());
};

fabric.Canvas.prototype._historySaveAction = function (event) {
  if (this.historyProcessing) return;

  if (event && event.target && event.target.disableHistory) {
    // Skip saving history for objects with disableHistory set to true
    return;
  }

  const nextState = this._historyNext();
  this.historyUndo.push(nextState);
  this.historyNextState = nextState;

  this.fire("history:append", { json: nextState });
};

fabric.Canvas.prototype.undo = function (callback) {
  this.historyProcessing = true;
  const lastState = this.historyUndo.pop();

  if (lastState) {
    this.historyRedo.push(this._historyNext());
    this.historyNextState = lastState;
    this._loadHistory(lastState, "history:undo", callback);
  } else {
    this.historyProcessing = false;
  }
};

fabric.Canvas.prototype.redo = function (callback) {
  this.historyProcessing = true;
  const nextState = this.historyRedo.pop();

  if (nextState) {
    this.historyUndo.push(this._historyNext());
    this.historyNextState = nextState;
    this._loadHistory(nextState, "history:redo", callback);
  } else {
    this.historyProcessing = false;
  }
};

fabric.Canvas.prototype._loadHistory = function (state, eventName, callback) {
  const self = this;

  this.loadFromJSON(state, function () {
    self.getObjects().forEach((obj) => {
      if (obj.type === "rect") {
        obj.toPlaceholder(); // Add placeholder behavior for rectangle
        obj.attachPlaceholder(self, self._offset); // Attach placeholder div
      } else if (obj.type === "circle") {
        obj.toPlaceholder(); // Add placeholder behavior for circle
        obj.attachPlaceholder(self, self._offset); // Attach placeholder div
      }
    });
    self.renderAll();
    self.fire(eventName);
    self.historyProcessing = false;
    if (callback && typeof callback === "function") callback();
  });
};

fabric.Canvas.prototype.clearHistory = function () {
  this.historyUndo = [];
  this.historyRedo = [];
  this.fire("history:clear");
};

fabric.Canvas.prototype.onHistory = function () {
  this.historyProcessing = false;
  this._historySaveAction();
};

fabric.Canvas.prototype.canUndo = function () {
  return this.historyUndo.length > 0;
};

fabric.Canvas.prototype.canRedo = function () {
  return this.historyRedo.length > 0;
};

fabric.Canvas.prototype.offHistory = function () {
  this.historyProcessing = true;
};
