import React, { useState, useEffect } from "react";
import { BreadCrumb } from "../home/_utils";
import close from "../../assets/images/cancel-icon.svg";
import InvoiceDownload from "../../assets/images/Invoice_Download_1.svg";
import Checked from "../../assets/images/greenCheck.png";
import unboxImage from "../../assets/images/Subscription-sucess.png";
import moment from "moment/moment";
import { commonAuthApis, commonPostApi, purchaseAmoutBreakup } from "./Api";
import { Button, Image, Modal } from "react-bootstrap";
import { NavLink, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SubscriptionPlanCard from "./SubscriptionPlanCard";
import { getToken } from "../../utils/ManageToken";
import { AlertModal, Footer, Header } from "../common/pages";
import { durationObj, durationObjName } from "../../utils/Constant";
import { getUser, getUserDetails, isResOk } from "../../utils/ReusableFunctions";
import { AllShimmer } from "../common/components";


function MySubscription(props) {
  const history = useHistory();

  const [planList, setPlanList] = useState([]);

  const [downloadRemains, setDownloadRemains] = useState({ membershipList: [], topUpList: [] });
  const { membershipList, topUpList } = downloadRemains;
  const [userBillHistory, setUserBillHistory] = useState([]);
  const [signup, setSignup] = useState();
  const [active, setActive] = useState(false);
  const [activeHistory, setActiveHistory] = useState(false);
  const [canceled, setCanceled] = useState(false);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [otherReason, setOtherReason] = useState('');
  const [loading, setLoading] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [resData, setResData] = useState({})
  const [alertData, setAlertData] = useState({});

  const [isTopupSubscription, setIsTopupSubscription] = useState(false);
  const [isBasicSubscription, setIsBasicSubscription] = useState(null);
  const [invoicePdfPath, setInvoicePdfPath] = useState(null);
  const [breakupAmount, setBreakupAmount] = useState({});
  const getUserBillingHistory = async () => {
    const url = 'user/billing-plan/history';
    if (userBillHistory.length === 0) {
      const res = await commonAuthApis(url, '', 'get');
      if (isResOk(res.status)) {
        // console.log(res)
        setUserBillHistory(res?.data?.data)
      }
    }

  }
  async function fetchData() {
    setLoading(true);
    const data = await commonPostApi(`user/billing-plan/available`, '', 'get');
    const membershipList = data?.data?.downloadRemaining.filter((ele) => !ele?.isTopup);
    const topUpList = data?.data?.downloadRemaining.filter((ele) => ele?.isTopup);
    setDownloadRemains({ membershipList: membershipList, topUpList: topUpList })
    setPlanList(data?.data);
    setLoading(false);


  }
  useEffect(() => {
    const showSucces = JSON.parse(sessionStorage.getItem('finSubscriptionDone'));
    // console.log(typeof(showSucces),showSucces)
    setSignup(showSucces);
    const isTopup = JSON.parse(sessionStorage.getItem('finSubscriptionIsTopup'));
    setIsTopupSubscription(isTopup);
    const isBasic = JSON.parse(sessionStorage.getItem('finSubscriptionIsBasic'));
    setIsBasicSubscription(isBasic);
    const invoiceLink = sessionStorage?.getItem('invoicePdf');
    setInvoicePdfPath(invoiceLink);



    fetchData();
  }, []);
  const userDetails = getUserDetails();
  const handleSubscription = (selectedPlan) => {


    const data = {
      "data": selectedPlan
    }
    if (getToken()) {
      purchaseAmoutBreakup(data, props.history, setIsAlert, setAlertData, setResData, breakupAmount, setBreakupAmount)

    } else {
      history.push('/onboarding');
    }


  }
  const handleClose = () => { setSignup(!signup); sessionStorage.removeItem('finSubscriptionDone'); sessionStorage.removeItem('finSubscriptionIsTopup'); sessionStorage.removeItem('finSubscriptionIsBasic'); sessionStorage.removeItem('invoicePdf'); }


  const handleCheckboxChange = (event) => {
    const reason = event.target.value;
    setSelectedReasons(reason)

  };

  const onHide = () => {
    setIsAlert(false)
  }

  const handleOtherReasonChange = (event) => {
    setOtherReason(event.target.value);
  };
  const reasonsList = [
    "I no longer need a subscription.",
    "I didn’t find valuable content",
    "I didn’t find value in features",
    "I experienced technical issues",
    "Others"
  ];
  const isCancelDisabled = selectedReasons.length === 0 || (selectedReasons.includes("Others") && otherReason.length === 0);

  const finalReason = selectedReasons.includes("Others") ? selectedReasons + " - " + otherReason : selectedReasons;
  const cancelSubsHandler = async (id) => {
    const data = {
      "data": {
        "cancellationReason": finalReason
      }
    }

    const res = await commonAuthApis(`user/billing-plan/${id}/cancel`, data, 'put');
    if (isResOk(res?.status)) {
      setOtherReason('');
      fetchData();
      setCanceled(true)

    }
  }

  const handleCancel = () => {
    setActive(!active);
    setSelectedReasons([]);
  }
  // ============================== CANCLATION MOCAL CODE END ======================================
  return (

    <div className="mySubscription-page">
      <Header />
      {signup &&
        <Modal className="signup-custom-model  mysubscription-custom-succes" show={signup} onHide={handleClose}>
          <Modal.Body className=" mysubscription-body-succes ">
            <Image src={close} onClick={handleClose} alt="canle icon" className="close-btn" />
            <h5 className='lato-semibold fs-32-24 primary'>{isBasicSubscription ? `Success! Your ${isBasicSubscription.charAt(0).toUpperCase() + isBasicSubscription.slice(1)} Plan is Active` : isTopupSubscription ? "Top Up successful" : "Subscription successful"}</h5>
            <h6 className='lato-regular fs-16-14 black-242 mt-3'>{isBasicSubscription ? "Dive in and make the most of your trial period." : isTopupSubscription ? "Maximize Your Experience with  Top-Up benefits!" : "Unbox exclusive premium membership benefits now!"} </h6>
            <Image src={unboxImage} alt="logo" className="box-img" />
            <Button onClick={() => { handleClose() }} className=" primary-btn explore-fin-btn">Go to My Membership</Button>
            <div className="my-subscription-buttons my-4">
              <a rel="noreferrer" href={invoicePdfPath} target="_blank" className=" fs-14-13 my-3 mx-auto lato-semibold primary secondary-btn pointer ">Download invoice</a>
            </div>
            <p onClick={() => { handleClose(); history.push({ pathname: '/' }); }} className="primary lato-semibold fs-14-13  explore-fin-btn pointer">Explore Thefinpedia</p>
          </Modal.Body>
        </Modal>
      }
      <div className="container">
        <BreadCrumb path={getToken() ? ["My Membership Plans"] : ["Pricing Plans"]} clasese="mb-3-desk" />
      </div>
      <div className="mySubscription-wrapper">
        <h5 className="lato-rgular fs-36-24 black-242 container ">{getToken() ? "My Membership Plans" : "Pricing Plans"}</h5>
        <div className="subscription-details bg-primary-05">
          {getToken() ?
            <div className="container">
              {true ?
                // {planList?.userSubscriptions?.length > 0 ?
                <div className="plan-update-wrapper bg-ECE-mb">
                  {loading ? <AllShimmer type={"common-card"} /> :
                    <div className="plan-details-box">
                      <h6 className="lato-rgular  fx-24 primary">Active Plan</h6>
                      <p className="fs-16-14 lato-regular black-252"> <b> {planList?.userSubscriptions?.[0]?.userSubscription?.premiumPlan?.name}</b> </p>
                      <p className="fs-16-14 lato-regular black-252">{planList?.userSubscriptions?.[0]?.userSubscription?.subPremiumPlan?.name}
                        {planList?.userSubscriptions?.length > 0 && !planList?.userSubscriptions?.[0]?.userSubscription?.subPremiumPlan?.isDefault && (
                          planList?.userSubscriptions?.[0]?.userSubscription?.isCancelled ?
                            <span className="text-danger mx-3">Cancelled</span> :
                            <span className="light-gray grey-919">
                              ({durationObj?.[planList?.userSubscriptions?.[0]?.userSubscription?.subPremiumPlanVariation?.resetInDays]} renewal)
                            </span>
                        )}</p>
                      {planList?.userSubscriptions?.[0]?.userSubscription?.endDate &&
                        !planList?.userSubscriptions?.[0]?.userSubscription?.subPremiumPlan?.isDefault ? (
                        <p className="fs-16-14 lato-regular black-252">
                          Valid till {planList?.userSubscriptions?.[0]?.userSubscription?.isCancelled ? (moment(planList?.userSubscriptions?.[0]?.endDate).format("DD/MM/YY [at] HH:mm")) : (moment(planList?.userSubscriptions?.[0]?.userSubscription?.endDate).format("DD/MM/YY [at] HH:mm"))}
                        </p>
                      ) : null}
                      <br />


                      {planList?.topupSubscription?.length > 0 && <>
                        <p className="fs-16-14 lato-regular black-252"> <b>Top Up Plan</b> </p>
                        {planList?.topupSubscription?.map((ele) => {
                          return (
                            <>
                              <p className="fs-16-14 lato-regular black-252">{ele?.userSubscription?.subPremiumPlan?.name} <span className=" text-danger">{ele?.isCancelled && ' Cancelled '}</span>

                              </p>
                              {!ele?.userSubscription?.isPostPlanner && (
                                <p className="fs-16-14 lato-regular black-252 mb-4"> Valid till  <span className="black-242"> {moment(ele?.userSubscription?.endDate).format("DD/MM/YY [at] HH:mm")}</span> </p>)}

                            </>
                          )
                        })}
                      </>
                      }

                    </div>
                  }

                  {loading ? <AllShimmer type={"common-card"} /> :
                    <div className="plan-details-box">
                      <h6 className="lato-rgular  fx-24 primary">Usage Left</h6>
                      {membershipList?.length > 0 &&
                        <p className="fs-16-14 lato-regular black-252"> <b>Membership</b> </p>
                      }
                      {
                        membershipList?.map((item, i) => (
                          item?.limit != 0 &&
                          <p key={i} className="fs-16-14 lato-regular black-252">
                            <span className={item?.pending === 0 ? 'text-danger' : ''}>
                              {item?.pending}
                            </span>
                            {item?.isPostPlanner ? '' : ` /${item?.limit} `}
                            {item?.contentCategory || ''} {item?.type} {item?.actionType}
                            {durationObjName?.[item?.resetInDays] && (
                              <span className="light-gray grey-919">
                                (per {durationObjName[item.resetInDays]})
                              </span>
                            )}
                            {item?.isPostPlanner && (
                              <span>for {item?.postPlannerDurationInDays} Days</span>
                            )}
                          </p>
                        ))
                      }

                      {topUpList?.length > 0 &&
                        <p className="fs-16-14 lato-regular black-252"> <b>Top Up</b> </p>
                      }
                      {
                        topUpList?.map((item, i) => {

                          return (

                            item?.limit != 0 &&
                            <p key={i} className="fs-16-14 lato-regular black-252">

                              <span className={item?.pending === 0 ? 'text-danger' : ''}>
                                {item?.pending}
                              </span>
                              {item?.isPostPlanner ? '' : ` /${item?.limit} `}
                              {item?.contentCategory || ''} {item?.type} {item?.actionType}
                              {durationObjName?.[item?.resetInDays] && (
                                <span className="light-gray grey-919">
                                  (per {durationObjName[item.resetInDays]})
                                </span>
                              )}
                              {item?.isPostPlanner && (
                                <span>
                                  {item?.postPlannerDurationInDays === 0
                                    ? "till plan expiry"
                                    : `for ${item?.postPlannerDurationInDays} Days`}
                                </span>
                              )}

                            </p>

                          )

                        })
                      }

                    </div>
                  }

                  <div className="plan-details-box position-relative">
                    {loading ? <AllShimmer type={"common-card"} /> :
                      <div>
                        <h6 className="lato-rgular  fx-24 primary">Billing Information</h6>

                        {(!userDetails?.ssoPartner || (userDetails?.ssoPartner && userDetails?.ssoPartner?.ssoPartnerType?.code === "LOOSE")) &&
                          <>
                            <NavLink className="fs-16-14 lato-regular black-252 pb-10 d-block" to="/profile-details">Modify billing info</NavLink>
                            <div className="fs-16-14 lato-regular black-252 pb-10 d-block pointer" onClick={() => { getUserBillingHistory(); setActiveHistory(true) }}>Billing history</div>
                            {
                              !planList?.userSubscriptions?.[0]?.userSubscription.subPremiumPlan.isDefault && !(planList?.userSubscriptions?.[0]?.userSubscription?.isCancelled) && <>
                                {(
                                  planList?.userSubscriptions?.[0]?.userSubscription?.endDate &&
                                  planList?.userSubscriptions?.[0]?.endDate &&
                                  moment(planList?.userSubscriptions?.[0]?.userSubscription?.endDate).isAfter(
                                    moment(planList?.userSubscriptions?.[0]?.endDate)
                                  )
                                ) &&
                                  <span className="lato-regular fs-16-14 grey-919">Next payment of <span className="primary"> ₹ {(planList?.userSubscriptions?.[0]?.userSubscription?.subPremiumPlanVariation?.discountedPrice) || '0'} </span> on <span className="primary"> {moment(planList?.userSubscriptions?.[0]?.endDate).format("ll")}</span></span>}
                                <div className=" fs-14-13 lato-semibold primary cancel-plan-btn pointer" onClick={() => { setActive(true); setCanceled(false) }}>Cancel Subscription</div>

                              </>
                            }
                          </>
                        }
                        {userDetails?.ssoPartner && userDetails?.ssoPartner?.allowSubscriptionPlanPage && userDetails?.ssoPartner?.subscriptionPlanPageLink !== "" &&
                          <a
                            className="fs-16-14 lato-regular black-252 pb-10 d-block"
                            href={
                              userDetails?.ssoPartner?.subscriptionPlanPageLink.startsWith("http") || userDetails?.ssoPartner?.subscriptionPlanPageLink.startsWith("https")
                                ? userDetails?.ssoPartner?.subscriptionPlanPageLink
                                : `https://${userDetails?.ssoPartner?.subscriptionPlanPageLink}`
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Manage Membership
                          </a>
                        }
                      </div>
                    }
                    {
                      active &&
                      <Modal className="save-confirm-collection-custom-model" centered show={active} onHide={() => handleCancel()}>
                        <Modal.Body className="cancelation-reason-modal py-5">
                          <Image src={close} onClick={() => handleCancel()} alt="canle icon" className="close-btn pointer " />
                          {canceled ?
                            <div className=" text-center py-5">
                              <h4 className="lato-regular fs-28-18 black-242 w-100 mb-3 text-center primary">Subscription has been Cancelled</h4>
                              <p className="lato-regular fs-16-14 black-242 w-100 text-center mw-634 mx-auto my-24">Your {planList?.userSubscriptions?.[0]?.userSubscription?.premiumPlan.name || "Subscription"} has been cancelled and you will no longer be billed.
                                You will continue to have access to  {(planList?.userSubscriptions?.[0]?.userSubscription?.premiumPlan.name || "").split(" ")[0].toLowerCase()} features until the end of the current billing cycle on: {moment(planList?.userSubscriptions?.[0]?.endDate).format("lll")}.</p>
                              <div className="d-flex justify-content-center align-items-center gap-12 w-100 mt-4">
                                <div className={`primary-btn fs-16-14 py-3 pointer`} onClick={() => { handleCancel() }}>Done</div>
                              </div>
                            </div>
                            :
                            <>
                              <h4 className="cancel-text lato-regular fs-28-18 black-242  mb-3 text-start primary mr-5 mr-md-0">You’re about to cancel your subscription</h4>
                              <p className="lato-regular fs-14-13 black-242 w-100 mb-1 text-start">You can continue enjoying your plan benefits until the end of the billing period. After that, you can continue with basic plan benefits.</p>
                              <p className="lato-regular fs-14-13 black-242 mb-4 mt-3 mt-md-0"><span className="lato-regular fs-14-13 primary">Note: </span> If Post Planner service is active for your current plan, any scheduled content after the end of billing period validity will be discarded.</p>

                              <div>
                                <p className="lato-regular fs-14-13 black-242 w-100 mb-2 text-start">Please, tell us why you’re canceling your subscription. </p>
                                {reasonsList.map((reason, index) => (
                                  <p key={index} className="lato-regular fs-14-13 black-242 w-100 mb-2 text-start d-flex align-items-center">
                                    <input
                                      type="radio"
                                      name="checkBox"
                                      value={reason}
                                      onChange={handleCheckboxChange}
                                    />
                                    &nbsp;&nbsp; {reason}
                                  </p>
                                ))}
                                {selectedReasons.includes("Others") && (
                                  <>
                                    <p className="lato-regular fs-14-13 black-242 w-100 mb-2 text-start mt-4 mb-2">Please tell us why you are cancelling your subscription.</p>
                                    <div className="text-area-reason-frame">
                                      <textarea
                                        type="text"
                                        maxLength={120}
                                        placeholder="Add your reason here"
                                        rows={3}

                                        className="lato-regular fs-16-14 input-box w-100 bg-white  rounded-0 border-0 text-area"
                                        value={otherReason}
                                        onChange={handleOtherReasonChange}
                                      />
                                      <span className='lato-regular f-s-8 lh-12 black-242 op-5 count-text-words'>{otherReason?.length}/120</span>
                                    </div>

                                  </>
                                )}
                              </div>
                              <div className="d-flex justify-content-between align-items-center gap-12 mt-5">
                                <p className=" lato-regular fx-12 black-242 op-6 pointer">Terms & Conditions </p>
                                <div className="d-flex justify-content-between align-items-center  ">
                                  <div className={` ghost-btn2 fs-16-14 lato-regular black-242 p-3 mr-3  pointer`} onClick={() => setActive(!active)}>Keep using it</div>
                                  <button disabled={isCancelDisabled} className={`primary-btn fs-16-14 py-3 pointer  `} onClick={() => !isCancelDisabled && cancelSubsHandler(planList?.userSubscriptions?.[0]?.userSubscription?.uuid)}>Cancel Subscription</button>
                                </div>
                              </div>
                            </>
                          }
                        </Modal.Body>
                      </Modal>
                    }
                    {
                      activeHistory &&
                      <Modal
                        className="save-confirm-collection-custom-model"
                        centered
                        show={activeHistory}
                        onHide={() => setActiveHistory(!activeHistory)}

                      >
                        <Modal.Body style={{ maxHeight: '80vh' }} className="cancelation-reason-modal py-5">
                          <div className="d-flex flex-row justify-content-between">
                            <h4 className="lato-regular fs-28-18 black-242 w-100 mb-3 text-start primary">Invoices</h4>
                            <div className="d-flex justify-content-end">
                              <Image src={close} onClick={() => setActiveHistory(!activeHistory)} alt="cancel icon" className="pointer mb-3" />
                            </div>
                          </div>
                          <div className="invoice-table" style={{ overflowY: 'auto', maxHeight: '60vh' }}> {/* Scrollable area */}
                            <table className="w-100 invoice-table-frame">
                              <thead style={{ position: 'sticky', top: 0, backgroundColor: '#ffffff', zIndex: 1 }}> {/* Fixed header */}
                                <tr className="invoice-header-row">
                                  <th className="lato-regular fs-14-12">Id</th>
                                  <th className="lato-regular fs-14-12">Plan</th>
                                  <th className="lato-regular fs-14-12">Date</th>
                                  <th className="lato-regular fs-14-12">Amount</th>
                                  <th className="lato-regular fs-14-12">Status</th>
                                  <th className="lato-regular fs-14-12">Download</th>
                                </tr>
                              </thead>
                              <tbody>
                                {userBillHistory?.map((ele, i) => (
                                  <tr className="invoice-body-row" key={i}>
                                    <td className="lato-regular fs-14-12">{i + 1}</td>
                                    <td className="lato-regular fs-14-12">
                                      {ele?.userSubscription?.premiumPlan?.name || ''}
                                      <br />
                                      {ele?.userSubscription?.subPremiumPlan?.name || ''}
                                    </td>
                                    <td className="lato-regular fs-14-12">{moment(ele?.startDate).format("DD/MM/YY [at] HH.mm")}</td>
                                    <td className="lato-regular fs-14-12">₹{ele?.amountPaid || 0}</td>
                                    <td className="lato-regular fs-14-12">
                                      <Image src={Checked} className="w-24" alt="check Icon" />
                                    </td>
                                    <td className="lato-regular fs-14-12">
                                      {ele?.invoicePdfPath ? (
                                        <a rel="noreferrer" href={ele?.invoicePdfPath} download target="_blank">
                                          <Image src={InvoiceDownload} className="w-24 pointer" alt="download Icon" />
                                        </a>
                                      ) : (
                                        <Image src={InvoiceDownload} className="w-24 disable-pointer" alt="download Icon" />
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </Modal.Body>
                      </Modal>
                    }
                  </div>
                </div>
                :
                <div className="plan-update-wrapper w-100">
                  <h6 className="lato-rgular py-4 text-center w-100 fx-24 primary">There is no active plan...</h6>
                </div>
              }
            </div>
            :
            <div className="pricing-description">
              <div className="container">
                <h5 className=" fs-24-18 lato-regular black-242">
                  Discover the premium pricing plan tailored just for you to supercharge your marketing endeavours!
                </h5>
                <p className=" fs-16-13 lato-regular black-05">
                  Get access to exclusive content with our recommended premium plans!
                </p>
              </div>
            </div>
          }
        </div>



      </div>
      {((userDetails?.ssoPartner && userDetails?.ssoPartner?.allowSubscriptionPlanPage && userDetails?.ssoPartner?.
        subscriptionPlanPageLink === ""
      ) || !userDetails?.ssoPartner
      ) && (
          <div className="up-your-game  pt-5">
            {
              !getToken()
                ?
                <></>
                :
                getUser()?.isPremium
                  ?
                  <>
                    <h5 className="fs-28-24 lato-regular container my-3  black-242 pb-10">
                      Enjoy your premium membership
                    </h5>
                    <p className="fs-18-14 lato-regular container">
                      Select or upgrade to a plan of your choice best suitable for you
                    </p>
                  </>
                  :
                  <>
                    <h5 className="fs-28-24 lato-regular container my-3  black-242 pb-10">
                      Want to up your game?
                    </h5>
                    <p className="fs-18-14 lato-regular container">
                      Select or upgrade to a plan of your choice
                    </p>
                  </>
            }

            {loading ? <div className="d-flex flex-row justify-content-between mt-3">
              <AllShimmer type={"subscription"} />
            </div>
              :
              <div className="your-game-card-wrapper">
                {planList && planList?.premiumPlans?.map((item, i) => {
                  const isActivePlan = planList?.userSubscriptions?.filter((ele) => ele?.userSubscription?.premiumPlan?.name === item?.name);

                  const shouldRenderTopupCard = item?.isTopup ? (getToken() && getUser()?.isPremium && planList?.userSubscriptions[0]?.userSubscription.level !== 0) : true;

                  if (shouldRenderTopupCard && item?.subPremiumPlan?.length > 0) {
                    return (<>
                      {item?.isTopup && <div className="doted-vertical-line"></div>}
                      <SubscriptionPlanCard
                        key={i}
                        index={i}
                        data={item}
                        isActivePlan={isActivePlan}
                        isAciveTopUP={planList?.topupSubscription}
                        handleSubscription={handleSubscription}
                        planList={planList}
                        breakupAmount={breakupAmount}

                      />
                    </>
                    );
                  }
                  return null;
                })}

              </div>
            }
          </div>
        )
      }
      {(userDetails?.ssoPartner && (userDetails?.ssoPartner?.allowSubscriptionPlanPage === false || userDetails?.ssoPartner?.subscriptionPlanPageLink !== ""))

        &&
        <div style={{ display: "flex", flexDirection: "column", minHeight: "63vh" }}>
          <div style={{ flexGrow: 1 }}>

            <div ></div>

          </div>

          <Footer />
        </div>
      }
      {(!userDetails?.ssoPartner || (userDetails?.ssoPartner &&
        userDetails?.ssoPartner?.allowSubscriptionPlanPage === true && userDetails?.ssoPartner?.subscriptionPlanPageLink === "")) && <Footer />}

      <AlertModal show={isAlert} onHide={onHide} alertData={alertData} data={resData} breakupAmount={breakupAmount} modalClass={"alert-modal"} type={"subscription"} />

    </div >
  );
}

export default MySubscription;
