
import React, { useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Footer, Header, NotFound } from "../common/pages";
import { useState } from "react";
import { BreadCrumb } from "../home/_utils";
import EventCard from "./EventCard";
import { commonAuthApis } from "../profile/Api";
import { EVENTS } from "../../utils/Constant";
import { AllShimmer } from "../common/components";

export default function MyEvents() {

    return (
        <section className='page-padding'>
            <Header />
            <BreadCrumb path={["My Events"]} clasese="mb-3-desk container" />
            <div className="container">
                <h3 className='lato-regular fs-32-28 black-242 mb-0'>My Events <span className='lato-bold'></span></h3>
                <div className="treanding-tab-wrapper pt-4">
                    <Tabs
                        defaultActiveKey="bookings"
                        class="trending-tabs justify-content-start"
                        id="uncontrolled-tab-example">
                        <Tab
                            eventKey="bookings"
                            class='lato-regular fs-18-14 black-242'
                            title="Bookings">
                            <BookingEvents />
                        </Tab>

                    </Tabs>
                </div>
            </div>
            <Footer />
        </section>

    )
}


function BookingEvents() {
    const [eventData, setEventData] = useState([])
    const [isLoading, serIsLoading] = useState(false);
    async function featchedEvents() {
        const res = await commonAuthApis('eventRegistration/registrationByUser', "", 'get');
        // console.log(res)
        if (res.status >= 200 && res.status <= 210) {
            setEventData(res.data.data);
            serIsLoading(false);
        }
    }
    useEffect(() => {
        serIsLoading(true)
        featchedEvents()
    }, [])
    return (
        <div className="events-card-wrapper pt-5">
            {isLoading ? (
                <AllShimmer type={EVENTS} />
            ) : eventData && eventData.length > 0 ? (
                eventData.map((item, i) => (
                    <EventCard key={i} data={item.event} registered={true} />
                ))
            ) : (
                <div className="d-flex justify-content-center w-100">
                    <NotFound />
                </div>
            )}
        </div>
    )
}
