import React from "react";
import { Image } from "react-bootstrap";
import arrowNext from "../../assets/images/Arrow_next.svg";
import arrowPre from "../../assets/images/Arrow_pre.svg";

export default function PlannerPagination({ viewAll, pageCount, viewDetail, pageNo, setPageNo }) {

  //   useEffect(() => {
  //     setPageNo(parseInt(page, 10));
  //   }, [page]);

  const onLeftClick = () => {
    if (pageNo > 1) {
      const newPage = pageNo - 1;
      setPageNo(newPage);
      viewDetail(viewAll, newPage);
    }
  };

  const onNextClick = () => {
    if (pageNo < pageCount) {
      const newPage = pageNo + 1;
      setPageNo(newPage);
      viewDetail(viewAll, newPage);
    }
  };

  const onValueInput = (e) => {
    let no = parseInt(e.target.value, 10);
    if (no > 0 && no <= pageCount) {
      setPageNo(no);
      viewDetail(viewAll, no);
    } else {
      //   setPageNo(page);
    }
  };




  return (
    <div className="pagination-section">
      <Image
        onClick={onLeftClick}
        src={arrowPre}
        className="pagination-pre-icon pointer"
        alt="back_icon"
      />
      <div className="pagination-count f-s-14 lato-medium black-242">
        Page
        <input
          className="pagination-item pointer"
          onWheel={(e) => e.target.blur()}
          type="number"
          value={pageNo}
          onChange={onValueInput}


        />
        of {pageCount}
      </div>
      <Image
        onClick={onNextClick}
        src={arrowNext}
        className="pagination-next-icon pointer"
        alt="next_icon"
      />
    </div>
  );
}
