import React from 'react'
import { Image } from 'react-bootstrap'
import noResult from '../../../assets/images/No-results-found.gif'
import CollectionEmpty from "../../../assets/images/collection-empty.png"
// import noResult from '../../../assets/images/Error-404-not-found.png'


export default function NotFound({ type, massage = "" }) {
  return (
    <div className='search-not-found mt-0'>
      <div className={`${!type ? 'not-found-img' : 'not-found-coll'}`} >
        <Image src={type == "collection" ? CollectionEmpty : noResult} alt='Result Not Foune' className=' mx-auto' />
      </div>

      <h5 className="lato-semibold fs-24-12 primary text-center mb-2">
        {type == "collection" ? (
          "This collection is empty!"
        ) : (
          <>
            The paper chase ends here: {massage || "No results found"}.
          </>
        )}
        <br />
      </h5>

      <h5 className='lato-semibold fs-24-12 primary text-center mb-5'>
        {type == "collection" ? "Feel free to explore and start adding!" :

          "Try refining your search keywords"}
      </h5>
      {/* <div className="search-trand-wraper">
        <Trending title={"SIP"} classes="fs-18-14 lato-semibold not-found-trend" image={trending} />
        <Trending title={"ELSS"} classes="fs-18-14 lato-semibold not-found-trend" image={trending} />
        <Trending title={"Financial Planning"} classes="fs-18-14 lato-semibold not-found-trend" image={trending} />
      </div> */}
    </div>
  )
}
