import React, { useState } from "react";
import { Image } from "react-bootstrap";
// import PlayVideo from "../../../assets/video/video-detail.mp4";
// import Profile from "../../../assets/images/fiba-logo.png";
// import Presentation from "../../../assets/images/presentation-details.png";

// import infographic from "../../../assets/images/infographic-details.png";

const VideoDetailsContentType = ({ type, extUrlLink, contentFilePath = Presentation, height = "600" }) => {

  const [pdfFallback, setPdfFallback] = useState(false);
  const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg'];
  const videoExtensions = ['.mp4', '.webm', '.ogg']; // Add more video extensions if needed

  const isImage = (filePath) => {
    const extension = (filePath ?? '').toLowerCase().slice(filePath.lastIndexOf('.'));
    return imageExtensions.includes(extension);
  };

  const isVideo = (filePath) => {
    const extension = (filePath ?? '').toLowerCase().slice(filePath.lastIndexOf('.'));
    return videoExtensions.includes(extension);
  };

  // Handle PDF loading error and switch to fallback if needed
  const handlePdfError = () => {
    setPdfFallback(true);
  };

  if (extUrlLink.includes("youtube.com")) {
    let YoutubeURL = '';
    if (extUrlLink.includes("/shorts/")) {
      YoutubeURL = `https://www.youtube.com/embed/${extUrlLink?.split("/")[(extUrlLink?.split("/").length - 1)]}?autoplay=1&mute=0&rel=0`;
    } else {
      YoutubeURL = `https://www.youtube.com/embed/${extUrlLink?.split("v=")[1]}?autoplay=1&mute=0&rel=0`;
    }

    return (
      <div className="video-play-area">
        <iframe
          width="100%"
          height={height}
          src={YoutubeURL}
          allowFullScreen
          title="Embedded YouTube"
          allow='autoplay'
        />
      </div>
    )
  } else if (contentFilePath || extUrlLink) {
    let file = contentFilePath ? contentFilePath : extUrlLink;
    const isPdf = file.toLowerCase().endsWith('.pdf');

    // For PDFs, try native browser viewing first, fallback to Google Docs viewer if needed
    const pdfSrc = pdfFallback
      ? `https://docs.google.com/viewer?url=${encodeURIComponent(file)}&embedded=true&chrome=false&disabledownload=true`
      : `${file}#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&download=0&print=0&view=FitH`;

    return (
      <div className="infographic-detail-wrapper" style={{ height: height + 'px', overflow: 'hidden' }}>
        {
          isImage(file)
            ? <div className="detail-wrapper">
              <Image
                className="presentation"
                src={file}
                alt="presentations"
                draggable={false}
                onContextMenu={(e) => e.preventDefault()}


              />
            </div>
            : isVideo(file)
              ? <div className="video-play-area">
                <video
                  width="100%"
                  height={height}
                  controls
                  src={file}
                  title="Embedded Video"
                  onContextMenu={(e) => e.preventDefault()}
                >
                  Your browser does not support the video tag.
                </video>
              </div>
              : isPdf ? (
                <object
                  data={pdfSrc}
                  type="application/pdf"
                  width="100%"
                  height={height}
                  onError={handlePdfError}
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                    backgroundColor: "#f5f5f5"
                  }}
                >
                  <iframe
                    src={`https://docs.google.com/viewer?url=${encodeURIComponent(file)}&embedded=true&chrome=false&disabledownload=true`}
                    width="100%"
                    height={height}
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "none",
                      transform: "scale(1)",
                      transformOrigin: "top left"
                    }}
                    title="Embedded Content"
                    frameBorder="0"
                  />
                </object>
              ) : (
                <iframe
                  src={file}
                  width="100%"
                  height={height}
                  style={{ width: "100%", height: "100%", border: "none" }}
                  title="Embedded Content"
                  frameBorder="0"
                />
              )
        }
      </div>
    )
  } else {
    return null;
  }
};

export default VideoDetailsContentType;
