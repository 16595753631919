import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Dropdown, Image, Modal } from "react-bootstrap";
import Logo from "../../../assets/images/brand-logo2.jpg";

import Menu from "../../../assets/images/menu-icon.svg";
import Close from "../../../assets/images/close.svg";
import ProfileIcon from "../../../assets/images/profile-icon.svg";
import NotificationIcon from "../../../assets/images/notification-icon.svg";
import { deleteToken, getToken } from "../../../utils/ManageToken";
import { useState } from "react";
import { EVENTS, UserTracking } from "../../../utils/Constant";

import CustomModal from "./CustomModal";
import CloseIcon from '../../../assets/images/greay-cancel-icon.svg';
import close from "../../../assets/images/cancel-icon.svg";
import training from '../../../assets/images/eventss-training.png';
import { getUserDetails } from "../../../utils/ReusableFunctions";
import { SignUp } from "../components";

const profileList = [{ name: "Profile Details", path: "/profile-details" }, { name: "My Socials", path: "/my-socials" }, { name: "My Preferences", path: "/my-preferences" }, { name: 'My Collections', path: '/mycollections' }, { name: 'My Membership', path: "/mymembership" }, { name: 'My Events', path: '/my-events' }, { name: "My Activity", path: `/myactivity/user-tracking?type=${UserTracking?.DOWNLOADED.toLowerCase()}` }, { name: "Log Out", path: "/logout" }];

export default function Header() {
  const history = useHistory();
  const [token, setToken] = useState(false);
  // const [isPostPlanner, setIsPostPlanner] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirm2, setConfirm2] = useState(false);
  const [signup, setSignup] = useState(false);


  useEffect(() => {
    if (getToken()) {
      setToken(true)

    }
    window.scrollTo(0, 0);
  }, []);

  const handleClose = (() => {
    setOpen(false);
  })
  function clickHandler(data) {

    if (data === '/logout') {
      // localStorage.removeItem('finpediaCustomerToken');
      deleteToken();
      setToken(false)
      window.location.href = "/";
    } else {
      history.push({
        pathname: data
      });
    }

  }
  const userDetails = getUserDetails();
  const isCompanyLogoVisible = userDetails?.ssoPartner && userDetails?.ssoPartner?.companyLogoVisibility === true;

  const hasCompanyLogoText = userDetails?.ssoPartner?.companyLogoText !== "";

  const shouldShowBothLogos = isCompanyLogoVisible && hasCompanyLogoText;
  const shouldShowOnlyCompanyLogo = isCompanyLogoVisible && !hasCompanyLogoText;
  const shouldShowLogoOnly = !isCompanyLogoVisible;
  return (

    <header className="main-header">
      <div className="container">
        <div className="header-wrapper">
          <input type="checkbox" name="so" id="so" className="d-none" />
          <div className="d-flex align-items-center justify-content-between gap-12-mb">
            <label htmlFor="so" className="nemue-lable mb-0">
              <Image src={Menu} className="menu-bar" alt="Humbar" />
              <Image src={Close} className="cancel-menu" alt="Humbar" />
            </label>

            {/* <Image
              src={userDetails?.ssoPartner && (userDetails?.ssoPartner?.ssoPartnerType?.
                companyLogoVisibility === true && userDetails?.ssoPartner?.companyLogoText === "") ? userDetails?.ssoPartner?.companyLogo : Logo}
              onClick={() => history.push("/")}
              className="desk-show pointer header-logo"

              alt="logo"
            /> */}

            {shouldShowBothLogos ? (
              // If both conditions are true, show companyLogo and Logo side by side with text above Logo
              <div className="" style={{ display: 'flex', alignItems: 'center' }}>
                <div className="" style={{ marginRight: '10px' }}>
                  <Image
                    src={userDetails?.ssoPartner?.companyLogo}
                    alt="company logo"
                    className="desk-show pointer header-logo"
                  />
                </div>
                <div className="" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <div
                    className=" desk-show mt-0 fx-12 lato-medium white"
                    style={{
                      textAlign: 'center',
                      marginBottom: '1px',

                    }}
                  >
                    {userDetails?.ssoPartner?.companyLogoText}
                  </div>
                  <Image
                    src={Logo}
                    alt="logo"
                    style={{ maxHeight: "3.5rem" }}
                    onClick={() => history.push("/")}
                    className="desk-show pointer header-logo"
                  />
                </div>
              </div>
            ) : shouldShowOnlyCompanyLogo ? (
              // If only companyLogo condition is true, show companyLogo
              <Image
                src={userDetails?.ssoPartner?.companyLogo}
                onClick={() => history.push("/")}
                className="desk-show pointer header-logo"
                alt="company logo"
              />
            ) : (
              // If no conditions are met for companyLogo, show Logo
              <Image
                src={Logo}
                onClick={() => history.push("/")}
                className="desk-show pointer header-logo"
                alt="logo"
              />
            )}




            {/* <Image
              src={userDetails?.ssoPartner && (userDetails?.ssoPartner?.ssoPartnerType?.code == "DEEP" || userDetails?.ssoPartner?.ssoPartnerType?.code == "FULL") ? userDetails?.ssoPartner?.companyLogo : Logo}
              onClick={() => history.push("/")}
              className="tab-show pointer header-mblogo"
              alt="logo"

            /> */}

            {shouldShowBothLogos ? (
              // If both conditions are true, show companyLogo and Logo side by side with text above Logo
              <div className="" style={{ display: 'flex', alignItems: 'center' }}>
                <div className="" style={{ marginRight: '10px' }}>
                  <Image
                    src={userDetails?.ssoPartner?.companyLogo}
                    alt="company logo"
                    className="tab-show pointer header-mblogo"
                  />
                </div>
                <div className="" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <div
                    className="tab-show mt-0 fs-10-8 lato-medium white"
                    style={{
                      textAlign: 'center',
                      marginBottom: '1px',

                    }}
                  >
                    {userDetails?.ssoPartner?.companyLogoText}
                  </div>
                  <Image
                    src={Logo}
                    alt="logo"
                    style={{ maxHeight: "2.5rem" }}
                    onClick={() => history.push("/")}
                    className="tab-show pointer header-mblogo"
                  />
                </div>
              </div>
            ) : shouldShowOnlyCompanyLogo ? (
              // If only companyLogo condition is true, show companyLogo
              <Image
                src={userDetails?.ssoPartner?.companyLogo}
                onClick={() => history.push("/")}
                className="tab-show pointer header-mblogo"
                alt="company logo"
              />
            ) : (
              // If no conditions are met for companyLogo, show Logo
              <Image
                src={Logo}
                onClick={() => history.push("/")}
                className="tab-show pointer header-mblogo"
                alt="logo"
              />
            )}



          </div>
          <ul className="header-ul">
            <label htmlFor="so">
              <li
                onClick={() => history.push("/trending/finpedia-trending")}
                className="lato-semibold white f-s-18 lh-24">
                Trending
              </li>
              <li
                onClick={() => history.push({ pathname: `/${EVENTS}`, search: `pageNumber=1&pageSize=10` })}
                className="lato-semibold white f-s-18 lh-24"
              >
                Events
              </li>
              <li
                onClick={() => {
                  if (token) {  // Replace 'isLoggedIn' with your actual login check
                    if (userDetails?.isPersonalization) {
                      history.push("/personalization/All");
                    } else {
                      setConfirm2(true);
                    }

                  }
                  else {
                    // history.push("/personalization/All");
                    setSignup(true);

                  }

                }}
                className="lato-semibold white f-s-18 lh-24">Personalization</li>
              <li
                onClick={() => history.push("/tools")}
                className="lato-semibold white f-s-18 lh-24">Tools</li>
              <li
                onClick={() => {
                  if (token) {  // Replace 'isLoggedIn' with your actual login check
                    if (userDetails?.isPostPlanner) {
                      history.push("/post-planner");
                    } else {
                      setConfirm2(true);
                    }

                  }
                  else {
                    history.push("/post-planner");

                  }

                }}
                className="lato-semibold white f-s-18 lh-24">Post Planner</li>
              {token && userDetails ? (
                <>
                  <li className="d-flex align-items-center mb-fixed-user">
                    <Dropdown className="header-profile-dropdown">
                      <Dropdown.Toggle
                        className="bg-transparent header-profile-icon"
                        id="dropdown-basic"
                      >

                        {" "}
                        <Image
                          src={ProfileIcon}
                          className="profile-header-icon"
                          alt="Profile Icon"
                        />

                      </Dropdown.Toggle>

                      <Dropdown.Menu className="show-profile-dropdown">
                        {profileList &&
                          profileList?.map((item, i) => {
                            return (
                              <Dropdown.Item
                                key={i}
                                onClick={() => clickHandler(item.path)}
                                className="lato-regular fs-22-13 op-7 black-242 "
                                href=""
                              >
                                {item.name}
                              </Dropdown.Item>
                            );
                          })}
                      </Dropdown.Menu>
                    </Dropdown>

                    <Image
                      src={NotificationIcon}
                      onClick={() => history.push({ pathname: '/notification', search: 'pageNumber=1&pageSize=18' })}
                      className="notification-header-icon"
                      alt="Notification Icon"
                    />
                  </li>
                </>
              ) : (
                <li>
                  <label htmlFor="so" >
                    <Link to="/onboarding" className="secondary-btn  fx-14 mb-fixed-user pointer">
                      <span className=""> Log in</span>
                      {/* <Image
                      {/* <Image
                        src={ProfileIcon}
                        className="profile-header-icon mb-show"
                        alt="Profile Icon"
                      /> */}
                    </Link>

                  </label>
                </li>
              )}
            </label>
          </ul>
        </div>
      </div>
      <CustomModal
        show={open}
        onHide={handleClose}
        modalClass={"planner-modal"}
      >
        <div className="d-flex justify-content-end">
          <Image src={CloseIcon} className='close-icon model-icon-close' alt='icon' style={{ cursor: "pointer" }} onClick={handleClose} />
        </div>
        <p className="lato-medium fs-20-16 black-242 d-flex justify-content-center">Your current subscription doesn't allow this feature, kindly upgrade.</p>
        <div className="d-flex justify-content-center ">
          <Button className="primary-btn "
            onClick={() => history.push("/mymembership")}

          >Upgrade</Button>
        </div>

      </CustomModal>
      {
        confirm2 &&
        <Modal className="save-confirm-collection-custom-model" centered show={confirm2} onHide={() => setConfirm2(!confirm2)}>

          <Modal.Body className="save-confirm-collection-model-body text-center">
            <Image src={close} onClick={() => setConfirm2(!confirm2)} alt="canle icon" className="close-btn pointer" />
            <Image src={training} className="mb-10" />
            <h4 className="lato-regular fs-24-16 black-242 w-100 text-center">This feature is available only to the subscribed members. Want to be a part of this <span className="primary"> premium community</span>? </h4>
            <div className="d-flex justify-content-center ">
              <div
                className={`primary-btn py-3 pointer`}
                onClick={() => {
                  if (window.location.pathname === '/mymembership') {
                    setConfirm2(false); // Set confirm2 to false if already on /mymembership
                  } else {
                    history.push('/mymembership'); // Otherwise, navigate to /mymembership
                  }
                }}
              >
                View Membership Plans
              </div>

            </div>
          </Modal.Body>
        </Modal>
      }
      {signup && <SignUp show={signup} handleClose={() => setSignup(!signup)} />}

    </header>

  );


}
