import React, { useState } from 'react'
import { Image } from "react-bootstrap";
import close from '../../assets/images/greay-cancel-icon.svg';
import searchIcon from "../../assets/images/Search_1.svg";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { AMC_CONTENT_CREATOR, MAX_LIMIT, START_PAGE } from '../../utils/Constant';
import { getAuthDataApi } from '../home/Api';
import { toast } from 'react-toastify';

export default function SearchTemplate({ setTemplatesData, setFilteredTemplates, getTemplateData, name, placeholder, clases, }) {
    const param = useParams();
    const { personalizedType } = useParams(); // Extract type from route
    const [keyword, setKeyword] = useState("");

    function filterNonEmptyTemplates(data) {
        return data.filter(item => item.list && item.list.length > 0);
    }


    async function fetchFilteredData(keyword) {
        const apiUrl = personalizedType !== "All"
            ? `/website/PersonalizationTemplate?pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}&category=${personalizedType}&searchValue=${keyword}`
            : `/website/PersonalizationTemplate?pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}&searchValue=${keyword}`;

        try {
            const res = await getAuthDataApi(apiUrl);


            if (res) {
                console.log(res)
                setTemplatesData(res);
                setFilteredTemplates(filterNonEmptyTemplates(
                    res)
                );
            } else {
                toast.error("Failed to get data");
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }


    function changeHandler(e) {
        const key = e.target.value;
        setKeyword(key);
        if (key) {

            fetchFilteredData(key);
        } else {

            getTemplateData(personalizedType === "All" ? null : personalizedType);
        }
    }


    function handleKeyPress(e) {
        if (e.key === "Enter") {
            fetchFilteredData(keyword);
        }
    };
    return (
        <div className={`keywords-input-fields search-amc-wrapper ${clases}`}>
            <input
                type="text"
                placeholder={`Search ${placeholder}`}
                autoComplete="off"
                className="input-box-keywords"
                name="keyword"
                inputmode="search"
                value={keyword || ""}
                onChange={changeHandler}
                onKeyDown={handleKeyPress}
            />
            {keyword && <Image src={close} onClick={() => {

                setKeyword(""); getTemplateData(personalizedType === "All" ? null : personalizedType)
            }} alt="canle icon" className="cancel-field" />}

            <Image
                onClick={() => fetchFilteredData(keyword)}
                src={searchIcon}
                alt="search icon"
                className="search-input-field-icon "
            />
        </div>
    )
}
