import React, { useEffect } from 'react'
import { useState } from 'react';
import { Header, Footer } from '../common/pages';
import instagram from "../../assets/images/instagrap-Bold.svg";
import facebook from "../../assets/images/facebook-bold.svg";
import linkding from "../../assets/images/linkding-bold.svg";
import twiter from "../../assets/images/twiter-bold.svg";
import instagramIcon from "../../assets/images/Instagram-color.svg";
import facebookIcon from "../../assets/images/facebook-color.svg";
import linkdingIcon from "../../assets/images/linedin-icon.svg";
import twiterIcon from "../../assets/images/twitter-color.svg";
import Hashtag from "../../assets/images/hashtag-icon.svg";
import { Image, Tab, Tabs } from 'react-bootstrap';
import { LinkedInEmbed, FacebookEmbed, InstagramEmbed, XEmbed } from 'react-social-media-embed';
import { SearchDropDown, Trending } from '../common';
import { ContentTypesCards } from './_utils';
import { getCommonApi } from './Api';
import { API_LIMIT, GLOBAL_TRENDING, START_PAGE, TheFinpedia } from '../../utils/Constant';
import { AllShimmer } from '../common/components';
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { calculateTotalPageCount } from '../../utils/ReusableFunctions';
import Pagination from './Pagination';
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */



const feeds = [
  { icon: twiter, text: "X", icon2: twiterIcon },
  { icon: instagram, text: "Instagram", icon2: instagramIcon },
  { icon: facebook, text: "Facebook", icon2: facebookIcon },
  { icon: linkding, text: "Linkedin", icon2: linkdingIcon },
]

export default function TrendingPage() {
  const [selectedKeys, setSelectedKeys] = useState(TheFinpedia);
  const history = useHistory();
  const handleSelect = (e) => {
    setSelectedKeys(e);
    if (e === TheFinpedia)
      history.push('finpedia-trending')
    else
      history.push(GLOBAL_TRENDING + '?pageNumber=1&pageSize=12')
  }
  useEffect(() => {
    if (history.location.pathname.includes("finpedia-trending"))
      handleSelect(TheFinpedia)
    // setSelectedKeys(TheFinpedia);
    else
      handleSelect("Around the Globe")
    // setSelectedKeys("Around the Globe");
  }, [history.location.pathname]);

  return (
    <section className='page-padding'>
      <Header />
      <div className="container">


        <SearchDropDown getKeys={(keys) => setSelectedKeys(keys)} classes="search-border trend-serch" />

        <h3 className='lato-light fs-48-32 black-242 mb-8'>What's
          <span className='lato-bold'>Trending</span>
          !</h3>
        <p className='lato-regular fs-18-14 black-242 op-5'>The insights you need to keep ahead in the game.</p>
        <div className="treanding-tab-wrapper">

          <Tabs
            defaultActiveKey={selectedKeys}
            class="trending-tabs"
            onSelect={(e) => handleSelect(e)}
            id="uncontrolled-tab-example">
            <Tab
              eventKey={TheFinpedia}
              className='lato-regular fs-18-14 black-242'
              title={TheFinpedia}>
              <TheFinPedia />
            </Tab>
            <Tab eventKey="Around the Globe" title="Around the Globe">
              <AroundGlobe />
            </Tab>
          </Tabs>
        </div>
      </div>
      <Footer />
    </section>

  )
}

function TheFinPedia() {
  const keysValue = ["SIP", "MAF", "BAF", "Volatility", "STP", "SWP", "ELSS", "Financial Planning"];
  const [listData, setListData] = useState()
  const [isLoading, setIsLoading] = useState(true);
  const [keys, setKeys] = useState([])
  const history = useHistory();
  useEffect(async () => {
    const info = await getCommonApi(`content/search?contentType=&keyword=${keysValue.join(',')}&pageNumber=${START_PAGE}&pageSize=${API_LIMIT}&sortBy=most%20recent`);
    if (info.status === 200) {
      setListData(info?.data?.data?.contents);
      setIsLoading(false)
    }
    const keysValues = JSON.parse(sessionStorage.getItem("trendingKeywords"));
    setKeys(keysValues);

  }, [history.location.pathname.includes("finpedia-trending")])

  return (
    <div className="">
      <div className="treanding-one-wrapper gap-12 ">
        {
          keys?.map((item, i) => {

            return (
              <Trending key={i} title={item?.trendingKeyword} classes="video-trend-border lh-20 fs-14-10 lato-medium black-242 " image={Hashtag} />
            )
          })
        }

      </div>

      {
        isLoading ?
          <AllShimmer type='All' noContainer={true} serchHide={false} />
          : (
            listData?.map((item, j) => {
              return (
                <ContentTypesCards
                  key={j}
                  countTitle={item?.title}
                  hide={true}
                  data={item?.list}
                  count={item?.count}
                  typeId={item?.uuid}
                  // suggestionData="Suggested"
                  code={item?.code}
                />
              );
            })
          )
      }
    </div>
  )
}



function AroundGlobe() {
  // const dateList = ["Today", 'Yesterday', 'Last 7 days', 'Last 30 days', 'This Month']
  const [selected, setSelected] = useState([]);
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const param = useParams();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(
    parseInt(params.page, 10) || START_PAGE
  );
  const [links, setLinks] = useState([]);
  const [socialLink, setSocialLink] = useState([])

  const getGlobalLinks = async () => {
    const res = await getCommonApi(`website/trending?pageNumber=${params.get('pageNumber') || START_PAGE}&pageSize=${params.get('pageSize') || API_LIMIT}${(params.has('type') ?? '') ? `&type=${params.get('type')}` : ''}`);
    setLinks(res?.data?.data)
    setSocialLink(res?.data?.data?.list)
  }
  useEffect(() => {
    window.scroll(0, 0);
    setCurrentPage(parseInt(params.get('pageNumber')))
    getGlobalLinks()

    // console.log(res)
  }, [history.location.pathname.includes(GLOBAL_TRENDING), params.get("pageNumber")]);

  useEffect(() => {

    if (param?.trend === GLOBAL_TRENDING) {
      // console.log('Heyyyy');
      getGlobalLinks();
    }

  }, [selected])
  function activeHandler(item) {
    // console.log(item)
    if (item == "All") {
      setSelected([]);
      history.push({ search: `pageNumber=1&pageSize=12` })

    }
    else if (selected.includes(item)) {
      let filter = selected.filter((ele => ele !== item))
      setSelected(filter)

      if (filter.length === 0) {
        history.push({ search: `pageNumber=1&pageSize=12` });
      } else {
        history.push({ search: `pageNumber=1&pageSize=12&type=${filter.join(',')}` });
      }
    } else {
      setSelected([...selected, item])
      history.push({ search: `pageNumber=1&pageSize=12&type=${[...selected, item]?.join(',') || 'All'}` })
      // getGlobalLinks([...selected, item]?.join(','))
    }

  }


  return (
    <div className="">
      <div className="around-the-globe-wrapper">
        <div className="feeds-wrapper">
          <p className='lato-regular black-242 op-6  fs-18-16 desk-show'>Feeds :</p>
          <h4
            onClick={() => {


              activeHandler("All")

            }} className={`Social-media-frame fs-14-12 lato-bold black-242  ${selected.length === 0 ? "active-feed" : 'bg-white'}`}>
            All
          </h4>
          <div className="vertial-line"></div>
          {
            feeds?.map((item, i) => {
              //active-feed
              return (
                <h4 key={i} onClick={() => activeHandler(item.text.toLowerCase())} className={`Social-media-frame fs-14-12 lato-bold black-242 ${selected?.includes(item.text.toLowerCase()) ? "active-feed" : ''} `}>
                  <Image src={item.icon} alt='icon' className='' />
                  {/* <Image src={item.icon2} alt='icon' className='mb-show' /> */}
                  <span className='desk-show'>  {item.text}</span>

                </h4>
              )
            })
          }

        </div>

      </div>
      <div class="grid-container">
        {links.length > 0 ? links?.list?.map((item, i) => {
          if (item.type.toLowerCase().includes('linkedin')) {
            return (
              <div key={i} className="embeded-wrapper ">
                <LinkedInEmbed url={item.link} width={425}
                />
              </div>
            )
          } else if (item.type.toLowerCase().includes('x')) {
            return (
              <div key={i} className="embeded-wrapper twiter-iframe">
                <XEmbed url={item.link} width={425} />
              </div>
            )
          } else if (item.type.toLowerCase().includes('instagram')) {
            return (
              <div key={i} className="embeded-wrapper ">
                <InstagramEmbed url={item.link} width={425} captioned />
              </div>
            )
          } else if (item.type.toLowerCase().includes('facebook')) {
            return (
              <div key={i} className="embeded-wrapper ">
                <FacebookEmbed url={item.link} width={425} />
              </div>
            )
          }

        }) :
          <h3 className=' text-center py-5 lato-bold w-100   '>Result is Not found</h3>


        }
      </div>
      {links?.length > 12 &&
        <Pagination
          history={history}
          location={location}
          page={currentPage}
          pageCount={calculateTotalPageCount(links && (((links?.length) ?? 0) !== 0) ? links.length : 10)}
        />
      }
    </div>
  )
}