export const formatDateTimeToISOString = (date, timeHh, timeMm, period) => {
  const dateObj = new Date(date);
  let hours = parseInt(timeHh, 10);
  const minutes = parseInt(timeMm, 10);

  if (period === 'PM' && hours !== 12) {
    hours += 12;
  }
  if (period === 'AM' && hours === 12) {
    hours = 0;
  }

  dateObj.setHours(hours);
  dateObj.setMinutes(minutes);
  dateObj.setSeconds(0);
  dateObj.setMilliseconds(0);

  return dateObj.toISOString();
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};

export const convertedDate = (date) => {
  const isodate = new Date(date);
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  const formattedDate = isodate.toLocaleDateString('en-GB', options);
  return formattedDate;
};

export const getMinDate = () => {
  const currentDate = new Date();

  // Convert current time to IST (UTC + 5:30)
  const istOffset = 5.5 * 60 * 60 * 1000;
  const currentIST = new Date(currentDate.getTime());

  const cutoffHour = 22; // 10 PM
  const cutoffMinutes = 45; // 45 minutes


  // If the current time is 10:45 PM IST or later, return day after tomorrow
  if (currentIST.getHours() > cutoffHour ||
    (currentIST.getHours() === cutoffHour && currentIST.getMinutes() >= cutoffMinutes)) {
    return new Date(currentDate.setDate(currentDate.getDate() + 2));
  }

  // Otherwise, return current date + 1
  return new Date(currentDate.setDate(currentDate.getDate() + 1));
}