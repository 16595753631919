import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Image } from "react-bootstrap";
import { MAX_LIMIT, START_PAGE, TARGETEDCONTENT, TARGETEDCONTENTS } from "../../../utils/Constant";
import { getCommonApi } from "../Api";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function CuratedContent() {
  const navigate = useHistory();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  async function featchData() {
    const data = await getCommonApi(`website/targetedContent/dashboard/list?pageNumber=${START_PAGE}&pageSize=8&withPosition=true&sortBy=most recent`);
    if (data.status === 200) {
      setData(data?.data?.data.list || []);

      setIsLoading(false);
    }
  }
  useEffect(() => {
    featchData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    slidesToScroll: 4,
    // slidesToShow:4,
    variableWidth: true,
    // autoplay: true,

    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
          // centerPadding: "60px",
        }
      },
      {
        breakpoint: 340,
        settings: {
          centerMode: true,
          // slidesToShow: 1,
          slidesToScroll: 1,
          // variableWidth: true,
        }
      },
    ]
  };
  return (
    <div className="online-presence-section  position-relative">
      <div className="container-mb position-relative">
        <p onClick={() => navigate.push({ pathname: `/${TARGETEDCONTENT}`, search: `?pageSize=18&pageNumber=${START_PAGE}&sortBy=Content&order=DESC` })} className="lato-medium fx-14 black-242 pointer home-explort-all">Explore All</p>

        <h4 className="fs-32-28 lato-semibold  primary text-center">
          Targeted Playlists
        </h4>
        <p className="lato-regular fs-22-16 black-242 text-center">
          Get relevant content as per client segments and needs
        </p>
        <div className="">
          <div className="social-media-card-wrapper ">
            <Slider className="targeted-content-slick-slider" {...settings} >

              {isLoading ?
                [...Array(4)].map((item) => {
                  return (
                    <div className=" target-imag-frame h-350">&nbsp;</div>


                  )
                })

                :
                data?.map((item, i) => {
                  // console.log(i)
                  return (
                    <div
                      className={`${item.thumbnailPath ? 'target-img-wrapper' : 'target-imag-frame-t h-350'}  p-0   `}
                      onClick={() =>
                        navigate.push({
                          pathname: `/content-type/All`,
                          search: `?key=${TARGETEDCONTENTS}&value=${encodeURIComponent(item.targetedContentName)}&pageSize=${MAX_LIMIT}&pageNumber=${START_PAGE}`,
                          state: item
                        })
                      }
                    >
                      {item.thumbnailPath ?
                        <Image
                          src={item.thumbnailPath}
                          alt={item.targetedContentName}
                        // className="responsive-card-width "
                        /> :
                        <p className=" lato-medium f-s-14 ">{item.targetedContentName}</p>
                      }

                    </div>
                  );
                })}

            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}
