import React from 'react'
import line from '../../assets/images/line-thirty.png'
import { Image } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { Footer, Header } from '../common/pages'
import { PageSubHeader, SearchDropDown } from '../common'
export default function SearchBasedLayout() {
  const param = useParams()

  const serchBased = {
    languages: <Languages />,
    amc: <Amc />
  }
  const contentType = serchBased[param.type]
  return (
    <div className='page-padding'>
      <Header />
      <div className=" d-flex align-items-center justify-content-center">
        <SearchDropDown getKeys={(keys) => console.log(keys)} dropdown={false} />
      </div>
      <div className="">
        {contentType}
      </div>
      <Footer />
    </div>
  )
}


function Languages() {
  return (
    <>
      <PageSubHeader title="Languages" count="15k" clases='based-layout' />
      <div className="container">
        <div className="language-box-wrapper">
          <div className="language-box">
            <h5 className='fs-75-41 lato-bold primary'>A</h5>
            <p className='lato-regular f-s-22 lh-28'>English</p>
          </div>
        </div>
      </div>
    </>
  )
}

function Amc() {
  return (
    <>
      <PageSubHeader title="Languages" count="15k" clases='based-layout' />
      <div className="container">
        <div className="language-box-wrapper">
          <div className="amc-box-wrapper">
            <div className="amc-img-box"></div>
            <div className="amc-content-wrapper">
              <h6 className='lato-medium fx-14 black-242'>Aditya Birla Sun Life Mutual Funds</h6>
              <div className="amc-count-frame">
                <p className='lato-regular f-s-14 black-242 m-0 text-center'>
                  10K<br />
                  <span className='f-s-10 op-7 black-242 text-center'>Videos</span>
                </p>
                <Image src={line} />
                <p className='lato-regular f-s-14 black-242 m-0 text-center'>
                  10K<br />
                  <span className='f-s-10 op-7 black-242 text-center'>Presentations</span>
                </p>
                <Image src={line} />
                <p className='lato-regular f-s-14 black-242 m-0 text-center'>
                  10K<br />
                  <span className='f-s-10 op-7 black-242 text-center'>Minimalists</span>
                </p>
                <Image src={line} />
                <p className='lato-regular f-s-14 black-242 m-0 text-center'>
                  10K<br />
                  <span className='f-s-10 op-7 black-242 text-center'>Infographics</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

