import React, { Component } from "react";
import { Modal, Image } from "react-bootstrap";
import close from "../../assets/images/profile-created-close.png";

export class ProfileCreated extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div
        className="modal show"
        style={{ display: "block", position: "initial" }}
      >
        <Modal.Dialog>
          <Modal.Header>
            <button
              type="button"
              className="close"
              onClick={this.props.onHide}
              aria-label="close"
            >
              <Image src={close} alt="close" />
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="profile-created-content">
              <div className="profile-created-description">
                <h2 className="fs-32-28 lato-semibold  primary text-center">
                  Kudos on leveling up your profile!
                </h2>
                <p className="fs-22-18 lato-regular  text-center ">
                  Profile perfection achieved! Enjoy personalized
                  recommendations, supercharged search, and hassle-free
                  interactions.
                </p>
                <button className="primary-btn">Explore Thefinpedia</button>
              </div>
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    );
  }
}

export default ProfileCreated;
