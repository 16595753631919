import React, { useEffect, useRef, useState } from "react"
import { fabric } from "fabric"
import { Dropdown, Overlay, Popover } from "react-bootstrap"
import { createPortal } from "react-dom"
import { ChromePicker, CirclePicker } from "react-color"
import { PropTypes } from "prop-types"
import "./canvas-history"
import "./canvas-placeholder"
import {
  widthIcon,
  heightIcon,
  textUploadIcon,
  imgUploadIcon,
  layerIcon,
  disabledLayerIcon,
  layerSendToBackIcon,
  layerBringToFrontIcon,
  undoIcon,
  disabledUndoIcon,
  redoIcon,
  disabledRedoIcon,
  plusIcon,
  minusIcon,
  textBoldIcon,
  textItalicIcon,
  textUnderlineIcon,
  textAlignLeftIcon,
  textAlignCenterIcon,
  textAlignRightIcon,
  textColor,
  textStrike,
  seperatorIcon,
  rotateSvg,
  duplicateIcon,
  deleteIcon,
  addIcon,
  eyeDropperIcon,
  zoomInIcon,
  zoomOutIcon
} from "../../../assets/images/canvas"


const popularFonts = [
  "Roboto",
  "Lobster",
  "Arial",
  "Times New Roman",
  "Verdana",
  "Pacifico",
  "Poppins",
  "Montserrat",
  "Open Sans",
  "Oswald",
  "Raleway",
  "Dancing Script"
]

const CanvasCobrand = ({ canvas, setCanvas, screen, selectedLanguage }) => {

  const [activeObject, setActiveObject] = useState(null)

  const [scale, setScale] = useState(100)

  const [settings, setSettings] = useState({
    text: {
      ...initialTextSettings
    },
    image: {
      ...initialImageSettings
    }
  })
  const [tooltipPosition, setTooltipPosition] = useState({
    top: 0,
    left: 0,
    visible: false
  })
  const inputRef = useRef(null)
  const [activeObjectSettings, setActiveObjectSettings] = useState({})
  const [showLayersSettings, setShowLayersSettings] = useState(false)
  const layerIconRef = useRef(null)
  const layerMobileIconRef = useRef(null)

  const getHistoryRedoIcon = () => {
    return canvas?.ref?.canRedo() ? redoIcon : disabledRedoIcon
  }
  const getHistoryUndoIcon = () => {
    return canvas?.ref?.canUndo ? undoIcon : disabledUndoIcon
  }

  const downloadHighResImage = () => {
    if (!canvas.ref) return
    const highResImage = canvas.ref.toDataURL({ format: "png", multiplier: 3 })
    const link = document.createElement("a")
    link.href = highResImage
    link.download = "cobrand-image.png"
    link.click()
  };


  useEffect(() => {
    const link = document.createElement("link")
    link.href = `https://fonts.googleapis.com/css2?${popularFonts
      .map((font) => `family=${font.replace(" ", "+")}`)
      .join("&")}&display=swap`
    link.rel = "stylesheet"
    document.head.appendChild(link)
    // function adding z-index to all objects
    if (!fabric.Canvas.prototype.updateZIndices) {
      fabric.Canvas.prototype.updateZIndices = function () {
        const objects = this.getObjects()
        objects.forEach((obj, index) => {
          addIdToObject(obj)
          obj.zIndex = index
        })
      }
    }



    fabric.Object.prototype.cornerSize = 6

    const rotateIcon = new Image()
    rotateIcon.src = rotateSvg

    rotateIcon.onload = function () {
      // Define the custom mtr control
      const customRotateControl = new fabric.Control({
        x: 0,
        y: -0.5,
        offsetY: -30,
        cursorStyle: "crosshair",
        actionHandler: fabric.controlsUtils.rotationWithSnapping,
        actionName: "rotate",
        render: renderIcon,
        cornerSize: 28
      })
      function renderIcon(ctx, left, top, styleOverride, fabricObject) {
        var size = this.cornerSize
        ctx.save()
        ctx.translate(left, top)
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle))
        ctx.drawImage(rotateIcon, -size / 2, -size / 2, size, size)
        ctx.restore()
      }


      fabric.Textbox.prototype.controls.mtr = customRotateControl
      fabric.Image.prototype.controls.mtr = customRotateControl


      canvas?.ref?.getObjects().forEach((obj) => {
        obj.setControlsVisibility({
          mtr: true
        })
      })

      // Render the canvas to ensure changes are applied
      canvas?.ref?.renderAll()
    }
  }, [])



  useEffect(() => {
    if (!canvas.ref) return
    canvas.ref.setWidth(canvas.canvasWidth)
    canvas.ref.setHeight(canvas.canvasHeight)
    canvas.ref.setBackgroundColor(canvas.backgroundColor || "#ffffff", () => {
      canvas.ref.renderAll()
    })
  }, [canvas.canvasWidth, canvas.canvasHeight, canvas.backgroundColor]);

  useEffect(() => {
    const canvasId = canvas?.uuid;
    let fabricCanvas;
    if (canvasId) {
      // Initialize or reuse the Fabric canvas
      const initCanvas = () => {

        fabricCanvas = new fabric.Canvas(canvasId, {
          width: canvas.canvasWidth || 800,
          height: canvas.canvasHeight || 450,
          // background: canvas.backgroundColor || "#ffffff",
        });
        fabricCanvas.setBackgroundColor(canvas.backgroundColor, () => {
          fabricCanvas.renderAll();
        });
        if (canvas.ref) {
          const canvasJson = canvas.ref.toJSON();
          if (Array.isArray(canvas.layers)) {
            canvasJson.objects = canvasJson.objects.map((obj, index) => {
              const layer = canvas.layers[index];
              if (layer) {
                return {
                  ...obj,
                  lockMovementX: layer.lockMovementX ?? obj.lockMovementX ?? false,
                  lockMovementY: layer.lockMovementY ?? obj.lockMovementY ?? false,
                  editable: layer.editable ?? obj.editable ?? false,
                  hasControls: layer.hasControls ?? obj.hasControls ?? true,
                  lockScalingX: layer.lockMovementX ?? obj.lockMovementX ?? false,
                  lockScalingY: layer.lockMovementX ?? obj.lockMovementX ?? false,
                  lockRotation: layer.lockMovementX ?? obj.lockMovementX ?? false,
                  selectable: !layer.lockMovementX,
                };
              }
              return obj; // If no matching layer, keep the object as is
            });
          }
          fabricCanvas.loadFromJSON(canvasJson, () => {
            fabricCanvas.getObjects().forEach((obj) => {
              if (obj.type === "rect") {
                obj.toPlaceholder("Click to add Image"); // Add placeholder behavior for rectangle
                obj.attachPlaceholder(fabricCanvas, fabricCanvas._offset); // Attach placeholder div
              } else if (obj.type === "circle") {
                obj.toPlaceholder("Click to add Image"); // Add placeholder behavior for circle
                obj.attachPlaceholder(fabricCanvas, fabricCanvas._offset); // Attach placeholder div
              }
            });
            fabricCanvas.renderAll();
          });
        }
        else if (Array.isArray(canvas.layers) && canvas.layers.length > 0) {

          const updatedLayers = canvas.layers.map((obj) => {
            return {
              ...obj,
              lockMovementX: obj.lockMovementX !== undefined ? obj.lockMovementX : false,
              lockMovementY: obj.lockMovementY !== undefined ? obj.lockMovementY : false,
              editable: obj.editable !== undefined ? obj.editable : false,
              hasControls: obj.hasControls !== undefined ? obj.hasControls : true,
              lockScalingX: obj.lockMovementX ?? obj.lockMovementX ?? false,
              lockScalingY: obj.lockMovementX ?? obj.lockMovementX ?? false,
              lockRotation: obj.lockMovementX ?? obj.lockMovementX ?? false,
              selectable: !obj.lockMovementX,
            };
          });
          const jsonData = {
            version: "5.2.1",
            objects: updatedLayers,
            background: canvas.backgroundColor || "#ffffff",
          };
          fabricCanvas.loadFromJSON(jsonData, () => {
            fabricCanvas.getObjects().forEach((obj) => {
              if (obj.type === "rect") {
                obj.toPlaceholder("Click to add Image"); // Add placeholder behavior for rectangle
                obj.attachPlaceholder(fabricCanvas, fabricCanvas._offset); // Attach placeholder div
              } else if (obj.type === "circle") {
                obj.toPlaceholder("Click to add Image"); // Add placeholder behavior for circle
                obj.attachPlaceholder(fabricCanvas, fabricCanvas._offset); // Attach placeholder div
              }
            });
            fabricCanvas.renderAll();
          });
        }

        setCanvas({
          ...canvas,
          ref: fabricCanvas,
        });

        return fabricCanvas;
      };

      fabricCanvas = initCanvas();
    }
    return () => {
      const placeholders = document.getElementsByClassName("placeholder");

      // Convert to a static array
      const placeholdersArray = Array.from(placeholders);

      // Remove each placeholder
      placeholdersArray.forEach((placeholder) => placeholder.remove());

      console.log("All placeholders removed:", placeholdersArray.length);
    };
  }, []);


  useEffect(() => {
    if (!canvas.ref) {
      return
    }
    const handleSelection = (e) => {
      const selectedObject =
        e.selected && e.selected.length > 0 ? e.selected[0] : null
      setActiveObject(selectedObject)
      if (selectedObject) {
        if (selectedObject.type === "textbox") {
          setActiveObjectSettings({
            fontSize: selectedObject.fontSize,
            fill: selectedObject.fill,
            fontFamily: selectedObject.fontFamily,
            fontWeight: selectedObject.fontWeight,
            fontStyle: selectedObject.fontStyle,
            underline: selectedObject.underline,
            linethrough: selectedObject.linethrough,
            textAlign: selectedObject.textAlign
          })
        } else if (selectedObject.type === "image") {
        }
        updateTooltipPosition(selectedObject)
      }
    }
    const handleObjectChange = () => {
      canvas?.ref?.updateZIndices()
    }

    const handleObjectMovingOrScaling = () => {
      const activeObject = canvas.ref.getActiveObject()
      if (activeObject) {
        updateTooltipPosition(activeObject)
      }
    }

    canvas.ref.on("object:moving", handleObjectMovingOrScaling)
    canvas.ref.on("object:scaling", handleObjectMovingOrScaling)
    canvas.ref.on("object:modified", handleObjectMovingOrScaling)
    canvas.ref.on("selection:created", handleSelection)
    canvas.ref.on("selection:updated", handleSelection)
    canvas.ref.on("selection:cleared", () => {
      setActiveObject(null)
    })
    canvas.ref.on("object:added", handleObjectChange)
    canvas.ref.on("object:modified", handleObjectChange)
    canvas.ref.on("object:removed", handleObjectChange)

    return () => {
      canvas.ref.off("selection:created", handleSelection)
      canvas.ref.off("selection:updated", handleSelection)
      canvas.ref.off("selection:cleared")
      canvas.ref.off("object:added", handleObjectChange)
      canvas.ref.off("object:modified", handleObjectChange)
      canvas.ref.off("object:removed", handleObjectChange)
      canvas.ref.off("object:moving", handleObjectMovingOrScaling)
      canvas.ref.off("object:scaling", handleObjectMovingOrScaling)
      canvas.ref.off("object:modified", handleObjectMovingOrScaling)
    }
  }, [canvas.ref])

  useEffect(() => {
    if (!canvas.ref) return
    const upperCanvas = canvas.ref.upperCanvasEl
    upperCanvas.style.transform = `scale(${scale / 100})`
  }, [scale]);

  useEffect(() => {
    if (!canvas.ref) return;

    const handleObjectMoving = (e) => {
      const obj = e.target;

      const canvasWidth = canvas.ref.getWidth();
      const canvasHeight = canvas.ref.getHeight();


      const objWidth = obj.getScaledWidth();
      const objHeight = obj.getScaledHeight();


      if (obj.left < 0) {
        obj.left = 0;
      }
      if (obj.top < 0) {
        obj.top = 0;
      }
      if (obj.left + objWidth > canvasWidth) {
        obj.left = canvasWidth - objWidth;
      }
      if (obj.top + objHeight > canvasHeight) {
        obj.top = canvasHeight - objHeight;
      }
    };


    canvas.ref.on("object:moving", handleObjectMoving);


    return () => {
      canvas.ref.off("object:moving", handleObjectMoving);
    };
  }, [canvas.ref]);


  const updateTooltipPosition = (object) => {
    if (!object) return;

    const canvasRect = canvas.ref.getElement().getBoundingClientRect();
    const objectRect = object.getBoundingRect();

    const top = objectRect.top + canvasRect.top - 60 + window.scrollY;
    const left = objectRect.left + canvasRect.left + objectRect.width - 30 + window.scrollX;

    setTooltipPosition({ top, left, visible: true });
  };

  const loadFont = async (font) => {
    return await document.fonts.load(`16px ${font}`)
  }

  const undo = () => {
    canvas.ref.undo()
  }

  const redo = () => {
    canvas.ref.redo()
  };

  const adjustTextboxWidth = (textbox) => {
    if (!textbox) return;
    const context = document.createElement("canvas").getContext("2d");
    context.font = `${textbox.fontWeight} ${textbox.fontStyle} ${textbox.fontSize}px ${textbox.fontFamily}`;
    const lines = textbox.text.split("\n");
    const maxWidth = lines.reduce((width, line) => {
      const lineWidth = context.measureText(line).width;
      return Math.max(width, lineWidth);
    }, 0);
    textbox.set({
      width: maxWidth + 10,
    });
    textbox.initDimensions();
  };

  const moveSelectedObject = (direction) => {
    if (!activeObject) return

    const objects = canvas.ref.getObjects()
    const object = objects.find((obj) => obj.id === activeObject?.id)
    if (object) {
      const currentIndex = objects.indexOf(object)
      if (direction === "up" && currentIndex < objects.length - 1) {
        const current = objects[currentIndex]
        objects[currentIndex] = objects[currentIndex + 1]
        objects[currentIndex + 1] = current
      } else if (direction === "down" && currentIndex > 0) {
        const current = objects[currentIndex]
        objects[currentIndex] = objects[currentIndex - 1]
        objects[currentIndex - 1] = current
      }

      const backgroundColor = canvas.ref.backgroundColor
      canvas.ref.clear()
      objects.forEach((obj) => {
        if (obj.type === "rect" || obj.type === "circle") {
          obj.attachPlaceholder(canvas.ref, canvas.ref._offset)
        }
        return canvas.ref.add(obj)
      })
      canvas.ref.backgroundColor = backgroundColor
      canvas.ref.renderAll()
      objects.forEach((obj, index) => {
        obj.zIndex = index
      })
      setActiveObject(object)
      canvas.ref.renderAll()
      canvas.ref.updateZIndices()
    }
  }

  const addText = () => {
    if (!canvas.ref) return

    const text = new fabric.Textbox("New Text", {
      left: 100,
      top: 100,
      width: settings.text.width,
      fontSize: settings.text.fontSize,
      fill: settings.text.fill,
      fontFamily: settings.text.fontFamily,
      fontWeight: settings.text.fontWeight,
      fontStyle: settings.text.fontStyle,
      underline: settings.text.underline,
      editable: true
    })

    canvas.ref.add(text)
    canvas.ref.setActiveObject(text)
    canvas.ref.renderAll()
  }

  const addImage = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        fabric.Image.fromURL(reader.result, (img) => {
          img.scaleToWidth(100)
          img.set({
            left: 50, top: 50, lockMovementX: false,
            lockMovementY: false,
            selectable: true,
            hasControls: true,
            editable: true,

          })
          canvas.ref.add(img)
        })
      }
      reader.readAsDataURL(file)
    }
  }

  return (
    <section className="cobrand-canvas-container">
      <div className="main-container">
        <div className="d-flex justify-content-between my-3 align-items-end">
          <div>
            {!activeObject ? (
              <div className="canvas-dimensions">
                <p>Background:</p>
                <p className="d-flex align-items-center ">
                  <img src={widthIcon} className="mx-2" alt="" />
                  Width: {canvas.canvasWidth}
                </p>
                <p className="d-flex align-items-center ">
                  <img src={heightIcon} className="mx-2" alt="" />
                  Height: {canvas.canvasHeight}
                </p>
              </div>
            ) : (
              <>
                {activeObject && activeObject.type === "textbox" && (
                  <div className="canvas-dimensions">
                    <p>Text:</p>
                    <p className="d-flex align-items-center ">
                      <img src={widthIcon} className="mx-2" alt="" />
                      Width:{" "}
                      {(activeObject.width * activeObject.scaleX).toFixed(1)}
                    </p>
                    <p className="d-flex align-items-center ">
                      <img src={heightIcon} className="mx-2" alt="" />
                      Height:{" "}
                      {(activeObject.height * activeObject.scaleY).toFixed(1)}
                    </p>
                  </div>
                )}
                {activeObject && activeObject.type === "image" && (
                  <div className="canvas-dimensions">
                    <p>Image:</p>
                    <p className="d-flex align-items-center ">
                      <img src={widthIcon} className="mx-2" alt="" />
                      Width:
                      {(activeObject.width * activeObject.scaleX).toFixed(1)}
                    </p>
                    <p className="d-flex align-items-center ">
                      <img src={heightIcon} className="mx-2" alt="" />
                      Height:
                      {(activeObject.height * activeObject.scaleY).toFixed(1)}
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
          <div>
            <div className="canvas-zoom">
              <img
                src={zoomOutIcon}
                alt=""
                onClick={() => setScale((p) => p - 20)}
              />
              {scale}%
              <img
                src={zoomInIcon}
                alt=""
                onClick={() => setScale((p) => p + 20)}
              />
            </div>
          </div>
        </div>
        <div className="playground-wrapper">
          <div className="playground" onClick={(e) => {

            if (canvas?.ref) {
              const target = canvas.ref.findTarget(e.nativeEvent, false);
              if (!target) {
                canvas.ref.discardActiveObject();
                canvas.ref.renderAll();
                setActiveObject(null);
              }
            }
          }}>
            <div className="canvas-main">
              <canvas
                style={{ scale: `${scale / 100}` }}
                id={`${canvas.uuid}`}
              ></canvas>
            </div>
          </div>
          <div className="canvas-sidebar">
            <p title="Upload Image">
              <img
                src={imgUploadIcon}
                alt=""
                onClick={() => inputRef?.current?.click()}
              />
              <input
                type="file"
                className="d-none"
                accept="image/*"
                onChange={addImage}
                ref={inputRef}
              />
            </p>
            <p title="Add Text" onClick={addText}>
              <img src={textUploadIcon} alt="" />
            </p>

            <p ref={layerIconRef} title="Layer">
              <img
                src={activeObject ? layerIcon : disabledLayerIcon}
                alt=""
                onClick={() => {
                  if (activeObject) setShowLayersSettings(true)
                }}
              />
              {layerIconRef.current && (
                <Overlay
                  show={showLayersSettings}
                  onHide={() => setShowLayersSettings(false)}
                  rootClose={true}
                  target={layerIconRef.current}
                  placement="right"
                >
                  <div className="layers-open">
                    <p title="Bring To Front" onClick={() => moveSelectedObject("up")}>
                      <img src={layerBringToFrontIcon} alt="" />
                    </p>
                    <p title="Send To Back" onClick={() => moveSelectedObject("down")}>
                      <img src={layerSendToBackIcon} alt="" />
                    </p>
                  </div>
                </Overlay>
              )}
            </p>
            <p onClick={undo} title="Undo">
              <img src={getHistoryUndoIcon()} alt="" />
            </p>
            <p onClick={redo} title="Redo">
              <img src={getHistoryRedoIcon()} alt="" />
            </p>
          </div>
          <div className="canvas-bottombar">
            {!activeObject && (
              <div className="main d-flex gap-8">
                <CustomColorInput
                  onChange={(e) =>
                    setCanvas({
                      ...canvas,
                      backgroundColor: e.target.value
                    })
                  }
                  value={canvas.backgroundColor}
                />
                <img src={seperatorIcon} className="show-mobile" alt="" />
                <div className="canvas-sidebar show-mobile gap-8">
                  <p title="Upload Image">
                    <img
                      src={imgUploadIcon}
                      alt=""
                      onClick={() => inputRef?.current?.click()}
                    />
                    <input
                      type="file"
                      className="d-none"
                      accept="image/*"
                      onChange={addImage}
                      ref={inputRef}
                    />
                  </p>
                  <p onClick={addText} title="Add Text">
                    <img src={textUploadIcon} alt="" />
                  </p>

                  <p ref={layerMobileIconRef} title="Layer">
                    <img
                      src={activeObject ? layerIcon : disabledLayerIcon}
                      alt=""
                      onClick={() => {
                        if (activeObject) setShowLayersSettings(true)
                      }}
                    />
                    {layerMobileIconRef.current && (
                      <Overlay
                        show={showLayersSettings}
                        onHide={() => setShowLayersSettings(false)}
                        rootClose={true}
                        target={layerMobileIconRef.current}
                        placement="top"
                      >
                        <div className="layers-open">
                          <p title="Bring To Front" onClick={() => moveSelectedObject("up")}>
                            <img src={layerBringToFrontIcon} alt="" />
                          </p>
                          <p title="Send To Back" onClick={() => moveSelectedObject("down")}>
                            <img src={layerSendToBackIcon} alt="" />
                          </p>
                        </div>
                      </Overlay>
                    )}
                  </p>
                  <p onClick={undo} title="Undo">
                    <img src={getHistoryUndoIcon()} alt="" />
                  </p>
                  <p onClick={redo} title="Redo">
                    <img src={getHistoryRedoIcon()} alt="" />
                  </p>
                </div>
              </div>
            )}
            {activeObject && activeObject.type === "textbox" && activeObject?.editable && (
              <div className="main">
                <div className="d-flex align-items-center gap-8">
                  <div className="show-desktop">
                    <CustomColorInput
                      onChange={(e) =>
                        setCanvas({
                          ...canvas,
                          backgroundColor: e.target.value
                        })
                      }
                      value={canvas.backgroundColor}
                    />
                  </div>
                  <div className="canvas-sidebar show-mobile gap-8">
                    <p ref={layerMobileIconRef} title="Layer">
                      <img
                        src={activeObject ? layerIcon : disabledLayerIcon}
                        alt=""
                        onClick={() => {
                          if (activeObject) setShowLayersSettings(true)
                        }}
                      />
                      {layerMobileIconRef.current && (
                        <Overlay
                          show={showLayersSettings}
                          onHide={() => setShowLayersSettings(false)}
                          rootClose={true}
                          target={layerMobileIconRef.current}
                          placement="top"
                        >
                          <div className="layers-open">
                            <p title="Bring To Front" onClick={() => moveSelectedObject("up")}>
                              <img src={layerBringToFrontIcon} alt="" />
                            </p>
                            <p title="Send To Back" onClick={() => moveSelectedObject("down")}>
                              <img src={layerSendToBackIcon} alt="" />
                            </p>
                          </div>
                        </Overlay>
                      )}
                    </p>
                    <p onClick={undo} title="Undo">
                      <img src={getHistoryUndoIcon()} alt="" />
                    </p>
                    <p onClick={redo} title="Redo">
                      <img src={getHistoryRedoIcon()} alt="" />
                    </p>
                  </div>
                  <p>
                    <img src={seperatorIcon} />
                  </p>
                  <p>
                    <Dropdown drop="up">
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {activeObjectSettings?.fontFamily}
                      </Dropdown.Toggle>

                      {createPortal(
                        <Dropdown.Menu
                          style={{ position: "absolute", zIndex: 1050 }}
                        >
                          {popularFonts.map((font) => (
                            <Dropdown.Item
                              key={font}
                              onClick={async () => {
                                if (!activeObject?.editable) return;

                                setActiveObjectSettings((p) => ({
                                  ...p,
                                  fontFamily: font
                                }))
                                await loadFont(font)
                                activeObject.set({ fontFamily: font })
                                canvas?.ref?.renderAll()
                              }}
                            >
                              {font}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>,
                        document.body // Render to the body
                      )}
                    </Dropdown>
                  </p>
                  <p className="font-size-main">
                    <input
                      type="number"
                      id="font-size-input"
                      value={activeObjectSettings?.fontSize || 16} // Default to 16 if fontSize is undefined
                      onChange={(e) => {
                        if (!activeObject?.editable) return;

                        const newFontSize = Number(e.target.value)

                        if (newFontSize > 0) {
                          setActiveObjectSettings((prevSettings) => ({
                            ...prevSettings,
                            fontSize: newFontSize
                          }))
                          if (activeObject) {
                            activeObject.set({ fontSize: newFontSize });
                            adjustTextboxWidth(activeObject);// Safely update fontSize

                            canvas?.ref?.renderAll() // Re-render the canvas
                          }
                        }
                      }}
                    />
                    <img
                      src={minusIcon}
                      onClick={() => {
                        if (!activeObject?.editable) return;
                        const newFontSize = activeObjectSettings.fontSize - 1
                        if (newFontSize > 0) {
                          setActiveObjectSettings((prevSettings) => ({
                            ...prevSettings,
                            fontSize: newFontSize
                          }))
                          activeObject.set({ fontSize: newFontSize });
                          adjustTextboxWidth(activeObject);// Safely update fontSize

                          canvas?.ref?.renderAll()
                        }
                      }}
                      className="before"
                      alt=""
                    />
                    <img
                      src={plusIcon}
                      onClick={() => {
                        if (!activeObject?.editable) return;
                        const newFontSize = activeObjectSettings.fontSize + 1
                        setActiveObjectSettings((prevSettings) => ({
                          ...prevSettings,
                          fontSize: newFontSize
                        }))
                        activeObject.set({ fontSize: newFontSize });
                        adjustTextboxWidth(activeObject);
                        canvas?.ref?.renderAll()
                      }}
                      className="after"
                      alt=""
                    />
                  </p>

                  <p>
                    <CustomColorInput
                      value={activeObjectSettings.fill}
                      onChange={(e) => {
                        if (!activeObject?.editable) return;
                        const newColor = e.target.value
                        setActiveObjectSettings((prevSettings) => ({
                          ...prevSettings,
                          fill: newColor
                        }))
                        activeObject.set({ fill: newColor })
                        canvas?.ref?.renderAll()
                      }}
                    >
                      <img src={textColor} alt="" />
                    </CustomColorInput>
                  </p>
                  <p
                    className={
                      activeObjectSettings.fontWeight === "bold" ? "active" : ""
                    }
                    onClick={() => {
                      if (!activeObject?.editable) return;
                      const newWetight =
                        activeObject.fontWeight === "bold" ? "normal" : "bold"
                      setActiveObjectSettings((prevSettings) => ({
                        ...prevSettings,
                        fontWeight: newWetight
                      }))
                      activeObject.set({ fontWeight: newWetight })
                      canvas?.ref?.renderAll()
                    }}
                    title="Bold"
                  >
                    <img src={textBoldIcon} alt="" />
                  </p>
                  <p
                    className={
                      activeObjectSettings.fontStyle === "italic"
                        ? "active"
                        : ""
                    }
                    onClick={() => {
                      if (!activeObject?.editable) return;

                      const newStyle =
                        activeObject.fontStyle === "italic"
                          ? "normal"
                          : "italic"
                      setActiveObjectSettings((prevSettings) => ({
                        ...prevSettings,
                        fontStyle: newStyle
                      }))
                      activeObject.set({ fontStyle: newStyle })
                      canvas?.ref?.renderAll()
                    }}
                    title="Italic"
                  >
                    <img src={textItalicIcon} alt="" />
                  </p>
                  <p
                    className={activeObjectSettings.underline ? "active" : ""}
                    onClick={() => {
                      if (!activeObject?.editable) return;

                      const newUnderline = !activeObject.underline
                      setActiveObjectSettings((prevSettings) => ({
                        ...prevSettings,
                        underline: newUnderline
                      }))
                      activeObject.set({ underline: newUnderline })
                      canvas?.ref?.renderAll()
                    }}
                    title="Underline"
                  >
                    <img src={textUnderlineIcon} alt="" />
                  </p>
                  <p
                    className={activeObjectSettings.linethrough ? "active" : ""}
                    onClick={() => {
                      if (!activeObject?.editable) return;

                      const newlinethrough = !activeObject.linethrough
                      setActiveObjectSettings((prevSettings) => ({
                        ...prevSettings,
                        linethrough: newlinethrough
                      }))
                      activeObject.set({ linethrough: newlinethrough })
                      canvas?.ref?.renderAll()
                    }}
                    title="Strike"
                  >
                    <img src={textStrike} alt="" />
                  </p>
                  <img src={seperatorIcon} alt="" />
                  <p
                    className={
                      activeObjectSettings.textAlign === "left" ? "active" : ""
                    }
                    onClick={() => {
                      if (!activeObject?.editable) return;

                      activeObject.set({ textAlign: "left" })
                      setActiveObjectSettings((prevSettings) => ({
                        ...prevSettings,
                        textAlign: "left"
                      }))
                      canvas?.ref?.renderAll()
                    }}
                    title="Align Left"
                  >
                    <img src={textAlignLeftIcon} alt="" />
                  </p>
                  <p
                    className={
                      activeObjectSettings.textAlign === "center"
                        ? "active"
                        : ""
                    }
                    onClick={() => {
                      if (!activeObject?.editable) return;

                      activeObject.set({ textAlign: "center" })
                      setActiveObjectSettings((prevSettings) => ({
                        ...prevSettings,
                        textAlign: "center"
                      }))
                      canvas?.ref?.renderAll()
                    }}
                    title="Align Center"
                  >
                    <img src={textAlignCenterIcon} alt="" />
                  </p>
                  <p
                    className={
                      activeObjectSettings.textAlign === "right" ? "active" : ""
                    }
                    onClick={() => {
                      if (!activeObject?.editable) return;

                      activeObject.set({ textAlign: "right" })
                      setActiveObjectSettings((prevSettings) => ({
                        ...prevSettings,
                        textAlign: "right"
                      }))
                      canvas?.ref?.renderAll()
                    }}
                    title="Align Right"
                  >
                    <img src={textAlignRightIcon} alt="" />
                  </p>
                </div>
              </div>
            )}
            {activeObject && activeObject.type === "image" && (
              <div className="main">
                <div className="d-flex align-items-center gap-8">
                  <div className="">
                    <CustomColorInput
                      onChange={(e) =>
                        setCanvas({
                          ...canvas,
                          backgroundColor: e.target.value
                        })
                      }
                      value={canvas.backgroundColor}
                    />
                  </div>{" "}
                  <p className="show-mobile">
                    <img src={seperatorIcon} />
                  </p>
                  <div className="canvas-sidebar show-mobile gap-8">
                    <p ref={layerMobileIconRef} title="Layer">
                      <img
                        src={activeObject ? layerIcon : disabledLayerIcon}
                        alt=""
                        onClick={() => {
                          if (activeObject) setShowLayersSettings(true)
                        }}
                      />
                      {layerMobileIconRef.current && (
                        <Overlay
                          show={showLayersSettings}
                          onHide={() => setShowLayersSettings(false)}
                          rootClose={true}
                          target={layerMobileIconRef.current}
                          placement="top"
                        >
                          <div className="layers-open">
                            <p title="Bring To Front" onClick={() => moveSelectedObject("up")}>
                              <img src={layerBringToFrontIcon} alt="" />
                            </p>
                            <p title="Send To Back" onClick={() => moveSelectedObject("down")}>
                              <img src={layerSendToBackIcon} alt="" />
                            </p>
                          </div>
                        </Overlay>
                      )}
                    </p>
                    <p onClick={undo} title="Undo">
                      <img src={getHistoryUndoIcon()} alt="" />
                    </p>
                    <p onClick={redo} title="Redo">
                      <img src={getHistoryRedoIcon()} alt="" />
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {tooltipPosition.visible && activeObject?.editable && (
        <div
          className="canvas-tooltip"
          style={{
            position: "absolute",
            top: tooltipPosition.top,
            left: tooltipPosition.left
          }}
        >
         {(activeObject?.type !=="rect" && activeObject?.type!=="circle") &&  <img
            src={duplicateIcon}
            title="Duplicate"
            alt=""
            onClick={() => {
              if (!activeObject) return

              activeObject.clone((clonedObject) => {
                if (clonedObject) {
                  clonedObject.set({
                    left: activeObject.left + 20,
                    top: activeObject.top + 20,
                    evented: true,
                    editable:true
                  })

                  canvas.ref.add(clonedObject)
                  canvas.ref.setActiveObject(clonedObject)
                  canvas.ref.renderAll()
                }
              })
            }}
          />}
          <img
            src={deleteIcon}
            title="Delete"
            alt=""
            onClick={() => {
              canvas.ref.remove(activeObject)
              canvas.ref.renderAll()
            }}
          />
        </div>
      )}
    </section>
  )
}

// CanvasCobrand.propTypes = {
// canvas: PropTypes.shape({
// width: PropTypes.number,
// height: PropTypes.number,
// backgroundColor: PropTypes.string,
// ref: PropTypes.object
// }).isRequired,
// setCanvas: PropTypes.func.isRequired
// }
export default CanvasCobrand

const addIdToObject = (object) => {
  if (!object?.id) {
    const timestamp = new Date().getTime()
    object.id = `${object?.type}_${timestamp}`
  }
}

const initialTextSettings = {
  fontSize: 16,
  fill: "#000000",
  fontFamily: "Arial",
  fontWeight: "normal",
  fontStyle: "normal",
  underline: false,
  editable: true,
  left: 50,
  top: 50,
  width: 100
}
const initialImageSettings = {
  left: 100,
  top: 100
}

const ColorPicker = ({ value, onChange }) => {
  const [activeTab, setActiveTab] = useState("circle")

  const handleEyePickerClick = async () => {
    if (!window.EyeDropper) {
      alert("Your browser does not support EyeDropper API.")
      return
    }

    try {
      const eyeDropper = new window.EyeDropper()
      const result = await eyeDropper.open()

      // setPickedColor(result.sRGBHex)
      onChange?.({ target: { value: result.sRGBHex } })
    } catch (e) {
      console.error("EyePicker failed:", e)
    }
  }

  const handleColorChange = (color) => {
    onChange?.({ target: { value: color.hex } })
  }

  return (
    <div
      style={{
        padding: "10px",
        borderRadius: "8px"
      }}
    >
      {activeTab === "circle" && (
        <>
          <CirclePicker
            color={value}
            onChangeComplete={handleColorChange}
            colors={[
              // Row 1 (Lightest shades of all colors)
              "#FFEBEE",
              "#E3F2FD",
              "#E8F5E9",
              "#FFFDE7",
              "#F3E5F5",
              "#FBE9E7",
              "#E0F2F1",
              // Row 2 (Slightly darker shades of all colors)
              "#FFCDD2",
              "#BBDEFB",
              "#C8E6C9",
              "#FFF9C4",
              "#E1BEE7",
              "#FFCCBC",
              "#B2DFDB",
              // Row 3 (Mid-tone shades of all colors)
              "#EF9A9A",
              "#90CAF9",
              "#A5D6A7",
              "#FFF59D",
              "#CE93D8",
              "#FFAB91",
              "#80CBC4",
              // Row 4 (Darker shades of all colors)
              "#E57373",
              "#64B5F6",
              "#81C784",
              "#FFF176",
              "#BA68C8",
              "#FF8A65",
              "#4DB6AC",
              "#000000",
              "#212121",
              "#424242",
              "#616161",
              "#9E9E9E",
              "#E0E0E0",
              "#FFFFFF",
              // Row 6 (Brown shades)
              "#3E2723",
              "#4E342E",
              "#5D4037",
              "#6D4C41",
              "#795548",
              "#A1887F",
              "#D7CCC8"
            ]}
          />
          <h4 className="f-s-12 my-4">Custom Color</h4>
          <div className="my-3 d-flex gap-6">
            <div
              onClick={handleEyePickerClick}
              style={{
                cursor: "pointer"
              }}
            >
              <img src={eyeDropperIcon} alt="" />
            </div>
            <div
              onClick={() => setActiveTab("sketch")}
              style={{
                cursor: "pointer"
              }}
            >
              <img src={addIcon} alt="" />
            </div>
          </div>
        </>
      )}

      {activeTab === "sketch" && (
        <ChromePicker color={value} disableAlpha onChange={handleColorChange} />
      )}
    </div>
  )
}
const CustomColorInput = ({ value, onChange, children = null }) => {
  const [showPicker, setShowPicker] = useState(false)
  const targetRef = React.useRef(null)

  return (
    <div ref={targetRef} style={{ display: "inline-block" }}>
      {/* Color preview (opens color picker) */}
      {children ? (
        <div onClick={() => setShowPicker(!showPicker)}>{children}</div>
      ) : (
        <div
          onClick={() => setShowPicker(!showPicker)}
          style={{
            width: "35px",
            height: "35px",
            backgroundColor: value,
            border: "1px solid #ccc",
            borderRadius: "50%",
            cursor: "pointer"
          }}
        ></div>
      )}

      {/* Overlay with ColorPicker */}
      <Overlay
        target={targetRef.current}
        show={showPicker}
        placement="top"
        rootClose
        onHide={() => setShowPicker(false)}
      >
        <Popover>
          <Popover.Content>
            <ColorPicker value={value} onChange={onChange} />
          </Popover.Content>
        </Popover>
      </Overlay>
    </div>
  )
}