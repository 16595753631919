import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Image, Modal } from "react-bootstrap";
import { useState } from "react";

import cancelIcon from '../../../assets/images/cancel-icon.svg'
import { commonAuthPostApi } from "../Api";
import { USER_TRACKING, UserTracking } from "../../../utils/Constant";
import { getToken } from "../../../utils/ManageToken";
import { toast } from "react-toastify";

import { Spinner } from "../../common/components";
import { downloadFromS3 } from "../../../utils/ReusableFunctions";
// import { createBrowserHistory } from 'history';

export default function VideoDetailsDownloadModal({
  selectedVariation = "",
  show = true,
  handleClose,
  contentType,
  detailsData = "",
  downloadPath = "",
  updatedThings,
  variation = [],
  languageColor = "",
  variationId = "",
  isPremium = "",
  limit = "",
  pending = "",
  ctx = null,
  getStats

}) {
  const navigate = useHistory();
  const history = useHistory();

  const [download, setDownload] = useState(false);
  const information = { "variation": variation, "data": detailsData, "languageColor": languageColor, "updatedThings": updatedThings }
  const [isChecked, setIsChecked] = useState(true);
  const [loading, setLoading] = useState(false);


  async function downloadHandler(e) {
    e.preventDefault();
    const info = {
      data: {
        contentUuid: detailsData.uuid,
        type: UserTracking.DOWNLOADED,
        contentVariationUuid: variationId ? variationId : detailsData?.defaultVariation.uuid,
      },
    };

    if (getToken()) {
      const response = await commonAuthPostApi(USER_TRACKING, info);

      if (!response.message) {
        if (downloadPath) {
          setLoading(true);

          downloadFromS3(downloadPath, getStats, setLoading, setDownload);
        }
        else {
          toast.error("No download file available");
        }
      } else {
        toast.error("No download file available");
      }
    } else {
      toast.error("You Need To Login");
    }
  }



  function navigateHandler() {
    if (getToken()) {
      // if(true){

      navigate.push({ pathname: `/cobranding`, state: information });

    } else {
      toast.error("You Need to Login");
    }
  }
  const isCoBrandDisabled = detailsData ? detailsData.branding?.brandingCode !== "NO-BRANDING" && (detailsData?.defaultVariation?.contentFilePath !== "") : false;
  // console.log(isCoBrandDisabled)


  useEffect(() => {
    const isOpen = sessionStorage.getItem('openSuccess');
    if (isOpen) {
      setDownload(true)

    }
    sessionStorage.removeItem('openSuccess')
  }, [])

  return (
    <>

      <Modal
        show={show}
        onHide={handleClose}
        className="details-downolad-modal  mb-py-20"
        style={{ top: download ? "27%" : "" }}
      >
        <div className={`header-download d-flex flex-row ${download ? "justify-content-end" : "justify-content-between"} px-4`}>
          {!download && <p className="lato-bold fs-24-12 primary ">Download Content?</p>}
          <Image onClick={handleClose} src={cancelIcon} alt="cancelIcon" className="pointer d-flex align-content-end " />
        </div>
        {!download && limit && pending && <p className=" lato-regular fs-14-12 black-242 mb-4 mx-4">{isPremium ? "Premium" : "Free"} Content Usage <span style={{
          color: pending <= 3 ? '#D72630'
            : 'black-242'
        }}> ({pending}/{limit})</span></p>}
        <Modal.Body className={`model-download-body ${download ? "d-flex align-items-center" : ""}`}>
          {download ? (
            <p className="lato-bold fx-22 pointer text-center d-flex justify-content-center pb-4 pb-md-0 ">
              Your {contentType.split('&id')[0]?.replace(/s\b/g, '')} has been downloaded
            </p>
          ) : (
            <div>
              <div className="check-heading d-flex align-items-center">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                />
                <label className="lato-bold fs-12-10 mt-2 mx-1 primary">
                  Compliance Undertaking:
                </label>
              </div>
              <p className="compliance lato-medium fs-13-11 black-242 op-5">
                Content provided by AMCs must remain unaltered, including their logos. Any modifications or misleading additions are strictly prohibited and may result in legal consequences. You will be solely responsible for any such actions; Thefinpedia and the AMC will not assume liability.
              </p>
              <p className="compliance-detail lato-medium fs-13-11 black-242 ">
                <span className="op-5">For details, please refer to the</span> <span onClick={() => history.push('/terms-conditions')} className="lato-medium fs-12-10 primary pointer">Terms & Conditions.</span>
              </p>


              <div className=" compliance-btns d-flex flex-row justify-content-between">
                <div
                  onClick={(e) => isChecked && (downloadPath ? downloadHandler(e) : toast.error("No download file available"))}
                  title={downloadPath ? " " : "No download file available"}
                  className={`d-flex text-center justify-content-center with-download lato-regular fs-14-12 pb-4 pointer text-decoration-none black-242 ${isChecked && downloadPath ? '' : 'pointer-none'}`}
                >
                  {loading ? <span className=" d-flex align-items-center gap-12"><Spinner /> Downloading...  </span> :
                    "No, I just want to download my files"}
                </div>
                <div
                  onClick={isChecked && isCoBrandDisabled ? navigateHandler : null}
                  className={`d-flex text-center justify-content-center lato-bold fs-14-12 with-cobrand-download ${isChecked && isCoBrandDisabled ? '' : 'disabled-Co-Brand-download'}`}
                >
                  Co-Brand and download
                </div>
              </div>

            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
