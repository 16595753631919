import { Image, Modal } from "react-bootstrap";
import CrossIcon from "../../assets/images/icon/cross-modal-icon.svg";


export default function PreviewPersonalizedModal({ preview, setPreview, previewData }) {
    const handleClose = () => setPreview(false);

    return (
        <div>
            <Modal className='preview-modal' centered show={preview} onHide={handleClose}>
                <Modal.Body>
                    <div className="preview-modal-heading d-flex flex-column">
                        <div className="cross-icon d-flex flex-row justify-content-between">
                            <p className="lato-bold fs-20-16 black-242">Preview</p>
                            <Image width={20} height={20} src={CrossIcon} onClick={handleClose} style={{ cursor: "pointer" }} />
                        </div >
                        <div className="d-flex flex-column align-items-center justify-content-center">

                            <Image
                                className="preview-image"
                                src={previewData?.thumbnailImage}
                                alt="image"
                            />

                            <p className="preview-text lato-medium fs-14-12 black-242 text-center">
                                {previewData?.description}
                            </p>
                        </div>



                    </div>


                </Modal.Body>
            </Modal>

        </div>
    )
}