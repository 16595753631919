import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';

import ImageChecked from "../../assets/images/icon/tick-icon.svg";

import { isCurrentDateAllowed, uploadFrequency } from '../../utils/Constant'
import { uploadDuration as uploadFrequencyOptions } from '../../utils/Constant';
import PostIcon from '../../assets/images/icon/post-icon.svg';

import PreviewIcon from "../../assets/images/icon/preview-icon.svg";
import ViewPlaylistModal from './ViewPlaylistModal';
import { getDetailsApi } from '../home/Api';
import TimeRange from "../../assets/images/icon/time-range.svg"
import DatePicker from "react-datepicker";
import CreatePlannerModal from './CreatePlannerModal';
import { UseMediaQuery } from './_utilis/useMediaQuery';
import { getMinDate } from './_utilis/platformsTime';

// import { convertedDate } from "./_utilis/platformsTime"
export default function PostPlannerCard({ data, index, handleClick, selectedCard, setSelectedCard, handlePlaylistSelect, handlePostSelect, selectedPlaylists, handleUploadDurationChange, isDynamic, feeds, playlist, dates, setDates, setPlaylistData, maxEndDate, handleUuidSelect, userDetails }) {
  const [duration, setDuration] = useState("");
  const [durationOptions, setDurationOptions] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [editDetail, setEditDetail] = useState({});
  const [checked, setChecked] = useState(false);
  const isMobile = UseMediaQuery('(max-width: 768px)');


  useEffect(() => {

    setDurationOptions(Object.keys(uploadFrequencyOptions).map(key => ({
      label: uploadFrequencyOptions[key],
      value: Number(key)
    })));


    const defaultDurationKey = Object.keys(uploadFrequencyOptions)[0];
    const defaultDuration = Number(defaultDurationKey);


    setDuration(defaultDuration);


    handleUploadDurationChange(data.uuid, defaultDuration);
  }, [data.uuid]);

  useEffect(() => {
    setChecked(selectedPlaylists.some(item => item.uuid === data.uuid));

  }, [selectedPlaylists, data.uuid]);

  const handlePreview = async (id) => {
    setPreviewOpen(true)



  }
  const handleEdit = async (id) => {
    const res = await getDetailsApi(`website/playlist/${id}`)

    if (res) {

      await setEditDetail(res?.data?.data)

      setOpen(true)
    }


  }

  const convertedDate = (date) => {
    const isodate = new Date(date);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = isodate.toLocaleDateString('en-GB', options);

    return formattedDate;
  };
  const getUploadFrequencyLabel = (value) => {
    const frequency = uploadFrequency.find(option => option.value === value);
    return frequency ? frequency.label : "";
  };



  const handleStartDateChange = (uuid, date) => {
    setDates(prevDates => {
      const updatedDates = {
        ...prevDates,
        [uuid]: {
          ...prevDates[uuid],
          startDate: date
        }
      };

      // Update selected playlists after changing the start date
      handlePlaylistSelect(uuid, updatedDates);

      return updatedDates;
    });
  };

  const handleEndDateChange = (uuid, date) => {
    setDates(prevDates => {
      const updatedDates = {
        ...prevDates,
        [uuid]: {
          ...prevDates[uuid],
          endDate: date
        }
      };

      // Update selected playlists after changing the end date
      handlePlaylistSelect(uuid, updatedDates);

      return updatedDates;
    });
  };

  const handleCheckboxClick = (e) => {
    e.stopPropagation();
    handleUuidSelect(data.uuid);
  };

  useEffect(() => {
    setDates(prevDates => {
      const updatedDates = { ...prevDates };

      // Check if startDate and endDate exist in data and update them in dates object
      if (data.startDate || data.endDate) {
        updatedDates[data.uuid] = {
          ...updatedDates[data.uuid],
          startDate: data.startDate ? new Date(data.startDate) : updatedDates[data.uuid]?.startDate,
          endDate: data.endDate ? new Date(data.endDate) : updatedDates[data.uuid]?.endDate,
        };
      }

      return updatedDates;
    });
  }, [data.startDate, data.endDate, data.uuid]);

  return (
    <div
      style={{
        backgroundColor: data?.userPostPlanner ? "#A5F5DC" : index === -1 ? "#270089" : "#ffffff",
        color: data?.userPostPlanner ? "#0000" : index === -1 ? "#0000" : "#0000",
        border: data?.userPostPlanner ? "" : index === -1 ? "" : "2px solid #A9A9A9"
      }}

      onClick={handleClick} className="planner-card-wrapper">
      {index === -1 ?
        <div className="card-first mb-0">

          <Image className="first-image" src={data?.thumbnailPath} />



        </div>

        :
        <div className="card-image mb-0 pointer">
          {!data.isCustomized ?
            <Image src={data?.thumbnailPath} onClick={() => { data?.isCustomized ? handleEdit(data?.uuid) : handlePreview(data?.uuid) }} className="card-img" />
            :
            <div className='card-plus text-center pointer' onClick={() => { data?.isCustomized ? handleEdit(data?.uuid) : handlePreview(data?.uuid) }}>
              <h5 title={data?.title} className="lato-bold fs-18-14 mt-2 mt-md-0 text-ellipsis-two white">
                {data?.title}
              </h5>
            </div>
          }
          <div className='planner-card-icons'>
            {data?.platforms && data?.platforms?.map((icon) => {
              return (
                <div className='card-icon' key={icon?.platform?.id}>
                  <Image width={10} height={10} src={icon?.platform?.iconImagePath} alt="icon" />
                </div>
              );
            })}

          </div>
          {isDynamic &&

            <div className='view-playlist d-flex flex-row'>
              {data?.userPostPlanner ? <div className='active-status'><p className='lato-medium fs-12-8 primary'>Active</p></div> : ""}
              <Image className='preview-image' onClick={() => { data?.isCustomized ? handleEdit(data?.uuid) : handlePreview(data?.uuid) }} src={PreviewIcon} alt="image" />
            </div>
          }
        </div>
      }

      <div className="card-content d-flex flex-row justify-content-between text-ellipsis-two">
        <h5 style={{ color: index === -1 ? "#ffffff" : "black" }} title={data?.title} className="lato-bold fs-18-14 text-ellipsis-two black-242">
          {data?.title}
        </h5>
        {isDynamic &&
          <div className="checkbox-container">
            {data?.userPostPlanner ?
              <>
                <input
                  type="checkbox"
                  id={`post_card_id_${index}`}
                  className="d-none"
                  checked={checked}
                  onChange={() => { handlePostSelect(data) }}


                />
                <label htmlFor={`post_card_id_${index}`} className={`cobrand-custom-checkbox bg-transparent mb-0 mt-1 border-black`}>
                  {<Image src={ImageChecked} alt="checked" className='d-block' />}
                </label>
              </>
              : <>
                <input
                  type="checkbox"
                  id={`post_card_id_${index}`}
                  className="d-none"
                  checked={checked}
                  onChange={handleCheckboxClick}


                />
                <label htmlFor={`post_card_id_${index}`} className={`cobrand-custom-checkbox bg-transparent mb-0 mt-1 border-black`}>
                  {checked && <Image src={ImageChecked} alt="checked" className='d-block' />}
                </label>

              </>}

          </div>
        }
      </div>
      {isDynamic &&
        <hr style={{ borderColor: "black" }} />
      }
      {isDynamic &&
        <div className="card-icon d-flex flex-row align-items-center">

          <Image src={PostIcon} className='post-icon' />

          <p style={{ color: "black" }} className="lato-bold fs-18-12 black-242">
            {getUploadFrequencyLabel(data?.uploadFrequency)} for
          </p>
        </div>
      }
      {isDynamic &&
        <div className='d-flex justify-content-between align-items-center'>
          {data?.userPostPlanner ?
            <div className='d-flex flex-row justify-content-center align-items-center'>
              <Image src={TimeRange} alt="image" />

              <p className='date-range lato-medium fs-12-8 black-242'>{convertedDate(data?.userPostPlanner?.startDate)} - {convertedDate(data?.userPostPlanner?.endDate)}</p>

            </div>
            :
            // <Dropdown onClick={(e) => e.stopPropagation()} onSelect={handleDropdownSelect}>
            //     <Dropdown.Toggle className="dropdown-btn post-card-dropdown d-flex justify-content-between" id="dropdown-basic">
            //         <h6 className="lato-semibold fs-14-12 black-4">{uploadFrequencyOptions[duration]}</h6>
            //         <Image src={DownIcon} alt="dropdown icon" className="dropdown-icon" />
            //     </Dropdown.Toggle>
            //     <Dropdown.Menu className="show-filter-dropdown">
            //         {durationOptions.map((option) => (
            //             <Dropdown.Item key={option.value} className="lato-regular fs-14-12 black-242" eventKey={option.value}>
            //                 {option.label}
            //             </Dropdown.Item>
            //         ))}
            //     </Dropdown.Menu>
            // </Dropdown>
            <div className="d-flex flex-row gap-5">

              <DatePicker
                selected={dates[data.uuid]?.startDate || (data.startDate ? new Date(data.startDate) : null)}
                onChange={(date) => handleStartDateChange(data.uuid, date)}
                selectsStart
                maxDate={dates[data.uuid]?.endDate || new Date(maxEndDate)}
                minDate={isCurrentDateAllowed ? new Date() : getMinDate()}
                // dateFormat="MMMM d, yyyy"
                placeholderText="Start date"
                className="form-control-date"
                showYearDropdown
                yearDropdownItemNumber={50}
                scrollableYearDropdown
                dateFormat={isMobile ? " d MMM , yyyy" : "d MMMM , yyyy"}
                onFocus={(e) => {
                  e.target.blur()
                  e.target.readOnly = true
                }}
              />
              <DatePicker
                selected={dates[data.uuid]?.endDate || (data.endDate ? new Date(data.endDate) : null)}
                onChange={(date) => handleEndDateChange(data.uuid, date)}
                selectsEnd
                minDate={dates[data.uuid]?.startDate || (isCurrentDateAllowed ? new Date() : getMinDate())}
                maxDate={new Date(maxEndDate)}
                // dateFormat="MMMM d, yyyy"
                placeholderText="End date"
                className="form-control-date"
                showYearDropdown
                yearDropdownItemNumber={50}
                scrollableYearDropdown
                dateFormat={isMobile ? " d MMM , yyyy" : "d MMMM , yyyy"}
                onFocus={(e) => {
                  e.target.blur()
                  e.target.readOnly = true
                }}

              />


            </div>

          }
          {(data?.amc?.circleImagePath || data?.user?.organizationLogoPath || data?.contentCreator?.thumbnailPath) && <div className='amc-logo'>
            {data?.isCustomized ?
              <Image style={{ marginLeft: "0.8rem" }} className="user-logo" width={32} height={32} src={data?.user?.organizationLogoPath} alt="" />
              :
              <Image style={{ marginLeft: "0.8rem" }} className="user-logo" width={32} height={32} src={data?.amc?.circleImagePath || data?.contentCreator?.thumbnailPath} alt="" />

            }
          </div>}
        </div>
      }

      {previewOpen && <ViewPlaylistModal previewOpen={previewOpen} setPreviewOpen={setPreviewOpen} playlistDetail={data} feeds={feeds} playlist={playlist} handlePlaylistSelect={handlePlaylistSelect} isPostPlanner={data?.userPostPlanner ? true : false} dates={dates} setDates={setDates} setPlaylistData={setPlaylistData} maxEndDate={maxEndDate} user={userDetails} />}
      {open && <CreatePlannerModal editDetail={data} open={open} setOpen={setOpen} feeds={feeds} setPlaylistData={setPlaylistData} userDetails={userDetails} />}
    </div>
  );
}