import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getDataApi } from './Api';
import { PageSubHeader } from '../common';
import { BreadCrumb, ContentTypesCards } from '../home/_utils';
import { AllShimmer } from '../common/components';
import { Footer, Header, NotFound } from '../common/pages';
import { MYCOLLECTION } from '../../utils/Constant';

export default function CollectionDetails() {
  const [collectionDetails, setCollectionDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const param = useParams();
  const location = useLocation();
  const history = useHistory();
  const [selectedItem, setSelectedItem] = useState(null);
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');


  // Fetch collection details
  useEffect(() => {
    const fetchCollectionDetails = async () => {
      try {
        const data = await getDataApi(`user-collection/${param.id.split("=")[1]}/items`);
        setCollectionDetails(data?.data);

        setTotalItems(data.data?.list?.reduce((acc, obj) => acc + (obj.count || 0), 0));
        setLoading(false);

        const queryParams = new URLSearchParams(location.search);
        const typeQuery = queryParams.get('type');
        if (typeQuery) {
          const matchedItem = data.data?.list?.find((item) => item.title === typeQuery);
          setSelectedItem(matchedItem);
        }
        else {

          setSelectedItem(null);
        }

      } catch (error) {
        setLoading(false);
      }
    };

    fetchCollectionDetails();
  }, [location.state?.isRender, location.search]);


  const viewDetail = (item) => {
    history.push(`${location.pathname}?type=${item.title}`);
  };
  const handleBreadCrumb = () => {
    if (type) {
      return [MYCOLLECTION, collectionDetails?.collection?.name, type]
    }
    else {
      return [MYCOLLECTION, collectionDetails?.collection?.name]
    }
  }

  return (
    <section className="page-padding">
      <Header />
      <div className="container">

        <BreadCrumb path={handleBreadCrumb()} clasese="mb-3-desk collection-page-breadcrumb" />

        {loading ? (
          <AllShimmer type="collectionDetails" noContainer={true} />
        ) : (
          <>
            <PageSubHeader
              title={collectionDetails?.collection?.name}
              clases="myactivity-pageheader p"
              count={totalItems}
              isSearch={false}
              data={collectionDetails}
              type
            />

            {selectedItem ? (
              <ContentTypesCards
                key={selectedItem.uuid}
                countTitle={selectedItem.title}
                hide={false}
                data={selectedItem.list}
                count={selectedItem.count}
                typeId={selectedItem.uuid}
                code={selectedItem.code}
                wrapperadd="result-wrap social"
                viewDetail={() => viewDetail(selectedItem)}
              />
            ) : collectionDetails?.list?.length === 0 ? (
              <NotFound type="collection" />
            ) : (
              collectionDetails?.list?.map((item, index) => (
                <ContentTypesCards
                  key={index}
                  countTitle={item.title}
                  hide={true}
                  data={item.list}
                  count={item.count}
                  typeId={item.uuid}
                  code={item.code}
                  viewDetail={() => viewDetail(item)}
                />
              ))
            )}
          </>
        )}
      </div>
      <Footer />
    </section>
  );
}
