import { useEffect } from "react"
import { useState } from "react"
import { Button, Dropdown, Image } from "react-bootstrap"
import DownIcon from "../../assets/images/dropdown-icon.svg";
import PremiumIcon from "../../assets/images/icon/premium-icon.svg";
import PremiumIconWhite from "../../assets/images/icon/white-premium.svg"
import { isEmptyObj } from "../../utils/ReusableFunctions";
import { durationObj, durationObjName, durationObjTopupName } from "../../utils/Constant";
import { getToken } from "../../utils/ManageToken";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
/* eslint-disable eqeqeq */


function SubscriptionPlanCard({ data, index, isActivePlan, isAciveTopUP, handleSubscription, planList, breakupAmount }) {
  const [duration, setDuration] = useState('');
  const [title, setTitle] = useState('');
  const [id, setId] = useState('');
  const [durationArr, setDurationArr] = useState({});
  const [planName, setPlanName] = useState(data?.subPremiumPlan[0]?.name || 'Select plan');
  const [planNameObj, setPlanNameObj] = useState({});


  const selectPlanHandler = (name) => {
    setPlanName(name || 'Select plan');
    const findPlanObj = data?.subPremiumPlan?.find((ele) => ele?.name === name);
    setDurationArr(findPlanObj);
    setDuration(findPlanObj?.subPremiumPlanVariation?.[0]?.resetInDays || '');
    setPlanNameObj(findPlanObj?.subPremiumPlanVariation?.[0] || {});
    setId(findPlanObj?.subPremiumPlanVariation?.[0]?.uuid || '');
  }
  const selectDurationHandler = (name) => {
    setDuration(name);
    const findPlanObj = durationArr?.subPremiumPlanVariation?.find((ele) => ele?.resetInDays === name);
    setPlanNameObj(findPlanObj);
  }
  const selectDurationHandlerTopup = (name, uuid) => {
    setDuration(name);
    const findPlanObj = durationArr?.subPremiumPlanVariation?.find((ele) => ele?.uuid === uuid);
    setPlanNameObj(findPlanObj);

  };
  useEffect(() => {
    if (isActivePlan?.length > 0 || (isAciveTopUP?.isActive && data?.isTopup)) {
      const activePlanInfo = (isAciveTopUP?.isActive && data?.isTopup) ? isAciveTopUP?.userSubscription[0] : isActivePlan[0]?.userSubscription;

      selectPlanHandler(activePlanInfo?.subPremiumPlan?.name || "");

      setDuration(activePlanInfo?.subPremiumPlanVariation?.resetInDays !== null ? activePlanInfo?.subPremiumPlanVariation?.resetInDays : '')
      setTitle(activePlanInfo?.subPremiumPlanVariation?.title || '')
      setId(activePlanInfo?.subPremiumPlanVariation?.uuid || '')
      setPlanNameObj(activePlanInfo?.subPremiumPlanVariation || {});

    } else {

      selectPlanHandler(data?.subPremiumPlan[0]?.name || "");
      setDuration(data?.subPremiumPlan?.[0]?.subPremiumPlanVariation?.[0]?.resetInDays !== null ? data?.subPremiumPlan?.[0]?.subPremiumPlanVariation?.[0]?.resetInDays : '')
      setId(data?.subPremiumPlan?.[0]?.subPremiumPlanVariation?.[0]?.uuid || '')
      setPlanNameObj(data?.subPremiumPlan?.[0]?.subPremiumPlanVariation?.[0] || {});
    }
  }, [])

  const isSubPremiumVarActive = () => (isActivePlan[0]?.userSubscription?.subPremiumPlanVariationId === planNameObj?.id);

  return (
    <div className={`new-plan-card-frame ${(isSubPremiumVarActive()) ? 'plan-activate' : ''}`}>

      {(isSubPremiumVarActive()) && <div className="ative-plan-sticker lato-regular fs-14-11 ">  Active </div>}
      <div className="header-new-pan-card  w-100">
        <h4 className={`lato-semibold  fs-22-18 text-center ${index == 1 ? 'primary' : 'black-242'}`}>  {index == 1 && <Image src={PremiumIcon} alt='' className="mb-2" />} {data?.name || 'basic Membership'} </h4>


        <div className={`plan-select-dropdown-frame  ${false && 'bg-A5F '} mt-3 w-100`}>
          {
            data?.subPremiumPlan?.length > 1 ?
              <Dropdown className="single-select-api w-100 mw-100 select-plan-dropdown ">
                <Dropdown.Toggle className="dropdown-btn 무자파르" id="dropdown-basic">
                  <h6 className="lato-semibold fx-14 black-242">{planName}</h6>
                  <Image src={DownIcon} alt="image" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="">
                  {data?.subPremiumPlan?.map((ele) => {
                    return (
                      <Dropdown.Item onClick={() => selectPlanHandler(ele?.name)} className="lato-regular fx-14 black-242 " href="" >
                        {ele?.name}
                      </Dropdown.Item>
                    )
                  })}

                </Dropdown.Menu>
              </Dropdown>
              :
              <p className=" lato-regular primary fs-14-13 text-center py-3">{data?.subPremiumPlan?.[0]?.name}</p>
          }
          {
            (durationArr?.subPremiumPlanVariation?.length > 1) && <>
              <p className=' lato-regular f-s-12 lh-20 black-242-5 mt-3 '>{data?.isTopup ? (durationArr?.isPostPlanner ? "Select Preferred Validity" : "Select Pack") : "Select billing frequency"}</p>
              <div className={`duration-select-frame ${''} `}>
                {
                  // ['Monthly','Quarterly','Half Yearly','Yearly'].map((ele)=>
                  durationArr?.subPremiumPlanVariation?.map((ele) => {
                    return (
                      <p
                        onClick={() => { data?.isTopup ? selectDurationHandlerTopup(ele?.pack, ele?.uuid) : selectDurationHandler(ele?.resetInDays); setTitle(ele?.title); setId(ele.uuid) }}
                        className={` lato-semibold fx-11 black-242-5 text-nowrap duration-box ${(id === ele?.uuid) ? 'active' : ''}`}> {(ele?.resetInDays === 0 && ele?.isPostPlanner) && <Image width={12} height={12} src={id == ele?.uuid ? PremiumIconWhite : PremiumIcon} className="mb-1" />} {data?.isTopup ? (durationArr?.isPostPlanner ? durationObjTopupName[ele?.resetInDays] : ele?.pack) : durationObj[ele?.resetInDays]}</p>
                    )
                  }
                  )
                }
              </div>
            </>
          }


        </div>

        <div className="new-plan-card-body" dangerouslySetInnerHTML={{ __html: planNameObj?.description }}>
        </div>
      </div>



      <div className="new-plan-card-footer d-flex flex-column justify-content-end">
        {!getToken() && data.level === 0 && durationArr?.isDefault ? (
          <Link
            to={{
              pathname: "/onboarding",
              state: { signup: true }  // or any value you want to pass
            }}
          >
            <div className="d-flex justify-content-center">
              <Button className="primary-btn px-5">Register</Button>
            </div>
          </Link>
        ) : (
          isEmptyObj(planNameObj) && (
            <h5 className="lato-semibold fs-18-16 primary text-center mb-0">
              {(planNameObj?.discountedPrice === 0 && !data.isTopup) ? (
                'Free'
              ) : (
                <>
                  {parseInt(planNameObj?.price ?? 0) !== 0 && (
                    <s className="fs-14-13 black-242">₹{planNameObj?.price}</s>
                  )}
                  {
                    data?.isTopup && planNameObj?.discountedPrice !== 0
                      ? `₹${planNameObj?.discountedPrice}`
                      : planNameObj?.discountedPrice === 0
                        ? null
                        : `₹${planNameObj?.discountedPrice}/${durationObjName[duration]}`
                  }


                </>
              )}
            </h5>
          )
        )}
        <div className="new-plan-card-bottom text-center" dangerouslySetInnerHTML={{ __html: planNameObj?.title }}>
        </div>



        <div className=" d-flex justify-content-center pt-4">
          {(planNameObj?.discountedPrice > 0 || isActivePlan[0]?.userSubscription?.level === 0 && (planNameObj?.discountedPrice > 0)) &&
            <Button disabled={isSubPremiumVarActive() && data?.level !== 2}

              className={`primary-btn mw-145 text-center pointer  ${[(isActivePlan[0]?.userSubscription?.subPremiumPlanVariationId), ...(isAciveTopUP?.map((ele) => ele.userSubscription?.subPremiumPlanVariationId) || [])].includes(planNameObj?.id)}`} onClick={() => handleSubscription({
                "premiumPlanUuid": data?.uuid,
                "subPremiumPlanUuid": durationArr?.uuid,
                "subPremiumPlanVariationUuid": planNameObj?.uuid,
                "isTopup": data?.isTopup || false,
                "breakupAmount": breakupAmount
                // "couponCode": "OFF 10"
              })}>{
                isSubPremiumVarActive()
                  ? (data?.level === 2 ? 'Buy Now' : (data?.level === 1 ? 'Subscribed' : (data.level === 0 && planNameObj?.discountedPrice > 0 ? "Subscribed" : "")))
                  : (data?.level === 2 ? 'Buy Now' : (data?.level === 1 ? 'Subscribe' : (data.level === 0 && planNameObj?.discountedPrice > 0 ? "Subscribe" : "")))
              }
            </Button>
          }
        </div>
      </div>

    </div>
  )
}

export default SubscriptionPlanCard;