import React from "react";
import notFound from "../../assets/images/No-results-found.gif";
import { useHistory } from "react-router-dom";
import { Button, Image } from "react-bootstrap";
import { SlideFilter } from "../home/staticComponents";
import { useState } from "react";
import { Footer, Header, Pagination } from "../common/pages";
import { EventCard } from "./";
import { useEffect } from "react";
import { getCommonApi } from "./Api";
import { formatNumber } from "../../utils/ReusableFunctions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { AllShimmer } from "../common/components";
import { EVENTS } from "../../utils/Constant";

/* eslint-disable react-hooks/exhaustive-deps */
export default function EventPage() {

  return (
    <div className="social-media-main-wrapper page-padding">
      <Header />
      <SlideFilter placeholder="Search in Events" dropdown={false} component={<EventAllCard />} />
      <Footer />
    </div>
  );
}
function EventAllCard() {
  const location = useLocation();
  const history = useHistory();
  const getParam = new URLSearchParams(location.search);
  const [eventData, setEventData] = useState();
  const [isLoading, setIsLoading] = useState(true)
  const getEventApiData = async () => {
    const res = await getCommonApi(`website/events${location.search}`);

    if (res.status === 200) {
      setEventData(res?.data?.data);
      setIsLoading(false)
    } else (
      setIsLoading(false)
    )
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true)
    getEventApiData()

  }, [location?.search])

  return (
    <>
      <div className="filter-slide-event-wrapper">

        <h3
          className={`lato-regular fs-36-24 ${eventData?.length > 0 ? "mb-5" : "mb-0"
            }`}
        >
          Events
          <span className="lato-semibold fs-14-12 black-242">
            &nbsp;&nbsp;
            {formatNumber(eventData?.length)}
          </span>
        </h3>


        <div className="events-card-wrapper">
          {isLoading ? <AllShimmer type={EVENTS} /> :
            (eventData?.length > 0 ?
              eventData?.list?.map((item, i) => {
                return (<>
                  {
                    (i === 4 || i === 12) && (eventData?.bannerAds?.[0] || eventData?.bannerAds?.[1]) &&
                    <a rel="noreferrer" className="banner-main-wrapper" href={eventData?.bannerAds[i === 4 ? 0 : 1]?.redirectionLink} target="_blank">
                      <Image src={eventData?.bannerAds[i === 4 ? 0 : 1]?.bannerImagePath} alt="banner Image" />
                    </a>
                  }
                  <EventCard key={i} data={item} />
                </>
                )
              })
              :
              <div className="result-not-found-section my-0 mt-0 text-center mx-auto">
                <div className="not-found-image">
                  <img src={notFound} className="w-100" alt="image" />
                </div>
                <p className="text-center lato-regular fs-24-18 primary fw-400 mb-0">No results found</p>
                <p className="text-center lato-regular fs-24-18 primary fw-400 mb-3">Even our detective's stumped! Ready for the next clue?</p>
                <Button onClick={(e) => { e.preventDefault(); history.push('/') }} className=" primary-btn mx-auto">Back to Homepage</Button>
              </div>)
          }
        </div>

        {eventData?.length > 10 &&
          <Pagination
            history={history}
            location={location}
            page={getParam.get('pageNumber')}
            pageCount={Math.ceil(eventData?.length / 10)}
          />
        }

      </div>
    </>
  );
}