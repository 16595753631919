import React, { useEffect, useState } from 'react'
import Header from './Header';
import { Tab, Tabs } from 'react-bootstrap';
import Footer from './Footer';
import ContentNotification from './ContentNotification';
import EventNotification from './EventNotification';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { BreadCrumb } from '../../home/_utils';
import { NotificationSelection, Spinner } from '../components';
import { postNotificationData } from "../../home/Api";
import { isResOk } from '../../../utils/ReusableFunctions';
import { toast } from 'react-toastify';

/* eslint-disable react-hooks/exhaustive-deps */



export default function NotificationSettingPage() {
  const { type } = useParams();

  const [selectedKey, setselectedKey] = useState(type || "Content");
  const [platformOptions, setPlatformOptions] = useState({
    WebPushNotification: false,
    Email: false,
    SMS: false,
    WhatsApp: false
  });
  const [saving, setSaving] = useState(false);
  const [notificationPost, setNotificationPost] = useState({ contentData: [], eventData: [] });
  const history = useHistory();
  useEffect(() => {
    if (type !== selectedKey) {
      setselectedKey(type);
    }
  }, [type]);
  const handleDataFromChild = (data, type) => {
    setNotificationPost(prevState => ({
      ...prevState,
      [type]: data
    }));
  };
  const handleSelect = (key) => {
    if (key !== selectedKey) {
      setselectedKey(key);
      history.push(`/notification-setting/${key}`);
    }
  };
  const handleSave = async () => {
    setSaving(true)
    const concatData = [...notificationPost.contentData, ...notificationPost.eventData];
    // console.log(concatData);
    const postData = {
      "data": {
        "platformOptions": platformOptions,
        "settings": concatData
      }
    }
    const res = await postNotificationData("inAppNotification/settings", postData);
    if (isResOk(res.status)) {
      // console.log(res);
      setSaving(false)
      toast.success("Successfully Updated");
    } else {
      setSaving(false)
    }
  }
  const toggleChangeHandler = (e) => {
    setPlatformOptions(prevOptions => ({
      ...prevOptions,
      [e.target.name]: e.target.checked
    }));
  };
  //rendering content when the button is clicked
  return (
    <section className='page-padding'>
      <Header />
      <div className="container">
        <BreadCrumb path={["Notifications", 'setting']} clasese="mb-3-desk" />
        <h3 className=' notification-text lato-regular fs-32-28 black-242 '>Notifications settings</h3>
        {/* <p className='lato-regular fs-18-14 black-242 op-5'>The insights you need to keep ahead in the game.</p> */}
        <div className="main-notification-tab-page-frame">
          <div style={{ marginTop: "4rem" }} className=" treanding-tab-wrapper">
            <Tabs
              defaultActiveKey={"Content"}
              activeKey={selectedKey}
              onSelect={handleSelect}
              class="notification-tabs"
              //   onSelect={(e) => { (e === "Content") ? history.push('finpedia-trending') : history.push(GLOBAL_TRENDING+'?pageNumber=1&pageSize=18') }}
              id="notification-tabs"
            >
              <Tab
                eventKey="Content"
                class='lato-regular fs-18-14 black-242 '
                title="Content">
                <NotificationSelection platformOptions={platformOptions} toggleChangeHandler={toggleChangeHandler} />

                <ContentNotification sendDataToParent={(data) => handleDataFromChild(data, 'contentData')} updatePlatformOptions={(data) => setPlatformOptions(data)} />
              </Tab>
              <Tab eventKey="Event" title="Event">
                <NotificationSelection platformOptions={platformOptions} toggleChangeHandler={toggleChangeHandler} />

                <EventNotification sendDataToParent={(data) => handleDataFromChild(data, 'eventData')} />
              </Tab>
            </Tabs>
          </div>
          <div className=" d-flex align-items-center justify-content-end py-3">
            <button className={`primary-btn ${false ? 'disabled' : ''}`} onClick={handleSave} disabled={false}>{saving ? <span className=' d-flex align-items-center gap-12'><Spinner /> Saving..</span> : 'Save'} </button>
          </div>

        </div>
      </div>
      <Footer />
    </section>
  )
}
