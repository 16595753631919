import React, { useEffect, useState } from 'react';
import { Image, Modal } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import close from "../../../assets/images/cancel-icon.svg";
import logo from "../../../assets/images/brand-logo.svg";
import email from "../../../assets/images/mail-send-icon.svg";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const loginWith = [
  // { text: "Continue with Google", icon: google, link: `https://${API_BASE_URL}finpedia/user/auth/google`, target: "_blank" },
  // { text: "Continue with Facebook", icon: facebook, link: "" },
  // { text: "Continue with Linedin", icon: linedin, link: "" },
  { text: "Sign up", icon: email, link: "/onboarding", target: "_self" }
];

export default function SignUp({ show, handleClose }) {
  const location = useLocation();
  const history = useHistory();
  const [currentRoute, setCurrentRoute] = useState('');

  useEffect(() => {
    let pathToStore = location.pathname;


    if (pathToStore.includes("editor")) {

      pathToStore += location.search;
    }

    setCurrentRoute(pathToStore);
    sessionStorage.setItem('previousRoute', pathToStore);
  }, [location]);


  const handleSignUpClick = () => {
    let pathToStore = location.pathname;

    if (pathToStore.includes("editor") || pathToStore.includes("events")) {
      pathToStore += location.search;

    }
    sessionStorage.setItem('previousRoute', pathToStore);
    history.push({
      pathname: "/onboarding",
      state: { register: true }
    });
  };
  const handleLogin = () => {
    let pathToStore = location.pathname;

    if (pathToStore.includes("editor") || pathToStore.includes("events")) {
      pathToStore += location.search;
    }

    sessionStorage.setItem('previousRoute', pathToStore); // Store route before redirect


  };

  return (
    <div className=''>
      <Modal className="signup-custom-model" show={show} onHide={handleClose}>
        <Modal.Body className="signup-model-body">
          <Image src={close} onClick={handleClose} alt="canle icon" className="close-btn" />
          <Image src={logo} onClick={() => { }} alt="logo" className="brand-logo" />
          <h5 className='lato-semibold fs-32-24 black-242'>Sign up to continue</h5>
          <h6 className='lato-regular fs-16-14 black-242'>Already a member?
            <Link to="/onboarding">
              <span className='primary pointer' onClick={() => { handleLogin() }
              }>
                Log in
              </span>
            </Link>
          </h6>
          <div className="login-accound-wrapper">
            {loginWith?.map((item, i) => {
              return (
                <div key={i} className="continue-with-account lato-regular fs-16-14 black-242" onClick={handleSignUpClick}>
                  <Image src={item.icon} alt='sign up' />
                  {item.text}
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
