import React, { useEffect, useRef, useState, useMemo } from 'react'
import RangeFields from './components/RangeFields'
import BarChart from './components/BarChart'
import RadioFields from './components/RadioFields'
import { mfObj } from '../../utils/Constant';
import { Image } from 'react-bootstrap'
import { CheckToolsSubscription, ShowShare, ToolsScreenshotHeader, captureImageHandler, checkToolsSubscription, debounce, formatCurrency, getUser, toolsDisclaimer, toolsInvestorDetails, toolsScreenshotHeader } from '../../utils/ReusableFunctions';


export default function SIPCalculator({ calculator, textLogo = '', imageData, setImageData, isDisable, setIsDisable, setAlertData, setIsAlert, setModalData }) {
  const graphDiv = useRef(null);
  let [frequency, setFrequency] = useState("monthly");
  let [roi, setRoi] = useState(8);
  let [sipAmount, setSipAmount] = useState(30000);
  let [investmentPeriod, setInvestmentPeriod] = useState(10);

  let [tai, setTai] = useState(0);
  let [tac, setTac] = useState(0);
  const selectOption = [
    { value: "monthly", label: "Monthly" },
    // { value: "quarterly", label: "Quarterly" },
  ];

  useEffect(() => {

    onSubmitAction(frequency, roi, sipAmount, investmentPeriod);

  }, [frequency, roi, sipAmount, investmentPeriod])


  const handleChange = (value, name) => {
    if (name === "frequency") {
      frequency = value
    }
    if (name === "roi") {
      roi = value
    }
    if (name === "sipAmount") {
      sipAmount = value
    }
    if (name === "investmentPeriod") {
      investmentPeriod = value
    }
    onSubmitAction(frequency, roi, sipAmount, investmentPeriod);
  }

  const onSubmitAction = (frequency, roi, sipAmount, investmentPeriod) => {
    let mf = mfObj[frequency];
    let tenure = investmentPeriod * mf;
    const toDivideROI = (roi / mf) / 100;
    const toMultiplyROI = ((roi / mf) + 100) / 100;
    tai = Math.round(sipAmount * investmentPeriod * mf);
    tac = Math.round(((((((roi / mf) + 100) / 100) ** tenure) - 1) / toDivideROI) * sipAmount * toMultiplyROI);
    setFrequency(frequency)
    setRoi(roi);
    setSipAmount(sipAmount);
    setInvestmentPeriod(investmentPeriod);
    setTac(tac)
    setTai(tai)
  };

  let downloadScreenshotFunc = null;

  const setDownloadScreenshotFunc = (func) => {
    downloadScreenshotFunc = func;
  };
  const barChart = useMemo(() => {
    return (
      <BarChart
        info={{
          labels: ["Total Invested Amount", "Total Investment Value"],
          data: [tai, tac],
        }}
      />
    );
  }, [tai, tac]);
  return (
    <div>
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART START ---------------------------------------------- */}
      <div className="calculator-result-main-wrapper">
        <div className="calculator-component-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Calculator
          </h4>


          <RangeFields
            label="SIP Amount"
            name={"sipAmount"}
            value={sipAmount}
            minVal={500}
            maxVal={1000000}
            step={500}
            getValue={debounce(handleChange)}
            showComma
          />

          <RangeFields
            label="Expected Rate of Return"
            name={'roi'}
            value={roi}
            minVal={4}
            maxVal={50}
            // step={1}
            unit="%"
            getValue={debounce(handleChange)}
          />
          <RadioFields
            options={selectOption}
            initialValue={"monthly"}
            onSelectionChange={(v) => { setFrequency(v) }}
            label="SIP Frequency"
          />
          <RangeFields
            label="Investment Tenure"
            name={"investmentPeriod"}
            value={investmentPeriod}
            minVal={1}
            maxVal={50}
            unit="Year"
            // step={1}
            getValue={debounce(handleChange)}
          />

        </div>
        <div className="result-graph-frame" ref={graphDiv}>
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7" >
            Results{" "}
            {<ShowShare calculator isDisable={isDisable} setIsDisable={setIsDisable} setAlertData={setAlertData} setIsAlert={setIsAlert} setModalData={setModalData} clickHandler={async () => {
              CheckToolsSubscription(textLogo, downloadScreenshotFunc, calculator?.uuid, imageData, setIsDisable, setAlertData, setIsAlert, setModalData)
            }} />}
          </h4>
          <div className="result-graph-wrapper" >
            <div className={`graph-1-frame ${false ? "w-auto" : "w-100"}`}>

              {barChart}
            </div>
          </div>
          {sipText(sipAmount, investmentPeriod, tai, tac, roi)}

        </div>
      </div>

      <div className="make-none">
        <CalculatorOutputScreenShot textLogo={textLogo} frequency={frequency} tai={tai} tac={tac} roi={roi} investmentPeriod={investmentPeriod} sipAmount={sipAmount} onDownloadScreenshot={setDownloadScreenshotFunc} setImageData={setImageData} />
      </div>
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART END ---------------------------------------------- */}
    </div>
  )
}




function CalculatorOutputScreenShot({ textLogo, frequency, tai, tac, roi, sipAmount, investmentPeriod, onDownloadScreenshot, setImageData }) {
  const { cobrandingLabelling, investor } = textLogo;
  const [signup, setSignup] = useState(false);
  const screenshotRef = useRef(null);

  useEffect(() => {
    if (onDownloadScreenshot) {
      captureImageHandler(onDownloadScreenshot, screenshotRef, setSignup, setImageData);
    }
  }, [textLogo, frequency, roi, sipAmount, investmentPeriod, screenshotRef]);
  return (<>


    <div ref={screenshotRef} id='content-to-print' className="tools-calulator-output-screenshot-frame ">
      {ToolsScreenshotHeader(cobrandingLabelling)}
      <div className={`graph-1-frame bg-gray ${false ? "w-auto" : "w-100"}`}>
        <BarChart
          info={{
            labels: ["Total Invested Amount", "Total Investment Value"],
            data: [tai, tac],
          }}
        />
      </div>
      {toolsInvestorDetails(investor)}
      <br /><br />
      {sipText(sipAmount, investmentPeriod, tai, tac, roi)}
      {toolsDisclaimer()}

    </div>

  </>
  )
};

function sipText(sipAmount, investmentPeriod, tai, tac, roi) {
  return (
    <p className=" f-s-16 lh-24 black-242 op-7 pt-2 text-center">
      With a monthly SIP of <span className=" lato-semibold primary">₹ {formatCurrency(sipAmount)}</span>, after <span className=" lato-semibold primary">{investmentPeriod}</span> years of investment, your total investment amount will be <span className=" lato-semibold primary">₹ {formatCurrency(tai)} </span> and its value will be <span className=" lato-semibold primary">₹ {formatCurrency(tac)} </span>, if the invested corpus keeps growing at <span className=" lato-semibold primary"> {roi} </span>% for the investment tenure.
    </p>

  )
}