import React, { useEffect, useState } from 'react'
import RangeFields from './components/RangeFields'
import BarChart from './components/BarChart'
import { CheckToolsSubscription, ShowShare, ToolsScreenshotHeader, captureImageHandler, debounce, formatCurrency, toolsInvestorDetails } from '../../utils/ReusableFunctions';
import { useMemo } from 'react';
import { useRef } from 'react';
/* eslint-disable eqeqeq */


export default function TargetAmount({ calculator, textLogo = '', imageData, setImageData, isDisable, setIsDisable, setAlertData, setIsAlert, setModalData }) {

  let [targetCorpus, setTargetCorpus] = useState(10000000);
  let [rateOfInterest, setRateOfInterest] = useState(12);
  let [tenure, setTenure] = useState(10);
  let [targetCorpusResult, setTargetCorpusResult] = useState(targetCorpus);
  let [targetCorpusResult2, setTargetCorpusResult2] = useState(targetCorpus);
  let [fixedSIP, setFixedSIP] = useState(43041);
  let [growingSIP, setGrowingSIP] = useState(29636);

  let [totalSIP, setTotalSIP] = useState(5164920);
  let [totalGrowingSIP, setTotalGrowingSIP] = useState(6590277);
  let [lumpsumInvestment, setLumpsumInvestment] = useState(3219732);

  useEffect(() => {
    onSubmitAction(targetCorpus, rateOfInterest, tenure)
  }, [targetCorpus, rateOfInterest, tenure])

  const handleChange = (value, name) => {

    if (name === "targetCorpus") {
      targetCorpus = value
    }

    if (name === "rateOfInterest") {
      rateOfInterest = value
    }
    if (name === "tenure") {
      tenure = value
    }

    onSubmitAction(targetCorpus, rateOfInterest, tenure);
  }

  const onSubmitAction = (targetCorpus, rateOfInterest, tenure) => {
    let SIPgrowthRate = 0.1;
    let roi = Number(rateOfInterest) / 100;
    let roi2 = Number(rateOfInterest);

    let roiConstant = ((roi / 12) * 100 * 100 + 1) / 100;

    fixedSIP = Math.round((targetCorpus / (((((roi2 / 12) + 100) / 100) ** (tenure * 12) - 1) / ((roi2 / 12) / 100))) / (((roi2 / 12) + 100) / 100));

    lumpsumInvestment = Math.round((1 / (roi + 1) ** tenure) * targetCorpus);

    let FixedSIPOldArray = [fixedSIP];
    let newFixedSIPArray = [];

    let cumulativeGrowingSIPOldArray = [];
    let cumulativeGrowingSIPNewArray = [];

    for (let i = 0; i < tenure; i++) {
      if (i > 0) {
        newFixedSIPArray.push(FixedSIPOldArray[i] * (1 + SIPgrowthRate));
        cumulativeGrowingSIPNewArray.push(
          cumulativeGrowingSIPOldArray[i - 1] + newFixedSIPArray[i] * 12,
        );
      } else if (i < 1) {
        newFixedSIPArray.push(FixedSIPOldArray[i]);
        cumulativeGrowingSIPNewArray.push(FixedSIPOldArray[0] * 12);
      }
      FixedSIPOldArray.push(newFixedSIPArray[i]);
      cumulativeGrowingSIPOldArray.push(cumulativeGrowingSIPNewArray[i]);

    }

    totalSIP = Math.round(fixedSIP * 12 * tenure);
    totalGrowingSIP = Math.round(cumulativeGrowingSIPNewArray[tenure - 1]);
    let targetCorpusResult = targetCorpus;
    let fixedSIPResult = fixedSIP > 0 ? fixedSIP : 0;

    fixedSIP = Math.round(fixedSIPResult);
    // growingSIP = Math.round(fixedSIPResult);
    totalSIP = totalSIP > 0 ? totalSIP : 0;
    totalGrowingSIP = totalGrowingSIP > 0 ? totalGrowingSIP : 0;


    setTenure(tenure);
    setTargetCorpus(targetCorpus);
    setRateOfInterest(rateOfInterest)
    setFixedSIP(fixedSIPResult);
    setGrowingSIP(fixedSIPResult);
    setLumpsumInvestment(lumpsumInvestment);

    setTotalSIP(totalSIP > 0 ? totalSIP : 0);
    setTotalGrowingSIP(totalGrowingSIP > 0 ? totalGrowingSIP : 0);
    setTargetCorpusResult(targetCorpusResult)
    setTargetCorpusResult2(targetCorpusResult)



    FixedSIPOldArray = [fixedSIP];
    newFixedSIPArray = [];
    cumulativeGrowingSIPOldArray = [];
    cumulativeGrowingSIPNewArray = [];

    let corpus1 = Math.round(targetCorpus);
    let init_pmt = -10000;
    let rate_of_return = roi; // in percentage
    let total_period = tenure; //years
    let growth_rate = 10; // in percentage
    let goal = corpus1;

    const fnParams = [init_pmt, rate_of_return, total_period * 12, growth_rate / 100];

    try {
      const result = goalSeek({
        fn,
        fnParams,
        percentTolerance: 0.1,
        maxIterations: 100,
        maxStep: 0.1,
        goal: goal,
        independentVariableIdx: 0
      });

      let result1 = Math.ceil(Math.abs(result));
      console.log(result1)

      growingSIP = Math.round(result1)
      setGrowingSIP(growingSIP)


      let totalCorpus = totalSIPCalculate(result1, total_period, growth_rate);
      totalGrowingSIP = totalCorpus > 0 ? totalCorpus : 0;
      setTotalGrowingSIP(totalGrowingSIP);


    } catch (e) {
      // console.log('error', e);
    }
  };

  const goalSeek = ({ fn, fnParams, percentTolerance, customToleranceFn, maxIterations, maxStep, goal, independentVariableIdx, }) => {
    if (typeof customToleranceFn !== 'function') {
      if (!percentTolerance) {
        throw Error('invalid inputs');
      }
    }
    let g;
    let y;
    let y1;
    let oldGuess;
    let newGuess;
    let res;
    const absoluteTolerance = ((percentTolerance || 0) / 100) * goal;
    // iterate through the guesses
    for (let i = 0; i < maxIterations; i++) {
      // define the root of the function as the error
      res = fn.apply(null, fnParams);
      res = parseFloat(res);

      y = res - goal;
      // console.log(`result: ${res}; y: ${y}`);
      if (isNaN(y))
        throw TypeError('resulted in NaN 1');

      // was our initial guess a good one?
      if (typeof customToleranceFn !== 'function') {
        if (Math.abs(y) <= Math.abs(absoluteTolerance))
          return fnParams[independentVariableIdx];
      }
      else {
        if (customToleranceFn(res))
          return fnParams[independentVariableIdx];
      }
      // set the new guess, correcting for maxStep
      oldGuess = fnParams[independentVariableIdx];
      newGuess = oldGuess + y;

      fnParams[independentVariableIdx] = newGuess;
      // re-run the fn with the new guess
      y1 = parseInt(fn.apply(null, fnParams)) - goal;



      if (isNaN(y1))
        throw TypeError('resulted in NaN 2');
      // calculate the error
      g = (y1 - y) / y;
      if (g === 0)
        g = 0.0001;
      // set the new guess based on the error, correcting for maxStep
      newGuess = oldGuess - y / g;

      fnParams[independentVariableIdx] = newGuess;
    }
    // done with iterations, and we failed to converge
    throw Error('failed to converge');
  };

  const fn = function calculate(pmt, rate, tper, growth) {
    let years;
    let cum_returns = 0;
    let growth_calc;
    let i;
    let this_pmt;
    let this_nper;
    let fv;

    // console.log(`try with pmt: ${pmt}`);
    growth_calc = 1 + growth;
    years = tper / 12;
    for (i = 1; i <= years; i++) {
      if (i == 1) {
        this_pmt = pmt;
      } else {
        this_pmt = pmt * ((growth_calc ** (i - 1)) - (growth_calc ** (i - 2)));
      }
      this_nper = tper - ((i - 1) * 12);
      fv = parseFloat(FV(rate, this_nper, this_pmt, 0, 1));

      cum_returns = cum_returns + fv;
    }
    return cum_returns
  }

  function FV(rate, nper, pmt, pv, type) {
    let pow = Math.pow(1 + (rate / 12), nper),
      fv;
    if (rate) {
      fv = (pmt * (1 + (rate / 12) * type) * (1 - pow) / (rate / 12)) - pv * pow;
    } else {
      fv = -1 * (pv + pmt * nper);
    }
    return fv.toFixed(2);
  }

  function totalSIPCalculate(firstValue, tenure, rate) {
    let totalValue = firstValue * 12;
    for (let i = 0; i < tenure; i++) {
      let temp = ((1 + (rate / 100)) * firstValue).toFixed(2);
      totalValue += temp * 12;
      firstValue = temp;
    }
    return Math.round(totalValue);
  }

  let downloadScreenshotFunc = null;

  const setDownloadScreenshotFunc = (func) => {
    downloadScreenshotFunc = func;
  };

  const barChart = useMemo(() => {
    return (
      <BarChart
        info={{
          labels: ["Fixed SIP", ["Step up", "SIP assumed", "at 10%"]],
          data: [fixedSIP, growingSIP],
        }}
      />
    );
  }, [fixedSIP, growingSIP]);
  const barChart2 = useMemo(() => {
    return (
      <BarChart
        info={{
          labels: ["Fixed SIP", ["Step up", "SIP assumed", "at 10%"], "Lumpsum"],
          data: [totalSIP, totalGrowingSIP, lumpsumInvestment],
        }}
      />
    );
  }, [totalSIP, totalGrowingSIP, lumpsumInvestment]);
  return (
    <div>
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART START ---------------------------------------------- */}
      <div className="calculator-result-main-wrapper">
        <div className="calculator-component-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Calculator
          </h4>


          <RangeFields
            label="Target Corpus"
            name={"targetCorpus"}
            value={targetCorpus}
            minVal={100000}
            maxVal={1000000000}
            step={100000}
            getValue={debounce(handleChange)}
            showComma />
          <RangeFields
            label="Expected Rate of Return"
            name={'rateOfInterest'}
            value={rateOfInterest}
            minVal={4}
            maxVal={50}
            step={1}
            unit="%"
            getValue={debounce(handleChange)}
          />
          <RangeFields
            label="Investment Tenure"
            name={"tenure"}
            value={tenure}
            minVal={1}
            maxVal={50}
            unit="Year"
            step={1}
            getValue={debounce(handleChange)}
          />

        </div>
        <div className="result-graph-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Results{" "}

            {<ShowShare calculator={calculator} isDisable={isDisable} setIsDisable={setIsDisable} clickHandler={() => { CheckToolsSubscription(textLogo, downloadScreenshotFunc, calculator?.uuid, imageData, setIsDisable, setAlertData, setIsAlert, setModalData) }} />}

          </h4>
          <div className="result-graph-wrapper">
            <div className={`graph-1-frame flex-column ${false ? "w-auto" : "w-100"}`}>
              <p className='lato-semibold  f-s-12 lh-14 primary text-center pt-2'>{`Monthly SIP Amount to create a corpus of ${formatCurrency(targetCorpusResult)}`} </p>
              {barChart}
            </div>

            <div className={`graph-1-frame flex-column ${false ? "w-auto" : "w-100"}`}>
              <p className='lato-semibold  f-s-12 lh-14 primary text-center pt-2'>{`Total Investment to create a corpus of ${formatCurrency(targetCorpusResult2)}`} </p>
              {barChart2}
            </div>

          </div>
          {targetAmountText(targetCorpus, rateOfInterest, tenure, fixedSIP, growingSIP, totalSIP, totalGrowingSIP, lumpsumInvestment)}

        </div>
      </div>
      <div className="make-none">
        <CalculatorOutputScreenShot textLogo={textLogo} fixedSIP={fixedSIP} growingSIP={growingSIP} targetCorpus={targetCorpus} totalSIP={totalSIP} totalGrowingSIP={totalGrowingSIP} lumpsumInvestment={lumpsumInvestment} onDownloadScreenshot={setDownloadScreenshotFunc} setImageData={setImageData} />
      </div>
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART END ---------------------------------------------- */}
    </div>
  )
}

function CalculatorOutputScreenShot({ textLogo, fixedSIP, growingSIP, targetCorpus, totalSIP, totalGrowingSIP, lumpsumInvestment, onDownloadScreenshot, setImageData, rateOfInterest, tenure }) {
  const { cobrandingLabelling, investor } = textLogo;
  const [signup, setSignup] = useState(false);

  const screenshotRef = useRef(null);

  useEffect(() => {
    if (onDownloadScreenshot) {

      captureImageHandler(onDownloadScreenshot, screenshotRef, setSignup, setImageData);
    }
  }, [textLogo, screenshotRef, targetCorpus, rateOfInterest, tenure, fixedSIP, growingSIP, totalSIP, totalGrowingSIP, lumpsumInvestment]);
  return (<>
    {/* {signup && <SignUp show={signup} handleClose={() => setSignup(!signup)} />} */}

    <div ref={screenshotRef} id='content-to-print' className="tools-calulator-output-screenshot-frame ">
      {ToolsScreenshotHeader(cobrandingLabelling)}

      <div className="result-graph-wrapper">
        <div className={`graph-1-frame flex-column ${false ? "w-auto" : "w-100"}`}>
          <p className='lato-semibold  f-s-12 lh-14 primary text-center pt-2'>{`Monthly SIP Amount to create a corpus of ${formatCurrency(targetCorpus)}`} </p>
          <BarChart
            info={{
              labels: ["Fixed SIP", ["Step up", "SIP assumed", "at 10%"]],
              data: [fixedSIP, growingSIP],
            }}
          />
        </div>
        <div className={`graph-1-frame flex-column ${false ? "w-auto" : "w-100"}`}>
          <p className='lato-semibold  f-s-12 lh-14 primary text-center pt-2'>{`Total Investment to create a corpus of ${formatCurrency(targetCorpus)}`} </p>
          <BarChart
            info={{
              labels: ["Fixed SIP", ["Step up", "SIP assumed", "at 10%"], "Lumpsum"],
              data: [totalSIP, totalGrowingSIP, lumpsumInvestment],
            }}
          />
        </div>
      </div>
      {toolsInvestorDetails(investor)}
      {targetAmountText(targetCorpus, rateOfInterest, tenure, fixedSIP, growingSIP, totalSIP, totalGrowingSIP, lumpsumInvestment)}

      <br /><br />

      <div className="bg-primary lh-16 mt-5">&nbspl;</div>

    </div>

  </>
  )
};

function targetAmountText(targetCorpus, rateOfInterest, tenure, fixedSIP, growingSIP, totalSIP, totalGrowingSIP, lumpsumInvestment) {
  return (
    <div className='d-flex justify-content-around p-2'>

      <p className=" f-s-16 lh-24 black-242 op-7 pt-2 text-center">
        If the target amount that you require is <span className=" lato-semibold primary"> ₹{formatCurrency(targetCorpus)}</span>, to accumulate this corpus in <span className=" lato-semibold primary">{tenure} </span>years, you can either start SIP of <span className=" lato-semibold primary">₹{formatCurrency(fixedSIP)}</span>, Step up SIP of  <span className=" lato-semibold primary">₹{formatCurrency(growingSIP)} </span>, or invest a lumpsum amount of <span className=" lato-semibold primary"> ₹{formatCurrency(lumpsumInvestment)}</span>. Considering your investment grows at  <span className=" lato-semibold primary"> {rateOfInterest}% </span> and step up rate at  <span className=" lato-semibold primary"> {10}% </span> annually. At the end of investment tenure, your total investment will accumulate <span className=" lato-semibold primary"> ₹{formatCurrency(totalSIP)} </span>  in case of SIP, <span className=" lato-semibold primary"> ₹{formatCurrency(totalGrowingSIP)}</span> in case of step up SIP and  <span className=" lato-semibold primary"> ₹{formatCurrency(lumpsumInvestment)}</span> in case of lumpsum investment.

      </p>
    </div>

  )
}