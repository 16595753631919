
import { toast } from "react-toastify";
import { postLoginInstance, preLoginInstance } from "../../utils";
import { getToken } from "../../utils/ManageToken";
import { getUserDetailsApi } from "../onBoarding/Api";
import { useHistory } from "react-router-dom";

//? ======================= COMMON GET DATA ===============================
export const getDataApi = async (url) => {
  try {

    const response = await postLoginInstance.get(url);
    const data = response.data;

    return data;
  } catch (error) {
    toast.error(error.response.data.message || "Something went wrong")
    return [];
  }
};
export const getDetailsApi = async (url) => {
  try {

    const response = await postLoginInstance.get(url);
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong")
    return error;
  }
};
export const commonAuthApis = async (url, data = "", method = "post") => {
  try {

    const response = await postLoginInstance[method](url, data);

    return response;
  } catch (error) {
    toast.error(error.response?.data?.message || "Something went wrong")
    return false;
  }
};
export const checkSubscriptionStatus = async (url, data = "", method = "post") => {
  try {

    const response = await postLoginInstance[method](url, data);

    return response;
  } catch (error) {

    return false;
  }
};
export const checkToolsSubscriptionStatus = async (url, data = "", method = "post") => {
  try {
    const response = await postLoginInstance[method](url, data);
    return response;
  } catch (error) {
    toast.error(error.response?.data?.message || "Something went wrong")
    return false;
  }
};
export const commonPostApi = async (url, data = "", method = "post") => {
  try {

    if (method.toLowerCase() === "delete") {
      const response = await postLoginInstance.delete(url);
      toast.success(response.data.data.message);
      return response.data;
    } else {
      const response = await postLoginInstance[method](url, data);
      return response.data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong")
    return false;
  }
};

export const verifyMobileApi = (ctx, data) => {
  return postLoginInstance.post('user/sentMobileNumberOtp', data)  // Return the promise
    .then(res => {
      ctx.setState({
        changeMobile: !ctx.state.changeMobile,
        otpSent: true,
        showResend: true,
        showTimer: true,
        otpMobile: ""
      });
      ctx.startCountdown();
      toast.success(res?.data?.data.message || "Success");
      getUserDetailsApi(ctx);
      return res;
    })
    .catch(error => {
      toast.error(error.response.data.message || "Something went wrong");
      throw error;
    });
};

export const verifyOtpApi = (ctx, data) => {
  postLoginInstance.post('user/verifyMobileNumberOtp', data)
    .then(res => {
      ctx.setState({
        showResend: false,
        changeMobile: false,
        otpSent: false,
        otpMobile: ""
      })
      toast.success(res?.data?.data.message || "Verified Successfully")
      getUserDetailsApi(ctx);
    })
    .catch(error => {
      toast.error(error.response.data.message || "Something went wrong ")
    });
}


export const sendEmailVerificationApi = (data) => {
  postLoginInstance.post('user/sentEmailAddressVerificationUrl', data)
    .then(res => {
      toast.success(res?.data?.data.message || "A verification link has been sent to your email account")
      getUserDetailsApi();
    })
    .catch(error => {
      toast.error(error.response.data.message || "Something went wrong ")
    });
}

// CAPTCHA
export const verifyCaptchaApi = (data, ctx) => {
  postLoginInstance.post('user/verifyCaptcha', data)
    .then(res => {

      if (res?.data?.data) {
        ctx.setState({
          showOtpBtn: true
        })
      }
    })
    .catch(error => {
      toast.error(error.response.data.message || "Something went wrong")
    });
}


export const getPlansApi = async (url) => {

  try {
    const response = await (getToken() ? postLoginInstance : preLoginInstance).get(url);
    return response;
  } catch (error) {
    toast.error(error.response.data.message || "Something went wrong")
    return error;
  }
};

export const purchaseAmoutBreakup = (data, history, setIsAlert, setAlertData, setResData, breakupAmount, setBreakupAmount) => {
  postLoginInstance.post('user/billing-plan/purchase-amount-breakup', data)
    .then(res => {
      if (res.data.data.alerts.length > 0) {
        setAlertData(res.data.data)
        setIsAlert(true)
        setResData(data)
        setBreakupAmount(res?.data?.data?.breakup?.previousSubcriptionBreakup)

      } else {
        history.push({
          pathname: "/checkout",
          state: {
            data: res?.data?.data,
            requestObj: data,
            isNewPurchase: true,
            breakupAmount: res?.data?.data?.breakup?.previousSubcriptionBreakup
          }
        })
      }


    })
    .catch(error => {
      toast.error(error.response.data.message || "Something went wrong")
    });
}

export const upgradeAmoutBreakup = (data, history) => {
  postLoginInstance.post('user/billing-plan/upgrade-amount-breakup', data)
    .then(res => {

      history.push({
        pathname: "/checkout",
        state: {
          data: res?.data?.data,
          requestObj: data,
          isNewPurchase: false,
        }
      })
    })
    .catch(error => {
      toast.error(error.response.data.message || "Something went wrong")
    });
}

export const purchasePlanApi = (data) => {

  postLoginInstance.post('user/billing-plan', data)
    .then(res => {
      toast.success("Success")

      processUserBillingApi(res?.data?.data.uuid)
    })
    .catch(error => {
      toast.error(error.response.data.message || "Something went wrong")
    });
}


export const upgradePlanApi = (data, userBillingUuid) => {

  postLoginInstance.put(`user/billing-plan/${userBillingUuid}/upgrade`, data)
    .then(res => {
      toast.success("Success")

      processUserBillingApi(res?.data?.data.newBillingDetails.uuid)
    })
    .catch(error => {
      toast.error(error.response.data.message || "Something went wrong")
    });
}
export const processUserBillingApi = (uuid) => {
  postLoginInstance.post(`user/billing-plan/${uuid}/process`)
    .then(res => {
      toast.success("Success")

      const history = useHistory();

      history.push("/mymembership")
    })
    .catch(error => {

      toast.error(error.response.data.message || "Something went wrong")
    });
}
