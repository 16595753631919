import React from 'react'
import { Image } from 'react-bootstrap';
import Video from "../../../assets/images/Videos.png";

import { MAX_LIMIT, START_PAGE } from '../../../utils/Constant'
import { navigateSetUrl } from '../../../utils/ReusableFunctions';
import { useHistory } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useState } from 'react';
import { useEffect } from 'react';
import { getContentTypeApi } from '../Api';
/* eslint-disable eqeqeq */


export default function ContentTypeSection() {
  const history = useHistory();
  const [contentTypes, setContentTypes] = useState([]);

  useEffect(() => {
    const contentType = JSON.parse(localStorage.getItem("contenType"));
    if (!contentType) {
      getContentTypeApi(setContentTypes);
    } else {
      setContentTypes(contentType);
    }
  }, []);

  const navigateData = (type) => {
    let pathname = `/content-type/${type?.replace('/', '_')}`;
    let search = `?pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}`;
    navigateSetUrl(history, pathname, search);
  }
  const settings = {
    dots: true,
    infinite: true,
    slidesToScroll: 4,
    slidesToShow: 4,
    variableWidth: true,
    autoplay: false,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          centerMode: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          variableWidth: true,
          // centerPadding: "60px",
        }
      },
      {
        breakpoint: 340,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        }
      },
    ]
  };
  // const settings = {
  //   dots: true,
  //   // infinite: true,
  //   speed: 500,
  //   slidesToScroll: 1,
  //   variableWidth: true,
  // };
  return (
    <div className="feature-section">
      <div className="container-mb">
        <div style={{ marginBottom: 0, paddingBottom: 0 }} className="slider-container">
          <Slider style={{ height: "150px" }} className="home-social-media home-content-type-slider" {...settings} >
            {contentTypes?.map((ele, i) => {
              if (ele?.contentTypeName == 'All') {
                return ''
              }
              return (
                <FeatureBox key={i} onClick={() => navigateData(ele?.contentTypeName)} img={ele?.thumbnailPath || Video} name={ele?.contentTypeName} />
              )
            })}
          </Slider>

        </div>
      </div>
    </div>

  )
}

function FeatureBox({ onClick, img, name }) {
  return (
    <div className='feature-box ' onClick={onClick}>
      <Image src={img} alt='img' className='feature-image' />
      <div className="black-wrapper">
        <h4 className='lato-regular fs-18-16 white'>{name}</h4>
      </div>

    </div>
  )
}
