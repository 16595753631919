import { Button, Dropdown, Image, Modal } from "react-bootstrap";
import ImageChecked from '../../assets/images/icon/tick-icon.svg';
import moment from 'moment';
import CrossIcon from "../../assets/images/icon/cross-modal-icon.svg";
import { useEffect, useState } from "react";
import { commonAuthPostApi, getAuthDataApi } from "../home/Api";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import UploadPlus from "../../assets/images/icon/upload-plus.svg"
import DeleteIcon from "../../assets/images/icon/upload-delete.svg"
import { formatDateTimeToISOString } from "./_utilis/platformsTime";




export default function UploadMediaModal({ upload, setUpload, maxEndDate, setShowDropdown }) {

    const [caption, setCaption] = useState('');
    const [dateTimeArr, setDateTimeArr] = useState([]);
    const [newDate, setNewDate] = useState(new Date());
    const [isComplaince, setIsComplaince] = useState(false);
    const [images, setImages] = useState([]);
    const [selectedFileType, setSelectedFileType] = useState('image');
    const [file, setFile] = useState(null);
    const [imagesData, setImagesData] = useState([])





    const handleDateChange = (date) => {
        setNewDate(date)
    }


    async function handleGetPlatforms() {
        try {
            const res = await getAuthDataApi(`/website/userPlatform/user`);
            if (res) {
                // setPlatformData(res);
                const date = moment(newDate || new Date());

                const transformedData = res.map(item => ({
                    uuid: item.platform?.uuid || "",

                    date: date.format('YYYY-MM-DD'),
                    timeMm: date.format('mm'),
                    timeHh: date.format('hh'),
                    period: date.format('A'),
                    checked: null,
                    platformName: item.platform?.platformName || "",
                    iconImagePath: item.platform?.iconImagePath || ""
                }));
                setDateTimeArr(transformedData);

            } else {
                toast.error("Failed to get Platform Data");
            }
        } catch (error) {
            console.error("Error fetching platform data:", error);
        } finally {
            // setIsLoading(false);
        }
    };
    const handleTerms = (e) => {
        e.preventDefault()
        window.location.href = '/terms-conditions';
    }
    useEffect(() => {

        handleGetPlatforms();

    }, [newDate]);

    const handleCaptionChange = (event) => {
        setCaption(event.target.value);
    };
    const handleSaveChanges = async () => {

        const platforms = dateTimeArr
            .filter(platform => platform.checked)
            .map(platform => ({
                uuid: platform.uuid,
                date: formatDateTimeToISOString(platform.date, platform.timeHh, platform.timeMm, platform.period)
            }))
        const payload = {

            "data": {
                "date": newDate,

                "caption": caption
            }

        };
        if (platforms?.length > 0) {
            payload.data.platforms = platforms;
        };
        const formData = new FormData();
        if (file) {
            formData.append("file", file)
        } else {
            formData.append("file", imagesData[0])

        }
        formData.append("key", JSON.stringify(payload));
        const res = await commonAuthPostApi("website/scheduledPostPlanner/custom-upload", formData)
        if (res) {
            toast.success("Uploaded Successfully")
            handleClose();
        }

    };
    const handleCheckboxChange = (e, uuid) => {
        setDateTimeArr(prevDateTimeArr =>
            prevDateTimeArr.map(item =>
                item.uuid === uuid ? { ...item, checked: e.target.checked } : item
            )
        );
    };
    const handleClose = () => {
        setUpload(false)
        setShowDropdown(false)
        setImages([]);
        setFile(null)
        setNewDate("");
        setCaption("");
        setDateTimeArr([]);
        setIsComplaince(false);



    };

    const handleCheckbox = (e) => {
        e.preventDefault();
        setIsComplaince(!isComplaince);
    };



    const handleTimeChange = (e, uuid, field) => {
        let value = Number(e.target.value);
        const item = dateTimeArr.find(item => item.uuid === uuid);

        if (field === 'timeHh') {
            // If AM is selected and the value is 12, set it to 00
            if (item.period === "AM" && value === 12) {
                value = 0;
            }

            // If PM is selected and the value is 00, set it to 12
            if (item.period === "PM" && value === 0) {
                value = 12;
            }

            setDateTimeArr(prevDateTimeArr =>
                prevDateTimeArr.map(item =>
                    item.uuid === uuid ? { ...item, [field]: value } : item
                )
            );
        } else if (field === 'timeMm') {
            const validatedValue = Math.max(0, Math.min(59, value));
            setDateTimeArr(prevDateTimeArr =>
                prevDateTimeArr.map(item =>
                    item.uuid === uuid ? { ...item, [field]: validatedValue } : item
                )
            );
        }
    };



    const togglePeriod = (uuid) => {
        setDateTimeArr(prevDateTimeArr =>
            prevDateTimeArr.map(item => {
                if (item.uuid === uuid) {
                    let updatedItem = { ...item, period: item.period === "AM" ? "PM" : "AM" };

                    // If switching to AM and the hour is 12, set it to 00
                    if (updatedItem.period === "AM" && updatedItem.timeHh === 12) {
                        updatedItem.timeHh = 0;
                    }

                    // If switching to PM and the hour is 00, set it to 12
                    if (updatedItem.period === "PM" && updatedItem.timeHh === 0) {
                        updatedItem.timeHh = 12;
                    }

                    return updatedItem;
                }
                return item;
            })
        );
    };

    const handlePlatformClick = (uuid) => {

        setDateTimeArr(prevDateTimeArr =>
            prevDateTimeArr.map(item =>
                item.uuid === uuid ? { ...item, editing: !item.editing } : item
            )
        );
    };

    const handleImageChange = (event) => {
        const files = event.target.files;
        const fileType = files[0]?.type;


        if (fileType.includes('image')) {
            setSelectedFileType('image');
            // Handle image upload (multiple allowed)
            setImages([...images, ...Array.from(files).map(file => URL.createObjectURL(file))]);
            setImagesData([...imagesData, ...Array.from(files)])
        } else if (fileType.includes('video')) {
            setSelectedFileType('video');
            // Handle single video/PDF upload
            setFile(files[0]);

        }
    };
    const handleDeleteImage = (indexToDelete) => {
        const filteredImages = images.filter((_, index) => index !== indexToDelete);
        setImages(filteredImages);
    };

    return (
        <Modal className='upload-post-modal' centered show={upload} onHide={handleClose}>
            <Modal.Body>
                <div className="upload-modal-heading d-flex flex-row justify-content-between align-items-baseline">
                    <div className="d-flex flex-row align-items-center date-wrapper">
                        {/* <Image src={CalenderIcon} /> */}
                        <p className="lato-regular black-242 fs-24-18">Upload Media</p>
                    </div>
                    <Image width={20} height={20} src={CrossIcon} onClick={handleClose} style={{ cursor: "pointer" }} />
                </div>
                <div className=' '>
                    <DatePicker
                        selected={newDate || new Date()}
                        onChange={(date) => { handleDateChange(date) }}

                        maxDate={new Date(maxEndDate)}
                        minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                        // dateFormat="MMMM d, yyyy"
                        placeholderText="Select Date"
                        className="form-control-date"
                        showYearDropdown
                        yearDropdownItemNumber={50}
                        scrollableYearDropdown
                        dateFormat={" d MMMM , yyyy"}
                        onFocus={(e) => {
                            e.target.blur()
                            e.target.readOnly = true
                        }}
                    />
                </div>


                <div className='upload-modal-main row '>
                    <div className="image-uploader d-flex flex-column">
                        <div className="image-main d-flex flex-column">
                            {images.length > 0 ? (
                                <div className="uploaded-image">
                                    <img src={(images[images.length - 1])} alt="Uploaded" className="big-image" />
                                </div>
                            ) : file ? (
                                <div className="uploaded-file" >
                                    <video
                                        className="uploaded-video"
                                        width="320"
                                        height="240"
                                        controls
                                        src={URL.createObjectURL(file)}
                                    >
                                        Your browser does not support the video tag.
                                    </video>
                                    {/* <p className="file-name lato-medium fs-14-12 black-242 op-5">
                                        {file.name}
                                    </p> */}
                                    <div className=" delete-icon pointer" onClick={() => setFile(null)}>
                                        <Image src={DeleteIcon} alt="Delete" />
                                    </div>
                                </div>

                            ) : (
                                <>
                                    <div className="plus-icon pointer" onClick={() => document.getElementById('file-input').click()}>
                                        <Image src={UploadPlus} alt="icon" />
                                    </div>
                                    <p className="plus-text lato-medium fs-14-12 black-242 op-5">
                                        Upload the images, videos, or PDFs you'd like to include in your post here.
                                    </p>
                                </>
                            )}

                            <input
                                id="file-input"
                                type="file"
                                accept="image/jpeg,image/png,video/mp4,video/mkv,application/pdf"
                                style={{ display: "none" }}
                                onChange={handleImageChange}
                                multiple={selectedFileType === 'image'}
                            />
                        </div>

                        <div className="note-text">
                            <p className="lato-medium fs-11-10 black-242 op-5">
                                Note: jpg, png, mp4, mkv, pdf file formats allowed only. Max upload size: 10mb.
                            </p>
                            <p className="lato-medium fs-11-10 black-242 op-5">
                                PDF file uploads are only compatible with LinkedIn and Whatsapp.
                            </p>
                        </div>

                        <div className="small-images d-flex flex-wrap">
                            {images.length > 0 && (
                                <div className="plus-small-icon pointer" onClick={() => document.getElementById('file-input').click()}>
                                    <Image width={18} height={18} src={UploadPlus} alt="Add icon" />
                                </div>
                            )}
                            {images.map((image, index) => (
                                <div key={index} className="small-image-wrapper">
                                    <div className="small-image">
                                        <img src={image} alt={`Uploaded ${index}`} />
                                        <div className="delete-icon" onClick={() => handleDeleteImage(index)}>
                                            <Image src={DeleteIcon} alt="Delete" />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>


                    <div className="d-flex flex-column  col-md-6 ">
                        <div className='social-media-edit  col-md-6 d-flex flex-column flex-md-row mb-md-5'>
                            {dateTimeArr?.map((dateTimeObj, index) => {
                                return (
                                    <div style={{ height: "40px", display: "flex", justifyContent: "center", alignItems: "center" }} key={index} className='d-flex flex-md-column flex-row justify-content-between justify-content-md-start align-items-center'>
                                        <div className='social-icons-details d-flex flex-md-column flex-row text-center justify-content-center align-items-center'>
                                            <input
                                                type="checkbox"
                                                checked={dateTimeObj.checked || false}
                                                onChange={(e) => handleCheckboxChange(e, dateTimeObj.uuid)}
                                                id={`full-check-box-${index}`}
                                                className='d-none'
                                            />
                                            <label htmlFor={`full-check-box-${index}`} className='cobrand-custom-checkbox mb-0 mt-1 d-md-none mr-3 ' onClick={() => handlePlatformClick(dateTimeObj.uuid)}>
                                                {dateTimeObj.checked && <Image src={ImageChecked} alt="" className='d-block' />}
                                            </label>
                                            <div className="social-icons-wrapper mr-3 mr-md-0">
                                                <Image width={18} height={18} src={dateTimeObj?.iconImagePath} alt="image" />
                                            </div>
                                            <p className='lato-bold fs-14-12 black-242 '>{dateTimeObj?.platformName}</p>
                                        </div>
                                        <div className='timing-wrapper d-flex flex-md-column flex-row text-center justify-content-center align-items-center'>
                                            <input
                                                type="checkbox"
                                                checked={dateTimeObj.checked || false}
                                                onChange={(e) => handleCheckboxChange(e, dateTimeObj.uuid)}
                                                id={`full-check-box-${index}`}
                                                className='d-none'
                                            />
                                            <label htmlFor={`full-check-box-${index}`} className='cobrand-custom-checkbox d-none d-md-block mb-0 mt-1' onClick={() => handlePlatformClick(dateTimeObj.uuid)}>
                                                {dateTimeObj.checked && <Image src={ImageChecked} alt="" className='d-block' />}
                                            </label>
                                            {dateTimeObj.checked ? (
                                                <div className='d-flex flex-row align-items-center justify-content-center'>
                                                    {/* Dropdown for Hours */}
                                                    <Dropdown className="single-select-api-time">
                                                        <Dropdown.Toggle className="dropdown-btn" id="dropdown-basic">
                                                            <h6 className="lato-semibold fx-14 black-242">{dateTimeObj.timeHh.toString().padStart(2, '0') || '00'}</h6>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {Array.from({ length: 12 }, (_, i) => i).map((hour) => {
                                                                const displayHour = hour === 0 ? "00" : hour.toString().padStart(2, '0');
                                                                return (
                                                                    <Dropdown.Item
                                                                        key={hour}
                                                                        className="lato-regular fx-14 black-242"
                                                                        onClick={() => handleTimeChange({ target: { value: hour } }, dateTimeObj.uuid, 'timeHh')}
                                                                    >
                                                                        {displayHour}
                                                                    </Dropdown.Item>
                                                                );
                                                            })}
                                                            <Dropdown.Item
                                                                key={12}
                                                                className="lato-semibold fx-14 black-242"
                                                                onClick={() => handleTimeChange({ target: { value: 12 } }, dateTimeObj.uuid, 'timeHh')}
                                                            >
                                                                12
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <p>:</p>
                                                    {/* Dropdown for Minutes */}
                                                    <Dropdown className="single-select-api-time">
                                                        <Dropdown.Toggle className="dropdown-btn" id="dropdown-basic">
                                                            <h6 className="lato-semibold fx-14 black-242">{dateTimeObj.timeMm.toString().padStart(2, '0') || '00'}</h6>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {Array.from({ length: 60 }, (_, i) => i).map((minute) => (
                                                                <Dropdown.Item
                                                                    key={minute}
                                                                    onClick={() => handleTimeChange({ target: { value: minute } }, dateTimeObj.uuid, 'timeMm')}
                                                                    className="lato-regular fx-14 black-242"
                                                                >
                                                                    {minute.toString().padStart(2, '0')}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            ) : (
                                                <p className='lato-regular fs-14-12 black-242'>
                                                    {`${dateTimeObj?.timeHh || "00"}:${dateTimeObj?.timeMm || "00"}`}
                                                </p>
                                            )}
                                            <div className='timings d-flex'>
                                                <div className='period-container'>
                                                    <span
                                                        className={`lato-regular fs-14-12 period-name b-left`}
                                                        style={{ backgroundColor: dateTimeObj.period === "AM" ? "#A5F5DC" : "#ECECEC", borderRight: "1px solid black" }}
                                                        onClick={() => togglePeriod(dateTimeObj.uuid)}
                                                    >
                                                        AM
                                                    </span>
                                                    <div className='vertical-line'></div>
                                                    <span
                                                        className={`lato-regular fs-14-12 b-right`}
                                                        style={{ backgroundColor: dateTimeObj.period === "PM" ? "#A5F5DC" : "#ECECEC", borderleft: "1px solid black" }}
                                                        onClick={() => togglePeriod(dateTimeObj.uuid)}
                                                    >
                                                        PM
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="text-area-frame d-flex mt-md-5 ">
                            <textarea type="text" className='fx-14 lato-normal black-242 op-8 text-area ' name='caption' value={caption} onChange={handleCaptionChange} placeholder='Enter your caption here' />

                        </div>

                    </div>

                </div>





                <div className=" upload-bottom d-flex flex-md-row flex-column">
                    <div className="d-inline flex-row">
                        <label className=" checkbox-container lato-regular fs-12-11 black-242 d-flex flex-row">
                            <input
                                type="checkbox"
                                // id={`create_card_id_${index}`}
                                checked={isComplaince}
                                onChange={handleCheckbox}
                                // style={{ marginRight: '8px', marginTop: "0" }}
                                className="d-none  "
                            />
                            <label style={{ marginLeft: "0" }} onClick={handleCheckbox} htmlFor={`create_card_id`} className={`cobrand-custom-checkbox bg-transparent mb-0 mt-1 border-black`}>
                                {isComplaince && <Image src={ImageChecked} alt="checked" className='d-block' />}
                            </label>
                            <div className="d-inline flex-row flex-wrap mt-md-1 mt-0 mx-2">
                                <p className="lato-medium  black-242">Compliance Undertaking: I agree that I am fully responsible for any files I upload. I understand that Post Planner serves only as a scheduling tool and is not liable for the content I upload. <span className="lato-bold primary pointer" onClick={(e) => { handleTerms(e) }}>Terms & Conditions.</span>
                                </p>
                            </div>
                        </label>
                    </div>
                    <div className="upload-modal-tab-btn d-flex flex-row justify-content-center ">
                        <Button className="secondary-btn lato-medium fs-14-12 pointer" onClick={handleClose}>Cancel</Button>
                        <Button disabled={!isComplaince} className="primary-btn lato-medium fs-14-12 pointer" onClick={handleSaveChanges}>Schedule</Button>

                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );



}