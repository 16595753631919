import React from 'react'
import { Image } from 'react-bootstrap'
import amcImae from "../../../assets/images/Video-Thumbnail.jpg"
import { useHistory, useParams } from 'react-router-dom';
import { API_LIMIT, CREATOR, START_PAGE } from '../../../utils/Constant';

import { formatNumber } from '../../../utils/ReusableFunctions';

export default function AmcCard({
  classes,
  collectionType,
  data = "",
  classdescription, }) {
  
  const param = useParams();
  const history = useHistory();


  function titleNameHandler() {
    if (collectionType === 'amcContentCreator') {
      if (data?.isAmc) {
        return data[`amcName`]
      } else {
        return data['contentCreatorName']
      }
    } else {
      return data[`${collectionType.toLowerCase()}Name`]
    }
  }
  const checkIsAmcOrCreator = () => {
    if (data.isAmc) {
      // return CREATOR;
      return 'amc';
    } else {
      return CREATOR
    }
  }
  function navigate() {
   if(collectionType === 'amcContentCreator'){

      if(checkIsAmcOrCreator() === "amc"){
        history.push({ pathname: `/content-type/All`, search: `?creator=${encodeURIComponent("Mutual Fund Company")}&amc=${encodeURIComponent(titleNameHandler())}&key=amc&value=${encodeURIComponent(titleNameHandler())}&pageNumber=${START_PAGE}&pageSize=${API_LIMIT}`, state: data })
      } else {
        history.push({ pathname: `/content-type/All`, search: `?creator=${encodeURIComponent(titleNameHandler())}&key=creator&value=${encodeURIComponent(titleNameHandler())}&pageNumber=${START_PAGE}&pageSize=${API_LIMIT}`, state: data })
      }

    } else {
      history.push({ pathname: `/content-type/All`, search: `?key=${collectionType === 'language' ? 'languages' : (collectionType === 'campaign') ? 'campaigns' : collectionType}&value=${encodeURIComponent(titleNameHandler())}&pageNumber=${START_PAGE}&pageSize=${API_LIMIT}`, state: data })//temp pagesze remve ,becuse its not in backend
    }

  }

  return (
    <div className={`amc-card-frame ${classes} ${data.thumbnailPath ? '' : 'amc-rambo-effect'}`}>
      <div onClick={navigate} className="amc-imag-frame w-100 pointer ">
        {data.thumbnailPath ?
          <Image src={data.thumbnailPath || amcImae} alt='amc image' className={`w-100 ${param.type === 'language' ? 'object-fit-none' : ''}`} />
          :
          <p className=' lato-regular fx-14 l-s-129 m-auto  pointer'>{titleNameHandler()}</p>

        }
      </div>
      <div className="amc-description-box">
        <p className={`lato-regular fx-14 l-s-129 black-242 pointer ${param.type === 'language' ? ' text-center' : ''}`} onClick={navigate}>{titleNameHandler()}</p>
       
        <div className={`amc-count-content-type ${(data?.contentTypeStats ?? false) ? Object.keys(data?.contentTypeStats || {})?.length > 4 && "justify-content-start" : ""} `}>
          {
            Object.keys(data?.contentTypeStats || {})?.map((item, i) => {
              if (item === 'totalContentCount') {
                return ""
              }
              return (
                <>
                  <div className='amc-type lato-semibold fx-14 black-242'>
                    {formatNumber(data?.contentTypeStats[item])}
                    <p className='lato-regular f-s-10 lh-14 black-242'>{item}</p>
                  </div>
                  <div className={`vertical-strip ${Object.keys(data?.contentTypeStats || {})?.length === i + 2 ? "d-none" : ""}`}>&nbsp;</div>
                </>
              )
            })
          }
        </div>
       
      </div>
    </div>
  )
}
