import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { getNotificationData } from '../../home/Api';
import { isResOk } from '../../../utils/ReusableFunctions';
import { SETTING_NOT, TARGETED_CONTENTS_NOTIFICATION } from '../../../utils/Constant';
import NotificationSelectDropDown from '../components/NotificationSelectDropDown';

const ContentNotification = ({ sendDataToParent, updatePlatformOptions }) => {
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [isLoading, setILoading] = useState(true);;



  const [isMutual, setIsMutual] = useState(false);

  const getContentNotification = async () => {
    setILoading(true)
    const res = await getNotificationData('/inAppNotification/settings/Content');

    if (isResOk(res?.status)) {

      setNotificationTypes(res?.data?.data?.settings);
      updatePlatformOptions(res?.data?.data?.platformOptions);
      const findFirstObj = res?.data?.data?.settings?.find((ele) => ele?.code === SETTING_NOT['CONTENT_CREATORS']);
      const option = findFirstObj?.list?.find((ele) => ele?.isMutualFuncCompany);
      setIsMutual(option.isSelected)
      setILoading(false)
    } else {
      setILoading(false)
    }
  };

  useEffect(() => {
    getContentNotification();
  }, []);
  useEffect(() => {
    sendDataToParent(notificationTypes);
  }, [notificationTypes]);

  const togglePill = (codeName, listId) => {
    setNotificationTypes(prevNotificationTypes => {
      const newNotificationTypes = [...prevNotificationTypes];
      const findFirstObj = newNotificationTypes?.find((ele) => ele?.code === codeName);
      const option = findFirstObj?.list?.find((id) => id.uuid === listId);
      option.isSelected = !option.isSelected;
      if ((option?.isMutualFuncCompany ?? false)) {
        // console.log("Test mutual",(option?.isMutualFuncCompany??false),option.isSelected);
        setIsMutual(option.isSelected)
        if (!option.isSelected) {
          const findFirstObj = newNotificationTypes?.find((ele) => ele?.code === SETTING_NOT['CONTENT_AMCS']);
          const listArr = findFirstObj?.list?.map((obj) => ({ ...obj, isSelected: false }));
          findFirstObj.list = listArr;
        }
      }
      return newNotificationTypes;
    });

  };

  const _sendDataToParent = () => {
    sendDataToParent(notificationTypes)
  }


  return (
    <div className='content-notification-main'>
      {/* <NotificationSelection/> */}
      <div className='notification-dropdown'>
        <h3 className='lato-regular fs-20-400 black-242 mb-16'>What notifications you wish to receive</h3>
        <section>
          <Row>

            {isLoading ?
              [1, 2, 3, 4, 5, 6].map((ele, i) => {
                return (
                  <Col xs={12} sm={6} key={i} className='d-flex'>
                    <div className='notification-main notification-type bg-white border-ECE flex-fill'>
                      <h4 className="lato-regular shimmer-container w-25 fs-14-400">
                        &nbsp;
                      </h4>
                      <hr />
                      <div className='pills-wrapper'>
                        {[...Array(Math.floor(Math.random() * 6) + 1)].map((item, optionIndex) => (
                          <span
                            key={optionIndex}
                            className={`lato-regular shimmer-container mw-151 fx-12 border-0 notification-pills ${false ? 'active' : ""}`}
                          >
                            {" "}

                          </span>
                        ))}
                      </div>
                    </div>
                  </Col>
                )
              })

              :
              notificationTypes?.map((ele, sectionIndex) => {

                const isShowAmc = isMutual ? [TARGETED_CONTENTS_NOTIFICATION, SETTING_NOT['CONTENT_AMCS']] : [TARGETED_CONTENTS_NOTIFICATION];

                if ((isShowAmc)?.includes(ele.code)) {
                  return (
                    <Col key={sectionIndex} xs={12} sm={6}>
                      <NotificationSelectDropDown ele={ele} togglePill={togglePill} />
                    </Col>
                  )

                }
                else if (SETTING_NOT['CONTENT_AMCS'] === ele?.code) {
                  return ''
                }
                return (
                  <Col xs={12} sm={6} key={sectionIndex} className='d-flex'>
                    <div className='notification-main notification-type flex-fill'>
                      <h4 className="lato-regular fs-14-400">
                        {ele.title}
                      </h4>
                      <hr />
                      <div className='pills-wrapper'>
                        {ele.list.map((item, optionIndex) => (
                          <span
                            key={item.uuid}
                            className={`lato-regular fx-12 notification-pills ${item.isSelected ? 'active' : ""}`}
                            onClick={() => togglePill(ele?.code, item?.uuid)}
                          >
                            {item.name}

                          </span>
                        ))}
                      </div>
                    </div>
                  </Col>
                )

              })}
          </Row>
        </section>
        <hr />
      </div>

    </div>
  );
};

export default ContentNotification;



