import React from "react";
import { Dropdown, Image } from "react-bootstrap";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import defaultVideoThmbnail from "../../../assets/images/video-img.png";
import defaultIcon from "../../../assets/images/default-grey.png";
import Options from "../../../assets/images/optionsIcon.svg";
import BlackEye from "../../../assets/images/view-icon.svg";
import ViewedIcon from "../../../assets/images/black-eye.svg";
import BlackDownload from "../../../assets/images/download.svg";
import colorIcon from "../../../assets/images/color-icon.svg";
import close from "../../../assets/images/cancel-icon.svg";
import languageIcon from "../../../assets/images/language-icon.svg";
import Premium from "../../../assets/images/tags.svg";
import Ads from "../../../assets/images/ads-icon.svg";
import FeaturedIcon from "../../../assets/images/featuredAd.png";
import BlackShare from "../../../assets/images/black-share.svg";
import save1 from "../../../assets/images/save-filed.svg";
import save2 from "../../../assets/images/save-holo.svg";
import liked from "../../../assets/images/Like.svg";
import likdeFill from "../../../assets/images/Like-fill.svg";

import training from '../../../assets/images/eventss-training.png';

// import { AiFillHeart } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { API_LIMIT, DEFAULT_PUBLISH_DATE, SHARE_BASE_URL, START_PAGE, USER_TRACKING, UserTracking } from "../../../utils/Constant";
import { formatNumber, getUserDetails, isPremiumAccess, isToday } from "../../../utils/ReusableFunctions";
import { commonAuthPostApi, getDetailsApi } from "../../home/Api";
import { getToken } from "../../../utils/ManageToken";
import Modal from 'react-bootstrap/Modal';
import { useEffect } from "react";
import { RWebShare } from "react-web-share";
import { toast } from "react-toastify";
import SaveCollectionModal from "./SaveCollectionModal";
import SignUp from "./SignUp";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import { VideoDetailsDownloadModal } from "../../home/_utils";
import { AlertModal, CustomModal } from "../pages";
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */


export default function Card({
  classes,
  imageClass,
  defaultImage = defaultVideoThmbnail,
  contentType,
  data = "",
  selectedCard,
  setSelectedCard,
  setReplacePostData,

  setColor,

  setLanguage,

  setLanguageOptions,

  setColorOptions,
  variation,
  setVariation,
  setCaption,
  setVariationId,
  checkbox
}) {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  // const [option, setOption] = useState(false);
  const [isValidThumbnail, setIsValidThumbnail] = useState(true)
  const [saveTo, setSaveTo] = useState(false)
  const [filePath, setFilePath] = useState('')
  const [confirm, setConfirm] = useState(false)
  const [confirm2, setConfirm2] = useState(false)
  const [signup, setSignup] = useState(false);
  const [allData, setAllData] = useState([]);
  const [path, setPath] = useState({});
  const [show, setShow] = useState(false);
  const [updatedTitle, setUpdatedTitle] = useState("");
  const [variationList, setVariationList] = useState([]);
  const [languageData, setLanguageData] = useState([]);
  const [colorData, setColorData] = useState([]);
  const [isAlert, setIsAlert] = useState(false);
  const [alertData, setAlertData] = useState({});
  const [updatedDescription, setUpdatedDescription] = useState("");
  const [isPremium, setIsPremium] = useState(false);
  const [limit, setLimit] = useState("");
  const [pending, setPending] = useState("");
  const [modalData, setModalData] = useState({});

  const [userStats, setUserStats] = useState({ likeCount: data?.likeCount, isLiked: data?.userStats?.isLiked, isSaved: data?.userStats?.isSaved, isDownloaded: data?.userStats?.isDownloaded, isSearched: data?.userStats?.isSearched, isShared: data?.userStats?.isShared, isViewed: data?.userStats?.isViewed });
  const { likeCount, isLiked, isSaved, isViewed } = userStats;
  const [collectionName, setCollectionName] = useState('Collection Name')

  const userTrackingOption = [
    { name: (isSaved ? UserTracking.UNSAVE : UserTracking.SAVED), image: "BsBookmarkFill", label: "Save to Collection" },
    { name: (!isLiked ? UserTracking.LIKED : UserTracking.UNLIKE), image: "AiFillHeart", label: !isLiked ? "Like" : 'Unliked' },
  ]

  useEffect(() => {
    let patObj = data?.contentVariation?.find((item) => item?.isDefault === true);

    if (!patObj) {
      patObj = data?.defaultVariation?.isDefault === true ? data?.defaultVariation : undefined;
    }
    setFilePath(patObj?.contentFilePath || '')
  }, []);

  const handleObject = (data) => {

    setLanguage(data?.language?.languageName);
    // setColor(data?.color)
    setVariationId(data?.uuid)
  }
  const token = getToken();

  const showDownloadModal = () => {
    setShow(!show)
  }

  const onHide = (() => {
    setIsAlert(false)
    if ((modalData?.data?.limit > 0 && modalData?.data?.pending > 0) || modalData?.data?.limit == 0) {
      setLimit(modalData.data.limit);
      setPending(modalData?.data?.pending);
      downloadTrackingHandler()
    }
  })

  const handleDownload = (async () => {
    const details = {
      data: {
        "contentUuid": data?.uuid,
        "actionType": "Downloaded"
      }
    }
    const user = getUserDetails();
    if (token) {
      if (data?.isPremium) {

        if (user?.isPremium) {
          const res = await commonAuthPostApi("website/content/check-subscription", details)
          if (res.data) {
            if (res.data.alerts.length > 0) {
              setIsAlert(true)
              setAlertData(res?.data)
              setModalData(res)

            }
            else {
              if ((res?.data?.limit > 0 && res?.data?.pending > 0) || res?.data?.limit == 0) {
                setLimit(res.data.limit);
                setPending(res?.data?.pending);
                downloadTrackingHandler()
              }
            }

          }
        } else {
          setConfirm2(true)
        }
      } else {
        const res = await commonAuthPostApi("website/content/check-subscription", details)

        if (res.data) {
          if (res.data.alerts.length > 0) {
            setIsAlert(true)
            setAlertData(res?.data)
            setModalData(res)

          }
          else {
            if (res?.data?.pending > 0) {
              setLimit(res.data.limit);
              setPending(res?.data?.pending);
              downloadTrackingHandler()
            }
          }
        }
      }

    }
    else {
      setSignup(true)
    }
  })
  const handleCheckboxClick = (e, data) => {
    e.stopPropagation();
    // e.preventDefault();
    setSelectedCard(data);

    setReplacePostData(data);

    setVariation(data?.contentVariation)
    setCaption(data?.title)
    let languageOption1 = data?.contentVariation?.map((item) => item.language);
    let languageOption = Object.values(languageOption1.reduce((uniqueMap, obj) => {
      uniqueMap[obj.languageName] = obj;
      return uniqueMap;
    }, {}));

    let colorOption = data?.contentVariation?.map((item) => item.color);

    setLanguageOptions(languageOption);
    setColorOptions(colorOption);

    if (languageOption?.length === 1) {
      let getFixedObj = data?.contentVariation?.find(item => item.language?.languageName === languageOption[0]?.languageName);
      handleObject(getFixedObj);

    }

    // if (colorOption?.length > 1) {
    // let getFixedObj = data?.contentVariation?.find(item => item.color?.colorName === colorOption[0]?.colorName);
    // handleObject(getFixedObj);

    // }
    // setCaption(res?.caption);
    const defaultColor = colorOption?.find(item => item?.colorName == "Default")
    setColor(defaultColor);

    const defaultVariation = data.contentVariation.find(item => item.color.colorName == "Default")
    setVariationId(defaultVariation.uuid)

  };

  function navigate(data) {
    const isPremium = data.isPremium;
    const token = getToken();
    const isPremiumAccessed = isPremiumAccess();

    const navigateToDetails = () => {
      history.push({
        pathname: `/details/${contentType?.replace("/", "_").toLowerCase()}&id=${data?.uuid || START_PAGE}`,
        state: {
          'breadCrumbPath': (params.get('key') ?? false) ? [params.get('key'), params.get('value')] : '',
          'searchValue': params.get('searchValue') || ""
        }
      });
    };

    if (isPremium) {
      if (!token) {
        setSignup(true);
      } else if (!isPremiumAccessed) {
        setConfirm2(!confirm2);
      } else {
        navigateToDetails();
      }
    } else {
      navigateToDetails();
    }
  }

  // ec2c5f68-5de8-11ee-9c26-b48c9dd517c6
  async function userTrackingHandler(content) {
    // console.log(content)
    const info = {
      "data": {
        "contentUuid": data.uuid,
        "type": content,
        "contentVariationUuid": data?.defaultVariation && data?.defaultVariation.uuid ? data?.defaultVariation.uuid : data?.contentVariation.find(x => x.isDefault === true)?.uuid,
      }
    }
    let token = getToken();
    if (!token) {
      if (content !== UserTracking.SHARED) {

        toast.error("You need to login first");
        setSignup(true);
      }
      // alert("You need to login first");
    } else if (content === UserTracking.SAVED || content === UserTracking.UNSAVE) {
      setSaveTo(!saveTo);
    } else {
      const response = await commonAuthPostApi(USER_TRACKING, info);
      // console.log("In card Like, Saved, share", response);
      if (content === UserTracking.LIKED || content === UserTracking.UNLIKE) {
        const newLikeCount = !isLiked ? likeCount + 1 : likeCount - 1;
        setUserStats({ ...userStats, likeCount: newLikeCount, isLiked: content === UserTracking.LIKED });
      }
      if (response && !response?.message) {
        toast.success(`Succesfully ${content}`);
      }
    }


  }
  async function downloadTrackingHandler() {

    // const info = {
    // "data": {
    // "contentUuid": data.uuid,
    // "type": UserTracking.DOWNLOADED,
    // "contentVariationUuid": data?.defaultVariation ? data?.defaultVariation.uuid : data?.contentVariation.find(x => x.isDefault === true)?.uuid,
    // }
    // }


    // let token = getToken();
    // const anchor = document.createElement('a');
    // const patObj = data?.contentVariation?.find((item) => item?.isDefault === true);
    // console.log("In card Like, Saved, share", patObj);
    // if (patObj?.contentFilePath && token) {
    // const response = await commonAuthPostApi(USER_TRACKING, info);
    // if (!response.message) {
    // // if (true) {
    // toast.success(`Successfully Downloaded`);
    // const splitUrl = patObj?.contentFilePath?.split('.');
    // const fileFormat = splitUrl[splitUrl.length - 1];
    // console.log("File Format:", fileFormat);
    // FileSaver.saveAs(patObj?.contentFilePath, `content.${fileFormat}`);
    // // console.log("Check Path", patObj);
    // // patObj?.contentFilePath ? anchor.href = patObj?.contentFilePath : toast.error("No download file available.");
    // // anchor.target = "_blank";
    // // anchor.click();
    // }
    // }

    const details = await getDetailsApi(`website/content/details/${data?.uuid}`);
    const variation = details.data.data?.contentVariation;
    variation && variation.length > 0 && variation.push(details.data.data?.defaultVariation);
    if (!details?.response?.data?.message) {
      setAllData(details?.data?.data)
      setPath(details?.data.data?.defaultVariation?.contentFilePath)
      setUpdatedTitle(details?.data?.data?.defaultVariation?.title)
      setVariationList(variation)
      setUpdatedDescription(details?.data?.data?.defaultVariation?.description)
      setLanguageData(details?.data.data?.defaultVariation?.language?.languageName)
      setColorData(details?.data?.data?.defaultVariation?.color)

      setShow(!show)
      setIsPremium(details?.data?.data?.isPremium)
    }

  }
  // conformCollectionPopUp
  function conformCollectionPopUp(data) {
    // console.log("Conform to saved says,", data)
    setSaveTo(data.label);
    setCollectionName(data.selectedItem);
    setConfirm(location.pathname.includes('collection-details') ? false : !confirm);
    if (!data.unSaved) {
      setUserStats({ ...userStats, isSaved: false });
    } else {
      setUserStats({ ...userStats, isSaved: true });
    }

    if (location.pathname.includes('collection-details')) {
      // console.log("Thsi is cllecton details page");
      history.push({ pathname: location.pathname, state: { 'collectionName': location.state?.collectionName, 'isRender': data.id } })//temp pagesze remve ,becuse its not in backend

    }
  }
  function navigateAmcDetails() {
    history.push({ pathname: `/content-type/All`, search: `?key=amc&value=${data?.amc}&pageNumber=${START_PAGE}&pageSize=${API_LIMIT}`, state: data })//temp pagesze remve ,becuse its not in backend
  }


  return (
    <div className={`common-card-box ${classes}`}>
      {signup && <SignUp show={signup} handleClose={() => setSignup(!signup)} />}
      <div className={`main-img position-relative lato-medium fs-14-12 ${(data.thumbnail && isValidThumbnail) ? "" : 'overlay-bg'} ${imageClass}`}
        onClick={() => !checkbox && navigate(data)}
      >{data.thumbnail && isValidThumbnail ?

        <div>
          <ImageBlurEffect path={data.thumbnail || defaultImage} setIsValidThumbnail={setIsValidThumbnail} imageClass={imageClass} />
        </div>
        :
        <p className="">{data.title || "Default title"}</p>
        }
        {checkbox == true &&
          <div className="checkbox-container" onClick={(e) => e.stopPropagation()}>
            <input
              type="checkbox"
              // id={`post_card_id_${index}`}
              // className="d-none"
              checked={data?.uuid == selectedCard?.uuid}
              onChange={(e) => { handleCheckboxClick(e, data) }}


            />

          </div>

        }
        <div className="premium-icon ">


          {data?.isPremium && (
            <Image src={Premium} className="premium-img" alt="Premium" />
          )}
          {data?.isAd && !data?.isFeaturedAd && (
            <Image src={Ads} className="add-ion" alt="ads" />
          )}
          {data?.isFeaturedAd && (
            <Image src={FeaturedIcon} className="add-ion" alt="ads" />
          )}
        </div>


        {/* =================== AMC LOGO ADDED FOR MOBILE ============== */}
        {
          <Image src={data?.amcCircleImagePath || data?.creatorCircleImagePath || defaultIcon} className="des-profile amc-logo-mobile mb-show" title={data?.amc} alt="amc logo" />
        }
        {/* =================== AMC LOGO ADDED FOR MOBILE ============== */}
        {/* =========================== COLOR LANGUAGE ICONS ============== */}
        <div className="color-language-icon-wrapper">
          {data?.isMultiColor &&
            <div className="color-frame">
              <Image src={colorIcon} alt="color Icon" />
              <p className="lato-regular fx-12 black-242 ">Color option available</p>
            </div>
          }
          {data?.isMultiLanguage &&
            <div className="language-frame">
              <Image src={languageIcon} alt="language Icon" />
              <p className="lato-regular fx-12 black-242 ">Multiple languages available</p>
            </div>
          }
        </div>
        {/* =========================== COLOR LANGUAGE ICONS ============== */}
      </div>

      <div className="description-box">
        <Image onClick={data?.amcCircleImagePath && navigateAmcDetails} src={data?.amcCircleImagePath || data?.creatorCircleImagePath || defaultIcon} className="des-profile desk-show" title={data?.amc} />
        <div className="des-content-wrappper" onClick={() => !checkbox && navigate(data)}>
          <p onClick={() => !checkbox && navigate(data)} className="fs-16-14 lato-regular black-242 text-ellipsis-two" title={data.title || "NA"}>
            {data.title || "NA"}
          </p>
          <p className=" fs-14-11 lato-medium black-242 mt-1">
            <span>

              {isLiked ? <Image src={likdeFill} className="imageIcon social-icon fs-14-12" /> : <Image src={liked} className="imageIcon social-icon fs-14-12" />}
              {formatNumber(data?.likeCount === likeCount ? data?.likeCount : data?.likeCount < likeCount ? data?.likeCount + 1 : data?.likeCount - 1) || 0}
              {/* {formatNumber(likeCount) || 0} */}
            </span>
            <span className="mb-border-none fs-14-11">
              {/* <PiEyeFill className="icon fs-14-12" /> */}
              {isViewed ? <Image src={ViewedIcon} className="icon w-20 fs-14-12" /> : <Image src={BlackEye} className="icon w-20 fs-14-12" />}

              {/* <Image src={isViewed?ViewedIcon:BlackEye} className="icon w-17 fs-14-12" /> */}
              {formatNumber(data?.viewCount) || 0}
            </span>
            {
              data?.publishedAt && data?.publishedAt !== DEFAULT_PUBLISH_DATE &&
              <span className="desk-show fs-14-11">
                {isToday(moment(data?.publishedAt, moment.ISO_8601, true).isValid() ? moment(data?.publishedAt).toISOString() : moment(data?.publishedAt, "DD-MM-YYYY").toISOString())
                  ? 'Today'
                  : moment(data?.publishedAt, moment.ISO_8601, true).isValid()
                    ? moment(data?.publishedAt).fromNow()
                    : moment(data?.publishedAt, "DD-MM-YYYY").fromNow()}
              </span>

            }

          </p>
        </div>

        {
          show &&
          <VideoDetailsDownloadModal
            show={show}
            handleClose={showDownloadModal}
            contentType={contentType}
            detailsData={allData}
            downloadPath={path}
            variation={variationList}
            languageColor={{ "language": languageData, "color": colorData }}
            updatedThings={{ "tittle": updatedTitle, "description": updatedDescription }}
            variationId={variation?.uuid}
            signup={false}
            isPremium={isPremium}
            limit={limit}
            pending={pending}
          // selectedVariation={}
          />
        }



        {/* ========================== Three drop down ========================== */}
        <Dropdown>
          <Dropdown.Toggle className="dropdown-toggle option-icon" id="dropdown-basic">
            <Image
              src={Options}
              // className="option-icon"
              alt="icon"
            />
          </Dropdown.Toggle>
          <Dropdown.Menu className="card-options">
            {
              userTrackingOption?.map((item, i) => {
                return (
                  <Dropdown.Item className="lato-semibold d-flex fx-14 black-242" onClick={() => userTrackingHandler(item.name)}>
                    {/* <Image src={item.image} alt="icon" style={{color:isLiked?"red":""}} /> */}
                    <spam className="icon-fixe-width">
                      {item.image === "AiFillHeart" ? (
                        isLiked ? <Image src={likdeFill} className="imageIcon social-icon m-0" /> : <Image src={liked} className="imageIcon social-icon" />
                      ) : (
                        (isSaved) ? <Image src={save1} className="imageIcon m-0" /> : <Image src={save2} className="imageIcon" />
                      )}
                    </spam>
                    {item.label}
                  </Dropdown.Item>

                )
              })
            }
            <Dropdown.Item className={`lato-semibold fx-14 d-flex black-242 ${filePath ? '' : 'pointer-none disable-item'}`} onClick={filePath ? (() => handleDownload()) : ""} >
              <spam className="icon-fixe-width"> <Image src={BlackDownload} alt="icon" className="imageIcon m-0" />
              </spam>
              Download
            </Dropdown.Item>

            <Dropdown.Item className="lato-semibold fx-14 black-242" >
              <RWebShare
                data={{
                  text: "",
                  url: `${SHARE_BASE_URL + "details/" + contentType.toLowerCase() + "&id=" + data.uuid}`,
                  title: data.title,
                }}
                onClick={() => { userTrackingHandler(UserTracking.SHARED) }}
              >

                <div className="lato-semibold fx-14 black-242 d-flex share-wrapper-btn ">
                  <spam className="icon-fixe-width">
                    <Image src={BlackShare} className="imageIcon w-21" alt="icon" />
                  </spam>
                  Share </div>
              </RWebShare>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {/* ========================== Three drop down ========================== */}
      </div>
      {/* ?============================ SAVE TO MODAL START ========================= */}
      {saveTo &&
        <SaveCollectionModal id={data.uuid} variationId={data.contentVariation && data.contentVariation[0]?.uuid || data?.defaultVariation?.uuid} show={saveTo} handleClose={() => setSaveTo(!saveTo)} collectionPopUp={conformCollectionPopUp} isUnSaved={isSaved} />
      }
      {/* ?============================ SAVE TO MODAL END ========================== */}
      {
        confirm &&
        <Modal className="save-confirm-collection-custom-model" centered show={confirm} onHide={() => setConfirm(!confirm)}>

          <Modal.Body className="save-confirm-collection-model-body">
            <Image src={close} onClick={() => setConfirm(!confirm)} alt="canle icon" className="close-btn pointer" />
            <h4 className="lato-regular fs-28-18 black-242 w-100 text-center">{collectionName}</h4>
            <div className="d-flex justify-content-center ">
              <div className={`primary-btn py-3 pointer`} onClick={() => { history.push('/mycollections') }}>View Collection</div>
            </div>
          </Modal.Body>
        </Modal>

      }

      <AlertModal show={isAlert} onHide={onHide} alertData={alertData} type={"usage"} modalClass={"alerts-modal"} />
      {/* -------------------------------- UPGRD PRENIUM PLAN MODAL START------------------------ */}
      {
        confirm2 &&
        <Modal className="save-confirm-collection-custom-model" centered show={confirm2} onHide={() => setConfirm2(!confirm2)}>

          <Modal.Body className="save-confirm-collection-model-body text-center">
            <Image src={close} onClick={() => setConfirm2(!confirm2)} alt="canle icon" className="close-btn pointer" />
            <Image src={training} className="mb-10" />
            <h4 className="lato-regular fs-24-16 black-242 w-100 text-center">This content is available only to the subscribed members. Want to be a part of this <span className="primary"> premium community</span>? </h4>
            <div className="d-flex justify-content-center ">
              <div className={`primary-btn py-3 pointer`} onClick={() => { history.push('/mymembership') }}>View Membership Plans</div>
            </div>
          </Modal.Body>
        </Modal>
      }
      {/* -------------------------------- UPGRD PRENIUM PLAN MODAL START------------------------ */}
    </div>
  );
}





const ImageBlurEffect = ({ path, setIsValidThumbnail, imageClass }) => {
  // const [isImageLoaded, setImageLoaded] = useState(false);
  // const lowQualityUrl = `${path}?q=low&f=auto&blur=500`;

  return (<>

    <LazyLoadImage
      className={`main-img ${imageClass}`}
      alt="image"
      effect="blur"
      src={path}
      onLoad={(e) => { }}
      onError={() => setIsValidThumbnail(false)}
      draggable={false}

    />
  </>
  );
};


