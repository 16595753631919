import React, { useEffect, useState } from 'react'
import RangeFields from './components/RangeFields'
import BarChart from './components/BarChart'
import { CheckToolsSubscription, ShowShare, ToolsScreenshotHeader, captureImageHandler, debounce, formatCurrency, toolsDisclaimer, toolsInvestorDetails } from '../../utils/ReusableFunctions';
import { useMemo } from 'react';
import { useRef } from 'react';
export default function SWP({ calculator, textLogo = '', imageData, setImageData, isDisable, setIsDisable, setAlertData, setIsAlert, setModalData }) {


  let [corpusAmount, setCorpusAmount] = useState(15000000);
  let [estateAmount, setEstateAmount] = useState(100000);
  let [SWPGrowthRate, setSWPGrowthRate] = useState(10);
  let [rateOfInterest, setRateOfInterest] = useState(15);
  let [tenure, setTenure] = useState(20);
  let [totalInvestedCorpus, setTotalInvestedCorpus] = useState(15000000);
  let [totalWithdrawal, setTotalWithdrawal] = useState(63397869);
  let [estateValue, setEstateValue] = useState(100000);
  const onSubmitAction = (corpusAmount, estateAmount, SWPGrowthRate, roi, tenure, totalInvestedCorpus, totalWithdrawal, estateValue) => {
    SWPGrowthRate = SWPGrowthRate / 100;
    roi = roi / 100;
    var partA = 0;
    partA = estateAmount * (1 / (1 + roi) ** tenure)
    var partB = 0;
    partB = corpusAmount - partA;

    var partC = 0;
    partC = Math.round((partB / (1 - (((1 + SWPGrowthRate) / (1 + roi)) ** tenure)) * (roi - SWPGrowthRate)) / (1 + roi));
    var corpusArray = [corpusAmount];
    var balanceArray = [];
    var balanceGrowthArray = [];

    var annualSWPOldArray = [partC];
    var annualSWPNewArray = [corpusAmount];
    var cumulativeSWPOldArray = [annualSWPOldArray[0]];
    var cumulativeSWPNewArray = [];
    for (var i = 0; i < (tenure); i++) {

      if (i >= 1)
      // for above zero cases
      {

        annualSWPNewArray.push(annualSWPOldArray[i - 1] * (1 + SWPGrowthRate));
        annualSWPOldArray.push(annualSWPNewArray[i]);
        corpusArray.push(balanceGrowthArray[i - 1]);

        cumulativeSWPNewArray.push(Number(cumulativeSWPOldArray[i - 1]) + Number(annualSWPOldArray[i]))
        cumulativeSWPOldArray.push(cumulativeSWPNewArray[i - 1])
      }
      // for 0 cases
      else {

      }
      // all cases
      balanceArray.push(corpusArray[i] - annualSWPOldArray[i]);
      balanceGrowthArray.push(balanceArray[i] + (balanceArray[i]) * roi);
    }

    totalWithdrawal = Math.round(cumulativeSWPNewArray[tenure - 2]) ? Math.round(cumulativeSWPNewArray[tenure - 2]) : 0

    setCorpusAmount(corpusAmount);
    setEstateAmount(estateAmount);
    setSWPGrowthRate(SWPGrowthRate * 100);
    setRateOfInterest(roi * 100);
    setTenure(tenure);
    setTotalInvestedCorpus(totalInvestedCorpus);
    setTotalWithdrawal(totalWithdrawal);
    setEstateValue(estateValue);

  };
  // useEffect(() => {
  //   onSubmitAction(corpusAmount, estateAmount, SWPGrowthRate, rateOfInterest, tenure, totalInvestedCorpus, totalWithdrawal, estateValue);
  // }, [])
  useEffect(() => {
    setCorpusAmount(corpusAmount);
    setEstateAmount(estateAmount);
    setSWPGrowthRate(SWPGrowthRate);
    setRateOfInterest(rateOfInterest);
    setTenure(tenure);

    setTotalInvestedCorpus(totalInvestedCorpus);
    setTotalWithdrawal(totalWithdrawal);
    setEstateValue(estateValue);
  }, [corpusAmount, estateAmount, SWPGrowthRate, rateOfInterest, tenure, totalInvestedCorpus, totalWithdrawal, estateValue])

  const handleChange = (value, name) => {
    if (name === "corpusAmount") {
      corpusAmount = value
    }
    if (name === "estateAmount") {
      estateAmount = value
    }
    if (name === "SWPGrowthRate") {
      SWPGrowthRate = value
    }
    if (name === "rateOfInterest") {
      rateOfInterest = value
    }
    if (name === "tenure") {
      tenure = value
    }
    onSubmitAction(corpusAmount, estateAmount, SWPGrowthRate, rateOfInterest, tenure, totalInvestedCorpus, totalWithdrawal, estateValue);
  }





  let downloadScreenshotFunc = null;

  const setDownloadScreenshotFunc = (func) => {
    downloadScreenshotFunc = func;
  };
  const barChart = useMemo(() => {
    return (
      <BarChart
        info={{
          labels: ["Total Invested Corpus", "Total Withdrawal", "Estate Value"],
          data: [corpusAmount, totalWithdrawal, estateAmount],
        }}
      />
    );
  }, [corpusAmount, totalWithdrawal, estateAmount]);
  return (
    <div>
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART START ---------------------------------------------- */}
      <div className="calculator-result-main-wrapper">
        <div className="calculator-component-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Calculator
          </h4>
          {/* <RangeSlider min={10} max={5000} /> */}

          <RangeFields
            label="Corpus or Investment Amount"
            name={"corpusAmount"}
            value={corpusAmount}
            minVal={100000}
            maxVal={1000000000}
            step={100000}
            getValue={debounce(handleChange)}
            showComma />
          <RangeFields
            label="Estate Amount or Corpus to be Preserved"
            name={"estateAmount"}
            value={estateAmount}
            minVal={100000}
            maxVal={1000000000}
            step={100000}
            getValue={debounce(handleChange)}
            showComma />
          <RangeFields
            label="Monthly SWP Rate"
            name={'SWPGrowthRate'}
            value={SWPGrowthRate}
            minVal={0}
            maxVal={50}
            unit="%"
            step={0.5}
            getValue={debounce(handleChange)}
          />
          <RangeFields
            label="Expected Rate of Return"
            name={"rateOfInterest"}
            value={rateOfInterest}
            minVal={1}
            maxVal={50}
            unit="%"
            step={0.5}
            getValue={debounce(handleChange)}
          />
          <RangeFields
            label="Investment Tenure"
            name={"tenure"}
            value={tenure}
            minVal={1}
            maxVal={50}
            unit="Year"
            step={1}
            getValue={debounce(handleChange)}
          />

        </div>
        <div className="result-graph-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Results{" "}
            {/* {showShare(calculator)} */}

            {<ShowShare calculator isDisable={isDisable} setIsDisable={setIsDisable} clickHandler={() => { CheckToolsSubscription(textLogo, downloadScreenshotFunc, calculator?.uuid, imageData, setIsDisable, setAlertData, setIsAlert, setModalData) }} />}

          </h4>
          <div className="result-graph-wrapper">
            <div className={`graph-1-frame flex-column ${false ? "w-auto" : "w-100"}`}>

              {barChart}
            </div>
          </div>
          {swpResultText(corpusAmount, SWPGrowthRate, estateAmount, tenure, totalWithdrawal, rateOfInterest)}

        </div>
      </div>
      <div className="make-none">

        <CalculatorOutputScreenShot
          textLogo={textLogo}
          corpusAmount={corpusAmount}
          totalWithdrawal={totalWithdrawal}
          estateAmount={estateAmount}
          SWPGrowthRate={SWPGrowthRate}
          tenure={tenure}
          rateOfInterest={rateOfInterest}
          onDownloadScreenshot={setDownloadScreenshotFunc}
          setImageData={setImageData}
        />
      </div>
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART END ---------------------------------------------- */}
    </div>
  )
}
function CalculatorOutputScreenShot({ textLogo, corpusAmount, totalWithdrawal, estateAmount, SWPGrowthRate, tenure, rateOfInterest, onDownloadScreenshot, setImageData, totalInvestedCorpus, estateValue }) {
  const { cobrandingLabelling, investor } = textLogo;
  const [signup, setSignup] = useState(false);
  const screenshotRef = useRef(null);

  useEffect(() => {
    if (onDownloadScreenshot) {

      captureImageHandler(onDownloadScreenshot, screenshotRef, setSignup, setImageData);
    }
  }, [textLogo, screenshotRef, corpusAmount, estateAmount, SWPGrowthRate, rateOfInterest, tenure, totalInvestedCorpus, totalWithdrawal, estateValue]);
  return (
    <>
      {/* {signup && <SignUp show={signup} handleClose={() => setSignup(!signup)} />} */}

      <div ref={screenshotRef} className="tools-calulator-output-screenshot-frame ">
        {ToolsScreenshotHeader(cobrandingLabelling)}

        <div className={`graph-1-frame bg-gray ${false ? "w-auto" : "w-100"}`}>
          <BarChart
            info={{
              labels: ["Total Invested Corpus", "Total Withdrawal", "Estate Value"],
              data: [corpusAmount, totalWithdrawal, estateAmount],
            }}
          />
        </div>
        {toolsInvestorDetails(investor)}
        <br /><br />
        {swpResultText(corpusAmount, SWPGrowthRate, estateAmount, tenure, totalWithdrawal, rateOfInterest)}
        {toolsDisclaimer()}
      </div>
    </>
  )
};

function swpResultText(corpusAmount, SWPGrowthRate, estateAmount, tenure, totalWithdrawal, rateOfInterest) {
  return (
    <p className=" f-s-16 lh-24 black-242 op-7 pt-2 text-center">
      With an initial investment of <span className=" lato-semibold primary">₹{formatCurrency(corpusAmount)}</span> in an SWP plan with a monthly withdrawal rate of <span className=" lato-semibold primary">{SWPGrowthRate}%</span> and an estate amount of <span className=" lato-semibold primary">₹{estateAmount}</span> that needs to be preserved over a period of <span className=" lato-semibold primary">{tenure}</span> years, your total withdrawals will amount to <span className=" lato-semibold primary">₹{formatCurrency(totalWithdrawal)}</span>, if the invested corpus keeps growing at <span className=" lato-semibold primary">{rateOfInterest}%</span>  for the investment tenure.
    </p>

  )
}