import React, { useEffect, useState } from 'react'
import Header from './Header'
import defaltImg from '../../../assets/images/default-img.png'
import optionsIcon from '../../../assets/images/optionsIcon.svg'
import setting from '../../../assets/images/settings_Notification.svg'
import { CardHeader } from '../components'
import { BreadCrumb } from '../../home/_utils'
import { Dropdown, Image } from 'react-bootstrap'
import { getNotificationListeningApi, modifyNotificationApi } from '../../home/Api'
import moment from 'moment'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import Pagination from './Pagination'
import { START_PAGE } from '../../../utils/Constant'
import { calculateTotalPageCount, isResOk } from '../../../utils/ReusableFunctions'
import NotFound from './NotFound'
/* eslint-disable react-hooks/exhaustive-deps */


export default function NotificationPage() {
  const location = useLocation();
  const history = useHistory()
  const params = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(parseInt(params.get('pageNumber')) || START_PAGE);
  const [notificationList, setNotificationList] = useState([]);
  const [filterValue, setFilterValue] = useState([]);
  const [isLoading, setILoading] = useState(true);
  const [isDropdownVisible, setDropdownVisible] = useState(false);


  const getNotificationListeningData = async () => {
    setCurrentPage(parseInt(params.get('pageNumber')))
    const res = await getNotificationListeningApi(`inAppNotification/list?pageNumber=${params.get('pageNumber')}&pageSize=${params.get('pageSize')}${filterValue.length > 0 ? `&filter=` + filterValue?.join(',') : ''}`);
    if (isResOk(res.status)) {
      setNotificationList(res.data.data);
      setILoading(false)
    } else {
      setILoading(false)
    }

  }
  useEffect(() => {
    getNotificationListeningData();
  }, [filterValue, location.search])
  const addFilterHandler = (a) => {

    if (filterValue?.includes(a)) {
      setFilterValue(filterValue?.filter(item => item !== a))
    } else {
      setFilterValue([...filterValue, a])
    }
  }
  const navigateToSettings = (tab) => {
    history.push(`/notification-setting/${tab}`)
  }
  const showDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  }
  const getModifyNotificationApi = async (url) => {
    const res = await modifyNotificationApi(url);
    if (isResOk(res.status)) {
      getNotificationListeningData()

    }
  }
  return (
    <div className='page-padding'>
      <Header />
      <section className="notification-main-section">
        {/* ============================================ TOP FILTERS SECTION START ============================ */}
        <div className="container">
          <BreadCrumb path={["Notifications"]} clasese="mb-3-desk" />

          <div className="notification-sub-header d-flex align-items-center justify-content-between">
            <CardHeader
              countTitle={'Notifications'}
              count={notificationList?.length}
              hide={false}
            />
            <div className="">

              {/* <h4 className=' lato-semibold fx-12 black-242 pointer'>Mark all as Read</h4> */}
              <div className="d-flex align-items-center gap-12">
                <div onClick={() => navigateToSettings('Content')} className=" d-flex align-items-center  pointer  fs-16-14 lato-regular primary">
                  <Image src={setting} alt='icon-setting' />&nbsp; Setting
                </div>
                <Dropdown show={isDropdownVisible} onToggle={setDropdownVisible} className="single-select-api d-flex align-items-center pointer">
                  <Dropdown.Toggle as={Image} src={optionsIcon} onClick={showDropdown} alt="icon" />
                  <Dropdown.Menu >
                    <Dropdown.Item className="lato-regular fx-14 black-242" onClick={() => {
                      getModifyNotificationApi('/inAppNotification/readAll')
                    }}>Mark all as read</Dropdown.Item>
                    <Dropdown.Item className="lato-regular fx-14 black-242" onClick={() => getModifyNotificationApi('/inAppNotification/removeAll')}>Delete all</Dropdown.Item>

                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>



        </div>
        {/* ============================================ TOP FILTERS SECTION END ============================ */}

        <div className="container-mb mt-4">
          <div className="notificaton-card-wrapper">
            {isLoading ? <div className='at-hight lato-bold fs-32-28'>Loading...</div> : (
              notificationList.length > 0 ? notificationList?.list?.map((item, i) => {
                return (
                  <div className="notification-main-card  active">
                    <div className="notificaton-tick-img-frame">
                      <div className="notifi-disk"> </div>
                      <div className="img-wrapper">
                        <Image src={item.thumbnailPath || defaltImg} alt="" />
                      </div>
                    </div>

                    <div className="notification-content ">
                      <div className="title-header d-flex align-items-center justify-content-between">
                        <h4 className=' lato-regular fs-22-12 black-242'>{item?.title || 'Na'}</h4>
                        {/* <Dropdown className="single-select-api  ">
                                                <Dropdown.Toggle className=" border-0 shadow-none  bg-transparent" id="dropdown-basic">
                                                    <Image src={optionsIcon} alt='icon' className=' pointer' />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="">
                                                    {["Mark as read", 'Delete notification', 'Turn off this notification type']?.map((item, i) => {
                                                        return (
                                                            <Dropdown.Item key={i} onClick={() => { }} className="lato-regular fx-14 black-242 " href=''>{item}</Dropdown.Item>
                                                        )
                                                    })}
                                                </Dropdown.Menu>
                                            </Dropdown> */}
                      </div>
                      <div className="description-view">
                        <p className=' lato-regular fs-14-12 black-242'>{item?.discription || ""}  </p>
                        <a rel="noreferrer" href={item?.link} target='_blank' ><span className=" fs-14-12  lato-semibold text-center pointer black-242 pointer">View</span></a>

                      </div>
                      <div className="footer-content d-flex align-items-center justify-content-end">
                        <span className=' lato-semibold fx-12 black-242'>{moment(item?.createdAt).fromNow()}</span>
                      </div>
                    </div>
                  </div>
                )
              }) :
                <NotFound />
            )
            }

          </div>
        </div>
        {notificationList?.length > 18 &&
          <Pagination
            history={history}
            location={location}
            page={currentPage}
            pageCount={calculateTotalPageCount(notificationList && (((notificationList?.length) ?? 0) !== 0) ? notificationList.length : 10)}
          />
        }
      </section>
    </div>
  )
}
