import React, { useState, useEffect } from "react";
import { Accordion, Button, Card, Dropdown, Image } from "react-bootstrap";
import { SearchDropDown } from "../../common";
import DropdownCommon from "./DropdownCommon";
import Filter from "../../../assets/images/leadingicon.svg";
import DownIcon from "../../../assets/images/dropdown-icon.svg";
import closeFilter from "../../../assets/images/cancel-icon.svg";
import languageIcon from "../../../assets/images/language-icon.svg";
import Premium from "../../../assets/images/tags.svg";
import colorIcon from "../../../assets/images/color-icon.svg";
import { getAuthDataApi, getCommonApi, getFilterApi } from "../Api";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import { replaceUrlCharacters, toCamelCase } from "../../../utils/ReusableFunctions";
import { BreadCrumb, WelcomeFinModal } from "../_utils";
import { AMC, CREATOR, CUSTOMIZATION, EVENTS, LANGUAGE, LANGUAGE_Code, MAX_LIMIT, PREMIUM, PREMIUM_TITLE, SOCIALMEDIA, SOCIALMEDIA_URL, START_PAGE, TARGETEDCONTENT, TARGETEDCONTENTS, isMultiColor, isMultiLanguage } from "../../../utils/Constant";
import { DateRangePicker } from "../../common/components";
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
/* eslint-disable eqeqeq */



const validValues = [
  "AMC",
  'amc',
  "category",
  "campaigns",
  "languages",
  AMC,
  "targetedContents",
];
const validKeys = [
  "socialMedias"
];
export default function SideFilter({
  component,
  dropdown = true,
  placeholder,
  dataLength,
  isFilter = true,
  isContainer = true,
  isBreadCrumb = true,
  selectedSocialMedia = {},
  isPostPlanner = false,
  updateFilter = null
}) {
  const location = useLocation();
  // console.log(location)
  const history = useHistory();
  const param = useParams();
  const [showFilter, setShowFilter] = useState(true);
  const [showWelcom, setShowWelcom] = useState(true);
  const [targetedChecked, setTargetedChecked] = useState(true);

  const [filterData, setFilterData] = useState([]);

  // const [navUrl, setNavUrl] = useState(new URLSearchParams());
  const [amcData, setAmcData] = useState([]);
  const [activeFilterTab, setActiveFilterTab] = useState([]);
  const [amcList, setAmcList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [contentType, setContentType] = useState("");
  const [targetedContentList, setTargetedContentList] = useState([])

  const [getParam, setNavUrl] = useState(new URLSearchParams(isPostPlanner ? "" : location.search));
  const defaultAmcState = getParam.get('amc') || 'Select AMC';
  const [amcName1, setAmcName1] = useState(defaultAmcState)
  const [isMutual, setIsMutual] = useState('')
  const collectionType = getParam.get('key');
  const [keyName, setKeyName] = useState(getParam.get('value'));
  const excludedKeys = ['key', 'value', 'pageNumber', 'pageSize', 'searchValue'];
  const [updatedFilterURL, setUpdatedFilterURL] = useState(getDefaultFilterParams());
  const filterSingleSelectKeys = ['amc'];

  async function fetchData() {
    let filterUrl = "content/filter";
    if (location.pathname.includes("/events")) {
      filterUrl = 'website/events/filters'
    }
    const data = await getFilterApi(filterUrl);
    const newData = data?.find((item) => item.code === 'TARGETED_CONTENT');
    const mutualFundCompanyTitles = data
      .flatMap(obj => obj.items.filter(item => item.isMutualFundCompany === true))
      .map(item => item.title);
    setTargetedContentList(newData);
    setFilterData(data);
    setIsMutual(mutualFundCompanyTitles?.[0] ?? "")
    setUpdatedFilterURL(getDefaultFilterParams());
  }
  async function fetchDropDownData() {
    // console.log('collectionType', collectionType);
    setKeyName(getParam.get('value'));
    const url = collectionType === 'languages' ? 'website/languages' :
      collectionType === 'socialMedias' ? SOCIALMEDIA_URL :
        collectionType === 'targetedContents' ? 'website/targetedContent/dashboard/list' :
          collectionType === 'creator' ? 'website/contentCreator' :
            'website/' + collectionType.replace(/s\b/g, '');

    const res = await getCommonApi(url + `?pageSize=10000&pageNumber=${START_PAGE}`);

    setAmcData(res?.data?.data);
    // console.log(res?.data?.data)
  }
  const setKeyNameHandler = (name) => {
    setKeyName(name);
    getParam.set('key', collectionType);
    getParam.set('value', (name));
    if (getParam.get('key') === AMC) {
      getParam.set('amc', name);
    }
    setUpdatedFilterURL(getParam.toString());
    console.log(getParam.toString());
    history.push({
      pathname: (location.pathname.includes(EVENTS)) ? `/${EVENTS}` : `/content-type/${param.type ? param.type : "All"}`,
      search: getParam.toString(),
    });
  }
  useEffect(() => {
    if ((collectionType ?? false) && (getParam.get('key') !== CREATOR)) {
      fetchDropDownData()
    }
  }, [collectionType])


  const amcListHandler = async () => {
    const currentUrl = window.location.href;

    // Check if "events" is in the URL
    const isEvent = currentUrl.includes('events');

    // Construct the API endpoint
    const apiUrl = isEvent ? 'website/amc?isEvent=1' : 'website/amc';
    const res = await getAuthDataApi(apiUrl);
    const sortAmc = res.list.sort((a, b) => a.amcName.localeCompare(b.amcName))
    setAmcList(sortAmc);
  }

  useEffect(() => {
    const params = new URLSearchParams(isPostPlanner ? "" : location.search);
    const filterObjUrl = {};

    params.forEach((value, key) => {
      if (!excludedKeys.includes(key)) {
        filterObjUrl[key] = value.split(',');
      }
    });
    if ((getParam.get(CREATOR) === isMutual) || getParam.get('key') === AMC) {
      amcListHandler()
    }
    if (params.has('amc')) {
      setAmcName1(params.get('amc'));
    }
    if (collectionType) {
      fetchDropDownData();
    }
    setActiveFilterTab(filterObjUrl)

    if (params.get(TARGETEDCONTENT)) {
      fetchData();
    }
    // Filter dropdown back button
    const newParams = new URLSearchParams(location.search);
    setKeyName(newParams.get("value"));
    setUpdatedFilterURL(getDefaultFilterParams());

  }, [location.search, collectionType, getParam]);


  // todo:----logic changed
  async function handleFilterClick(itemTitle, key) {

    let updatedFilterUrlArray = updatedFilterURL.split("=");

    let currentParamsArray = [];

    updatedFilterUrlArray.forEach((updatedFilterUrl, index) => {

      if (index > 0) {

        let updatedFilterUrlTempArray = updatedFilterUrl.split("&");
        let updatedFilterUrlTemp1 = updatedFilterUrl.replace(updatedFilterUrlTempArray[updatedFilterUrlTempArray.length - 1], "");
        let updatedFilterUrlArray1 = updatedFilterUrlArray[index - 1].split("&");
        let key = updatedFilterUrlArray1[updatedFilterUrlArray1.length - 1];

        if (index == updatedFilterUrlArray.length - 1) {
          updatedFilterUrlTemp1 = updatedFilterUrl + "&";
        }

        updatedFilterUrlTemp1 = updatedFilterUrlTemp1.substring(0, updatedFilterUrlTemp1.length - 1);
        updatedFilterUrlTemp1 = replaceUrlCharacters(updatedFilterUrlTemp1);

        let value = `${key}=${updatedFilterUrlTemp1}`;

        currentParamsArray.push(value);
      }

    });

    let currentParams = {};

    currentParamsArray.forEach(currentParamsTemp => {
      let currentParamsTempArray = currentParamsTemp.split("=");
      currentParams[currentParamsTempArray[0]] = currentParamsTempArray.length > 1 ? currentParamsTempArray[1] : "";
    });

    itemTitle = replaceUrlCharacters(itemTitle);

    let existingValue = currentParams[key];
    let updatedURL = "";

    let index = 0;
    for (const paramKey of Object.keys(currentParams)) {

      if (index != 0) {
        updatedURL += `&`;
      }

      let value = currentParams[paramKey];
      updatedURL += `${paramKey}=${value}`;
      index++;

    };

    if (!existingValue) {
      updatedURL += `&${key}=${itemTitle}`;
    } else {
      if (existingValue.split(",").includes(itemTitle)) {
        let existingValueArray = existingValue.split(",");
        existingValueArray.splice(existingValueArray.indexOf(itemTitle), 1);
        let newValue = existingValueArray.join(",");
        updatedURL = updatedURL.replace(key + "=" + existingValue, key + "=" + newValue);
      } else {
        let newValue = existingValue + "," + itemTitle;
        updatedURL = updatedURL.replace(key + "=" + existingValue, key + "=" + newValue);
      }
    }

    let newURL = "";
    let creatorValue = "";
    let amcValue = "";
    let dayValue = "";
    let fromValue = "";
    let toValue = "";
    let targetedContentValue = "";
    updatedURL.split("&").forEach((element, index) => {

      let key1 = element.split("=")[0];
      let value1 = element.split("=")[1];
      if (index != 0) {
        newURL += `&`;
      }

      if (value1 != "") {
        newURL += element;
      }

      if (key1 === "creator") {
        creatorValue = value1;
      } else if (key1 === "amc") {
        amcValue = value1;
      } else if (key1 === "day") {
        dayValue = value1;
      } else if (key1 === "to") {
        toValue = value1;
      } else if (key1 === "from") {
        fromValue = value1;
      } else if (key1 === "targetedContent") {
        targetedContentValue = value1;
      }

    });
    updatedURL = newURL;

    // exclude some keys
    let excludeKeys = ['premium'];

    // amc
    if (creatorValue === "" || !creatorValue.includes("Mutual Fund Company")) {
      updatedURL = updatedURL.replace("amc=" + amcValue, "");
    }

    // event day
    if (dayValue === "" || !dayValue.includes("Date Range")) {
      updatedURL = updatedURL.replace("from=" + fromValue, "");
      updatedURL = updatedURL.replace("to=" + toValue, "");
    }

    // targeted content
    if (!targetedContentValue.includes(itemTitle)) {
      let targetedContentKey = itemTitle.replace(" ", "");
      targetedContentKey = targetedContentKey.charAt(0).toLowerCase() + targetedContentKey.slice(1);
      // remove value with targetedContentKey
      if (targetedContentKey !== "") {
        let newURL = "";
        updatedURL.split("&").forEach((element, index) => {
          let key = element.split("=")[0];
          let value = element.split("=")[1];
          if (index != 0) {
            newURL += `&`;
          }
          if (excludeKeys.includes(key) || key !== targetedContentKey) {
            if (value != "") {
              newURL += element;
            }
          }
        });
        updatedURL = newURL;
      }
    }

    if (filterSingleSelectKeys.includes(key)) {

      updatedURL.split("&").forEach((element, index) => {
        let key1 = element.split("=")[0];
        let value1 = element.split("=")[1];
        if (key == key1) {
          updatedURL = updatedURL.replace(`${key}=${value1}`, "");
        }
      });

      updatedURL += `${key}=${itemTitle}`;
      // console.log(updatedURL);

    }


    newURL = "";
    updatedURL.split("&").forEach((element, index) => {
      if (element != "") {
        if (index != 0) {
          newURL += `&`;
        }
        newURL += element;
      }
    });

    updatedURL = newURL;
    setNavUrl(new URLSearchParams(updatedURL));
    setUpdatedFilterURL(updatedURL);
    if (isPostPlanner) {
      updateFilter(updatedURL, contentType, searchValue)
    } else {
      history.push({
        pathname: location.pathname.includes("/events") ? `/events` : `/content-type/${param.type ? param.type : "All"}`,
        search: updatedURL
      });
    }

  }

  const handleSearch = (contentType, searchDetails) => {
    setSearchValue(searchDetails)
    setContentType(contentType)
    setNavUrl(new URLSearchParams(getParam));
    updateFilter(`&${getParam}`, contentType, searchDetails)
  }

  const isMedia = location.pathname.includes(SOCIALMEDIA);



  function showFilterCount(obj) {
    const subCount = ((activeFilterTab[isMultiColor] && getParam.has(CUSTOMIZATION?.toLowerCase())) && (activeFilterTab[isMultiLanguage] && getParam.has(LANGUAGE?.toLowerCase()))) ? 2 : (((activeFilterTab[isMultiColor] && getParam.has(CUSTOMIZATION?.toLowerCase())) || (activeFilterTab[isMultiLanguage] && getParam.has(LANGUAGE?.toLowerCase()))) ? 1 : 0)
    const subtract = Object.keys(obj)?.includes('sortBy') ? 1 : 0;
    // const subtract2= obj[CREATOR]?.includes(MUTUAL_FUND_COMPANY) ? 1 : 0;
    const lengthWithSubtraction = Object.keys(obj)?.length - (subtract + subCount);
    return lengthWithSubtraction;
  }

  const sortHandler = (data) => {

    // console.log(data)
    const sorData = data.sort((a, b) => a?.[collectionType.replace(/s\b/g, '') + 'Name'].localeCompare(b?.[collectionType.replace(/s\b/g, '') + 'Name']))
    // console.log(sorData)
    return sorData
  }

  function clearFilter(keyword) {

    let updatedURL = "";

    const params = new URLSearchParams(location.search);
    params.forEach((key, element) => {
      if (element != "searchValue") {
        if (updatedURL != '') {
          updatedURL += `&`
        }
        updatedURL += `${element}=${key}`
      }
    });

    if (updatedURL != '') {
      updatedURL += `&`
    }
    updatedURL += `searchValue=${keyword}`

    setNavUrl(new URLSearchParams(updatedURL));
    setUpdatedFilterURL(updatedURL);

  }

  function getDefaultFilterParams() {

    let updatedURL = "";

    const params = new URLSearchParams(location.search);
    params.forEach((key, element) => {
      if (updatedURL != '') {
        updatedURL += `&`
      }
      updatedURL += `${element}=${key}`
    });
    return updatedURL;
  }

  return (
    <div className={`${isContainer ? "container" : ""} overflow-hidden`}>
      {isBreadCrumb &&
        <BreadCrumb
          path={
            (location.pathname === '/events')
              ? ['Events']
              : (collectionType === null)
                ? [param.type?.replace('_', "/")]
                : (collectionType.toLowerCase() === AMC.toLowerCase())
                  ? [CREATOR]
                  : (collectionType.toLowerCase() === 'targetedcontents')
                    ? ['Targeted Playlists', keyName]
                    : [collectionType.replace(/([a-z])([A-Z])|s$/g, '$1 $2'), keyName]
          }
          clasese="mb-3"
        />
      }
      <div className="user-search-wrapper">
        <SearchDropDown
          dropdown={isMedia || param.type === AMC || param.type === TARGETEDCONTENT ? false : dropdown}
          placeholder={placeholder}
          classes="search-border"
          clearFilter={(keyword) => { setActiveFilterTab([]); setAmcName1(defaultAmcState); clearFilter(keyword) }}
          isEvent={(location.pathname.includes(EVENTS))}
          isFilterYes={(Object.keys(activeFilterTab).includes('searchValue'))}
          isPostPlanner={isPostPlanner}
          handleSearch={(contentType, searchValue) => handleSearch(contentType, searchValue)}
        />
      </div>
      <div className="sort-targeted-wrapper">
        {
          //* ================ TARGETED CONTENT TOGGLE BTN START =================
          getParam.get('key') === TARGETEDCONTENTS && getParam.has(TARGETEDCONTENT) &&
          <div className={`targatedConten-toggal-btn mt-2 ${!showFilter ? 'on-top-1' : ""}`}>
            <p className="lato-semibold fx-14 black-242">Targeted </p>
            <input type="checkbox" className=" d-none" onChange={(e) => {
              const isTargeted = e.target.checked;
              setTargetedChecked(isTargeted);
              if (isTargeted) {
                getParam.delete('targeted');
              } else {
                getParam.set('targeted', 1);
              }
              history.push({
                search: getParam.toString(),
              });
            }} checked={targetedChecked} name="targateChecked" id="targateChecked" />
            <div className="targate-toggal-btn-frame">
              <label htmlFor="targateChecked" className="disk-toggal-btn"></label>
            </div>
            <p className="lato-semibold fx-14 black-242"> All</p>
          </div>
          //* ================ TARGETED CONTENT TOGGLE BTN END =================
        }
        {
          // *======================== SORT BY DROPDOWN START ===================
          dropdown && param.type !== "social-media" && (
            <div className={`right-0 sort-in-filter-dropdown ${!showFilter ? 'on-top-1' : ""}`}>
              <DropdownCommon title={"Sort by"} />
            </div>
            // *======================== SORT BY DROPDOWN END ===================
          )}
      </div>
      {isFilter &&
        <div className="filter-sort-frame social-media-filter  position-relative">


          <div className={`filter-btn-main-wrapper ${!showFilter ? 'w-37' : "w-auto"}`}>
            <div
              className="filter-button"
              onClick={() => {
                if (!filterData.length > 0) fetchData();
                setShowFilter(!showFilter)
              }}
            >{showFilterCount(activeFilterTab) > 0 &&
              <span className="filter-count">{showFilterCount(activeFilterTab)}</span>
              }
              <Image src={Filter} alt="icon" />
              <p className="fx-14 lato-medium black-495 desk-show">Filters</p>

              <Image src={DownIcon} alt="icon" className="desk-show" />
            </div>
            <div className={`clear-filter-wrapper desk-show ${showFilter && 'd-none'}`}>
              <div onClick={() => {
                if (isPostPlanner) {
                  setNavUrl(new URLSearchParams())
                  updateFilter("");
                  setSearchValue("");
                } else {
                  const searchValue = getParam.get('searchValue');
                  let pathname = location?.pathname;
                  let value = getParam.get('value');
                  let pageSize = pathname === '/events' ? 10 : MAX_LIMIT;

                  if (value != "") {
                    value = replaceUrlCharacters(value);
                  }
                  let newUrl = {
                    pathname: pathname,
                    search: `${(value != "" && (validValues.includes(collectionType) || validKeys.includes(collectionType))) ? `key=${collectionType}&value=${value}&` : ""}${searchValue ? `searchValue=${searchValue}&` : ''}pageNumber=${START_PAGE}&pageSize=${pageSize}`
                  }
                  setUpdatedFilterURL(newUrl.search);
                  history.push(newUrl);
                  setNavUrl(new URLSearchParams(newUrl.search))
                  setActiveFilterTab([]); setAmcName1(defaultAmcState)
                }
              }} className={`clear-filter-btn lato-medium fs-16-14 ${(showFilterCount(activeFilterTab) < 1) ? 'd-none' : ""}`}>
                Clear All
              </div>
              <Image onClick={() => setShowFilter(!showFilter)} src={closeFilter} className="pointer " alt="cancle" />
            </div>
          </div>
          <div className={`d-flex gap-12 align-items-center flex-wrap main-filter-frame ${!showFilter ? "width-half" : "width-full"}`}>
            {activeFilterTab['day']?.includes('Date Range') &&
              <DateRangePicker
              // getDate={(date) => {
              // handleFilterClick(
              //   date,
              //   'rangeDate'
              // )
              // }}
              />
            }
            {((getParam.get(CREATOR) === isMutual) || getParam.get('key') === AMC) &&
              // *=========================== AMC DROPDOWN START (if mutual fund selected then show )=======================
              <Dropdown className="single-select-api">
                <Dropdown.Toggle className="dropdown-btn" id="dropdown-basic">
                  <h6 className="lato-semibold fx-14 black-242">{amcName1 || 'Na'}</h6>
                  {/* <h6 className="lato-semibold fx-14 black-242">{toCamelCase(amcName1) || 'Na'}</h6> */}
                  <Image src={DownIcon} alt="image" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="">
                  {amcList?.map((item, i) => {

                    if (getParam.get('key') === AMC) {

                      return (
                        <Dropdown.Item key={i} onClick={() => {
                          setKeyNameHandler(item?.amcName);
                        }} className="lato-regular fx-14 black-242 " href=''>{item?.amcName}</Dropdown.Item>
                      )

                    } else {

                      return (
                        <Dropdown.Item key={i} onClick={() => {
                          handleFilterClick(item?.amcName, "amc");
                        }} className="lato-regular fx-14 black-242 " href=''>{item?.amcName}</Dropdown.Item>
                      )

                    }

                  })}
                  {/* <Dropdown.Item className="lato-regular black-242 " href=''>Most Viewed</Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
              // *=========================== AMC DROPDOWN END (if mutual fund selected then show )=======================

            }
            {/* {!(collectionType ?? false) && !((getParam.get('creator') === 'Mutual Fund Company')) && !(activeFilterTab['day']?.includes('Date Range')) &&
              <div className=""></div>
            } */}
            {(collectionType ?? false) && (getParam.get('key') !== CREATOR && getParam.get('key') !== AMC) &&
              <Dropdown className="single-select-api">
                <Dropdown.Toggle className="dropdown-btn" id="dropdown-basic">
                  <h6 className="lato-semibold fx-14 black-242">{keyName || 'Na'}</h6>
                  <Image src={DownIcon} alt="image" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="">
                  {((collectionType === 'socialMedias' ? amcData : amcData?.list)?.length > 0) ? (
                    (collectionType === 'socialMedias' ? amcData : sortHandler(amcData?.list)).map((item, i) => {
                      return (
                        <Dropdown.Item
                          key={i}
                          onClick={() => setKeyNameHandler(item?.[collectionType.replace(/s\b/g, '') + 'Name'])}
                          className="lato-regular fx-14 black-242 "
                          href=""
                        >
                          {item?.[collectionType.replace(/s\b/g, '') + 'Name']}
                        </Dropdown.Item>
                      );
                    })
                  ) : (
                    <Dropdown.Item className="lato-regular fx-14 black-242 ">No Data Found!</Dropdown.Item>

                  )}

                  {/* <Dropdown.Item className="lato-regular black-242 " href=''>Most Viewed</Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
            }
            {getParam.get('targetedContent') &&
              <div className="targeted-filter-wrapper mt-2 mt-md-0 ">
                {
                  activeFilterTab?.['targetedContent']?.map((ele, i) => {
                    const fineTarget = targetedContentList?.['items']?.find((item) => item.title === ele) || [];
                    //  console.log("This is find tha conten",fineTarget.types);
                    return (
                      <CustomDropDown data={fineTarget} targetfilterChangeHandler={(e, item) =>
                        handleFilterClick(
                          item,
                          (ele.replace(/\s/g, '')).replace(/\w/, (a) => a.toLowerCase())
                        )} filterObj={activeFilterTab} />
                    )
                  }
                  )

                }
              </div>
            }


          </div>

        </div>
      }
      <div className="filter-open-warpper">
        <div className={`filter-side-bar ${showFilter && "show-filter"}`}>
          <div className="filter-title">
            <h6 className="lato-semibold f-s-18 black-242">Filter</h6>

            <div className={`clear-filter-wrapper pe-0`}>
              {/* <div onClick={() => setActiveFilterTab([])} className={`clear-filter-btn lato-medium fs-16-14 ${(Object.keys(activeFilterTab).length < 1) ? 'd-none' : ""}`}>
                Clear
              </div> */}
              <Image onClick={() => setShowFilter(!showFilter)} src={closeFilter} className="pointer " alt="cancle" />
            </div>
          </div>
          {filterData?.map((tab, i) => (
            <Accordion key={i} defaultActiveKey={PREMIUM}>
              <Card className={`${tab.title || "filter-premium-card"}`}>
                {tab.code !== PREMIUM && (
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={
                      ((isPostPlanner && tab.title.toLowerCase() === "section"))
                        ? ''
                        : tab.title || PREMIUM
                    }
                    className={

                      ((isPostPlanner && tab.title.toLowerCase() === "section"))
                        ?
                        "pointer-none"
                        :
                        ""
                    }
                  >
                    <>
                      <h6 className="lato-semibold fx-14 black-242 ">
                        {tab.title}
                        {((activeFilterTab[tab.key] ?? false) || (activeFilterTab[isMultiColor] && tab.code === CUSTOMIZATION) || (activeFilterTab[isMultiLanguage] && tab.code === LANGUAGE_Code)) &&
                          <span className="filter-indicator"></span>
                        }
                      </h6>
                      <Image src={DownIcon} alt="image" />
                    </>
                  </Accordion.Toggle>
                )}
                <Accordion.Collapse eventKey={tab.title || PREMIUM}>
                  <Card.Body className={tab.code === "AMC" ? "overflow-auto" : ""}>
                    {tab.code === CUSTOMIZATION ? (

                      <div className="customization-color-box">
                        {/* <div
                          className={`clear-filter-btn w-50 mb-3 text-center py-2 ellipsis pointer lato-medium black-242 fx-12 ${(activeFilterTab["is Multi Color".replace(/\s/g, "")] || []).includes("true") || (getParam.get("is Multi Color".replace(/\s/g, "")) === "true") ? 'active-bg' : ''}`}
                          onClick={() => handleFilterClick("true", "is Multi Color".replace(/\s/g, ""))}
                        > <Image src={colorIcon}    onClick={() => handleFilterClick("true", "is Multi Color".replace(/\s/g, ""))} alt="Image" className="multi-icon" />&nbsp;&nbsp;
                          is Multi Color
                        </div> */}
                        <div className="filter-color-frame flex-column  align-items-start gap-6">
                          <p className="lato-medium text-start f-s-12 grey-919">
                            Select Color
                          </p>
                          <div className="filter-multi-colors flex-wrap  align-items-center">
                            {tab.items?.map((color, i) => {
                              let isActive = false;
                              const activeFiltersForCategory = activeFilterTab[tab.key] || [];
                              isActive = (activeFiltersForCategory.includes(color.title));
                              return (
                                <>
                                  <span
                                    key={color.uuid}
                                    title={color.title}
                                    onClick={() =>
                                      handleFilterClick(
                                        color.title.replace("/", "_"),
                                        tab.key
                                      )
                                    }
                                    className={`lato-semibold d-flex align-items-center gap-6 fx-12  ${((isActive && getParam.get(tab.key)?.includes(color.title))) ? "active-bg" : ""
                                      }`}
                                  >
                                    <div
                                      key={i}
                                      // className={`filter-colors ${isActive?'color-outline':''}`}
                                      className={`filter-colors border-0`}
                                      // onClick={() =>
                                      //   handleFilterClick(
                                      //     color.title.replace("/", "_"),
                                      //     tab.key
                                      //   )
                                      // }
                                      style={{ background: color.colorCode }}

                                      title={color.title}
                                    ></div>

                                    {color.title}
                                  </span>

                                </>
                              );
                            })}
                            <span
                              title={"Multi Color"}
                              onClick={() => handleFilterClick("true", isMultiColor)}
                              className={`lato-semibold d-flex align-items-center gap-6 fx-12 ${(getParam.get(isMultiColor) ?? false) ? "active-bg" : ""} `}
                            >
                              <Image src={colorIcon} alt="Image" className={`multi-icon pointer`} /> Multi Color
                            </span>
                          </div>
                        </div>
                      </div>

                    ) : (
                      <>
                        {
                          tab.items.map((item) => {
                            let isActive = false;
                            // if (tab.key === "contentType") {
                            //   isActive = item.title.replace("/", "_") === contentType;
                            // } else {
                            const activeFiltersForCategory = activeFilterTab[tab.key] || [];
                            // console.log('Check type:=',param.type,'===',item.title,(param?.type?.toLowerCase()===item?.title?.toLowerCase()))
                            isActive = activeFiltersForCategory.includes(item.title);
                            const isDisabled = (param?.type?.toLowerCase() === item?.title?.toLowerCase());
                            // }
                            // console.log(getParam.get(tab.key),'itemTitle',item.title.replace("/", "_"),getParam.get(tab.key)?.includes(item.title))
                            // todo:-add in active condtion :|| (getParam.get('key').includes(socialMedias) &&  (item.title==SOCIAL_MEDIA))
                            return (
                              <span
                                key={item.uuid}
                                title={item.title}
                                onClick={() => { handleFilterClick(item.title, tab.key) }}
                                className={`lato-semibold fx-12 ${isDisabled ? "pointer-none" : ''}   ${((isActive && getParam.get(tab.key)?.includes(item.title))) ? "active-bg" : ""
                                  }`}
                              >
                                {tab.code === PREMIUM && item.title === PREMIUM_TITLE && <Image className="premium-image" src={Premium} alt="icon" />}
                                {(item.title)}
                              </span>
                            );
                          })
                        }
                        {tab.code === LANGUAGE_Code &&
                          <div
                            className={`clear-filter-btn w-50 mb-3 text-center py-2 ellipsis pointer lato-medium black-242 fx-12 ${(activeFilterTab[isMultiLanguage] || []).includes("true") || (getParam.get(isMultiLanguage) === "true") ? 'active-bg' : ''}`}
                            onClick={() => handleFilterClick("true", isMultiLanguage)}
                          > <Image src={languageIcon} alt="Image" className="multi-icon" />&nbsp;&nbsp;
                            Multi Language
                          </div>

                        }

                      </>
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          ))}
          <div className={`clear-filter-wrapper sticky-bottom justify-content-between  pe-0 mb-flex`}>
            <div onClick={() => {
              if (isPostPlanner) {
                setNavUrl(new URLSearchParams())
                updateFilter("");
                setSearchValue("");
              } else {
                const searchValue = getParam.get('searchValue');
                let pathname = location?.pathname;
                let pageSize = pathname === '/events' ? 10 : MAX_LIMIT;
                let newUrl = {
                  pathname: pathname,
                  search: `${(getParam.get('value') && validValues.includes(collectionType)) ? `key=${collectionType}&value=${getParam.get('value')}&` : ""}${searchValue ? `searchValue=${searchValue}&` : ''}pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}`
                }
                history.push(newUrl);
                setNavUrl(new URLSearchParams(newUrl.search))
                setActiveFilterTab([]); setAmcName1(defaultAmcState)
              }
            }} className={`clear-filter-btn w-100 text-center py-2 pointer lato-medium black-242 fs-16-14 ${(Object.keys(activeFilterTab).length < 1) ? 'd-none' : ""}`}>
              Clear All
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            {/* <div onClick={() => setShowFilter(true)} className={`clear-filter-btn w-100 text-center py-2 pointer lato-medium black-242 bg-primary text-white fs-16-14 ${(Object.keys(activeFilterTab).length < 1) ? 'd-none' : ""}`}>
              Apply
            </div> */}
          </div>
        </div>

        <div className={`right-data-frame fs-16-14 ${!showFilter ? "width-half" : "width-full"}`} >
          {component}
        </div>
      </div>
      {/* {param.type === "All" && JSON.parse(sessionStorage.getItem("fromLogin")) &&
        <WelcomeFinModal show={showWelcom} data={welcomeModalData} handleClose={() => { setShowWelcom(false); sessionStorage.setItem('fromLogin', false); }} />
      } */}
    </div>
  );
}






function CustomDropDown({ data, targetfilterChangeHandler, filterObj }) {
  const [show, setShow] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);


  const isChecked = (item, title) => {
    const formattedTitle = (title.replace(/\s/g, '')).replace(/\w/, (a) => a.toLowerCase());
    // console.log(filterObj[formattedTitle] ,"second=>", filterObj[formattedTitle]?.includes(item))
    return (filterObj[formattedTitle] && filterObj[formattedTitle].includes(item)) ?? false;
  };

  return (
    <>
      <div className={`target-item-dropdown  ${false ? 'zindex-6' : ''}`}>
        <Button onClick={() => setShow(!show)} className={`dropdown-toggle lato-medium f-s-14 lh-20 ${(params.get((data.title?.replace(/\s/g, ''))?.replace(/\w/, (a) => a.toLowerCase())) ?? false) ? 'active-bg' : ''}`} id={data.title}>
          {data.title}
        </Button>
        <ul className={`dropdown-menu ${show ? 'show-menu' : ''} `} >
          {
            data.types?.map((item, i) => {
              return (
                <li key={i} className='lato-regular'>
                  <input
                    type="checkbox"
                    onChange={(e) => targetfilterChangeHandler(e, item, (data.title.replace(/\s/g, '')).replace(/\w/, (a) => a.toLowerCase()))}
                    checked={isChecked(item, data.title)}
                    className='targeted-chekbox'
                    id={item + i}
                  />
                  <label className='w-100 pointer m-0' htmlFor={item + i}>{item}</label>
                </li>
              )
            })
          }
        </ul>
      </div>
      <div onClick={() => setShow(false)} className={show ? "dropdown-baground-wrapper" : "d-none"}> </div>
    </>
  )
}