import React, { useEffect, useState } from 'react'
import RangeFields from './components/RangeFields'
import BarChart from './components/BarChart'
import { CheckToolsSubscription, ShowShare, ToolsScreenshotHeader, captureImageHandler, debounce, formatCurrency, toolsDisclaimer, toolsInvestorDetails } from '../../utils/ReusableFunctions';
import { useMemo } from 'react';
import { useRef } from 'react';
export default function FixedMonthlyWithdrawal({ calculator, textLogo = '', imageData, setImageData, isDisable, setIsDisable, setAlertData, setIsAlert, setModalData }) {
  let [corpusAmount, setCorpusAmount] = useState(10000000);
  let [swpAmount, setSWPAmount] = useState(75000);
  let [rateOfInterest, setRateOfInterest] = useState(12);
  let [tenure, setTenure] = useState(25);
  let [totalWithdrawal, setTotalWithdrawal] = useState(22500000);
  let [totalAccCorpus, setTotalAccCorpus] = useState(56971166);




  useEffect(() => {
    onSubmitAction(corpusAmount, swpAmount, rateOfInterest, tenure);
  }, [corpusAmount, swpAmount, rateOfInterest, tenure])

  const handleChange = (value, name) => {

    if (name === "corpusAmount") {
      corpusAmount = value
    }
    if (name === "swpAmount") {
      swpAmount = value
    }
    if (name === "rateOfInterest") {
      rateOfInterest = value
    }
    if (name === "tenure") {
      tenure = value
    }

    onSubmitAction(corpusAmount, swpAmount, rateOfInterest, tenure);
  }
  const onSubmitAction = (corpusAmount, swpAmount, roi, tenure) => {
    var toDivideROI = (roi / 100) / 12 || 0;
    let partA = ((((((roi / 100) / 12) + 1) ** (tenure * 12)) - 1) / toDivideROI) * (-swpAmount);
    let partB = ((1 + toDivideROI) ** (tenure * 12)) * corpusAmount;
    let calculatedTotalAccCorpus = Math.round(partA + partB);
    totalAccCorpus = calculatedTotalAccCorpus >= 0 ? calculatedTotalAccCorpus : 0;
    setTotalAccCorpus(totalAccCorpus);
    totalWithdrawal = swpAmount * tenure * 12;
    setTotalWithdrawal(totalWithdrawal);


    setTotalWithdrawal(totalWithdrawal);
    setTotalAccCorpus(totalAccCorpus);

    setCorpusAmount(corpusAmount);
    setSWPAmount(swpAmount);
    setRateOfInterest(rateOfInterest);
    setTenure(tenure);

  };
  let downloadScreenshotFunc = null;
  const setDownloadScreenshotFunc = (func) => {
    downloadScreenshotFunc = func;
  };
  const barChart = useMemo(() => {
    return (
      <BarChart
        info={{
          labels: ["Total Withdrawal", "Total Invested Corpus", "End Corpus"],
          data: [totalWithdrawal, corpusAmount, totalAccCorpus],
        }}
      />
    );
  }, [totalWithdrawal, corpusAmount, totalAccCorpus]);
  return (
    <div>
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART START ---------------------------------------------- */}
      <div className="calculator-result-main-wrapper">
        <div className="calculator-component-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Calculator
          </h4>


          <RangeFields
            label="Corpus or Investment Amount"
            name={"corpusAmount"}
            value={corpusAmount}
            minVal={100000}
            maxVal={1000000000}
            step={100000}
            getValue={debounce(handleChange)}
            showComma />
          <RangeFields
            label="Monthly SWP Amount"
            name={'swpAmount'}
            value={swpAmount}
            minVal={5000}
            maxVal={1000000}
            step={5000}
            getValue={debounce(handleChange)}
            showComma
          />
          <RangeFields
            label="Expected Rate of Return"
            name={"rateOfInterest"}
            value={rateOfInterest}
            minVal={1}
            maxVal={50}
            unit="%"
            step={0.5}
            getValue={debounce(handleChange)}
          />
          <RangeFields
            label="Tenure enrolled for SWP"
            name={"tenure"}
            value={tenure}
            minVal={1}
            maxVal={50}
            unit="Year"
            step={1}
            getValue={debounce(handleChange)}
          />

        </div>
        <div className="result-graph-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Results{" "}

            {<ShowShare calculator isDisable={isDisable} setIsDisable={setIsDisable} clickHandler={() => { CheckToolsSubscription(textLogo, downloadScreenshotFunc, calculator?.uuid, imageData, setIsDisable, setAlertData, setIsAlert, setModalData) }} />}

          </h4>
          <div className="result-graph-wrapper">
            <div className={`graph-1-frame ${false ? "w-auto" : "w-100"}`}>

              {barChart}
            </div>
          </div>
          {fixedMonthlyWithdrawalText(corpusAmount, swpAmount, tenure, totalWithdrawal, totalAccCorpus, rateOfInterest)}

        </div>
      </div>
      <div className="make-none">
        <CalculatorOutputScreenShot
          textLogo={textLogo}
          totalWithdrawal={totalWithdrawal}
          corpusAmount={corpusAmount}
          totalAccCorpus={totalAccCorpus}
          swpAmount={swpAmount}
          tenure={tenure}
          rateOfInterest={rateOfInterest}
          onDownloadScreenshot={setDownloadScreenshotFunc}
          setImageData={setImageData} />
      </div>
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART END ---------------------------------------------- */}
    </div>
  )
}


function CalculatorOutputScreenShot({ textLogo, totalWithdrawal, corpusAmount, rateOfInterest, totalAccCorpus, swpAmount, tenure, onDownloadScreenshot, setImageData }) {
  const { cobrandingLabelling, investor } = textLogo;
  const [signup, setSignup] = useState(false);
  const screenshotRef = useRef(null);

  useEffect(() => {
    if (onDownloadScreenshot) {

      captureImageHandler(onDownloadScreenshot, screenshotRef, setSignup, setImageData);
    }
  }, [textLogo, screenshotRef, corpusAmount, swpAmount, rateOfInterest, tenure]);
  return (
    <>

      {/* {signup && <SignUp show={signup} handleClose={() => setSignup(!signup)} />} */}

      <div ref={screenshotRef} className="tools-calulator-output-screenshot-frame ">
        {ToolsScreenshotHeader(cobrandingLabelling)}

        <div className={`graph-1-frame bg-gray ${false ? "w-auto" : "w-100"}`}>
          <BarChart
            info={{
              labels: ["Total Withdrawal", "Total Invested Corpus", "End Corpus"],
              data: [totalWithdrawal, corpusAmount, totalAccCorpus],
            }}
          />
        </div>
        {toolsInvestorDetails(investor)}
        <br /><br />

        {fixedMonthlyWithdrawalText(corpusAmount, swpAmount, tenure, totalWithdrawal, totalAccCorpus, rateOfInterest)}

        {toolsDisclaimer()}
      </div>

    </>
  )
};

function fixedMonthlyWithdrawalText(corpusAmount, swpAmount, tenure, totalWithdrawal, totalAccCorpus, rateOfInterest) {
  return (
    <p className=" f-s-16 lh-24 black-242 op-7 pt-2 text-center">
      With an initial investment of <span className=" lato-semibold primary">₹{formatCurrency(corpusAmount)}</span> in an SWP plan at a monthly Withdrawal amount of <span className=" lato-semibold primary">₹{formatCurrency(swpAmount)}</span> for a period of <span className=" lato-semibold primary">{tenure}</span> years, your total withdrawals would amount to <span className=" lato-semibold primary">₹{formatCurrency(totalWithdrawal)}</span> leaving an end corpus of <span className=" lato-semibold primary">₹{formatCurrency(totalAccCorpus)}</span>, if the invested corpus keeps growing at <span className=" lato-semibold primary">{rateOfInterest}%</span> for the investment tenure.
    </p>

  )
}