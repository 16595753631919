import React from 'react';
import { Image, Modal } from "react-bootstrap";
import CloseIcon from '../../../assets/images/icon/cross-modal-icon.svg';
/* eslint-disable eqeqeq */
const CustomModal = ({ show, onHide, title, children, modalClass = null, isCross, type }) => {
  return (

    <Modal show={show} onHide={onHide} dialogClassName={`custom-modal ${modalClass}`}>
      {
        (title || isCross) &&
        <Modal.Header>
          <Modal.Title style={{ color: type == "alert" ? 'red' : "" }} className='red-hat-display-bold f-s-20  py-1'>{title}</Modal.Title>
          <Image src={CloseIcon} className='close-icon model-icon-close' alt='icon' onClick={onHide} />
        </Modal.Header>
      }
      <Modal.Body>
        {children}
      </Modal.Body>
    </Modal>

  );
};
export default CustomModal;