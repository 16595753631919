import axios from 'axios';
import { getToken, deleteToken } from "./ManageToken";

const postLoginInstance = axios.create(
  {
    baseURL: process.env.REACT_APP_API_BASE_URL,  // Url for Dev
    // baseURL: process.env.REACT_APP_API_BASE_URL, // Url for UAT
    // baseURL: 'http://13.126.239.61:3001/finpedia/v1/', // Url for Local
    // baseURL: 'https://api.hbits.co/api/',  // Url for Production
    headers: {
      // 'Content-Type': 'application/x-www-form-urlencoded',
      'Content-Type': 'application/json',
    }
  }
);

// SET THE AUTH TOKEN FOR ANY REQUEST
postLoginInstance.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${getToken()}`;
  return config;
});
// INTERCEPT RESPONSE TO CHECK IF TOKEN HAS EXPIRED AND IF YES THEN REDIRECT TO LOGIN OR HOME
postLoginInstance.interceptors.response.use(undefined, (error) => {
  if (error?.response?.status === 401) {

    deleteToken();
    window.location = "/";
  }
  return Promise.reject(error);
});

export default postLoginInstance;