import React from 'react';
import { AutoSizer, Table } from "react-virtualized";
import { Column } from "react-virtualized";
import { Link } from "react-router-dom";
import { Button, Image } from "react-bootstrap";
import notFoundDefault from '../../assets/images/not-found.png';
import Pagination from './Pagination';
import FormElement from '../form/FormElement';
import BaseReactComponent from '../form/BaseReactComponent';
import FormValidator from '../form/FormValidator';
import SelectControl from "../form/SelectControl";
import Form from '../form/Form';


class CustomTable extends BaseReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10,
    }
  }
  onValidSubmit = () => {

  }
  render() {
    const { tableData, className = "", columnList = [], notFoundImage = notFoundDefault, moduleName, message = "", isButton, buttonText, linkUrl, linkText, currentPage = 1, totalPages, history, location, pageSize, pageSizeOptions, handlePageSize, topPagination = false } = this.props;
    return (
      <div className="table-wrapper">
        {
          topPagination &&
          <div className="header-navigation">
            {
              tableData && tableData.length >= 1 && totalPages > 1 &&
              <Pagination
                history={history}
                location={location}
                page={currentPage}
                pageCount={totalPages}
              />
            }
            {
              pageSize &&
              <Form onValidSubmit={this.onValidSubmit} ref={el => this.form = el}>
                <FormElement
                  valueLink={this.linkState(this, "pageSize")}
                  label="Page Size"
                  hint={{
                    title: "Title Custom",
                    description: <span> Custom Hint Bro! </span>
                  }}
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "Field cannot be empty"
                    }
                  ]}
                  control={{
                    type: SelectControl,
                    settings: {
                      options: pageSizeOptions,
                      multiple: false,
                      searchable: true,
                      // menuIsOpen: true,
                      onChangeCallback: (onBlur) => {
                        onBlur(this.state.pageSize);
                        handlePageSize(this.state.pageSize);
                        // console.log('Hello world!');
                      }
                    }
                  }}
                />
              </Form>
            }
          </div>
        }
        {
          columnList && columnList.length > 0
            ?
            <AutoSizer disableHeight>
              {({ width }) => (
                <Table
                  width={width}
                  height={(60 * (tableData?.length + 1)) - 10}
                  headerHeight={50}
                  // headerClassName='table-header-v'
                  rowHeight={60}
                  rowCount={tableData.length}
                  rowGetter={({ index }) => tableData[index]}
                  className={`custom-table ${className}`}
                >
                  {
                    columnList && columnList.length > 0 &&
                    columnList.map((item, key) => {
                      return (
                        <Column
                          key={key}
                          width={item.coumnWidth}
                          className={item.className}
                          label={item.labelName}
                          dataKey={item.dataKey}
                          cellRenderer={({ rowData }) => { return item.cell(rowData, item.dataKey) }}
                        />
                      )
                    })
                  }
                </Table>
              )}
            </AutoSizer>
            :
            <div className="not-found-wrapper">
              <Image src={notFoundImage} />
              <p className="regular"> {moduleName ? "No " + moduleName + " Found" : message}</p>
              {
                isButton &&
                <Button
                  className="primary-btn"
                  onClick={isButton}
                >
                  {buttonText}
                </Button>
              }
              {
                linkUrl &&
                <Link className="primary-btn" to={linkUrl}>
                  {linkText}
                </Link>
              }
            </div>
        }
        {
          tableData && tableData.length >= 1 && totalPages > 1 &&
          <Pagination
            history={history}
            location={location}
            page={currentPage}
            pageCount={totalPages}
          />
        }
      </div>
    );
  };
}

export default CustomTable;