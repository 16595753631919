import React, { useEffect, useState } from "react";
import { Footer, Header, NotFound } from "../common/pages";
import { SearchFilter } from "../common";
import { AmcCard, BreadCrumb } from "./_utils";
import { AllShimmer, CardHeader } from "../common/components";
import { getAuthDataApi } from "./Api";
import { toast } from "react-toastify";
import { AMC_CONTENT_CREATOR, CAMPAIGN, START_PAGE } from "../../utils/Constant";
import { Dropdown, Image } from "react-bootstrap";
import DownIcon from '../../assets/images/dropdown-icon.svg';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { isResOk } from "../../utils/ReusableFunctions";
import { commonAuthApis } from "../profile/Api";
/* eslint-disable react-hooks/exhaustive-deps */

export default function AmcListeningPage() {
  const param = useParams();
  const [info, setInfo] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [creatorFilterData, setCreatorFilterData] = useState([]);
  const [filterMaxData, setFilterMaxData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortBy, setSortBy] = useState("Max Content");

  const [contentCreator, setContentCreator] = useState({ creatorName: 'Select Creator', contentAmcName: 'Select Amc' });
  const { creatorName, contentAmcName } = contentCreator;
  const [amcList, setAmcList] = useState([]);
  const [contentCreatorList, setContentCreatorList] = useState([]);
  const [isMutualFundCompany, setIsMutualFundCompany] = useState(false);

  async function getData() {

    let url = param.type === 'language' ? 'website/languages' : "website/" + param.type;
    let data = await getAuthDataApi(`/${url}?pageSize=10000&pageNumber=${START_PAGE}`);

    if (data) {
      const maxContentData = [...data?.list].sort((a, b) => {
        const sumA = (a?.contentTypeStats ? Object.values(a.contentTypeStats).reduce((acc, val) => acc + val, 0) : 0);
        const sumB = (b?.contentTypeStats ? Object.values(b.contentTypeStats).reduce((acc, val) => acc + val, 0) : 0);

        return sumB - sumA;
      });

      setInfo(data);
      setFilterData(maxContentData);
      setFilterMaxData(maxContentData);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      toast.error(data?.response.data.message || "Something went wrong");

    }
  }


  async function getAllContetncreator() {
    const res = await commonAuthApis('website/contentCreator?type=content', '', 'get');
    if (isResOk(res.status)) {

      setContentCreatorList(res.data?.data)
    }

  }
  useEffect(() => {

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    getAllContetncreator()
    getData();


  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10); // 3 seconds delay

    // Cleanup the timeout if the component is unmounted
    return () => clearTimeout(timer);
  }, []);


  const getName = (item) => {
    return item.amcName?.toLowerCase() || item.campaignName?.toLowerCase() || item.categoryName?.toLowerCase() || item.languageName?.toLowerCase() || item.contentCreatorName?.toLowerCase() || '';
  };


  function sortHandler(data) {
    setSortBy(data);


    let sortedData;
    switch (data) {
      case 'Ascending (A-Z)':
        sortedData = [...filterData].sort((a, b) => {
          const nameA = getName(a)
          const nameB = getName(b)
          return nameA.localeCompare(nameB);
        });
        break;
      case 'Descending (Z-A)':
        sortedData = [...filterData].sort((a, b) => {
          const nameA = getName(a)
          const nameB = getName(b)
          return nameB.localeCompare(nameA);
        });
        break;
      case "Min Content":
        sortedData = [...filterData].sort((a, b) => {
          const sumA = Object.values(a.contentTypeStats || {}).reduce((acc, val) => acc + val, 0);
          const sumB = Object.values(b.contentTypeStats || {}).reduce((acc, val) => acc + val, 0);
          return sumA - sumB;
        });
        break;
      case "Max Content":
        sortedData = [...filterData].sort((a, b) => {
          const sumA = Object.values(a.contentTypeStats || {}).reduce((acc, val) => acc + val, 0);
          const sumB = Object.values(b.contentTypeStats || {}).reduce((acc, val) => acc + val, 0);
          return sumB - sumA;
        });
        break;
      default:
        sortedData = [...filterMaxData];
    }

    setFilterData(sortedData);

  }


  const amcListHandler = async () => {
    const res = await getAuthDataApi('website/amc');
    const sortAmc = res.list.sort((a, b) => a.amcName.localeCompare(b.amcName))
    setAmcList(sortAmc);
  }

  const filterCreatorDataHandler = (creator = "") => {
    let sortedData;
    if (creator) {
      sortedData = [...filterMaxData].filter(item => (item?.contentCreator?.contentCreatorName === creator))
    } else {
      sortedData = [...filterMaxData];
    }

    setFilterData(sortedData);
    setCreatorFilterData(sortedData);
  }
  const filterAmcDataHandler = (creator = '', amc = "") => {


    let sortedData;
    if (amc) {
      sortedData = [...creatorFilterData].filter(item => (item?.amc?.amcName === amc))
    } else {
      sortedData = [...creatorFilterData];
    }

    setFilterData(sortedData);
  }
  return (
    <div className="page-padding-filter">
      <div className="container">
        <BreadCrumb path={[param.type === AMC_CONTENT_CREATOR ? 'Creator' : param.type.replace(/\w/, (a) => a.toUpperCase())]} />

      </div>
      <Header />
      {isLoading ? (
        <AllShimmer type="amc" />
      ) : (
        <div className="amc-card-wrapper">
          <div className="container">

            <SearchFilter setSortBy={setSortBy} data={filterMaxData} filterData={filterData} setValueHandler={(e) => { setFilterData(e) }} name={param.type.toLowerCase()} placeholder={param.type === AMC_CONTENT_CREATOR ? 'Creator' : (param.type.charAt(0).toUpperCase() + param.type.slice(1))} />
            <div className="d-flex align-items-center mb-wrap">
              <CardHeader
                title="Creator"
                count={filterData?.length}
                hide={false}
                countTitle={param.type === AMC_CONTENT_CREATOR ? 'Creator' : param.type.replace(/\w/, (a) => a.toUpperCase())}
              // viewAll={`/content-type/${countTitle?.replace('/', "_")}`}//change
              />
              {/*================================== IN CAMPING CREATOR AND AMC DROPDOWN SHOWING START =================== */}
              <div className="w-100 amc-listening-filter">
                {param.type === CAMPAIGN.toLowerCase() &&
                  <Dropdown className="single-select-api 777">
                    <Dropdown.Toggle className="dropdown-btn" id="dropdown-basic">
                      <h6 className="lato-semibold fx-14 black-242">{creatorName || 'Na'}</h6>
                      <Image src={DownIcon} alt="image" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="">
                      <Dropdown.Item onClick={() => {
                        setContentCreator({ ...contentCreator, creatorName: "All" });
                        getAllContetncreator();
                        getData();
                        setIsMutualFundCompany(false);


                      }} className="lato-regular fx-14 black-242 " href=''>All</Dropdown.Item>
                      {contentCreatorList?.list?.map((item, i) => {
                        return (
                          <Dropdown.Item key={i} onClick={() => {
                            setContentCreator({ ...contentCreator, creatorName: item?.contentCreatorName });
                            if (item?.isMutualFundCompany) {
                              amcListHandler(); setIsMutualFundCompany(true)
                            } else {
                              setIsMutualFundCompany(false)
                            }
                            filterCreatorDataHandler(item?.contentCreatorName)
                            setSortBy("Max Content")
                          }}
                            className="lato-regular fx-14 black-242 " href=''>{item?.contentCreatorName || "Na"}</Dropdown.Item>
                        )
                      })}
                      {/* <Dropdown.Item className="lato-regular black-242 " href=''>Most Viewed</Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                }
                {isMutualFundCompany && param.type === CAMPAIGN.toLowerCase() &&
                  <Dropdown className="single-select-api ">
                    <Dropdown.Toggle className="dropdown-btn" id="dropdown-basic">
                      <h6 className="lato-semibold fx-14 black-242">{contentAmcName || 'Na'}</h6>
                      <Image src={DownIcon} alt="image" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="">

                      <Dropdown.Item onClick={() => {
                        setContentCreator({ ...contentCreator, contentAmcName: 'All' })
                        filterAmcDataHandler(creatorName, '')
                      }} className="lato-regular fx-14 black-242 " href=''>All
                      </Dropdown.Item>
                      {amcList?.map((item, i) => {

                        return (
                          <Dropdown.Item key={i} onClick={() => {
                            setContentCreator({ ...contentCreator, contentAmcName: item.amcName })
                            filterAmcDataHandler(creatorName, item.amcName)
                          }} className="lato-regular fx-14 black-242 " href=''>{item?.amcName}</Dropdown.Item>
                        )
                      })}
                      {/* <Dropdown.Item className="lato-regular black-242 " href=''>Most Viewed</Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                }
              </div>
              {/*================================== IN CAMPING CREATOR AND AMC DROPDOWN SHOWING END =================== */}

              <div className={`dropdown-main-wrapper w-auto`}>
                <Dropdown>
                  <Dropdown.Toggle className="dropdown-btn" id="dropdown-basic">
                    <h6 className="lato-semibold fx-14 black-242">{sortBy || 'Sort By'}</h6>
                    <Image src={DownIcon} alt="image" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="show-filter-dropdown">
                    {['Ascending (A-Z)', 'Descending (Z-A)', "Max Content", "Min Content"]?.map((item, i) => {
                      return (
                        <Dropdown.Item key={i} onClick={() => sortHandler(item)} className="lato-regular black-242 fx-14" href=''>{item}</Dropdown.Item>
                      )
                    })}

                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="amc-all-card-frame">
              {filterData?.length > 0 ? (
                <div className={`common-all-card-wrapper w-100 result-wrap  ${filterData?.length >= 3 && ""}`}>
                  {filterData?.map((item, index) => {
                    return (
                      <AmcCard
                        key={index}
                        collectionType={param.type}
                        data={item}
                        classes={true ? `responsive-card-width ` : ""}
                      />
                    );
                  })}
                </div>
              ) : (
                <NotFound />
              )}
            </div>
          </div>
        </div>
      )}

      <Footer />
    </div>

  );
}


