
import { BreadCrumb } from "../home/_utils";
import SocialContent from "./SocialContent";


export default function MySocials(props) {


  return (
    <div className="my-social-wrapper">
      <BreadCrumb path={["My Socials"]} />
      <div className="my-social-heading">
        <h5 className="fs-32-28 lato-semibold black-242">My Socials</h5>
      </div>
      <SocialContent />


      <div className="my-social-links d-flex flex-row">
        <p onClick={() => props.history.push('/terms-conditions')} className="lato-medium fs-14-12 primary pointer">Terms and Condition </p>
        <p className="lato-medium fs-14-12 primary">|</p>
        <p onClick={() => props.history.push('/privacy-policy')} className="lato-medium fs-14-12 primary pointer">Privacy Policy</p>
      </div>
    </div>
  );
}
