import { Image, Button } from "react-bootstrap";
import CalenderIcon from "../../assets/images/icon/calender-icon.svg";
import ListIcon from "../../assets/images/icon/list-icon.svg";
import PublishedIcon from "../../assets/images/icon/published-icon.svg";
import UnpublishedIcon from "../../assets/images/icon/unpublished-icon.svg";
import { useEffect, useState } from "react";
import PerformanceModal from "./PerformanceModal";
import { groupDataByDate } from "../../utils/ReusableFunctions";
import { getAuthDataApi } from "../home/Api";
import { toast } from "react-toastify";
import { formatDate } from "./_utilis/platformsTime";
import { useLocation, useHistory } from "react-router-dom";
import { HistoryCalenderView, PreviewModal } from ".";
import PreviewIcon from "../../assets/images/icon/preview-icon.svg";
import PremiumIcon from "../../assets/images/tags.svg";

export default function PlannerHistoryTab({ activeTab, preview, setPreview, previewData, setPreviewData, performanceData, setPerformanceData }) {
  const [showPerformance, setShowPerformance] = useState(false);
  const [data, setData] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const currentType = queryParams.get("type") || "list";

  const handleTabClick = (tab) => {
    queryParams.set("type", tab);
    history.push({ search: queryParams.toString() });
  };

  useEffect(() => {
    const isHistoryTab = location.pathname === '/post-planner' && queryParams.get('tab') === 'history';
    if (isHistoryTab && !queryParams.get("type")) {
      queryParams.set("type", "list");
      history.replace({ search: queryParams.toString() });
    }
  }, [location, queryParams, history]);

  const isHistoryTab = location.pathname === '/post-planner' && queryParams.get('tab') === 'history';

  async function handleGetHistory() {

    try {

      const res = await getAuthDataApi(`/website/userPostPlanner/history`);

      if (res) {

        const groupedData = groupDataByDate(res);

        setData(groupedData);

      } else {
        toast.error("Failed to get data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // setIsLoading(false);
    }
  };




  useEffect(() => {
    handleGetHistory()

  }, [])

  const handlePreview = (post) => {
    setPreview(true);
    setPreviewData(post);
  }

  return (

    <div className="planner-history-wrapper">
      <PerformanceModal showPerformance={showPerformance} setShowPerformance={setShowPerformance} uuid={performanceData?.uuid} />

      <div className="planner-history-heading d-flex flex-row justify-content-between">
        <h5 className="lato-regular fs-24-18 black-242">Post History & Analytics</h5>
        <div className="schedule-btn-wrapper d-none d-md-block  align-items-center ">
          <Button
            className={`scheduled-plan-btn lato-regular fs-14-11 black-242   ${currentType === "calendar" ? "active" : ""}`}
            onClick={() => handleTabClick("calendar")}

          >
            <Image style={{ marginRight: "5px" }} src={CalenderIcon} alt="icon" />
            Calendar
          </Button>

          <Button
            className={`scheduled-plan-btn lato-regular fs-14-11 black-242 ${currentType === "list" ? "active" : ""}`}
            onClick={() => handleTabClick("list")}

          >
            <Image style={{ marginRight: "5px" }} src={ListIcon} alt="icon" className="" />
            List
          </Button>
        </div>
        <div className="icons-wrapper d-flex flex-row d-md-none">
          <>
            <div className={`icons-small d-flex align-items-center mx-2 ${currentType === "calendar" ? "active" : ""}`}>
              <Image
                height={18}
                width={18}
                src={CalenderIcon}
                alt="icon"
                className="icon"
                onClick={() => handleTabClick("calendar")}
              />
            </div>
            <div className={`icons-small d-flex align-items-center ${currentType === "list" ? "active" : ""}`}>
              <Image
                height={18}
                width={18}
                src={ListIcon}
                alt="icon"
                className="icon"

                onClick={() => handleTabClick("list")}
              />
            </div>
          </>
        </div>
      </div>
      {isHistoryTab && currentType === "list" ? (
        data?.length > 0 ?
          <div className="schedule-cards d-flex flex-column">
            {data?.length > 0 && data?.map((item, index) => (
              <div key={index} className="d-flex flex-column">
                <div className="d-flex flex-row justify-content-between align-items-baseline">
                  <div className="d-flex flex-row align-items-center date-wrapper">
                    <Image src={CalenderIcon} />
                    <p className="lato-regular fs-14-11 black-242">{item?.date}</p>
                  </div>
                  {/* <Image src={AnalyticIcon} onClick={()=>{handlePerformance(item)}} /> */}
                </div>
                <div className="d-flex flex-column scheduled-content-wrapper">
                  {item?.content?.map((post) => (
                    <div className="d-flex flex-column scheduled-item">
                      <div className="d-flex flex-row justify-content-between">
                        <div className="history-card-image">
                          {
                            post?.content?.thumbnail ? (
                              <div className="thumbnail-image">
                                <Image width={80} height={80} src={post.content.thumbnail} alt="image" />
                                <div className="preview-image">
                                  <Image onClick={() => handlePreview(post)} width={20} height={20} src={PreviewIcon} alt="icon" />
                                </div>
                                {post?.content?.isPremium &&
                                  <div className="premium-icon">
                                    <Image className="view-detail" width={18} height={18} src={PremiumIcon} alt="icon" />
                                  </div>
                                }
                              </div>
                            ) : (
                              post?.scheduledPostPlannerContents?.[0]?.thumbnailPath ? (
                                <div className="thumbnail-image">
                                  <Image width={80} height={80} src={post.scheduledPostPlannerContents[0].thumbnailPath} alt="image" />
                                  <div className="preview-image">
                                    <Image className="view-detail" onClick={() => handlePreview(post)} width={20} height={20} src={PreviewIcon} alt="icon" />
                                  </div>
                                  {post?.content?.isPremium &&
                                    <div className="premium-icon">
                                      <Image className="view-detail" width={18} height={18} src={PremiumIcon} alt="icon" />
                                    </div>
                                  }
                                </div>
                              ) : (
                                <div className="card-plus">
                                  <h5 title={post?.caption} className="lato-bold fs-10-5 text-ellipsis-two black-242">
                                    {post?.caption}
                                  </h5>
                                  <div className="preview-image">
                                    <Image onClick={() => handlePreview(post)} width={20} height={20} src={PreviewIcon} alt="icon" />
                                  </div>
                                  {post?.content?.isPremium &&
                                    <div className="premium-icon">
                                      <Image className="view-detail" width={18} height={18} src={PremiumIcon} alt="icon" />
                                    </div>
                                  }
                                </div>
                              )
                            )
                          }


                        </div>
                        <div className="d-flex flex-column justify-content-between scheduled-content-text">
                          <div className="planner-title-container">
                            <h5 className="lato-medium fs-14-11 black-242">{post?.caption || "NA"}</h5>
                            <div className="d-flex">
                              {/* <Image style={{ cursor: "pointer" }} onClick={() => handlePerformance(post)} width={18} height={18} src={AnalyticIcon} /> */}

                            </div>

                          </div>
                          <div className="d-flex flex-row">
                            {post?.userPostPlanner?.endDate &&
                              <p className="tag-planner-text lato-medium black-242 op-5">{post?.userPostPlanner?.playlist?.title} | Ends on {formatDate(post?.userPostPlanner?.endDate)}</p>}
                            <p className="tag-planner-text lato-medium black-242 op-5 d-none d-md-flex">{post?.contentTypeName}</p>
                          </div>

                          <div className="d-flex flex-row justify-content-md-between flex-wrap ">
                            <div className="d-flex flex-column">
                              <div className="d-flex flex-row">
                                <p className="tag-planner-text lato-medium black-242 op-5 d-md-none">{post?.contentTypeName}</p>

                                {/* <p className="lato-regular fs-10-8 black-4 d-md-none">Occasion</p> */}
                              </div>
                              <div>
                                <p className="lato-regular fs-10-8 black-4 d-flex text-center align-items-center">
                                  <Image height={8} width={8} src={post?.isPosted ? PublishedIcon : UnpublishedIcon} className="dot-icon" />
                                  {post?.isPosted ? "Published" : "Unpublished"}
                                  {post?.occasionName ? <span className="mx-1"> | {post?.occasionName}</span> : ""}
                                </p>
                              </div>
                            </div>

                            <div className="d-md-flex flex-row d-none">
                              {post?.sheduledPostPlannerPlatforms.map((item) => (
                                <div key={item} className="social-icons-wrapper">
                                  <Image className="social-image" height={13} width={13} src={item?.platform?.iconImagePath} />
                                </div>
                              ))}
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className="d-md-none flex-row d-flex">
                        {post?.sheduledPostPlannerPlatforms.map((item) => (
                          <div key={item} className="social-icons-wrapper">
                            <Image className="social-image" height={9} width={9} src={item?.platform?.iconImagePath} />
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="planner-break-line"></div>
              </div>
            ))}
          </div>
          :
          <div style={{ height: '40vh' }} className="  d-flex justify-content-center align-items-center text-center "> <p className="lato-regular fs-18-12 black-242">You have not posted anything yet </p></div>
      ) : (
        isHistoryTab && currentType === "calendar" && (
          <div className="">

            <HistoryCalenderView performanceData={performanceData} setPerformanceData={setPerformanceData} />
            <PreviewModal preview={preview} setPreview={setPreview} previewData={previewData} />

          </div>
        )
      )}
    </div>
  );
}
