import React, { useEffect, useState } from 'react';
import { Button, Col, Dropdown, Image, Row } from "react-bootstrap";
import CalenderIcon from "../../assets/images/icon/calender-icon.svg";
import FinpediaIcon from "../../assets/images/icon/finpedia-icon.svg";
import { CustomDropDown } from '../../utils/form';
import RadioFields from '../tools/components/RadioFields';
import { AllShimmer, Spinner } from '../common/components';
import { commonAuthPostApi, getAuthDataApi } from '../home/Api';
import { toast } from 'react-toastify';
import ImageChecked from '../../assets/images/icon/tick-icon.svg';
import { ContentTypesCards } from '../home/_utils';
import { SlideFilter } from '../home/staticComponents';
import PdfImage from "../../assets/images/pdf-image.png"
import { AMC, API_LIMIT, isCurrentDateAllowed, MAX_LIMIT, socialMedias, START_PAGE, TARGETEDCONTENT } from '../../utils/Constant';
import { CustomModal, NotFound } from '../common/pages';
import CrossIcon from "../../assets/images/icon/cross-modal-icon.svg"
import { calculateTotalPageCount, formatNumber, groupDataByDate, isResOk, replaceUrlCharacters } from '../../utils/ReusableFunctions';
import defaultIcon from "../../assets/images/default-grey.png";
import save2 from "../../assets/images/save-filed.svg";
import BlackDownload from "../../assets/images/download-white.svg";
import BlackShare from "../../assets/images/black-share.svg";
import { formatDate, formatDateTimeToISOString, getMinDate } from "./_utilis/platformsTime";
import moment from 'moment';
import { putAuthCommonApi } from './Api';
import PlannerPagination from './PlannerPagination';
import DatePicker from "react-datepicker";
import ManualIcon from "../../assets/images/icon/manual-icon.svg"
import UploadPlus from "../../assets/images/icon/upload-plus.svg"
import DeleteIcon from "../../assets/images/icon/upload-delete.svg"
import SelectPositionModal from './SelectPositionModal';
import LockIcon from "../../assets/images/icon/lock-icon.svg";



/* eslint-disable eqeqeq */

export default function ReplacePost({ replaceData, setRecentData, setOpenReplace, addData, maxEndDate, userDetails, checkCount, activeKey }) {
  const [colorOptions, setColorOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [variation, setVariation] = useState([]);
  const [color, setColor] = useState("");
  const [language, setLanguage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [replacePostData, setReplacePostData] = useState([]);
  const [caption, setCaption] = useState('');
  const [platformData, setPlatformData] = useState([]);
  const [socialData, setSocialData] = useState([]);
  const [isSubLoading, setIsSubLoading] = useState(true);
  const [type, setType] = useState("All")
  const [key, setKey] = useState("socialMedias")
  const [data, setData] = useState(null);
  const [selectedCard, setSelectedCard] = useState("");
  const [dateTimeArr, setDateTimeArr] = useState([]);
  const [variationId, setVariationId] = useState();
  const [isComplaince, setIsComplaince] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [optionReplace, setOptionReplace] = useState(() => {
    if (activeKey === "Add" && checkCount === true) {
      return "custom";
    } else if (activeKey === "Edit" && replaceData?.isManual === true) {
      return "custom";
    } else {
      return "finpedia";
    }
  });

  const [images, setImages] = useState([]);
  const [selectedFileType, setSelectedFileType] = useState('image');
  const [file, setFile] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [imagesData, setImagesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    START_PAGE
  );
  const [videoFiles, setVideoFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [pageNo, setPageNo] = useState(parseInt(START_PAGE, 10));
  const [selectedSocialMedia, setSelectedSocialMedia] = useState("");
  const [newDate, setNewDate] = useState(
    replaceData?.date
      ? new Date(replaceData.date)
      : isCurrentDateAllowed
        ? new Date()
        : getMinDate()
  );
  const [pdfImage, setPdfImage] = useState("");
  const [filters, setFilters] = useState("");
  const [contentType, setContentType] = useState("");
  const [search, setSearch] = useState("");
  const [openPosition, setOpenPosition] = useState(false);
  const [cobrandingLabelling, setCobrandingLabelling] = useState({ labelStart: false, startText: false, startLogo: false, lableEnd: false, endText: false, endLogo: false, labelFull: false, fullText: false, fullLogo: false, positionId: '', textPosition: '', logoPosition: '', personalization: false, personalizationText: '' })
  const [files, setFiles] = useState([])

  const [imageObjects, setImageObjects] = useState([]);
  const [dateTimeObj, setDateTimeObj] = useState({
    uuid: "",
    date: "",
    timeMm: "",
    timeHh: "",
    period: "",
    checked: null,
    platformName: ""
  });
  const [warning, setWarning] = useState(false);

  useEffect(() => {
    if (replacePostData?.scheduledPostPlannerContents) {
      const updatedFiles = replacePostData.scheduledPostPlannerContents.map((item) => {
        const { cobrandFilePath, uuid, thumbnailPath } = item;

        // Determine file type based on file extension in cobrandFilePath
        const extension = cobrandFilePath.split('.').pop().toLowerCase();
        let type = '';
        if (extension === 'pdf') {
          type = 'pdf';
        } else if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
          type = 'image';
        } else if (['mp4', 'mkv', 'avi', 'mov'].includes(extension)) {
          type = 'video';
        }

        return { cobrandFilePath, uuid, thumbnailPath, type };
      });

      setFiles(updatedFiles);
    }
    setCobrandingLabelling({

      ...cobrandingLabelling,

      startText: replaceData?.cobrandPositions.start?.some((i) => i === "TEXT") || false,
      endText: replaceData?.cobrandPositions.end?.some((i) => i === "TEXT") || false,
      startLogo: replaceData?.cobrandPositions.start?.some((i) => i === "LOGO") || false,
      endLogo: replaceData?.cobrandPositions.end?.some((i) => i === "LOGO") || false,
      fullText: replaceData?.cobrandPositions?.full?.text && replaceData?.cobrandPositions?.full?.text[0],
      fullLogo: replaceData?.cobrandPositions?.full?.logo && replaceData?.cobrandPositions?.full?.logo[0],
      lableEnd: replaceData?.cobrandPositions?.end?.length > 0 ? true : false,
      labelStart: replaceData?.cobrandPositions?.start?.length > 0 ? true : false,
      labelFull: (replaceData?.cobrandPositions?.full?.text?.length > 0 || replaceData?.cobrandPositions?.full?.logo?.length > 0) ? true : false,
      textPosition: replaceData?.cobrandPositions?.full?.text && replaceData?.cobrandPositions?.full?.text[0],
      logoPosition: replaceData?.cobrandPositions?.full?.logo && replaceData?.cobrandPositions?.full?.logo[0]

    })
  }, [replacePostData]);

  const handleClose = (() => {
    setWarning(false);
  })
  const handleNavigate = () => {
    window.location.href = "mymembership";
  }

  const handleFileChange = async (event) => {
    const selectedFiles = Array.from(event.target.files);

    // Check total number of files
    if (files?.length + selectedFiles.length > 10) {
      toast.error("Cannot upload more than 10 files");
      return;
    }


    const existingTypes = new Set(files.map(file => file.type));
    const newType = selectedFiles[0]?.type.includes('image')
      ? 'image'
      : selectedFiles[0]?.type.includes('video')
        ? 'video'
        : 'pdf';

    // Restrict mixing file types
    if (
      (existingTypes.has('pdf') && (newType === 'image' || newType === 'video')) ||
      ((existingTypes.has('image') || existingTypes.has('video')) && newType === 'pdf')
    ) {
      toast.error('Cannot mix PDF files with images or videos.');
      return;
    }

    if (newType === 'pdf' && existingTypes.has('pdf')) {
      toast.error('Only one PDF file is allowed.');
      return;
    }

    // Calculate the total size of existing and new video files
    const existingVideoSize = files
      .filter((file) => file.type === 'video')
      .reduce((acc, file) => acc + file.binary.size, 0);

    const newVideoSize = selectedFiles
      .filter((file) => file.type.includes('video'))
      .reduce((acc, file) => acc + file.size, 0);

    const totalVideoSize = existingVideoSize + newVideoSize;

    // Calculate the total size of existing and new PDF files
    const existingPdfSize = files
      .filter((file) => file.type === 'pdf')
      .reduce((acc, file) => acc + file.binary.size, 0);

    const newPdfSize = selectedFiles
      .filter((file) => file.type.includes('pdf'))
      .reduce((acc, file) => acc + file.size, 0);

    const totalPdfSize = existingPdfSize + newPdfSize;

    // Show error for exceeding video size
    if (totalVideoSize > 10 * 1024 * 1024) {
      toast.error('Total video file size exceeds 10MB');
      return;
    }

    // Show error for exceeding PDF size
    if (totalPdfSize > 10 * 1024 * 1024) {
      toast.error(' PDF file size exceeds 10MB');
      return;
    }

    // Filter and process files
    const validSelectedFiles = selectedFiles.filter((file) => {
      if (file.type.includes('video') && totalVideoSize > 10 * 1024 * 1024) {
        return false; // Exclude videos if size exceeds limit
      }
      if (file.type.includes('pdf') && totalPdfSize > 10 * 1024 * 1024) {
        return false; // Exclude PDFs if size exceeds limit
      }
      return true; // Include file if size is within limits
    });

    // Add new files
    const newFiles = await Promise.all(
      validSelectedFiles.map(async (file) => {
        const type = file.type.includes('image')
          ? 'image'
          : file.type.includes('video')
            ? 'video'
            : 'pdf';

        let thumbnailPath = '';
        let cobrandFilePath = '';

        if (type === 'video') {
          thumbnailPath = await generateThumbnail(file); // Generate thumbnail for video
          cobrandFilePath = thumbnailPath;
        } else if (type === 'pdf') {
          thumbnailPath = PdfImage; // Placeholder for PDF thumbnail
          cobrandFilePath = PdfImage;
        }

        return {
          binary: file,
          thumbnailPath,
          type,
          uuid: "",
          localPath: URL.createObjectURL(file),
          cobrandFilePath,
        };
      })
    );

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };


  const changeRadio = ((e) => {
    e.preventDefault();
    e.stopPropagation();
    if ((activeKey === "Add" && checkCount) || (replaceData && checkCount && replaceData?.isManual)) {
      setWarning(true)
    } else {
      setOptionReplace("finpedia")
    }
  })

  async function handleGetPlatforms() {
    try {
      const res = await getAuthDataApi(`/website/userPlatform/user`);
      if (res) {
        setPlatformData(res);
        const date = moment(newDate);

        const transformedData = res.map(item => ({

          uuid: item?.platform?.uuid || "",
          // date: "",
          // timeMm: "",
          // timeHh: "",
          // period: "",
          date: date.format('YYYY-MM-DD'),
          timeMm: date.format('mm'),
          timeHh: date.format('hh'),
          period: date.format('A'),
          checked: null,
          platformName: item?.platform?.platformName || "",
          iconImagePath: item?.platform?.iconImagePath || ""
        }));


        setDateTimeArr(transformedData);

        handleReplacePostDetails(replaceData, transformedData)
      } else {
        toast.error("Failed to get Platform Data");
      }

    } catch (error) {
      // console.error("Error fetching platform data:", error);
    } finally {
      setIsLoading(false);
    }


  };
  const getInputValue = () => {
    let value = '';

    if (cobrandingLabelling.labelStart) value += 'Start ';
    if (cobrandingLabelling.lableEnd) value += 'End ';
    if (cobrandingLabelling.labelFull) value += 'Full ';

    return value.trim(','); // Remove extra space at the end
  };


  async function handleGetSocials() {
    try {
      const res = await getAuthDataApi(`/website/playlist/content-types`);
      if (res) {
        setSocialData(res?.list);


      } else {
        toast.error("Failed to get data");
      }

    } catch (error) {
      // console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }


  }

  const handleDateChange = (date) => {
    // e.preventDefault();
    setNewDate(date)
  }
  const handleModalClose = (() => {
    setModalOpen(false);
  })

  useEffect(() => {
    handleGetSocials()


  }, [])

  useEffect(() => {
    // handleReplacePostDetails()
    handleGetPlatforms()

  }, [newDate]);


  async function handleReplacePostDetails(replaceData, dateTimeArr) {
    if (replaceData) {
      setIsLoading(true)
      try {
        const res = await getAuthDataApi(`website/scheduledPostPlanner/${replaceData?.uuid}`);
        if (res) {
          setCaption(res?.caption);

          const updatedDateTimeArr = dateTimeArr.map(dateTimeObj => {
            const platformObj = res.sheduledPostPlannerPlatforms.find(item => item?.platform?.uuid === dateTimeObj.uuid);

            if (platformObj) {
              const date = moment(platformObj.date);


              return {
                ...dateTimeObj,
                date: date.format('YYYY-MM-DD'),
                timeMm: date.format('mm'),
                timeHh: date.format('hh'),
                period: date.format('A'),
                checked: true
              };
            } else {
              return {
                ...dateTimeObj,
                date: moment(res.date).format('YYYY-MM-DD'),
                timeMm: moment(res.date).format('mm'),
                timeHh: moment(res.date).format('hh'),
                period: moment(res.date).format('A'),
                checked: false
              };
            }
          });
          setReplacePostData(res);


          setVariation(res?.content?.contentVariation);

          setDateTimeArr(updatedDateTimeArr);




          let languageOption1 = res?.content?.contentVariation?.map((item) => item.language);
          let languageOption = Object.values(languageOption1.reduce((uniqueMap, obj) => {
            uniqueMap[obj.languageName] = obj;
            return uniqueMap;
          }, {}));

          let colorOption = res?.content?.contentVariation?.map((item) => item.color);

          setLanguageOptions(languageOption);
          setColorOptions(colorOption);

          if (languageOption?.length === 1) {
            let getFixedObj = res?.content?.contentVariation?.find(item => item.language?.languageName === languageOption[0]?.languageName);
            handleObject(getFixedObj);

          }

          if (colorOption?.length > 1) {
            let getFixedObj = res?.contentVariation;
            handleObject(getFixedObj);


          }


        } else {
          toast.error("Failed to get data");
        }
      } catch (error) {
        // console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }

    }
    else {
      const updatedDateTimeArr = dateTimeArr.map(dateTimeObj => {
        // const platformObj = res.sheduledPostPlannerPlatforms.find(item => item?.platform?.uuid === dateTimeObj.uuid);
        const parsedDate = moment(newDate, "DD-MM-YYYY | ddd");


        const isoString = parsedDate.toISOString();

        return {
          ...dateTimeObj,
          date: parsedDate.format('YYYY-MM-DD'),
          timeMm: parsedDate.format('mm'),
          timeHh: parsedDate.format('hh'),
          period: parsedDate.format('A'),
          checked: false,

        };


      });
      setDateTimeArr(updatedDateTimeArr);


    }

  };

  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const weekday = weekdays[date.getDay()];

    return `${day}-${month}-${year} | ${weekday}`;
  };

  const handleObject = (data) => {
    setLanguage(data?.language?.languageName);
    setColor(data?.color)
    setVariationId(data?.uuid)
  }


  const languageSelectHandler = (data) => {
    let getFixedObj = variation?.find(item =>
      item.color?.colorName === color?.colorName && item.language?.languageName === data?.languageName
    ) || variation?.find(item => item.language?.languageName === data?.languageName
    );
    handleObject(getFixedObj)
  }

  const colorSelectHandler = (data) => {
    let getFixedObj = variation?.find((item, i) => (item.color?.colorName === data?.colorName));

    handleObject(getFixedObj);
  };

  const handleCaptionChange = (e) => {
    e.preventDefault();
    setCaption(e.target.value);
  };


  const handleCheckboxChange = (e, uuid) => {
    setDateTimeArr(prevDateTimeArr =>
      prevDateTimeArr.map(item =>
        item.uuid === uuid ? { ...item, checked: e.target.checked } : item
      )
    );

  };

  const handleCheckbox = (e) => {
    e.preventDefault();
    setIsComplaince(!isComplaince);
  };


  const handleTimeChange = (e, uuid, field) => {
    let value = Number(e.target.value);
    const item = dateTimeArr.find(item => item.uuid === uuid);

    if (field === 'timeHh') {
      // If AM is selected and the value is 12, set it to 00
      if (item.period === "AM" && value === 12) {
        value = 0;
      }

      // If PM is selected and the value is 00, set it to 12
      if (item.period === "PM" && value === 0) {
        value = 12;
      }

      setDateTimeArr(prevDateTimeArr =>
        prevDateTimeArr.map(item =>
          item.uuid === uuid ? { ...item, [field]: value } : item
        )
      );
    } else if (field === 'timeMm') {
      const validatedValue = Math.max(0, Math.min(59, value));
      setDateTimeArr(prevDateTimeArr =>
        prevDateTimeArr.map(item =>
          item.uuid === uuid ? { ...item, [field]: validatedValue } : item
        )
      );
    }
  };

  const togglePeriod = (uuid, type) => {
    setDateTimeArr(prevDateTimeArr =>
      prevDateTimeArr.map(item => {
        if (item.uuid === uuid) {
          let updatedItem = { ...item, period: type };

          // If switching to AM and the hour is 12, set it to 00
          if (updatedItem.period === "AM" && updatedItem.timeHh === 12) {
            updatedItem.timeHh = 0;
          }

          // If switching to PM and the hour is 00, set it to 12
          if (updatedItem.period === "PM" && updatedItem.timeHh === 0) {
            updatedItem.timeHh = 12;
          }

          return updatedItem;
        }
        return item;
      })
    );
  };

  const generateThumbnail = async (file) => {
    setUploading(true)
    const formData = new FormData();
    formData.append("file", file);

    try {
      const res = await commonAuthPostApi("website/scheduledPostPlanner/generate-thumbnail", formData);
      const thumbnailUrl = res?.data; // Assuming the response has a `thumbnailUrl` key
      return thumbnailUrl ? thumbnailUrl : null;
    } catch (error) {
      console.error("Failed to generate thumbnail:", error);
      return null;
    } finally {
      setUploading(false)
    }
  };

  const handleDeleteFile = (indexToDelete) => {
    const filteredFiles = files.filter((_, index) => index !== indexToDelete);
    setFiles(filteredFiles);
  };

  const handleImageChange = async (event) => {
    const files = event.target.files;
    const fileType = files[0]?.type;

    if (fileType.includes('image')) {
      setSelectedFileType('image');
      // Handle image upload (multiple allowed)

      setImages([...images, ...Array.from(files).map(file => URL.createObjectURL(file))]);
      setImagesData([...imagesData, ...Array.from(files)]);

    } else if (fileType.includes('video')) {

      setSelectedFileType('video');
      const videoThumnailUrl = await generateThumbnail(files[0])
      if (videoThumnailUrl) {
        setImages([...images, videoThumnailUrl]);
        setVideoFiles((prevFiles) => [...prevFiles, files[0]]);
        setFile(files[0]);
      }
    }
    else if (fileType.includes('pdf')) {

      setSelectedFileType("pdf")
      setFile(files[0])
      setPdfImage(PdfImage)
    }
  };

  const handleUpgrade = () => {
    window.location.href = "/mymembership"
  }

  const handleDeleteImage = (indexToDelete, image) => {
    const filteredImages = images.filter((_, index) => index !== indexToDelete);
    setImages(filteredImages);
    const filteredImagesData = imagesData.filter((_, index) => index !== indexToDelete);
    setImagesData(filteredImagesData);
    const filterVideos = videoFiles.filter((_, index) => index !== indexToDelete)
    setVideoFiles(filterVideos)
    const lastIndex = filterVideos?.length - 1;
    const lastVideo = filterVideos[lastIndex];

    // Check if lastVideo exists in any item's thumbnailPath in imageObjects
    const matchedItem = imageObjects.find(item => item.thumbnailPath === lastVideo);

    if (matchedItem) {
      setFile(matchedItem.cobrandFilePath || matchedItem.thumbnailPath);
    } else {
      setFile(lastVideo);
    }
    const filteredImageObjects = imageObjects.filter(item => item.thumbnailPath !== image);
    setImageObjects(filteredImageObjects);
  };

  const handleDeletePdfImage = () => {
    setPdfImage("");
    setFile(null)
  }
  const handleModal = (e) => {

    e.preventDefault();
    setModalOpen(true)
  }
  const handleTerms = () => {
    window.location.href = '/terms-conditions'
  }

  const handlePlatformClick = (uuid) => {

    setDateTimeArr(prevDateTimeArr =>
      prevDateTimeArr.map(item =>
        item.uuid === uuid ? { ...item, editing: !item.editing } : item
      )
    );
  };

  async function handleSelectRadio(item) {
    setSelectedSocialMedia(item)


    let mediaValue = item?.socialMediaName || item?.categoryName;
    mediaValue = replaceUrlCharacters(mediaValue);
    try {
      const res = await getAuthDataApi(`content/searchDetails?key=${item?.socialMediaName ? "socialMedias" : "category"}&value=${mediaValue}&pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}`);
      if (res) {
        setData(res);
        setPageNo(START_PAGE)
        setIsSubLoading(false);
      } else {
        toast.error("Failed to get data");
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }

  };

  const updateFilter = async (filters, contentType, search = "") => {
    let contentTypeOption = (contentType && (contentType != "" && contentType != "All")) ? `&contentType=${contentType}` : "";
    let mediaValue = selectedSocialMedia?.socialMediaName || selectedSocialMedia?.categoryName;
    mediaValue = replaceUrlCharacters(mediaValue);
    setFilters(filters);
    setContentType(contentType);
    setSearch(search);
    const res = await getAuthDataApi(`content/searchDetails?key=${selectedSocialMedia?.socialMediaName ? "socialMedias" : "category"}&value=${mediaValue}&pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}${contentTypeOption}${filters && filters.includes('&') ? filters : "&" + filters}${search ? `&searchValue=${search}` : ""}`);
    if (res) {
      setData(res);
      setPageNo(START_PAGE)
      setIsSubLoading(false);
    } else {
      toast.error("Failed to get data");
    }
  }

  const viewAllDetail = async (contentTypeValue, newPage) => {
    let contentType = contentTypeValue.replace("/content-type/", "").replace("_", '/');

    let contentTypeOption = (contentType && (contentType != "" && contentType != "All")) ? `&contentType=${contentType}` : "";
    let mediaValue = selectedSocialMedia?.socialMediaName || selectedSocialMedia?.categoryName;
    mediaValue = replaceUrlCharacters(mediaValue);
    const res = await getAuthDataApi(`content/searchDetails?key=${selectedSocialMedia?.socialMediaName ? "socialMedias" : "category"}&value=${mediaValue}&pageNumber=${newPage}&pageSize=${MAX_LIMIT}${contentTypeOption}${filters && filters.includes('&') ? filters : "&" + filters}${search ? `&searchValue=${search}` : ""}`);
    if (res) {
      setData(res);
      // setPageNo(START_PAGE)
      setIsSubLoading(false);
    } else {
      toast.error("Failed to get data");
    }
  }

  const totalPage = calculateTotalPageCount(data && (((data?.contents?.length) ?? 0) !== 0) ? data?.contents[0].count : 10);


  const handleSave = async () => {
    setIsLoading(true);
    const parsedDate = moment(newDate || new Date(), "DD-MM-YYYY | ddd");
    const isoString = parsedDate.toISOString();

    const start = [
      cobrandingLabelling.startText ? "TEXT" : null,
      cobrandingLabelling.startLogo ? "LOGO" : null,
    ].filter(item => item !== null);

    const end = [
      cobrandingLabelling.endText ? "TEXT" : null,
      cobrandingLabelling.endLogo ? "LOGO" : null,
    ].filter(item => item !== null);

    const full = cobrandingLabelling.labelFull
      ? {
        text: (cobrandingLabelling.fullText && cobrandingLabelling.textPosition) ? [cobrandingLabelling.textPosition] : [],
        logo: (cobrandingLabelling.fullLogo && cobrandingLabelling.logoPosition) ? [cobrandingLabelling.logoPosition] : []
      }
      : [];


    const scheduledPostPlannerContents = files
      .filter(file => !file.binary)
      .map(({ cobrandFilePath, uuid }) => ({ cobrandFilePath, uuid }));
    const payload = {
      data: {
        date: replaceData?.date || isoString,

        isManual: optionReplace == "custom",
        cobrandPositions: {
          start: start,
          end: end,
          full: full
        },
        platforms: dateTimeArr
          .filter((platform) => platform.checked)
          .map((platform) => ({
            uuid: platform.uuid,
            date: formatDateTimeToISOString(platform.date, platform.timeHh, platform.timeMm, platform.period),
          })),
        caption: caption,
        contentTypeName: selectedSocialMedia?.socialMediaName || selectedSocialMedia?.categoryName,

        ...(optionReplace == "finpedia" && { // Include content only when isManual is false
          content: {
            uuid: replacePostData?.content?.uuid || replacePostData?.uuid,

          },
          contentVariation: {
            uuid: variationId,
          },
        }),
        ...(replaceData && optionReplace == "custom" && {
          scheduledPostPlannerContents,
        }),
      },
    };


    const formData = new FormData();
    formData.append("key", JSON.stringify(payload))


    files.forEach(file => {
      if (file.binary) {
        formData.append("file", file.binary);
      }
    });



    if (replaceData) {
      const res = await putAuthCommonApi(`website/scheduledPostPlanner/${replaceData?.uuid}`, formData);
      if (isResOk(res?.status)) {
        // toast.success("Updated Successsfully")
        const res = await getAuthDataApi("website/userPostPlanner/recent");
        if (res) {
          setIsLoading(false);
          setOpenReplace(false);
          const groupedData = groupDataByDate(res);
          setRecentData(groupedData);
        }
      }
      else {
        setIsLoading(false)
      }

    }
    else {

      const res = await commonAuthPostApi(`website/scheduledPostPlanner`, formData);
      if (res?.data && !res?.status) {

        toast.success("Added Successsfully")
        const res = await getAuthDataApi("website/userPostPlanner/recent");
        if (res) {
          setIsLoading(false);

          setOpenReplace(false);
          const groupedData = groupDataByDate(res);
          setRecentData(groupedData);
        }
      }
      else {
        setIsLoading(false)
      }

    }




  };

  const isDisabled = () => {
    if (optionReplace === "finpedia") {
      return (!selectedCard && !replaceData) || isLoading;
    } else {
      return uploading || isLoading || files.length === 0;
    }
  };



  const handleCancel = () => {
    setOpenReplace(false)
  }

  const renderComponent = () => {
    if (((data?.contents?.length ?? 0) > 0) && type !== AMC && type !== TARGETEDCONTENT) {
      return (
        <>
          {key !== null && key !== socialMedias && type === "All" && <AmcDetailshead type={key?.toLowerCase()} data={data} />}
          {key === socialMedias && (
            <div className="socialMedias-main-header">
              <h5 className="lato-regular fs-32-24 black-242">
                {/* {decodeURIComponent(socialData?.socialMediaName.replace(/\w/, (a) => a.toUpperCase()) ?? "NaN") || "Na"} &nbsp;&nbsp;&nbsp;&nbsp; */}
                <span className="lato-regular fs-14-12">
                  {formatNumber(data.length)}
                </span>
              </h5>
            </div>
          )}
          {/* {(Array.isArray(data) ? data : data.contents)?.map((item, j) => { */}
          {(data.contents)?.map((item, j) => {
            // console.log(j, (j+1 ) % 2)
            if (item.list?.length > 0) {
              return (
                <div>
                  <ContentTypesCards
                    key={j}
                    countTitle={item?.title}
                    hide={data?.contents?.length === 1 ? false : true}
                    // wrapperadd={data.length === 1 ? `result-wrap ${item.list?.length>=3 && "justify-content-between"}` : ""}
                    wrapperadd={data?.contents?.length === 1
                      ? `result-wrap ${item.list?.length >= 3 && ""} social`
                      : ""

                    }
                    data={item.list}
                    count={item.count}
                    typeId={item?.uuid}
                    bannerAds={data?.bannerAds}
                    code={item.code}
                    // isLoading={!isSubLoading}
                    isLoading={false}
                    type={type}
                    selectedCard={selectedCard}
                    setSelectedCard={setSelectedCard}
                    replacePostData={replacePostData}
                    setReplacePostData={setReplacePostData}
                    color={color}
                    setColor={setColor}
                    language={language}
                    setLanguage={setLanguage}
                    languageOptions={languageOptions}
                    setLanguageOptions={setLanguageOptions}
                    colorOptions={colorOptions}
                    setColorOptions={setColorOptions}
                    variation={variation}
                    setVariation={setVariation}
                    setCaption={setCaption}
                    setVariationId={setVariationId}
                    checkbox={true}
                    componentType={"replacePost"}
                    viewDetail={viewAllDetail}
                    isContainer={false}

                  />
                  {data && ((data?.contents?.length) ?? false) && data?.contents[0].count > API_LIMIT && data.contents.length < 2 && (
                    <PlannerPagination

                      viewAll={`/content-type/${item?.title?.toLowerCase()}`}//change


                      pageCount={totalPage}
                      viewDetail={viewAllDetail}
                      pageNo={pageNo}
                      setPageNo={setPageNo}
                    />
                  )}
                  {
                    j < data?.contents.length && data?.contents.length !== 1 &&
                    (j + 1) % 2 == 0 && Math.floor(j / 2) < 2 && data?.bannerAds && data?.bannerAds.length > 0 && data?.bannerAds?.[Math.floor((j / 2))]?.bannerImagePath &&
                    <a rel="noreferrer" className="banner-main-wrapper" href={data?.bannerAds[Math.floor(j / 2)]?.redirectionLink} target="_blank">
                      {/* {console.log("hey", j,j/2,Math.floor(j / 2), data?.bannerAds)} */}
                      <Image src={data?.bannerAds[Math.floor((j / 2))]?.bannerImagePath} alt="banner Image" />
                    </a>
                  }
                </div>
              );
            }
            return null;
          })}
          {/* </div> */}
        </>
      );
    } else {
      // console.log("else last component")
      return (
        <div>
          {key !== null && key !== socialMedias && type === "All" ? (<>
            <AmcDetailshead type={key} data={data} />
            {
              ((data?.contents?.length ?? 0) === 0) && <NotFound />
            }

          </>
          ) : (
            <NotFound />
          )}
          {/* <NotFound /> */}
        </div>
      );
    }
  };


  return (
    <>
      <div className="replace-post-wrapper ">
        <Row className='replace-heading d-flex flex-md-row flex-column justify-content-between mb-3 pointer'>
          <Col md={6} sm={12}>
            <div
              className={`finpedia-option-wrapper d-flex flex-row justify-content-between ${optionReplace === 'finpedia' ? 'selected' : ''}`}
              onClick={(e) => { changeRadio(e) }}
            >
              <div className='d-flex flex-row'>
                <Image src={FinpediaIcon} alt="finpedia" className='mb-1' />
                <div className='finpedia-text d-flex flex-column'>
                  <p className='lato-semibold fs-18-14 black-242 mb-1'>Thefinpedia</p>
                  <p className='lato-regular fx-12 black-4'>Select from Thefinpedia curated content</p>
                </div>
              </div>
              <div onClick={(e) => e.stopPropagation()} className=''>
                <input
                  className='custom-radio mt-3'
                  type="radio"
                  aria-label="Select Finpedia content"
                  checked={optionReplace === 'finpedia'}
                  onChange={(e) => { changeRadio(e) }}
                />
              </div>
            </div>
          </Col>

          <Col md={6} sm={12}>
            <div
              className={`finpedia-option-wrapper d-flex flex-row justify-content-between mt-3 mt-md-0 ${optionReplace === 'custom' ? 'selected' : ''}`}
              onClick={() => { setOptionReplace('custom') }}
            >
              <div className='d-flex flex-row'>
                <Image src={ManualIcon} alt="custom" />
                <div className='finpedia-text d-flex flex-column'>
                  <p className='lato-semibold fs-18-14 black-242 mb-1'>Manual</p>
                  <p className='lato-regular fx-12 black-4'>Upload custom content of your own</p>
                </div>
              </div>
              <div onClick={(e) => e.stopPropagation()}>
                <input
                  className='custom-radio mt-3'
                  type="radio"
                  aria-label="Select Custom content"
                  checked={optionReplace === 'custom'}
                  onChange={() => { setOptionReplace('custom'); }}
                />
              </div>
            </div>
          </Col>
        </Row >

        <div className="d-flex flex-row justify-content-between align-items-center date-wrapper mb-4">

          <DatePicker
            selected={replaceData ? new Date(replaceData?.date) : newDate}
            onChange={(date) => { handleDateChange(date) }}

            maxDate={new Date(maxEndDate)}
            minDate={isCurrentDateAllowed ? new Date : getMinDate()}
            // dateFormat="MMMM d, yyyy"
            placeholderText="Select Date"
            className="form-control-date mt-0"
            showYearDropdown
            yearDropdownItemNumber={50}
            scrollableYearDropdown
            dateFormat={" d MMMM , yyyy"}
            disabled={replaceData}
            onFocus={(e) => {

              e.target.blur()
              e.target.readOnly = true
            }}
          />
          <div className=' replace-post-btns sticky-btn d-none d-md-flex justify-content-center align-content-center flex-row mb-3'>
            <Button style={{ marginRight: "2rem" }} onClick={handleCancel} className="secondary-btn ">Cancel</Button>
            <Button onClick={handleSave} disabled={isDisabled()}
              className="primary-btn">{isLoading ? <Spinner /> : "Save"}</Button>


          </div>

        </div>
        {optionReplace != "finpedia" && userDetails?.isCustomUploadAllowed === false &&

          <div className=" custom-upgrade d-flex flex-md-row flex-column justify-content-between align-items-center">
            <div className='d-flex flex-column'>
              <div className="content-details d-flex flex-row">
                <p className="lato-med)ium fs-20-16 black-242 mb-1 mr-2">
                  Custom Upload
                </p>
                <Image src={LockIcon} alt="icon" />
              </div>
              <p className='lato-regular fx-14 black-4'>Please upgrade your current subscription plan to access custom upload feature.</p>
            </div>
            <Button onClick={handleUpgrade} className="primary-btn mt-2 mt-md-0">Upgrade</Button>
          </div>
        }
        <div className={`d-flex flex-row ${userDetails.isCustomUploadAllowed === false && optionReplace !== 'finpedia' ? 'disabled-div' : ''}`}>

          <div className='edit-modal-main d-flex flex-column flex-md-row justify-content-between'>
            <div className=' d-flex flex-column pr-2'>

              {optionReplace == "finpedia" ?

                <div className="image-uploader d-flex flex-column">
                  <div className="image-main d-flex flex-column">
                    {replacePostData?.content?.thumbnail || replacePostData?.thumbnail || caption ?
                      <div>
                        {replacePostData?.content?.thumbnail || replacePostData?.thumbnail ?
                          <Image className="replace-schedule-image mb-3 mb-md-0" src={replacePostData?.content?.thumbnail || replacePostData?.thumbnail} alt="image" />
                          :
                          <div className='card-plus mb-md-0'>
                            <h5 title={caption} className="lato-bold fs-14-12 text-ellipsis-two black-242">
                              {caption}
                            </h5>
                          </div>
                        }
                      </div>
                      :

                      <>
                        <div className="plus-icon pointer" onClick={() => files?.length == 10 ? toast.error("Cannot Upload more than 10 Files") : document.getElementById('file-input').click()}>
                          <Image src={UploadPlus} alt="icon" />
                        </div>

                        <p className="plus-text lato-medium fx-12 black-242 op-5">
                          Your selected post will be displayed here once you choose content from the list below.
                        </p>
                      </>

                    }
                  </div>
                  <div className='note-text mx-0'>
                    <p className="lato-medium fs-11-10 black-242 op-5">
                      PDF file uploads are only compatible with LinkedIn and Whatsapp.
                    </p>
                  </div>

                </div>
                :
                <>
                  <div className='d-flex flex-row'>
                    <div className="image-uploader d-flex flex-column">
                      <div className="image-main d-flex flex-column">
                        {uploading ? (
                          <span className='d-flex align-items-center gap-12 justify-content-center'><Spinner /> uploading...</span>
                        ) : files.length > 0 ? (
                          <div className="uploaded-image">
                            {(() => {
                              const lastFile = files[files.length - 1]; // Get the last uploaded file

                              if (lastFile.type === "image") {
                                return <img src={lastFile.localPath || lastFile.thumbnailPath} alt="Uploaded" className="big-image" />;
                              }

                              if (lastFile.type === "video") {
                                return (
                                  <video
                                    className="uploaded-video"
                                    width="320"
                                    height="240"
                                    controls
                                    src={lastFile?.localPath || lastFile?.cobrandFilePath}
                                  >
                                    Your browser does not support the video tag.
                                  </video>
                                );
                              }

                              if (lastFile.type === "pdf" && lastFile.thumbnailPath) {
                                return (
                                  <div>
                                    <Image src={lastFile?.thumbnailPath} alt="PDF Thumbnail" className="pdf-thumbnail" />
                                    {lastFile?.binary?.name && <p className="pdf-title">{lastFile?.binary?.name}</p>}
                                  </div>
                                );
                              }

                              return null;
                            })()}
                            <div className="delete-icon" onClick={() => handleDeleteFile(files.length - 1)}>
                              <Image src={DeleteIcon} alt="Delete" />
                            </div>
                          </div>
                        ) : (
                          <>
                            {uploading ? (
                              <span className='fx-14 d-flex align-items-center gap-12 justify-content-center'><Spinner /> uploading...</span>
                            ) : (
                              <div className="plus-icon pointer" onClick={() => files?.length == 10 ? toast.error("Cannot Upload more than 10 Files") : document.getElementById('file-input').click()}>
                                <Image src={UploadPlus} alt="icon" />
                              </div>
                            )}
                            {!uploading && (
                              <p className="plus-text lato-medium fx-12 black-242 op-5">
                                Your selected post will be displayed here once you choose content from the list below.
                              </p>
                            )}
                          </>
                        )}


                        <input
                          id="file-input"
                          type="file"
                          accept="image/jpeg,image/png,video/mp4,video/mkv,application/pdf"
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                          multiple
                        />
                      </div>

                      <div className="note-text">
                        <p className="lato-medium fs-11-10 black-242 op-5">
                          Note: jpg, png, mp4, mkv, pdf file formats allowed only. Max upload size: 10mb.
                        </p>
                        <p className="lato-medium fs-11-10 black-242 op-5">
                          PDF file uploads are only compatible with LinkedIn and Whatsapp.
                        </p>
                      </div>

                      <div className="small-images d-flex flex-wrap">
                        {files.length > 0 && !files.some(file => file.type === 'pdf') && (
                          <div className="plus-small-icon pointer" onClick={() => files?.length == 10 ? toast.error("Cannot Upload more than 10 Files") : document.getElementById('file-input').click()}>
                            {uploading ? (
                              <span className='d-flex align-items-center gap-12 justify-content-center'><Spinner /></span>
                            ) : (
                              <Image width={18} height={18} src={UploadPlus} alt="Add icon" />
                            )}
                          </div>
                        )}
                        {files.map((file, index) => (
                          <div key={index} className="small-image-wrapper">
                            <div className="small-image">
                              {file.type === 'image' && <img src={file.localPath || file.thumbnailPath} alt={`Uploaded ${index}`} />}
                              {file.type === 'video' && file.thumbnailPath && (
                                <img src={file.thumbnailPath} alt="Video Thumbnail" />
                              )}
                              {file.type === 'pdf' && <img src={file.thumbnailPath} alt="PDF Thumbnail" />}
                              <div className="delete-icon" onClick={() => handleDeleteFile(index)}>
                                <Image src={DeleteIcon} alt="Delete" />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              }
              {replaceData &&
                <div className="post-details d-flex d-md-none flex-row">

                  <p className="tag-planner-text lato-medium black-242 op-5">{replacePostData?.userPostPlanner?.playlist?.title || replaceData?.userPostPlanner.playlist?.title} | Ends on {replacePostData?.userPostPlanner?.endDate ? formatDate(replacePostData?.userPostPlanner?.endDate) : formatDate(replaceData?.date)}</p>
                  {replacePostData?.contentTypeName &&
                    <p style={{ width: "fit-content" }} className="tag-planner-text lato-medium black-242 op-5 mt-0">{replacePostData?.contentTypeName}</p>}
                </div>
              }
            </div>
            <div className='edit-modal-dropdown'>
              <div className="branding-color-language-frame">
                <div className=" dropwon-component-wrapper1 d-flex flex-row flex-md-column justify-content-start mb-0">


                  <CustomDropDown
                    label={language || "Language"}
                    options={languageOptions?.length === 1 && language === languageOptions[0]?.languageName ? [] : languageOptions || []}
                    getData={(data) => {
                      languageSelectHandler(data)

                    }}
                    id="Language"
                    classes='light-border'

                    disabled={languageOptions?.length <= 1}
                  // disabled={languageOption?.length>=1 && language===languageOption[0]?.languageName}
                  />

                  <CustomDropDown
                    label={color || "Color"}
                    options={colorOptions || []}
                    getData={(data) => colorSelectHandler(data)}
                    id="color"
                    disabled={colorOptions?.length <= 1}
                    classes='light-border'
                  />

                </div>
                <div className="position-input" >
                  <input
                    value={getInputValue()}
                    readOnly
                    type="text"
                    id="selectableInput"
                    placeholder="Position"
                    disabled={optionReplace == "custom"}
                    style={{ borderRadius: "8px", outline: "none", border: "1px solid #242E3D33", fontSize: "14px", padding: "1rem" }}
                  />
                  <div
                    className={`lato-medium fs-14-12 primary position-select d-flex ${optionReplace === "custom" ? "cursor-not-allowed" : "pointer"}`}

                    onClick={() => {
                      if (optionReplace !== "custom") {
                        setOpenPosition(true);
                      }
                    }}
                  >
                    Select
                  </div>
                </div>
              </div>
            </div>
            <div className='social-media-edit col-md-6 d-flex flex-column flex-md-row '>
              {dateTimeArr?.map((dateTimeObj, index) => {
                const isDisabled =
                  files?.length > 0 &&
                  files[0].type === "pdf" &&
                  ["Facebook", "Instagram", "Twitter"].includes(dateTimeObj.platformName);

                return (
                  <div key={index} className={`${isDisabled ? "disabled-div" : ""} d-flex flex-md-column flex-row justify-content-between justify-content-md-start`}>
                    <div className='social-icons-details d-flex flex-md-column flex-row text-center justify-content-center align-items-center'>
                      <input
                        type="checkbox"
                        checked={dateTimeObj.checked || false}
                        onChange={(e) => handleCheckboxChange(e, dateTimeObj.uuid)}
                        id={`full-check-box-${index}`}
                        className='d-none'

                      />
                      <label htmlFor={`full-check-box-${index}`} className='cobrand-custom-checkbox mb-0 mt-1 d-md-none mr-3 ' onClick={() => handlePlatformClick(dateTimeObj.uuid)}>
                        {dateTimeObj.checked && <Image src={ImageChecked} alt="" className='d-block' />}
                      </label>
                      <div className="social-icons-wrapper mr-3 mr-md-0">
                        <Image width={18} height={18} src={dateTimeObj?.iconImagePath} alt="image" />
                      </div>
                      <p className='lato-bold fs-14-12 black-242'>{dateTimeObj?.platformName}</p>
                    </div>
                    <div className='timing-wrapper d-flex flex-md-column flex-row text-center justify-content-between align-items-center'>
                      <input
                        type="checkbox"
                        checked={dateTimeObj.checked || false}
                        onChange={(e) => handleCheckboxChange(e, dateTimeObj.uuid)}
                        id={`full-check-box-${index}`}
                        className='d-none'
                      />
                      <label htmlFor={`full-check-box-${index}`} className='cobrand-custom-checkbox d-none d-md-block mb-0 mt-1' onClick={() => handlePlatformClick(dateTimeObj.uuid)}>
                        {dateTimeObj.checked && <Image src={ImageChecked} alt="" className='d-block' />}
                      </label>
                      {dateTimeObj.checked ? (
                        <div className='d-flex flex-row align-items-center justify-content-center'>
                          {/* Dropdown for Hours */}
                          <Dropdown className="single-select-api-time">
                            <Dropdown.Toggle className="dropdown-btn" id="dropdown-basic">
                              <h6 className="lato-semibold fx-14 black-242">{dateTimeObj.timeHh.toString().padStart(2, '0') || '00'}</h6>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {Array.from({ length: 12 }, (_, i) => i).map((hour) => {
                                const displayHour = hour === 0 ? "00" : hour.toString().padStart(2, '0');
                                return (
                                  <Dropdown.Item
                                    key={hour}
                                    onClick={() => handleTimeChange({ target: { value: hour } }, dateTimeObj.uuid, 'timeHh')}
                                    className="lato-regular fx-14 black-242"

                                  >
                                    {displayHour}
                                  </Dropdown.Item>
                                );
                              })}
                              <Dropdown.Item
                                key={12}
                                className="lato-semibold fx-14 black-242"
                                onClick={() => handleTimeChange({ target: { value: 12 } }, dateTimeObj.uuid, 'timeHh')}
                              >
                                12
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          <p>:</p>
                          {/* Dropdown for Minutes */}
                          <Dropdown className="single-select-api-time">
                            <Dropdown.Toggle className="dropdown-btn" id="dropdown-basic">
                              <h6 className="lato-semibold fx-14 black-242">{dateTimeObj.timeMm.toString().padStart(2, '0') || '00'}</h6>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {Array.from({ length: 60 }, (_, i) => i).map((minute) => (
                                <Dropdown.Item
                                  key={minute}
                                  onClick={() => handleTimeChange({ target: { value: minute } }, dateTimeObj.uuid, 'timeMm')}
                                  className="lato-regular fx-14 black-242"
                                >
                                  {minute.toString().padStart(2, '0')}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      ) : (
                        <p className='lato-regular fs-14-12 black-242'>
                          {`${dateTimeObj?.timeHh || "00"}:${dateTimeObj?.timeMm || "00"}`}
                        </p>
                      )}
                      <div className='timings d-flex'>
                        <div className='period-container'>
                          <span
                            className={`lato-regular fs-14-12 period-name b-left`}
                            style={{ backgroundColor: dateTimeObj.period === "AM" ? "#A5F5DC" : "#ECECEC", borderRight: "1px solid black" }}
                            onClick={() => togglePeriod(dateTimeObj.uuid, "AM")}
                          >
                            AM
                          </span>
                          <div className='vertical-line'></div>
                          <span
                            className={`lato-regular fs-14-12 b-right`}
                            style={{ backgroundColor: dateTimeObj.period === "PM" ? "#A5F5DC" : "#ECECEC", borderleft: "1px solid black" }}
                            onClick={() => togglePeriod(dateTimeObj.uuid, "PM")}
                          >
                            PM
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>


        </div>
        {replaceData &&

          <div className={`post-details d-none d-md-flex flex-row${userDetails?.isCustomUploadAllowed === false && optionReplace != 'finpedia' ? 'disabled-div' : ''}`}>
            <p className="tag-planner-text lato-medium black-242 op-5">{replacePostData?.userPostPlanner?.playlist?.title || replaceData?.userPostPlanner?.playlist?.title} | Ends on {replaceData?.userPostPlanner?.endDate ? formatDate(replaceData?.userPostPlanner?.endDate) : formatDate(replaceData?.date)}</p>
            {replacePostData?.contentTypeName &&
              <p className="tag-planner-text lato-medium black-242 op-5 d-none d-md-flex">{replacePostData?.contentTypeName || ""}</p>}
          </div>
        }


        <div className={`text-area-frame mt-5 ${userDetails?.isCustomUploadAllowed === false && optionReplace != 'finpedia' ? 'disabled-div' : ''}`}>
          <textarea type="text" maxLength={120} className='fx-14 lato-normal black-242 op-8 text-area ' name='caption' value={caption} onChange={(e) => { handleCaptionChange(e) }} placeholder=' Enter Caption' />

        </div>
        {optionReplace != "finpedia" &&
          < div style={{ marginBottom: "10rem" }}>

          </div>
        }
        {optionReplace == "finpedia" &&
          <div className='select-post-details'>
            <h5 className='lato-bold fs-32-28 black-242 mt-5'>Select Post</h5>
            <div className="d-flex flex-row align-items-center justify-content-between select-post-date">
              {replaceData?.date ?

                <div className='d-flex flex-row'>
                  <Image src={CalenderIcon} />
                  <p className="lato-regular black-242 fs-14-11 mx-2">{formattedDate(replaceData?.date)}</p>

                </div>
                : ""}
            </div>

            <RadioFields
              options={socialData}

              initialValue={"same"}
              onSelectionChange={handleSelectRadio}

            />
          </div>
        }
        {data && optionReplace == "finpedia" &&
          <div className="">
            {isLoading ? (
              <AllShimmer type={type === "amc" ? null : type} />
            ) : (
              <SlideFilter
                dataLength={data && ((data?.contents?.length) ?? 0)}
                component={renderComponent()}
                isFilter={type === TARGETEDCONTENT ? false : true}
                isContainer={false}
                isBreadCrumb={false}
                setData={setData}
                selectedSocialMedia={selectedSocialMedia}
                isPostPlanner={true}
                updateFilter={(filters, contentType, searchDetails) => updateFilter(filters, contentType, searchDetails)}
              />
            )}

          </div>
        }

        <div className=' replace-post-btns fixed-btn d-flex justify-content-center align-content-center d-xl-none flex-row '>
          <Button style={{ marginRight: "2rem" }} onClick={handleCancel} className="secondary-btn ">Cancel</Button>
          <Button onClick={handleSave} disabled={isDisabled()}
            className="primary-btn">{isLoading ? <Spinner /> : "Save"}</Button>


        </div>
        <div className='mb-5'>

        </div>


      </div >
      <CustomModal
        show={modalOpen}
        onHide={handleModalClose}
        modalClass={"compliance-modal"}
      >
        <div className=" heading d-flex justify-content-between mb-4">
          <p className=" lato-bold fs-20-16 primary"> Compliance Undertaking</p>
          <Image src={CrossIcon} className='close-icon model-icon-close' alt='icon' style={{ cursor: "pointer" }} onClick={handleModalClose} />
        </div>
        <p className="lato-medium fx-12 black-242 op-5">Content provided by AMCs must remain unaltered, including their logos. Any modifications or misleading additions are strictly prohibited and may result in legal consequences. You will be solely responsible for any such actions; Thefinpedia and the AMC will not assume liability.</p>
        <br />
        <div className="d-flex flex-row"><p className="lato-medium fx-12 black-242 op-5">For details, please refer to the&nbsp;</p> <span onClick={() => handleTerms()} className="fx-12 lato-medium primary pointer"> Terms & Conditions.</span></div>



      </CustomModal >

      <SelectPositionModal openPosition={openPosition} setOpenPosition={setOpenPosition} cobrandingLabelling={cobrandingLabelling} setCobrandingLabelling={setCobrandingLabelling} />

      <CustomModal
        show={warning}
        onHide={handleClose}
        modalClass={"planner-heads-modal"}
      >
        <div className=" heading d-flex justify-content-between mb-4">
          <p className=" lato-bold fs-20-16 red "> Heads Up!</p>
          <Image src={CrossIcon} className='close-icon model-icon-close' alt='icon' style={{ cursor: "pointer" }} onClick={handleClose} />
        </div>
        <p className="lato-medium fs-18-16 black-242 ">You do not have sufficient content usage available! Want to keep the service flowing? Simply Top Up or Upgrade now.</p>
        <div className="d-flex justify-content-center">
          <Button onClick={handleNavigate} className="primary-btn">View Plans</Button>
        </div>
      </CustomModal>
    </>
  )



  function AmcDetailshead({ type, data }) {


    const [amcCardData, setAmcCardData] = useState([]);
    return (
      <div className="">
        <div className="amc-details-heading-wrapper">
          <div className="amc-details-profile-image">
            {type.toLowerCase() !== 'languages' && data?.circleImagePath &&
              <span className="amc-detail-profile dt-show">
                <Image
                  src={data?.circleImagePath || defaultIcon}
                  alt="profile-Image"
                  className="profile-Image"
                />
              </span>
            }

            <div>
              <h4 className=" lato-semibold fs-32-24 black-242">
                {type.toLowerCase() !== 'languages' && data?.circleImagePath &&
                  <span className="amc-profile-img-mb mb-inline-block">
                    <Image
                      src={data?.circleImagePath || defaultIcon}
                      alt="profile-Image"
                      className="profile-Image amc-profile-img-mb mr-3"
                    />
                  </span>
                }
                {data ? (data[type?.toLowerCase() + "Name"] || decodeURIComponent(socialData?.socialMediaName || socialData.categoryName).replace(/\w/, (a) => a.toUpperCase())) : decodeURIComponent(socialData?.socialMediaName || socialData?.categoryName || "Na").replace(/\w/, (a) => a.toUpperCase())}
              </h4>
              <div className="content-type-data">
                {Object.keys(data?.contentTypeStats || {})?.map(
                  (item, i) => {
                    if (item == 'totalContentCount') {
                      return ''
                    }
                    return (
                      <>
                        <p className=" lato-regular f-s-12 lh-16 black-242">
                          {item}{" "}
                          {formatNumber(data?.contentTypeStats[item])}
                        </p>
                        <span
                          className={`virtical-strip ${Object.keys(data?.contentTypeStats)
                            .length ===
                            i + 1 && "d-none"
                            }`}
                        ></span>
                      </>
                    );
                  }
                )}
              </div>
            </div>
          </div>
          {type.toLowerCase() === "campaign" && (
            <div className="download-share-icon ">
              <span className="icon-fram">
                <Image src={save2} alt="download icon" className="save-icon" />
              </span>
              <span className="icon-fram">
                <Image
                  src={BlackShare}
                  className="share-icon"
                  alt="download icon"
                />
              </span>
              <div className="primary-btn d-flex align-items-center">
                <Image src={BlackDownload} alt="download icon" />
                &nbsp; Download{" "}
              </div>
            </div>
          )}
        </div>


        <p className=" lato-regular fs-16-14 black-242 op-6">
          {data?.description || ""}
        </p>
        {data?.bannerPath &&
          <Image
            src={data?.bannerPath}
            alt="image"
            className="amc-details-main-image"
          />
        }

      </div>

    );
  }

}