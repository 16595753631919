import React from 'react'
import { useState, useEffect } from 'react';

import { BiSolidShareAlt } from "react-icons/bi";
import profile from '../../assets/images/markit-plan.png'
import eventImg from '../../assets/images/event-img.png';
import CalenderBorder from '../../assets/images/icon/calender-border-icon.svg';
import VenueIcon from "../../assets/images/icon/venue-icon.svg";
import speaker from '../../assets/images/speeker.svg';
import attendees from '../../assets/images/attendees.svg';
import onlineIcon from '../../assets/images/Online.png';
import inPerson from '../../assets/images/Union.png';
import { Button, Image, Modal } from 'react-bootstrap';
import { CustomModal, Footer, Header } from '../common/pages';

import { getCommonApi } from './Api';
import { useParams } from 'react-router-dom';
import moment from 'moment/moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { RWebShare } from 'react-web-share';
import { IS_ONLINE, SHARE_BASE_URL } from '../../utils/Constant';
import { getToken } from '../../utils/ManageToken';
import { SignUp, Spinner } from '../common/components';
import { BreadCrumb } from '../home/_utils';
import { handleEventRegistration, isEventExpired, isNonFinUser, isPremiumAccess, isResOk } from '../../utils/ReusableFunctions';
import { commonAuthApis } from '../profile/Api';
import calender from '../../assets/images/Calender_Icon.svg';
import AddToCalendar from '@culturehq/add-to-calendar';
import training from '../../assets/images/eventss-training.png';
import close from "../../assets/images/cancel-icon.svg";
import { commonAuthPostApi } from '../home/Api';
import CrossIcon from "../../assets/images/icon/cross-modal-icon.svg"
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */

export default function EventDetails() {
  const param = useParams();
  const history = useHistory()
  const [signup, setSignup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [detail, setDetail] = useState();
  const isPremiumAccessed = isPremiumAccess();
  const [confirm2, setConfirm2] = useState(false)
  const [warning, setWarning] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [loading, setLoading] = useState(false);


  const handleToggle = () => {
    setShowMore((prev) => !prev);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10); // 3 seconds delay


    return () => clearTimeout(timer);
  }, []);

  // const handleEventRegistration = async (data) => {
  //   setLoading(true);
  //   const subscription = {
  //     "data": {
  //       eventUuid: data?.uuid
  //     }
  //   }
  //   const check = await commonAuthPostApi(`website/events/check-subscription`, subscription)
  //     ;

  //   if ((check?.data?.limit > 0 && check?.data?.pending >= 0) || check?.data?.limit == 0) {
  //     if (data?.contentCreator?.isFinpediaUser) {
  //       history.push({ pathname: '/event-chekout', state: { detail: data } })
  //       setLoading(false);

  //     } else {
  //       // CALL API FOR REGISTER IN FIND DATA
  //       const info = {
  //         "data": {
  //           "eventUuid": data?.uuid
  //         }
  //       }
  //       const res = await commonAuthApis('/eventRegistration', info)
  //       // console.log(res)
  //       if (res?.status > 199 && res?.status < 240) {

  //         const registrationLink = data?.registrationLink;
  //         const validUrl = registrationLink && (registrationLink.startsWith('http://') || registrationLink.startsWith('https://'))
  //           ? registrationLink
  //           : `https://${registrationLink}`;

  //         window.open(validUrl || "", '_blank');
  //         setLoading(false);
  //       }
  //     }
  //   }
  //   else {
  //     setWarning(true);
  //     setLoading(false);
  //   }
  // }
  const registerHandler = async (data) => {
    if (detail?.isPremium) {
      if (!isPremiumAccessed) {

        setConfirm2(!confirm2);
        return;
      }
    }

    await handleEventRegistration(data, setLoading, setWarning, history);
  };

  const getDetailsData = async () => {
    const res = await getCommonApi('website/events/' + param.id)

    if (isResOk(res.status)) {
      setDetail(res?.data?.data)
      setIsLoading(false)
    } else {
      setIsLoading(false)

    }
  }
  const handleNavigate = () => {
    history.push('/mymembership')
  }
  const handleClose = (() => {
    setWarning(false);
  })
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    getDetailsData()
  }, [])
  // const isNonFinUser = async (data) => {
  //   // console.log("Yes this event is registered");
  //   setLoading(true)
  //   const subscription = {
  //     "data": {
  //       eventUuid: data?.uuid
  //     }
  //   }
  //   const check = await commonAuthPostApi(`website/events/check-subscription`, subscription)
  //     ;

  //   if ((check?.data?.limit > 0 && check?.data?.pending >= 0) || check?.data?.limit == 0) {


  //     if (!data?.contentCreator?.isFinpediaUser) {

  //       // console.log("and also not fin user");

  //       // Ensure the registrationLink has a protocol (http:// or https://)
  //       const registrationLink = data?.registrationLink;
  //       const validUrl = registrationLink && (registrationLink.startsWith('http://') || registrationLink.startsWith('https://'))
  //         ? registrationLink
  //         : `https://${registrationLink}`;

  //       window.open(validUrl || "", '_blank');
  //       setLoading(false);
  //     }
  //   }
  //   else {
  //     setWarning(true);
  //     setLoading(false)
  //   }
  // };
  return (
    <div className=' page-padding'>
      {signup && <SignUp show={signup} handleClose={() => setSignup(!signup)} />}
      <Header />
      {isLoading ?
        <EventdetailsShrmmer />
        : <>
          <BreadCrumb path={["Event", `${detail?.name}`]} clasese="mb-3-desk container" />
          <div className="container">
            <div className="event-details-header">
              <h3 className='lato-bold fs-32-28 black-242'>{detail?.name || ''}</h3>
              <div className="event-register-wrapper">
                <div className="w-100 mb-show">
                  <p className='lato-medium fx-14 black-242'> {detail?.amount ? "₹" + detail?.amount : "Free"}</p>
                </div>

                <span className="evet-addtocalender-cutom calender-dropDown-bottom">
                  <AddToCalendar
                    event={{
                      name: detail?.name || "Happy Hour",
                      details: detail?.description || "Let's go after work",
                      location: detail?.city || "Boston, MA",
                      startsAt: detail?.startDate || "2024-02-17T04:00:00.000Z",
                      endsAt: detail?.endDate || "2025-12-06T18:00:00-05:00"
                    }}
                    children={<Image src={calender} alt="icon" className="icon save-icon calender-icon" />
                    }

                  />
                </span>
                <RWebShare
                  data={{
                    text: "",
                    url: `${SHARE_BASE_URL + "events-details/" + detail?.uuid}`,
                    title: detail?.name,
                  }}
                // onClick={() =>{}}
                >
                  <div className="save-frame pointer">
                    <BiSolidShareAlt className="icon share-icon" />
                  </div>
                </RWebShare>

                <Button
                  disabled={
                    (detail.isRegistered) || loading || isEventExpired(detail?.endDate)
                  }
                  className={((detail.isRegistered) || isEventExpired(detail?.endDate)) ? "lato-medium fs-18-16 px-3 h-40 disable-btn" : "primary-btn pointer py-3"} onClick={!detail?.contentCreator?.isFinpediaUser ? (() => isNonFinUser(detail, setLoading, setWarning, history)) :
                    () => {
                      if (!getToken()) {
                        setSignup(true)
                      }
                      else { registerHandler(detail) }
                    }}>
                  {loading ? (
                    <span className=' d-flex align-items-center gap-12 justify-content-center'><Spinner /> Register...</span>
                  ) :
                    (detail?.isRegistered) ? 'Registered' : 'Register'}
                </Button>

              </div>
            </div>
          </div>
          <div className="event-info-wrapper">
            <div className="container">
              <div className="event-detail-profile-wrapper">
                <Image src={detail?.amc?.circleImagePath || detail?.contentCreator?.thumbnailPath || profile} alt='profile event' />
                <h6 className='lato-regular fs-24-18 black-242'>{detail?.amc?.amcName || detail?.contentCreator?.contentCreatorName}</h6>
              </div>
              <div className="event-details-poster ">
                {(IS_ONLINE?.includes(detail?.eventFormat?.toLowerCase())) ?
                  <div className="offline-icon-chip lato-regular fx-12">
                    <Image src={onlineIcon} alt='img' className='offline-icon' /> {detail?.eventFormat}
                  </div>
                  :
                  <div className="inperson-icon-chip lato-regular fx-12">
                    <Image src={inPerson} alt='img' className='offline-icon' /> {detail?.eventFormat}
                  </div>
                }
                <Image src={detail?.bannerPath || eventImg} alt='img' className='event-details-image' />
                <div className="attendees-frame lato-regular fs-14-13 black-242 ">
                  <Image src={attendees} alt='attendees icon' className='attendess-image' />
                  {detail?.totalSeats} Attendees
                </div>
              </div>
              <p className='lato-regular fs-16-13 black-242'><Image src={CalenderBorder} alt='image' className='mb-2' />&nbsp;    {moment(detail?.startDate).format("ddd, DD MMM | h:mm a")} - {moment(detail?.endDate).format("ddd, DD MMM | h:mm a")}</p>
              {!(IS_ONLINE?.includes(detail?.eventFormat)) &&
                < div className='d-flex flex-row align-items-start'>

                  <Image src={VenueIcon} alt='image' className='mx-1 mt-md-1 mt-0' />&nbsp; &nbsp;
                  <p className='lato-regular fs-16-13 black-242   '> Venue : <span className='text-center'>{detail?.venueName} | {detail?.address}</span></p>
                </div>}
            </div>
          </div>
          <div className="speker-section">
            <div className="container">
              <div className='d-flex flex-row justify-content-between'>
                <h6 className='lato-regular fs-24-18 black-242 m-0'>Speakers</h6>
                {detail?.eventSpeakers?.length > 3 && (
                  <p
                    style={{ textDecoration: "underline" }}
                    onClick={handleToggle}
                    className="fs-14-12 lato-medium black-242 pointer d-none d-md-flex"
                  >
                    {showMore ? 'show less' : 'show more'}
                  </p>
                )}
                {detail?.eventSpeakers?.length > 2 && (
                  <p
                    style={{ textDecoration: "underline" }}
                    onClick={handleToggle}
                    className="fs-14-12 lato-medium black-242 pointer d-flex d-md-none"
                  >
                    {showMore ? 'show less' : 'show more'}
                  </p>
                )}
              </div>
              <div className={`speaker-card-frame ${showMore ? "flex-wrap" : ""}`}>
                {detail?.eventSpeakers?.map((item, i) => {
                  return (
                    <div key={i} className="speaker-box">
                      <Image src={item?.profilePicture || speaker} alt='speakerImg' className='mx-auto desk-show obj-cover' />
                      <div className="speaker-card-profile ">
                        <Image src={item?.profilePicture || speaker} alt='speakerImg' className='mx-auto mb-show obj-cover ' />
                        <div className="" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                          <h5 className='lato-regular fs-16-12 w-100 text-center ellipsis'>{item?.speakerName || ""}</h5>
                        </div>
                      </div>
                      <p className='lato-regular fx-14 light-black ellipsis-5 text-justify'>{item?.description || ''}
                      </p>
                      {item?.description.length > 500 &&
                        <p className='lato-regular fx-14 primary underline text-start pointer'>read more</p>
                      }
                      <a href={item?.link} target='_blank' className='lato-regular underline fs-16-12 w-100 text-center'>Let's Connect</a>
                    </div>
                  )
                })
                }

              </div>
              <h6 className='lato-regular fs-24-18 black-242 m-0'>About the event</h6>
              {/* <p className='lato-regular fs-16-14 light-black my-3'>{detail?.description}
              </p> */}
              <div className="new-event-card-body" dangerouslySetInnerHTML={{ __html: detail?.description }}>
              </div>
              <div className='event-keys-wrapper d-flex gap-12 align-items-center py-3'>
                {
                  detail?.keywords?.split(',').map((item, i) => {
                    return (
                      <div className='key-words-frame lato-regular fs-14-13 black-242'>{item}</div>
                    )
                  })
                }
              </div>

            </div>
          </div>
          <Footer />
          {!isEventExpired(detail?.endDate) &&
            <div className="sticky-event-detailse-footer desk-show">
              <div className="container">
                <div className="sticky-frame-footer py-2">
                  <div className="w-100 ">
                    <h3 className='lato-bold fs-24-14 black-242'>{detail?.name || ''}</h3>
                    <p className='lato-regular fs-14-12 black-242'> {moment(detail?.startDate).format("lll") || ''}</p>
                    <p className='lato-regular fs-14-12 black-242 '><a rel="noreferrer" target='_blank' className='lato-regular fs-16-13 black-242 text-decoration-none' href={detail?.venueMapLink}> Venue : {detail?.venueName} {detail?.city && `(${detail.city})` || ''}</a></p>
                  </div>
                  <div className="stiky-footer-rifht">
                    <p className="lato-bold fs-24-14 black-242">
                      {detail?.amount ? `₹ ${detail.amount}` : "Free"}
                    </p>

                    <div className=" d-flex align-items-center gap-12 pt-2">

                      <span className="evet-addtocalender-cutom">
                        <AddToCalendar
                          event={{
                            name: detail?.name || "Happy Hour",
                            details: detail?.description || "Let's go after work",
                            location: detail?.city || "Boston, MA",
                            startsAt: detail?.startDate || "2024-02-17T04:00:00.000Z",
                            endsAt: detail?.endDate || "2025-12-06T18:00:00-05:00"
                          }}
                          children={<Image src={calender} alt="icon" className="icon save-icon calender-icon" />
                          }

                        />
                      </span>
                      <RWebShare
                        data={{
                          text: "",
                          url: `${SHARE_BASE_URL + "events-details/" + detail?.uuid}`,
                          title: detail?.name,
                        }}
                      // onClick={() =>{}}
                      >
                        <div className="save-frame pointer">
                          <BiSolidShareAlt className="icon share-icon" />
                        </div>
                      </RWebShare>

                      <Button
                        disabled={
                          (detail?.isRegistered) || loading || isEventExpired(detail?.endDate)
                        }
                        className={((detail?.isRegistered) || isEventExpired(detail?.endDate)) ? "lato-medium fs-18-16 px-3 h-40 disable-btn" : "primary-btn pointer py-3"} onClick={!detail?.contentCreator?.isFinpediaUser ? (() => isNonFinUser(detail, setLoading, setWarning, history)) :
                          () => {
                            if (!getToken()) {
                              setSignup(true)
                            }
                            else { registerHandler(detail) }
                          }}>
                        {loading ? (
                          <span className=' d-flex align-items-center gap-12 justify-content-center'><Spinner /> Register...</span>
                        ) :
                          (detail?.isRegistered) ? 'Registered' : 'Register'}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </>}
      {
        confirm2 &&
        <Modal className="save-confirm-collection-custom-model" centered show={confirm2} onHide={() => setConfirm2(!confirm2)}>

          <Modal.Body className="save-confirm-collection-model-body text-center">
            <Image src={close} onClick={() => setConfirm2(!confirm2)} alt="canle icon" className="close-btn pointer" />
            <Image src={training} className="mb-10" />
            <h4 className="lato-regular fs-24-16 black-242 w-100 text-center">This event is available only to the subscribed members. Want to be a part of this <span className="primary"> premium community</span>? </h4>
            <div className="d-flex justify-content-center ">
              <div className={`primary-btn py-3 pointer`} onClick={() => { history.push('/mymembership') }}>View Membership Plans</div>
            </div>
          </Modal.Body>
        </Modal>
      }
      <CustomModal
        show={warning}
        onHide={handleClose}
        modalClass={"planner-heads-modal"}
      >
        <div className=" heading d-flex justify-content-between mb-4">
          <p className=" lato-bold fs-20-16 red "> Heads Up!</p>
          <Image src={CrossIcon} className='close-icon model-icon-close' alt='icon' style={{ cursor: "pointer" }} onClick={handleClose} />
        </div>
        <p className="lato-medium fs-18-16 black-242 ">You’ve reached your {detail?.isPremium ? "premium" : "free"} event access limit! For more {detail?.isPremium ? "premium" : "free"}  events, <span onClick={handleNavigate} className="lato-medium fs-18-16 primary pointer">Upgrade now.</span></p>

      </CustomModal>

    </div>
  )
}


function EventdetailsShrmmer() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [])
  return (
    <div className="container">
      <div className=" d-flex align-items-center justify-content-between m-4">
        <div className="shimmer-container lh-40 w-50 ">&nbsp;</div>
        <div className="shimmer-container lh-30 mw-101 ">&nbsp;</div>
      </div>
      <div className="description-box">
        <div className="des-profile desk-show shimmer-container"></div>
        <div className="des-content-wrappper">
          <p className=" fs-16-14 w-100 lato-regular h-auto black-242 shimmer-container"> &nbsp;</p>
        </div>
      </div>
      <div className="banner-shimmer my-3 shimmer-container"></div>

      <div className="shimmer-container lh-28 w-50 mt-3 ">&nbsp;</div>
      <div className="shimmer-container lh-28 w-25 mt-2 mb-5">&nbsp;</div>
      <div className="shimmer-container lh-28 mw-151 my-5 ">&nbsp;</div>

      <div className="overflow-hidden d-flex align-content-center gap-12">
        <div className="event-shimmer-card">
          <div className="image-circle shimmer-container">

          </div>
          <div className="shimmer-container lh-28 w-75  ">&nbsp;</div>
          <div className="shimmer-container lh-28 mw-151  ">&nbsp;</div>
        </div>
        <div className="event-shimmer-card">
          <div className="image-circle shimmer-container">

          </div>
          <div className="shimmer-container lh-28 w-75  ">&nbsp;</div>
          <div className="shimmer-container lh-28 mw-151  ">&nbsp;</div>
        </div>
        <div className="event-shimmer-card">
          <div className="image-circle shimmer-container">

          </div>
          <div className="shimmer-container lh-28 w-75  ">&nbsp;</div>
          <div className="shimmer-container lh-28 mw-151  ">&nbsp;</div>
        </div>

      </div>
      <div className="shimmer-container lh-28 mw-151 my-5 ">&nbsp;</div>
      <div className="shimmer-container lh-16 w-100  mt-3">&nbsp;</div>
      <div className="shimmer-container lh-16 w-100  mt-2">&nbsp;</div>
      <div className="shimmer-container lh-16 w-50  mt-2">&nbsp;</div>

      <div className=" d-flex gap-12 overflow-hidden">
        <div className="shimmer-container lh-28 mw-151 my-5 ">&nbsp;</div>
        <div className="shimmer-container lh-28 mw-151 my-5 ">&nbsp;</div>
        <div className="shimmer-container lh-28 mw-151 my-5 ">&nbsp;</div>
      </div>
    </div>
  )

}