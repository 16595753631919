import React, { useState } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import { BiSolidShareAlt } from "react-icons/bi";
import eventImg from '../../assets/images/event-img.png';
import paidIcon from '../../assets/images/paid-icon.svg';
import calender from '../../assets/images/Calender_Icon.svg';
import onlineIcon from '../../assets/images/Online.png';
import inPerson from '../../assets/images/Union.png';
import prenium from '../../assets/images/tags.svg';
import ads from '../../assets/images/ads.svg';
import userGroup from '../../assets/images/userGroup.svg';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment/moment";
import { SignUp, Spinner } from "../common/components";
import { getToken } from "../../utils/ManageToken";
import { RWebShare } from "react-web-share";
import { IS_ONLINE, SHARE_BASE_URL } from "../../utils/Constant";
import { handleEventRegistration, isEventExpired, isNonFinUser, isPremiumAccess } from "../../utils/ReusableFunctions";
import { commonAuthApis } from "../profile/Api";
import AddToCalendar from '@culturehq/add-to-calendar';
import training from '../../assets/images/eventss-training.png';
import close from "../../assets/images/cancel-icon.svg";
import { CustomModal } from "../common/pages";
import CrossIcon from "../../assets/images/icon/cross-modal-icon.svg"
import { commonAuthPostApi } from "../home/Api";

export default function EventCard({ data, classes, registered = false }) {

  const history = useHistory()
  const [signup, setSignup] = useState(false);
  const isPremiumAccessed = isPremiumAccess();
  const [confirm2, setConfirm2] = useState(false)
  const [warning, setWarning] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleNavigate = () => {
    history.push('/mymembership')
  }
  const handleClose = (() => {
    setWarning(false);
  })

  // const handleEventRegistration = async (data) => {
  //   setLoading(true)
  //   const subscription = {
  //     "data": {
  //       eventUuid: data?.uuid
  //     }
  //   }
  //   const check = await commonAuthPostApi(`website/events/check-subscription`, subscription)
  //     ;

  //   if ((check?.data?.limit > 0 && check?.data?.pending >= 0) || check?.data?.limit == 0) {

  //     if (data?.contentCreator?.isFinpediaUser) {
  //       history.push({ pathname: '/event-chekout', state: { detail: data } })
  //       setLoading(false)
  //     } else {
  //       // CALL API FOR REGISTER IN FIND DATA
  //       const info = {
  //         "data": {
  //           "eventUuid": data?.uuid
  //         }
  //       }

  //       const res = await commonAuthApis('/eventRegistration', info)

  //       if (res.status > 199 && res.status < 240) {
  //         const registrationLink = data?.registrationLink;
  //         const validUrl = registrationLink && (registrationLink.startsWith('http://') || registrationLink.startsWith('https://'))
  //           ? registrationLink
  //           : `https://${registrationLink}`;

  //         window.open(validUrl || "", '_blank');
  //         setLoading(false)
  //       }
  //     }
  //   }
  //   else {
  //     setWarning(true)
  //     setLoading(false)
  //   }
  // }

  const registerHandler = async (data) => {
    if (data?.isPremium) {
      if (!isPremiumAccessed) {

        setConfirm2(!confirm2);
        return;
      }
    }

    await handleEventRegistration(data, setLoading, setWarning, history);
  };
  // const isNonFinUser = async (id) => {

  //   setLoading(true)
  //   const subscription = {
  //     "data": {
  //       eventUuid: id
  //     }
  //   }
  //   const check = await commonAuthPostApi(`website/events/check-subscription`, subscription)
  //     ;

  //   if ((check?.data?.limit > 0 && check?.data?.pending >= 0) || check?.data?.limit == 0) {
  //     if (!data?.contentCreator?.isFinpediaUser) {
  //       // Ensure the registrationLink has a protocol (http:// or https://)
  //       const registrationLink = data?.registrationLink;
  //       const validUrl = registrationLink && (registrationLink.startsWith('http://') || registrationLink.startsWith('https://'))
  //         ? registrationLink
  //         : `https://${registrationLink}`;

  //       window.open(validUrl || "", '_blank');
  //       setLoading(false);
  //     }
  //   }
  //   else {
  //     setWarning(true);
  //     setLoading(false);
  //   }
  // };
  return (
    <div className={`event-card pointer ${classes}`} >
      <div className="event-image-box">
        <div className="event-mode" onClick={() => (window.location.href = "./events-details/" + data.uuid)}
        >
          <div>
            {(IS_ONLINE?.includes(data?.eventFormat?.toLowerCase())) ?
              <div className="offline-icon-chip lato-regular fx-12">
                <Image src={onlineIcon} alt='img' className='offline-icon' /> {data?.eventFormat}
              </div>
              :
              <div className="inperson-icon-chip lato-regular fx-12">
                <Image src={inPerson} alt='img' className='offline-icon' /> {data?.eventFormat}
              </div>
            }

            {/* {true && */}

          </div>
          <div>
            {data?.isAd &&
              <Image src={ads} alt="mode" className="ads-icon mx-2" />
            }
            {data?.isPremium &&
              <Image src={prenium} alt="mode" className="prenium" />
            }
          </div>
        </div>
        <span className="thumbnail-imag-framez" onClick={() => (window.location.href = "./events-details/" + data.uuid)}
        >
          <Image src={data?.thumbnailPath || eventImg} className="bg-event-image" alt="" />
        </span>
        <div className="event-actions-frame">
          <div className="event-attend">
            <Image src={userGroup} alt="usergroup" className="desk-show" />
            {data?.totalSeats && <span className="lato-semibold fs-14-11 desk-show">{data?.totalSeats} | </span>}
            {/* {data?.eventType > 0 && <> */}
            <Image src={paidIcon} alt="" />{" "}
            <span className="lato-semibold fs-14-11 ">{data?.eventType?.toUpperCase()}</span>
            {/* </>} */}
          </div>

          <span className="evet-addtocalender-cutom mb-show">
            <AddToCalendar
              event={{
                name: data?.name || "Happy Hour",
                details: data?.description || "Let's go after work",
                location: data?.city || "Boston, MA",
                startsAt: data?.startDate || "2024-02-17T04:00:00.000Z",
                endsAt: data?.endDate || "2025-12-06T18:00:00-05:00"
              }}
              children={<Image src={calender} alt="icon" className="icon save-icon calender-icon" />
              }

            />
          </span>
          <div className="event-rggister desk-show">
            <span className="evet-addtocalender-cutom">
              <AddToCalendar
                event={{
                  name: data?.name || "Happy Hour",
                  details: data?.description || "Let's go after work",
                  location: data?.city || "Boston, MA",
                  startsAt: data?.startDate || "2024-02-17T04:00:00.000Z",
                  endsAt: data?.endDate || "2025-12-06T18:00:00-05:00"
                }}
                children={<Image src={calender} alt="icon" className="icon save-icon calender-icon" />
                }

              />
            </span>
            <RWebShare
              data={{
                text: "",
                url: `${SHARE_BASE_URL + "events-details/" + data?.uuid}`,
                title: data?.name,
              }}

            >
              <span className="save-frame">
                <BiSolidShareAlt className="icon share-icon" />
              </span>
            </RWebShare>
            {registered ?
              <Button onClick={() => { }} className={"lato-medium fs-18-16 disable-btn"}>Registered</Button>
              :
              <Button
                disabled={
                  (data.isRegistered) || loading || isEventExpired(data?.endDate)
                } onClick={!data?.contentCreator?.isFinpediaUser ? () => { isNonFinUser(data, setLoading, setWarning, history) } : () => { if (!getToken()) { setSignup(true) } else { registerHandler(data) } }} className={((data.isRegistered) || isEventExpired(data?.endDate)) ? "lato-medium fs-18-16 disable-btn" : "primary-btn"}> {loading ? (
                  <span className=' d-flex align-items-center gap-12 justify-content-center'><Spinner /> Register...</span>
                ) : (
                  (data.isRegistered) ? "Registered" : "Register"
                )}</Button>
            }
          </div>
        </div>
      </div>
      <div className="event-content-frame" onClick={() => (window.location.href = "./events-details/" + data.uuid)}
      >
        <p className="lato-semibole fs-14-11 black-242">
          {moment(data?.startDate).format("ddd, DD MMM | h:mm a")} - {moment(data?.endDate).format("ddd, DD MMM | h:mm a")}
        </p>
        <h5 className="lato-bold fs-18-14 black-242">{data?.name}</h5>
        <p className="lato-semibole fs-14-11 black-242">Organised by &nbsp;{data?.amc?.amcName || data?.contentCreator?.contentCreatorName}</p>
        <p className="speaker-names lato-semibole fs-14-11 black-242">Speakers- &nbsp;{(data?.eventSpeakers?.map((item, i) => item?.speakerName))?.join(', ')}</p>
        {data?.eventFormat !== "ONLINE" ? <p className="lato-semibole fs-14-11 black-242">Venue :- {data?.venueName} {data?.city && `(${data.city})`}</p> : <br />}
      </div >
      {signup && <SignUp show={signup} handleClose={() => setSignup(!signup)} />
      }

      {
        confirm2 &&
        <Modal className="save-confirm-collection-custom-model" show={confirm2} onHide={() => setConfirm2(!confirm2)}>

          <Modal.Body className="save-confirm-collection-model-body text-center">
            <Image src={close} onClick={() => setConfirm2(!confirm2)} alt="canle icon" className="close-btn pointer" />
            <Image src={training} className="mb-10" />
            <h4 className="lato-regular fs-24-16 black-242 w-100 text-center">This event is available only to the subscribed members. Want to be a part of this <span className="primary"> premium community</span>? </h4>
            <div className="d-flex justify-content-center ">
              <div className={`primary-btn py-3 pointer`} onClick={() => { history.push('/mymembership') }}>View Membership Plans</div>
            </div>
          </Modal.Body>
        </Modal>
      }
      <CustomModal
        show={warning}
        onHide={handleClose}
        modalClass={"planner-heads-modal"}
      >
        <div className=" heading d-flex justify-content-between mb-4">
          <p className=" lato-bold fs-20-16 red "> Heads Up!</p>
          <Image src={CrossIcon} className='close-icon model-icon-close' alt='icon' style={{ cursor: "pointer" }} onClick={handleClose} />
        </div>
        <p className="lato-medium fs-18-16 black-242 ">You’ve reached your {data?.isPremium ? "premium" : "free"} event access limit! For more {data?.isPremium ? "premium" : "free"} events, <span onClick={handleNavigate} className="lato-medium fs-18-16 primary pointer">Upgrade now.</span></p>

      </CustomModal>
    </div >
  );
}
