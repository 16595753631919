import { toast } from "react-toastify";
import { postLoginInstance, preLoginInstance } from "../../utils";

export const getPlaylistApi = async (url) => {
  try {
    const response = await postLoginInstance.get(url);
    const data = response?.data;
    return data;
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong")
    return [];
  }
};

export const getColorApi = async (url) => {
  try {

    const response = await postLoginInstance.get(url);
    const data = response?.data;

    return data;
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong")
    return [];
  }
};

export const getLanguageApi = async (url) => {
  try {

    const response = await postLoginInstance.get(url);
    const data = response?.data;
    return data;
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong")
    return [];
  }
};

export const commonPostApi = async (url, data, method = 'post') => {
  try {
    const response = await preLoginInstance[method](url, data);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong")
    throw error;
  }
};

export const deletePostApi = async (url) => {
  try {
    const response = await postLoginInstance.delete(url);

    if (response?.status < 250 && response?.status > 199) {
      toast.success("Successfully Deleted.")
    }
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
  }
}

export const putAuthCommonApi = async (url, data) => {
  try {
    const response = await postLoginInstance.put(url, data);
    if (response?.status < 250 && response?.status > 199) {
      toast.success("Successfully Updated.")
    }
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.message || 'Something went wrong.');
    return false;
  }
}
export const scheduleAuthPostApi = async (url, data) => {

  try {

    const response = await postLoginInstance.post(url, data);
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong")

    return error.response;
    // throw error;
  }
};
