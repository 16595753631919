import PropTypes from "prop-types";
import React from "react";
import { Button, Col, Image, Modal, Row } from 'react-bootstrap';
import CrossIcon from "../../assets/images/icon/cross-modal-icon.svg";
import ImageChecked from "../../assets/images/icon/tick-icon.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment-timezone';
import { getMinDate } from './_utilis/platformsTime';
import DeletePlanIcon from "../../assets/images/icon/delete-plan-icon.svg";
import LockIcon from "../../assets/images/icon/lock-icon.svg"
import {
  BaseReactComponent,
  CustomTextControl,
  Form,
  FormElement,
  FormValidator,
  SelectControl
} from "../../utils/form";
import { toast } from "react-toastify";
import { getPlaylistApi } from "./Api";
import { commonAuthPostApi, getAuthDataApi } from "../home/Api";
import { isResOk } from "../../utils/ReusableFunctions";
import { isCurrentDateAllowed, MAX_LIMIT, START_PAGE, uploadDuration, uploadFrequency, uploadFrequencyManual } from "../../utils/Constant";
import { Spinner } from "../common/components";
import { commonAuthApis } from "../profile/Api";
import { getToken } from "../../utils/ManageToken";
import DeletePostModal from "./DeletePostModal";

class CreatePlannerModal extends BaseReactComponent {
  constructor(props) {
    super(props);
    const search = props.location ? props.location.search : "";
    const params = new URLSearchParams(search);
    const editData = this.props.editDetail;
    const user = this.props.userDetails;
    const orderedLabels = ["Daily", "Weekly", "Bi-Weekly", "Fortnightly", "As and When"];

    this.state = {
      formData: {
        uuid: editData?.uuid || "",
        planName: editData?.title || "",
        frequency: editData?.uploadFrequency || "",
        duration: "",
        contentTypes: [],
        platforms: editData?.platforms && editData?.platforms?.map((p) => p?.platform?.uuid) || []
      },

      validated: false,
      frequencyOption: uploadFrequency
        .sort((a, b) => orderedLabels.indexOf(a.label) - orderedLabels.indexOf(b.label))
        .map(option => ({
          value: option.value,
          label: option.label
        })),
      frequencyOptionManual: uploadFrequencyManual
        .sort((a, b) => orderedLabels.indexOf(a.label) - orderedLabels.indexOf(b.label))
        .map(option => ({
          value: option.value,
          label: option.label
        })),
      durationOption: Object.keys(uploadDuration).map(key => ({ label: uploadDuration[key], value: Number(key) })),
      checklist: [],
      maxDate: new Date(),
      selectedItems: editData?.contentTypes || [],
      platforms: this.props.feeds,
      startDate: editData?.userPostPlanner?.startDate
        ? new Date(editData.userPostPlanner.startDate)
        : editData?.startDate
          ? new Date(editData.startDate)
          : "",
      endDate: editData?.userPostPlanner?.endDate
        ? new Date(editData.userPostPlanner.endDate)
        : editData?.endDate
          ? new Date(editData.endDate)
          : "",
      editDetail: this.props.editDetail,
      maximumEndDate: "",
      isMobile: window.innerWidth <= 768,
      isLoading: false,
      isComplaince: true,
      customizationType: editData?.isManual ? "manual" : "auto",
      showDelete: false,
      isManual: editData?.isManual,
      user: this.props.userDetails

    };


    this.feed = [

    ];
  }
  convertedDate = (date) => {
    const isodate = new Date(date);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = isodate.toLocaleDateString('en-GB', options);

    return formattedDate;
  };

  handleChange = (field, value) => {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [field]: value
      }
    }), this.validateForm);
  };

  handleCheckboxChange = (field, value) => {
    this.setState(prevState => {
      const currentValues = prevState.formData[field];
      const newValues = currentValues.includes(value)
        ? currentValues.filter(v => v !== value)
        : [...currentValues, value];
      return {
        formData: {
          ...prevState.formData,
          [field]: newValues
        }
      };
    }, this.validateForm);
  };

  handleItemCheckboxChange = item => {
    this.setState(prevState => {
      const selectedItems = prevState.selectedItems.some(selected => selected.uuid === item.uuid)
        ? prevState.selectedItems.filter(selected => selected.uuid !== item.uuid)
        : [...prevState.selectedItems, item];
      return { selectedItems };
    });
  };

  handlePlatformCheckboxChange = platform => {
    this.setState(prevState => {
      const selectedPlatforms = prevState.formData.platforms.includes(platform.uuid)
        ? prevState.formData.platforms.filter(uuid => uuid !== platform.uuid)
        : [...prevState.formData.platforms, platform.uuid];


      return {
        formData: {
          ...prevState.formData,
          platforms: selectedPlatforms
        }
      };
    });
  };
  getAllowedDate = async () => {
    const res = await getAuthDataApi("website/userPostPlanner/allowedDate");
    if (res) {
      this.setState({ maximumEndDate: res?.endDate })
    }

  }

  validateForm = () => {
    const { formData } = this.state;
    const allFieldsFilled = formData.planName && formData.frequency && formData.duration;
    this.setState({ validated: allFieldsFilled });
  };
  handleClose = (() => {
    this.props.setOpen(false);
    if (!this.state.editDetail) {
      this.setState({
        formData: {
          planName: "",
          frequency: "",
          duration: "",
          platforms: [],
        },
        selectedItems: [],
        startDate: "",
        endDate: "",
        isComplaince: false
      });
    }

  });

  handleCheckboxChange = (e) => {
    e.preventDefault();
    this.setState({ isComplaince: !this.state.isComplaince });
  };

  handleUpgrade = (e) => {
    e.preventDefault();
    window.location.href = "/mymembership";
  };


  handleSubmit = async () => {
    const { formData, selectedItems } = this.state;

    const plannerData = {
      data: {
        title: formData?.planName,
        uploadFrequency: formData?.frequency,
        platforms: formData?.platforms?.map(uuid => ({ uuid })),
        startDate: moment(this.state.startDate).tz('Asia/Kolkata').format('YYYY-MM-DDTHH:mm:ssZ'),
        endDate: moment(this.state.endDate).tz('Asia/Kolkata').format('YYYY-MM-DDTHH:mm:ssZ'),
        isManual: this.state.customizationType === "manual", // Add the isManual key
        ...(this.state.customizationType !== "manual" && {
          contentTypes: selectedItems?.map(item => ({
            uuid: item?.uuid,
            ...(item?.socialMediaName
              ? { isSocialMedia: true }
              : item?.categoryName
                ? { isCategory: true }
                : {})
          }))
        })
      }
    };

    const editPostData = {
      "data": {
        "playlist": {
          "uuid": formData.uuid,
          "title": formData.planName,
          "uploadFrequency": formData.frequency,
          "isManual": this.state.customizationType === "manual", // Add the isManual key
          "startDate": moment(this.state.startDate).tz('Asia/Kolkata').format('YYYY-MM-DDTHH:mm:ssZ'),
          "endDate": moment(this.state.endDate).tz('Asia/Kolkata').format('YYYY-MM-DDTHH:mm:ssZ'),
          "platforms": formData?.platforms?.map(uuid => ({ uuid })),
          ...(this.state.customizationType !== "manual" && {

            "contentTypes": selectedItems.map(item => ({
              uuid: item.uuid,
              ...(item.socialMediaName
                ? { isSocialMedia: true }
                : item.categoryName
                  ? { isCategory: true }
                  : {})
            })),
          })
        },

      }
    };
    const data = new FormData();
    data.append("key", JSON.stringify(plannerData || editPostData)); // Corrected this line to ensure plannerData is sent correctly

    try {

      if (this.state.editDetail) {
        this.setState({ isLoading: true })
        if (this.state.editDetail?.userPostPlanner) {
          const checkEditData = {
            "data": {
              "playlists": [{

                uuid: editPostData?.data?.playlist?.uuid,
                startDate: editPostData?.data?.startDate,
                endDate: editPostData?.data?.endDate
              }]
            }
          }

          const check = await commonAuthPostApi(`website/userPostPlanner/checkSubscription`, checkEditData);

          if (check?.data?.pending >= 0) {
            const res = await commonAuthApis(`website/userPostPlanner/${this.state.editDetail?.userPostPlanner?.uuid}`, editPostData, "put");
            if (res) {

              toast.success("Updated Successfully");
              const data = await getPlaylistApi(`website/playlist?pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}`);
              if (data) {
                this.props.setPlaylistData(data?.data?.list);
              }
              this.props.setOpen(false);
              this.handleClose();
            }
            else {
              toast.error("Failed to update plan");
            }
            this.props.setOpen(false);
            this.handleClose();
          }
          else {
            toast.error("Failed to update plan");
          }
        }

        else {


          const res = await commonAuthApis(`website/playlist/${this.state.editDetail?.uuid}`, plannerData, "put");


          if (isResOk(res?.status)) {

            toast.success("Updated Successfully");
            const data = await getPlaylistApi(`website/playlist?pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}`);
            if (data) {
              this.props.setPlaylistData(data?.data?.list);
            }
            this.props.setOpen(false);
            this.handleClose();
          }
          else {
            toast.error("Failed to update plan");
          }


        }

      }
      else {
        const response = await commonAuthPostApi(`website/playlist`, plannerData, 'put');
        if (response?.data) {
          toast.success("Added Successfully");
          this.props.setOpen(false);
          this.handleClose();


        } else {
          toast.error("Failed to add plan");
        }
      }
    } catch (error) {
      toast.error("An error occurred while adding the plan");
      console.error("API Error:", error);
    } finally {
      this.setState({ isLoading: false })
    }
  };

  fetchData = async () => {
    try {
      const response = await getAuthDataApi("website/playlist/content-types");

      if (response?.list) {
        this.setState({
          checklist: response.list || [],
        });

        if (this.state?.editDetail?.contentTypes) {

          const editContentTypesUuids = new Set(this.state?.editDetail?.contentTypes?.map(item => item.uuid));
          const selectedItems = response.list.filter(item => editContentTypesUuids.has(item.uuid));

          // Set the selectedItems state
          this.setState({
            selectedItems: selectedItems || [],
          });
        }
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  handleDeletePlan = () => {
    this.setState({ showDelete: true })
  }

  onHide = () => {
    this.setState({ showDelete: false })
  }

  componentDidMount() {
    const token = getToken();
    if (token) {
      this.fetchData();
      this.getAllowedDate();
    }
    // this.fetchPlatforms();
  }

  render() {
    const { formData, validated, checklist, selectedItems, platforms } = this.state;
    const { editDetail, maximumEndDate, isMobile, isComplaince, user } = this.state;

    const isButtonDisabled = !(
      formData.planName &&
      formData.frequency &&
      this.state.startDate &&
      this.state.endDate &&
      this.state.isComplaince &&
      formData.platforms.length > 0 &&
      (this.state.customizationType === "manual" || this.state.selectedItems.length > 0)
    );


    return (
      <Modal
        size="lg"
        className="planner-custom-model"
        show={this.props.open}

        onHide={() => {
          this.handleClose();

        }}
      >
        <Modal.Body className="create-planner-body">
          <div className="d-flex flex-row justify-content-between ">
            <p className=" lato-medium fx-24 black-242">{editDetail ? "Edit Playlist" : "Create Customized Plan"}</p>
            <div onClick={() => { this.handleClose() }} className="">
              <Image className="create-cross-icon mt-2 mt-md-0" src={CrossIcon} alt="image" />
            </div>
          </div>
          <Form onValidSubmit={this.handleSubmit}>
            <Row>
              <Col md={6}>
                <FormElement
                  valueLink={this.linkState(this, "formData.planName")}
                  label="Plan Name"
                  validations={[
                    {
                      validate: FormValidator.isRequired,
                      message: "Field cannot be empty"
                    }
                  ]}
                  control={{
                    type: CustomTextControl,
                    onChangeCallback: value => this.handleChange("planName", value)
                  }}
                />
              </Col>
              <Col md={6}></Col>

              <Col md={12} className="customization">
                <h4 className="lato-bold fx-18 black-242 py-0 ">Types of Customization</h4>

                <div className="mb-3 d-flex flex-column flex-md-row ">
                  <label className=" d-flex flex-row mb-md-0 mb-4">
                    <input
                      type="radio"
                      name="customizationType"
                      value="auto"
                      checked={this.state.customizationType === 'auto'}
                      onChange={(e) => this.setState({ customizationType: e.target.value })}
                      className="custom-radio"

                    />
                    <h5 className="auto">
                      <p className="lato-medium fx-14 black-242">Auto <span className="lato-regular fx-12 black-4">(Thefinpedia curated content)</span></p>
                    </h5>
                  </label>

                  <label className=" d-flex flex-row ">
                    <input
                      type="radio"
                      name="customizationType"
                      value="manual"
                      checked={this.state.customizationType === 'manual'}
                      onChange={(e) => this.setState({ customizationType: e.target.value })}
                      className="custom-radio"
                      disabled={user?.isCustomUploadAllowed === false}
                      style={{
                        cursor: user?.isCustomUploadAllowed === false ? 'not-allowed' : 'pointer'
                      }}
                    />
                    <div className="auto d-flex flex-row mb-0">
                      {/* <div className="d-flex flex-row flex-nowrap"> */}

                      <p className="lato-medium fx-14 black-242 d-flex flex-row ">Manual </p> <span className="lato-regular fx-12  black-4 flex-nowrap"> (Custom Upload)</span>
                      {user?.isCustomUploadAllowed === false &&
                        <div className="d-flex flex-row  ">
                          <Image src={LockIcon} alt="icon" className="mx-2" />
                          <span style={{ whiteSpace: 'nowrap' }} onClick={(e) => { this.handleUpgrade(e) }} className="lato-regular fx-12 primary mx-2 pointer ">Upgrade Plan</span>
                        </div>


                      }
                      {/* </div> */}

                    </div>

                  </label>
                </div>

                <h5 className="auto-text lato-regular fx-14 black-242 op-5">
                  {this.state.customizationType === 'manual'
                    ? "You will be allowed to upload your own content from the scheduled plan tab once you schedule a planner here."
                    : "Your posts are system curated. You will be allowed to view and edit the content list from the scheduled plan tab once you schedule a planner here."}
                </h5>

                <hr />
              </Col>

              <Col md={4} className="planner-custom-control">
                <FormElement
                  valueLink={this.linkState(this, "formData.frequency")}
                  label="Frequency"
                  control={{
                    type: SelectControl,
                    settings: {
                      options: this.state.customizationType === "manual"
                        ? this.state.frequencyOptionManual
                        : this.state.frequencyOption,

                      multiple: false,
                      searchable: true,
                      placeholder: "Select frequency",
                      // onChangeCallback: value => this.handleChange("frequency", value)
                      onChangeCallback: (onBlur) => {
                        onBlur(this.state.formData.frequency);
                      }
                    }
                  }}
                />
              </Col>
              <Col md={5} className="d-flex flex-row align-items-center">

                <div className="form-group custom-date-range-picker  ">
                  <label htmlFor="" className="form-label"> Duration of Plan </label>
                  <div className="d-flex flex-row gap-10">
                    <DatePicker
                      selected={this.state.startDate}
                      onChange={(date) => { this.setState({ startDate: date }) }}
                      selectsStart
                      maxDate={this.state.endDate || new Date(maximumEndDate)}
                      minDate={isCurrentDateAllowed ? new Date() : getMinDate()}
                      isClearable={!!this.state.startDate}
                      placeholderText="Start Date"
                      className="form-control"
                      showYearDropdown
                      yearDropdownItemNumber={50}
                      scrollableYearDropdown
                      dateFormat={isMobile ? " d MMM , yyyy" : "d MMMM , yyyy"}
                      onFocus={(e) => {
                        e.target.blur()
                        e.target.readOnly = true
                      }}
                    />
                    <div className="d-none d-md-flex">
                      <DatePicker
                        selected={this.state.endDate}
                        onChange={(date) => { this.setState({ endDate: date }) }}
                        selectsStart
                        minDate={this.state.startDate || (isCurrentDateAllowed ? new Date() : getMinDate())}
                        isClearable={!!this.state.endDate}
                        maxDate={new Date(maximumEndDate)}
                        placeholderText="End Date"
                        className="form-control "
                        showYearDropdown
                        yearDropdownItemNumber={50}
                        scrollableYearDropdown
                        dateFormat={isMobile ? " d MMM , yyyy" : "d MMMM , yyyy"}

                        onFocus={(e) => {
                          e.target.blur()
                          e.target.readOnly = true
                        }}
                      />
                    </div>
                    <div className="d-md-none">
                      <DatePicker
                        selected={this.state.endDate}
                        onChange={(date) => { this.setState({ endDate: date }) }}
                        selectsStart
                        minDate={this.state.startDate || (isCurrentDateAllowed ? new Date() : getMinDate())}
                        isClearable={!!this.state.endDate}
                        maxDate={new Date(maximumEndDate)}
                        placeholderText="End Date"
                        className="form-control "
                        showYearDropdown
                        yearDropdownItemNumber={50}
                        scrollableYearDropdown
                        dateFormat={isMobile ? " d MMM , yyyy" : "d MMMM , yyyy"}
                        popperPlacement="right-start"
                        onFocus={(e) => {
                          e.target.blur()
                          e.target.readOnly = true
                        }}
                      />
                    </div>

                  </div>
                </div>
              </Col>

              <Col md={4}></Col>
              {this.state.customizationType === 'auto' &&
                <Col md={12} className="content-checkbox">
                  <p className="lato-bold fx-18 black-242 py-0">Types of content to be included in planner</p>
                  <h5 className="lato-regular fx-14 black-242 op-5 mt-1 mt-md-0">
                    Types of content for selection will be available based on your preferred branding settings.
                  </h5>
                  <div className="form-checkbox">
                    {checklist
                      .filter(option =>
                        formData.frequency == "" ||
                        (formData.frequency == '0.00' && option.isAvailableCustomizedForPostPlanner) ||
                        (formData.frequency != '0.00' && !option.isAvailableCustomizedForPostPlanner)
                      )
                      .map((option, index) => (
                        <div key={index} className="form-checkbox-item">
                          <input
                            type="checkbox"
                            className="d-none"
                            id={`contentCheck${index}`}
                            checked={selectedItems.some(item => item.uuid === option.uuid)}
                            onChange={() => this.handleItemCheckboxChange(option)}
                          />
                          <label className="cobrand-custom-checkbox bg-transparent mb-0 mt-1" htmlFor={`contentCheck${index}`}>
                            {selectedItems?.some(item => item.uuid === option.uuid) && (
                              <img src={ImageChecked} alt="tick" className="d-block" />
                            )}
                          </label>
                          <p className="platform-text lato-regular fx-14 black-242 mt-1 ">
                            {option?.categoryName || option?.socialMediaName}
                          </p>
                        </div>
                      ))}
                  </div>

                  <hr />
                </Col>
              }

              <Col md={12} className="content-checkbox ">
                <h4 className="lato-bold fx-18 black-242">Select preferred Platforms</h4>
                <div className="form-checkbox d-flex flex-md-row flex-column justify-content-between align-content-center text-center">
                  {this?.props?.feeds?.map((platform, index) => (
                    <div key={index} className="form-checkbox-item d-flex flex-row ">
                      <input
                        type="checkbox"
                        className="d-none"
                        id={`platformCheck${index}`}
                        checked={formData.platforms.includes(platform.uuid)}
                        onChange={() => this.handlePlatformCheckboxChange(platform)}
                      />
                      <label className="cobrand-custom-checkbox bg-transparent mb-0 mt-1" htmlFor={`platformCheck${index}`}>
                        {formData.platforms.includes(platform.uuid) && (
                          <img src={ImageChecked} alt="tick" className="d-block" />
                        )}
                      </label>
                      <div className="platform-image mt-1  ">
                        <Image height={15} width={15} src={platform?.iconImagePath} />
                      </div>
                      <p className=" platform-text lato-regular fx-14 black-242 mt-1  "> {platform?.platformName}</p>

                    </div>
                  ))}
                </div>
              </Col>
              <Col md={12} className="compliance-check">

                <div className="d-inline flex-row align-items-center">
                  <label style={{ marginTop: "1.8rem" }} className=" checkbox-container lato-regular fs-12-10 black-242 d-flex flex-row">
                    <input
                      type="checkbox"
                      // id={`create_card_id_${index}`}
                      checked={isComplaince}
                      onChange={this.handleCheckboxChange}
                      // style={{ marginRight: '8px', marginTop: "0" }}
                      className="d-none "
                    />
                    <label style={{ marginLeft: "0" }} onClick={this.handleCheckboxChange} htmlFor={`create_card_id`} className={`cobrand-custom-checkbox bg-transparent mb-0 mt-2 border-black`}>
                      {isComplaince && <Image src={ImageChecked} alt="checked" className='d-block' />}
                    </label>
                    <p className=" compliance-text lato-regular fx-12 black-4 d-flex flex-row flex-wrap  mt-0 mx-2">
                      Compliance Undertaking: Content provided by AMCs must remain unaltered, including their logos. Any modifications or misleading additions are strictly prohibited and may result in legal consequences. You will be solely responsible for any such actions or any files you upload; Thefinpedia and the AMC will not assume liability. Post Planner serves only as a scheduling tool and is not liable for the content you upload.
                      <p className="compliance-text lato-regular fx-12 black-4">For details, please refer to the <span onClick={(e) => { e.preventDefault(); window.location.href = '/terms-conditions' }} className="lato-regular fx-12 primary pointer">Terms & Conditions.</span></p>
                    </p>
                  </label>
                </div>



              </Col>

              <Col md={12}>
                <div className={`create-modal-bottom d-md-flex d-none flex-row ${this.state.editDetail ? 'justify-content-between' : 'justify-content-end'}`}>
                  {this.state.editDetail && <div onClick={this.handleDeletePlan} className="delete-plan d-flex flex-row justify-content-center pointer">
                    <Image width={18} height={18} src={DeletePlanIcon} alt="delete" />
                    <p className="lato-regular fx-14 red-D72 mx-2">Delete Plan</p>

                  </div>
                  }
                  <div className="create-modal-btn d-flex flex-row justify-content-end ">
                    <Button onClick={this.handleClose} style={{ marginRight: "1rem" }} type="submit" className="secondary-btn lato-medium fs-14-11 grey-1D1" >
                      Cancel
                    </Button>
                    <Button disabled={isButtonDisabled || this.state.isLoading} type="submit" className="primary-btn lato-medium fs-14-11 grey-1D1" >
                      {this.state.isLoading ? <Spinner /> : "Save"}
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body >
        <div className={`create-modal-bottom d-md-none  flex-row ${this.state.editDetail ? 'justify-content-between' : 'justify-content-center'}`}>
          {this.state.editDetail && <div onClick={this.handleDeletePlan} className="delete-plan d-flex flex-row justify-content-center pointer">
            <Image width={18} height={18} src={DeletePlanIcon} alt="delete" />
          </div>
          }
          <div className="create-modal-btn-sm d-flex align-content-center ">
            <Button onClick={this.handleClose} style={{ minWidth: this.state.editDetail ? '9rem' : '13.6rem' }} type="submit" className="secondary-btn lato-medium fs-14-11 grey-1D1 mx-3" >
              Cancel
            </Button>
            <Button style={{ minWidth: this.state.editDetail ? '9rem' : '13.6rem' }} disabled={isButtonDisabled || this.state.isLoading} type="submit" className="primary-btn lato-medium fs-14-11 grey-1D1" >
              {this.state.isLoading ? <Spinner /> : "Save"}
            </Button>
          </div>
        </div>

        <DeletePostModal showDelete={this.state.showDelete} onHide={this.onHide} deleteData={this.state.editDetail?.uuid} setPlaylistData={this.props.setPlaylistData} handleClose={this.handleClose} type={"custom"} />
      </Modal >
    );
  }
}

CreatePlannerModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  location: PropTypes.object
};

export default CreatePlannerModal;