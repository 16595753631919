
import React, { useEffect } from 'react';
import { commonPostApi } from '../home/Api';
import { deleteToken } from '../../utils/ManageToken';


export default function Sso() {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    let redirectToArray = window.location.search.split("redirectTo=");
    let redirectTo = "";
    if (redirectToArray.length > 1) {
      redirectTo = redirectToArray[1]
    }
    if (token) {

      const data = {
        "data": {
          "token": token
        }
      }
      const fetchData = async () => {

        try {
          deleteToken();
          const response = await commonPostApi(`/sso/sso-login`, data);

          console.log('API Response:', response.data);
          if (response?.data?.user) {
            localStorage.setItem('finUserDetails', JSON.stringify(response?.data?.user));
            sessionStorage.setItem('fromLogin', true);
          }


          localStorage.setItem('finpediaCustomerToken', JSON.stringify(response?.data?.token))

          window.location.href = redirectTo || "/"

        } catch (error) {
          console.error('API Error:', error);

        }
      };

      fetchData();
    }
  }, []);

  return (
    <div></div>

  );
}
