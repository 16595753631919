import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

import { isResOk } from '../../../utils/ReusableFunctions';
import { getAllCitesApi, getNotificationData } from '../../home/Api';
import { SETTING_NOT, TARGETED_CONTENTS_NOTIFICATION } from '../../../utils/Constant';
import NotificationSelectDropDown from '../components/NotificationSelectDropDown';
import SelectLocation from '../components/SelectLocation';

/* eslint-disable react-hooks/exhaustive-deps */


const EventNotification = ({ sendDataToParent }) => {
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [location, setLocation] = useState([]);
  const [allCities, setAllCities] = useState([]);
  // const [isChanged, setIsChanged] = useState(false);
  const [isMutual, setIsMutual] = useState(false);
  const [isLoading, setILoading] = useState(true);
  const getEventNotification = async () => {
    setILoading(true)
    const res = await getNotificationData('/inAppNotification/settings/Event');

    if (isResOk(res.status)) {

      setNotificationTypes(res?.data?.data?.settings);
      const findFirstObj = res?.data?.data?.settings?.find((ele) => ele?.code === SETTING_NOT['EVENT_CREATORS']);
      const locationObj = res?.data?.data?.settings?.find((ele) => ele?.code === SETTING_NOT['LOCATIONS']);
      const option = findFirstObj?.list?.find((ele) => ele?.isMutualFuncCompany);

      setLocation(locationObj?.list.map((ele) => ele.name))
      setIsMutual(option?.isSelected);
      setILoading(false)

    }
    else {
      setILoading(false)
    }
  };

  const getCityData = async (val = '') => {
    getAllCitesApi(val, setAllCities);
  }

  useEffect(() => {
    getEventNotification();
    getCityData();
  }, []);
  useEffect(() => {
    sendDataToParent(notificationTypes);
  }, [notificationTypes]);

  const togglePill = (codeName, listId) => {
    setNotificationTypes(prevNotificationTypes => {
      const newNotificationTypes = [...prevNotificationTypes];
      const findFirstObj = newNotificationTypes?.find((ele) => ele?.code === codeName);
      const option = findFirstObj?.list?.find((id) => id.uuid === listId);
      option.isSelected = !option.isSelected;
      if ((option?.isMutualFuncCompany ?? false)) {

        setIsMutual(option.isSelected)
        if (!option.isSelected) {
          const findFirstObj = newNotificationTypes?.find((ele) => ele?.code === SETTING_NOT['EVENT_AMCS']);
          const listArr = findFirstObj?.list?.map((obj) => ({ ...obj, isSelected: false }));
          findFirstObj.list = listArr;
        }
      }
      return newNotificationTypes;
    });
  };

  const updateLoactionHandler = (data, codeName) => {

    setLocation(data);
    setNotificationTypes(prevNotificationTypes => {
      const newNotificationTypes = [...prevNotificationTypes];
      const findFirstObj = newNotificationTypes?.find((ele) => ele?.code === codeName);
      findFirstObj.list = data
      return newNotificationTypes;
    });

  }
  return (
    <div className='content-notification-main'>
      {/* <NotificationSelection /> */}
      <div className='notification-dropdown'>
        <h3 className='lato-regular fs-20-400 black-242 mb-16'>What notifications you wish to receive</h3>
        <section>
          <Row>
            {isLoading ?
              [1, 2, 3, 4, 5, 6].map((ele, i) => {
                return (
                  <Col xs={12} sm={6} key={i} className='d-flex'>
                    <div className='notification-main notification-type bg-white border-ECE flex-fill'>
                      <h4 className="lato-regular shimmer-container w-25 fs-14-400">
                        &nbsp;
                      </h4>
                      <hr />
                      <div className='pills-wrapper'>
                        {[...Array(Math.floor(Math.random() * 6) + 1)].map((item, optionIndex) => (
                          <span
                            key={optionIndex}
                            className={`lato-regular shimmer-container mw-151 fx-12 border-0 notification-pills ${false ? 'active' : ""}`}
                          >
                            {" "}

                          </span>
                        ))}
                      </div>
                    </div>
                  </Col>
                )
              })
              :
              notificationTypes?.map((ele, sectionIndex) => {

                const isShowAmc = isMutual ? [TARGETED_CONTENTS_NOTIFICATION, SETTING_NOT['EVENT_AMCS']] : [TARGETED_CONTENTS_NOTIFICATION];
                if ((isShowAmc)?.includes(ele.code)) {
                  return (
                    <Col key={sectionIndex} xs={12} sm={6}>
                      <NotificationSelectDropDown ele={ele} togglePill={togglePill} />
                    </Col>
                  )

                }
                else if (SETTING_NOT['EVENT_AMCS'] === ele?.code) {
                  return ''
                }
                return (
                  <Col xs={12} sm={6} key={sectionIndex} className='d-flex'>
                    <div className='notification-main notification-type flex-fill'>
                      <h4 className="lato-regular mb-3 fs-14-400">{ele.title}</h4>

                      {ele.code === SETTING_NOT['LOCATIONS'] ?
                        <SelectLocation label="Location" placeholder={'Search Location'} onchangeHandler={(val) => getCityData(val)} selectedKeyWords={location} options={allCities} getKeys={(data) => updateLoactionHandler(data, ele?.code)} />
                        : <>
                          <hr />
                          <div className='pills-wrapper'>
                            {ele.list.map((item, optionIndex) => (
                              <span key={item.uuid} className={`lato-regular fx-12 notification-pills ${item.isSelected ? 'active' : ""}`}
                                onClick={() => togglePill(ele?.code, item?.uuid)}>
                                {item.name}
                              </span>
                            ))}
                          </div>
                        </>
                      }

                    </div>
                  </Col>
                )

              }
              )}

          </Row>
        </section>
        <hr />
      </div>
    </div>
  );
};

export default EventNotification;
