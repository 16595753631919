import React, { useEffect } from 'react'
import Trending from '../../common/Trending'
import { SearchDropDown } from '../../common'
import { useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getTrendingKeysApi } from '../Api';
// const specialkeywords = ["SIP", "MAF", "BAF", "Volatility", "STP", "SWP", "ELSS", "Financial Planning"];
const toCamelCase = (sentence) => sentence.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');

export default function HomeSearch() {
  const [selectedKeys, setSelectedKeys] = useState("");
  const [trendingKeywords, setTrendingKeywords] = useState([])

  // const trendingKeywords = specialkeywords;
  const navigate = useHistory()

  // const randomItems = specialkeywords.sort(() => Math.random() - 0.5).slice(0, 4);
  function navigateHandler() {
    navigate.push('/trending/finpedia-trending')
  }
  useEffect(() => {
    // const trendingKeywords = JSON.parse(sessionStorage.getItem("trendingKeywords"));
    // if (!trendingKeywords) {
    //   getTrendingKeysApi(setTrendingKeywords);
    // }else{
    //   setTrendingKeywords(trendingKeywords);
    // }
    getTrendingKeysApi(setTrendingKeywords);
  }, [])
  return (
    <div className="hearo-section">
      <div className="container">
        <div className="hero-content">
          <h4 className="lato-regular fs-32-24  white">
            Marketing made easy for
          </h4>
          <h3 className="lato-bold fs-57-28  white ">
            Mutual Fund Distributors & Advisors
          </h3>
          <SearchDropDown getKeys={(keys) => setSelectedKeys(keys)} />
          <div className="treanding-one-wrapper">
            <p className='white fs-18-14 lato-bold pointer' onClick={navigateHandler}>Trending: </p>
            <div className='trending-wrapper'>
              {
                trendingKeywords?.map((item, i) => {
                  // const randomNumber = Math.floor(Math.random() * specialkeywords.length);
                  return (
                    <Trending title={(item?.count ?? false) ? toCamelCase(item?.trendingKeyword || '') : item?.trendingKeyword} link={((item?.link) ?? false) || ""} classes="white fs-18-14 lato-semibold" />
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
