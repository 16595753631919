import React from 'react';
import { Image } from "react-bootstrap";
import PasswordHide from "../../../assets/images/password-hide.svg";
import PasswordShow from "../../../assets/images/password-show.svg";
import {
  BaseReactComponent,
  CustomButton,
  CustomTextControl,
  Form,
  FormElement,
  FormValidator,
} from "../../../utils/form";

import { loginApi } from '../Api';
import ForgotPassword from '../ForgotPassword';

class LoginForm extends BaseReactComponent {
  constructor(props) {
    super(props)
    this.state = {
      login: "",
      loginPassword: "",
      showPassowrd: false,
      error: "",
    }
  }
  onValidSubmit = (e) => {

    const data = {
      data: {
        "emailOrMobile": this.state.login,
        "password": this.state.loginPassword
      }
    }
    loginApi(this, data);
  };

  render() {

    return (
      <div className="login-box">
        {
          this.state.showForgotPasswordModal &&
          <ForgotPassword
            show={this.state.showForgotPasswordModal}
            handleClose={() => this.setState({ showForgotPasswordModal: false })}
          />
        }
        <h5 className="lato-regular f-s-24 lh-32">Log in</h5>
        <Form onValidSubmit={this.onValidSubmit}>
          <FormElement
            valueLink={this.linkState(this, "login")}
            required
            validations={[
              {
                validate: FormValidator.isRequired,
                message: "Please enter register email",
              },

            ]}
            control={{
              type: CustomTextControl,
              settings: {
                placeholder: "Email/Phone",
              },
            }}
          />
          <div className='pos-rel'>
            <FormElement
              valueLink={this.linkState(this, "loginPassword")}
              required
              classes={{
                formGroup: "password-input-field",
              }}
              validations={[
                {
                  validate: FormValidator.isRequired,
                  message: "Password cannot be empty",
                },
              ]}
              control={{
                type: CustomTextControl,
                settings: {
                  type: this.state.showPassowrd ? "text" : "password",
                  placeholder: "Password",
                },
              }}
            />
            {
              this.state.error && !this.state.captureText &&
              <p className="lato-regular red-FF3 f-s-14 lh-20 error-msg pointer">{this.state.error}</p>
            }
            <Image
              src={this.state.showPassowrd ? PasswordShow : PasswordHide}
              className='password-hide pos-abs pointer'
              onClick={() => this.setState({ showPassowrd: !this.state.showPassowrd })}
            />
          </div>
          <p
            className="lato-regular white f-s-14 lh-20 forgot-password "
          >
            <span
              className=' pointer'
              onClick={() => { this.setState({ showForgotPasswordModal: true }) }}
            >
              Forgot Password ?
            </span>
          </p>
          <br /><br />
          <CustomButton
            // isDisabled={true}
            type="button "
            onClick={this.onValidSubmit}
            className="secondary-btn mw-352 mw-b-15"
            buttonText="Log in"
          />

        </Form>

        <br /><br /><br />
        <div className="hr-lines">
          <span className="line"></span>
          <span className="lato-regular white f-s-16 lh-19">or</span>
          <span className="line"></span>
        </div>
        <div className="new-to-finpedia">
          <h5 className="lato-regular f-s-24 lh-32">New to Thefinpedia? <span onClick={() => this.props.toggleForm()} className=' lato-bold pointer under-line'>Register</span> </h5>
        </div>

      </div>
    );
  };
}
LoginForm.propTypes = {
  // getPosts: PropTypes.func
};
export default LoginForm;