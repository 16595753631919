import React, { useState, useEffect } from 'react';
import { Dropdown, Image } from 'react-bootstrap';
import DownIcon from "../../../assets/images/dropdown-icon.svg";
import CloseIcon from "../../../assets/images/cancel-icon.svg";


/* eslint-disable react-hooks/exhaustive-deps */


const NotificationSelectDropDown = ({ ele, togglePill = () => { } }) => {
    const [targatedValue, setTargatedValue] = useState('');
    const [targatedValueOpt, setTargatedValueOpt] = useState([]);
    const [targatedList, setTargatedList] = useState([]);

    useEffect(() => {
        const defaultList = ele?.list?.filter((item) => item.isSelected);
        setTargatedValueOpt(defaultList);
    }, [])

    const handleTargetedValueChange = (item) => {
        setTargatedValue(item.name);
        setTargatedValueOpt([...targatedValueOpt, item]);
    }

    const removeTargetedValue = (index) => {
        const filterData = targatedValueOpt?.filter((ele, i) => ele.uuid !== index)
        setTargatedValue('');
        setTargatedValueOpt(filterData);
    };
    return (

        <div className='notification-main notification-type flex-fill'>
            <h4 className="lato-regular mb-3 fs-14-400">
                {ele.title}
            </h4>
            {/* <hr /> */}
            <Dropdown className="single-select-api  w-100 mw-100">
                <Dropdown.Toggle className="dropdown-btn" id="dropdown-basic">
                    <input type="text" value={targatedValue} onChange={(e) => {
                        setTargatedValue(e.target.value);
                        const searchData = ele?.list.filter((ele) => ele?.name?.toLowerCase()?.startsWith(e.target.value?.toLowerCase()?.trim()));
                        setTargatedList(searchData?.length > 0 ? searchData : ele?.list);
                    }} name='targatedValue' className='lato-semibold fx-14 black-242 input-box w-100 mw-100 border-0' placeholder={`${(ele?.placeHolder)}`} />
                    {targatedValue && <Image src={CloseIcon} onClick={() => setTargatedValue('')} className='text-remove-image w-24' alt='icon' />}
                    {/* <h6 className="lato-semibold fx-14 black-242">{toCamelCase("Test dropDoen")}</h6> */}
                    <Image src={DownIcon} alt="image" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="">
                    {(targatedList?.length > 0 ? targatedList : ele?.list)?.map((item, i) => {
                        return (
                            <Dropdown.Item key={item?.uuid} onClick={() => {
                                handleTargetedValueChange(item);
                                togglePill(ele?.code, item?.uuid);
                            }
                            } className="lato-regular fx-14 black-242 " >{item?.name}</Dropdown.Item>
                        )
                    })}
                    {/* <Dropdown.Item className="lato-regular black-242 " href=''>Most Viewed</Dropdown.Item> */}
                </Dropdown.Menu>
            </Dropdown>
            <div className='pills-wrapper mt-3'>
                {targatedValueOpt?.map((item, i) => (
                    <span
                        key={item.uuid}
                        className={`lato-regular fx-12 notification-pills active`}
                    // onClick={() => console.log(item)}
                    >
                        {item.name}
                        <div className='targated-value-removed' onClick={() => removeTargetedValue(item.uuid)}>
                            <Image src={CloseIcon} />
                        </div>
                    </span>
                ))}
            </div>
        </div>


    )
}


export default NotificationSelectDropDown;