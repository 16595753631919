import { Image } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import PreviewIcon from "../../assets/images/icon/preview-icon.svg";
import { useState } from "react";
import PreviewModal from "../planner/PreviewModal";
import PreviewPersonalizedModal from "./PreviewPersonalizedModal";
import { getAuthDataApi } from "../home/Api";
import { toast } from "react-toastify";

export default function PersonalizationCards({ data, type }) {
    const history = useHistory();
    const [preview, setPreview] = useState(false);
    const [previewData, setPreviewData] = useState();

    const navigateDetails = async (card) => {

        let personalizationType = type === "All" ? data?.title : type;
        const path = `/editor/${personalizationType}/${card?.uuid}`;
        const queryParams = `?name=${encodeURIComponent(card?.templateName)}`;

        // Navigate to the constructed URL
        history.push({
            pathname: path,
            search: queryParams,
            state: {
                data: card?.uuid
            }
        });
    };

    const handlePreview = (e, card) => {
        e.preventDefault();
        e.stopPropagation();
        setPreview(true);
        setPreviewData(card);
    }


    return (
        <>
            <PreviewPersonalizedModal preview={preview} setPreview={setPreview} previewData={previewData} />

            <div className={`row pointer  personalization-cards-wrapper ${type !== "All" ? "" : "has-extra-cards"}`}>
                {data?.list?.map((card, index) => (
                    <div key={index} className="col-6 col-md-4 col-lg-3 ">
                        <div onClick={() => { navigateDetails(card) }} className="personalization-card">
                            <div className="card-image">
                                <Image className="thumbnail" src={card?.thumbnailImage || ""} alt="image" />
                                <Image width={20} height={20} onClick={(e) => { handlePreview(e, card) }} className='preview-image' src={PreviewIcon} alt="image" />
                            </div>
                            <p className="card-tag lato-medium fs-14-12 black-242  text-ellipsis-one">{card?.templateName}</p>
                            <span className="greeting lato-medium fs-14-12 black-242 text-ellipsis-one ">{card?.description || ""}</span>
                        </div>
                    </div>
                ))}
            </div>
        </>

    );
}
