
import { postLoginInstance, postLooseLoginInstance, preLoginInstance } from "../../utils";
import { toast } from "react-toastify";
import { API_LIMIT, START_PAGE } from "../../utils/Constant";
import { getToken } from "../../utils/ManageToken";


// LOGIN API
export const loginApi = (ctx, data) => {
  const instance = getToken() ? postLooseLoginInstance : preLoginInstance;
  instance.post('user/login', data)

    .then(res => {
      localStorage.setItem('finpediaCustomerToken', JSON.stringify(res?.data?.data.token.token));
      localStorage.setItem('finUserDetails', JSON.stringify(res?.data?.data.token.user));
      sessionStorage.setItem('fromLogin', true);

      // Retrieve the previous route from session storage
      const previousRoute = sessionStorage.getItem('previousRoute');

      // Check if there is a previous route
      if (previousRoute) {
        // Remove previousRoute from sessionStorage
        sessionStorage.removeItem('previousRoute');


        const [pathname, search] = previousRoute.split('?');

        // Redirect to the previous route
        ctx.props.history.push({
          pathname,
          search: search ? `?${search}` : undefined, // Part after `?` if exists
        });

      }
      else {
        // Default redirect with search parameters if no previous route exists
        ctx.props.history.push({
          pathname: '/',
          // search: `pageNumber=${START_PAGE}&pageSize=${API_LIMIT}`,
        });
      }
    })
    .catch((error) => {
      toast.error(error.response.data.message || "Something went wrong");
    });
};

//VERIFY USER API
export const verifyUserApi = async (data) => {
  try {
    const response = await (getToken() ? postLoginInstance : preLoginInstance).post('user/sentMobileNumberOtp', data);
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message || "Something went wrong")
    throw error;
  }
};

//REGISTER USER API
export const registerUserApi = async (data, setIsLoading) => {
  try {
    const response = await (getToken() ? postLoginInstance : preLoginInstance).post('user/register', data);
    return response.data;
  } catch (error) {
    setIsLoading(false);
    toast.error(error.response.data.message || "Something went wrong")
    throw error;
  }
};

// FORGOT PASSWORD API
export const forgotPasswordApi = (ctx, data) => {
  const instance = getToken() ? postLoginInstance : preLoginInstance;

  instance.post('user/forgot-password', data)
    .then(res => {
      ctx.setState({
        showSuccess: true
      })
      toast.success(res?.data?.data.message || "Success")
    })
    .catch(error => {
      toast.error(error.response.data.message || "Something went wrong")
    });
};

// USER DETAILS API
export const getUserDetailsApi = (ctx = null, setLogoFile = null, setBrandinText = null) => {
  postLoginInstance.get('user/details')
    .then(res => {
      localStorage.setItem('finUserDetails', JSON.stringify(res?.data?.data));
      if (ctx?.state?.userDetails) {
        ctx.setState({
          userDetails: res?.data?.data
        })
      }
      if (setLogoFile !== null && setBrandinText !== null) {
        console.warn("call fo cobranding");
        setLogoFile(res?.data?.data?.organizationLogoPath ?? null);
        setBrandinText({ company: res?.data?.data?.companyName ?? "", arnNo: res?.data?.data?.arnNumber ?? "", companyEmail: res?.data?.data?.emailAddress ?? "", companyNo: res?.data?.data?.mobileNumber ?? "" })

      }

      if (ctx?.state?.token) {
        ctx.props.history.push(`/content-type/All?pageNumber=${START_PAGE}&pageSize=${API_LIMIT}`);
      }
    })
    .catch(error => {
      toast.error(error.response?.data.message || "Something went wrong")
    });
};

//RESET PASSWORD API
export const resetPasswordApi = (ctx, data) => {
  const instance = getToken() ? postLoginInstance : preLoginInstance;

  instance.put(`/user/reset-password/${ctx.state.token}`, data)
    .then((res) => {
      toast.success(res?.data?.data.message || "Password set successfully");
      ctx.props.handleClose()
      // ctx.props.history.push("/onboarding");
    })
    .catch((error) => {
      toast.error(error.response.data.message || "Something went wrong")
    });
};

// VERIFY EMAIL API
export const verifyEmailApi = (ctx) => {
  const instance = getToken() ? postLoginInstance : preLoginInstance;

  instance.post(`/user/verifyEmailAddressVerificationToken/${ctx.state.token}`)
    .then((res) => {
      // console.log('res', res);
      toast.success(res?.data?.data.message || "Email verified successfully");
      ctx.props.history.push("/onboarding");
    })
    .catch((error) => {
      toast.error(error.response.data.message || "Something went wrong")
    });
};