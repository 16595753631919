import React, { useEffect } from 'react'
import { Image, Modal } from 'react-bootstrap'
import close from "../../../assets/images/cancel-icon.svg";

/* eslint-disable react-hooks/exhaustive-deps */


export default function WelcomeFinModal({ show, data, handleClose }) {

  // const calculateCompleteness = (data) => {
  //   const totalFields = userDetailsKeys.length;
  //   // const filledFields = data.filter(value => value !== null).length;
  //   const filledFields = [];
  //   userDetailsKeys.forEach((key) => {
  //     if (data.hasOwnProperty(key) && data[key] !== null && data[key] !== "" && data[key] !== undefined) {
  //       filledFields.push(key);
  //     }
  //   });
  //   // console.log(filledFields.length);
  //   return (filledFields.length / totalFields) * 100;
  // };
  useEffect(() => {
    // const data = JSON.parse(localStorage.getItem("finUserDetails"));
    // if (data && calculateCompleteness(data) != 100) {
    //   setRange(calculateCompleteness(data))
    // } else {
    //   handleClose()
    // }

    if (data?.code !== 'AFTER-LOGIN') {
      sessionStorage.setItem('welcomeHome', true);
    }
  }, [])

  function showContetnHander(type) {
    switch (type) {
      case 'VIDEO':
        return <iframe className='welcome-video-tag' width={'100%'} src="https://www.youtube.com/embed/IJgngYNmPp0" frameborder="0" ></iframe>
      case 'TEXT':
        return <p className='lato-regular text-center fs-22-18 black242' dangerouslySetInnerHTML={{ __html: data.description }}></p>
      default:
        return <p className='lato-regular text-center fs-22-18 black242'>Enhance your experience. Complete your profile to unlock personalized recommendations tailored to your needs.</p>
    }
  }
  return (
    <div>
      <Modal className="welcome-custom-model" show={show} onHide={handleClose}>
        <Image src={close} onClick={handleClose} alt="canle icon" className="close-btn pointer" />

        <Modal.Body className="welcome-model-body p-0">

          <h3 className='lato-regular text-center fs-32-28 mb-3 primary'>{data.title}</h3>
          {/* <h3 className='lato-regular text-center fs-32-28 mb-3 primary'><span className=' lato-semibold'>Thefinpedia</span>  is here to make marketing easy for MFDs/RIAs</h3> */}
          <p className='lato-regular text-center fs-22-18 mb-3 black-242'>{data.subTitle || 'Welcome to Thefinpedia!'}</p>

          <div className=" text-center mt-5">
            {/* <video className='welcome-video-tag' controls>
            <source src={demoVideo} type="video/mp4"/>
                Your browser does not support the video tag.
          </video> */}
            {/* <iframe className='welcome-video-tag' width={'100%'} src="https://www.youtube.com/embed/IJgngYNmPp0" frameborder="0" ></iframe> */}
            {showContetnHander(data.type)}
          </div>
          {/* <h3 className='lato-semibold text-center fs-32-28 mb-3 primary'>Welcome to Thefinpedia!</h3>
          <p className='lato-regular text-center fs-22-18 black242'>Discover, personalize and share high-quality content with your clients instantly!</p>
          <h3 className='lato-semibold text-center fs-32-28 primary mt-4 pt-3'>Your profile is {Math.round(range)}% complete </h3>
          <ProgressBar now={range} />
          <p className='lato-regular text-center fs-22-18 black242'>Enhance your experience. Complete your profile to unlock personalized recommendations tailored to your needs.</p>
          <div className="text-center">
            <Button className='primary-btn mt-5 mx-auto' onClick={() => history.push("/profile-details")}>Complete My Profile</Button>
          </div> */}
        </Modal.Body>
      </Modal>
    </div>
  )
}
