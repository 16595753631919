import React, { useEffect, useState } from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { AMC, AMC_CONTENT_CREATOR, API_LIMIT, CAMPAIGN, CAMPAIGNS, CREATOR, LANGUAGE, LANGUAGES, MAX_LIMIT, MYCOLLECTION, SOCIAL_MEDIA, SOCIAL_MEDIAS, START_PAGE, TARGETED_CONTENT, TARGETED_CONTENTS } from '../../../utils/Constant';
import { getContentTypeApi } from '../Api';

const listType = ['language', 'languages', 'campaigns', 'campaign', 'category', 'amc', CREATOR]
const titleMap = {
  [SOCIAL_MEDIAS]: SOCIAL_MEDIA,
  [TARGETED_CONTENTS]: TARGETED_CONTENT,
  [CAMPAIGNS]: CAMPAIGN,
  [LANGUAGES]: LANGUAGE,
  'Amc': CREATOR,
  [AMC]: CREATOR,
};


export default function BreadCrumb({ path, clasese, isKeyValue = "" }) {
  const history = useHistory();
  const location = useLocation()
  const params = new URLSearchParams(location.search);
  const { personalizedType } = useParams();

  const [contentType, setContentType] = useState(JSON.parse(localStorage.getItem("contenType")))
  // const contentType = JSON.parse(localStorage.getItem("contenType"));
  const contentTypeList = contentType?.map((item) => item.contentTypeName.toLowerCase());
  useEffect(() => {

    getContentTypeApi(setContentType)
  }, [])
  function navigateHandler(item) {


    const params = new URLSearchParams(location.search);
    if (item.toLowerCase() === 'notifications') {
      history.push('/notification');
    }
    else if (contentTypeList?.includes(item?.toLowerCase().replace("_", "/"))) {

      const newSearchValue = `pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}`;
      if ((isKeyValue?.length !== 2)) {

        history.push({
          pathname: `/content-type/${item.toLowerCase().replace("/", "_")}`,
          search: `${newSearchValue}`,
        });
      } else {

        history.push({
          pathname: `/content-type/${item.toLowerCase().replace("/", "_")}`,
          search: `?key=${isKeyValue[0]}&value=${isKeyValue[1]}&pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}`
        })
      }
    }
    else if (params.get('key') === 'targetedContents' || item == "targeted playlists" || (isKeyValue[0] === "targetedContents" && isKeyValue[0].toLowerCase() === item)) {

      history.push({ pathname: `/targetedContent`, search: `?pageSize=${API_LIMIT}&pageNumber=${START_PAGE}&sortBy=Content&order=DESC` });
    }
    else if (params.get('key') === "socialMedias" || (isKeyValue[0] === "socialMedias" && isKeyValue[0].toLowerCase() === item)) {

      history.push(`/social-media`);
    }
    else if (item.toLowerCase() === "my activity") {

      history.push(`/myactivity/user-tracking?type=downloaded`);
    }
    else if (listType?.includes(item?.toLowerCase()?.trim()) && !isKeyValue) {
      const amcCreatorUrl = ([AMC, CREATOR]?.includes(item?.toLowerCase()?.trim())) ? AMC_CONTENT_CREATOR : item.toLowerCase().replace(/s$/g, '').trim();
      history.push(`/collection-type/${amcCreatorUrl}`)

    }
    else if (isKeyValue) {

      if (listType?.includes(item.toLowerCase())) {
        const amcCreatorUrl = ([AMC, CREATOR]?.includes(item?.toLowerCase()?.trim())) ? AMC_CONTENT_CREATOR : item.toLowerCase().replace(/s$/g, '').trim();
        history.push(`/collection-type/${amcCreatorUrl}`)
      } else {
        history.push({
          pathname: '/content-type/All',
          search: `?key=${isKeyValue[0]}&value=${encodeURIComponent(item)}&pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}`
        })
      }

    }
    else if (MYCOLLECTION?.toLowerCase() === item?.toLowerCase()) {
      history.push(`/mycollections`);
    }
    else if (item?.toLowerCase() === "membership" || item?.toLowerCase() === "my membership") {
      history.push(`/mymembership`);
    }
    else if (item?.toLowerCase() === "event") {

      history.push({ pathname: '/events', search: 'pageNumber=1&pageSize=10' });
    }
    else if (item?.toLowerCase() === "tools") {

      history.push({ pathname: '/tools' });
    }
    else if (item?.toLowerCase() === "personalization") {

      history.push({ pathname: '/personalization/All' });
    }
    else if (item?.toLowerCase() === personalizedType?.toLowerCase()) {

      history.push({ pathname: `/personalization/${personalizedType}` });
    }
    else if (location.pathname.includes('/collection-details') && params.get('type')) {
      const newPath = location.pathname;
      history.push({ pathname: newPath });
    }
    else {

      history.push({ pathname: `/details/${location?.state?.data?.contentType?.contentTypeName.replace("/", "_").toLowerCase()}&id=${location?.state?.data?.uuid || START_PAGE}` })
    }


  }


  return (
    <div className={`breadcrumb-wrapper ${clasese}`}>
      <Breadcrumb >
        <Breadcrumb.Item className='lato-regular f-s-12' onClick={() => history.push("/")}>Home</Breadcrumb.Item>
        {path && path?.map((item, i) => {
          const isLastItem = i === path.length - 1;
          const titleName = item?.replace(/(?:^|-)\w/g, function (match) {
            return match.toUpperCase();
          }).replace(/-/g, ' ');
          const breadcrumbKeys = ["socialMedias", "targetedContents", "creator", "amc", "category", "campaigns", "languages"];
          const urlChecks = ["tools", "events-details", "collection-details", "checkout", "personalization"];
          return (
            <Breadcrumb.Item
              key={i}
              className={`lato-regular f-s-12 ${breadcrumbKeys.includes(params.get("key")) || urlChecks.some((str) => window.location.href.includes(str)) ? "" : "breadCrumb-pr"}`}
              onClick={isLastItem ? null : () => navigateHandler(item.toLowerCase())}
            >
              {/* {console.log(titleName)} */}
              {titleMap[titleName] || titleName}
            </Breadcrumb.Item>
          )
        })}

      </Breadcrumb>
    </div>
  )
}
