import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import arrowNext from "../../../assets/images/Arrow_next.svg";
import arrowPre from "../../../assets/images/Arrow_pre.svg";
import { navigateSetUrl } from "../../../utils/ReusableFunctions";
import { START_PAGE } from "../../../utils/Constant";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function Pagination(props) {
  const [tempPageNo, setTempPageNo] = useState(parseInt(props.page, 10)); // Temporary state for input
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setTempPageNo(props.page); // Sync temp state with props.page when it changes
  }, [props.page]);

  const onLeftClick = () => {
    if (props.page > 1) {
      const params = new URLSearchParams(location.search);
      params.set("pageNumber", parseInt(props.page, 10) - 1);
      navigateSetUrl(history, history.pathname, params.toString());
    }
  };

  const onValueInputChange = (e) => {
    setTempPageNo(e.target.value); // Update temporary state as the user types
  };

  const onInputSubmit = () => {
    const no = parseInt(tempPageNo, 10);
    if (no > 0 && no <= props.pageCount) {
      const params = new URLSearchParams(location.search);
      params.set("pageNumber", no);
      navigateSetUrl(history, history.pathname, params.toString());
    } else {
      setTempPageNo(props.page); // Reset to current page number if input is invalid
    }
  };

  const onInputKeyPress = (e) => {
    if (e.key === "Enter") {
      onInputSubmit(); // Handle Enter key press
    }
  };

  const onInputBlur = () => {
    onInputSubmit(); // Handle input blur (click outside)
  };

  const onNextClick = () => {
    if (props.page < props.pageCount) {
      const params = new URLSearchParams(location.search);
      params.set("pageNumber", parseInt(props.page, 10) + 1);
      navigateSetUrl(history, history.pathname, params.toString());
    }
  };

  return (
    <div className="pagination-section">
      <Image
        onClick={onLeftClick}
        src={arrowPre}
        className={`pagination-pre-icon pointer ${props.page <= 1 ? "disable-arrow" : ""}`}
        alt="back_icon"
      />
      <div className="pagination-count f-s-14 lato-medium black-242">
        Page
        <input
          className="pagination-item pointer"
          onWheel={(e) => e.target.blur()}
          type="number"
          value={tempPageNo}
          onChange={onValueInputChange}
          onKeyPress={onInputKeyPress}
          onBlur={onInputBlur}
        />
        of {props.pageCount}
      </div>
      <Image
        onClick={props.page < props.pageCount ? onNextClick : null}
        src={arrowNext}
        className={`pagination-next-icon pointer ${props.page >= props.pageCount ? "disable-arrow" : ""}`}
        alt="next_icon"
      />
    </div>
  );
}
