import React, { useEffect, useState } from "react";
import { AlertModal, Footer, Header } from "../common/pages";
import { BreadCrumb } from "../home/_utils";
import { Image } from "react-bootstrap";
import abcLogo from "../../assets/images/abc.png";
import { useHistory, useLocation } from "react-router-dom";
import Personalised from "./Personalised";

import html2canvas from 'html2canvas';
import {
  ChildEducationPlanner,
  DelaySip,
  FixedMonthlyWithdrawal,
  ImpactOfInflation,
  Lumpsum,
  SIPCalculator,
  TargetAmount,
  StepUp,
  SWP,
  RetirementPlanner
} from ".";
import { useRef } from "react";
import SIPWithEMIPlanner from "./SIPWithEMIPlanner";
import { useParams } from "react-router-dom";
import { getAuthDataApi } from "../home/Api";

export default function CalculatorPage() {
  const graphRef = useRef(null);

  const location = useLocation();
  const history = useHistory()
  const [isChecked, setIsChecked] = useState({});
  const [imageData, setImageData] = useState("");
  const [calculator, setCalculator] = useState({});
  const { name } = useParams();
  const [filterData, setFilterData] = useState();
  const [isDisable, setIsDisable] = useState(false);
  const [alertData, setAlertData] = useState();
  const [isAlert, setIsAlert] = useState(false);
  const [modalData, setModalData] = useState();


  //* -----------------------------------------------------------------------------------------
  const getTools = (async () => {
    const res = await getAuthDataApi("website/tools/list?pageNumber=1&pageSize=18")
    if (res) {
      setFilterData(res);
      const matchedItem = res.find(item => item.link === `/${name}`);
      if (matchedItem) {
        setCalculator(matchedItem);

      }

    }

  });

  useEffect(() => {
    getTools()
  }, [name])

  const downloadScreenshot = () => {
    if (graphRef.current) {
      html2canvas(graphRef.current).then((canvas) => {
        const image = canvas.toDataURL('image/png', 1.8);

        let downloadLink = document.createElement('a');
        downloadLink.href = image;
        downloadLink.download = 'graph-screenshot.png';

        document.body.appendChild(downloadLink);

        downloadLink.click();


        document.body.removeChild(downloadLink);
      });
    }
  };
  const onHide = (() => {

    setIsAlert(false)

  });

  return (
    <div className="page-padding ">
      <div className="container">


        <Header />
        <BreadCrumb path={["Tools", calculator?.toolName]} />
        {/* ===============================  SIP HEADER PART START =================== */}
        <div className="tools-calculator-sub-header">
          <h5 className="lato-regular fs-32-28 black-242">
            {calculator?.toolName}
          </h5>
          <div className="tools-sip-info">
            <div className="tools-sip-name-frame">
              <p className="lato-regular fx-12 black-242 m-0 op-5">
                Sponsored By
              </p>
              <p className="lato-medium fs-14-13 m-0 black-242">
                {calculator?.amc?.amcName || calculator?.contentCreator?.contentCreatorName || ""}
              </p>

            </div>
            <div className="tools-sip-logo">
              <Image src={calculator?.amc?.circleImagePath || calculator?.contentCreator?.circleImagePath} alt="sip Logo" />
            </div>
          </div>
        </div>
        <Personalised isTextLogo={(e) => { setIsChecked(e) }} />
      </div>
      <div ref={graphRef} className="container">
        {calculator?.code === "SIPCalculator" && <SIPCalculator calculator={calculator} textLogo={isChecked} imageData={imageData} setImageData={setImageData} isDisable={isDisable} setIsDisable={setIsDisable} setAlertData={setAlertData} setIsAlert={setIsAlert} setModalData={setModalData} />}
        {calculator?.code === "StepUp" && <StepUp calculator={calculator} textLogo={isChecked} imageData={imageData} setImageData={setImageData} isDisable={isDisable} setIsDisable={setIsDisable} setAlertData={setAlertData} setIsAlert={setIsAlert} setModalData={setModalData} />}
        {calculator?.code === "DelaySip" && <DelaySip calculator={calculator} textLogo={isChecked} imageData={imageData} setImageData={setImageData} isDisable={isDisable} setIsDisable={setIsDisable} setAlertData={setAlertData} setIsAlert={setIsAlert} setModalData={setModalData} />}
        {calculator?.code === "TargetAmount" && <TargetAmount calculator={calculator} textLogo={isChecked} imageData={imageData} setImageData={setImageData} isDisable={isDisable} setIsDisable={setIsDisable} setAlertData={setAlertData} setIsAlert={setIsAlert} setModalData={setModalData} />}
        {calculator?.code === "ChildEducationPlanner" && <ChildEducationPlanner calculator={calculator} textLogo={isChecked} imageData={imageData} setImageData={setImageData} isDisable={isDisable} setIsDisable={setIsDisable} setAlertData={setAlertData} setIsAlert={setIsAlert} setModalData={setModalData} />}
        {calculator?.code === "SWP" && <SWP calculator={calculator} textLogo={isChecked} imageData={imageData} setImageData={setImageData} isDisable={isDisable} setIsDisable={setIsDisable} setAlertData={setAlertData} setIsAlert={setIsAlert} setModalData={setModalData} />}
        {calculator?.code === "FixedMonthlyWithdrawal" && <FixedMonthlyWithdrawal calculator={calculator} textLogo={isChecked} imageData={imageData} setImageData={setImageData} isDisable={isDisable} setIsDisable={setIsDisable} setAlertData={setAlertData} setIsAlert={setIsAlert} setModalData={setModalData} />}
        {calculator?.code === "RetirementPlanner" && <RetirementPlanner calculator={calculator} textLogo={isChecked} imageData={imageData} setImageData={setImageData} isDisable={isDisable} setIsDisable={setIsDisable} setAlertData={setAlertData} setIsAlert={setIsAlert} setModalData={setModalData} />}
        {calculator?.code === "Lumpsum" && <Lumpsum calculator={calculator} textLogo={isChecked} imageData={imageData} setImageData={setImageData} isDisable={isDisable} setIsDisable={setIsDisable} setAlertData={setAlertData} setIsAlert={setIsAlert} setModalData={setModalData} />}
        {calculator?.code === "ImpactOfInflation" && <ImpactOfInflation calculator={calculator} textLogo={isChecked} imageData={imageData} setImageData={setImageData} isDisable={isDisable} setIsDisable={setIsDisable} setAlertData={setAlertData} setIsAlert={setIsAlert} setModalData={setModalData} />}
        {calculator?.code === "SIPEMIPlanner" && <SIPWithEMIPlanner calculator={calculator} textLogo={isChecked} imageData={imageData} setImageData={setImageData} isDisable={isDisable} setIsDisable={setIsDisable} setAlertData={setAlertData} setIsAlert={setIsAlert} setModalData={setModalData} />}
      </div>

      <br /><br /><br />
      <div className="container">
        <p className='lato-regular f-s-12 lh-16 text-center'>Note: Returns are neither assured nor guaranteed. There is no warranty about the accuracy of the calculator. <br />
          Disclaimer: Mutual Fund investments are subject to market risks, read all scheme related documents carefully.</p>
        <br /><br /><br />
      </div>
      <Footer />
      <AlertModal show={isAlert} onHide={onHide} alertData={alertData} type={"usage"} modalClass={"alerts-modal"} />
    </div>
  );



}


