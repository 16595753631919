import React from "react";
import {
  Form,
  FormElement,
  FormValidator,
  CustomTextControl,
  SelectControl,
  BaseReactComponent,
  DatePickerControl,
} from "../../utils/form";
import { Button, Image } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
// import Footer from ".../commom/Footer";
import Edit from "../../assets/images/edit-icon.png";
import Logo from "../../assets/images/profile-logo.png";
import Profile from "../../assets/images/profile.png";
import { getAuthDataApi, updateProfileApi } from "../home/Api";
import { DEFAULT_IMAGE_HEIGHT, DEFAULT_IMAGE_WIDTH, UPDATE_PROFILE } from "../../utils/Constant";
import { getUser } from "../../utils/ReusableFunctions";
import { commonPostApi, sendEmailVerificationApi, verifyMobileApi, verifyOtpApi } from "./Api";
import { BreadCrumb } from "../home/_utils";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import closeIcon from '../../assets/images/close-black.svg'
import warningIcon from "../../assets/images/warning-icon.svg";
import lockIcon from "../../assets/images/lock-icon.svg";
import greenTickIcon from "../../assets/images/green-tick-icon.svg";
import { getUserDetailsApi } from "../onBoarding/Api";
import { Spinner } from "../common/components";
import ReactDOM from "react-dom";

const companyTypes = [{ "value": "MFD (Mutual Fund Distributor)", "label": "MFD (Mutual Fund Distributor)" }, { "value": "RIA (Registered Investment Advisor)", "label": "RIA (Registered Investment Advisor)" }, { "value": "ND (National Distributor)", "label": "ND (National Distributor)" }, { value: "Other", label: "Other" }]
export class ProfileDetails extends BaseReactComponent {
  constructor(props) {
    super(props);
    const userDetails = getUser();
    // console.log('userDetails', userDetails);
    this.state = {
      userDetails,
      firstName: userDetails?.firstName || "",
      lastName: userDetails?.lastName || "",
      pleaseselect: userDetails?.pleaseselect || "",
      dateOfBirth: userDetails?.dateOfBirth ? new Date(userDetails.dateOfBirth) : "",
      gender: userDetails?.gender || "",
      genderOption: [
        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" },
      ],
      mobileNumber: userDetails?.unverifiedMobileNumber || userDetails?.mobileNumber || "",
      emailAddress: userDetails?.unverifiedEmailAddress || userDetails?.emailAddress || "",
      // whatsappMobileNumber: userDetails?.whatsappMobileNumber || "",
      organizationName: userDetails?.companyName || "",
      organizationType: userDetails?.organizationType || "",
      arnNumber: userDetails?.arnNumber || "",
      euinNumber: userDetails?.euinNumber || "",
      companyAddress: userDetails?.companyAddress || "",
      city: userDetails?.city || "",
      state: userDetails?.state || "",
      pincode: userDetails?.pincode || "",
      gstNumber: userDetails?.gstNumber || "",
      companyInception: userDetails?.companyInception ? new Date(userDetails.companyInception) : "",
      organizationLogo: userDetails?.organizationLogoPath ? userDetails?.organizationLogoPath : null,
      profilePicture: userDetails?.profilePicturePath ? userDetails?.profilePicturePath : null,
      allStateData: [],
      allCityData: [],
      mobileStatus: "",
      otpMobile: null,
      otpSent: false,
      showResend: userDetails?.unverifiedMobileNumber ? true : false,
      changeMobile: false,
      emailStatus: "",
      changeEmail: false,
      showTimer: false,
      secondsLeft: 30,
      isSubmited: false,
      emailLoading: false,
      dobError: false,

    };
  }
  isError = () => {
    const {
      firstName,
      lastName,
      dateOfBirth,
      organizationName,
      organizationType,
      arnNumber,
      mobileNumber,
      emailAddress,
    } = this.state;
    const requiredFields = [
      firstName,
      lastName,
      dateOfBirth,
      mobileNumber,
      emailAddress,
      // gender,
      organizationName,
      organizationType,
      arnNumber,

    ];
    const emptyField = requiredFields.some(field => field === "" || field == null);

    if (emptyField) {
      toast.error("Please fill out all required fields.");
    }


  }


  onValidSubmit = async () => {
    this.setState({ isSubmited: true })
    const {
      firstName,
      lastName,
      dateOfBirth,
      gender,
      // mobileNumber,
      // emailAddress,
      // whatsappMobileNumber,
      organizationName,
      organizationType,
      arnNumber,
      euinNumber,
      companyAddress,
      city,
      state,
      pincode,
      gstNumber,
      companyInception,
      allStateData,
      allCityData,
      mobileNumber,
      emailAddress,
    } = this.state;
    const requiredFields = [
      firstName,
      lastName,
      dateOfBirth,
      mobileNumber,
      emailAddress,
      // gender,
      organizationName,
      organizationType,
      arnNumber,

    ];



    const emptyField = requiredFields.some(field => field === "" || field == null);

    if (!this.state.dateOfBirth) {
      this.setState({ dobError: true });
    } else {
      this.setState({ dobError: false });
      // Proceed with form submission logic
    }
    const validateName = (name) => {
      const trimmedName = name.trim(); // Remove leading and trailing spaces
      const isNotEmpty = trimmedName.length > 0;

      return isNotEmpty;
    };


    const isFirstNameValid = validateName(firstName);
    const isLastNameValid = validateName(lastName);

    if (emptyField || !isFirstNameValid || !isLastNameValid) {

      toast.error("Please fill out all required fields.");

      this.setState({ isSubmited: false });
      return false;
    }


    let data = {
      data: {
        firstName: firstName,
        lastName: lastName,
        dateOfBirth: dateOfBirth,
        gender: gender,
        companyAddress: companyAddress,
        companyName: organizationName,
        organizationType: organizationType,
        arnNumber: arnNumber,
        euinNumber: euinNumber,
        city: city,
        state: state,
        pincode: pincode,
        gstNumber: gstNumber,
        companyInception: companyInception,
      },
    };
    const response = await updateProfileApi(UPDATE_PROFILE, data, "put");

    if (response.data) {
      toast.success("Updated Successfully");
      localStorage.setItem("finUserDetails", JSON.stringify(response.data));
      this.setState({ isSubmited: false })
    } else {
      this.setState({ isSubmited: false })
    }
  };
  getDataHandler = async (url) => {
    const data = await getAuthDataApi(url);
    return data;
  }


  isFormValid = () => {

    return (
      this.validateName(this.state.firstName) &&
      this.validateName(this.state.lastName) &&
      this.state.mobileNumber.length === 10 &&
      this.state.dateOfBirth
    );
  };

  componentDidMount() {
    getUserDetailsApi(this);
    this.getDataHandler("/website/location")
      .then((data) => {
        if (Array.isArray(data)) {
          this.setState({ allStateData: data });
        }
      })
      .catch((error) => {

      });
    if (this.state?.state) {
      this.getCity();
    }
  }
  getCity = () => {
    this.getDataHandler(`/website/location/${this.state?.state}`)
      .then((data) => {
        if (Array.isArray(data)) {
          this.setState({ allCityData: data });
        }
      })
      .catch((error) => {

      });
  }
  logoUploadHandler = async (e, type) => {
    const file = e.target.files[0];
    const fileType = file.type;
    // Allowed file types
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];

    // Validate file type
    if (!allowedTypes.includes(fileType)) {
      toast.error("Only Image files are allowed.");
      return;
    }

    this.setState({ isSubmited: true })
    console.log("Is lofog and proile pdaed")
    if (type === 'profile') {
      let data = new FormData();
      data.append('profile-picture', e.target.files[0])
      const imageUrl = URL.createObjectURL(e.target.files[0]);
      this.setState({ profilePicture: imageUrl })
      let response = await commonPostApi("user/upload-profile-picture", data, "put");
      // console.log("Profile Petcure", response);
      if (response.data) {
        toast.success("Updated Successfully");
        localStorage.setItem("finUserDetails", JSON.stringify(response.data));
        this.setState({ isSubmited: false });
      }
      else {
        this.setState({ isSubmited: false });
      }
    } else {
      let data = new FormData();
      data.append('organization-logo', e.target.files[0]);
      const imageUrl = URL.createObjectURL(e.target.files[0]);

      // todo======================== SIZING IMEGE CODE START ==================
      const image = new window.Image();
      image.src = URL.createObjectURL(e.target.files[0]);
      image.onload = async () => {
        const width = image.width;
        const height = image.height;

        if (width > DEFAULT_IMAGE_WIDTH || height > DEFAULT_IMAGE_HEIGHT) {
          toast.error(`Image dimensions must be ${DEFAULT_IMAGE_WIDTH}x${DEFAULT_IMAGE_HEIGHT} or smaller. Uploaded image is (${width}x${height})`);
        } else {
          this.setState({ organizationLogo: imageUrl }, async () => {
            let response = await commonPostApi("user/upload-organization-logo", data, "put");
            // console.log(response)

            if (response.data) {
              localStorage.setItem("finUserDetails", JSON.stringify(response.data));
              toast.success("Updated Successfully");
              this.setState({ isSubmited: false })
            }
            else {
              this.setState({ isSubmited: false })
            }
          });
        }
      };
      // todo======================== END


    }
    //
  }
  handleChange = (e, validation) => {
    let isValid = [];
    validation.map((item) => {
      if (FormValidator[item](e.target.value)) {
        isValid.push(true)
      } else {
        isValid.push(false)
      }
    })
    const check = isValid.every((item) => item === true);
    if (check) {
      this.setState({ [e.target.name]: e.target.value })
    } else if (!check && !(validation.includes("isRequired"))) {
      this.setState({ [e.target.name]: e.target.value })
    }
  }
  startCountdown() {
    let countdown = setInterval(() => {
      if (this.state.secondsLeft > 0) {
        this.setState((prevState) => ({
          secondsLeft: prevState.secondsLeft - 1,
        }));
      } else {
        clearInterval(countdown);
        this.setState({ showTimer: false, secondsLeft: 30 });
      }
    }, 1000); // Update every second
  }


  handleSave = () => {
    this.isError();
    ReactDOM.findDOMNode(this.form).dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };

  render() {
    const { profilePicture, isSubmited, organizationLogo, state, userDetails } = this.state;
    return (
      <div>
        {/* <Header /> */}
        <BreadCrumb path={["Profile Details"]} clasese="mb-3-desk" />
        <div className="information-wrapper">
          <h2>Basic Information</h2>
          <Form ref={(el) => (this.form = el)} onValidSubmit={this.onValidSubmit}>


            <div className="form-area">
              <div className="form-details">
                <Row>
                  <Col md={6}>
                    <div className="form-group">
                      <p className="form-label-custom fs-18-16">First Name <span className="text-danger">*</span></p>
                      <FormElement
                        valueLink={this.linkState(this, "firstName")}
                        required
                        disabled={userDetails?.ssoPartner && userDetails?.ssoPartner?.
                          canUserUpdateProfile === false
                        }
                        validations={[
                          {
                            validate: FormValidator.isRequired,
                            message: "First Name cannot be empty",
                          },

                        ]}
                        control={{
                          type: CustomTextControl,
                          settings: {
                            type: "text",
                            placeholder: "Enter First Name",
                          },
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>

                    <div className="form-group">
                      <p className="form-label-custom fs-18-16">Last Name <span className="text-danger">*</span></p>
                      <FormElement
                        valueLink={this.linkState(this, "lastName")}
                        required
                        disabled={userDetails?.ssoPartner && userDetails?.ssoPartner?.
                          canUserUpdateProfile === false
                        }
                        validations={[
                          {
                            validate: FormValidator.isRequired,
                            message: "Last Name cannot be empty",
                          },

                        ]}
                        control={{
                          type: CustomTextControl,
                          settings: {
                            type: "text",
                            placeholder: "Enter Last Name",
                          },
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group custom-date-range-picker">

                      <FormElement
                        valueLink={this.linkState(this, "dateOfBirth")}
                        label="Date of Birth"
                        required
                        disabled={userDetails?.ssoPartner && userDetails?.ssoPartner?.
                          canUserUpdateProfile === false
                        }
                        validations={[
                          {
                            validate: FormValidator.isRequired,
                            message: "Date of Birth cannot be empty",
                          }
                        ]}
                        control={{
                          type: DatePickerControl,

                          settings: {
                            selected: this.state.dateOfBirth,
                            onChange: (date) => this.setState({ dateOfBirth: date }),
                            maxDate: new Date(),
                            dateFormat: "dd/MM/yyyy",
                            placeholder: "Select Date of birth",
                            className: "form-control",
                            showYearDropdown: true,
                            yearDropdownItemNumber: 50,
                            scrollableYearDropdown: true,

                          }
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <FormElement
                      valueLink={this.linkState(this, "gender")}
                      label="Gender"
                      disabled={userDetails?.ssoPartner && userDetails?.ssoPartner?.
                        canUserUpdateProfile === false
                      }
                      control={{
                        type: SelectControl,
                        settings: {
                          options: this.state.genderOption,
                          multiple: false,
                          searchable: true,
                          placeholder: "Select gender",
                          onChangeCallback: (onBlur) => {
                            onBlur(this.state.gender);
                          }

                        }
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <div className="form-group">

                      {
                        userDetails.unverifiedMobileNumber
                          ?
                          <span className="verification-status d-flex align-content-center text-center"><Image src={warningIcon} className="icons" /> Verification Pending</span>
                          :
                          <span className="verification-status verified"><Image src={greenTickIcon} className="icons" /> Verified</span>
                      }
                      <FormElement

                        valueLink={this.linkState(this, "mobileNumber")}
                        required

                        label="Mobile Number"
                        className="form-control verify"
                        validations={[
                          {
                            validate: FormValidator.isRequired,
                            message: "Mobile Number cannot be empty",
                          },
                          {
                            validate: FormValidator.isNum,
                            message: "Only Numbers Allowed",
                          },
                        ]}
                        control={{
                          type: CustomTextControl,

                          settings: {
                            placeholder: "Enter Mobile Number",
                            onChangeCallback: (onBlur) => {
                              onBlur(this.state.mobileNumber);
                            }
                          }
                        }}

                        minLength={10}
                        disabled={!this.state.changeMobile || (userDetails?.ssoPartner && userDetails?.ssoPartner?.canUserUpdateProfile === false)}
                      />
                      {
                        this.state.changeMobile ?
                          <span className="change-button">
                            <span onClick={async () => {
                              if (this.state.mobileNumber.length !== 10) {
                                toast.warn("Mobile Number is not valid");
                                return;
                              }

                              const data = {
                                data: { mobileNumber: this.state.mobileNumber }
                              };
                              const profileData = {
                                data: { unverifiedMobileNumber: this.state.mobileNumber }
                              };

                              try {
                                const response = await verifyMobileApi(this, data);  // Await the response
                                // Only proceed with updating profile if verifyMobileApi returns a response
                                if (response) {
                                  await updateProfileApi(UPDATE_PROFILE, profileData, "put");
                                }
                              } catch (error) {
                                console.error("Error verifying or updating mobile number:", error);
                              }
                            }}>
                              Update
                            </span>
                            <Image src={closeIcon} className="ml-2" onClick={() =>
                              this.setState({
                                changeMobile: !this.state.changeMobile,
                                mobileNumber: userDetails?.unverifiedMobileNumber || userDetails?.mobileNumber
                              })}
                            />
                          </span>

                          :
                          (
                            !userDetails?.ssoPartner &&
                            <span
                              className="change-button"
                              onClick={() => {
                                this.setState({
                                  changeMobile: !this.state.changeMobile,
                                })
                              }}
                            >
                              {(!this.state.changeMobile && !this.state.otpSent) ? "Change" : "Edit"}
                            </span>
                          )
                      }
                      {
                        // !this.state.changeMobile &&
                        <div>
                          {

                            ((this.state.otpSent || userDetails.unverifiedMobileNumber))
                              ?
                              <div className="verification-wrapper">
                                <Image src={lockIcon} className="icons" />
                                <div className="right">
                                  <h6 className="lato-regular f-s-14 lh-20 black-242">Enter OTP</h6>
                                  <p className="lato-regular f-s-12 lh-16 black-4 pt-1">Please enter an OTP sent to your new mobile number.</p>
                                  <div className="otp-wrapper">
                                    <input
                                      value={this.state.otpMobile}
                                      type="number"
                                      name="otpMobile"
                                      className="form-control"
                                      onChange={(e) => this.handleChange(e, ["isNum"])}
                                      placeholder="Enter OTP"
                                    // maxLength={10}
                                    />
                                    {
                                      this.state.otpMobile && !userDetails?.ssoPartner
                                        ?
                                        <Button
                                          className={`primary-btn`}
                                          onClick={() => {
                                            const data = {
                                              data: {
                                                mobileNumber: this.state.mobileNumber,
                                                mobileNumberOtp: this.state.otpMobile
                                              }
                                            }
                                            verifyOtpApi(this, data);

                                          }
                                          }
                                        >
                                          Verify
                                        </Button>
                                        :
                                        <Button className={`primary-btn disable`}>Verify</Button>
                                    }
                                  </div>
                                  {
                                    this.state.showResend &&
                                    (this.state.showTimer ? (
                                      <div>
                                        <span className='lato-regular primary f-s-12'>Resend in {this.state.secondsLeft} seconds</span>
                                      </div>
                                    ) : (
                                      <span
                                        onClick={() => {
                                          const data = {
                                            data: {
                                              mobileNumber: this.state.mobileNumber
                                            }
                                          }
                                          verifyMobileApi(this, data);

                                        }}
                                        className="lato-regular primary f-s-12 pointer">
                                        Resend OTP
                                      </span>
                                    ))

                                  }
                                </div>
                              </div>
                              :
                              (this.state.changeMobile && !this.state.otpSent)
                              &&
                              <div className="verification-wrapper">
                                <Image src={warningIcon} className="icons" />
                                <div className="right">
                                  <h6 className="lato-regular f-s-14 lh-20 black-242">Change Mobile Number</h6>
                                  <p className="lato-regular f-s-12 lh-16 black-4 pt-1">An OTP will be sent to your updated mobile number for verification.</p>
                                </div>
                              </div>
                          }
                        </div>
                      }
                    </div>
                  </Col>

                  <Col md={6}>
                    {!this.state.emailLoading ?
                      userDetails.unverifiedEmailAddress
                        ?
                        <span className="verification-status email d-flex flex-row text-center align-items-center"><Image src={warningIcon} className="icons" /> Verification Pending</span>
                        :
                        <span className="verification-status email verified"><Image src={greenTickIcon} className="icons" /> Verified</span>
                      :
                      <span className="verification-status email d-flex align-items-center"><Spinner /> Updating...</span>

                    }
                    <FormElement
                      valueLink={this.linkState(this, "emailAddress")}
                      label="Email Address"
                      required
                      disabled={userDetails?.ssoPartner}
                      validations={[
                        {
                          validate: FormValidator.isEmail,
                          message: "Please enter a valid email",
                        },

                        {
                          validate: FormValidator.isRequired,
                          message: "Email Address cannot be empty",
                        },
                      ]}
                      control={{
                        type: CustomTextControl,
                        settings: {
                          type: "text",
                          disabled: !this.state.changeEmail,
                          placeholder: "Enter email address",

                        },
                      }}
                      classes={{
                        formGroup: "custom-group",
                        inputField: "verify"
                      }}
                    />
                    {(!userDetails?.ssoPartner || (userDetails?.ssoPartner && userDetails?.ssoPartner?.canUserUpdateProfile === true)) &&

                      <span
                        className="change-button email-change"
                      >
                        <span onClick={async () => {
                          const { emailAddress, userDetails, changeEmail } = this.state;


                          if (changeEmail && emailAddress) {

                            this.setState({ emailLoading: true })
                            const profileData = {
                              data: { unverifiedEmailAddress: this.state.emailAddress }
                            };

                            const response = await updateProfileApi(UPDATE_PROFILE, profileData, "put");
                            // console.log('response', response);
                            if (response.data) {

                              this.setState({ userDetails: response.data })
                              const data = {
                                "data": { "emailAddress": response.data.unverifiedEmailAddress }
                              };
                              sendEmailVerificationApi(data);
                              this.setState({ emailLoading: false })

                            } else {

                              // If the email is the same, reset the state back to the previous email
                              this.setState({ emailAddress: userDetails.unverifiedEmailAddress || userDetails.emailAddress });
                            }

                          }
                          this.setState({
                            changeEmail: !this.state.changeEmail,
                          })
                          this.setState({ emailLoading: false })

                        }}>
                          {!this.state.changeEmail ? "Change" : "Update"}
                        </span>
                        {this.state.changeEmail && <Image src={closeIcon} className="ml-2" onClick={() => this.setState({ changeEmail: !this.state.changeEmail })} />}

                      </span>
                    }
                    {
                      userDetails.unverifiedEmailAddress &&
                      <div className="verification-wrapper email">
                        <Image src={warningIcon} className="icons" />
                        <div className="right">
                          <h6 className="lato-regular f-s-14 lh-20 black-242 mt-1">Verification email sent</h6>
                          <p className="lato-regular f-s-12 lh-16 black-4 pt-1">Please verify your email address by following the link sent to {userDetails.unverifiedEmailAddress}.</p>
                          <Button className="secondary-btn" onClick={() => {
                            const data = {
                              "data": { "emailAddress": userDetails.unverifiedEmailAddress }
                            };
                            sendEmailVerificationApi(data);
                          }}>Resend verification email</Button>
                        </div>
                      </div>
                    }
                  </Col>


                </Row>
              </div>
              <div className="profile-image">
                <div className="profile-section">
                  <div className="edit">
                    <input disabled={userDetails?.ssoPartner && userDetails?.ssoPartner?.canUserUpdateProfile === false
                    } type="file" name="Profile Picture" accept="image/*" onChange={(e) => this.logoUploadHandler(e, "profile")} className="d-none" id="Profile-Picture" />
                    <label htmlFor="Profile-Picture">
                      <Image src={Edit} alt="edit" className="pointer" />
                    </label>
                  </div>
                  <Image src={profilePicture ? profilePicture : Profile} alt="edit" />
                  <h3 className="fs-14-13">Profile Picture</h3>
                </div>
              </div>
            </div>
            <h2>Company/Billing Information</h2>
            <span className="lato-regular f-s-12 lh-18 op-5">*This information will be used for Billing & GST purposes</span>
            <div className="form-area">
              <div className="form-details">
                <Row>
                  <Col md={6}>

                    <div className="form-group">

                      <FormElement
                        valueLink={this.linkState(this, "organizationName")}
                        required
                        disabled={userDetails?.ssoPartner && userDetails?.ssoPartner?.
                          canUserUpdateProfile === false
                        }
                        label="Company Name"
                        className="form-control"
                        validations={[
                          {
                            validate: FormValidator.isRequired,
                            message: "Company Name cannot be empty",
                          },
                        ]}
                        control={{
                          type: CustomTextControl,

                          settings: {
                            placeholder: "Enter company name",
                            onChangeCallback: (onBlur) => {
                              onBlur(this.state.organizationName);
                            }
                          }
                        }}

                      />
                    </div>

                  </Col>
                  <Col md={6}>
                    <FormElement
                      valueLink={this.linkState(this, "organizationType")}
                      label="Company Type"
                      disabled={userDetails?.ssoPartner && userDetails?.ssoPartner?.
                        canUserUpdateProfile === false
                      }
                      required
                      validations={[
                        {
                          validate: FormValidator.isRequired,
                          message: "Company Type cannot be empty",
                        },
                      ]}
                      control={{
                        type: SelectControl,
                        settings: {
                          options: companyTypes,
                          multiple: false,
                          searchable: true,

                          placeholder: "Select Type",
                          onChangeCallback: (onBlur) => {
                            onBlur(this.state.organizationType);
                          }
                        }
                      }}
                    />

                  </Col>
                  <Col md={6}>
                    <div className="form-group">

                      <FormElement
                        valueLink={this.linkState(this, "arnNumber")}
                        disabled={userDetails?.ssoPartner && userDetails?.ssoPartner?.
                          canUserUpdateProfile === false
                        }
                        required
                        label="ARN/RIA Number "
                        className="form-control"
                        validations={[
                          {
                            validate: FormValidator.isRequired,
                            message: "ARN/RIA Number cannot be empty",
                          },
                        ]}
                        control={{
                          type: CustomTextControl,

                          settings: {
                            placeholder: "Enter arn/rai name",
                            onChangeCallback: (onBlur) => {
                              onBlur(this.state.arnNumber);
                            }
                          }
                        }}

                        maxLength={10}

                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <p className="form-label-custom fs-18-16">EUIN Number </p>
                      <input
                        value={this.state.euinNumber}
                        type="text"
                        name="euinNumber"
                        className="form-control"
                        onChange={(e) => this.handleChange(e, ["isAlphaNumeric"])}
                        placeholder="Enter EUIN number"
                        maxLength={15}
                        disabled={userDetails?.ssoPartner && userDetails?.ssoPartner?.
                          canUserUpdateProfile === false
                        }
                      />
                    </div>
                  </Col>
                  <Col md={6}>

                    <div className="form-group custom-date-range-picker">
                      <label htmlFor="" className="form-label">Company Inception</label>
                      <DatePicker
                        selected={this.state.companyInception}
                        onChange={(date) => this.setState({ companyInception: date })}
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Enter company inception"
                        className="form-control "
                        // showMonthDropdown
                        showYearDropdown
                        // dateFormatCalendar="MMMM"
                        yearDropdownItemNumber={50}
                        scrollableYearDropdown
                        disabled={userDetails?.ssoPartner && userDetails?.ssoPartner?.
                          canUserUpdateProfile === false
                        }
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <p className="form-label-custom fs-18-16">Company Address</p>
                      <input
                        value={this.state.companyAddress}
                        type="text"
                        name="companyAddress"
                        className="form-control"
                        onChange={(e) => this.handleChange(e, ["isAlphaNumeric"])}
                        placeholder="Enter company address"
                        required
                        disabled={userDetails?.ssoPartner && userDetails?.ssoPartner?.
                          canUserUpdateProfile === false
                        }
                      />
                    </div>

                  </Col>
                  <Col md={6}>
                    <FormElement
                      valueLink={this.linkState(this, "state")}
                      label="State"
                      disabled={userDetails?.ssoPartner && userDetails?.ssoPartner?.
                        canUserUpdateProfile === false
                      }
                      control={{
                        type: SelectControl,
                        settings: {
                          options: this.state.allStateData?.map((item, i) => { return ({ "value": item, "label": item }) }),
                          multiple: false,
                          searchable: true,
                          placeholder: "Select State",
                          onChangeCallback: (onBlur) => {
                            this.setState({ city: "" }, () => {
                              this.getCity()
                            })

                            onBlur(state);
                          }
                        }
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <FormElement
                      valueLink={this.linkState(this, "city")}
                      label="City"
                      disabled={userDetails?.ssoPartner && userDetails?.ssoPartner?.
                        canUserUpdateProfile === false
                      }
                      control={{
                        type: SelectControl,
                        settings: {
                          options: this.state.allCityData?.map((item, i) => { return ({ "value": item, "label": item }) }),
                          multiple: false,
                          searchable: true,
                          placeholder: "Select City",
                          onChangeCallback: (onBlur) => {
                            onBlur(this.state.city);
                          }
                        }
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <p className="form-label-custom fs-18-16">Pincode</p>
                      <input
                        value={this.state.pincode}
                        type="text"
                        name="pincode"
                        className="form-control"
                        onChange={(e) => this.handleChange(e, ["isNum"])}
                        placeholder="Enter pincode"
                        maxLength={6}
                        disabled={userDetails?.ssoPartner && userDetails?.ssoPartner?.
                          canUserUpdateProfile === false
                        }
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <p className="form-label-custom fs-18-16">GST Number</p>
                      <input
                        value={this.state.gstNumber}
                        type="text"
                        name="gstNumber"
                        className="form-control"
                        onChange={(e) => this.handleChange(e, ["isAlphaNumeric"])}
                        placeholder="Enter GST number"
                        maxLength={15}
                        disabled={userDetails?.ssoPartner && userDetails?.ssoPartner?.
                          canUserUpdateProfile === false
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="profile-image">
                <div className="profile-section mb-90">
                  <div className="edit">
                    <input disabled={userDetails?.ssoPartner && userDetails?.ssoPartner?.
                      canUserUpdateProfile === false
                    } type="file" name="organizationLogo" accept="image/*" className="d-none" onChange={(e) => this.logoUploadHandler(e, "organization")} id="organization-Logo" />
                    <label htmlFor="organization-Logo">
                      <Image src={Edit} alt="edit" className="pointer" />
                    </label>
                  </div>
                  { }
                  <Image src={organizationLogo ? organizationLogo : Logo} alt="edit" />

                  <p className="f-s-10 image-requirment">(Please upload image of {DEFAULT_IMAGE_WIDTH} X {DEFAULT_IMAGE_HEIGHT} dimensions for best output)</p>
                  <h3 className="fs-14-13">Company Logo</h3>
                </div>
              </div>
            </div>

            {isSubmited ? (
              <div className="lato-medium fx-18 detail-button-download primary ">
                <Spinner />
                Submitting...
              </div>
            ) : (
              (!userDetails?.ssoPartner || (userDetails?.ssoPartner && userDetails?.ssoPartner.canUserUpdateProfile === true)) && (  // Hide the button if ssoPartner exists
                <Button onClick={this.handleSave} className="primary-btn">Submit</Button>
              )
            )}


          </Form >
        </div>
        {/* <Footer /> */}
      </div >

    );
  }
}

export default ProfileDetails;