import React, { useEffect } from "react";
import { Header, Footer } from "../common/pages";
import { BreadCrumb } from "../home/_utils";
import { SearchFilter } from "../common";
import {
  useHistory,

} from "react-router-dom";
import { useState } from "react";
import { CardHeader } from "../common/components";
import { Image } from "react-bootstrap";
import { getAuthDataApi } from "../home/Api";


export default function ToolsPage() {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  function setValueHandler(info) {
    setFilterData(info)
  }



  const getTools = (async () => {
    const res = await getAuthDataApi("website/tools/list?pageNumber=1&pageSize=18")
    if (res) {
      setData(res);
      setFilterData(res);

    }

  });
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    getTools()
  }, [])





  return (
    <div className="page-padding ">
      <div className="container">
        <Header />
        <BreadCrumb path={["Tools"]} />
        <SearchFilter
          data={data}
          filterData={filterData}
          setValueHandler={setValueHandler}
          name="tools"
          placeholder="tool"
          clases=""
        />

        <CardHeader
          title={"Tools"}
          count={data?.length}
          hide={false}
          countTitle={"Tools"}

        />
        <div className="tolls-card-wrapper">
          {filterData?.map(item => {
            return (
              <ToolsCard data={item} />
            )
          })}
        </div>
      </div>
      <Footer />

    </div>
  );
}

function ToolsCard({ data }) {
  const history = useHistory();

  return (
    <div
      className="toolsCardWrapper responsive-card-width"
      onClick={() => {
        window.location.href = `/tools/calculator${data?.link}`;

      }}
    >
      <div className="image-frame">
        <Image src={data?.thumbnailPath} alt={data?.toolName} />
      </div>
      <div className="content-frame lato-regular fs-24-16 black-242 ">
        {data?.toolName || 'NA'}
      </div>
    </div>
  );
}
